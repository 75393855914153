import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, TablePaginationConfig } from 'antd';
import Search from 'antd/lib/input/Search';
import Title from 'antd/lib/typography/Title';
import { fetchConfigure } from 'app/apis/CriteriaManageClient';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { USER_PAGE_NUMBER } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useFetch } from 'app/hooks/useFetch';
import { PageTitle } from 'app/layouts/AdminLayout.styled';
import { ConfiguredType, TeamConfigureResponse } from 'app/types/CriteriaConfigure';
import { SelectOptions } from 'app/types/entity';
import { isNull } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ManageTeamsWrapper } from '../ManageTeams/ManageTeams.styled';
import './ManageCriteria.scss';
import moment from 'moment';

export const ManageCriteria = () => {
  const { data: configureData } = useFetch(fetchConfigure);
  const [teamConfigure, setTeamConfigure] = useState<TeamConfigureResponse[]>();
  const [page, setPage] = useState(1);
  const searchEl = useRef(null);
  const { setBreadcrumbs } = useContext(TeamContext);
  useEffect(() => {
    setBreadcrumbs([createDefaultBreadCrumb('Criteria management')]);
  }, [setBreadcrumbs]);
  useEffect(() => {
    if (configureData) {
      setTeamConfigure(configureData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configureData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ...`}
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const configuredOptions: SelectOptions<ConfiguredType>[] = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const columns: EditableColumn<TeamConfigureResponse>[] = [
    {
      title: '#',
      width: '5%',
      render: (value, record, index) => (page - 1) * USER_PAGE_NUMBER + index + 1,
      align: 'center',
    },
    {
      title: 'Team Name',
      width: '35%',
      dataIndex: 'teamName',
      editable: true,
      rules: [{ required: true, message: 'This field is required' }],
      ...getColumnSearchProps('teamName'),
    },
    {
      title: 'Project',
      width: '35%',
      dataIndex: 'projectName',
      editable: true,
      inputType: 'select',
      ...getColumnSearchProps('projectName'),
    },
    {
      title: 'Configured Date',
      width: '15%',
      editable: false,
      dataIndex: 'lastedDate',
      render: (value) => moment(value).format('MM/YYYY'),
    },
    {
      title: 'Is Configured?',
      dataIndex: 'isConfigure',
      inputType: 'select',
      editable: true,
      options: configuredOptions,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => record.isConfigure === value,
      align: 'center',
    },
    {
      title: 'Action',
      align: 'center',
      render: (value, record) => (
        <Button type="link">
          <Link
            to={{
              pathname: `configure-criteria/${record.id}`,
              state: {
                defaultDate: record.lastedDate,
              },
            }}
          >
            Edit
          </Link>
        </Button>
      ),
    },
  ];

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
  };
  return (
    <ManageTeamsWrapper>
      <Helmet>
        <title>ADM Tool | Criteria management</title>
      </Helmet>
      <Row>
        <Col span={15}>
          <PageTitle>
            <Title level={3}>CRITERIA MANAGEMENT</Title>
          </PageTitle>
        </Col>
      </Row>
      {/* <Row style={{ marginBottom: '1rem' }}>
        <Col span={24}>
          <Space>
            <Button type="primary">Export template</Button>
          </Space>
        </Col>
      </Row> */}
      <Row>
        <Col span={24}>
          <EditableTable isEdit={false} data={teamConfigure} showAction={false} columns={columns} onChange={onChange} />
        </Col>
      </Row>
    </ManageTeamsWrapper>
  );
};

import { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col, Radio, RadioChangeEvent, Select, notification, Space, Button, Spin, Menu, Dropdown } from 'antd';
import Icon, { DownloadOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import './TeamDetail.scss';
// Contexts
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { Stage } from 'app/types/TeamDetailTypes';
import { Plan } from './Plan/Plan';
import { Monitoring } from './Monitoring/Monitoring';
import { Report } from './Report/Report';
import { DisplayComponents } from './DisplayComponents/DisplayComponents';

// Styles
import { PageTitle, StyledPlanSubmitButton, StyledTag, StyledAlert, StyledSubmittedTag } from './TeamDetail.styled';
import SendIcon from './SendIcon';
import {
  allowEditIterationPlan,
  saveDemoPlanByType,
  createIterationNote,
  createIterationPlan,
  createIterationReport,
  editIterationReport,
  fetchTeamBreadcrumbs,
  submitIterationReport,
  updateIterationNote,
  saveAttendanceByType,
  downloadReportExportPDF,
  getAllNoteByPage,
  createDisplayComponent,
  updateDisplayComponent,
} from 'app/apis/teamDetailClient';
import { submitPlan } from 'app/apis/IterationPlanClient';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { IterationPlanResponse } from 'app/types/Iteration';
import { UserContext } from 'app/contexts/UserContext';
import { ExportType, SubPage, subPageOptions } from 'app/types/TeamDetail';
import { openNotification } from 'app/utils/notificationUtils';
import { withAuthz } from 'app/components/HOC/withAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { IIterationNote } from 'app/types/IterationNote';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { IterationNoteContext } from 'app/contexts/IterationNoteContext';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useFetch } from 'app/hooks/useFetch';
import { DownloadReport } from './Report/DownloadReport/DownloadReport';
import { getOverViewBaseBreadCrumb } from 'app/common/breadcrumb';
import html2canvas from 'html2canvas';
import { exportOptions } from 'app/types/exportOptions';
import { roleNames } from 'app/common/constants';
import UserProfileIcon from './UserProfileIcon';
import { SyncButton } from '../SystemMonitoring/SyncButton';
import useSyncStatus from 'app/hooks/useSyncStatus';
import { getStatusByTeamId, triggerSync } from 'app/apis/systemMonitoringClient';
import { useHistory } from 'react-router';
import { TeamOverview } from './Overview/TeamOverview';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import SendCheckIcon from './SendCheckIcon';
import { Helmet } from 'react-helmet';
import { DisplayType } from 'app/types/Stakeholders';

export const TeamDetail = () => {
  const {
    user: { permissions, userRole },
  } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const { team } = useContext(TeamDetailContext);
  const { components, exportSection } = useContext(DisplayComponentsContext);
  const { Option } = Select;

  const { setIterationNotesAll } = useContext(IterationNoteContext);
  const {
    stage: currentStage,
    setStage,
    teamIterations,
    selectedIteration,
    setSelectedIteration,
    reportStatus,
    setReportStatus,
    demoPlanData,
    forceRefetchIterationData,
  } = useContext(IterationContext);
  const { isAuthorized } = useAuthz();
  const allowEdit =
    isAuthorized &&
    (currentStage === Stage.Other ||
      currentStage === undefined ||
      (selectedIteration?.isCurrentSprint && currentStage < Stage.Other) ||
      selectedIteration?.isFutureSprint);
  const [fetchingDataCreateReport, setFetchingDataCreateReport] = useState(false);
  const [fetchingDataCreatePlan, setFetchingDataCreatePlan] = useState(false);
  const { setIterationNotes } = useContext(IterationNoteContext);
  const { setBreadcrumbs, page, setSubPage } = useContext(TeamContext);
  // const authzHoc = withAuthz('iteration:plan');
  const [changingReportState, setChangingReportState] = useState(false);
  const [exportProcessing, setExportProcessing] = useState(false);
  const { data: initialSyncStatus } = useFetch(() => getStatusByTeamId(team?.id), [team.id]);
  const [showChartGeneratedAlert, setShowChartGeneratedAlert] = useState<boolean>(false);
  const { syncStatus } = useSyncStatus(
    undefined,
    useCallback(() => setShowChartGeneratedAlert(true), [])
  );
  useFetch(
    () =>
      fetchTeamBreadcrumbs(team?.id).then((data) => {
        setBreadcrumbs([getOverViewBaseBreadCrumb(), ...data]);
      }),
    [team?.id]
  );

  const history = useHistory();

  const checkPage = (page: string) => {
    if (Object.values(SubPage).includes(page)) {
      return '#page=' + SubPage[SubPage[page]];
    } else {
      return '#page=';
    }
  };

  useEffect(() => {
    const urlHash = new URLSearchParams(history.location.hash.replace('#', '?'));
    const currentPage = urlHash.get('page');
    if (checkPage(currentPage) === '#page=') {
      const page = selectedIteration?.isCurrentSprint
        ? SubPage[SubPage.Monitoring]
        : selectedIteration?.isFutureSprint
        ? SubPage[SubPage.Plan]
        : SubPage[SubPage.Report];
      history.push({
        hash: `#page=${page}`,
        search: '?iteration=' + selectedIteration?.id,
      });
    } else {
      history.push({
        hash: checkPage(SubPage[page]),
        search: '?iteration=' + selectedIteration?.id,
      });
    }
  }, [history, page, selectedIteration?.id]);

  useEffect(() => {
    if (page !== SubPage.Plan) {
      setStage(Stage.Other);
    }

    // Only show create report if user does not click Create Report button before
    // (it is mean iterationReport is null and status differ from Submited)
    setReportStatus(null);
    if (
      selectedIteration !== undefined &&
      // Remove this condition to allow create/update the report for old iteration
      // selectedIteration.isCurrentSprint &&
      page === SubPage.Report
      // && permissions.some(
      //   (userPerm) =>
      //     userPerm === 'iteration:report::create' ||
      //     userPerm === 'iteration:report::update_submit_report' ||
      //     userPerm === 'iteration:report::update_edit_report'
      // )
    ) {
      if (selectedIteration.iterationReport === null) {
        setReportStatus(IterationReportStatusEnum.None);
      } else {
        setReportStatus(selectedIteration.iterationReport.status);
      }
    }
  }, [page, selectedIteration, setReportStatus, permissions, setStage]);

  function getClassNameForSprint(dateFrom: any, dateTo: any) {
    const currentDate = new Date();
    if (new Date(dateFrom) <= currentDate && new Date(dateTo) >= currentDate) {
      return 'current-sprint';
    }
    if (new Date(dateFrom) >= currentDate) {
      return 'future-sprint';
    }
  }

  const pagesOptions = selectedIteration?.isCurrentSprint
    ? subPageOptions
    : selectedIteration?.isFutureSprint
    ? subPageOptions.filter((page) => page.value !== SubPage.Monitoring && page.value !== SubPage.Report)
    : subPageOptions.filter((page) => page.value !== SubPage.Monitoring);

  const onChangeTeamType = ({ target: { value } }: RadioChangeEvent) => {
    setSubPage(value);
  };

  const exportMenuItems = (
    <Menu className="export-menu-items">
      <Menu.Item onClick={() => exportReport(ExportType.PDF, true)}>Document (PDF)</Menu.Item>
      <Menu.Item onClick={() => exportReport(ExportType.Image, true)}>Image (PNG)</Menu.Item>
    </Menu>
  );

  const submitPlanOnClickHandler = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      (async function () {
        try {
          let result: IterationPlanResponse = null;
          if (currentStage === Stage.PrePlan) {
            setFetchingDataCreatePlan(true);
            result = await createIterationPlan({ iterationId: selectedIteration?.id });
          } else if (currentStage === Stage.Planning) {
            await saveDemoPlanByType(selectedIteration?.id, SubPage.Plan);
            await saveAttendanceByType(selectedIteration?.id, SubPage.Plan);
            result = await submitPlan(selectedIteration?.iterationPlan.id);

            notification.success({
              message: `Submit plan successfully`,
              duration: 3,
            });
          } else if (currentStage === Stage.Planned) {
            result = await allowEditIterationPlan(selectedIteration?.iterationPlan.id);
          }
          selectedIteration.iterationPlan = { ...result };
          setSelectedIteration(selectedIteration);
          setStage(result?.status === undefined ? Stage.PrePlan : result.status);
        } catch (error) {
          const err = error?.data as ErrorResponse;
          if (err.error?.validationErrors?.length > 0) {
            openNotification(error.status, err.error.validationErrors?.map((e) => e.message).join('\r\n'));
          } else {
            openNotification(error.status, err.error?.message);
          }
        } finally {
          setFetchingDataCreatePlan(false);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStage, selectedIteration, demoPlanData]
  );

  const submitReportOnClickHandler = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      (async function () {
        try {
          setChangingReportState(true);
          var result =
            reportStatus === IterationReportStatusEnum.InProgress
              ? await submitIterationReport({ iterationId: selectedIteration?.id })
              : await editIterationReport({ iterationId: selectedIteration?.id });

          selectedIteration.iterationReport = { ...result };

          if (reportStatus === IterationReportStatusEnum.InProgress) {
            notification.success({
              message: `Submit report successfully`,
              duration: 3,
            });

            saveDemoPlanByType(selectedIteration?.id, SubPage.Report);
            saveAttendanceByType(selectedIteration?.id, SubPage.Report);
          }

          setSelectedIteration(selectedIteration);
          setReportStatus(result.status);
        } catch (error) {
          const err = error.data as ErrorResponse;
          if (err.error.validationErrors !== null && err.error.validationErrors?.length > 0) {
            openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
          } else {
            openNotification(error.status, err.error.message);
          }
        } finally {
          setChangingReportState(false);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIteration, reportStatus, setSelectedIteration, setReportStatus, demoPlanData]
  );

  const iterationOptionChange = (value: number) => {
    const iteration = teamIterations?.find((x) => x.id === value);
    setSelectedIteration(iteration);
    if (!iteration.isCurrentSprint) {
      if (iteration.isFutureSprint) {
        setSubPage(SubPage.Plan);
      } else {
        setSubPage(SubPage.Report);
      }
    } else {
      setSubPage(SubPage.Monitoring);
    }
  };

  // Create Report button on click event
  const createReportOnClickHandler = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      (async function () {
        try {
          setFetchingDataCreateReport(true);
          var result = await createIterationReport({
            iterationId: selectedIteration?.id,
          });
          selectedIteration.iterationReport = { ...result };
          setSelectedIteration({ ...selectedIteration });
          setReportStatus(result.status);
          iterationOptionChange(selectedIteration.id);
          setSubPage(SubPage.Report);
        } catch (error) {
          const err = error.data as ErrorResponse;
          if (err.error.validationErrors !== null && err.error.validationErrors?.length > 0) {
            openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
          } else {
            openNotification(error.status, err.error.message);
          }
        } finally {
          setFetchingDataCreateReport(false);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIteration, setSelectedIteration]
  );

  const saveNoteHandler = useCallback(async (iterationNote: IIterationNote): Promise<void> => {
    try {
      if (iterationNote?.id) {
        const result = await updateIterationNote(iterationNote);
        setIterationNotes((prevNotes) => [...prevNotes.filter((n) => n.id !== iterationNote.id), result]);
      } else {
        const result = await createIterationNote(iterationNote);
        setIterationNotes((prevNotes) => [...prevNotes, result]);
      }
      var iterationNotesAllReport = await getAllNoteByPage(iterationNote.pageType);
      setIterationNotesAll(iterationNotesAllReport);
      notification.success({
        message: 'Note updated successfully',
        duration: 3,
      });
    } catch (error) {
      const message = error?.data?.error?.validationErrors[0]?.message ?? 'Something went wrong';
      notification.error({
        message: message,
        duration: 3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const StyledPlanSubmitButtonWithAuthz = authzHoc(StyledPlanSubmitButton, 'create', 'submit_plan', 'allow_edit');

  const exportReportHandler = async (exportSections, exportType: ExportType) => {
    let exportedSubSections = [];
    const classHideElement = 'hide-element';
    const classShowElement = 'show-element';
    // Call api update record of DisplayComponentItems Table
    const request = {
      userId: user.id,
      value: JSON.stringify(exportSections),
      displayType: DisplayType.ExportReportForm,
    };
    if (exportSection === '') {
      await createDisplayComponent(request);
    } else {
      await updateDisplayComponent(request);
    }
    Object.entries(exportSections).forEach(([key, value]: [string, string[]]) => {
      if (value.length === 0) {
        document.getElementById(key).setAttribute('data-html2canvas-ignore', 'true');
      } else {
        exportedSubSections = [...exportedSubSections, ...value];
        const element = document.getElementById(key);
        if (element.classList.contains(classHideElement)) {
          element.classList.add(classShowElement);
        }
      }
    });

    const unExportedSubSections = exportOptions
      .flatMap((s) => s.options)
      .map((s) => s.value)
      .filter((v) => !exportedSubSections.includes(v));

    unExportedSubSections.forEach((s) => {
      document.getElementById(s).setAttribute('data-html2canvas-ignore', 'true');
    });

    await exportReport(exportType);

    Object.entries(exportSections).forEach(([key, value]: [string, string[]]) => {
      if (value.length === 0) {
        document.getElementById(key).removeAttribute('data-html2canvas-ignore');
      } else {
        const element = document.getElementById(key);
        if (element.classList.contains(classShowElement)) {
          element.classList.remove(classShowElement);
        }
      }
    });

    unExportedSubSections.forEach((s) => {
      document.getElementById(s).removeAttribute('data-html2canvas-ignore');
    });
  };

  const exportReport = async (exportType: ExportType, isExportAll?: boolean) => {
    setExportProcessing(true);
    const exportDiv = document.querySelector('.content') as HTMLElement;
    try {
      if (isExportAll) {
        exportDiv.querySelectorAll('.hide-element').forEach((item) => {
          item.classList.add('show-element');
        });
      }

      let fileName;
      if (page === SubPage.Plan) {
        fileName = 'Iteration_Plan_' + team?.name.split(' ').join('') + '_' + selectedIteration.name;
      } else {
        fileName = 'Iteration_Report_' + team?.name.split(' ').join('') + '_' + selectedIteration.name;
      }

      const canvas = html2canvas(exportDiv, {
        allowTaint: true,
        useCORS: true,
        logging: true,
        scale: 1,
        onclone: async (clonedDocument) => {
          document.querySelectorAll('img').forEach((image) => {
            image.crossOrigin = 'anonymous';
          });
          replaceRealSgvOnExport('#carried-keep', clonedDocument);
          replaceRealSgvOnExport('#carried-over-icon', clonedDocument);
          handleTextAreaExport(clonedDocument);
        },
      });

      switch (exportType) {
        case ExportType.PDF:
          //Process into PDF on server
          await downloadReportExportPDF((await canvas).toDataURL(), fileName + '.pdf', team.id);
          break;
        case ExportType.Image:
          //Save directly as image
          savePictureAs((await canvas).toDataURL('image/png'), fileName + '.png');
          break;
      }
    } catch (error) {
      notification.error({
        message: `Report Export: Client error`,
        duration: 3,
      });
    } finally {
      if (isExportAll) {
        exportDiv.querySelectorAll('.show-element').forEach((item) => {
          item.classList.remove('show-element');
        });
      }
      setExportProcessing(false);
    }
  };

  function replaceRealSgvOnExport(id: string, clonedDocument: Document) {
    const documentSVG = document.querySelector(id);
    const replacedSVG = clonedDocument.querySelector(id);
    if (documentSVG && replacedSVG) {
      replacedSVG.outerHTML = documentSVG.outerHTML;
    }
  }

  function handleTextAreaExport(clonedDocument: Document) {
    //replace all clonedDocument textarea to normal div to fix html2canvas textarea bug
    const documentTextarea = document.querySelectorAll('textarea');
    const replacedTextarea = clonedDocument.querySelectorAll('textarea');
    replacedTextarea.forEach((textArea, index) => {
      if ((textArea as any).ariaHidden) return;

      const card = textArea.parentElement as HTMLDivElement;
      card.style.justifyContent = 'flex-start';
      card.style.width = '248px';
      card.style.backgroundColor = '#fff';

      const div = clonedDocument.createElement('div');
      div.innerText = textArea.value;
      div.style.width = documentTextarea[index].style.width === '' ? '218px' : documentTextarea[index].style.width;
      div.style.height = documentTextarea[index].style.height === '' ? '98px' : documentTextarea[index].style.height;
      div.style.overflowY = 'scroll';
      card.prepend(div);
      textArea.style.display = 'none';
    });
  }

  function savePictureAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;

      //Firefox requires the link to be in the body
      document.body.appendChild(link);

      //simulate click
      link.click();

      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  return (
    <>
      <Helmet>
        <title>ADM Tool | Team Details</title>
      </Helmet>
      {showChartGeneratedAlert && (
        <StyledAlert
          message="New data has been received, click the button to reload charts"
          showIcon
          banner
          type="warning"
          onClose={() => setShowChartGeneratedAlert(false)}
          action={
            <Space>
              <Button
                onClick={() => {
                  forceRefetchIterationData();
                  setShowChartGeneratedAlert(false);
                }}
                size="middle"
                type="ghost"
              >
                Refresh
              </Button>
            </Space>
          }
          closable
        />
      )}
      <Row style={{ alignItems: 'center' }} gutter={[16, 16]}>
        <Col lg={15} xs={24}>
          <PageTitle className="page-title" level={5}>
            {team?.name.toUpperCase()}
          </PageTitle>
          <Space wrap size={[8, 16]}>
            <StyledTag>{team?.model ?? 'TBD'}</StyledTag>

            {page !== SubPage.Overview && (
              <Select onChange={iterationOptionChange} value={selectedIteration?.id} style={{ width: 120 }}>
                {teamIterations?.map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    label={item.name}
                    className={getClassNameForSprint(item.dateFrom, item.dateTo)}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
            {(page === SubPage.Monitoring || page === SubPage.Overview) &&
              (isAuthorized || userRole === roleNames.pqa) && (
                <SyncButton
                  renderStyle="button"
                  showSyncSpecificDate={true}
                  syncStatus={syncStatus ?? initialSyncStatus?.status}
                  syncAction={(syncDate: Date) => {
                    triggerSync(team.id, { syncDate });
                  }}
                />
              )}

            {(currentStage === Stage.Planned || reportStatus === IterationReportStatusEnum.Submitted) &&
              isAuthorized && (
                <StyledSubmittedTag icon={<Icon component={SendCheckIcon} />}>Submitted</StyledSubmittedTag>
              )}

            {/* If activePage is Plan page and currentStage value is less than Stage.Planned */}
            {allowEdit && currentStage !== Stage.Planned && page === SubPage.Plan && (
              <StyledPlanSubmitButton
                disabled={fetchingDataCreatePlan}
                size="middle"
                className={`create-button ${currentStage === Stage.Planning ? 'warning' : 'primary'}`}
                icon={
                  currentStage === Stage.PrePlan ? (
                    <PlusOutlined />
                  ) : currentStage === Stage.Planning ? (
                    <Icon component={SendIcon} />
                  ) : (
                    ''
                  )
                }
                onClick={submitPlanOnClickHandler}
                data-html2canvas-ignore
              >
                {currentStage === Stage.PrePlan && <>Create Plan</>}
                {currentStage === Stage.Planning && <>Submit Plan</>}
              </StyledPlanSubmitButton>
            )}

            {currentStage === Stage.Planned &&
              permissions.some((userPerm) => userPerm === 'iteration:plan::allow_edit') && (
                <StyledPlanSubmitButton
                  disabled={fetchingDataCreatePlan}
                  size="middle"
                  className={`create-button edit`}
                  onClick={submitPlanOnClickHandler}
                  data-html2canvas-ignore
                >
                  <>Enable Editing for SM</>
                </StyledPlanSubmitButton>
              )}

            {currentStage === Stage.Planned && (
              <Dropdown.Button
                className="export-button"
                type="ghost"
                disabled={exportProcessing}
                icon={<DownOutlined />}
                overlay={exportMenuItems}
                data-html2canvas-ignore
              >
                {exportProcessing ? <Spin size="small" style={{ marginRight: '10px' }} /> : <DownloadOutlined />}
                Download Report
              </Dropdown.Button>
            )}
            {/* Show create report button */}
            {isAuthorized && reportStatus === IterationReportStatusEnum.None && (
              <StyledPlanSubmitButton onClick={createReportOnClickHandler} disabled={fetchingDataCreateReport}>
                {fetchingDataCreateReport && <>Creating Report...</>}
                {!fetchingDataCreateReport && (
                  <>
                    <PlusOutlined /> Create Report
                  </>
                )}
              </StyledPlanSubmitButton>
            )}
            {isAuthorized && reportStatus === IterationReportStatusEnum.InProgress && (
              <StyledPlanSubmitButton
                className={`create-button warning`}
                icon={<Icon component={SendIcon} />}
                onClick={submitReportOnClickHandler}
                disabled={changingReportState}
                data-html2canvas-ignore
              >
                {changingReportState ? <>Please wait...</> : <>Submit Report</>}
              </StyledPlanSubmitButton>
            )}
            {(userRole === roleNames.admin || userRole === roleNames.pqa) &&
              reportStatus === IterationReportStatusEnum.Submitted && (
                <StyledPlanSubmitButton
                  className={`create-button edit`}
                  onClick={submitReportOnClickHandler}
                  disabled={changingReportState}
                  data-html2canvas-ignore
                >
                  {changingReportState ? <>Please wait...</> : <>Enable Editing for SM</>}
                </StyledPlanSubmitButton>
              )}

            {reportStatus === IterationReportStatusEnum.Submitted && (
              <DownloadReport exportReportHandler={exportReportHandler} exportProcessing={exportProcessing} />
            )}

            <DisplayComponents />
          </Space>
        </Col>

        <Col
          lg={9}
          xs={24}
          data-html2canvas-ignore
          className="gutter-row team-detail-tab"
          style={{ marginLeft: 'auto' }}
        >
          <Radio.Group
            options={pagesOptions}
            onChange={onChangeTeamType}
            value={page}
            optionType="button"
            buttonStyle="solid"
            data-html2canvas-ignore
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '1.5em', alignItems: 'center', marginTop: '0.5rem' }} gutter={16}>
        <Col className="gutter-row">
          {team?.scrumMaster && team?.scrumMaster?.email && (
            <Space>
              <Icon component={UserProfileIcon} />
              <span style={{ color: '#024fa5' }}>
                {'Scrum Master: '}
                {team.scrumMaster.customerEmail ? team.scrumMaster.customerEmail : team.scrumMaster.email}
              </span>
            </Space>
          )}
        </Col>
      </Row>
      <Row>
        {selectedIteration?.id !== undefined && (
          <Col style={{ minWidth: '100% ' }}>
            {page === SubPage.Overview && <TeamOverview />}
            {page === SubPage.Plan && (
              <Plan stage={currentStage} iterationId={selectedIteration?.id} saveNoteHandler={saveNoteHandler} />
            )}
            {page === SubPage.Monitoring && selectedIteration.isCurrentSprint && (
              <Monitoring stage={currentStage} page={SubPage.Monitoring} />
            )}
            {page === SubPage.Report && !selectedIteration.isFutureSprint && (
              <Report
                stage={currentStage}
                showReport={selectedIteration.iterationReport !== null}
                saveNoteHandler={saveNoteHandler}
              />
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

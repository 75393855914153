import { OwnershipScoreCategoryChart } from 'app/types/Ownership';
import { Bar, BarConfig } from '@ant-design/plots';
import { useMemo, useState } from 'react';
import { Select } from 'antd';
import { OwnershipChartColor, OwnershipColor } from 'app/common/constants';

import './OwnershipScoreByCategoryChart.scss';

interface IProps {
  dataSource: OwnershipScoreCategoryChart;
}

export const OwnershipScoreByCategoryChart = ({ dataSource }: IProps) => {
  const [selectedLevel, setSelectedLevel] = useState<string>('1');
  const chartData = useMemo(() => dataSource ?? [], [dataSource]);
  const selectOptions = Object.keys(chartData).map((k) => ({ label: `Level ${k}`, value: k }));

  const chartConfig: BarConfig = useMemo(() => {
    return {
      data: (chartData[selectedLevel] ?? []).map((x, i) => {
        return { Category: x.categoryName, Score: x.scorePercent, key: i };
      }),
      legend: {
        position: 'top',
        items: [
          {
            name: 'Actual Score',
            value: 'Actual Score',
            marker: {
              symbol: 'circle',
              style: {
                stroke: OwnershipChartColor.barColor,
                fill: OwnershipChartColor.barColor,
              },
            },
          },
          {
            name: 'Total Score',
            value: 'Total Score',
            marker: {
              symbol: 'circle',
              style: {
                stroke: OwnershipColor.notApplicable,
                fill: OwnershipColor.notApplicable,
              },
            },
          },
        ],
      },
      xField: 'Score',
      meta: {
        Score: {
          formatter: (value) => `${value}%`,
        },
      },
      yField: 'Category',
      xAxis: {
        min: 0,
        max: 100,
        tickCount: 5,
        tickInterval: 20,
        line: {
          style: {
            stroke: '#dfdfdf',
          },
        },
        grid: {
          line: {
            style: {
              stroke: 'transparent',
            },
          },
        },
      },
      yAxis: {
        animate: false,
      },
      autoFit: true,
      barWidthRatio: 0.3,
      seriesField: 'Category',
      color: OwnershipChartColor.barColor,
      padding: [60, 10, 50, 110],
      barBackground: {
        style: {
          fill: OwnershipChartColor.barBackground,
        },
      },
    } as BarConfig;
  }, [chartData, selectedLevel]);

  return (
    <div className="chart-wrapper">
      <div className="chart-area" key={selectedLevel}>
        {chartConfig ? <Bar {...chartConfig} key={selectedLevel} /> : null}
      </div>
      <div className="level-select">
        <Select
          size="small"
          onChange={(value) => setSelectedLevel(value)}
          value={selectedLevel}
          options={selectOptions}
        />
      </div>
    </div>
  );
};

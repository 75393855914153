import styled from 'styled-components';

export const StyledPlanSection = styled.div`
  margin-top: 4rem;
  &.space-bottom {
    margin-bottom: 1.5rem;
  }
  &.space-top {
    margin-top: 1.5rem;
  }

  .content-wrapper > .ant-row:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

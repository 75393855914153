import { Option, ProjectItem, ProjectItemRequest } from 'app/types/ProjectManagement';
import { fetchAsync } from '../utils/fetch';

export const fetchProjectLeads = async (): Promise<Option[]> => {
  return fetchAsync('/api/projectgroup/projectLeads', {
    method: 'GET',
  });
};

export const fetchProjectGroupsOption = async (): Promise<Option[]> => {
  return fetchAsync('/api/projectgroup/all', {
    method: 'GET',
  });
};
export const fetchProjectGroups = async (): Promise<ProjectItem[]> => {
  return fetchAsync('/api/projectgroup', {
    method: 'GET',
  });
};
export const createProjectGroups = async (request: ProjectItemRequest): Promise<ProjectItem> => {
  return fetchAsync('/api/projectgroup', {
    method: 'POST',
    body: request,
  });
};
export const updateProjectGroups = async (id: number, request: ProjectItem): Promise<ProjectItem> => {
  return fetchAsync(`/api/projectgroup/update/${id}`, {
    method: 'PUT',
    body: request,
  });
};
export const deleteProjectGroups = async (id: number): Promise<ProjectItem> => {
  return fetchAsync(`/api/projectgroup/delete/${id}`, {
    method: 'DELETE',
  });
};

import { Form, Input, Col, Select, Divider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ColorBadge, TeamWeeklyStatusFormWrapper } from './TeamWeeklyStatusForm.styled';
import { CriticalStatusColor, RiskStatusColor } from 'app/common/constants';
import {
  IssueResponse,
  TagData,
  TeamDetail,
  TeamIssueOverviewMiniCard,
  WeeklyIssueTypeColour,
  WeeklyIssueTypeTitle,
} from 'app/types/WeeklyOverview';
import { createTeamIssueForWeek, updateTeamIssueForWeek } from 'app/apis/reportClient';

type Props = {
  initData?: IssueResponse;
  formForCreate?: boolean; // if true: form create - false: form edit
  setIsCreateIssue: (isCreate: boolean) => void;
  setIsEditIssue: (isEditIssue: boolean) => void;
  resetForm: boolean;
  currentWeek: TeamIssueOverviewMiniCard;
  selectedTeamCardData: TeamDetail;
  setResetForm: (reset: boolean) => void;
};

export const TeamWeeklyStatusForm = ({
  initData,
  formForCreate,
  setIsEditIssue,
  setIsCreateIssue,
  setResetForm,
  resetForm,
  currentWeek,
  selectedTeamCardData,
}: Props) => {
  const onFinish = async (values: any) => {
    const result: IssueResponse = {
      action: values.action,
      cause: values.cause,
      name: values.name,
      highlight: values.highlight,
      teamId: selectedTeamCardData.id,
      reportDate: currentWeek?.reportDate,
      teamWeeklyStatusId: currentWeek?.teamWeeklyStatusId,
      id: initData?.id || 0,
      type: values.type,
    };
    if (formForCreate) {
      await createTeamIssueForWeek(result);
    } else await updateTeamIssueForWeek(result);

    setIsEditIssue(false);
    setIsCreateIssue(false);
    setResetForm(!resetForm);
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const defaultType: TagData = {
    type: WeeklyIssueTypeColour.Critical,
    typeTitle: WeeklyIssueTypeTitle.Resource,
  };
  return (
    <Col span={24}>
      <TeamWeeklyStatusFormWrapper
        colon={false}
        {...layout}
        labelAlign="left"
        id="TeamWeeklyStatusForm"
        onFinish={onFinish}
        autoComplete="off"
        className="manheim-form-wrapper"
        initialValues={{
          name: initData?.name || '',
          type: initData?.type || defaultType,
          cause: initData?.cause || '',
          action: initData?.action || '',
          highlight: initData?.highlight || '',
        }}
      >
        <Form.Item label="Issue" name="name" rules={[{ required: true, message: 'Missing Issue Name' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Type" className="parent-form-item" required>
          <Input.Group compact>
            <Form.Item noStyle name={['type', 'type']} rules={[{ required: true, message: 'Missing Type Level' }]}>
              <Select style={{ minWidth: '23%', marginRight: '2%' }}>
                <Select.Option value={WeeklyIssueTypeColour.Critical}>
                  <ColorBadge color={CriticalStatusColor} />
                </Select.Option>
                <Select.Option value={WeeklyIssueTypeColour.Risk}>
                  <ColorBadge color={RiskStatusColor} />
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle name={['type', 'typeTitle']} rules={[{ required: true, message: 'Missing Type Name' }]}>
              <Select style={{ minWidth: '75%' }}>
                <Select.Option value={WeeklyIssueTypeTitle.Resource}>Resource</Select.Option>
                <Select.Option value={WeeklyIssueTypeTitle.Delivery}>Delivery</Select.Option>
                <Select.Option value={WeeklyIssueTypeTitle.Quality}>Quality</Select.Option>
                <Select.Option value={WeeklyIssueTypeTitle.Process}>Process</Select.Option>
                <Select.Option value={WeeklyIssueTypeTitle.Cost}>Cost</Select.Option>
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item label="Root Cause" name="cause">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Action" name="action">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item label="Highlight" name="highlight">
          <TextArea rows={3} />
        </Form.Item>

        <Divider className="fields-divider" />
      </TeamWeeklyStatusFormWrapper>
    </Col>
  );
};

import { OwnershipLevelColors, OwnershipLevelTitle } from 'app/common/constants';
import { AuditedEntity } from './entity';

export interface OwnershipStatusResponse {
  rankingOverview: OwnershipLevel;
  monthlyHighlight: OwnershipMonthlyHighlight;
  scoreByLevels: OwnershipScoreLevel[];
  scoresByCategoryChart: OwnershipScoreCategoryChart;
  scoreTrendChartItems: OwnershipScoreTrendItem[];
  statusDetails: OwnershipStatusDetailItem[];
}

export interface OwnershipRankingOverview {
  value: number;
  percentChange: number;
}

export interface OwnershipScoreLevel {
  level: OwnershipLevel;
  scorePercent: number;
  scoreTarget: TargetResponse;
  scoreType: TargetType;
  maxScore: number;
  actualScore: number;
}

export interface OwnershipScoreCategoryChart extends Record<string, OwnershipScoreCategory[]> {}

export interface OwnershipScoreCategory {
  categoryName: string;
  scorePercent: number;
  scoreTarget: number;
}

export interface OwnershipScoreTrendItem {
  scoreTarget: TargetResponse;
  actualScore: number;
  maxScore: number;
  scoreType: TargetType;
  scorePercent: number;
  itemDate: string;
  ownershipLevel: number;
}

export interface OwnershipCriteriaDetail {
  level: number;
  criteriaDetail: string;
  description: string;
}

export interface OwnershipStatusDetailItem {
  /**id is not a db entity ID. It is only here as a requirement for EditableTable component */
  id: number;
  teamId: number;
  teamCategoryId: number;
  categoryName: string;
  teamCriteriaId: number;
  criteriaId: number;
  criteriaName: string;
  levelDetails: OwnershipCriteriaDetail[];
  selfEvaluationId: number;
  selfEvaluation: number;
  evaluationChange: OwnershipDetailEvaluationChange;
  actionToImprove: string;
}

export interface OwnershipMonthlyHighlight {
  monthlyHighlightId: number;
  highlightDate: Date;
  highlightNote: string;
  teamId: number;
}

export interface OwnershipUpdateStatusDetailRequest {
  teamCriteriaId: number;
  selfEvaluationId: number;
  level: OwnershipLevel;
  actionToImprove: string;
  levelString: string;

  dateId?: string;
}

export enum OwnershipDetailEvaluationChange {
  None,
  Up,
  Down,
}

export enum HighlightType {
  Ownership,
  Heatmap,
  Overview,
}

export enum OwnershipLevel {
  None,
  Level_0,
  Level_1,
  Level_2,
  Level_3,
  Level_4,
  NotYet,
}

export interface CategoryModel {
  name: string;
  themeColor: string;
  order: number;
}
export interface CategoryResponse extends AuditedEntity {
  name: string;
  themeColor: string;
  order: number;
}
export interface CriteriaModel {
  name: string;
  categoryId: number;
}
export interface CriteriaResponse extends AuditedEntity {
  name: string;
  categoryId: number;
  order?: number;
}
export interface CriteriaDetailModel {
  criteriaId: number;
  level: OwnershipLevel;
  description: string;
  detail: string;
  point: number;
  isPublished: boolean;
  categoryId?: number;
}
export interface CriteriaDetailResponse extends AuditedEntity {
  criteriaId: number;
  level: number;
  description: string;
  detail: string;
  point: number;
  isPublished: boolean;
  categoryId?: number;
}

export type TargetType = typeof TargetType[keyof typeof TargetType];
export const TargetType = {
  Percentage: 0,
  Score: 1,
} as const;

export interface TargetDto {
  teamId: number;
  date: Date;
  targetType: TargetType;
  items: TargetResponse[];
}

export interface TargetResponse {
  id: number;
  level: OwnershipLevel;
  from: number;
  to?: number;
}

export const OwnershipLevelOption = [
  {
    name: OwnershipLevelTitle.level_1,
    level: OwnershipLevel.Level_1,
  },
  {
    name: OwnershipLevelTitle.level_2,
    level: OwnershipLevel.Level_2,
  },
  {
    name: OwnershipLevelTitle.level_3,
    level: OwnershipLevel.Level_3,
  },
  {
    name: OwnershipLevelTitle.level_4,
    level: OwnershipLevel.Level_4,
  },
];

export const getOwnershipLevelTitle = (type) => {
  if (type < 0) {
    return 'None';
  }
  return `Level ${type}`;
};

export const getOwnershipLevelNumber = (lv: number) => {
  if (lv < 0) {
    return '-';
  }
  return lv;
};

export const getOwnershipLevelColor = (lv: number) => {
  if (lv < 0) return '#D9D9D9';

  return OwnershipLevelColors[lv % OwnershipLevelColors.length];
};

import { Collapse, Divider, Form, Input, Row } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { isEmpty } from 'lodash';
import { OwnershipDetailInfoWrapper } from './OwnershipStatusDetail.styled';
import { OwnershipHeatmapModalDetail } from 'app/types/OwnershipHeatmap';
import { definedColorSatisfaction, evaluationTitle } from './OwnershipHelper';
import moment from 'moment';

type Props = {
  data: OwnershipHeatmapModalDetail;
};
const defaultEditorValues =
  '{"blocks":[{"key":"6nr2t","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';
export const OwnershipDetailInfo = ({ data }: Props) => {
  const { Panel } = Collapse;
  return (
    <OwnershipDetailInfoWrapper requiredMark={false}>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Self-evaluation" key="1">
          <Form.Item
            labelAlign="left"
            labelCol={{
              span: 8,
            }}
            label="Client Satisfaction"
          >
            {definedColorSatisfaction(data?.clientSatisfaction, 'client').title}
          </Form.Item>
          <Form.Item
            labelAlign="left"
            labelCol={{
              span: 8,
            }}
            label="Ownership Level"
          >
            {definedColorSatisfaction(data?.ownershipLevel, 'level').title}
          </Form.Item>
          <Form.Item
            labelAlign="left"
            labelCol={{
              span: 8,
            }}
            label="SLA Satisfaction Rate"
          >
            {data?.satisfactionRate >= 0
              ? `${data?.satisfactionRate}%`
              : data?.satisfactionRate < -1
              ? 'No Ticket/SLA tracking'
              : 'N/A'}
          </Form.Item>
          <Form.Item
            labelAlign="left"
            labelCol={{
              span: 8,
            }}
            label="# of Prod Issue"
          >
            {data?.productionIssue > 0 ? data?.productionIssue : data?.productionIssue === 0 ? 'No Issue' : 'N/A'}
          </Form.Item>
        </Panel>
        <Panel header="Highlight" key="2">
          <Form.Item name="highlight">
            {data?.highlight && JSON.parse(data?.highlight)?.blocks?.length > 0 ? (
              <Editor
                readOnly={true}
                toolbarHidden={true}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-box"
                toolbarClassName="editor-toolbar"
                defaultContentState={
                  isEmpty(data.highlight) ? JSON.parse(defaultEditorValues) : JSON.parse(data.highlight)
                }
              />
            ) : (
              <Row justify="center" className="default-no-data-text">
                No data
              </Row>
            )}
          </Form.Item>
        </Panel>
        <Panel header="Roadmaps" key="3">
          {data?.roadmaps?.length > 0 ? (
            data.roadmaps.map((item, index) => (
              <div key={index}>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Quarter"
                >
                  <Input.TextArea value={item.quarter} />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Objective"
                >
                  <Input.TextArea value={item.objective} />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Current Status"
                >
                  <Input.TextArea value={item.currentStatus} readOnly />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Evaluation"
                >
                  {evaluationTitle(item.evalution).title}
                </Form.Item>
                {index < data.roadmaps.length - 1 && <Divider />}
              </div>
            ))
          ) : (
            <Row justify="center" className="default-no-data-text">
              No data
            </Row>
          )}
        </Panel>
        <Panel header="Action" key="4">
          {data?.actions?.length > 0 ? (
            data.actions.map((item, index) => (
              <div key={index}>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Due date"
                >
                  {moment(item.dueDate).format('MMM DD, YYYY')}
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Key result"
                >
                  <Input.TextArea value={item.keyResult} readOnly />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Current Status"
                >
                  <Input.TextArea value={item.currentStatus} readOnly />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="PIC"
                >
                  {item.personInCharge}
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  label="Evaluation"
                >
                  {evaluationTitle(item.evalution).title}
                </Form.Item>
                {index < data.actions.length - 1 && <Divider />}
              </div>
            ))
          ) : (
            <Row justify="center" className="default-no-data-text">
              No data
            </Row>
          )}
        </Panel>
      </Collapse>
    </OwnershipDetailInfoWrapper>
  );
};

import styled from 'styled-components';

export const CommitEditButtonWrapper = styled.div`
  .ant-tag {
    cursor: pointer;
    opacity: 0.5;

    &.is-selected {
      opacity: 1;
    }
  }
`;

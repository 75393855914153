export const ADMFooter = () => {
  return (
    <>
      <div className="page-footer">
        Copyright © 2022 <a href="https://www.fpt-software.com/">FPT</a>. All rights reserved
      </div>
    </>
  );
};

export default ADMFooter;

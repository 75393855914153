import styled from 'styled-components';

export const StyledProgramKanbanOverview = styled.div`
  .ant-card-bordered {
    box-shadow: unset;
  }
  .ant-card-head-title {
    text-align: center;
  }
  .custom-title {
    font-weight: bold;
  }
  .custom-item-pass {
    border-radius: 50%;
    font-size: 14px;
    padding: 3px 9px;
    background-color: green;
    color: white;
  }
  .custom-item-fail {
    margin-left: 5px;
    border-radius: 50%;
    font-size: 14px;
    padding: 3px 9px;
    background-color: red;
    color: white;
  }
  .ant-card-head-title {
    padding: 0px !important;
  }
  .ant-card-head {
    padding: 0px;
    background-color: rgb(164, 213, 255);
    a {
      padding: 8px 0;
      color: black;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .ant-card-head:hover {
    background-color: #1890ff;
    a {
      color: white !important;
    }
  }
  .ant-card-body {
    background-color: rgb(241, 248, 255);
  }
  .spin {
    margin-top: 20px;
    padding: auto;
    text-align: center;
  }
`;
export const CardItem = styled.div`
  margin: 10px 0 14px 8px;
  display: flex;
  justify-content: space-between;
`;

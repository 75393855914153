import React, { useState } from 'react';
import { Image } from 'antd';
import { StyledImagesPreview } from './StyledImagesPreview';
import { FileImageFilled } from '@ant-design/icons';
import { IComplimentImage } from 'app/types/entity';

interface IProps {
  data: IComplimentImage[];
}

export const ImagesPreview = ({ data }: IProps) => {
  const [openReview, setOpenReview] = useState<boolean>(false);
  return (
    <div>
      {data.length > 0 && (
        <StyledImagesPreview>
          <FileImageFilled
            onClick={() => setOpenReview(!openReview)}
            style={{
              color: '#1890ff',
            }}
          />
          <Image.PreviewGroup
            preview={{
              visible: openReview,
              onVisibleChange: (value) => {
                setOpenReview(value);
              },
              maskProps: {
                display: 'none',
              },
            }}
          >
            {data.map((img, index) => (
              <Image key={index} src={img.href} className="img-preview" />
            ))}
          </Image.PreviewGroup>
        </StyledImagesPreview>
      )}
    </div>
  );
};

import { RefAttributes, useContext, useEffect, useMemo, useState } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Col, Empty, Radio, Row } from 'antd';
import { SelectOptions } from 'app/types/entity';
import _ from 'lodash';
import { PlanCapacity, GroupedTeamPlanData, GroupedTeamPlanCapacityChartStatistic } from 'app/types/GroupedTeamModel';
import { PlannedCapacityColor, TotalCapacityColor } from 'app/common/constants';
import { TeamParentContext } from 'app/contexts/TeamParentContext';

export const PlanGroupedTeamCapacity = () => {
  const [capacities, setCapacities] = useState<PlanCapacity[]>();
  const [capacityStatistic, setCapaciyStatistic] = useState<GroupedTeamPlanCapacityChartStatistic>();
  const { iterationData, loading } = useContext(TeamParentContext);

  useEffect(() => {
    setCapacities(iterationData?.planData?.planCapacity);
    setCapaciyStatistic(iterationData?.planData?.planCapacityStatistic);
  }, [iterationData]);

  const capacitySummary = useMemo(() => {
    // Use .slice to get last 3 velocities (assume it's ASC sorting)
    const data = capacities?.filter((p) => p.state === 'Planned Capacity').slice(-3) || [];
    const avg = _.mean(data.map((dt: PlanCapacity) => dt.point | 0));
    const temp: SelectOptions<any>[] = [
      {
        label: `Last 3: ${Math.round(avg * 10) / 10} hours`,
        value: 1,
      },
      {
        label: `Best 3: ${capacityStatistic?.best3} hours `,
        value: 2,
      },
      {
        label: `Worst 3: ${capacityStatistic?.worst3} hours`,
        value: 3,
      },
    ];
    return temp;
  }, [capacities]);

  const config = useMemo(() => {
    const data = capacities?.map((v) => ({ code: v.code, value: v.point, state: v.state })) || [];

    const config: ColumnConfig & RefAttributes<PlanCapacity> = {
      data: data,
      xField: 'code',
      yField: 'value',
      // renderer: 'svg',
      seriesField: 'state',
      columnWidthRatio: 0.5,
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      yAxis: {
        title: {
          text: 'Hours',
          position: 'center',
          spacing: 20,
          style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
        },
      },
      autoFit: true,
      meta: {
        code: {
          alias: 'Iteration Name',
        },
        value: {
          alias: 'Planned Capacity',
        },
      },
      legend: false,
      color: [TotalCapacityColor, PlannedCapacityColor],
      loading: loading,
    };
    return config;
  }, [capacities]);

  return (
    <Row>
      <Col span={24}>
        <div className="container">
          <Row className="" justify="center">
            <Radio.Group options={capacitySummary} optionType="button" buttonStyle="solid" />
          </Row>
          <Row style={{ marginTop: '25px' }}>
            <Col span={24}>
              <Column {...config} />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

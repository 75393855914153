import { IterationGoalStatus } from 'app/components/IterationGoals/IterationGoal';
import { TopPerformerRank } from 'app/components/TopPerformers/TopPerformers';
import { SubPage } from './TeamDetail';

export interface Entity {
  id: number;
}

export interface PagedRequest {
  pageSize?: number;
  pageIndex?: number;
}

export interface AuditedEntity extends Entity {
  lastModificationTime: string;
  lastModifierUserId: number;
  creationTime: string;
  creatorUserId: number;
}

export interface PagedRequest {
  pageSize?: number;
  pageIndex?: number;
}

export interface PageResult<T> {
  [x: string]: any;
  totalCount: number;
  items: T[];
}

export interface PageRequest {
  currentPage?: number;
  pageSize?: number;
  sortDirection?: 'ascend' | 'descend' | '';
  sortColumn?: string;
}
export interface SelectOptions<T = string> {
  value: T;
  label: string;
}

export enum ActionStatus {
  'Done',
  'Active',
}

export enum RetrospectiveType {
  'PreviousIteration',
  'Well',
  'Bad',
  'Todo',
}

export interface RetrospectiveNoteRequest extends Entity {
  iterationId: number;
  content: string;
  status: ActionStatus;
  type: RetrospectiveType;
  personalInCharge: number;
  deadline?: Date;
}

export interface RetrospectiveNoteResponse extends Entity {
  iterationId: number;
  content: string;
  personalInCharge: number;
  personalInChargeName: string;
  deadline: Date;
  status: ActionStatus;
  type: RetrospectiveType;
  lastModifiedUserName: string;
}

export interface QuarterReleaseNote extends Entity {
  teamId: number;
  content: string;
  quarterName: string;
  featureCode: string;
}

export interface CreateIterationGoal {
  name: string;
  notePlan: string;
  noteMonitor: string;
  noteReport: string;
  iterationId: number;
}

export interface IterationGoalResponse extends Entity {
  name: string;
  notePlan: string;
  noteMonitor: string;
  noteReport: string;
  iterationId: number;
  status: IterationGoalStatus;
}

export interface IterationGoalRequest extends Entity {
  name: string;
  notePlan: string;
  noteMonitor: string;
  noteReport: string;
  iterationId: number;
  status: IterationGoalStatus;
  subPage: SubPage;
}
export interface IComplimentRequest {
  id?: number;
  iterationId: number;
  title: string;
  message: string;
  from: string;
  complimentImages?: IComplimentImage[];
  uploadImages?: File[];
}

export interface IComplimentImage {
  id: number;
  fileName: string;
  href: string;
}

export interface IComplimentResponse {
  id?: number;
  iterationId: number;
  iterationName?: string;
  title: string;
  message: string;
  from: string;
  complimentImages?: IComplimentImage[];
}

export interface CustomReportResponse {
  id?: number;
  iterationId: number;
  url?: string;
  title: string;
  note: string;
  reportType: number;
  reportMainData?: string;
  unit?: number;
}
export interface CustomReportRequest {
  id?: number;
  url?: string;
  iterationId: number;
  uploadImages: File;
  title: string;
  note: string;
  reportType: number;
  reportMainData?: string;
  unit?: number;
}

export enum TeamStatus {
  Todo = 0,
  InProgress = 1,
  Done = 2,
}
export interface ITeamStatus {
  iterationName: string;
  teamId: number;
  status: TeamStatus;
}
export interface IIterationOverviewRequest {
  iterationName: string;
}
export interface IIterationOverviewResponse {
  plan: ITeamStatus[];
  report: ITeamStatus[];
}

export interface TopPerformerResponse {
  id: number;
  fullName: string;
  memberId: number;
  rank: TopPerformerRank;
  iterationId: number;
  caption: string;
  avatarUrl: string;
}

export interface TopPerformerRequest {
  id: number;
  memberId: number;
  rank: TopPerformerRank;
  iterationId: number;
  caption: string;
  avatarUpload?: File;
}
export interface TopPerformerSelectOptionResponse {
  memberId: number;
  teamId: number;
  fullName: string;
  avatarUrl: string;
}

export interface IQualitySummaryResponse {
  unitTestCoverage: number;
  numberLineOfCode: number;
  defectRate: number;
  defects: number;
  userStories: number;
}
export interface IQualitySummaryRequest {
  unitTestCoverage: number;
  numberLineOfCode: number;
}

export enum TypeProgress {
  ProgressBar = 0,
  SegmentBar = 1,
}

export interface TeamHighLightRequest {
  id?: number;
  iterationId: number;
  highlightType: SubPage;
  highlight: string;
  riskIssue: string;
  blocker: string;
  dependency: string;
}

export interface TeamHighLightResponse extends Entity {
  iterationId: number;
  highlightType: SubPage;
  highlight: string;
  riskIssue: string;
  blocker: string;
  dependency: string;
}

export interface DefectChartByStateResponseItem {
  state: string;
  value: number;
  iteration: string;
}

export interface DefectChartByCategoryResponseItem {
  state: string;
  value: number;
}

export interface DefectChartByStateResponse {
  note: string;
  data: DefectChartByStateResponseItem[];
}

export interface DefectChartByCategoryResponse {
  note: string;
  data: DefectChartByCategoryResponseItem[];
}

import { Alert, Button, Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import styled from 'styled-components';

const TITLE_COLOR = '#09488a';

export const PageTitle = styled(Title)`
  font-weight: bold !important;
  color: ${TITLE_COLOR} !important;
  min-width: fit-content;
`;

export const StyledTag = styled(Tag)`
  min-width: 90px;
  min-height: 30px;
  max-width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff !important;
  color: #0e50a4;
  border: 1px solid #0e50a4;
  font-size: 15px;
  text-align: center;
  margin-right: 10px;
`;

export const StyledSubmittedTag = styled(Tag)`
  min-width: 90px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #389e0d !important;
  color: #ffffff;
  border: 1px solid #389e0d;
  font-size: 15px;
  text-align: center;
  margin-right: 0;
`;

export const StyledPlanSubmitButton = styled(Button)`
  background-color: #389e0d;
  color: #fff;

  &:hover,
  &:focus {
    border-color: #389e0d;
    background-color: #389e0d;
    color: #fff;
  }

  &.edit {
    background-color: #1890ff;
    &:hover,
    &:focus {
      border-color: #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }

  &.warning {
    background-color: #ffc600;

    &:hover,
    &:focus {
      border-color: #ffc600;
      background-color: #ffc600;
      color: #fff;
    }
  }
  &.warning.ant-btn {
    border-radius: 3px !important;
  }
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

import { ICustomEditor } from 'app/types/CustomEditorModel';
import { IImageFileForEditorResponse } from 'app/types/ImageFileForEditor';
import { fetchAsync } from 'app/utils/fetch';

export const uploadImageEditor = (file: any): Promise<IImageFileForEditorResponse> => {
  const formData = new FormData();
  formData.append('uploadedImage', file);

  return fetchAsync('/api/guidelinefaqs/upload-image-editor', {
    type: 'text',
    method: 'POST',
    body: formData,
  });
};

export const createOrUpdateContent = (payload: ICustomEditor): Promise<ICustomEditor> =>
  fetchAsync('/api/guidelinefaqs', {
    method: 'PUT',
    body: payload,
  });

export const getGuidelineFaqs = (type: number): Promise<ICustomEditor> => {
  const url = `/api/guidelinefaqs?type=${type}`;
  return fetchAsync(url, { method: 'GET' });
};

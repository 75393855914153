import { createContext, useContext, useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { TeamDetailContext } from './TeamDetailContext';
import { ITeamParentContext } from 'app/types/team';
import { fetchGroupedTeamDataByIteration } from 'app/apis/groupedTeamClient';
import { GroupedTeamModel } from 'app/types/GroupedTeamModel';

export const TeamParentContext = createContext<ITeamParentContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const TeamParentContextProvider = ({ children }: IProps) => {
  const [selectedIterationName, setSelectedIterationName] = useState<string>('');
  const { team } = useContext(TeamDetailContext);

  const { data: iterationData, loading } = useFetch(() => {
    if (selectedIterationName) {
      return fetchGroupedTeamDataByIteration(selectedIterationName, team?.id);
    }
  }, [selectedIterationName, team?.id]);

  useEffect(() => {}, [selectedIterationName]);

  return (
    <TeamParentContext.Provider
      value={{
        loading,
        selectedIterationName,
        setSelectedIterationName,
        iterationData,
      }}
    >
      {children}
    </TeamParentContext.Provider>
  );
};

import { Drawer } from 'antd';
import styled from 'styled-components';
import './LeftMenu.scss';

export const StyledMenu = styled.div`
  height: 100%;

  .ant-menu-submenu-title {
    .-title-content {
      margin-left: 24px;
    }
  }

  .ant-btn {
    width: 100%;
    text-align: left;
    height: 48px;

    a,
    span {
      font: normal normal normal 16px/21px Segoe UI;
      margin-left: 16px;
    }
  }

  .is-active {
    background-color: rgb(81, 149, 238);
    color: white;
  }
`;

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-weight: bold;
    margin-left: 10px;
  }
`;

import { Drawer, Form, Row } from 'antd';
import styled from 'styled-components';

export const OwnershipDetailDrawerWrapper = styled(Drawer)`
  .ant-drawer-header {
    text-align: center;
    letter-spacing: 0px;
    color: #404040;
    opacity: 1;
    .ant-drawer-title {
      font-size: 25px;
      text-transform: uppercase;
    }
  }
  .ant-drawer-body {
    .modal-body {
      .title-header {
        text-align: left;
        width: fit-content;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        font-weight: bold;
        font-size: 21px;
        border-bottom: 6px #707070 solid;
        p {
          margin-bottom: 10px;
        }
      }
      .btn {
        width: 100px;
        height: 39px;
        &.first {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .default-no-data-text {
    font-style: italic;
    font-weight: 500;
  }
`;

export const OwnershipFormWrapper = styled(Form)`
  .ant-input {
    &.half-width {
      width: 50%;
    }
  }
  .btn-link {
    padding-left: 0;
  }
  .editor-wrapper {
    border: 1px solid #d9d9d9;
  }
  .editor-box {
    padding: 5px 20px;
    min-height: 100px;
  }
`;
export const ModalSectionWrapper = styled(Row)`
  margin: 1rem 0;
  .title {
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 15px;
  }
  .line-content {
    margin-bottom: 0;
  }
  .title-content {
    font-weight: 700;
  }
  .dependencies-list {
    margin: 2rem 0;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .stakeholder-name {
    font-style: italic;
  }
`;
export const CustomLevelCell = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  .level {
    border: 1px solid white;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    position: relative;
    .numb {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const OwnershipDetailInfoWrapper = styled(Form)`
  margin-top: 1rem;
  .editor-wrapper {
    border: none;
  }
  .editor-box {
    padding: 0;
    min-height: 100px;
  }
  label {
    font-weight: 600;
  }
  textarea {
    border: none;
    height: auto;
    overflow: hidden;
    padding: 0;
    resize: none;
  }
`;

export const SelectedRow = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
export const EmptyRow = styled.div`
  width: 100%;
  height: 50px;
  display: block;
  &:hover {
    cursor: pointer;
  }
`;

import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { OwnershipLevelColors } from 'app/common/constants';
import { DotComponent, TailInfo } from 'app/pages/Reports/WeeklyStatus/ReportWeeklyStatus.styled';
import { OwnershipTeamStatusDetail } from 'app/types/OwnershipProgramData';
import { hex2rgba } from 'app/utils/utils';
import { range } from 'lodash';

type Props = {
  teams: OwnershipTeamStatusDetail[];
  setFilter: (value: number) => void;
  value?: number[];
};

export const TeamStatusTailInfo = ({ teams, value, setFilter }: Props) => {
  const minLevel = useMemo(() => (teams.length > 0 ? Math.min(...teams.map((x) => x.level)) : 0), [teams]);
  const maxLevel = useMemo(() => (teams.length > 0 ? Math.max(...teams.map((x) => x.level)) : 0), [teams]);

  // const levelRange = [
  //   ...[minLevel < 0 ? -1 : undefined],
  //   ...[
  //     minLevel !== maxLevel
  //       ? range(minLevel < 0 ? 1 : minLevel, maxLevel + 1)
  //       : minLevel > 0
  //       ? [minLevel]
  //       : [undefined],
  //   ].flatMap((x) => x),
  // ].filter((x) => Number(x));
  const levelRange = range(minLevel, maxLevel + 1);
  const checkValue = (check?: number) => {
    return value.filter((x) => x === check).length > 0;
  };

  const getLevelColor = (lv) => {
    const c = lv < 0 ? '#D9D9D9' : OwnershipLevelColors[lv % OwnershipLevelColors.length];
    return !checkValue(lv) ? c : hex2rgba(c, 0.3);
  };

  return (
    <TailInfo>
      <Row gutter={[20, 10]} justify="end" align="middle" className="tail-info-row">
        {levelRange.map((level, index) => {
          return (
            <Col key={index} onClick={() => setFilter(level)}>
              <DotComponent color={getLevelColor(level)} />
              <span className={`label-text ${checkValue(level) && 'disable'}`}>
                {level < 0 ? 'None' : `Level ${level}`}
              </span>
              <span className={`label-text num ${checkValue(level) && 'disable'}`}>
                ({teams.filter((x) => x.level === level).length})
              </span>
            </Col>
          );
        })}
      </Row>
    </TailInfo>
  );
};

import { Tooltip } from 'antd';
import styled from 'styled-components';

export const StyledCompliment = styled.div`
  .ant-table-cell {
    padding: 8px;
  }

  td.ant-table-cell {
    height: 57px;
  }

  .filter-dropdown {
    position: absolute;
    width: 100%;
    right: 28px;
    top: 15px;
    & > div {
      min-width: 150px;
    }
  }
  @media screen and (max-width: 992px) {
    .compliment {
      .card-title {
        text-align: left;
      }
    }
  }
`;

export const StyledElipsisCellText = styled(Tooltip)`
  & {
    white-space: nowrap;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

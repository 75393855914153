import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { UserRoutes, AdminRoutes, mapRoutesToButtonItems } from 'app/common/routes';

import { StyledMenu, CustomDrawer } from './LeftMenu.styled';

interface IProps {
  isMobileSize: boolean;
  isAdmin: boolean;
  isApproved: boolean;
  subMenuAlignment: 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter';
  dateId: string;
}
export const LeftMenu = ({ isMobileSize, isAdmin, isApproved, subMenuAlignment, dateId }: IProps) => {
  const [collapse, setCollapse] = useState(false);
  const { pathname } = useLocation();

  let MenuItems = [];

  if (isMobileSize) {
    MenuItems = MenuItems.concat(UserRoutes);
  }

  if (isAdmin) {
    MenuItems = MenuItems.concat(AdminRoutes);
  }

  const handleToggleCollapsed = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      {MenuItems.length > 0 && (
        <>
          <Button className="btn-collapse" type="text" onClick={handleToggleCollapsed}>
            <MenuOutlined />
          </Button>

          <CustomDrawer
            title="MENU"
            placement="left"
            maskClosable={true}
            onClose={() => setCollapse(false)}
            visible={collapse}
            bodyStyle={{ padding: '4px' }}
            width={350}
          >
            <StyledMenu>
              {mapRoutesToButtonItems(
                MenuItems,
                '',
                subMenuAlignment,
                'lm-menu',
                (route, parentRoute) => {
                  const {
                    route: { path, relativeWithParent },
                  } = route;

                  if (!parentRoute) return pathname.includes(path);

                  const {
                    route: { path: pathPrefix },
                  } = parentRoute;

                  return pathname.includes(`${relativeWithParent ? pathPrefix : ''}${path}`);
                },
                () => setCollapse(false)
              )}
            </StyledMenu>
          </CustomDrawer>
        </>
      )}
    </>
  );
};

import { FilterTeam } from 'app/common/constants';
import { TeamResponse } from 'app/types/team';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const FilterTeamOwnership = (teamid: number | string, teams: TeamResponse[]) => {
  var team = teams.find((x) => x.id === teamid);
  if (team === null || team === undefined) {
    return false;
  } else {
    var filter = FilterTeam.find(
      (item) => item.toLowerCase().replace(' ', '') === team.name.toLowerCase().replace(' ', '')
    );
    if (filter) {
      return true;
    } else {
      return false;
    }
  }
};

export const isJson = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return isNaN(str);
};

import { TABLE_DEFAULT_PAGE_INDEX, TABLE_DEFAULT_PAGE_SIZE } from 'app/common/constants';
import { Entity, PagedRequest } from './entity';

export interface UsersRequest extends PagedRequest {
  userName?: string;
  email?: string;
  customerEmail?: string;
  userRoles?: string;
  teamRoles?: string;
  fullName?: string;
  isActive?: string;
}

export enum UserStatusEnum {
  NeedToUpdateInfo,
  WaitingForApproval,
  HasBeenRejected,
  Active,
}

export interface CreateUserRequest {
  userName?: string;
  fullName: string;
  email: string;
  customerEmail?: string;
  userRole: string;
  teamRole: number;
  isActive: boolean;
}

export interface FavoriteRequest {
  teamId: Number;
  isFavorite: boolean;
}

export interface UpdateUserRequest {
  fullName: string;
  customerEmail?: string;
  userRole: string;
  teamRole: number;
  isActive: boolean;
}

export interface RejectUserRequest {
  userId: number;
  note: string;
}

export interface UserResponse extends Entity {
  userName: string;
  email: string;
  customerEmail: string;
  fullName: string;
  userRole: string;
  userRoleDisplayName: string;
  teamRole: number;
  avatarUrl?: string;
  reportTo: string[];
  userStatus: UserStatusEnum;
  note: string;
  isActive: boolean;
  defaultTeamId?: number;
  permissions?: string[];
  // This field will need to depend on userRole
  // If it's SM, this will be an array of teamId which he/she have permission on it
  // Otherwise, this is null
  managedTeams?: number[];
  IsSso: boolean;
}

export interface MyUserRequest extends Entity {
  fullName: string;
  customerEmail: string;
  teamRoles?: number;
  reportTo: string[];
  defaultTeamId?: number;
}

export interface UserResponseModel {
  totalRows: number;
  users: UserResponse[];
}

export interface UserModel {
  userName?: string;
  fullName: string;
  email: string;
  customerEmail?: string;
  roles: string;
  teamRoles?: number;
  isActive: boolean;
}

export interface NewUserModel extends UserModel {
  password: string;
}

export interface RoleResponse {
  name: string;
  displayName: string;
  id: number;
}

export interface ChangePasswordModel {
  oldPassword: string;
  newPassword: string;
}

export interface TeamRoleRespone {
  value: string;
  displayName: string;
}

export interface RequestAccessModel {
  fullName: string;
  defaultTeamId: number;
  teamRole: number;
}

export enum UserRole {
  admin = 1,
  user,
  scrum_master,
  pqa,
}

export const initFilterData: UsersRequest = {
  userName: '',
  email: '',
  customerEmail: '',
  fullName: '',
  userRoles: '',
  teamRoles: '',
  isActive: '',
  pageSize: TABLE_DEFAULT_PAGE_SIZE,
  pageIndex: TABLE_DEFAULT_PAGE_INDEX,
};

import { Form, Input, Button, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { resetPasswordMyUser } from '../../apis/userClient';
import { ChangePasswordModel } from '../../types/user';
import { ErrorResponse } from 'app/types/ErrorResponse';
import './ResetPassword.scss';

interface IProps {
  id: number | string;
  isOpenDialog: boolean;
  setVisible: (param: boolean) => void;
}

export const ResetPassword = ({ id, isOpenDialog, setVisible }: IProps) => {
  const openNotification = () => {
    notification.success({
      message: `Change password successfully`,
      duration: 2,
    });
  };

  const onFinish = async (values: ChangePasswordModel) => {
    try {
      await resetPasswordMyUser(values);
      openNotification();
      setVisible(false);
    } catch (error) {
      const errResp = error.data as ErrorResponse;
      if (errResp?.error?.message === 'InvalidOldPassword') {
        notification.error({
          message: `Wrong old password!`,
          duration: 2,
        });
      } else {
        notification.error({
          message: `There is an error`,
          duration: 2,
        });
      }
    }
  };

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <>
      {isOpenDialog && (
        <Form
          style={{ marginTop: '1rem' }}
          {...formItemLayout}
          name="Reset Password"
          colon={false}
          onFinish={onFinish}
          id="myForm"
        >
          <Modal
            className="change-password-modal"
            title="CHANGE PASSWORD"
            visible={isOpenDialog}
            onCancel={() => setVisible(false)}
            centered
            footer={[
              <div key="xxx" style={{ textAlign: 'center', marginLeft: '50px' }}>
                <Button
                  key="cancel"
                  style={{ backgroundColor: 'Gainsboro', width: '100px' }}
                  onClick={() => setVisible(false)}
                >
                  <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Cancel</span>
                </Button>
                <Button
                  form="myForm"
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '100px',
                    backgroundColor: '#7BBB5E',
                  }}
                >
                  <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Change</span>
                </Button>
              </div>,
            ]}
          >
            <Form.Item
              style={{ textAlignLast: 'left' }}
              label="Old password"
              name="oldPassword"
              rules={[
                { required: true, message: 'Please input your old password!' },
                // { min: 6, message: 'The password must be minimum 6 characters.' },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              style={{ textAlignLast: 'left' }}
              label="New password"
              name="newPassword"
              rules={[
                { required: true, message: 'Please input your new password!' },
                { min: 6, message: 'Password must be minimum 6 characters.' },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: '0', textAlignLast: 'left' }}
              label="Confirm password"
              name="verifypassword"
              rules={[
                { required: true, message: 'Please input your confirm password!' },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
              dependencies={['newPassword']}
            >
              <Input.Password />
            </Form.Item>
          </Modal>
        </Form>
      )}
    </>
  );
};

export default ResetPassword;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { EditableColumn } from 'app/components/EditableTable';
import { Card } from 'app/components/Card';
import { Row, Select, Table } from 'antd';
import { ImagesPreview } from '../ImagesPreview/ImagesPreview';
import { StyledCompliment } from './Compliment.styled';
import { useFetch } from 'app/hooks/useFetch';
import { fetchComplimentOverview } from 'app/apis/compliments';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { IComplimentResponse } from 'app/types/entity';
import { getIterationsListNotFuture } from 'app/apis/iterationOverview';

type Option = {
  label: string;
  value: string;
};

export const Compliment = () => {
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<Option[]>([]);

  const [selectedYear, setSelectedYear] = useState<string>('default');

  const { team } = useContext(TeamDetailContext);

  const pageSize = 5;

  const { data: iterationsList } = useFetch<string[]>(() => getIterationsListNotFuture(), []);

  useEffect(() => {
    if (iterationsList && iterationsList.length > 0 && options.length < 1) {
      let opts = [
        {
          value: 'default',
          label: 'YTD',
        },
      ];
      iterationsList.slice(0, 3).forEach((i) => {
        opts.push({
          value: i,
          label: i,
        });
      });
      setOptions(opts);
    }
  }, [iterationsList, options]);

  const { data: complimentData } = useFetch(
    () => fetchComplimentOverview(team?.id, selectedYear),
    [team, selectedYear]
  );

  const columns = useMemo<EditableColumn<IComplimentResponse>[]>(
    () => [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: '50%',
      },
      {
        title: '',
        key: 'images',
        width: '5%',
        align: 'center',
        render: (value, record, index) => <ImagesPreview data={record?.complimentImages} />,
      },
      {
        title: 'From',
        dataIndex: 'from',
        width: '30%',
      },
      {
        title: 'Iteration',
        dataIndex: 'iterationName',
        width: '15%',
        sorter: {
          compare: (a, b) => a.iterationName.localeCompare(b.iterationName),
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize]
  );
  return (
    <StyledCompliment>
      <Card title="5-star Compliments" clasTitleName="compliment" className="compliment">
        <Row justify="end" className="filter-dropdown">
          <Select value={selectedYear} options={options} onChange={(value) => setSelectedYear(value)} />
        </Row>
        <Table
          bordered
          pagination={{
            pageSize,
            current: page,
            hideOnSinglePage: true,
            onChange: (page) => setPage(page),
          }}
          dataSource={complimentData}
          columns={columns}
        />
      </Card>
    </StyledCompliment>
  );
};

import styled from 'styled-components';

export const StyledChart = styled.div`
  .tooltip-card {
    padding: 10px 0;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .title {
      font-weight: 400;
      font-size: 14px;
      text-align: start;
    }

    .value {
      font-weight: 700;
    }
  }
  .throughput-chart-header {
    height: 34px;
  }

  @media screen and (max-width: 1305px) {
    .throughput-chart-header {
      height: auto;
    }
  }
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Helmet } from 'react-helmet';
import { Form, Input, Button, notification } from 'antd';
import { useHistory } from 'react-router';
import { login } from '../apis/authClient';
import { ACCESS_TOKEN_KEY } from '../common/constants';
import { ILoginForm } from '../types/LoginForm';
import { Image, Typography } from 'antd';
import './Login.scss';
import React, { useState } from 'react';
import { ErrorBox } from '../components/ErrorBox';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../common/authConfig';
import { UserStatusEnum } from 'app/types/user';
import { ADMFooter } from '../components/ADMFooter';
import { ErrorResponse } from 'app/types/ErrorResponse';
const { Title } = Typography;

export const Login = () => {
  <Helmet>
    <title>Agile Delivery Management | Login</title>
  </Helmet>;
  const history = useHistory();
  const [hasError, setHasError] = useState<Response>();
  const { instance } = useMsal();
  const onFinish = async (values: ILoginForm) => {
    try {
      const { accessToken, userStatus, teamId, isSso } = await login(values);
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      userStatus === UserStatusEnum.Active
        ? teamId
          ? history.push(`/teams/${teamId}`)
          : history.push(`/dashboard`)
        : history.push({
            pathname: '/getting_started',
            state: { userStatus: userStatus, isSso: isSso },
          });
    } catch (err) {
      setHasError(err);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const onValueChanges = () => {
    setHasError(undefined);
  };

  const handleMicrosoftLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      const err = error.data as ErrorResponse;
      notification.error({
        message: err?.error?.message ?? error.message ?? 'Unknown error',
        duration: 2,
      });
    }
  };

  return (
    <div className="page-login">
      <Helmet>
        <title>Agile Delivery Management | Login</title>
      </Helmet>

      <div className="page-login__background">
        <h1 className="page-login__title">Agile Delivery Management</h1>

        <div
          className="page-login__background-image"
          style={{ backgroundImage: `url(/assets/img/login-background.png)` }}
        />
      </div>

      <div className="page-login-form-wrapper">
        <div className="page-login-branding">
          <Image src="/assets/img/logo-C99-transparent.png" preview={false} width="223px" />

          <Title
            className="page-login-title"
            level={2}
            style={{
              textAlign: 'center',
              marginTop: '42px',
              fontWeight: 'bold',
              color: '#09488A',
            }}
          >
            <Image src="/assets/img/logo-adm.png" preview={false} width="223px" />
          </Title>
        </div>
        <div className="page-login-form-container">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValueChanges}
          >
            <Form.Item>
              <Title level={3} style={{ textAlign: 'center', color: '#09488A' }}>
                Login
              </Title>
              <ErrorBox error={hasError} />
            </Form.Item>

            <Form.Item name="userName" rules={[{ required: true, message: 'Please input your username!' }]}>
              <Input placeholder="User Name" />
            </Form.Item>

            <Form.Item
              className="input-password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item>
              <a href="#">Forgot password?</a>
            </Form.Item>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }}>
                Login
              </Button>
            </Form.Item>
          </Form>
          <Button size="large" type="default" onClick={handleMicrosoftLogin}>
            Sign in with FSOFT Account
          </Button>
        </div>
        <ADMFooter />
      </div>
    </div>
  );
};

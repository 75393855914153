import { Col, Row } from 'antd';
import { Card } from 'app/components/Card';
import { UtilizedCapacity } from 'app/charts/Report/UtilizedCapacity/UltilizedCapacity';
import { IterationScheduleState } from 'app/components/IterationReport/IterationScheduleState';
import { IterationTeamDelivery } from 'app/components/IterationReport/IterationTeamDelivery';
import './GroupedTeamReport.scss';
import { ReportCarriedOver } from 'app/components/GroupedTeamReport/ReportCarriedOver';
import { IterationDetail } from 'app/components/IterationDetail/IterationDetail';
import { ReportType } from 'app/types/CacheReportModel';
import { GroupedTeamModel } from 'app/types/GroupedTeamModel';
import { TeamType } from 'app/types/team';
import { useCallback, useContext } from 'react';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';

interface IGroupedTeamReportProps {
  iteration: GroupedTeamModel;
  type: TeamType;
}

export const GroupedTeamReport = ({ iteration, type }: IGroupedTeamReportProps) => {
  const dataSource = iteration?.reportData;
  const { team } = useContext(TeamDetailContext);
  const filterReport = useCallback(
    (type: ReportType) => {
      const chart = dataSource?.reportChart?.filter((x) => x.type === type);
      if (chart === undefined || chart === null || chart.length < 1) {
        return undefined;
      }
      return chart.at(0).chartObject;
    },

    [dataSource?.reportChart]
  );
  const checkHaveOtherData = dataSource?.reportChart?.some(
    (item) => item.chartObject !== '' && item.chartObject && item.type !== ReportType.GroupedTeamChild
  );
  const chartType = useCallback((type: TeamType) => {
    let chart = [];
    switch (type) {
      case TeamType.ReleaseTrain:
        chart['delivery'] = ReportType.ReleaseTrainDeliveryReport;
        chart['scheduleState'] = ReportType.ReleaseTrainScheduleStateReport;
        chart['capacity'] = ReportType.ReleaseTrainActualCapacityReport;
        chart['burndown'] = ReportType.ReleaseTrainIterationBurndown;
        chart['carriedOver'] = ReportType.ReleaseTrainCarriedOver;
        break;
      case TeamType.DeliveryStream:
        chart['delivery'] = ReportType.DeliveryStreamDeliveryReport;
        chart['scheduleState'] = ReportType.DeliveryStreamScheduleStateReport;
        chart['capacity'] = ReportType.DeliveryStreamActualCapacityReport;
        chart['burndown'] = ReportType.DeliveryStreamIterationBurndown;
        chart['carriedOver'] = ReportType.DeliveryStreamCarriedOver;
        break;
      case TeamType.Portfolio:
        chart['delivery'] = ReportType.PortfolioDeliveryReport;
        chart['scheduleState'] = ReportType.PortfolioScheduleStateReport;
        chart['capacity'] = ReportType.PortfolioActualCapacityReport;
        chart['burndown'] = ReportType.PortfolioIterationBurndown;
        chart['carriedOver'] = ReportType.PortfolioCarriedOver;
        break;
      default:
        break;
    }
    return chart;
  }, []);
  return (
    <div>
      <Row gutter={[24, 16]} className="chart-container">
        <Col xs={24} xxl={8} xl={12} className="delivery-div">
          <Card
            title="Delivery"
            titleTooltip={
              <span>
                Delivery = Total points of &quot;Accepted&quot; and &quot;Released&quot; cards (User stories and
                Defects) / Total points of all cards.
              </span>
            }
          >
            <IterationTeamDelivery
              dataSource={filterReport(chartType(team?.type)['delivery'])}
              checkHaveOtherData={checkHaveOtherData}
            />
          </Card>
        </Col>
        <Col xs={24} xxl={8} xl={12} className="schedule-state-div">
          <Card title="Schedule State" titleTooltip={<span>Shows number of User Stories in each state.</span>}>
            <IterationScheduleState
              dataSource={filterReport(chartType(team?.type)['scheduleState'])}
              checkHaveOtherData={checkHaveOtherData}
            />
          </Card>
        </Col>

        <Col xs={24} xxl={8} xl={12} className="actual-capacity-div">
          <Card
            title="Actual Capacity"
            titleTooltip={<span>Utilized capacity = (Team actual effort / Team total capacity) * 100%</span>}
          >
            <UtilizedCapacity
              dataSource={filterReport(chartType(team?.type)['capacity'])}
              checkHaveOtherData={checkHaveOtherData}
            />
          </Card>
        </Col>
        <Col xs={24} xxl={7} xl={12}>
          <Card className="carried-report-container">
            <ReportCarriedOver
              dataSource={filterReport(chartType(team?.type)['carriedOver'])}
              checkHaveOtherData={checkHaveOtherData}
            />
          </Card>
        </Col>
      </Row>
      <IterationDetail dataSource={dataSource} type={type} />
    </div>
  );
};

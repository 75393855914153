import { Button } from 'antd';
import styled from 'styled-components';

export const TeamTag = styled.span`
  background-color: #ededed;
  padding: 3px 10px;
  margin: 0 8px 2px 0;
  display: inline-block;
  border-radius: 3px;
  &:first-child {
    margin-left: 0;
  }
`;

export const AddProjBtn = styled(Button)`
  height: 40px;
`;

import { useState } from 'react';
import { Button } from 'antd';
import { CheckboxModal } from './CheckboxModal';
import './DisplayComponents.scss';
import EyeIcon from '../EyeIcon';
import Icon from '@ant-design/icons';

export const DisplayComponents = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <Button onClick={showModal} icon={<Icon component={EyeIcon} />}>
        Display
      </Button>

      {isModalVisible ? <CheckboxModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} /> : null}
    </>
  );
};

import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import { ModalSection } from 'app/pages/Statistic/OganizationModal/ModalSection';
import { OwnershipFormWrapper } from './OwnershipStatusDetail.styled';
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  ClientSatisfaction,
  EvaluationProgress,
  OwnershipHeatmapModalDetail,
  // ProductionIssue,
  // SatisfactionRate,
} from 'app/types/OwnershipHeatmap';
import { updateHeatmapDetail } from '../../../apis/ownershipHeatmap';
import { openNotification, openNotificationByType } from 'app/utils/notificationUtils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { OwnershipLevel } from 'app/types/Ownership';
import { useContext, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UserContext } from 'app/contexts/UserContext';
import { roleNames } from 'app/common/constants';

interface Option {
  label: string;
  value: number;
}
const satisfactionOptions: Option[] = [
  {
    label: 'Satisfied',
    value: ClientSatisfaction.Satisfied,
  },
  {
    label: 'Somewhat Satisfied',
    value: ClientSatisfaction.SomewhatSatisfied,
  },
  {
    label: 'Not Satisfied',
    value: ClientSatisfaction.NotSatisfied,
  },
  {
    label: 'Not Applicable',
    value: ClientSatisfaction.NotApplicable,
  },
  {
    label: 'N/A',
    value: ClientSatisfaction.NA,
  },
];
const levelOptions: Option[] = [
  {
    label: '4',
    value: OwnershipLevel.Level_4,
  },
  {
    label: '3',
    value: OwnershipLevel.Level_3,
  },
  {
    label: '2',
    value: OwnershipLevel.Level_2,
  },
  {
    label: '1',
    value: OwnershipLevel.Level_1,
  },
  {
    label: '0',
    value: OwnershipLevel.Level_0,
  },
  {
    label: 'N/A',
    value: OwnershipLevel.None,
  },
];

const evaluationOptions: Option[] = [
  {
    label: 'Not started',
    value: EvaluationProgress.NotStarted,
  },
  {
    label: 'Completed',
    value: EvaluationProgress.Completed,
  },
  {
    label: 'On track',
    value: EvaluationProgress.OnTrack,
  },
  {
    label: 'At risk',
    value: EvaluationProgress.AtRisk,
  },
  {
    label: 'Critical risk',
    value: EvaluationProgress.CriticalRisk,
  },
];
const defaultEditorValues =
  '{"blocks":[{"key":"6nr2t","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';
type Props = {
  data: OwnershipHeatmapModalDetail;
  setIsSubmiting: (isSubmiting: boolean) => void;
  setToggleEdit: (toggle: boolean) => void;
  smEmail: string;
};
interface OptionStringVal {
  label: string;
  value: string;
}
export const OwnershipDetailForm = ({ data, setIsSubmiting, setToggleEdit, smEmail }: Props) => {
  const [form] = Form.useForm();
  const currentYear = moment().toDate().getFullYear();
  const [isNotracking, setIsNoTracking] = useState<boolean>(data.satisfactionRate > -2 ? false : true);

  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const isSM = userRole === roleNames.scrumMaster && email === smEmail;
  const isQA = userRole === roleNames.pqa;

  const optionsQuarter: OptionStringVal[] = [
    {
      label: `${currentYear}-Q1`,
      value: `${currentYear}-Q1`,
    },
    {
      label: `${currentYear}-Q2`,
      value: `${currentYear}-Q2`,
    },
    {
      label: `${currentYear}-Q3`,
      value: `${currentYear}-Q3`,
    },
    {
      label: `${currentYear}-Q4`,
      value: `${currentYear}-Q4`,
    },
  ];

  const onChangeNoSLATracking = (e: CheckboxChangeEvent) => {
    setIsNoTracking(e.target.checked);
  };

  const initValues = {
    client: data.clientSatisfaction,
    ownershipLevel: data.ownershipLevel,
    slaRate: data.satisfactionRate > 0 ? data.satisfactionRate : 0,
    prodIssue: data.productionIssue >= 0 ? data.productionIssue : null,
    highlight: isEmpty(data.highlight) ? JSON.parse(defaultEditorValues) : JSON.parse(data.highlight),
    roadmap: data.roadmaps?.map((item) => ({
      quarter: optionsQuarter.find((x) => x.value === item.quarter)?.value,
      objective: item.objective,
      status: item.currentStatus,
      evalution: item.evalution,
    })),
    action: data.actions?.map((item) => ({
      dueDate: item.dueDate ? moment(item.dueDate) : null,
      keyResult: item.keyResult,
      status: item.currentStatus,
      pic: item.personInCharge,
      evalution: item.evalution,
    })),
  };
  const onFinish = async (values: any) => {
    setIsSubmiting(true);
    try {
      let result: OwnershipHeatmapModalDetail = {
        id: data?.id,
        clientSatisfaction: values.client,
        date: data?.date,
        highlight: JSON.stringify(values.highlight),
        teamId: data?.teamId,
        name: data?.name,
        ownershipLevel: values.ownershipLevel,
        productionIssue: values.prodIssue === null ? -1 : values.prodIssue,
        roadmaps:
          values.roadmap?.map((item) => ({
            quarter: item.quarter,
            objective: item.objective,
            currentStatus: item.status,
            evalution: item.evalution,
          })) ?? [],
        actions:
          values.action?.map((item) => ({
            dueDate: item.dueDate.toDate(),
            keyResult: item.keyResult,
            currentStatus: item.status,
            personInCharge: item.pic,
            evalution: item.evalution,
          })) ?? [],
        satisfactionRate: isNotracking ? -2 : values.slaRate,
      };
      await updateHeatmapDetail(result.id, result);
      openNotificationByType('success', 'Saved');
      setIsSubmiting(false);
      setToggleEdit(false);
    } catch (error) {
      setIsSubmiting(false);
      console.error(error);
      openNotification(error.status, error?.data?.error?.message);
    }
  };
  return (
    <OwnershipFormWrapper
      form={form}
      id="ownership"
      name="ownershipForm"
      onFinish={onFinish}
      autoComplete="off"
      colon={false}
      initialValues={initValues}
    >
      <ModalSection title="Self-evaluation (*)" align="start" titleUpperCase={false}>
        <Form.Item
          labelAlign="left"
          labelCol={{
            span: 6,
          }}
          label="Client Satisfaction"
          name="client"
          rules={[{ required: true, message: `Required` }]}
        >
          <Select options={satisfactionOptions} key="clientSatisfaction" disabled={isSM} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          labelCol={{
            span: 6,
          }}
          label="Maturity Level"
          name="ownershipLevel"
          rules={[{ required: true, message: `Required` }]}
        >
          <Select options={levelOptions} key="ownershipLevel" disabled={isQA} />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item
              labelAlign="left"
              labelCol={{
                span: 12,
              }}
              label="SLA Satisfaction Rate"
              name="slaRate"
              rules={[{ required: true, message: `Required` }]}
            >
              <InputNumber min={0} max={100} step={1} key="slaRate" disabled={isNotracking || isSM} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Checkbox onChange={onChangeNoSLATracking} checked={isNotracking} disabled={isSM}>
                No Ticket/SLA tracking
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          labelAlign="left"
          labelCol={{
            span: 6,
          }}
          label="# of Prod Issue"
          name="prodIssue"
        >
          <InputNumber min={0} max={100} step={1} key="prodIssue" disabled={isSM} />
        </Form.Item>
      </ModalSection>
      <ModalSection title="Highlight" align="start" titleUpperCase={false}>
        <Form.Item name="highlight">
          <Editor
            wrapperClassName="editor-wrapper"
            editorClassName="editor-box"
            toolbarClassName="editor-toolbar"
            onEditorStateChange={(values) => {
              form.setFieldsValue({
                highlight: values,
              });
            }}
            defaultContentState={isEmpty(data.highlight) ? JSON.parse(defaultEditorValues) : JSON.parse(data.highlight)}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'remove',
                'history',
              ],
            }}
            onTab={(e) => {
              const tabCharacter = '              ';
              e.preventDefault();
              let currentState = form.getFieldValue('highlight');
              let newContentState = Modifier.replaceText(
                currentState.getCurrentContent(),
                currentState.getSelection(),
                tabCharacter
              );
              form.setFieldsValue({
                description: EditorState.push(currentState, newContentState, 'insert-characters'),
              });
            }}
          />
        </Form.Item>
      </ModalSection>
      <ModalSection title="Roadmaps" align="start" titleUpperCase={false}>
        <Form.List name="roadmap">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} align="middle" justify="space-between" gutter={10}>
                  <Col span={23}>
                    <Form.Item
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      {...field}
                      label="Quarter"
                      name={[field.name, 'quarter']}
                      rules={[{ required: true, message: `Required` }]}
                    >
                      <Select options={optionsQuarter} disabled={isQA} />
                    </Form.Item>
                  </Col>
                  {!isQA && (
                    <Col span={1}>
                      <Form.Item>
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={23}>
                    <Form.Item
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      {...field}
                      label="Objective"
                      name={[field.name, 'objective']}
                      rules={[
                        { max: 500, message: `Max 500 characters` },
                        { required: true, message: `Required` },
                      ]}
                    >
                      <Input.TextArea rows={5} disabled={isQA} />
                    </Form.Item>
                  </Col>
                  <Col span={23}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="Current Status"
                      name={[field.name, 'status']}
                      rules={[{ max: 500, message: `Max 500 characters` }]}
                    >
                      <Input.TextArea rows={5} disabled={isQA} />
                    </Form.Item>
                  </Col>
                  <Col span={23}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="Evaluation"
                      name={[field.name, 'evalution']}
                      rules={[{ required: true, message: `Required` }]}
                    >
                      <Select options={evaluationOptions} disabled={isQA} />
                    </Form.Item>
                  </Col>
                  <Divider />
                </Row>
              ))}
              {!isQA && (
                <Row>
                  <Button type="link" className="btn-link" onClick={() => add()}>
                    Add new
                  </Button>
                </Row>
              )}
            </>
          )}
        </Form.List>
      </ModalSection>
      <ModalSection title="Actions" align="start" titleUpperCase={false}>
        <Form.List name="action">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} align="middle" justify="space-between" gutter={10}>
                  <Col span={23}>
                    <Form.Item
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      {...field}
                      label="Due date"
                      name={[field.name, 'dueDate']}
                      rules={[{ required: true, message: `Required` }]}
                    >
                      <DatePicker format="MMM DD, YYYY" disabled={isQA} />
                    </Form.Item>
                  </Col>
                  {!isQA && (
                    <Col span={1}>
                      <Form.Item>
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={23}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="Key result"
                      name={[field.name, 'keyResult']}
                      rules={[
                        { max: 500, message: `Max 500 characters` },
                        { required: true, message: `Required` },
                      ]}
                    >
                      <Input.TextArea rows={5} disabled={isQA} />
                    </Form.Item>
                  </Col>
                  <Col span={23}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="Current Status"
                      name={[field.name, 'status']}
                      rules={[{ max: 500, message: `Max 500 characters` }]}
                    >
                      <Input.TextArea rows={5} disabled={isQA} />
                    </Form.Item>
                  </Col>
                  <Col span={23}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="PIC"
                      name={[field.name, 'pic']}
                      rules={[
                        { max: 500, message: `Max 80 characters` },
                        { required: true, message: `Required` },
                      ]}
                    >
                      <Input disabled={isQA} />
                    </Form.Item>
                  </Col>
                  <Col span={23}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="Evaluation"
                      name={[field.name, 'evalution']}
                      rules={[{ required: true, message: `Required` }]}
                    >
                      <Select options={evaluationOptions} disabled={isQA} />
                    </Form.Item>
                  </Col>
                  <Divider />
                </Row>
              ))}
              {!isQA && (
                <Row>
                  <Button type="link" className="btn-link" onClick={() => add()}>
                    Add new
                  </Button>
                </Row>
              )}
            </>
          )}
        </Form.List>
      </ModalSection>
    </OwnershipFormWrapper>
  );
};

import { SubPage } from './TeamDetail';

export interface DateNoteItem {
  id: number;
  type: DateNoteType;
  note: string;
  scheduleDate: Date;
  holiday: DayDefined;
  pageType: SubPage;
}

export enum AttendanceType {
  None = 0,
  half = 1,
  full = 2,
  Holiday = 3,
}
export enum DateNoteType {
  None = 0,
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Holiday = 6,
}
export interface DayDefined {
  name: string;
  isHoliday: boolean;
  isManageHoliday?: boolean;
}
export interface SingleAttend {
  id: number;
  memberID: number;
  iterationID: number;
  dayOff: Date;
  type: AttendanceType;
  note: string;
  pageType: SubPage;
  holiday?: DayDefined;
  isPlanned?: boolean;
}
export interface AttendanceDataItem {
  memberId: number;
  name: string;
  attendance: SingleAttend[];
}
export interface DayOffRequest {
  iterationID: number;
  memberID: number;
  note: string;
  type: AttendanceType;
  dayOff: Date;
  pageType: SubPage;
}
export interface DayOffRequestForList {
  id: number;
  iterationID: number;
  memberID: number;
  note: string;
  type: AttendanceType;
  dayOff: Date;
  pageType: SubPage;
}

export interface ScheduleDateRequest {
  iterationID: number;
  type: DateNoteType;
  note: string;
  scheduleDate: Date;
  pageType: SubPage;
}

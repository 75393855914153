import Divider from 'antd/lib/divider';
import styled from 'styled-components';

const TITLE_COLOR = '#09488a';

export const ToolbarContainer = styled.div`
  margin-bottom: 16px;
  .ant-input.ant-input-lg {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const PageTitle = styled.div`
  h3 {
    font-weight: bold;
    color: ${TITLE_COLOR};
  }
  h5 {
    font-size: 20px;
    font-weight: bold;
    color: ${TITLE_COLOR};
  }
  .ant-switch {
    margin-right: 5px;
  }
  .history-toggle {
    text-align: right;
  }
`;

export const DeviderWrapper = styled(Divider)`
  border-bottom: 2px solid #09488a;
  color: #09488a;
  margin-top: 0;
`;

import styled from 'styled-components';

export const StyledTargetPlan = styled.div`
  line-height: 1rem;
  margin-top: 10px;
  bottom: 2rem;
  left: 2rem;

  .p-top {
    padding-top: 8px;
  }
`;

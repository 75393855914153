/* eslint-disable react/display-name */
import { useContext, useEffect, useMemo, useState } from 'react';
import { TABLE_DEFAULT_PAGE_SIZE } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { WorkItemType, TicketResponse, CarriedOverUpdateTicket } from '../../types/PlannedWorkItem';
import { updateCarriedOverNoteTicket } from 'app/apis/teamDetailClient';
import { notification, Space, TablePaginationConfig } from 'antd';
import Icon from '@ant-design/icons';
import DefectIcon from '../TeamDetail/Plan/DefectIcon';
import UserStoryIcon from '../TeamDetail/Plan/UserStoryIcon';
import { Stage } from 'app/types/TeamDetailTypes';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { SubPage } from 'app/types/TeamDetail';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { canEditReport } from '../TeamDetail/Report/Report';
import { UserContext } from 'app/contexts/UserContext';
import _ from 'lodash';
import { TeamContext } from 'app/contexts/TeamContext ';

interface IProps {
  page: SubPage;
  dataSource: any[];
}
export const CarriedOverItem = (props: IProps) => {
  const listAllCarriedOver = useMemo(() => {
    const result = props.dataSource && [...props.dataSource];
    return result;
  }, [props.dataSource]);
  const { isAuthorized } = useAuthz();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [listCarriedOver, setListCarriedOver] = useState<any[]>(listAllCarriedOver);
  const { stage: currentStage, selectedIteration, reportStatus } = useContext(IterationContext);
  const {
    user: { permissions },
  } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { page: activePage } = useContext(TeamContext);

  // Change iteration
  useEffect(() => {
    if (activePage === SubPage.Plan) {
      setListCarriedOver(null);
      setLoading(true);
    }
  }, [selectedIteration?.id, activePage]);

  useEffect(() => {
    setPage(1);
  }, [currentStage, selectedIteration?.id]);
  useEffect(() => {
    setListCarriedOver(listAllCarriedOver);
  }, [listAllCarriedOver]);
  const renderIDColumn = (record: TicketResponse) => {
    return {
      children: (
        <Space>
          <Icon component={record.type === WorkItemType.Defect ? DefectIcon : UserStoryIcon} />
          {record.code}
        </Space>
      ),
    };
  };

  const openNotification = (statusCode: number, message: string) => {
    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 3,
    });
  };

  const columns = useMemo<EditableColumn<TicketResponse>[]>(
    () => [
      {
        title: '#',
        width: '5%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Feature',
        dataIndex: 'feature',
        key: 'feature',
        editable: false,
        width: '8%',
      },
      {
        title: 'ID',
        dataIndex: 'code',
        render: (text, record) => renderIDColumn(record),
        sorter: (a: any, b: any) => 1,
        width: '8%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'parentId',
        editable: false,
        width: '600px',
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'ownerId',
        editable: false,
        width: '400px',
      },
      {
        title: 'Point',
        width: '5%',
        dataIndex: 'point',
        key: 'point',
        editable: false,
        align: 'center',
      },
      {
        title: 'Schedule State',
        dataIndex: 'scheduleState',
        key: 'scheduleState',
        editable: false,
        width: '8%',
      },
      {
        title: 'Reason Of Carried Over',
        dataIndex: 'carriedOverNote',
        key: 'carriedOverNoteID',
        editable: true,
        width: '600px',
      },
    ],
    [page, pageSize]
  );
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const onSave = async (ticketId: number, { id, code, carriedOverNote, iterationId }: TicketResponse) => {
    try {
      const carriedOverTicketRequest: CarriedOverUpdateTicket = { id, carriedOverNote };
      const response: TicketResponse = await updateCarriedOverNoteTicket(carriedOverTicketRequest);
      listAllCarriedOver?.forEach((item) => {
        if (item.id === response.id) {
          item.carriedOverNote = response?.carriedOverNote;
        }
        return item;
      });
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });
      setListCarriedOver(listAllCarriedOver);
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };
  return (
    <EditableTable
      className="work-item"
      onChange={onChange}
      onSave={onSave}
      pageSize={pageSize}
      loading={loading}
      current={page}
      isEdit={
        isAuthorized &&
        (currentStage === Stage.Other ||
          currentStage === undefined ||
          (selectedIteration?.isCurrentSprint && currentStage === Stage.Planning)) &&
        canEditReport(reportStatus, permissions)
      }
      columns={columns}
      data={listCarriedOver}
    />
  );
};

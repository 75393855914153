import { Col, DatePicker, Radio, RadioChangeEvent, Row, Spin } from 'antd';
import { fetchOwnershipProgramData } from 'app/apis/ownershipClient';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { TeamContext } from 'app/contexts/TeamContext ';
import { WeeklyStatusWrapper } from 'app/pages/Reports/WeeklyStatus/ReportWeeklyStatus.styled';
import { PlanSection } from 'app/pages/TeamDetail/Plan/PlanSection/PlanSection';
import {
  OwnershipImprove,
  OwnershipTeamStatusDetail,
  OwnershipTeamStatusDetailGroup,
  OwnershipTeamStatusViewType,
} from 'app/types/OwnershipProgramData';
import { isNull } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { StyledOwnershipWrapper } from './OwnershipOverview.styled';
import { TeamStatusDetail } from '../TeamStatusDetail/TeamStatusDetail';
import { TeamStatusSection } from '../TeamStatusDetail/TeamStatusSection';
import { TeamStatusTailInfo } from '../TeamStatusDetail/TeamStatusTailInfo';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { OwnershipLevelChart } from 'app/charts/Ownership/OwnershipLevelChart';
import { OwnershipLevelItem } from 'app/types/OwnershipProgramData';
import { Card } from 'app/components/Card';
import { openNotification } from 'app/utils/notificationUtils';
import { useFetch } from 'app/hooks/useFetch';
import { fetchHighlightHeatmap } from 'app/apis/ownershipHeatmap';
import { getOwnershipLevelTitle, HighlightType } from 'app/types/Ownership';
import { OwnershipHighlight } from 'app/components/Ownership/Highlight/OwnershipHighlight';
import { OwnershipLevelStatisticsChart } from 'app/charts/Ownership/OwnershipLevelStatisticsChart';
import { TopTeamImprove } from 'app/components/Ownership/TableTopTeamImprove/TopTeamImprove';
import { Helmet } from 'react-helmet';

export interface IOwnershipOverviewProps {}

export function OwnershipOverview({ ...props }: IOwnershipOverviewProps) {
  const { setBreadcrumbs } = useContext(TeamContext);

  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment().startOf('month'));
  const [teamStatusFilterBy, setTeamStatusFilterBy] = useState<number>(OwnershipTeamStatusViewType.ByLevel);
  const [teamStatusFilterLevel, setTeamStatusFilterLevel] = useState<number[]>([]);
  const [teamStatusGroup, setTeamStatusGroup] = useState<OwnershipTeamStatusDetailGroup[]>([]);
  const [teamStatusDetailData, setTeamStatusDetailData] = useState<OwnershipTeamStatusDetail[]>([]);
  const [chartLevelByProjectData, setChartLevelByProjectData] = useState<OwnershipLevelItem[]>([]);
  const [chartLevelByPortfolioData, setChartLevelByPortfolioData] = useState<OwnershipLevelItem[]>([]);
  const [chartLevelStatistics, setChartLevelStatistics] = useState<OwnershipLevelItem[]>([]);
  const [tableTopTeamImprove, setTableTopTeamImprove] = useState<OwnershipImprove[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: highlightData } = useFetch(
    () => fetchHighlightHeatmap(selectedMonth.startOf('day').format('YYYY-MM-01'), HighlightType.Overview),
    [selectedMonth]
  );
  const disabledDates: RangePickerProps['disabledDate'] = (selected) => {
    return selected && (selected >= moment().endOf('week') || selected <= moment('01-01-2021', 'MM-DD-YYYY'));
  };
  const handleMonthlyOptionChange = (date: moment.Moment) => {
    if (date) {
      setSelectedMonth(date.startOf('month'));
    }
  };
  const viewbyOptions = [
    {
      label: 'Level',
      value: OwnershipTeamStatusViewType.ByLevel,
    },
    {
      label: 'Project',
      value: OwnershipTeamStatusViewType.ByProject,
    },
    {
      label: 'Portfolio',
      value: OwnershipTeamStatusViewType.ByPortfolio,
    },
  ];

  const loadData = async (viewDate: string) => {
    try {
      setLoading(true);
      var data = await fetchOwnershipProgramData(viewDate);
      setTeamStatusDetailData(data?.teamStatusDetail);
      setChartLevelByProjectData(data?.chartLevelByProject);
      setChartLevelByPortfolioData(data?.chartLevelByPortfolio);
      setChartLevelStatistics(data?.chartLevelStatistics);
      setTableTopTeamImprove(data?.tableTeamImprove);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification(500, error);
    }
  };

  useEffect(() => {
    loadData(selectedMonth.startOf('day').format('YYYY-MM-01'));
  }, [selectedMonth]);

  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'Team Maturity',
        parentCrumb: null,
        renderAsLink: false,
      },
      { ...createDefaultBreadCrumb('Maturity Level'), renderAsLink: false },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teamStatusDetailData) {
      getTeamStatusDetailByGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamStatusFilterBy, teamStatusDetailData]);

  const getTeamStatusDetailByGroup = async () => {
    const group = [] as OwnershipTeamStatusDetailGroup[];
    for (let i = 0; i < teamStatusDetailData.length; i++) {
      // Find group name
      let title = getTeamStatusTitleGroup(teamStatusDetailData[i]);
      let flag = false;

      for (let j = 0; j < group.length; j++) {
        if (group[j].groupName === title) {
          flag = true;
          group[j].teams.push(teamStatusDetailData[i]);
          break;
        }
      }

      if (!flag) {
        let newGroup = {} as OwnershipTeamStatusDetailGroup;
        newGroup.groupName = title;
        newGroup.teams = [] as OwnershipTeamStatusDetail[];
        group.push(newGroup);

        i--;
        continue;
      }
    }

    // Sort group title
    group.sort((a, b) => {
      if (a.groupName === 'None') return -1;
      if (b.groupName === 'None') return 1;
      if (a.groupName === 'No Project Group') return 1;
      if (b.groupName === 'No Project Group') return -1;

      if (a.groupName > b.groupName) return 1;
      if (b.groupName > a.groupName) return -1;
      return 0;
    });

    // Revert array if sort by level
    if (teamStatusFilterBy === OwnershipTeamStatusViewType.ByLevel) {
      group.reverse();
    }

    setTeamStatusGroup(group);
  };

  const getTeamStatusTitleGroup = (data: OwnershipTeamStatusDetail): string => {
    switch (teamStatusFilterBy) {
      case OwnershipTeamStatusViewType.ByLevel:
        if (data.level === undefined || isNull(data.level)) return 'None';
        else return getOwnershipLevelTitle(data.level);
      case OwnershipTeamStatusViewType.ByProject:
        return data.projectGroupName;
      case OwnershipTeamStatusViewType.ByPortfolio:
        return data.portfolioName;
    }

    return '';
  };

  const onChangeTeamStatusFilterBy = ({ target: { value } }: RadioChangeEvent) => {
    setTeamStatusFilterBy(value);
  };

  return (
    <StyledOwnershipWrapper className="ownership-overview-wrapper">
      <Helmet>
        <title>ADM Tool | Maturity Level</title>
      </Helmet>
      <WeeklyStatusWrapper>
        <span className="title">
          MATURITY STATUS
          <DatePicker
            style={{ marginLeft: 10 }}
            picker="month"
            format={'MMMM yyyy'}
            disabledDate={disabledDates}
            value={selectedMonth}
            onChange={handleMonthlyOptionChange}
          />
        </span>
      </WeeklyStatusWrapper>
      {loading ? (
        <Spin />
      ) : (
        <>
          <PlanSection title="OVERVIEW">
            <Row gutter={16}>
              <Col className="gutter-row" xl={12} sm={24} xs={24}>
                <Card titleTooltip="Top Team Improvement/Decline" title="Top Team Improvement/Decline">
                  <TopTeamImprove data={tableTopTeamImprove} />
                </Card>
              </Col>

              <Col className="gutter-row" xl={12} sm={24} xs={24}>
                <OwnershipHighlight content={highlightData} type={HighlightType.Overview} />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Card titleTooltip="Maturity Level by Project" title="Maturity Level by Project">
                  <OwnershipLevelChart dataSource={chartLevelByProjectData} />
                </Card>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" md={12} sm={24} xs={24}>
                <Card titleTooltip="Maturity Level by Portfolio" title="Maturity Level by Portfolio">
                  <OwnershipLevelChart dataSource={chartLevelByPortfolioData} />
                </Card>
              </Col>
              <Col className="gutter-row" md={12} sm={24} xs={24}>
                <Card titleTooltip="Maturity Level Statistics" title="Maturity Level Statistics">
                  <OwnershipLevelStatisticsChart dataSource={chartLevelStatistics} />
                </Card>
              </Col>
            </Row>
          </PlanSection>

          {teamStatusDetailData && (
            <TeamStatusSection
              title="Status Detail"
              tailContent={
                <TeamStatusTailInfo
                  teams={teamStatusDetailData}
                  value={teamStatusFilterLevel}
                  setFilter={function (value: number): void {
                    const newValue = [...teamStatusFilterLevel];
                    var index = newValue.indexOf(value);
                    if (index !== -1) {
                      newValue.splice(index, 1);
                    } else {
                      newValue.push(value);
                    }
                    setTeamStatusFilterLevel(null);
                    setTeamStatusFilterLevel(newValue);
                  }}
                />
              }
            >
              <Row style={{ alignItems: 'center' }}>
                <Col>
                  <b style={{ marginRight: 10 }}>View by</b>
                </Col>
                <Col>
                  <Radio.Group
                    options={viewbyOptions}
                    value={teamStatusFilterBy}
                    onChange={onChangeTeamStatusFilterBy}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Col>
              </Row>
              <div>
                {teamStatusGroup.map((p, i) => {
                  const showTeams = p.teams.filter((x) => teamStatusFilterLevel.indexOf(x.level) === -1);
                  if (showTeams.length === 0) {
                    return <></>;
                  }

                  return (
                    <div style={{ marginTop: 15 }} key={i}>
                      <TeamStatusDetail teams={showTeams} title={p.groupName} />
                    </div>
                  );
                })}
              </div>
            </TeamStatusSection>
          )}
        </>
      )}
    </StyledOwnershipWrapper>
  );
}

import { COLOR_PRIMARY } from 'app/common/constants';
import { Drawer } from 'antd';
import styled from 'styled-components';

export const WeeklyRect = styled.div`
  background-color: ${(props) => props.color};
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 16px;
  color: #ffffff;
  justify-content: center;
  align-items: center;

  &.select {
    &:hover {
      border: 2px solid ${COLOR_PRIMARY};
      cursor: pointer;
    }
    &.active {
      border: 2px solid ${COLOR_PRIMARY};
      cursor: pointer;
    }
  }
`;

export const WeeklyModalWrapper = styled(Drawer)`
  .ant-drawer-header {
    text-align: center;
    color: #404040;
    .ant-drawer-title {
      font-size: 25px;
    }
  }
  .ant-drawer-body {
    .drawer-body {
      width: 100%;
      .title-header {
        text-align: left;
        width: fit-content;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        font-weight: bold;
        font-size: 27px;
        border-bottom: 6px #707070 solid;
        p {
          margin-bottom: 10px;
        }
      }
      .add-new-btn {
        padding: 0;
        margin: 0.5rem 0;
      }
      .week-list-section {
        margin: 0.5rem 0;
      }
      padding-bottom: 2rem;
    }
  }
`;

import React from 'react';
import { Row, Col, Space } from 'antd';
import { DeliveryResponse } from 'app/types/CacheReportModel';
import { ChartDetailWrapper } from './ChartDetailWrapper.styled';

interface IDetailChartDelivery {
  loading: boolean;
  dataSource: DeliveryResponse;
}

export const DetailChartDelivery = ({ loading, dataSource }: IDetailChartDelivery) => {
  const total = dataSource?.total;
  const delivered = dataSource?.delivered;
  return (
    <ChartDetailWrapper>
      <Col span={12}>
        <Space direction="vertical" size={10}>
          <Row>Total: </Row>
          <Row>Delivered: </Row>
          <Row>Remaining: </Row>
        </Space>
      </Col>
      <Col span={12}>
        <Space direction="vertical" size={10}>
          <Row>{loading || !dataSource ? `0` : total} points</Row>
          <Row>{loading || !dataSource ? `0` : delivered} points</Row>
          <Row>{loading || !dataSource ? `0` : total - delivered} points</Row>
        </Space>
      </Col>
    </ChartDetailWrapper>
  );
};

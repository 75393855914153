import { Entity } from './entity';
import {
  AcceptedChartColor,
  CompletedChartColor,
  DefinedChartColor,
  FeatureChartColor_Accepted,
  FeatureChartColor_Cancelled,
  FeatureChartColor_Developing,
  FeatureChartColor_Done,
  FeatureChartColor_FeaturePrioritization,
  FeatureChartColor_IdeaPrioritization,
  FeatureChartColor_Intake,
  FeatureChartColor_Measuring,
  FeatureChartColor_ProblemDiscovery,
  FeatureChartColor_SolutionDiscovery,
  IdeaChartColor,
  InProgressChartColor,
  ReleasedChartColor,
} from 'app/common/constants';
import { QuarterReleasePlanTaskStatePrepending } from './TeamDetail';

export enum WorkItemType {
  Feature = 1,
  UserStory = 2,
  Defect = 3,
  Task = 4,
}

export interface TicketResponse extends Entity {
  code: string;
  name: string;
  owner: string;
  coOwner: string;
  qaOwner: string;
  point?: number;
  state: string;
  estimate: number;
  todo: number;
  actual: number;
  effortUpdateResult: number;
  environment: string;
  scheduleState: string;
  scheduleStatePrefix: string;
  flowState: string;
  note: string;
  isCommitted: boolean;
  feature: string;
  type: WorkItemType;
  taskState: string;
  iterationState: IterationState;
  testCaseNote: string;
  parentId?: number;
  parent?: TicketResponse;
  carriedOverNote: string;
  iterationId?: number;
  resolution: string;
  resolutionDetail: string;
  rootCauseCategory: string;
  priority: string;
  closedDuration: number;
}
export interface DefectRootCauseColumn {
  id: number;
  code: string;
  name: string;
  priority: string;
  state: string;
  environment: string;
  scheduleState: string;
  resolution: string;
  resolutionDetail: string;
  rootCauseCategory: string;
  closedDuration: number;
}

export interface TicketResponseCustom extends TicketResponse {
  itemState?: ItemState;
}

export enum ItemState {
  CARRIED_TO_NEXT_ITERATION = 1,
  KEEP = 2,
}

export enum TaskState {
  UNCOMPLETED_CARD = 'Uncompleted card',
}

export enum IterationState {
  Plan = 1,
  Monitor = 2,
  Report = 3,
}

export interface UpdateWorkItem {
  note: string;
  isCommitted: boolean;
}

export interface UpdateTestCase {
  testCaseNote: string;
}

export interface CarriedOverUpdateTicket {
  id: number;
  carriedOverNote: string;
}

export enum UserStoryStates {
  Accepted = 'Accepted',
  Completed = 'Completed',
  Defined = 'Defined',
  Idea = 'Idea',
  InProgress = 'In-Progress',
  Released = 'Released',
}

export enum FeatureStates {
  Intake = 'Intake',
  IdeaPrioritization = 'Idea Prioritization',
  ProblemDiscovery = 'Problem Discovery',
  SolutionDiscovery = 'Solution Discovery',
  FeaturePrioritization = 'Feature Prioritization',
  Developing = 'Developing',
  Accepted = 'Accepted',
  Measuring = 'Measuring',
  Done = 'Done',
  Cancelled = 'Cancelled',
}

export const getWorkItemStateColour = (taskState: string) => {
  if (taskState.endsWith(QuarterReleasePlanTaskStatePrepending.UserStory)) {
    switch (taskState.replace(QuarterReleasePlanTaskStatePrepending.UserStory, '')) {
      case UserStoryStates.Accepted:
        return AcceptedChartColor;
      case UserStoryStates.Completed:
        return CompletedChartColor;
      case UserStoryStates.Defined:
        return DefinedChartColor;
      case UserStoryStates.Idea:
        return IdeaChartColor;
      case UserStoryStates.InProgress:
        return InProgressChartColor;
      case UserStoryStates.Released:
        return ReleasedChartColor;
      default:
        return IdeaChartColor;
    }
  } else {
    switch (taskState.replace(QuarterReleasePlanTaskStatePrepending.Feature, '')) {
      case FeatureStates.Intake:
        return FeatureChartColor_Intake;
      case FeatureStates.IdeaPrioritization:
        return FeatureChartColor_IdeaPrioritization;
      case FeatureStates.ProblemDiscovery:
        return FeatureChartColor_ProblemDiscovery;
      case FeatureStates.SolutionDiscovery:
        return FeatureChartColor_SolutionDiscovery;
      case FeatureStates.FeaturePrioritization:
        return FeatureChartColor_FeaturePrioritization;
      case FeatureStates.Developing:
        return FeatureChartColor_Developing;
      case FeatureStates.Measuring:
        return FeatureChartColor_Measuring;
      case FeatureStates.Accepted:
        return FeatureChartColor_Accepted;
      case FeatureStates.Done:
        return FeatureChartColor_Done;
      case FeatureStates.Cancelled:
        return FeatureChartColor_Cancelled;
      default:
        return FeatureChartColor_Intake;
    }
  }
};

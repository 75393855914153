import React from 'react';
import { Row, Col, Space } from 'antd';
import { ActualCapacityResponse } from 'app/types/CacheReportModel';
import { ChartDetailWrapper } from './ChartDetailWrapper.styled';
interface IDetailChartActualCapacity {
  loading: boolean;
  dataSource?: ActualCapacityResponse;
}

export const DetailChartActualCapacity = ({ loading, dataSource }: IDetailChartActualCapacity) => {
  const todo = dataSource?.todo;
  const plan = dataSource?.plan;
  const total = dataSource?.total;
  const actual = dataSource?.actual;
  const formatPercent = (num: number, total: number) => {
    if (total === 0 || !plan || !actual || !total || !todo) return 0;
    let result = (num / total) * 100;
    return result.toFixed(1);
  };
  return (
    <ChartDetailWrapper>
      <Col span={10}>
        <Space direction="vertical" size={10}>
          <Row>Team capacity:</Row>
          <Row>Plan:</Row>
          <Row>Actuals:</Row>
          <Row>To do:</Row>
        </Space>
      </Col>
      <Col span={14}>
        <Space direction="vertical" size={10}>
          <Row>{loading || !total ? `0` : total} hours</Row>
          <Row>
            {loading || !plan ? `0` : plan} hours ({loading || !dataSource ? `0` : `${formatPercent(plan, total)}%`})
          </Row>
          <Row>
            {loading || !actual ? `0` : actual} hours (
            {loading || !dataSource ? `0` : `${formatPercent(actual, actual + todo)}%`})
          </Row>
          <Row>
            {loading || !todo ? `0` : todo} hours (
            {loading || !dataSource ? `0` : `${formatPercent(todo, actual + todo)}%`})
          </Row>
        </Space>
      </Col>
    </ChartDetailWrapper>
  );
};

import { useContext, useState } from 'react';
import {
  DateNoteItem,
  DateNoteType,
  DayDefined,
  ScheduleDateRequest,
  AttendanceType,
  AttendanceDataItem,
  DayOffRequest,
  DayOffRequestForList,
} from '../../../types/Attendance';
import { AttendanceMark } from 'app/common/constants';
import { AssignModal, AttendanceHeaderCell, ColorRadio, PopoverContent } from '../Attendance.styled';
import { Button, Col, Form, Input, Popover, Radio, Row } from 'antd';
import moment from 'moment';
import { IterationContext } from 'app/contexts/IterationContext';
import { SubPage } from 'app/types/TeamDetail';
import {
  createScheduleDate,
  removeScheduleDate,
  updateScheduleDate,
  createListDateOff,
  updateListDayOff,
} from 'app/apis/teamDetailClient';
import { openNotification } from 'app/utils/notificationUtils';

interface IRenderHeaderCell {
  date: DateNoteItem;
  onUpdate: (i: number, dateData: DateNoteItem) => void;
  index: number;
  isEdit: boolean;
  dataAttendenceList: AttendanceDataItem[];
  updateAttendenceList: (data: AttendanceDataItem[]) => void;
}
export const RenderHeaderAttendance = ({
  date,
  onUpdate,
  index,
  isEdit,
  dataAttendenceList,
  updateAttendenceList,
}: IRenderHeaderCell) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { type } = date;
  const { selectedIteration } = useContext(IterationContext);
  const [currentType, setCurrentType] = useState<DateNoteType>(type);
  const [attendOption, setAttendOption] = useState<AttendanceType>(null);
  // need to add field attendence options in data cho header
  const [form] = Form.useForm();
  const markOptions = [
    {
      color: AttendanceMark.red,
      value: DateNoteType.First,
    },
    {
      color: AttendanceMark.yellow,
      value: DateNoteType.Second,
    },
    {
      color: AttendanceMark.pink,
      value: DateNoteType.Third,
    },
    {
      color: AttendanceMark.blue,
      value: DateNoteType.Fourth,
    },
    {
      color: AttendanceMark.grey,
      value: DateNoteType.Fifth,
    },
  ];
  const definedColor = (type: number) => {
    switch (type) {
      case DateNoteType.None:
        return AttendanceMark.header;
      case DateNoteType.First:
        return AttendanceMark.red;

      case DateNoteType.Second:
        return AttendanceMark.yellow;

      case DateNoteType.Third:
        return AttendanceMark.pink;

      case DateNoteType.Fourth:
        return AttendanceMark.blue;

      case DateNoteType.Fifth:
        return AttendanceMark.grey;

      case DateNoteType.Holiday:
        return AttendanceMark.pinkDefault;

      default:
        return AttendanceMark.header;
    }
  };

  const setDateNoteType = () => {
    return date?.holiday?.isHoliday && date?.holiday?.name !== 'Weekend' && !date?.holiday?.isManageHoliday
      ? DateNoteType.First
      : date.holiday.isManageHoliday
      ? DateNoteType.Holiday
      : DateNoteType.None;
  };

  const defaultDate: DateNoteItem = {
    ...date,
    id: null,
    type: setDateNoteType(),
    note: '',
  };
  const showModal = () => {
    form.setFieldsValue({
      note: '',
      attendType: AttendanceType.None,
    });
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // update dayoff
      if (values?.attendType !== AttendanceType.None) {
        let listCreateRequest = [];
        let listUpdateRequest = [];
        let listCreateRespone;
        let listUpdateRespone;

        dataAttendenceList?.forEach((member) => {
          member?.attendance?.forEach((item) => {
            // Create list dayoff for update or create
            if (moment(item?.dayOff).isSame(moment(date?.scheduleDate)) && item?.id === null) {
              let requestItem: DayOffRequest = {
                iterationID: selectedIteration?.id,
                memberID: member?.memberId,
                note: values?.attendType === AttendanceType.None ? '' : values?.note,
                type: values?.attendType,
                dayOff: date?.scheduleDate,
                pageType: SubPage.Monitoring,
              };
              listCreateRequest?.push(requestItem);
            }
            if (moment(item?.dayOff).isSame(moment(date?.scheduleDate)) && item?.id !== null) {
              let requestItem: DayOffRequestForList = {
                id: item?.id,
                iterationID: selectedIteration?.id,
                memberID: member?.memberId,
                note: values?.attendType === AttendanceType.None ? '' : values?.note,
                type: values?.attendType,
                dayOff: date?.scheduleDate,
                pageType: item?.pageType,
              };
              listUpdateRequest?.push(requestItem);
            }
          });
        });
        // call api to creat and update ListDayOff
        listCreateRespone = await createListDateOff(listCreateRequest);
        listUpdateRespone = await updateListDayOff(listUpdateRequest);
        let listAllResponse = [...listCreateRespone, ...listUpdateRespone];

        // Update item of dataAttendenceList
        dataAttendenceList?.forEach((member) => {
          member?.attendance?.forEach((item) => {
            if (moment(item?.dayOff).isSame(moment(date?.scheduleDate))) {
              listAllResponse?.forEach((cr) => {
                if (cr.memberID === member.memberId) {
                  item.id = cr?.id;
                  item.pageType = cr?.pageType;
                  item.note = values?.attendType === AttendanceType.None ? '' : values?.note;
                  item.type = values?.attendType;
                }
              });
            }
          });
        });
        updateAttendenceList(dataAttendenceList);
        form.setFieldsValue({
          attendType: AttendanceType.None,
        });
      }

      // update scheduledate
      let request: ScheduleDateRequest = {
        iterationID: selectedIteration?.id,
        note: values?.note,
        pageType: SubPage.Monitoring,
        scheduleDate: date?.scheduleDate,
        type: currentType,
      };
      if (date.id !== null) {
        const resp = await updateScheduleDate(date.id, request);
        const itemUpdate: DateNoteItem = {
          ...resp,
          holiday: date?.holiday,
        };
        onUpdate(index, itemUpdate);
      } else {
        const resp = await createScheduleDate(request);
        const itemUpdate: DateNoteItem = {
          ...resp,
          holiday: date?.holiday,
        };
        onUpdate(index, itemUpdate);
      }
      setLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      openNotification(error.status, error?.data?.error?.message);
      setLoading(false);
    }
  };
  const onCancel = () => {
    setIsModalVisible(false);
    setCurrentType(null);
    form.setFieldsValue({
      note: '',
      type: date?.holiday?.isHoliday && date?.holiday?.name !== 'Weekend' ? DateNoteType.First : null,
    });
  };
  const onReset = async () => {
    try {
      await removeScheduleDate(date.id);
      onUpdate(index, defaultDate);
      // Reset dayoff all member
      let listUpdateRequest = [];
      let listUpdateRespone;
      dataAttendenceList?.forEach((member) => {
        member?.attendance?.forEach((item) => {
          // Create list dayoff for update or create
          if (moment(item?.dayOff).isSame(moment(date?.scheduleDate)) && item?.id !== null) {
            let requestItem: DayOffRequestForList = {
              id: item?.id,
              iterationID: selectedIteration?.id,
              memberID: member?.memberId,
              note: '',
              type: AttendanceType.None,
              dayOff: date?.scheduleDate,
              pageType: item?.pageType,
            };
            listUpdateRequest?.push(requestItem);
          }
        });
      });
      // call api to creat and update ListDayOff
      listUpdateRespone = await updateListDayOff(listUpdateRequest);
      // Update item of dataAttendenceList
      dataAttendenceList?.forEach((member) => {
        member?.attendance?.forEach((item) => {
          if (moment(item?.dayOff).isSame(moment(date?.scheduleDate))) {
            listUpdateRespone?.forEach((cr) => {
              if (cr.memberID === member.memberId) {
                item.id = cr?.id;
                item.pageType = cr?.pageType;
                item.note = '';
                item.type = date?.holiday?.isManageHoliday ? AttendanceType.Holiday : AttendanceType.None;
              }
            });
          }
        });
      });
      let attendentListClone = dataAttendenceList && [...dataAttendenceList];
      updateAttendenceList(attendentListClone);
      // Set up
      setCurrentType(
        date?.holiday?.isHoliday && date?.holiday?.name !== 'Weekend' ? DateNoteType.First : DateNoteType.None
      );
      form.setFieldsValue({
        note: '',
        type: date?.holiday?.isHoliday && date?.holiday?.name !== 'Weekend' ? DateNoteType.First : null,
        attendType: AttendanceType.None,
      });
    } catch (error) {
      openNotification(error.status, error?.data?.error?.message);
    }
  };
  return (
    <>
      <AssignModal
        title={
          <Row justify="center" className="title-modal">
            {moment(date?.scheduleDate).format('dddd, MMMM DD, yyyy')}
          </Row>
        }
        visible={isModalVisible}
        footer={false}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
          initialValues={{
            type: date && date?.type !== DateNoteType.None ? date?.type : null,
            note: date?.note ?? '',
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Display color"
            name="type"
            rules={[
              {
                required: true,
                message: 'Required this field',
              },
            ]}
          >
            <Row>
              {markOptions.map((opt) => (
                <ColorRadio
                  key={opt?.color}
                  className={opt?.value === currentType && 'checked'}
                  color={opt?.color}
                  onClick={() => {
                    form.setFieldsValue({
                      type: opt?.value,
                    });
                    setCurrentType(opt.value);
                  }}
                />
              ))}
            </Row>
          </Form.Item>
          <Form.Item label="Partial day" name="attendType">
            <Radio.Group>
              <Radio value={AttendanceType.full} onChange={() => setAttendOption(AttendanceType.full)}>
                Full day
              </Radio>
              <Radio value={AttendanceType.half} onChange={() => setAttendOption(AttendanceType.half)}>
                Half day
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Note"
            name="note"
            rules={[
              {
                required: true,
                message: 'Required this field',
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Row justify="center" gutter={20}>
            <Col>
              <Button type="default" onClick={onCancel}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </AssignModal>
      {type !== DateNoteType.None ? (
        <Popover
          content={
            <PopoverContent>
              <Row gutter={10}>
                <Col className="description-text">
                  <p>{date.note}</p>
                </Col>
              </Row>
              {isEdit && date.note !== '' && (
                <Row gutter={10}>
                  <Col>
                    <Button onClick={onReset} type="default" danger>
                      Clear
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={() => setIsModalVisible(true)} type="primary">
                      Edit
                    </Button>
                  </Col>
                </Row>
              )}
            </PopoverContent>
          }
          title={
            <div>
              {((date?.holiday?.isHoliday && date?.holiday?.name !== 'Weekend') || date?.holiday?.isManageHoliday) && (
                <div>{date?.holiday?.name}</div>
              )}
              {moment(date.scheduleDate).format('dddd, MMMM DD, yyyy')}
            </div>
          }
        >
          <AttendanceHeaderCell
            className={isEdit ? 'editable' : ''}
            color={definedColor(type)}
            onClick={() =>
              isEdit &&
              ((date?.holiday?.isHoliday && date?.holiday?.name !== 'Weekend') || date?.holiday?.isManageHoliday)
                ? showModal()
                : null
            }
          >
            {moment(date?.scheduleDate).format('MM/DD')}
          </AttendanceHeaderCell>
        </Popover>
      ) : (
        <AttendanceHeaderCell
          className={isEdit ? 'editable' : ''}
          onClick={() => (isEdit ? showModal() : null)}
          color={definedColor(type)}
        >
          {moment(date?.scheduleDate).format('MM/DD')}
        </AttendanceHeaderCell>
      )}
    </>
  );
};

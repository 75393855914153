import { IIssueReportIdeaResponse } from 'app/types/IssueReportIdeaModel';
import moment from 'moment';
import { IssueReportStatusToText, IssueReportTypeToText } from './IssueReportIdeaHelper';
import { EditorState, convertFromRaw } from 'draft-js';

// binding data for table
export const bindingData = (data: IIssueReportIdeaResponse) => {
  const creationTimeFormat = moment(data.creationTime);
  data.creationTime = creationTimeFormat.format('MM-DD-YY HH:mm');
  data.statusText = IssueReportStatusToText(data.status);
  data.typeText = IssueReportTypeToText(data.type);
  return data;
};

export const getEditorState = function (detail: string) {
  if (detail == null) {
    return EditorState.createEmpty();
  }
  const detailInJson = JSON.parse(JSON.stringify(detail));
  const detailJsonObject = JSON.parse(detailInJson);
  const newEditorState = EditorState.createWithContent(convertFromRaw(detailJsonObject));
  return newEditorState;
};

import { Radio } from 'antd';
import { EditableTable } from 'app/components/EditableTable';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    min-width: 150px;
    min-height: 39px;
    text-align: center;
    border-radius: inherit;
    span {
      line-height: 35px;
    }
  }
`;

export const StyledEditableTable = styled(EditableTable)`
  table {
    thead {
      th:last-child {
        text-align: center;
      }
    }

    .is-sync-fail {
      color: red;
    }
  }
`;

import { useContext, useEffect, useMemo, useState } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import {
  ChartTargetColors,
  ChartTargetValue,
  InovationHigherTargetColor,
  InovationLowerTargetColor,
} from 'app/common/constants';
import { Col, Empty, Radio, Row, Select } from 'antd';
import { SelectOptions } from 'app/types/entity';
import { ChartTarget } from '../ChartTarget/ChartTarget';
import { StyledChart } from '../Charts.styled';
import { ReportType } from 'app/types/CacheReportModel';
import { OverviewViewType } from 'app/types/OverviewViewType';
import { fetchOverviewThroughput, saveChartSettingRequest } from 'app/apis/fetchTeamOverview';
import { mean } from 'lodash';
import { OverviewChartItem } from 'app/types/OverviewChartData';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { useFetch } from 'app/hooks/useFetch';
import { SaveChartSettingModel } from 'app/types/SaveChartSettingRequest';
import { OverviewNote } from '../../OverviewNote/OverviewNote';
import { getOverviewQuarterSelectOptions } from 'app/common/helpers';
import { cloneDeep } from 'lodash';

const reportType = ReportType.OverviewThroughput;

export enum ThroughputType {
  UserStories,
  Defects,
  StoriesDefects,
}

export const OverviewThroughputChart = () => {
  const { team } = useContext(TeamDetailContext);

  const optionsList = getOverviewQuarterSelectOptions();

  const options = [
    { value: ThroughputType.UserStories, label: 'User Stories' },
    { value: ThroughputType.Defects, label: 'Defects' },
    { value: ThroughputType.StoriesDefects, label: 'Stories & Defects' },
  ];

  const [viewType, setViewType] = useState(optionsList[0].value);
  const [throughputType, setThroughputType] = useState(options[0].value);
  const { data: chartData } = useFetch(() => fetchOverviewThroughput(team?.id, viewType), [team, viewType]);
  const [target, setTarget] = useState(chartData?.target ?? 0);
  const [note, setNote] = useState(chartData?.note ?? '');
  const [cloneData, setCloneData] = useState<OverviewChartItem[]>([]);

  useEffect(() => {
    if (chartData == null) return;
    let clone = cloneDeep(chartData?.items);
    switch (throughputType) {
      case ThroughputType.UserStories:
        setCloneData(clone.filter((x) => x.ticketType === ThroughputType.UserStories));
        break;
      case ThroughputType.Defects:
        setCloneData(clone.filter((x) => x.ticketType === ThroughputType.Defects));
        break;
      case ThroughputType.StoriesDefects:
        var res: OverviewChartItem[] = [];
        clone.forEach((item) => {
          var exist = res.find((x) => x.iterationName === item.iterationName);
          if (exist) {
            exist.completedPercent += item.completedPercent;
          } else {
            res.push(item);
          }
        });
        setCloneData(res);
        break;
      default:
        setCloneData(clone.filter((x) => x.ticketType === ThroughputType.UserStories));
        break;
    }
  }, [throughputType, chartData]);

  useEffect(() => {
    if (!chartData) return;

    setTarget(chartData.target);
    setNote(chartData.note);
  }, [chartData]);

  const summaryData = useMemo(() => {
    if (chartData == null) return null;

    // Use .slice to get last 3 velocities (assume it's ASC sorting)
    // const lastItems = chartData.items?.slice(-3).sort((a, b) => b.completedPercent - a.completedPercent) || [];
    const inputData = cloneDeep(cloneData);
    const outputData = [];
    inputData.forEach((item) => {
      var existing = outputData.filter((x) => x.iterationName === item.iterationName);
      if (existing?.length) {
        var existingIndex = outputData.indexOf(existing[0]);
        outputData[existingIndex].completedPercent += item.completedPercent;
      } else {
        outputData.push(item);
      }
    });
    const data = outputData.map((x) => x.completedPercent) || [];
    const max = Math.max(...data);
    const min = Math.min(...data);
    const avg = Math.round(mean(data));
    const temp: SelectOptions<any>[] = [
      {
        label: `Avg: ${avg}`,
        value: 1,
      },
      {
        label: `Highest: ${max}`,
        value: 2,
      },
      {
        label: `Lowest: ${min}`,
        value: 3,
      },
    ];
    return temp;
  }, [cloneData, chartData]);

  const generateColumnConfig = (dataSource: OverviewChartItem[]): ColumnConfig => {
    const customTargetLegend: LegendItem[] = [
      {
        name: 'Over Target',
        value: ChartTargetValue.Higher,
        marker: {
          symbol: 'circle',
          style: {
            fill: ChartTargetColors[ChartTargetValue.Higher],
          },
        },
      },
      {
        name: 'Below Target',
        value: ChartTargetValue.Lower,
        marker: {
          symbol: 'circle',
          style: {
            fill: ChartTargetColors[ChartTargetValue.Lower],
          },
        },
      },
    ];

    const data = [];
    var colors = [InovationHigherTargetColor, InovationLowerTargetColor];

    dataSource.forEach((item) => {
      let newItem = {
        iterationName: item.iterationName,
        completedPercent: item.completedPercent,
        type: item.completedPercent >= chartData.target ? 'Over Target' : 'Below Target',
      };
      data.push(newItem);
    });

    if (data[0]?.type === 'Below Target') colors = [InovationLowerTargetColor, InovationHigherTargetColor];
    if (data?.filter((x) => x.type === 'Over Target')?.length === 0) colors = [InovationLowerTargetColor];
    if (data?.filter((x) => x.type === 'Below Target')?.length === 0) colors = [InovationHigherTargetColor];

    var max = Math.max(...data.map((o) => o.completedPercent));

    return {
      data,
      legend: {
        position: 'top',
        items: customTargetLegend,
        marker: {
          style: {
            width: 30,
            height: 30,
            fontSize: 30,
          },
        },
      },
      maxColumnWidth: 50,
      tooltip: {
        title: (title) => `${title}`,
        customContent: (value, data) => {
          return `
              <div class="tooltip-card">
                <div class="title">${value}</div>
                <div>
                  Card(s): <span class="value">${data[0]?.data.completedPercent}</span>
                </div>
              </div>`;
        },
      },
      isStack: true,
      xField: 'iterationName',
      yField: 'completedPercent',
      seriesField: 'type',
      color: colors,
      label: {
        position: 'middle',
      },
      yAxis: {
        type: 'linear',
        max: max,
        title: {
          text: 'Card(s)',
          style: { fontSize: 16, fontWeight: 600 },
        },
        position: 'left',
        label: {
          formatter: (text) => parseInt(text),
        },
      },
      meta: {
        iterationName: {
          alias: 'Iteration Name',
        },
        completedPercent: {
          alias: 'card(s)',
        },
        type: {
          alias: 'card(s)',
        },
      },
    };
  };

  const onTargetSave = async (value: number) => {
    chartData.target = value;
    const payload = new SaveChartSettingModel();
    payload.target = value;
    payload.note = chartData.note;
    payload.teamId = team.id;
    payload.chartType = reportType;

    setTarget(value);

    return await saveChartSettingRequest(payload);
  };

  const onNoteSave = async (value: string) => {
    chartData.note = value;
    const payload = new SaveChartSettingModel();
    payload.target = chartData.target;
    payload.note = value;
    payload.teamId = team.id;
    payload.chartType = reportType;

    setNote(value);

    return await saveChartSettingRequest(payload);
  };

  return (
    <StyledChart>
      <Row className="throughput-chart-header">
        <Col span={6}>
          <Row>
            <ChartTarget value={target} onSave={onTargetSave} unit=" card(s)" />
          </Row>
        </Col>
        <Col span={13}>
          {cloneData && cloneData.length > 0 && (
            <Row justify="center">
              <Radio.Group options={summaryData} optionType="button" buttonStyle="solid" />
            </Row>
          )}
        </Col>
        <Col span={5} style={{ top: '-44px' }}>
          <Row justify="end">
            <Select
              options={options}
              style={{ width: '100%' }}
              defaultValue={options[0].value}
              onChange={(value) => setThroughputType(value)}
            />
          </Row>
          <Row justify="end" style={{ marginTop: '12px' }}>
            <Select
              options={optionsList}
              style={{ width: '100%' }}
              defaultValue={optionsList[0].value}
              onChange={(value) => setViewType(value)}
            />
          </Row>
        </Col>
      </Row>

      {cloneData?.length > 0 ? <Column {...generateColumnConfig(cloneData)} /> : <Empty />}

      {cloneData && <OverviewNote content={note} onSave={onNoteSave} />}
    </StyledChart>
  );
};

import { Row, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const OganizationModalWrapper = styled(Drawer)`
  .ant-drawer-header {
    text-align: center;
    letter-spacing: 0px;
    color: #404040;
    opacity: 1;
    .ant-drawer-title {
      font-size: 25px;
      text-transform: uppercase;
    }
  }
  .ant-drawer-body {
    .modal-body {
      .title-header {
        text-align: left;
        width: fit-content;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        font-weight: bold;
        font-size: 21px;
        border-bottom: 6px #707070 solid;
        p {
          margin-bottom: 10px;
        }
      }
      .btn {
        width: 100px;
        height: 39px;
        &.first {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .default-no-data-text {
    font-style: italic;
    font-weight: 500;
  }
`;

export const ModalSectionWrapper = styled(Row)`
  margin: 1rem 0;
  .title {
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 15px;
  }
  .line-content {
    margin-bottom: 0;
  }
  .title-content {
    font-weight: 700;
  }
  .dependencies-list {
    margin: 2rem 0;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .stakeholder-name {
    font-style: italic;
  }
`;

export const LinkBoxArrowIcon = styled(Link)`
  color: black;
  vertical-align: text-bottom;
  margin-left: 5px;
`;

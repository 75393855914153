import { Breadcrumb } from 'antd';
import { generateCrumbs } from 'app/common/breadcrumb';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useContext, useEffect, useState } from 'react';
import './ADMBreadcrumb.scss';
import useIsMobile from 'app/utils/useIsMobile';

export const ADMBreadcrumb = () => {
  const [isMobile] = useIsMobile();
  let { breadcrumbs, setBreadcrumbs } = useContext(TeamContext);
  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
  }, [setBreadcrumbs, breadcrumbs]);
  return (
    <Breadcrumb style={{ margin: '13px 0' }} className={isMobile ? 'overflow-breadcumb' : ''}>
      {generateCrumbs(breadcrumbs)}
    </Breadcrumb>
  );
};

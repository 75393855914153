import { OwnershipLevel as OwnershipLevelFull } from './Ownership';

export interface OwnershipProgramData {
  listDate: OwnershipProgramDate[];
  teamStatusDetail: OwnershipTeamStatusDetail[];
  chartLevelByProject: OwnershipLevelItem[];
  chartLevelByPortfolio: OwnershipLevelItem[];
  chartLevelStatistics: OwnershipLevelItem[];
  tableTeamImprove: OwnershipImprove[];
}

export interface OwnershipProgramDate {
  label: string;
  value: string;
}

export interface OwnershipTeamStatusDetail {
  id: number;
  projectName: string;
  projectGroupName: string;
  portfolioName: string;
  level: number;
}

export interface OwnershipTeamStatusDetailGroup {
  groupLevel?: number;
  groupName: string;
  teams: OwnershipTeamStatusDetail[];
}

export enum OwnershipTeamStatusViewType {
  ByLevel = 1,
  ByProject = 2,
  ByPortfolio = 3,
}

export interface OwnershipLevelItem {
  teams: number;
  name: string;
  level: number;
  previousMonth: number;
}

export interface OwnershipImprove {
  name: string;
  teamId: number;
  score: number;
  scoreChange: number;
  level: number;
  levelChange: number;
}

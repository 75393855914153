import { createContext, useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { TagsResponse } from 'app/types/TagsDefinition';
import { fetchProductWithId } from 'app/apis/statisticOrganization';

interface IDefinitionConfigContext {
  techStacks: TagsResponse[];
  setTechStacks: (techStacks: TagsResponse[]) => void;
  keywords: TagsResponse[];
  setKeywords: (keywords: TagsResponse[]) => void;
  workloads: TagsResponse[];
  setWorkloads: (workloads: TagsResponse[]) => void;
}

export const DefinitionConfigContext = createContext<IDefinitionConfigContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const DefinitionConfigContextProvider = ({ children }: IProps) => {
  const [techStacks, setTechStacks] = useState<TagsResponse[]>();
  const [keywords, setKeywords] = useState<TagsResponse[]>();
  const [workloads, setWorkloads] = useState<TagsResponse[]>();

  const { data: originalProducts, error: fetchProductError } = useFetch<TagsResponse[]>(() => fetchProductWithId());
  // console.log(originalProducts);

  useEffect(() => {
    const filteredTechStacks = originalProducts?.filter((x) => x.type === 2) ?? [];
    const filteredKeywords = originalProducts?.filter((x) => x.type === 1) ?? [];
    const filteredWorkloads = originalProducts?.filter((x) => x.type === 0) ?? [];
    setTechStacks(filteredTechStacks);
    setKeywords(filteredKeywords);
    setWorkloads(filteredWorkloads);
  }, [originalProducts]);

  if (fetchProductError) {
    return <div>{JSON.stringify(fetchProductError)}</div>;
  }

  return (
    <DefinitionConfigContext.Provider
      value={{ techStacks, keywords, workloads, setTechStacks, setKeywords, setWorkloads }}
    >
      {children}
    </DefinitionConfigContext.Provider>
  );
};

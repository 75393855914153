import { Tooltip } from 'antd';
import { convertUtcDateToLocalDate } from 'app/common/helpers';
import { useContext, useEffect, useState } from 'react';
import { getLastSyncOnByTeamId } from 'app/apis/systemMonitoringClient';
import { useParams } from 'react-router';
import { useFetch } from 'app/hooks/useFetch';
import './LastSync.scss';
interface IRouteParams {
  teamId?: string;
}
export const LastSync = () => {
  const { teamId } = useParams<IRouteParams>();
  const { data: lastSync } = useFetch(() => getLastSyncOnByTeamId(Number(teamId)), [teamId]);
  const [lastSyncOn, setLastSyncOn] = useState<Date>(null);
  const tzOffset: Number = new Date().getTimezoneOffset() / -60;
  useEffect(() => {
    setLastSyncOn(lastSync);
  }, [teamId, lastSync]);
  return (
    <div className="sync-info-container">
      {lastSyncOn ? (
        <Tooltip
          placement="bottomRight"
          title={`This time is based on your local time (UTC${tzOffset.toStringWithSign()}).`}
        >
          Last synced on{' '}
          {convertUtcDateToLocalDate(new Date(lastSyncOn)).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </Tooltip>
      ) : (
        'Last synced on: Unknown'
      )}
    </div>
  );
};

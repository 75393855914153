import styled from 'styled-components';
import { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';

// --- Adding Select Prefix Icon ----
type CustomSelectProps = SelectProps<typeof SelectWrapper> & {
  prefixIcon?: ReactNode;
};
export const SelectWrapper = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }
  .ant-select-arrow {
    right: 11px !important;
  }
`;
export const SelectWrapperNull = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-select-arrow {
    right: 11px !important;
  }
`;
const CustomSelect = ({ prefixIcon, children, ...rest }: CustomSelectProps) => {
  if (prefixIcon === null) {
    return (
      <SelectWrapperNull>
        {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
        <Select {...rest}>{children}</Select>
      </SelectWrapperNull>
    );
  }
  return (
    <SelectWrapper>
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select {...rest}>{children}</Select>
    </SelectWrapper>
  );
};

export default CustomSelect;

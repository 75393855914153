import { Pie } from '@ant-design/plots';
import { Empty } from 'antd';
import { TestExecutionStateResponse } from 'app/types/TestExecutionStateResponse';
import { useEffect, useMemo, useState } from 'react';

import './TestExecutionState.scss';

interface IProps {
  dataSource: TestExecutionStateResponse[];
}

export const TestExecutionState = ({ dataSource }: IProps) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dataSource) {
      setData(dataSource.filter((x) => x.value !== 0));
    }
  }, [dataSource]);
  const config = useMemo(() => {
    const colors = ['#77c172', '#FF0000', '#707070', '#B4B4B4', '#FF8A8A', '#00AEBB', '#0E50A4'];

    const config = {
      data: dataSource ?? [],
      height: 300,
      appendPadding: 10,
      animation: false,
      angleField: 'value',
      colorField: 'type',
      color: colors,
      radius: 1,
      innerRadius: 0.65,
      label: {
        autoRotate: false,
        type: 'inner',
        offset: '-50%',
        content: ({ percent }) => {
          // Should not show label if percent == 0
          if (percent === 0) {
            return '';
          }
          return `${(percent * 100).toFixed(0)}%`;
        },
        style: {
          textAlign: 'center',
          fontSize: 14,
        },
      },
      interactions: [{ type: 'legend-filter', enable: false }],
      statistic: false,
      tooltip: {
        customContent: (title) => {
          if (dataSource === undefined) {
            return ``;
          }

          let items = ``;

          for (let i = 0; i < dataSource.length; i++) {
            let stateName = dataSource.at(i).type;
            items += `
                <div class="ant-row ${stateName === title ? 'execution-state-active' : 'display-none'}">
                  <div class="ant-col ant-col-20">
                    <span class="ant-badge ant-badge-status ant-badge-not-a-wrapper">
                      <span class="ant-badge-status-dot" style="background: ${colors.at(i)};"></span>
                      <span class="ant-badge-status-text">${stateName}</span>
                    </span>
                  </div>
                  <div class="ant-col ant-col-4 execution-state-lable-count">
                    ${dataSource.at(i).value}
                  </div>                  
                </div>
                `;
          }

          return `<div class="execution-state-tooltip">${items}</div>`;
        },
      },
    };

    return config;
  }, [dataSource]);

  if (data.length < 1) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return <Pie {...config} />;
};

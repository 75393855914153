import { Divider, Row, Select } from 'antd';
import styled from 'styled-components';

export const SearchValueWrapper = styled.span`
  color: ${(props) => props.color};
`;

export const SearchTreeWrapper = styled.div`
  .ant-select-selection-placeholder {
    flex: none;
  }
  min-width: 300px;
`;

export const DividerSearchPopup = styled(Divider)`
  margin: 5px 0;
`;

export const RowAction = styled(Row)`
  padding: 0 5px;
`;

export const SearchBox = styled(Select)`
  margin-bottom: 8;
  width: 600px;
`;

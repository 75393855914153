import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Carousel, Col, Row, Tooltip } from 'antd';
import { PROGRAM_OVERVIEW_BEST_PERFORMER_NUMBER } from 'app/common/constants';
import { ProgramOverviewBestPerformer } from 'app/types/ProgramOverviewModel';
import { chunk } from 'lodash';
import { StyledProgramBestPerformer } from './ProgramBestPerformer.style';

interface IProps {
  data: ProgramOverviewBestPerformer[];
}

export const ProgramBestPerformer = ({ data }: IProps) => {
  const pages = chunk(data, PROGRAM_OVERVIEW_BEST_PERFORMER_NUMBER);

  return (
    <StyledProgramBestPerformer>
      <Carousel arrows nextArrow={<RightOutlined />} prevArrow={<LeftOutlined />}>
        {pages.map((items, index) => (
          <Row key={index}>
            {items.map((item, index) => (
              <Col key={index} xxl={6} lg={12}>
                <Tooltip
                  title={
                    <>
                      <div>{item.caption}</div>
                    </>
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#000' }}
                >
                  <Row>
                    <Col xs={12}>
                      <Avatar src={item.imageUrl} />
                    </Col>
                    <Col xs={12}>
                      <div className="user-name">{item.name}</div>
                      <div className="team-name">{item.teamName}</div>
                    </Col>
                  </Row>
                </Tooltip>
              </Col>
            ))}
          </Row>
        ))}
      </Carousel>
    </StyledProgramBestPerformer>
  );
};

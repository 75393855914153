import { IComplimentRequest, IComplimentResponse } from 'app/types/entity';
import { fetchAsync } from 'app/utils/fetch';

export const getCompliments = (iterationId: number): Promise<IComplimentResponse[]> => {
  const url = `/api/compliments?iterationId=${iterationId}`;
  return fetchAsync(url, { method: 'GET' });
};
export const createCompliment = (payload: IComplimentRequest): Promise<IComplimentResponse> => {
  const data = new FormData();

  data.append('title', payload.title);
  data.append('message', payload.message);
  data.append('from', payload.from);
  data.append('iterationId', payload.iterationId.toString());

  payload.uploadImages?.forEach((x) => {
    data.append('uploadImages', x);
  });
  const url = `/api/compliments`;
  return fetchAsync(url, { type: 'text', method: 'POST', body: data });
};
export const editCompliment = (payload: IComplimentRequest): Promise<IComplimentResponse> => {
  const url = `/api/compliments/${payload.id}`;
  const data = new FormData();

  data.append('title', payload.title);
  data.append('message', payload.message);
  data.append('from', payload.from);
  data.append('iterationId', payload.iterationId.toString());
  data.append('complimentImages', JSON.stringify(payload.complimentImages));
  payload.uploadImages?.forEach((x) => {
    data.append('uploadImages', x);
  });

  return fetchAsync(url, { type: 'text', method: 'PUT', body: data });
};
export const deleteCompliment = (complimentId: number): Promise<IComplimentResponse> => {
  const url = `/api/compliments/${complimentId}`;
  return fetchAsync(url, { method: 'DELETE' });
};

export const fetchComplimentOverview = (teamId: number, iterationName: string): Promise<IComplimentResponse[]> => {
  const url = `/api/compliments/overview?teamId=${teamId}&&iterationName=${iterationName}`;
  return fetchAsync(url, { method: 'GET' });
};

import styled from 'styled-components';

export const TeamCapacityContainer = styled.div`
  border: 1px solid #d9d9d9;
  position: relative;

  .red-text {
    color: red;
  }
  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    padding-bottom: 0;
    border: none;
    margin: 0;
  }

  .ant-progress-inner {
    border-radius: 0;
  }
  .ant-progress-bg {
    height: 15px !important;
  }
  .ant-table-thead {
    //@at-root: nowrap;
  }
  .ant-progress-inner {
    border: 1px solid #d9d9d9;
    padding: 3px 0px 3px 0px;
  }
  .ant-table-cell-ellipsis span {
    white-space: normal !important;
  }
  .progress-wrapper {
    .bar {
      width: 82%;
    }
    .percent {
      margin-left: 5px;
      width: 20%;
      fontsize: 1em;
    }
    &.over-capacity {
      .percentage-value {
        color: red;
      }
    }
  }
  .rowTotal_TeamDetail {
    font-weight: bold !important;
    background-color: #f3c300;
  }
`;

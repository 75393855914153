import { MonitorStatusResponse, SyncTeamModel } from 'app/types/SystemMonitoring';
import { fetchAsync } from '../utils/fetch';

export const fetchStatus = (): Promise<MonitorStatusResponse[]> => fetchAsync('/api/systemmonitoring');
export const triggerSync = (teamId: number, syncSetting: SyncTeamModel): Promise<MonitorStatusResponse> =>
  fetchAsync(`/api/systemmonitoring/TriggerSync/${teamId}`, {
    method: 'POST',
    body: syncSetting,
  });

export const getStatusByTeamId = (teamId: number): Promise<MonitorStatusResponse> =>
  fetchAsync(`/api/systemmonitoring/team/${teamId}`, { method: 'GET' });

export const getLastSyncOnByTeamId = (teamId: number): Promise<Date> =>
  fetchAsync(`/api/systemmonitoring/lastsync/${teamId}`, { method: 'GET' });

export const triggerSyncHighLevelTeam = (releaseTrainId: number): Promise<MonitorStatusResponse> =>
  fetchAsync(`/api/systemmonitoring/triggersynchighlevelteam/${releaseTrainId}`, { method: 'POST' });

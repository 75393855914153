import { Col } from 'antd';
import { OjectTeamDataResponse } from 'app/types/Organizational';
import { hex2rgba } from 'app/utils/utils';
import React from 'react';
import { DeliveryStreamWrapper } from '../OrginazationChart.styled';
import ReleaseTrainNode from './ReleaseTrainNode';

interface IProps {
  deliveryStream: OjectTeamDataResponse;
  color: string;
  setCurrentTeam: (id: number) => void;
}

function DeliveryStreamNode({ deliveryStream, color, setCurrentTeam }: IProps) {
  const rgbaDelivery = hex2rgba(color, 0.8);
  return (
    <DeliveryStreamWrapper color={rgbaDelivery}>
      <Col span={5} className="delivery-stream-row">
        <span>{deliveryStream.name}</span>
      </Col>
      {deliveryStream?.childrenItems?.length > 0 && (
        <Col span={18} className="release-train-and-team-row">
          {deliveryStream?.childrenItems?.map((releasetrain, i) => (
            <ReleaseTrainNode releaseTrain={releasetrain} key={i} color={color} setCurrentTeam={setCurrentTeam} />
          ))}
        </Col>
      )}
    </DeliveryStreamWrapper>
  );
}

export default DeliveryStreamNode;

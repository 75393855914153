import { QuarterReleaseNote } from 'app/types/entity';
import { fetchAsync } from 'app/utils/fetch';

export const createQuarterReleaseNote = (action: QuarterReleaseNote): Promise<QuarterReleaseNote> => {
  return fetchAsync(`/api/iterationreport/quarterReleasePlanNote`, {
    method: 'POST',
    body: action,
  });
};

export const updateQuarterReleaseNote = (action: QuarterReleaseNote): Promise<QuarterReleaseNote> => {
  return fetchAsync(`/api/iterationreport/quarterReleasePlanNote`, {
    method: 'PUT',
    body: action,
  });
};

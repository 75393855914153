import { Col, Collapse, Row } from 'antd';
import { StyleHallOfFameSection } from './HallOfFameSection.styled';
import { RecognitionCard } from './RecognitionCard';
import { AwardModel, AwardResponse } from 'app/types/AllAwardType';

const { Panel } = Collapse;

interface IProps {
  data: AwardModel[];
  onclick: (value) => void;
}
export const HallOfFameSection = ({ data: reconization, onclick }: IProps) => {
  const Header = <>{reconization[0].requestType.title}</>;
  return (
    <StyleHallOfFameSection>
      <Collapse defaultActiveKey={['1']}>
        <Panel header={Header} key="1">
          <Row gutter={[20, 20]}>
            {reconization?.map((e, index) => (
              <Col span={6} key={index}>
                <RecognitionCard award={e} onclick={onclick} />
              </Col>
            ))}
          </Row>
        </Panel>
      </Collapse>
    </StyleHallOfFameSection>
  );
};

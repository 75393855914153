import styled from 'styled-components';

export const TeamCapacityMonitorContainer = styled.div`
  border: 1px solid #d9d9d9;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    padding-bottom: 0;
    border: none;
    margin: 0;
  }
  .ant-progress-inner {
    border-radius: 0;
  }
  .ant-progress-bg {
    height: 15px !important;
  }
  .ant-table-thead {
    //@at-root: nowrap;
  }
  .ant-progress-inner {
    border: 1px solid #d9d9d9;
    padding: 3px 0px 3px 0px;
  }
  .ant-table-content {
    table {
      table-layout: auto !important;
    }
    .ant-col-20 {
      flex: 0 0 80% !important;
      max-width: 80% !important;
    }
  }
  .progress-wrapper {
    .percentage-value {
      padding: 3px 0px;
    }
    &.over-capacity {
      .percentage-value {
        color: red;
      }
    }
  }
  .rowTotal_CapacityDetail {
    font-weight: bold !important;
    background-color: #f3c300;
  }
`;
export const ModalContentContainer = styled.div`
  position: relative;
  min-height: 378px;
  padding-top: 10px;
  padding: 10px 25px 0px 25px;
  & .title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    padding-bottom: 15px;
    position: relative;
  }
  .ant-progress-inner {
    border-radius: 0;
  }
  .ant-progress-bg {
    height: 15px !important;
  }
  .ant-table-thead {
    //@at-root: nowrap;
  }
  .ant-progress-inner {
    border: 1px solid #d9d9d9;
    padding: 3px 0px 3px 0px;
  }
  .container-table {
    .ant-table-content {
      border: 1px solid #d9d9d9;
    }
    .ant-table-thead > tr > th {
      font-size: 15px;
      font-weight: 700;
      color: #000000;
    }
    .ant-table-body {
      //body scrollbar
      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 5px;
      }
    }
    .ant-table-tbody > tr > td {
      font-size: 14px;
      font-weight: 500;
    }
    .ant-table.ant-table-small .ant-table-thead > tr > th {
      // Name Column
      padding: 3px 1px;
      :nth-child(2) {
        padding: 3px 11px;
      }
    }
    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      // Progress bar Column
      padding: 3px 11px;
    }
    .ant-table.ant-table-small .ant-table-thead > tr > th,
    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      :nth-child(3) {
        padding: 3px 11px;
        .ant-table-column-sorters {
          .ant-table-column-title {
            text-align: center;
          }
        }
      }
    }
    .red-text,
    .red-text + .normal-effort {
      color: red;
    }
    .highlight-effort {
      border-radius: 50%;
      background-clip: content-box !important;
      color: white;
      text-align: center;
    }
    .not-update-effort {
      background-color: #fd0000 !important;
      border-radius: 50%;
    }

    .effort-decrease {
      background-color: #dfb300 !important;
    }
  }
  .bar.ant-progess-outer.ant-progess-inner.ant-progress-bg {
    height: 12px !important;
  }
  .progress-wrapper {
    .percentage-value {
      text-align: center;
      padding: 3px 0px;
    }
    &.over-capacity {
      .percentage-value {
        color: red;
      }
    }
  }
  .btn-close {
    margin-top: 25px;
    padding: 0 25px;
    font-weight: bold;
    border: solid 1px #f0f0f0;
    background-color: #f0f0f0;
  }
  .btn-close:hover {
    border: solid 1px #a19e9e;
    color: #595959;
    background-color: #f0f0f0;
  }
  .rowTotal_CapacityDetail {
    font-weight: bold !important;
    background-color: #f3c300;
  }
`;

const StoryIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 28" version="1.1">
      <path
        d="M -0.002 0.750 C -0.004 1.163, 4.159 1.380, 9.248 1.234 C 26.043 0.750, 27.697 0.363, 13.750 0.180 C 6.188 0.081, -0.001 0.337, -0.002 0.750 M 0 1.500 C 0 1.775, 0.225 2, 0.500 2 C 0.775 2, 1 1.775, 1 1.500 C 1 1.225, 0.775 1, 0.500 1 C 0.225 1, 0 1.225, 0 1.500 M 8.713 6.621 C 8.321 7.013, 8 10.736, 8 14.894 L 8 22.455 10.712 19.743 L 13.423 17.031 16.258 19.747 L 19.092 22.463 18.796 14.481 L 18.500 6.500 13.963 6.204 C 11.467 6.041, 9.105 6.229, 8.713 6.621 M 0 27 C 0 27.550, 0.477 28, 1.059 28 C 1.641 28, 1.840 27.550, 1.500 27 C 1.160 26.450, 0.684 26, 0.441 26 C 0.198 26, 0 26.450, 0 27 M 24.333 27.667 C 24.700 28.033, 25.300 28.033, 25.667 27.667 C 26.033 27.300, 25.733 27, 25 27 C 24.267 27, 23.967 27.300, 24.333 27.667"
        stroke="none"
        fill="#eff9e7"
        fillRule="evenodd"
      />
      <path
        d="M 0.667 1.667 C -0.565 2.899, -0.086 25.514, 1.200 26.800 C 1.984 27.584, 6.117 28, 13.129 28 C 27.285 28, 27 28.309, 27 12.929 L 27 1 14.167 1 C 7.108 1, 1.033 1.300, 0.667 1.667 M 0.385 14 C 0.385 20.875, 0.545 23.688, 0.740 20.250 C 0.936 16.813, 0.936 11.188, 0.740 7.750 C 0.545 4.313, 0.385 7.125, 0.385 14 M 8.713 6.621 C 8.321 7.013, 8 10.736, 8 14.894 L 8 22.455 10.712 19.743 L 13.423 17.031 16.258 19.747 L 19.092 22.463 18.796 14.481 L 18.500 6.500 13.963 6.204 C 11.467 6.041, 9.105 6.229, 8.713 6.621"
        stroke="none"
        fill="#86bf6a"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default StoryIcon;

import { Empty, Table, TablePaginationConfig } from 'antd';
import { GroupedTeamLinks, HighlighterColor, TABLE_DEFAULT_PAGE_SIZE } from 'app/common/constants';
import { DetailTableData } from 'app/types/GroupedTeamModel';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import './Detail.scss';
import { DetailTableContainer } from './DetailTable.styled';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { TeamParentContext } from 'app/contexts/TeamParentContext';
import { Link } from 'react-router-dom';

interface IProps {
  titleColumn: string;
  teamType: number;
}

function Detail({ titleColumn, teamType }: IProps) {
  const [dataDetail, setDataDetail] = useState<DetailTableData[]>();
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const { loading, iterationData } = useContext(TeamParentContext);

  useEffect(() => {
    setDataDetail(iterationData?.planData?.detail);
  }, [iterationData]);

  const renderTotalVelocityColumn = (record: DetailTableData) => {
    return {
      props: {
        style: { background: record.targetVelocity === 0 ? HighlighterColor : '' },
      },
      children: <div>{record.targetVelocity}</div>,
    };
  };

  const renderPlannedVelocityColumn = (record: DetailTableData) => {
    return {
      props: {
        style: { background: record.plannedVelocity === 0 ? HighlighterColor : '' },
      },
      children: <div>{record.plannedVelocity}</div>,
    };
  };

  const renderTotalCapacityColumn = (record: DetailTableData) => {
    return {
      props: {
        style: { background: record.targetCapacity === 0 ? HighlighterColor : '' },
      },
      children: <div>{record.targetCapacity}</div>,
    };
  };

  const renderPlannedCapacityColumn = (record: DetailTableData) => {
    return {
      props: {
        style: { background: record.planCapacity === 0 ? HighlighterColor : '' },
      },
      children: <div>{record.planCapacity}</div>,
    };
  };

  const renderFeaturesColumn = (record: DetailTableData) => {
    return {
      props: {
        style: { background: record.feature === 0 ? HighlighterColor : '' },
      },
      children: <div>{record.feature}</div>,
    };
  };

  const renderUserStoriesColumn = (record: DetailTableData) => {
    return {
      props: {
        style: { background: record.userStories === 0 ? HighlighterColor : '' },
      },
      children: <div>{record.userStories}</div>,
    };
  };

  const renderDefectsColumn = (record: DetailTableData) => {
    return {
      children: <div>{record.defects}</div>,
    };
  };

  const renderTasksColumn = (record: DetailTableData) => {
    return {
      props: {
        style: { background: record.tasks === 0 ? HighlighterColor : '' },
      },
      children: <div>{record.tasks}</div>,
    };
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const renderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`/${GroupedTeamLinks[teamType + 1]}/${record.teamId}`}>
          {text}
        </Link>
      </>
    );
  };

  const columns = useMemo<EditableColumn<DetailTableData>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: titleColumn,
        dataIndex: 'name',
        width: '33%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (value, record) => renderLink(value, record),
      },
      {
        title: 'Total\nCapacity',
        dataIndex: 'totalCapacity',
        sorter: {
          compare: (a, b) => a.targetCapacity - b.targetCapacity,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderTotalCapacityColumn(record),
      },
      {
        title: 'Planned\nCapacity',
        dataIndex: 'planCapacity',
        sorter: {
          compare: (a, b) => a.planCapacity - b.planCapacity,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderPlannedCapacityColumn(record),
      },
      {
        title: 'Total\nVelocity',
        dataIndex: 'totalVelocity',
        sorter: {
          compare: (a, b) => a.targetVelocity - b.targetVelocity,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderTotalVelocityColumn(record),
      },
      {
        title: 'Planned\nVelocity',
        dataIndex: 'plannedVelocity',
        sorter: {
          compare: (a, b) => a.plannedVelocity - b.plannedVelocity,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderPlannedVelocityColumn(record),
      },

      {
        title: 'Features',
        dataIndex: 'feature',
        sorter: {
          compare: (a, b) => a.feature - b.feature,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderFeaturesColumn(record),
      },
      {
        title: 'User Stories',
        dataIndex: 'userStories',
        sorter: {
          compare: (a, b) => a.userStories - b.userStories,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderUserStoriesColumn(record),
      },
      {
        title: 'Defects',
        dataIndex: 'defects',
        sorter: {
          compare: (a, b) => a.defects - b.defects,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderDefectsColumn(record),
      },
      {
        title: 'Tasks',
        dataIndex: 'tasks',
        sorter: {
          compare: (a, b) => a.tasks - b.tasks,
        },
        width: '8%',
        align: 'right',
        render: (value, record, index) => renderTasksColumn(record),
      },
    ],
    [page, pageSize]
  );
  return (
    <DetailTableContainer>
      <EditableTable
        columns={columns}
        data={dataDetail}
        onChange={onChange}
        showAction={false}
        className="iteration-detail-table"
        id="release-train-detail-table"
        loading={loading}
      />
    </DetailTableContainer>
  );
}

export default Detail;

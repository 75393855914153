import React, { useEffect, useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Select, Tag } from 'antd';
import { Entity, SelectOptions } from '../types/entity';
import { CaretDownOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { ColumnGroupType, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { NUMBER_REGEX_FORMAT, PARSE_NUMBER_REGEX_FORMAT } from 'app/common/constants';
import { isUndefined } from 'lodash';

interface inputProps<T> {
  style?: React.CSSProperties;
  searchOnSelect?: boolean;
  withEdit?: boolean;
  withChangePassword?: boolean;
  withDelete?: boolean;
  mode?: 'multiple' | 'tags';
  customOptionsFilter?: (record: T, option: SelectOptions<any>) => boolean;
  min?: number;
  max?: number;
}

export interface EditableColumn<T> extends ColumnType<T> {
  inputType?: 'number' | 'text' | 'select' | 'custom' | 'colorSelect';
  inputProps?: inputProps<T>;
  options?: SelectOptions<any>[];
  editable?: boolean;
  editingContent?: (record: T) => JSX.Element;
  rules?: any[];
  visible?: boolean;
}

interface EditableCellProps<T extends Entity> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  editingContent?: JSX.Element;
  dataIndex: string;
  title: any;
  inputType?: 'number' | 'text' | 'select' | 'custom' | 'colorSelect';
  inputProps?: inputProps<T>;
  record: T;
  index: number;
  children: React.ReactNode;
  rules?: any[];
  options?: SelectOptions<any>[];
}

export function EditableCell<T extends Entity>({
  editing,
  editingContent,
  dataIndex,
  title,
  inputType,
  inputProps,
  record,
  index,
  children,
  rules,
  options,
  ...restProps
}: EditableCellProps<T>) {
  let inputNode = <Input />;
  let content = children;
  switch (inputType) {
    case 'number':
      inputNode = (
        <InputNumber
          formatter={(value: number | string) => value.toString().replace(NUMBER_REGEX_FORMAT, ',')}
          parser={(value: string) => value.replace(PARSE_NUMBER_REGEX_FORMAT, '')}
          min={inputProps?.min}
          max={inputProps?.max}
        />
      );
      break;
    case 'select':
      if (inputProps?.customOptionsFilter) {
        options = options.filter((opt) => inputProps.customOptionsFilter(record, opt));
      }
      inputNode = (
        <Select
          options={options}
          showSearch={inputProps?.searchOnSelect ?? false}
          mode={inputProps?.mode ?? null}
          optionFilterProp="label"
          filterOption={(value, option) => option.label.toString().toLowerCase().includes(value.toLowerCase())}
          suffixIcon={<CaretDownOutlined />}
          {...inputProps}
        />
      );
      content = options?.find((item) => item.value === record[dataIndex])?.label ?? children;
      break;
    case 'custom':
      inputNode = editingContent;
      break;
    case 'colorSelect':
      let item = options?.find((x) => x.label === record[dataIndex]);
      inputNode = (
        <Select suffixIcon={<SearchOutlined />} {...inputProps}>
          {options.map((x) => {
            return (
              <span key={x.label} style={{ backgroundColor: x.label }}>
                {x.value}
              </span>
            );
          })}
        </Select>
      );
      content = (
        <Tag style={{ color: 'black' }} key={item?.label} color={item?.label}>
          {item?.value}
        </Tag>
      );
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
          {inputNode}
        </Form.Item>
      ) : (
        content
      )}
    </td>
  );
}

interface IProps<T> extends TableProps<T> {
  data: T[];
  columns: EditableColumn<T>[];
  onSave?: (id: number, record: T) => Promise<any>;
  saveButtonText?: string;
  onDelete?: (id: number, record?: T) => Promise<any>;
  onChangePassword?: (id: number) => Promise<any>;
  onEdit?: (id: number) => Promise<any>;
  onCancel?: () => void;
  onEditRecord?: (record: T) => void;
  isChangePassword?: boolean;
  isEdit?: boolean;
  isDelete?: boolean;
  pagination?: TablePaginationConfig;
  totalRows?: number;
  current?: number;
  pageSize?: number;
  showSizeChanger?: boolean;
  showAction?: boolean;
  rowClassName?: (record, index) => string;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => void;
}

export function EditableTable<T extends Entity>({
  data: originData,
  columns,
  onSave,
  onDelete,
  onChangePassword,
  onEdit,
  onCancel,
  onEditRecord,
  pagination,
  onChange,
  isChangePassword,
  isEdit,
  isDelete,
  totalRows,
  current,
  pageSize,
  saveButtonText = 'Save',
  showSizeChanger = true,
  showAction = true,
  ...rest
}: IProps<T>) {
  const [form] = Form.useForm();
  const [data, setData] = useState<T[]>(originData);
  const [editingKey, setEditingKey] = useState<number>();

  useEffect(() => {
    setData(originData);
  }, [originData]);

  const isEditing = (record: T) => record.id === editingKey;

  const internalOperationColumn: EditableColumn<T> = {
    title: 'Action',
    dataIndex: 'operation',
    align: 'center',
    width: 100,
    // eslint-disable-next-line react/display-name
    render: (_: any, record: T) => {
      const editable = isEditing(record);
      return editable ? (
        <span data-html2canvas-ignore>
          <Button type="link" onClick={() => save(record)}>
            {saveButtonText}
          </Button>
          <Popconfirm title="Are you sure?" onConfirm={cancel}>
            <Button type="link">Cancel</Button>
          </Popconfirm>
        </span>
      ) : (
        <span data-html2canvas-ignore>
          {isEdit && (
            <Button
              type="link"
              disabled={Boolean(editingKey)}
              onClick={() => (onEdit != null ? onEdit(record.id) : edit(record))}
            >
              Edit
            </Button>
          )}
          {isDelete && (
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id, record)}>
              <Button type="link">Delete</Button>
            </Popconfirm>
          )}
          {isChangePassword && (
            <Popconfirm
              title="Do you want to reset password?"
              icon={<QuestionCircleOutlined style={{ color: '#0172db' }} />}
              onConfirm={() => handleChangePassword(record.id)}
            >
              <Button type="link">Reset password</Button>
            </Popconfirm>
          )}
        </span>
      );
    },
  };

  const userOperationColumn = columns.filter((x) => x.dataIndex === 'operation')[0];

  const operationColumn: EditableColumn<T> = !isUndefined(userOperationColumn)
    ? {
        align: 'center',
        width: 100,
        ...userOperationColumn,
        render: (value, record, index) => {
          const props = userOperationColumn?.inputProps;

          const editable = isEditing(record);
          return editable ? (
            <span>
              <Button type="link" onClick={() => save(record)}>
                {saveButtonText}
              </Button>
              <Popconfirm title="Are you sure?" onConfirm={cancel}>
                <Button type="link">Cancel</Button>
              </Popconfirm>
            </span>
          ) : (
            <span style={props?.style}>
              {props?.withEdit && (
                <Button
                  type="link"
                  disabled={Boolean(editingKey)}
                  onClick={() => (onEdit != null ? onEdit(record.id) : edit(record))}
                >
                  Edit
                </Button>
              )}
              {props?.withDelete && (
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id, record)}>
                  <Button type="link">Delete</Button>
                </Popconfirm>
              )}
              {props?.withChangePassword && (
                <Popconfirm title="Do you want to reset password?" onConfirm={() => handleChangePassword(record.id)}>
                  <Button type="link">Reset password</Button>
                </Popconfirm>
              )}
              {userOperationColumn.render && userOperationColumn.render(value, record, index)}
            </span>
          );
        },
      }
    : internalOperationColumn;

  const edit = (record: T) => {
    if (onEditRecord) {
      onEditRecord(record);
    }
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };
  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
    setEditingKey(null);
  };

  const handleDelete = async (id: number, record: T) => {
    try {
      await onDelete?.(id, record);
    } catch (error) {}
  };

  const handleChangePassword = async (id: number) => {
    try {
      await onChangePassword?.(id);
    } catch (error) {}
  };

  const save = async (record: T) => {
    try {
      const row = (await form.validateFields()) as T;
      await onSave?.(record.id, { ...record, ...row });
      setEditingKey(null);
    } catch (errInfo) {}
  };

  const makeEditableColumn = (col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: T) => ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          options: col.options,
        }),
      } as EditableColumn<T>;
    }

    return {
      ...col,
      onCell: (record: T) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        inputProps: col.inputProps,
        rules: col.rules,
        options: col.options,
        title: col.title,
        editing: isEditing(record),
        editingContent: col.editingContent && col.editingContent(record),
      }),
    } as EditableColumn<T>;
  };

  columns = columns.filter((c) => c.visible !== false && c.dataIndex !== 'operation');

  const mergedColumns = (!showAction ? columns : [...columns, operationColumn]).map((col: EditableColumn<T>) => {
    const colGroup = col as ColumnGroupType<T>;
    if (colGroup.children) {
      colGroup.children = colGroup.children.map((childCol) => makeEditableColumn(childCol));
      return colGroup;
    }

    return makeEditableColumn(col);
  });

  return (
    <Form form={form} component={false} className="editable-form">
      <Table
        rowClassName="editable-row"
        bordered
        {...rest}
        size="small"
        rowKey="id"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={data}
        columns={mergedColumns}
        pagination={{
          pageSize: pageSize,
          current: current,
          total: totalRows,
          ...pagination,
          showSizeChanger: showSizeChanger,
          onChange: (page: number, pageSize: number) => {
            cancel();
            pagination?.onChange(page, pageSize);
          },
        }}
        onChange={onChange}
      />
    </Form>
  );
}

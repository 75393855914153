import { useContext, useEffect, useMemo, useState } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import {
  ChartTargetColors,
  ChartTargetValue,
  InovationHigherTargetColor,
  InovationLowerTargetColor,
} from 'app/common/constants';
import { Col, Empty, Radio, Row, Select } from 'antd';
import { SelectOptions } from 'app/types/entity';
import { ChartTarget } from '../ChartTarget/ChartTarget';
import { StyledChart } from '../Charts.styled';
import { ReportType } from 'app/types/CacheReportModel';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { useFetch } from 'app/hooks/useFetch';
import { fetchOverviewVelocity, saveChartSettingRequest } from 'app/apis/fetchTeamOverview';
import { OverviewChartItem } from '../../../../types/OverviewChartData';
import { SaveChartSettingModel } from 'app/types/SaveChartSettingRequest';
import { OverviewNote } from '../../OverviewNote/OverviewNote';
import { mean, uniq } from 'lodash';
import { IterationContext } from '../../../../contexts/IterationContext';
import { getOverviewQuarterSelectOptions } from 'app/common/helpers';

const reportType = ReportType.OverviewVelocity;

export const OverviewVelocityChart = () => {
  const { team } = useContext(TeamDetailContext);
  const optionsList = getOverviewQuarterSelectOptions();
  const [viewType, setViewType] = useState(optionsList[0].value);
  const { data: chartData } = useFetch(() => fetchOverviewVelocity(team?.id, viewType), [team, viewType]);
  const [target, setTarget] = useState(chartData?.target ?? 0);
  const [note, setNote] = useState(chartData?.note ?? '');

  useEffect(() => {
    if (!chartData) return;

    setTarget(chartData.target);
    setNote(chartData.note);
  }, [chartData]);

  const summaryData = useMemo(() => {
    if (chartData == null) return null;

    // Use .slice to get last 3 velocities (assume it's ASC sorting)
    // const lastItems = chartData.items?.slice(-3).sort((a, b) => b.completedPercent - a.completedPercent) || [];
    const data = chartData.items?.map((x) => x.completedPercent) || [];
    const max = Math.max(...data);
    const min = Math.min(...data);
    const avg = Math.round(mean(data));
    const temp: SelectOptions<any>[] = [
      {
        label: `Avg: ${avg} pts`,
        value: 1,
      },
      {
        label: `Highest: ${max} pts`,
        value: 2,
      },
      {
        label: `Lowest: ${min} pts`,
        value: 3,
      },
    ];
    return temp;
  }, [chartData]);

  const generateColumnConfig = (dataSource: OverviewChartItem[]): ColumnConfig => {
    const customTargetLegend: LegendItem[] = [
      {
        name: 'Over Target',
        value: ChartTargetValue.Higher,
        marker: {
          symbol: 'circle',
          style: {
            fill: ChartTargetColors[ChartTargetValue.Higher],
          },
        },
      },
      {
        name: 'Below Target',
        value: ChartTargetValue.Lower,
        marker: {
          symbol: 'circle',
          style: {
            fill: ChartTargetColors[ChartTargetValue.Lower],
          },
        },
      },
    ];

    const data = [];
    var colors = [InovationHigherTargetColor, InovationLowerTargetColor];

    dataSource.forEach((item) => {
      let newItem = {
        iterationName: item.iterationName,
        completedPercent: item.completedPercent,
        type: item.completedPercent >= chartData.target ? 'Over Target' : 'Below Target',
      };
      data.push(newItem);
    });

    if (data[0]?.type === 'Below Target') colors = [InovationLowerTargetColor, InovationHigherTargetColor];
    if (data?.filter((x) => x.type === 'Over Target')?.length === 0) colors = [InovationLowerTargetColor];
    if (data?.filter((x) => x.type === 'Below Target')?.length === 0) colors = [InovationHigherTargetColor];

    var max = Math.max(...data.map((o) => o.completedPercent));

    return {
      data,
      legend: {
        position: 'top',
        items: customTargetLegend,
        marker: {
          style: {
            width: 30,
            height: 30,
            fontSize: 30,
          },
        },
      },
      maxColumnWidth: 50,
      tooltip: {
        title: (title) => `${title}`,
        customContent: (value, data) => {
          return `
              <div class="tooltip-card">
                <div class="title">${value}</div>
                <div>
                 Point(s): <span class="value">${data[0]?.data.completedPercent} pts</span>
                </div>
              </div>`;
        },
      },
      isStack: true,
      xField: 'iterationName',
      yField: 'completedPercent',
      seriesField: 'type',
      color: colors,
      label: {
        position: 'middle',
      },
      yAxis: {
        type: 'linear',
        max: max,
        title: {
          text: 'Point(s)',
          style: { fontSize: 16, fontWeight: 600 },
        },
        position: 'left',
        label: {
          formatter: (text) => parseInt(text),
        },
      },
      meta: {
        iterationName: {
          alias: 'Iteration Name',
        },
        completedPercent: {
          alias: 'point(s)',
        },
        type: {
          alias: 'point(s)',
        },
      },
    };
  };

  const onTargetSave = async (value: number) => {
    chartData.target = value;
    const payload = new SaveChartSettingModel();
    payload.target = value;
    payload.note = chartData.note;
    payload.teamId = team.id;
    payload.chartType = reportType;

    setTarget(value);

    return await saveChartSettingRequest(payload);
  };

  const onNoteSave = async (value: string) => {
    chartData.note = value;
    const payload = new SaveChartSettingModel();
    payload.target = chartData.target;
    payload.note = value;
    payload.teamId = team.id;
    payload.chartType = reportType;

    setNote(value);

    return await saveChartSettingRequest(payload);
  };

  return (
    <StyledChart>
      <Row>
        <Col span={6}>
          <Row>
            <ChartTarget value={target} onSave={onTargetSave} unit=" pts" />
          </Row>
        </Col>
        <Col span={13}>
          {chartData && chartData.items.length > 0 && (
            <Row justify="center">
              <Radio.Group options={summaryData} optionType="button" buttonStyle="solid" />
            </Row>
          )}
        </Col>
        <Col span={5}>
          <Row justify="end">
            <Select
              options={optionsList}
              style={{ width: '100%' }}
              defaultValue={optionsList[0].value}
              onChange={(value) => setViewType(value)}
            />
          </Row>
        </Col>
      </Row>

      {chartData && chartData.items?.length > 0 ? <Column {...generateColumnConfig(chartData?.items)} /> : <Empty />}

      {chartData && <OverviewNote content={note} onSave={onNoteSave} />}
    </StyledChart>
  );
};

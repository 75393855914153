import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Row, Upload, Image, Radio, Select, InputNumber } from 'antd';

import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { CustomReportRequest, CustomReportResponse } from 'app/types/entity';
import { getBase64 } from 'app/utils/uploadUtils';
import { useEffect, useState } from 'react';
import { formAction } from './CustomReport';
import './CustomReportForm.scss';
import { isEmpty } from 'lodash';
import { CustomReportType, CustomReportTypeOptions, UnitOptions } from 'app/types/TeamDetail';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const showErrorNotification = (message: string, description: string) => {
  notification.open({
    message: message,
    description: description,
    // onClick: () => {
    //   console.log(description);
    // },
  });
};

interface IProps {
  action: formAction;
  customReportData: CustomReportResponse;
  onSubmit: (form: CustomReportRequest) => void;
  onCloseModal: () => void;
  iterationId: number;
}

export const CustomReportForm = (props: IProps) => {
  const [form] = Form.useForm();
  const [uploadFile, setUpLoadFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [reportType, setReportType] = useState<number>();
  const [inputType, setInputType] = useState<number>(UnitOptions[0].value);

  useEffect(() => {
    setReportType(props.customReportData?.reportType);
    setInputType(+props.customReportData?.unit || UnitOptions[0].value);
    form.setFieldsValue({
      title: props.customReportData?.title,
      note: isEmpty(props.customReportData?.note) ? '' : JSON.parse(props.customReportData?.note),
      url: props.customReportData?.url,
      value: props.customReportData?.reportMainData,
      unit: +props.customReportData?.unit || UnitOptions[0].value,
      reportType: props.customReportData?.reportType,
    });
    setImageUrl(props.customReportData?.url);
  }, [props.customReportData]);

  const imageChange = (file) => {
    getBase64(file, (imageUrl) => {
      setImageUrl(imageUrl);
    });
  };

  const handleSubmitForm = () => {
    let formValues = form.getFieldsValue(['title', 'note', 'reportType', 'value', 'unit']);
    const payload: CustomReportRequest = {
      id: props.customReportData.id,
      title: formValues.title,
      iterationId: props.iterationId,
      note: JSON.stringify(formValues.note),
      uploadImages: uploadFile,
      url: props.customReportData.url,
      reportType: formValues.reportType,
      reportMainData: formValues.value,
      unit: formValues.unit,
    };
    props.onSubmit(payload);
    form.resetFields();
    props.onCloseModal();
  };

  const handleChangeReportType = (value) => {
    setReportType(value);
    if (value === CustomReportType.ValueReport) {
      setImageUrl(null);
      setUpLoadFile(null);
    } else {
      form.setFieldsValue({
        value: null,
        unit: UnitOptions[0].value,
      });
    }
  };
  const handleValueText = (value) => {
    return isEmpty(value?.replace(/\s/g, '')) ? '' : value;
  };

  return (
    <Form
      {...layout}
      initialValues={{
        title: props.customReportData?.title,
        note: isEmpty(props.customReportData?.note) ? '' : JSON.parse(props.customReportData?.note),
        url: props.customReportData?.url,
        value: props.customReportData?.reportMainData,
        unit: +props.customReportData?.unit || UnitOptions[0].value,
        reportType: props.customReportData?.reportType,
      }}
      validateTrigger="onSubmit"
      onFinish={() => handleSubmitForm()}
      form={form}
      name="custom-report-edit"
      labelAlign="left"
      className="custom-report-form"
    >
      <Form.Item
        label="Chart title"
        name="title"
        rules={[
          { required: true, min: 5, max: 100, message: 'Length must be between 5 and 100' },
          { whitespace: true, message: 'Field must not be empty' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Report Type" name="reportType">
        <Radio.Group
          options={CustomReportTypeOptions}
          onChange={(e) => {
            handleChangeReportType(e.target.value);
          }}
        />
      </Form.Item>
      {reportType === CustomReportType.ImgReport ? (
        <Form.Item
          labelAlign={'left'}
          label="Picture"
          name="file"
          rules={
            !imageUrl &&
            reportType === CustomReportType.ImgReport && [{ required: true, message: 'This field is required!' }]
          }
        >
          <Row>
            <Col>
              {imageUrl && <Image className="custom-report-form-img" src={imageUrl} style={{ width: '250px' }} />}
            </Col>
            <Col>
              <Upload
                accept=".jpeg,.png,.jpg"
                showUploadList={false}
                beforeUpload={(file) => {
                  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                  if (!isJpgOrPng) {
                    showErrorNotification('Warning', 'You can only upload JPG/PNG file!');
                  }
                  const isLt3M = file.size / 1024 / 1024 < 3;
                  if (!isLt3M) {
                    showErrorNotification('Warning', 'Image must smaller than 3MB!');
                  }
                  if (isJpgOrPng && isLt3M) {
                    setUpLoadFile(file);
                    imageChange(file);
                  }
                  return false;
                }}
                maxCount={1}
              >
                <Button style={{ paddingLeft: '0' }} type="link" className="btn-upload" icon={<CloudUploadOutlined />}>
                  Upload Picture
                </Button>
                {imageUrl && (
                  <Button
                    danger
                    style={{ paddingLeft: '0' }}
                    type="link"
                    className="btn-upload"
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageUrl(null);
                    }}
                    icon={<DeleteOutlined />}
                  >
                    Clear
                  </Button>
                )}
              </Upload>
            </Col>
          </Row>
        </Form.Item>
      ) : (
        <>
          <Col>
            <Form.Item label="Unit of measurement" name="unit" rules={[{ required: true }]}>
              <Select options={UnitOptions} onChange={(value) => setInputType(+value)} />
            </Form.Item>
          </Col>

          <Form.Item
            label="Value"
            name="value"
            dependencies={['unit']}
            rules={[
              {
                required: true,
                message: 'Field must not be empty',
              },
            ]}
          >
            {inputType === UnitOptions[0].value ? (
              <InputNumber
                min={0}
                max={100}
                onChange={(values) => {
                  form.setFieldsValue({
                    value: values,
                  });
                }}
              />
            ) : (
              <Input
                maxLength={20}
                onChange={(e) => {
                  form.setFieldsValue({
                    value: handleValueText(e.target.value),
                  });
                }}
              />
            )}
          </Form.Item>
        </>
      )}

      <Form.Item label="Note" name="note">
        <Editor
          wrapperClassName="editor-wrapper"
          editorClassName="editor-box"
          toolbarClassName="editor-toolbar"
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'emoji',
              'remove',
              'history',
            ],
          }}
          onEditorStateChange={(values) => {
            form.setFieldsValue({
              note: values,
            });
          }}
          onTab={(e) => {
            const tabCharacter = '              ';
            e.preventDefault();
            let currentState = form.getFieldValue('note');
            let newContentState = Modifier.replaceText(
              currentState.getCurrentContent(),
              currentState.getSelection(),
              tabCharacter
            );
            form.setFieldsValue({
              note: EditorState.push(currentState, newContentState, 'insert-characters'),
            });
          }}
          defaultContentState={props.customReportData?.note ? JSON.parse(props.customReportData?.note) : null}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <div className="form-footer">
          <Button
            className="btn btn-cancel"
            type="default"
            formAction=""
            onClick={() => {
              props.onCloseModal();
              form.resetFields();
            }}
          >
            CANCEL
          </Button>
          {props.action !== formAction.Read && (
            <Button key="submit" type="primary" formAction="" htmlType="submit" className="btn">
              {props.action.toUpperCase()}
            </Button>
          )}
        </div>
      </Form.Item>
    </Form>
  );
};

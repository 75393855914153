import { Col, Row } from 'antd';
import { Card } from 'app/components/Card';
import { HealthReportResponse } from 'app/types/IterationStatusAndHealthResponse';
import { TicketResponse, WorkItemType } from 'app/types/PlannedWorkItem';

import { CarriedOverWrapper } from './CarriedOver.styled';

interface ICarriedItemProps {
  value: number | string;
  title: string;
  isPercent: boolean;
}

const CarriedItem = ({ value, title, isPercent }: ICarriedItemProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <p style={{ fontWeight: 'bold', fontSize: '46px', margin: 0 }}>
        {value}
        {isPercent && '%'}
      </p>
      <p style={{ fontSize: '18px', margin: 0 }}>{title}</p>
    </div>
  );
};

interface IComponentProps {
  dataIterationHealth: HealthReportResponse;
  dataCarriedOver: TicketResponse[];
}

export const CarriedOver = ({ dataIterationHealth, dataCarriedOver }: IComponentProps) => {
  if (!dataIterationHealth || dataIterationHealth === undefined) {
    dataIterationHealth = {
      userStoriesHealth: -1,
      featuresHealth: -1,
    };
  }
  const { featuresHealth, userStoriesHealth } = dataIterationHealth;
  let carriedOverUserStories = dataCarriedOver?.filter((it) => it.type === WorkItemType.UserStory)?.length;
  let carriedOverDefects = dataCarriedOver?.filter((it) => it.type === WorkItemType.Defect)?.length;
  if (carriedOverUserStories === undefined || carriedOverDefects === undefined) {
    carriedOverUserStories = -1;
    carriedOverDefects = -1;
  }
  return (
    <CarriedOverWrapper>
      <Card
        id="carried-over-export"
        title="Carried Over"
        className={`carried-card carried-over ${
          carriedOverUserStories === 0 && carriedOverDefects === 0 ? 'healthy' : 'unhealthy'
        }`}
        titleTooltip={null}
      >
        <Row justify="center">
          <Col span={12}>
            <CarriedItem
              value={carriedOverUserStories === -1 ? 'N/A' : carriedOverUserStories}
              title="User Stories"
              isPercent={false}
            />
          </Col>
          <Col span={12}>
            <CarriedItem
              value={carriedOverDefects === -1 ? 'N/A' : carriedOverDefects}
              title="Defects"
              isPercent={false}
            />
          </Col>
        </Row>
      </Card>

      <Card
        id="iteration-health-export"
        title="Rally Health"
        className={`carried-card iteration-health ${
          userStoriesHealth === 100 && featuresHealth === 100 ? 'healthy' : 'unhealthy'
        }`}
        titleTooltip={
          <span>
            Display the quality of features and backlogs. It pulls data from Rally, view detail at Reports/Rally Health.
          </span>
        }
      >
        <Row justify="center">
          <Col span={12}>
            <CarriedItem
              value={userStoriesHealth === -1 ? 'N/A' : userStoriesHealth}
              title="User Stories"
              isPercent={userStoriesHealth !== -1}
            />
          </Col>
          <Col span={12}>
            <CarriedItem
              value={featuresHealth === -1 ? 'N/A' : featuresHealth}
              title="Features"
              isPercent={featuresHealth !== -1}
            />
          </Col>
        </Row>
      </Card>
    </CarriedOverWrapper>
  );
};

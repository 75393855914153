import { createContext, useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { fetchCategory, fetchCriteria, fetchCriteriaDetail } from 'app/apis/fetchOwnership';
import { CategoryResponse, CriteriaDetailResponse, CriteriaResponse } from 'app/types/Ownership';
import Item from 'antd/lib/list/Item';
import { orderBy } from 'lodash';

interface IOwnershipConfigContext {
  categories: CategoryResponse[];
  setCategoryies: (categories: CategoryResponse[]) => void;
  criterias: CriteriaResponse[];
  setCriterias: (criterias: CriteriaResponse[]) => void;
  criteriasDetail: CriteriaDetailResponse[];
  setCriteriaDetail: (criteriasDetail: CriteriaDetailResponse[]) => void;
}

export const OwnershipConfigContext = createContext<IOwnershipConfigContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const OwnershipConfigContextProvider = ({ children }: IProps) => {
  const [categories, setCategoryies] = useState<CategoryResponse[]>();
  const [criterias, setCriterias] = useState<CriteriaResponse[]>();
  const [criteriasDetail, setCriteriaDetail] = useState<CriteriaDetailResponse[]>();

  const { data: originalCategories, error: fetchCategoryError } = useFetch(() => fetchCategory());
  const { data: originalCriterias, error: fetchCriteriaError } = useFetch(() => fetchCriteria());
  const { data: originalCriteriasDetail, error: fetchCriteriaDetailError } = useFetch(() => fetchCriteriaDetail());

  useEffect(() => {
    const formatCriteria: CriteriaResponse[] = originalCriterias?.map((x) => ({
      ...x,
      order: originalCategories?.find((item) => item.id === x.categoryId)?.order,
    }));
    const formatCriteriaDetail: CriteriaDetailResponse[] = originalCriteriasDetail?.map((x) => ({
      ...x,
      categoryId: formatCriteria?.find((item) => item.id === x.criteriaId)?.categoryId,
      order: formatCriteria?.find((item) => item.id === x.criteriaId)?.order,
    }));
    setCategoryies(orderBy(originalCategories, ['order', 'name'], 'asc'));
    setCriterias(orderBy(formatCriteria, ['order', 'categoryId', 'name'], 'asc'));
    setCriteriaDetail(orderBy(formatCriteriaDetail, ['order', 'categoryId', 'criteriaId', 'level'], 'asc'));
  }, [originalCategories, originalCriterias, originalCriteriasDetail]);

  if (fetchCategoryError) {
    return <div>{JSON.stringify(fetchCategoryError)}</div>;
  }
  if (fetchCriteriaError) {
    return <div>{JSON.stringify(fetchCriteriaError)}</div>;
  }
  if (fetchCriteriaDetailError) {
    return <div>{JSON.stringify(fetchCriteriaDetailError)}</div>;
  }

  return (
    <OwnershipConfigContext.Provider
      value={{ categories, criterias, criteriasDetail, setCategoryies, setCriterias, setCriteriaDetail }}
    >
      {children}
    </OwnershipConfigContext.Provider>
  );
};

export interface StakeholdersResponse {
  id: number;
  teamId: number;
  name: string;
  role: string;
  location: string;
  site: string;
  isExternalTeam: boolean;
  isOffboard: boolean;
  note: string;
  isCountProductivity: boolean;
  isCommitted: boolean;
}

export interface StakeholdersRequest {
  teamId: number;
  name: string;
  role: string;
  location: string;
  site: string;
  isExternalTeam: boolean;
  isOffboard: boolean;
  note: string;
  isCountProductivity: boolean;
  isCommitted: boolean;
}
export const DisplayColOptions = [
  {
    lable: 'Role',
    value: 'role',
  },
  {
    lable: 'Location',
    value: 'location',
  },
  {
    lable: 'Site',
    value: 'site',
  },
  {
    lable: 'Is Count Productivity',
    value: 'isCountProductivity',
  },
  {
    lable: 'Is External Team',
    value: 'isExternalTeam',
  },
  {
    lable: 'Is Offboard',
    value: 'isOffboard',
  },
  {
    lable: 'Notes',
    value: 'note',
  },
];

export interface Introduction {
  content: string;
}

export interface IntroductionResponse {
  content: string;
}
export interface IntroductionRequest {
  content: string;
}

// Need to update here for restore about Export Report Filter of User
export enum DisplayType {
  DisplayForm = 0,
  ExportReportForm = 1,
}
export interface DisplayComponentRequest {
  userId: number;
  value: string;
  displayType: DisplayType;
}
export interface GetDisplayRequest {
  displayType: DisplayType;
}

import { Spin } from 'antd';
import Item from 'antd/lib/list/Item';
import { fetchAllTeams } from 'app/apis/teamClient';
import { getOverViewBaseBreadCrumb } from 'app/common/breadcrumb';
import { TeamResponse } from 'app/types/team';
import { SubPage } from 'app/types/TeamDetail';
import { BreadcrumbResponse } from 'app/types/TeamDetailTypes';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useFetch } from '../hooks/useFetch';
import { UserContext } from './UserContext';

interface ITeamContext {
  teams: TeamResponse[];
  setTeams: (teams: TeamResponse[]) => void;
  breadcrumbs: BreadcrumbResponse[];
  setBreadcrumbs: (crumbs: BreadcrumbResponse[]) => void;
  page: SubPage;
  setSubPage: (page: SubPage) => void;
}

export const TeamContext = createContext<ITeamContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const TeamContextProvider = ({ children }: IProps) => {
  const { user } = useContext(UserContext);
  const [page, setSubPage] = useState(SubPage.Monitoring);
  const [teams, setTeams] = useState<TeamResponse[]>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbResponse[]>();

  const history = useHistory();
  useEffect(() => {
    const urlHash = new URLSearchParams(history.location.hash.replace('#', '?'));
    const currentPage = urlHash.get('page');
    setSubPage(SubPage[currentPage]);
  }, [history.location.hash]);

  useEffect(() => {
    (async function () {
      try {
        var origindata = await fetchAllTeams(user?.id);
        setTeams(origindata);
        if (breadcrumbs && breadcrumbs.length === 0) {
          setBreadcrumbs([getOverViewBaseBreadCrumb(), ...breadcrumbs]);
        }
      } catch (error) {
        return <div>{JSON.stringify(error)}</div>;
      }
    })();
  }, [user, breadcrumbs]);

  if (!teams) {
    return <Spin />;
  }
  return (
    <TeamContext.Provider
      value={{
        teams,
        setTeams,
        breadcrumbs,
        setBreadcrumbs,
        page,
        setSubPage,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

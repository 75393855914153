import { Row } from 'antd';
import styled from 'styled-components';

export const DotComponent = styled.div`
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
`;
export const TailInfo = styled.div`
  .label-text {
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 0.4rem;
    &.num {
      font-weight: bold;
      margin-left: 0.3rem;
    }
    &.disable {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .tail-info-row {
    margin-bottom: 16px;
  }
`;
export const Label = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
export const WeeklyStatusWrapper = styled(Row)`
  .title {
    font-weight: bold;
    color: #0e50a4;
  }
  span {
    .ant-select {
      margin-left: 2.2em;
      font-weight: normal;
    }
  }
  .btn-create {
    background: #2785ff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    width: 100px;
    height: 39px;

    text-align: center;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
  }
`;

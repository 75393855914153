import { useContext, useEffect, useMemo, useState } from 'react';
import { TablePaginationConfig } from 'antd';

import './DetailTable.scss';
import { GroupedTeamLinks, TABLE_DEFAULT_PAGE_SIZE } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { TeamIterationMonitorDetail } from 'app/types/TeamDetailTypes';
import { TeamParentContext } from 'app/contexts/TeamParentContext';
import { Link } from 'react-router-dom';

export interface IDetailTableProps {
  dataSource: TeamIterationMonitorDetail[];
  title: String;
}

export const DetailTable = ({ dataSource, title }: IDetailTableProps) => {
  const { loading } = useContext(TeamParentContext);

  const [data, setData] = useState<TeamIterationMonitorDetail[]>();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);

  useEffect(() => {
    if (!dataSource) return;

    setData(dataSource);
  }, [dataSource]);

  const renderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`/${GroupedTeamLinks[record.type]}/${record.id}`}>
          {text}
        </Link>
      </>
    );
  };

  const columns = useMemo<EditableColumn<any>[]>(
    () => [
      {
        title: '#',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        width: '3%',
        align: 'center',
      },
      {
        title: title,
        dataIndex: 'teamName',
        sorter: (a, b) => a.teamName.localeCompare(b.teamName),
        width: '20%',
        render: (value, record) => renderLink(value, record),
      },
      {
        title: 'Total Capacity',
        dataIndex: 'totalCapacity',
        align: 'right',
        sorter: (a, b) => a.totalCapacity - b.totalCapacity,
        width: '7%',
      },
      {
        title: 'Estimated Capacity',
        dataIndex: 'estimatedCapacity',
        align: 'right',
        width: '7%',
        sorter: (a, b) => a.estimatedCapacity - b.estimatedCapacity,
      },
      {
        title: 'Actual Capacity',
        dataIndex: 'actualCapacity',
        align: 'right',
        width: '7%',
        sorter: (a, b) => a.actualCapacity - b.actualCapacity,
      },
      {
        title: 'Accepted Points',
        dataIndex: 'acceptedPoints',
        align: 'right',
        width: '7%',
        sorter: (a, b) => a.acceptedPoints - b.acceptedPoints,
      },
      {
        title: 'Total Points',
        dataIndex: 'totalPoints',
        align: 'right',
        width: '7%',
        sorter: (a, b) => a.totalPoints - b.totalPoints,
      },
      {
        title: 'Idea',
        dataIndex: 'idea',
        align: 'right',
        sorter: (a, b) => a.idea - b.idea,
        width: '7%',
      },
      {
        title: 'Defined',
        dataIndex: 'defined',
        align: 'right',
        sorter: (a, b) => a.defined - b.defined,
        width: '7%',
      },
      {
        title: 'In Progress',
        dataIndex: 'inProgress',
        align: 'right',
        sorter: (a, b) => a.inProgress - b.inProgress,
        width: '7%',
      },
      {
        title: 'Completed',
        width: '7%',
        dataIndex: 'completed',
        align: 'right',
        sorter: (a, b) => a.completed - b.completed,
      },
      {
        title: 'Accepted',
        dataIndex: 'accepted',
        align: 'right',
        sorter: (a, b) => a.accepted - b.accepted,
        width: '7%',
      },
      {
        title: 'Released',
        dataIndex: 'released',
        align: 'right',
        sorter: (a, b) => a.released - b.released,
        width: '7%',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, title]
  );
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  return (
    <div className="iteration-detail-monitor-container">
      <EditableTable
        onChange={onChange}
        pageSize={pageSize}
        current={page}
        loading={loading}
        columns={columns}
        data={data}
        bordered
        showAction={false}
      />
    </div>
  );
};

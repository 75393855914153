import { Button, Form, Input, notification, Select, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import { AccessRequest, fetchMyUser, SetDefaultUserInfo } from 'app/apis/userClient';
import { regexFullName, teamRoles } from 'app/common/constants';
import { AuthContext } from 'app/contexts/AuthContext';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useFetch } from 'app/hooks/useFetch';
import { TeamTypeConst } from 'app/types/team';
import { SelectOptions } from 'app/types/entity';
import { MyUserRequest, UserResponse } from 'app/types/user';
import { useContext, useMemo, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { GettingStartedResult } from './GettingStartedResult';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 },
};

export enum GettingStartedFormEnum {
  GettingStarted,
  GettingStartedSSO,
  Submitted,
  WaitingApproval,
  Rejected,
}

export enum SubmitType {
  ExistCredential,
  SSO,
}

interface IProps {
  form: GettingStartedFormEnum;
  isSso: boolean;
}

export const GettingStartedForm = ({ form, isSso }: IProps) => {
  const [formType, setFormType] = useState(form);
  const { teams } = useContext(TeamContext);

  const teamOptions = useMemo(
    () =>
      teams.filter((item) => item.type === TeamTypeConst.Team).map((item) => ({ value: item.id, label: item.name })),
    [teams]
  );
  const {
    user: { id },
  } = useContext(AuthContext);
  const { data: userData } = useFetch(fetchMyUser);

  const teamRolesOptions: SelectOptions<number>[] = useMemo(() => {
    return teamRoles.map((r) => ({
      value: r.code,
      label: r.name,
    }));
  }, []);

  const onFinish = async (form: MyUserRequest) => {
    try {
      if (formType === GettingStartedFormEnum.GettingStarted) {
        let result: UserResponse = null;
        result = await SetDefaultUserInfo(Number(id), form);
        if (result) window.location.href = `teams/${result.defaultTeamId}`;
        else {
          notification.error({
            message: `Error updating your infomation`,
            description: 'Please try again later',
            duration: 2,
          });
        }
      }
      if (isSso && formType === GettingStartedFormEnum.GettingStartedSSO) {
        await AccessRequest(Number(id), form);
        setFormType(GettingStartedFormEnum.Submitted);
      }
    } catch (error) {}
  };

  return (
    <>
      {userData && (
        <>
          {formType === GettingStartedFormEnum.GettingStarted ||
          formType === GettingStartedFormEnum.GettingStartedSSO ? (
            <Space direction="vertical" align="center">
              <Title level={2} style={{ textAlign: 'center', color: '#006EFF' }}>
                BEFORE GETTING STARTED!
              </Title>
              <Title level={4} style={{ textAlign: 'center', color: '#006EFF', fontWeight: 400 }}>
                We need a little more info before you can use this app
              </Title>

              <Form
                initialValues={{ remember: true }}
                autoComplete="off"
                labelAlign="left"
                colon={false}
                onFinish={onFinish}
                {...layout}
              >
                <Form.Item
                  label="Fullname"
                  name="fullName"
                  initialValue={userData?.fullName}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your fullname!',
                    },
                    {
                      pattern: regexFullName,
                      message: `Invalid name`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Default team"
                  name="defaultTeamId"
                  rules={[{ required: true, message: 'Please choose your default team!' }]}
                  initialValue={userData?.defaultTeamId}
                >
                  <Select
                    showSearch
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {teamOptions.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Team role"
                  name="teamRole"
                  rules={[{ required: true, message: 'Please choose your team role!' }]}
                  initialValue={userData?.teamRole}
                >
                  <Select options={teamRolesOptions} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                  {formType === GettingStartedFormEnum.GettingStarted ? (
                    <Button type="primary" htmlType="submit" className="submitBtn">
                      Get Started!
                    </Button>
                  ) : (
                    <Button type="primary" htmlType="submit" className="submitBtnSSO">
                      Request Access
                    </Button>
                  )}
                </Form.Item>
              </Form>
              {isSso && (
                <Link to="/login" className="link">
                  Back to sign in
                </Link>
              )}
            </Space>
          ) : (
            <GettingStartedResult form={formType} />
          )}
        </>
      )}
    </>
  );
};

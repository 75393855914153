import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Tooltip, Form, TablePaginationConfig, Space, DatePicker, Input } from 'antd';
import Icon from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { EditableColumn, EditableTable } from '../EditableTable';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import { StyledModal } from 'app/pages/ManageUsers/ManagedUsers.styled';
import { DemoPlanWrapper } from './DemoPlan.styled';
import { SearchBox } from 'app/components/SearchBox/SearchBox';
import { DemoPlanItemRequest, DemoPlanResponse, Stage } from 'app/types/TeamDetailTypes';
import { IterationContext } from 'app/contexts/IterationContext';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { SubPage } from 'app/types/TeamDetail';
import { TopPerformersContext } from 'app/contexts/TopPerformersContext';
import { useFetch } from 'app/hooks/useFetch';
import { createDemoPlanItem, fetchDemoPlan, removeDemoPlan, updateDemoPlan } from 'app/apis/teamDetailClient';
import { updateItem } from 'app/utils/tableUtils';
import { ErrorType, MAX_LENGTH_DEMO_PLAN, roleNames, USER_PAGE_NUMBER } from 'app/common/constants';
import { DemoPlanColumn } from 'app/types/Iteration';
import DefectIcon from 'app/pages/TeamDetail/Plan/DefectIcon';
import UserStoryIcon from 'app/pages/TeamDetail/Plan/UserStoryIcon';
import { WorkItemType } from 'app/types/PlannedWorkItem';
import ToolTip from '../Tooltip/ToolTip';
import { IterationBackLogContext } from 'app/contexts/IterationBackLogContext';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { UserContext } from 'app/contexts/UserContext';
import { openNotification, openNotificationByType } from 'app/utils/notificationUtils';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import './CustomDemoPlan.scss';
import { isEmpty } from 'lodash';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { useAuthz } from 'app/hooks/useAuthz';

interface Props {
  page: SubPage;
  id?: string;
}

type Option = {
  label: string;
  value: number;
};

export const DemoPlan = ({ page, id = 'demo-plan' }: Props) => {
  const { team } = useContext(TeamDetailContext);
  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const disabledDates: RangePickerProps['disabledDate'] = (selected) => {
    return selected < moment.utc().subtract(1, 'days');
  };
  const { stage, reportStatus, selectedIteration, demoPlanData, setDemoPlanData } = useContext(IterationContext);
  const { topPerformerSelectOptions } = useContext(TopPerformersContext);
  const { demoItems } = useContext(IterationBackLogContext);
  const { handleComponentClass } = useContext(DisplayComponentsContext);
  const [triggerResetData, setTriggerResetData] = useState<boolean>(false);
  const { data: demoPlan } = useFetch(
    () => fetchDemoPlan(selectedIteration.id, selectedIteration.teamId),
    [triggerResetData, stage, reportStatus, page, selectedIteration]
  );
  const [ticketOptions, setTicketOptions] = useState<Option[]>();
  const [personInChargeOptions, setPersonInChargeOptions] = useState<Option[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [oldContentEditor, setOldContentEditor] = useState('');
  const [demoItemEditId, setDemoItemEditId] = useState(-1);

  useEffect(() => {
    if (topPerformerSelectOptions && selectedIteration && demoPlan && demoItems) {
      let colData = filterData(demoPlan);
      setDemoPlanData(colData);
      let picOptions: Option[] = topPerformerSelectOptions.map((pic) => ({
        label: pic.fullName,
        value: pic.memberId,
      }));

      setPersonInChargeOptions(picOptions);
      let ticketOptions: Option[] = demoItems
        .filter((x) => x != null)
        ?.map((ticket) => ({
          label: `${ticket?.code}-${ticket?.name}`,
          value: ticket?.id,
        }));
      setTicketOptions(ticketOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIteration, topPerformerSelectOptions, demoPlan, stage, triggerResetData, reportStatus, page]);

  // Whenever Plan/Report were submitted
  const isEdit =
    (stage !== Stage.Planned && page === SubPage.Plan) ||
    (reportStatus !== IterationReportStatusEnum.Submitted && page === SubPage.Report) ||
    page === SubPage.Monitoring;
  // authen
  const { isAuthorized } = useAuthz();
  // when ever Plan tab was submitted
  const isDataForSubmittedPlan = stage === Stage.Planned && page === SubPage.Plan;
  // when ever Report tab was submitted
  const isDataForSubmittedReport = reportStatus === IterationReportStatusEnum.Submitted && page === SubPage.Report;
  const limitInthePast = new Date(2000, 0);
  const filterData = (data: DemoPlanResponse[]) => {
    let result: DemoPlanColumn[] = data
      // check render data by current stage + subpage
      .filter((x) =>
        isDataForSubmittedPlan
          ? x.pageType === SubPage.Plan
          : isDataForSubmittedReport
          ? x.pageType === SubPage.Report
          : x.pageType === SubPage.Monitoring
      )
      .map((item) => {
        // check if the date is default date by setting a limit time in the past
        let checkDate = moment.utc(item?.demoDate).isBefore(moment.utc(limitInthePast));
        return {
          id: item.id,
          ticket: item.ticket ? `${item.ticket.code}-${item.ticket.name}` : '',
          ticketId: item.ticketId,
          personInCharge: item.personInCharge ? `${item.personInCharge.firstName} ${item.personInCharge.lastName}` : '',
          note: item.note,
          personInChargeId: item.memberId,
          ticketType: item.ticket?.type,
          demoDate: checkDate ? null : moment.utc(item?.demoDate).local(),
          title: item.title,
        };
      });
    return result;
  };

  // Form action
  const onFilterTicketChange = (value: string) => {
    form.setFieldsValue({
      ticket: value,
    });
  };
  const onFilterPersonInChargeChange = (value: string) => {
    form.setFieldsValue({
      personInCharge: value,
    });
  };
  const onFilterTicketClear = () => {
    form.setFieldsValue({
      ticket: null,
    });
  };
  const onFilterPICClear = () => {
    form.setFieldsValue({
      personInCharge: null,
    });
  };
  const onChange = (pagination: TablePaginationConfig) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  // Table config

  const optionsEdit = [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'emoji',
    'image',
    'remove',
    'history',
  ];

  const renderTicketColumn = (record: DemoPlanColumn) => {
    return {
      children: (
        <Space>
          <Icon component={record.ticketType === WorkItemType.Defect ? DefectIcon : UserStoryIcon} />
          {record.ticket}
        </Space>
      ),
    };
  };
  const renderNoteColumn = (record: DemoPlanColumn) => {
    return (
      <Editor
        readOnly={true}
        toolbarHidden={true}
        wrapperClassName="editor-wrapper-demo-plan-text"
        toolbarClassName="editor-toolbar"
        defaultContentState={convertFromTextToEditor(record.note)}
      />
    );
  };
  const editingContent = (record: DemoPlanColumn) => {
    return (
      <Editor
        wrapperClassName="editor-wrapper-demo-plan"
        toolbarClassName="editor-toolbar"
        editorClassName="editor-box"
        defaultContentState={convertFromTextToEditor(record.note)}
        toolbar={{
          options: optionsEdit,
        }}
        onContentStateChange={(value) => {
          record.note = JSON.stringify(value);
        }}
      />
    );
  };

  const convertFromTextToEditor = (note) => {
    // if note is null or empty
    if (note === null) {
      return convertToRaw(EditorState.createEmpty().getCurrentContent());
    }
    // if note is a text

    if (!isJson(note)) {
      var editorStateFromText = ContentState.createFromText(note);
      return convertToRaw(editorStateFromText);
    } else return JSON.parse(note);
  };

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return isNaN(str);
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const columns = useMemo<EditableColumn<DemoPlanColumn>[]>(() => {
    let defineColumns: EditableColumn<DemoPlanColumn>[] = [
      {
        title: '#',
        width: '3%',
        align: 'center',
        render: (value, record, index) => (pageNumber - 1) * pageSize + index + 1,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        editable: true,
        width: '10%',
        rules: [{ max: 200, message: 'The field title must be a string with a maximum length of 200' }],
      },
      {
        title: 'Demo Items',
        dataIndex: 'ticket',
        width: '37%',
        editable: false,
        render: (value, record, index) => renderTicketColumn(record),
      },
      {
        title: 'Person In Charge',
        dataIndex: 'personInChargeId',
        editable: true,
        width: '10%',
        inputType: 'select',
        options: personInChargeOptions,
        inputProps: { searchOnSelect: true },
      },
      {
        title: 'Date',
        dataIndex: 'demoDate',
        editable: true,
        width: '12%',
        inputType: 'custom',
        align: 'left',
        render: (value, record, index) =>
          record.demoDate ? moment(record.demoDate).format('HH:mm - MMM DD, YYYY').toString() : null,
        editingContent: (record) => (
          <DatePicker
            format={'dd, yyyy/MM/DD HH:mm'}
            disabledDate={disabledDates}
            showTime
            onOk={(value) => {
              record.demoDate = value;
            }}
          />
        ),
      },
      {
        title: 'Note',
        editable: true,
        width: 'auto',
        inputType: 'custom',
        dataIndex: 'note',
        render: (value, record, index) => renderNoteColumn(record),
        editingContent: (record) => editingContent(record),
      },
    ];

    return defineColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, personInChargeOptions, demoPlanData, stage, triggerResetData, reportStatus, page]);

  // Table action
  const onCreateDemoPlanItem = async (values) => {
    try {
      let note = formatEditor(values.note);
      let demoPlanRequest: DemoPlanItemRequest = {
        title: values.title,
        iterationId: selectedIteration.id,
        note: note,
        pageType: SubPage.Monitoring,
        ticketId: values.ticket,
        memberId: values.personInCharge,
        demoDate: values.demoDate ? values.demoDate.toDate() : new Date(1900, 0),
      };
      var content = getTextFromEditor(note);
      if (content.length > MAX_LENGTH_DEMO_PLAN) {
        openNotificationByType(ErrorType, 'The note must be a string with length less than 2000');
      } else {
        const resp = await createDemoPlanItem(demoPlanRequest);

        if (resp) {
          setTriggerResetData(!triggerResetData);
          setToggleModal(false);
        }
      }
    } catch (error) {
      openNotification(error.status, error?.data?.error?.message);
    }
  };
  const onUpdate = async (id: number, record: DemoPlanColumn) => {
    try {
      let note = formatEditor(record.note);
      let demoPlanRequest: DemoPlanItemRequest = {
        title: record.title,
        iterationId: selectedIteration.id,
        note: note,
        pageType: page,
        ticketId: record.ticketId,
        memberId: record.personInChargeId,
        demoDate: record.demoDate ? record.demoDate.toDate() : new Date(1900, 0),
      };

      var content = getTextFromEditor(note);
      if (content.length > MAX_LENGTH_DEMO_PLAN) {
        openNotificationByType(
          ErrorType,
          'Cant update the demo item because the note must be a string with length less than 2000'
        );
        record.note = oldContentEditor;
      } else {
        if (note === 'null') {
          demoPlanRequest.note = null;
          await updateDemoPlan(record.id, demoPlanRequest);
          record.note = null;
        }
        await updateDemoPlan(record.id, demoPlanRequest);
        record.note = note;
      }

      let newdata = updateItem(record, demoPlanData);
      setDemoPlanData(newdata);
    } catch (error) {
      console.error(error);
    }
  };
  const onDelete = async (id: number, record: DemoPlanColumn) => {
    try {
      await removeDemoPlan(record.id);
      setTriggerResetData(!triggerResetData);
    } catch (error) {
      console.error(error);
    }
  };
  const oncancel = () => {
    var record = demoPlanData.find((x) => x.id === demoItemEditId);
    record.note = oldContentEditor;
  };
  const onEditRecord = (record: DemoPlanColumn) => {
    setOldContentEditor(record.note);
    setDemoItemEditId(record.id);
  };
  const getTextFromEditor = (content) => {
    //if content is null then return
    if (content === null || isEmpty(content) || content === 'null') {
      return '';
    }
    //all text from block
    var textFromEditor = [];
    JSON.parse(content).blocks.forEach((x) => textFromEditor.push(x.text));
    return textFromEditor.join('');
  };

  const formatEditor = (data) => {
    if (data === 'null') {
      return null;
    }
    if (typeof data === 'string') {
      return data;
    }
    return JSON.stringify(data);
  };

  return (
    <DemoPlanWrapper id={id}>
      <Row>
        <Col span={16} className={handleComponentClass('plan-demo-plan')}>
          <PageTitle>
            <Title level={5}>
              {page === SubPage.Report ? 'DEMO ITEMS' : 'DEMO PLAN'}
              <ToolTip
                title={
                  page === SubPage.Plan ? (
                    <span>Demo plan for planning</span>
                  ) : page === SubPage.Monitoring ? (
                    <span>Demo plan for Monitor</span>
                  ) : page === SubPage.Report ? (
                    <span>Demo plan for Report</span>
                  ) : null
                }
              />
              <Tooltip title="ay9o" />
            </Title>
          </PageTitle>
        </Col>
        {isEdit && isAuthorized && (
          <Col span={8} className="title-section-add-button">
            <Button
              data-html2canvas-ignore
              type="primary"
              onClick={() => {
                form.resetFields();
                setToggleModal(true);
              }}
              size="large"
            >
              Add
            </Button>
          </Col>
        )}
      </Row>
      <DeviderWrapper />
      <EditableTable
        scroll={{ x: 'max-content' }}
        data={demoPlanData}
        isEdit={isAuthorized && isEdit}
        onSave={onUpdate}
        onDelete={onDelete}
        onCancel={oncancel}
        onEditRecord={onEditRecord}
        isDelete={isAuthorized && isEdit}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          onChange: () => onChange,
          current: pageNumber,
        }}
      />

      <StyledModal
        title="ADD DEMO ITEM"
        visible={toggleModal}
        onCancel={() => setToggleModal(false)}
        width={700}
        centered
        footer={[
          <Button key="back" size="large" onClick={() => setToggleModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" size="large" form="demoPlan">
            Create
          </Button>,
        ]}
      >
        <Form
          {...layout}
          form={form}
          size={'middle'}
          onFinish={onCreateDemoPlanItem}
          name="demoPlan"
          initialValues={{ ticket: '', personInCharge: '', note: '' }}
          autoComplete="off"
          colon={false}
          labelAlign="left"
        >
          <Form.Item label="Title" name="title">
            <Input type={'text'} autoComplete="off" placeholder="Title" />
          </Form.Item>
          <Form.Item label="Demo Item" name="ticket">
            <SearchBox
              data={ticketOptions}
              onFilterChange={onFilterTicketChange}
              onFilterClear={onFilterTicketClear}
              placeholder="Search"
            />
          </Form.Item>
          <Form.Item label="Person In Charge" name="personInCharge">
            <SearchBox
              data={personInChargeOptions}
              onFilterChange={onFilterPersonInChargeChange}
              onFilterClear={onFilterPICClear}
              placeholder="Search"
            />
          </Form.Item>
          <Form.Item label="Date" name="demoDate">
            <DatePicker format={'dd, yyyy/MM/DD HH:mm'} showTime />
          </Form.Item>
          <Form.Item label="Note" name="note">
            <Editor
              wrapperClassName="editor-wrapper-demo-plan"
              toolbarClassName="editor-toolbar"
              editorClassName="editor-box"
              toolbar={{
                options: optionsEdit,
              }}
            />
          </Form.Item>
        </Form>
      </StyledModal>
    </DemoPlanWrapper>
  );
};

import { Col } from 'antd';
import { OjectTeamDataResponse } from 'app/types/Organizational';
import { hex2rgba } from 'app/utils/utils';

import { EdgePoint } from '../OrginazationChart.styled';

interface IProps {
  team: OjectTeamDataResponse;
  color: string;
  setCurrentTeam: (teamId: number) => void;
}

function TeamNode({ team, color, setCurrentTeam }: IProps) {
  const rgbaEdge = hex2rgba(color, 1);
  return (
    <>
      <Col className="team-row" span={3} onClick={() => setCurrentTeam(team.id)}>
        <span className="team-name">{team.name}</span>
      </Col>
      <EdgePoint color={rgbaEdge}>
        <span className="edge-point" />
      </EdgePoint>
    </>
  );
}

export default TeamNode;

import styled from 'styled-components';

export const TrendsWrapper = styled.div`
  .spiner-container {
    height: 70vh;
  }
  .title {
    font-weight: bold;
    color: #0e50a4;
    font-size: 25px;
  }
  .chart-section {
    margin-top: 2rem;
  }
  .card-container {
    .card-title {
      margin-bottom: 12px;
    }
  }
`;

import { useContext, useState } from 'react';
import { AttendanceDataItem, SingleAttend, AttendanceType, DayOffRequest } from '../../../types/Attendance';
import { DotComponent } from 'app/pages/Reports/WeeklyStatus/ReportWeeklyStatus.styled';
import { CriticalStatusColor, RiskStatusColor, AttendanceMark } from 'app/common/constants';
import { AssignModal, AttendanceCell, LegendItem, PopoverContent } from '../Attendance.styled';
import { Button, Col, Form, Input, Popover, Radio, Row } from 'antd';
import moment from 'moment';
import { createDateOff, updateDayOff } from 'app/apis/teamDetailClient';
import { IterationContext } from 'app/contexts/IterationContext';
import { SubPage } from 'app/types/TeamDetail';
import { openNotification } from 'app/utils/notificationUtils';

interface IRenderCell {
  record: AttendanceDataItem;
  onUpdate: (i: number, j: number, attend: SingleAttend) => void;
  index: number;
  subIndex: number;
  attend: SingleAttend;
  memberId: number;
  isEdit: boolean;
}

export const RenderCellAttendance = ({ onUpdate, index, subIndex, record, attend, memberId, isEdit }: IRenderCell) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [attendOption, setAttendOption] = useState<AttendanceType>(attend?.type);
  const [form] = Form.useForm();
  const { selectedIteration } = useContext(IterationContext);
  const isManageHoliday = attend.holiday.isManageHoliday;
  const showModal = () => {
    if (attend.id !== null) {
      form.setFieldsValue({
        note: attend?.note,
        type: attend?.type,
      });
      setAttendOption(AttendanceType.full);
    } else {
      form.setFieldsValue({
        note: '',
        type: attend?.type !== AttendanceType.None ? attend?.type : AttendanceType.full,
      });
      setAttendOption(AttendanceType.full);
    }
    setIsModalVisible(true);
  };
  const onHideModal = () => {
    form.setFieldsValue({
      note: '',
      type: AttendanceType.None,
    });
    setIsModalVisible(false);
  };
  const onFinish = async (values) => {
    try {
      setLoading(true);
      let request: DayOffRequest = {
        iterationID: selectedIteration.id,
        memberID: memberId,
        note: values.type === AttendanceType.None ? '' : values.note,
        type: values.type,
        dayOff: attend.dayOff,
        pageType: SubPage.Monitoring,
      };

      if (attend?.id !== null) {
        const resp = await updateDayOff(attend.id, request);
        let result: SingleAttend = {
          ...resp,
          note: values?.type === AttendanceType.None ? '' : values?.note,
          type:
            values?.type !== AttendanceType.None
              ? values?.type
              : isManageHoliday
              ? AttendanceType.Holiday
              : AttendanceType.None,
        };
        onUpdate(index, subIndex, result);
      } else {
        const resp = await createDateOff(request);
        let result: SingleAttend = {
          ...resp,
          note: values.type === AttendanceType.None ? '' : values?.note,
          type: values?.type,
        };
        onUpdate(index, subIndex, result);
      }
      // if none option has been selected, then set clear the form field
      if (values.type === AttendanceType.None)
        form.setFieldsValue({
          note: '',
        });

      setLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      openNotification(error.status, error?.data?.error?.message);
      setLoading(false);
    }
  };

  if (attend?.holiday?.isHoliday && attend?.holiday?.name === 'Weekend')
    return <AttendanceCell color={AttendanceMark.header} />;
  return (
    <>
      <AssignModal
        title={
          <Row justify="center" className="title-modal">
            {record.name}
            <br /> ({moment(attend.dayOff).format('dddd, MMMM DD, yyyy')})
          </Row>
        }
        visible={isModalVisible}
        footer={false}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
          initialValues={{ type: attend?.type ?? AttendanceType.full, note: attend?.note }}
          onFinish={onFinish}
          colon={true}
        >
          <Form.Item label="Partial day" name="type">
            <Radio.Group>
              <Radio value={AttendanceType.full} onChange={() => setAttendOption(AttendanceType.full)}>
                Full day
              </Radio>
              <Radio value={AttendanceType.half} onChange={() => setAttendOption(AttendanceType.half)}>
                Half day
              </Radio>
              <Radio value={AttendanceType.None} onChange={() => setAttendOption(AttendanceType.None)}>
                None
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Note"
            name="note"
            rules={[
              {
                required: attendOption !== AttendanceType.None,
                message: 'Required this field',
              },
              {
                max: 200,
                message: 'Maximum 200 characters',
              },
            ]}
            hidden={attendOption === AttendanceType.None}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Row justify="center" gutter={20}>
            <Col>
              <Button type="default" onClick={onHideModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </AssignModal>
      {[AttendanceType.None, AttendanceType.Holiday].includes(attend.type) ? (
        <AttendanceCell
          className={isEdit ? 'editable' : ''}
          onClick={isEdit ? showModal : null}
          color={attend.type === AttendanceType.Holiday ? AttendanceMark.pinkDefault : 'white'}
        />
      ) : (
        <Popover
          content={
            <PopoverContent>
              <LegendItem className="legend" align="middle" gutter={10}>
                <Col className="item">
                  <DotComponent color={attend.type === AttendanceType.full ? CriticalStatusColor : RiskStatusColor} />
                  <p>{attend.type === AttendanceType.full ? 'Full day off' : 'Half day off'}</p>
                </Col>
              </LegendItem>
              <Row gutter={10}>
                <Col>Reason:</Col>
                <Col className="description-text">
                  <p>{attend.note}</p>
                </Col>
              </Row>
            </PopoverContent>
          }
          title={`${record.name} - (${moment(attend.dayOff).format('dddd, MMMM DD, yyyy')})`}
        >
          <AttendanceCell
            className={isEdit ? 'editable' : ''}
            onClick={isEdit ? showModal : null}
            color={
              attend.type === AttendanceType.full
                ? CriticalStatusColor
                : attend.type === AttendanceType.half
                ? RiskStatusColor
                : 'white'
            }
          >
            <b>{attend?.isPlanned === true ? 'P' : 'U'}</b>
          </AttendanceCell>
        </Popover>
      )}
    </>
  );
};

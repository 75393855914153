import { TopPerformerRequest, TopPerformerResponse, TopPerformerSelectOptionResponse } from 'app/types/entity';
import { fetchAsync } from 'app/utils/fetch';

export const fetchPerformerById = (id: number | string): Promise<TopPerformerResponse> =>
  fetchAsync(`/api/top-performer/${id}`);

export const fetchPerformersByIterationId = (iterationId: number | string): Promise<TopPerformerResponse[]> =>
  fetchAsync(`/api/top-performer/iteration/${iterationId}`);

export const fetchPerformersSelectOptionsByTeamId = (
  teamId: number | string
): Promise<TopPerformerSelectOptionResponse[]> => fetchAsync(`/api/top-performer/listoptions/${teamId}`);

export const createTopPerformer = (
  performer: TopPerformerRequest,
  iterationId: number | string
): Promise<TopPerformerResponse> => {
  const url = `/api/top-performer/${iterationId}`;
  const data = appendMainFormData(performer);
  return fetchAsync(url, { type: 'text', method: 'POST', body: data });
};

export const updateTopPerformer = (
  performer: TopPerformerRequest,
  iterationId: number | string
): Promise<TopPerformerResponse> => {
  const url = `/api/top-performer/${iterationId}`;
  const data = appendMainFormData(performer);
  data.append(`Id`, `${performer.id}`);
  return fetchAsync(url, { type: 'text', method: 'PUT', body: data });
};

export const deleteTopPerformer = (iterationId: number | string, id: number): Promise<void> => {
  return fetchAsync(`/api/top-performer/${iterationId}/${id}`, {
    method: 'DELETE',
  });
};

const appendMainFormData = (performer: TopPerformerRequest) => {
  let data: FormData = new FormData();
  data.append(`MemberId`, `${performer.memberId}`);
  data.append(`Rank`, `${performer.rank}`);
  data.append(`IterationId`, `${performer.iterationId}`);
  data.append(`Caption`, performer.caption ?? '');
  data.append(`UploadImage`, performer.avatarUpload);

  return data;
};

export const fetchTopPerformersOverview = (teamId: number, iterationName: string): Promise<TopPerformerResponse[]> => {
  const url = `/api/top-performer/overview?teamId=${teamId}&&iterationName=${iterationName}`;
  return fetchAsync(url, { method: 'GET' });
};

const EnhanceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 57 58" version="1.1">
      <path
        d="M 0 1.622 C 0 2.893, 0.301 2.995, 1.388 2.093 C 2.322 1.318, 7.086 1.032, 15.922 1.220 L 29.067 1.500 20.608 15 L 12.148 28.500 17.990 28.796 L 23.833 29.091 15.167 43.029 C 6.372 57.172, 4.387 58.987, 1.200 55.800 C 0.267 54.867, 0 54.981, 0 56.315 C 0 57.902, 2.144 58.011, 28.575 57.765 L 57.150 57.500 57.325 28.750 L 57.500 0.001 28.750 0 C 2.897 0, 0 0.163, 0 1.622 M -0 2 C -0 2.733, 0.300 3.033, 0.667 2.667 C 1.033 2.300, 1.033 1.700, 0.667 1.333 C 0.300 0.967, -0 1.267, -0 2 M 38.647 11 L 31.989 21 38.234 21 L 44.479 21 26.500 39 L 8.521 57 31.060 57 C 47.258 57, 53.938 56.662, 54.800 55.800 C 56.508 54.092, 56.508 3.908, 54.800 2.200 C 54.140 1.540, 51.734 1, 49.453 1 C 45.310 1, 45.298 1.011, 38.647 11 M 0.079 56.583 C 0.127 57.748, 0.364 57.985, 0.683 57.188 C 0.972 56.466, 0.936 55.603, 0.604 55.271 C 0.272 54.939, 0.036 55.529, 0.079 56.583"
        stroke="none"
        fill="#f3f5f7"
        fillRule="evenodd"
      />
      <path
        d="M 1.200 2.200 C -0.508 3.908, -0.508 54.092, 1.200 55.800 C 4.387 58.987, 6.372 57.172, 15.167 43.029 L 23.833 29.091 17.985 28.796 L 12.138 28.500 19.109 17.500 C 22.944 11.450, 26.749 5.263, 27.566 3.750 L 29.051 1 15.726 1 C 6.695 1, 2.013 1.387, 1.200 2.200 M 38.647 11 L 31.989 21 38.234 21 L 44.479 21 26.500 39 L 8.521 57 31.060 57 C 47.258 57, 53.938 56.662, 54.800 55.800 C 56.508 54.092, 56.508 3.908, 54.800 2.200 C 54.140 1.540, 51.734 1, 49.453 1 C 45.310 1, 45.298 1.011, 38.647 11"
        stroke="none"
        fill="#4d6b84"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default EnhanceIcon;

export interface OwnershipReportData {
  id: number;
  teamName: string;
  releaseTrain: string;
  deliveryStream: string;
  portfolio: string;
  project: string;
  releaseTrainId: number;
  deliveryStreamId: number;
  portfolioId: number;
  projectId: number;
  statusChanged: boolean;
}

export enum OwnershipReportFilterType {
  ByPortfolio,
  ByDeliveryStream,
  ByReleaseTrain,
  ByProject,
  ByStatus,
}

import { Col, Empty, Row } from 'antd';
import { Card } from 'app/components/Card';

import { CarriedOverWrapper } from '../CarriedOver/CarriedOver.styled';

interface ICarriedItemProps {
  value: number | string;
  title?: string;
  isPercent: boolean;
}

const ReportCarriedItem = ({ value, title, isPercent }: ICarriedItemProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <p style={{ fontSize: '18px', marginTop: '18px', marginBottom: 0 }}>{title}</p>
      {isPercent ? (
        <p style={{ fontSize: '30px', marginTop: '45px', marginBottom: 0, color: value < 0 ? 'red' : 'green' }}>
          {isPercent ? (value < 0 ? `▼${-value}%` : `▲${value}%`) : value}
        </p>
      ) : (
        <p style={{ fontWeight: 'bold', fontSize: '46px', margin: 0 }}>{value}</p>
      )}
    </div>
  );
};

interface IComponentProps {
  dataSource: any;
  checkHaveOtherData: boolean;
}

export const ReportCarriedOver = ({ dataSource, checkHaveOtherData }: IComponentProps) => {
  if (!dataSource || dataSource === undefined) {
    if (!checkHaveOtherData) {
      return (
        <Card className="empty-card-carried-over" title="Carried Over to Next Sprint">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
      );
    } else
      dataSource = {
        carriedOverUserStories: -1,
        carriedOverDefects: -1,
        userStoriesRate: null,
        defectsRate: null,
      };
  }

  const { carriedOverDefects, carriedOverUserStories, userStoriesRate, defectsRate } = dataSource;

  return (
    <CarriedOverWrapper>
      <Card
        className="carried-Over-High-Level"
        title="Carried Over to Next Sprint"
        titleTooltip={<span>The number of User Stories and Defects were carried over to next iteration.</span>}
      >
        <Row justify="center" className={`carried-card ${carriedOverUserStories <= 0 ? 'healthy' : 'unhealthy'}`}>
          <Col span={12}>
            <ReportCarriedItem
              value={carriedOverUserStories === -1 ? '--' : carriedOverUserStories}
              title="User Stories"
              isPercent={false}
            />
          </Col>
          <Col span={12}>
            <ReportCarriedItem
              value={userStoriesRate === null ? '--' : userStoriesRate}
              isPercent={userStoriesRate !== -1}
            />
          </Col>
        </Row>
        <Row
          justify="center"
          className={`carried-card ${carriedOverDefects <= 0 ? 'healthy' : 'unhealthy'}` + ` carried-Over-High-Level`}
        >
          <Col span={12}>
            <ReportCarriedItem
              value={carriedOverDefects === -1 ? '--' : carriedOverDefects}
              title="Defects"
              isPercent={false}
            />
          </Col>
          <Col span={12}>
            <ReportCarriedItem value={defectsRate === null ? '--' : defectsRate} isPercent={defectsRate !== -1} />
          </Col>
        </Row>
      </Card>
    </CarriedOverWrapper>
  );
};

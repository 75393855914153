import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ForbiddenMessage } from 'app/pages/ForbiddenMessage';
import { Redirect, useHistory } from 'react-router';
import { useFetch } from 'app/hooks/useFetch';
import { fetchAsync } from 'app/utils/fetch';

export const HangFireDashboard = () => {
  const {
    user: { token },
  } = useContext(AuthContext);

  const { data, error, loading } = useFetch(() => fetchAsync('/api/auth/hangfire', { method: 'POST' }));

  if (loading) return <>Please wait...</>;

  if (!token || error) {
    return <ForbiddenMessage />;
  }

  window.location.href = `/jobs`;

  return <></>; //<Redirect to={`/jobs?access_token=${token}`} push />;
};

import { Button, Card, Col, Input, Popconfirm, Row, Space, Tooltip } from 'antd';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { ActionStatus, RetrospectiveNoteResponse } from 'app/types/entity';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { CheckCircleFill, PencilSquare, Trash } from 'react-bootstrap-icons';
import './RetrospectiveNoteCard.scss';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { UserContext } from 'app/contexts/UserContext';
import { Stage } from 'app/types/TeamDetailTypes';
import moment from 'moment';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';

export interface IProps {
  dataSource?: RetrospectiveNoteResponse[];
  isSelectedIteration: Boolean;
  note: RetrospectiveNoteResponse;
  isEditing?: boolean;
  isAction: boolean;
  footer?: (note: RetrospectiveNoteResponse) => ReactNode;

  onEdit?: () => void;
  onDelete?: (id: number) => void;

  onSave?: (note: RetrospectiveNoteResponse) => void;
  onCancel?: () => void;
  onOpenRetroNoteModal?: () => void;
}

const RetrospectiveNoteCard = ({
  note,
  isEditing,
  isAction,
  footer,
  onEdit,
  onCancel,
  onSave,
  onOpenRetroNoteModal,
  ...props
}: IProps) => {
  const { selectedIteration, stage, reportStatus } = useContext(IterationContext);
  const { isAuthorized } = useAuthz();
  const {
    user: { permissions },
  } = useContext(UserContext);
  const allowEdit =
    isAuthorized &&
    (stage === Stage.Other ||
      stage === undefined ||
      (selectedIteration?.isCurrentSprint && stage === Stage.Planning)) &&
    canEditReport(reportStatus, permissions);

  const [editing, setEditing] = useState(isEditing === true);
  const [text, setText] = useState(note.content);

  useEffect(() => {
    setText(note.content);
  }, [note.content]);

  const onSaveHandler = (note: RetrospectiveNoteResponse) => {
    onSave(note);
    setEditing(false);
  };

  const cancelDelete = () => {
    setEditing(false);
    setText(note.content);
    if (onCancel) onCancel();
  };
  var currentDate = moment().format();
  var datetime = moment(note?.deadline).format();
  let showTextEdit =
    (reportStatus === null || reportStatus == IterationReportStatusEnum.InProgress) && stage !== Stage.Planned;

  return (
    <Card
      className={`action-card ${datetime && datetime < currentDate ? 'miss-deadline' : ''} ${
        showTextEdit ? '' : 'readonly-card'
      }`}
    >
      {showTextEdit ? (
        <Input.TextArea
          className={`action-content ${datetime && datetime < currentDate ? 'miss-deadline' : ''} ${
            props.isSelectedIteration !== true ? 'previous-iteration' : ''
          }`}
          readOnly={!editing}
          value={text}
          bordered={editing}
          onChange={({ target }) => setText(target.value)}
          maxLength={500}
          cols={5}
          autoFocus={editing}
        />
      ) : (
        <div>{text}</div>
      )}

      <Space align="start" className="actions-container" style={{ display: showTextEdit ? 'initial' : 'none' }}>
        {editing ? (
          <Row>
            <Col span={24}>
              <Row justify="center" align="middle">
                <Tooltip
                  placement="bottom"
                  visible={text === undefined || (text?.length > 0 && text?.length < 5)}
                  title={'Require at least 5 characters'}
                  overlayInnerStyle={{ background: 'white', color: 'black', fontSize: '14px', padding: '10px' }}
                >
                  <Button
                    type="text"
                    className="btn-mark-done"
                    onClick={() => {
                      onSaveHandler({ ...note, content: text });
                      setEditing(false);
                    }}
                    disabled={text === undefined || text.length < 5}
                  >
                    <span className="text">Save</span>
                  </Button>
                </Tooltip>
                <Button type="text" className="btn-cancel" onClick={cancelDelete}>
                  <span className="text">Cancel</span>
                </Button>
              </Row>
            </Col>
          </Row>
        ) : (
          <div
            className={`un-editing-container ${props.isSelectedIteration === true ? 'edit-trash' : ''} ${
              isAction ? 'action-container' : ''
            }`}
          >
            {footer && footer(note)}
            {props.isSelectedIteration === true ? (
              <div className="edit-button">
                <Button
                  type="text"
                  icon={<PencilSquare color="#B1B1B1" />}
                  onClick={() => {
                    if (props.dataSource && note?.type === 3) {
                      if (onOpenRetroNoteModal) {
                        onEdit();
                        onOpenRetroNoteModal();
                      }
                    } else {
                      setEditing(true);
                      if (onEdit) onEdit();
                    }
                  }}
                  hidden={!allowEdit}
                />
                <Popconfirm
                  title="Are you sure to delete this Action?"
                  onConfirm={() => props.onDelete(note.id)}
                  onCancel={cancelDelete}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<Trash color="#B1B1B1" />} hidden={!allowEdit} type="text" />
                </Popconfirm>
              </div>
            ) : (
              <div className="status-button">
                <Button
                  type="text"
                  icon={<CheckCircleFill color="#0BA800" />}
                  className="btn-done"
                  hidden={note.status === ActionStatus.Active}
                  onClick={() => {
                    onSaveHandler({ ...note, status: ActionStatus.Active });
                  }}
                  disabled={!allowEdit}
                >
                  <span className="text">Done</span>
                </Button>
                <Button
                  type="text"
                  className="btn-mark-done"
                  hidden={note.status === ActionStatus.Done}
                  onClick={() => {
                    onSaveHandler({ ...note, status: ActionStatus.Done });
                  }}
                  disabled={!allowEdit}
                >
                  <span className="text">Mark Done</span>
                </Button>
              </div>
            )}
          </div>
        )}
      </Space>
    </Card>
  );
};

export default RetrospectiveNoteCard;

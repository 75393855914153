import { Tag } from 'antd';
import 'app/pages/WorkItem/WorkItem.scss';
import { IterationGoalResponse } from 'app/types/entity';
import { IterationGoalStatus } from './IterationGoal';
import './IterationGoalStatusItem.scss';

interface IProps {
  item: IterationGoalResponse;
}

export const IterationGoalStatusItem = ({ item }: IProps) => {
  let bgColor = '';
  let text = '';
  switch (item.status) {
    case IterationGoalStatus.None:
      text = 'N/A';
      break;
    case IterationGoalStatus.Pass:
      text = 'Pass';
      bgColor = 'pass';
      break;
    case IterationGoalStatus.Fail:
      text = 'Fail';
      bgColor = 'fail';
      break;
  }

  return (
    <Tag className={`integeration-goal-status-tag ${bgColor}`}>
      <span>{text}</span>
    </Tag>
  );
};

import { Col, notification, Progress, Row } from 'antd';
import { fetchTargetCapacity, saveNoteCapacity } from 'app/apis/teamDetailClient';
import {
  MobileBreakPoint,
  PROGRESS_BAR_MAX_COLOR,
  PROGRESS_BAR_MIN_COLOR,
  PROGRESS_BELOW_TARGET__COLOR,
  PROGRESS_COLOR,
  PROGRESS_GRADIENT_START__COLOR,
  PROGRESS_ON_TARGET__COLOR,
} from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { IterationContext } from 'app/contexts/IterationContext';
import { UserContext } from 'app/contexts/UserContext';
import { useAuthz } from 'app/hooks/useAuthz';
import { useFetch } from 'app/hooks/useFetch';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { SubPage } from 'app/types/TeamDetail';
import { NoteReportCapacityModel, TeamCapacityMonitorModel, TeamCapacityResponse } from 'app/types/TeamDetailTypes';
import { openNotification } from 'app/utils/notificationUtils';
import { updateItem } from 'app/utils/tableUtils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TeamCapacityMonitorContainer } from './TeamCapacityMonitor.styled';
import useIsMobile from 'app/utils/useIsMobile';

interface IProps {
  dataMonitor?: TeamCapacityResponse;
  page: SubPage;
}
export const TeamCapacityMonitor = ({ ...props }: IProps) => {
  const { isAuthorized } = useAuthz();
  const { reportStatus } = useContext(IterationContext);
  const {
    user: { permissions },
  } = useContext(UserContext);
  const { selectedIteration } = useContext(IterationContext);

  const { data: fetchedTargetCapacity } = useFetch(
    () => fetchTargetCapacity(selectedIteration?.id),
    [selectedIteration?.id]
  );
  const [dataMonitor, setDataMonitor] = useState<TeamCapacityMonitorModel[]>([]);
  const [targetCapacity, setTargetCapacity] = useState(fetchedTargetCapacity?.target);
  const [isMobile] = useIsMobile();

  useEffect(() => {
    if (props.dataMonitor && props.dataMonitor.tasks) {
      let sum: TeamCapacityMonitorModel = {
        actual: 0,
        todo: 0,
        capacity: 0,
        effortUpdateResult: 0,
        plan: 0,
        owner: 'Total',
        note: '',
        id: 0,
      };
      const result = props.dataMonitor?.tasks?.map((x) => {
        const capacity = props.dataMonitor.plannedCapacity.find((p) => p.owner === x.owner);
        return capacity ? { ...x, capacity: capacity?.capacity, id: capacity.id, note: capacity.note } : x;
      });
      result.forEach((item) => {
        sum = countData(sum, item);
      });
      setDataMonitor([...result, sum]);
    }
  }, [props.dataMonitor]);

  useEffect(() => {
    setTargetCapacity(fetchedTargetCapacity?.target);
  }, [fetchedTargetCapacity]);
  function countData(prev: TeamCapacityMonitorModel, next: TeamCapacityMonitorModel) {
    prev.actual = prev?.actual + next?.actual;
    prev.todo = prev?.todo + next?.todo;
    prev.capacity = prev.capacity + next.capacity;
    prev.effortUpdateResult = prev.effortUpdateResult + next.effortUpdateResult;
    prev.plan = prev.plan + next.plan;
    return prev;
  }
  const PercentageBar = ({ value }: any) => {
    const middlePoint = value - 100;

    const strokes =
      value > 100
        ? {
            '0%': middlePoint > 100 ? PROGRESS_BAR_MAX_COLOR : PROGRESS_BAR_MIN_COLOR,
            [`${100 - middlePoint}%`]: PROGRESS_BAR_MAX_COLOR,
            '100%': PROGRESS_COLOR.over,
          }
        : value < targetCapacity
        ? {
            from: PROGRESS_GRADIENT_START__COLOR,
            to: PROGRESS_BELOW_TARGET__COLOR,
          }
        : {
            from: PROGRESS_GRADIENT_START__COLOR,
            to: PROGRESS_ON_TARGET__COLOR,
          };

    const percent = value === Infinity ? 0 : value > 100 ? 100 : value;

    return (
      <>
        <Row
          gutter={[8, 8]}
          className={`progress-wrapper ${value > 100 ? 'over-capacity' : value < targetCapacity ? 'below-target' : ''}`}
        >
          <Col span={20}>
            <Progress className="bar" strokeColor={strokes} percent={percent} showInfo={false} strokeLinecap="square" />
          </Col>
          <Col>
            <div className="percentage-value">
              {value === Infinity ? 'N/A' : value}
              {value !== Infinity && '%'}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const MyCapacityPlanning = (record: object) => {
    const progressCapacity = Number(((record['actual'] / record['capacity']) * 100).toFixed());
    if (isNaN(progressCapacity) || !isFinite(progressCapacity)) {
      return 0;
    } else {
      return progressCapacity;
    }
  };
  const MyPercentageBarMonitor = (record: any) => {
    const value = Number(((record['plan'] / record['capacity']) * 100).toFixed());
    if (isNaN(value) || !isFinite(value)) {
      return {
        props: { className: record?.id === 0 ? 'rowTotal_CapacityDetail' : '' },
        children: <PercentageBar value={0} />,
      };
    } else {
      return {
        props: { className: record?.id === 0 ? 'rowTotal_CapacityDetail' : '' },
        children: <PercentageBar value={value} />,
      };
    }
  };
  const MyPercentageActualMonitor = (record: any) => {
    const value = Number(((record['actual'] / record['capacity']) * 100).toFixed());
    if (isNaN(value) || !isFinite(value)) {
      return {
        props: { className: record?.id === 0 ? 'rowTotal_CapacityDetail' : '' },
        children: <PercentageBar value={0} />,
      };
    } else {
      return {
        props: { className: record?.id === 0 ? 'rowTotal_CapacityDetail' : '' },
        children: <PercentageBar value={value} />,
      };
    }
  };
  const RenderCapacity = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_CapacityDetail' : '' },
    children: value,
  });
  const RenderPlan = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_CapacityDetail' : record.capacity < record.plan ? 'red-text' : '' },
    children: value,
  });
  const RenderTodo = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_CapacityDetail' : '' },
    children: value,
  });
  const RenderName = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_CapacityDetail' : '' },
    children: value,
  });
  const RenderNote = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_CapacityDetail' : '' },
    children: value,
  });
  const RenderActual = (value, record) => {
    let className = 'normal-effort';
    if (record.effortUpdateResult == 0) {
      className = 'highlight-effort not-update-effort';
    } else if (record.effortUpdateResult < 0) {
      className = 'highlight-effort effort-decrease';
    }
    if (record.capacity < record.actual) {
      className += ' red-text';
    }
    return {
      props: { className: record.id === 0 ? 'rowTotal_CapacityDetail' : '' },
      children: (
        <Row className={className} justify="center" style={{ width: '80%', maxWidth: '100%', margin: '0 auto' }}>
          {value}
        </Row>
      ),
    };
  };

  const columns = useMemo<EditableColumn<any>[]>(
    () => [
      {
        title: '#',
        width: 40,
        key: 'num',
        render: (value, record, index) => (record?.id === 0 ? null : index + 1),
        align: 'center',
        fixed: !isMobile ? 'left' : false,
      },
      {
        title: 'Name',
        dataIndex: 'owner',
        key: 'name',
        width: 200,
        editable: false,
        ellipsis: true,
        align: 'left',
        render: RenderName,
        fixed: !isMobile ? 'left' : false,
      },
      {
        title: 'Capacity',
        width: 80,
        dataIndex: 'capacity',
        key: 'capacity',
        align: 'center',
        editable: false,
        inputType: 'text',
        sorter: (a, b) => {
          if (a.owner === 'Total' || b.owner === 'Total') return 0;
          return a.capacity - b.capacity;
        },
        render: RenderCapacity,
      },
      {
        title: 'Plan',
        dataIndex: 'plan',
        key: 'plan',
        width: 80,
        align: 'center',
        sorter: (a, b) => {
          if (a.owner === 'Total' || b.owner === 'Total') return 0;
          return a.plan - b.plan;
        },
        render: RenderPlan,
      },
      {
        title: 'Todo',
        dataIndex: 'todo',
        key: 'todo',
        width: 80,
        align: 'center',
        sorter: (a, b) => {
          if (a.owner === 'Total' || b.owner === 'Total') return 0;
          return a.todo - b.todo;
        },
        render: RenderTodo,
      },
      {
        title: 'Actual',
        dataIndex: 'actual',
        key: 'actual',
        width: 80,
        align: 'center',
        sorter: (a, b) => {
          if (a.owner === 'Total' || b.owner === 'Total') return 0;
          return a.actual - b.actual;
        },
        render: RenderActual,
      },
      {
        title: 'Capacity Planning',
        dataIndex: 'capacityPlanning',
        key: 'percent',
        width: 330,
        sorter: (a, b) => {
          if (a.owner === 'Total' || b.owner === 'Total') return 0;
          return MyCapacityPlanning(a) - MyCapacityPlanning(b);
        },
        sortDirections: ['ascend', 'descend'],
        align: 'left',
        render: (value, record, index) => MyPercentageBarMonitor(record),
      },
      {
        title: 'Actual Effort',
        dataIndex: 'capacityPlanning',
        key: 'percent',
        width: 300,
        sorter: (a, b) => {
          if (a.owner === 'Total' || b.owner === 'Total') return 0;
          return MyCapacityPlanning(a) - MyCapacityPlanning(b);
        },
        sortDirections: ['ascend', 'descend'],
        align: 'left',
        render: (value, record, index) => MyPercentageActualMonitor(record),
      },
      {
        title: 'Notes',
        dataIndex: 'note',
        key: 'note',
        editable: true,
        width: 200,
        align: 'left',
        rules: [{ max: 200, message: 'The field Note must be a string with a maximum length of 200' }],
        render: RenderNote,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetCapacity]
  );
  const onSave = async (id: number, record) => {
    try {
      if (String(record.note).length > 200) {
        notification.error({
          message: `Note is 200 characters only`,
          duration: 2,
        });
      } else {
        let request: NoteReportCapacityModel = {
          note: record.note,
        };
        let response = await saveNoteCapacity(id, request);
        let newData = dataMonitor.find((x) => x.id === response['id']);
        newData.note = record.note;
        updateItem(newData, dataMonitor);
      }
    } catch (error) {
      openNotification(error.status, error?.data?.error?.message);
    }
  };
  return (
    <TeamCapacityMonitorContainer style={{ padding: 20 }}>
      <span className="target">Target: {targetCapacity}%</span>
      <EditableTable
        scroll={{
          x: 'max-content',
        }}
        pageSize={100}
        bordered={true}
        className={'container-table'}
        pagination={{
          hideOnSinglePage: true,
        }}
        data={dataMonitor}
        columns={columns}
        onSave={onSave}
        isEdit={
          (isAuthorized && props.page === SubPage.Report) ||
          (props.page === SubPage.Monitoring && canEditReport(reportStatus, permissions))
        }
      />
    </TeamCapacityMonitorContainer>
  );
};

import { Button, Col, InputNumber, Popconfirm, Row } from 'antd';
import { useEffect, useState } from 'react';
import { StyledChartTarget } from './ChartTarget.styled';

interface IProps {
  value: number;
  onSave?: (value: number) => Promise<boolean>;
  unit?: string;
}

export const ChartTarget = ({ onSave, value: propValue, unit = '%' }: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [targetValue, setTargetValue] = useState<number>(propValue);

  useEffect(() => {
    setTargetValue(propValue);
  }, [propValue]);

  return (
    <StyledChartTarget>
      <Row gutter={8}>
        <Col className={`gutter-row ${isEditing ? 'p-top' : ''}`}>
          <span>Target:</span>
        </Col>
        <Col className="gutter-row">
          {isEditing ? (
            <InputNumber width={'2rem'} min={0} value={targetValue} onChange={(value) => setTargetValue(value)} />
          ) : (
            <span>
              <strong>
                {targetValue}
                {unit}
              </strong>
            </span>
          )}
        </Col>
        {isEditing && (
          <Col className={`gutter-row ${isEditing ? 'p-top' : ''}`}>
            <span>{unit}</span>
          </Col>
        )}
        <Col>
          {isEditing ? (
            <Row style={{ marginTop: '2px' }}>
              <Button
                type="link"
                size="small"
                onClick={async () => {
                  if (!(await onSave?.(targetValue))) {
                    console.error('Error when saving target plan!');
                    return;
                  }

                  setIsEditing(false);
                }}
              >
                Save
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  setTargetValue(propValue);
                  setIsEditing(false);
                }}
              >
                <Button type="link" size="small">
                  Cancel
                </Button>
              </Popconfirm>
            </Row>
          ) : (
            <Row style={{ padding: '0px 8px 8px 1px', color: '#1890ff', cursor: 'pointer' }}>
              <span onClick={() => setIsEditing(true)}>Edit</span>
            </Row>
          )}
        </Col>
      </Row>
    </StyledChartTarget>
  );
};

import { Col, notification, Progress, Row, Tooltip } from 'antd';
import { Card } from 'app/components/Card';
import {
  fetchTargetCapacity,
  saveTeamCapacity,
  setTargetCapacity as setTargetCapacityApi,
} from 'app/apis/teamDetailClient';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { useFetch } from 'app/hooks/useFetch';
import { Stage, TargetCapacityModel, TeamCapacityModel } from 'app/types/TeamDetailTypes';
import { useContext, useEffect, useMemo, useState } from 'react';
import { EditableColumn, EditableTable } from '../../EditableTable';
import { TargetPlan } from '../TargetPlan/TargetPlan';
import { TeamCapacityContainer } from './TeamCapacity.styled';
import {
  PROGRESS_BAR_MAX_COLOR,
  PROGRESS_BAR_MIN_COLOR,
  PROGRESS_BELOW_TARGET__COLOR,
  PROGRESS_COLOR,
  PROGRESS_GRADIENT_START__COLOR,
  PROGRESS_ON_TARGET__COLOR,
} from 'app/common/constants';
interface IProps {
  dataPlan?: TeamCapacityModel[];
}
export const TeamCapacity = (props: IProps) => {
  const { isAuthorized } = useAuthz();
  const { stage: currentStage, selectedIteration } = useContext(IterationContext);

  const { data: fetchedTargetCapacity } = useFetch(() => {
    if (selectedIteration?.iterationPlan) {
      return fetchTargetCapacity(selectedIteration?.id);
    }

    return new Promise<TargetCapacityModel>((resolve, reject) => resolve({ target: 0 }));
  }, [selectedIteration?.iterationPlan]);
  const [targetCapacity, setTargetCapacity] = useState(fetchedTargetCapacity?.target);

  useEffect(() => {
    setTargetCapacity(fetchedTargetCapacity?.target);
  }, [fetchedTargetCapacity]);

  const onTargetSave = async (value: number) => {
    return await setTargetCapacityApi(selectedIteration?.id, { target: value })
      .then((data: TargetCapacityModel) => {
        setTargetCapacity(data.target);

        notification.success({
          message: 'Update Target capacity successfully',
          duration: 3,
        });

        return true;
      })
      .catch((error) => {
        console.error(error);
        // const err = error.data as ErrorResponse;
        notification.error({
          message: 'Target plan value must be between 0 and 100', //err?.error?.message ?? error.message ?? 'Unknown error',
          duration: 2,
        });

        return false;
      });
  };

  const PercentageBar = ({ value }: any) => {
    const middlePoint = value - 100;

    const strokes =
      value > 100
        ? {
            '0%': middlePoint > 100 ? PROGRESS_BAR_MAX_COLOR : PROGRESS_BAR_MIN_COLOR,
            [`${100 - middlePoint}%`]: PROGRESS_BAR_MAX_COLOR,
            '100%': PROGRESS_COLOR.over,
          }
        : value < targetCapacity
        ? {
            from: PROGRESS_GRADIENT_START__COLOR,
            to: PROGRESS_BELOW_TARGET__COLOR,
          }
        : {
            from: PROGRESS_GRADIENT_START__COLOR,
            to: PROGRESS_ON_TARGET__COLOR,
          };

    return (
      <>
        <Row
          gutter={[8, 8]}
          className={`progress-wrapper ${value > 100 ? 'over-capacity' : value < targetCapacity ? 'below-target' : ''}`}
        >
          <Col span={24}>
            <Progress
              className="bar"
              strokeColor={strokes}
              percent={value > 100 ? 100 : value}
              showInfo={false}
              strokeLinecap="square"
            />
            <span className="percent">
              <span className="percentage-value">
                {value === Infinity ? 'N/A' : value}
                {value !== Infinity && '%'}
              </span>
            </span>
          </Col>
        </Row>
      </>
    );
  };

  const MyPercentageBar = (value: any, record) => {
    return {
      props: { className: record.id === 0 ? 'rowTotal_TeamDetail' : '' },
      children: <PercentageBar value={value} />,
    };
  };
  const MyTooltip = (value: any, record: any) => {
    return {
      props: { className: record.id === 0 ? 'rowTotal_TeamDetail' : '' },
      children: (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    };
  };
  const RenderCapacity = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_TeamDetail' : '' },
    children: Number(value).toFixed(1),
  });
  const RenderPlan = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_TeamDetail' : record.capacity < record.plan ? 'red-text' : '' },
    children: Number(value).toFixed(1),
  });
  const MyNumber = (value, record) => ({
    props: { className: record.id === 0 ? 'rowTotal_TeamDetail' : record.capacity < record.plan ? 'red-text' : '' },
    children: Number(value).toFixed(1),
  });

  const columns = useMemo<EditableColumn<any>[]>(
    () => [
      {
        title: '#',
        width: 60,
        key: 'num',
        render: (value, record, index) => (record?.id === 0 ? null : index + 1),
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'owner',
        key: 'name',
        width: 200,
        editable: false,
        ellipsis: true,
        align: 'left',
        render: MyTooltip,
      },
      {
        title: 'Capacity Planning',
        dataIndex: 'capacityPlanning',
        key: 'percent',
        width: 330,
        sorter: (a, b) => {
          if (a.owner === 'Total' || b.owner === 'Total') return 0;
          return a.capacityPlanning - b.capacityPlanning;
        },
        sortDirections: ['ascend', 'descend'],
        align: 'left',
        render: MyPercentageBar,
      },
      {
        title: 'Capacity',
        width: 80,
        dataIndex: 'capacity',
        key: 'capacity',
        align: 'right',
        editable: false,
        inputType: 'text',
        render: RenderCapacity,
      },
      {
        title: 'Plan',
        dataIndex: 'plan',
        key: 'plan',
        width: 80,
        align: 'right',
        render: RenderPlan,
      },
      {
        title: 'Available',
        dataIndex: 'available',
        key: 'available',
        width: 90,
        align: 'right',
        render: MyNumber,
      },
      {
        title: 'Point',
        dataIndex: 'point',
        key: 'point',
        align: 'right',
        width: 80,
        editable: true,
        inputType: 'text',
        render: MyNumber,
      },
      {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        width: 370,
        ellipsis: true,
        render: MyTooltip,
        editable: true,
        align: 'left',
        inputType: 'text',
        rules: [{ max: 200, message: 'The field Note must be a string with a maximum length of 200' }],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetCapacity]
  );

  const isValidNumber = (value) => {
    return Number.isInteger(value) && value >= 0;
  };
  const onSave = async (id: number, { point, note }: TeamCapacityModel) => {
    try {
      point = Number(point);

      if (String(note).length > 200) {
        notification.error({
          message: `Note is 200 characters only`,
          duration: 2,
        });
        return;
      }

      if (!isValidNumber(point)) {
        notification.error({
          message: `Invalid point`,
          duration: 2,
        });
        return;
      }

      props.dataPlan.forEach((item) => {
        if (item.id === id) {
          item.point = point;
          item.note = note;
        }
      });

      const postData = { ...props.dataPlan.find((x) => x.id === id), id, point, note };

      await saveTeamCapacity(selectedIteration?.id, [postData]);
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: `Unknown error. Check the console.`,
        duration: 2,
      });
    }
  };

  var child = document.createElement('col');
  child.setAttribute('style', 'width:200px');
  var colGroup = document.getElementsByTagName('colgroup')[2];
  if (colGroup && colGroup.childElementCount < 9) {
    colGroup.appendChild(child);
  }

  const filterData = (data: TeamCapacityModel[]) => {
    let sum: TeamCapacityModel = {
      id: 0,
      owner: 'Total',
      capacityPlanning: 0,
      capacity: 0,
      plan: 0,
      available: 0,
      point: 0,
      note: '',
    };
    if (data && data.length > 0) {
      data?.forEach((item) => {
        sum = countData(sum, item);
      });
      return [...data, sum];
    }
  };

  function countData(prev: TeamCapacityModel, next: TeamCapacityModel) {
    prev.capacity = prev?.capacity + next?.capacity;
    prev.plan = prev.plan + next.plan;
    prev.available = prev.available + next.available;
    prev.point = prev.point + next.point;
    prev.capacityPlanning = prev.capacity > 0 ? Number(((prev.plan / prev.capacity) * 100).toFixed(2)) : 0;
    return prev;
  }
  return (
    <TeamCapacityContainer style={{ padding: 20 }}>
      <Card
        className="title"
        title="Team detail"
        titleTooltip={
          <>
            <span>Team Detail table requires setting up target, capacity to use.</span>
            <ul className="tooltip-content">
              <li>Capacity planning = Plan / Capacity * 100%</li>
              <li>Available = Capacity - Plan</li>
              <li>
                Point: The point of each folk counts total point of cards that they are owner on team board by default
                and you can change it manually.
              </li>
            </ul>
          </>
        }
      />
      <EditableTable
        pageSize={100}
        data={filterData(props?.dataPlan)}
        isEdit={
          (selectedIteration?.isCurrentSprint || selectedIteration?.isFutureSprint) &&
          isAuthorized &&
          currentStage !== undefined &&
          currentStage !== Stage.Planned
        }
        columns={columns}
        onSave={onSave}
        scroll={{ x: '100%' }}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
      <TargetPlan value={targetCapacity} onSave={onTargetSave} />
    </TeamCapacityContainer>
  );
};

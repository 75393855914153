import { Button, Col, Row } from 'antd';
import { fetchHeatmapDetail } from 'app/apis/ownershipHeatmap';
import { roleNames } from 'app/common/constants';
import { UserContext } from 'app/contexts/UserContext';
import { useFetch } from 'app/hooks/useFetch';
import React, { useContext, useState } from 'react';
import { OwnershipDetailForm } from './OwnershipDetailForm';
import { OwnershipDetailInfo } from './OwnershipDetailInfo';
import { OwnershipDetailDrawerWrapper } from './OwnershipStatusDetail.styled';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  teamId: number;
  selectedMonth: moment.Moment;
  teamName: string;
  smEmail: string;
};

export const OwnershipDetailDrawer = ({ visible, setVisible, selectedMonth, teamName, teamId, smEmail }: Props) => {
  const [isSubmiting, setIsSubmitting] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const { data: heatmapDetail } = useFetch(() => fetchHeatmapDetail(selectedMonth.toDate(), teamId), [visible, isEdit]);
  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const isAllowedEdit =
    userRole === roleNames.admin ||
    userRole === roleNames.pqa ||
    (userRole === roleNames.scrumMaster && email === smEmail);
  return (
    <OwnershipDetailDrawerWrapper
      title="Detail"
      visible={visible}
      onClose={() => {
        setVisible(false);
        setEdit(false);
      }}
      width={800}
      footer={false}
      destroyOnClose={true}
    >
      <div className="modal-body">
        <Row align="middle" justify="space-between">
          <Col>
            <div className="title-header">
              <p>{teamName ?? ''}</p>
            </div>
          </Col>

          {isEdit ? (
            <Col>
              <Button className="btn first" type="default" onClick={() => setEdit(false)}>
                Cancel
              </Button>
              <Button className="btn" type="primary" htmlType="submit" form="ownership" loading={isSubmiting}>
                Save
              </Button>
            </Col>
          ) : (
            isAllowedEdit && (
              <Button className="btn" type="primary" onClick={() => setEdit(true)}>
                Edit
              </Button>
            )
          )}
        </Row>

        <Row>
          <Col span={24}>
            {isEdit ? (
              heatmapDetail && (
                <OwnershipDetailForm
                  data={heatmapDetail}
                  setIsSubmiting={setIsSubmitting}
                  setToggleEdit={setEdit}
                  smEmail={smEmail}
                />
              )
            ) : (
              <OwnershipDetailInfo data={heatmapDetail} />
            )}
          </Col>
        </Row>
      </div>
    </OwnershipDetailDrawerWrapper>
  );
};

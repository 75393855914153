/* eslint-disable react/prop-types */
import { Button, Col, DatePicker, Image, Row, Spin } from 'antd';
import { Helmet } from 'react-helmet';
import { useContext, useEffect, useState } from 'react';
import { CommonSection } from 'app/components/CommonSection/CommonSection';
import { WeeklyOverview } from './Overview/WeeklyOverview';
import { WeeklyStatusWrapper } from './ReportWeeklyStatus.styled';
import { TeamDetail, WeeklyOverviewData } from 'app/types/WeeklyOverview';
import { TeamContext } from 'app/contexts/TeamContext ';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';

import { TeamWeeklyStatusModal } from './StatusDetail/TeamWeeklyStatusModal/TeamWeeklyStatusModal';
import { roleNames } from 'app/common/constants';
import { createWeeklyStatusPageData, getWeeklyStatusOverview } from 'app/apis/reportClient';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { AuthContext } from 'app/contexts/AuthContext';
import { DetailSection } from './DetailSection/DetailSection';

export function ReportWeeklyStatus() {
  const { user } = useContext(AuthContext);
  // state of selection in dropdown week list
  const [selectedWeekStartDate, setSelectedWeekStartDate] = useState<moment.Moment>(moment().startOf('week'));

  const [loading, setLoading] = useState<boolean>(false);
  const { setBreadcrumbs } = useContext(TeamContext);

  // Toggle Modal for Team detail
  const [toggleModal, setToggleModal] = useState(false);
  // The overview data of selected week
  const [weeklyOverviewData, setWeeklyOverviewData] = useState<WeeklyOverviewData>(new WeeklyOverviewData());
  // State to defined whenever open edit/create form for an issue in team detail
  // When one form is open, all the other have to turn off edit/create state, so these can handle edit/create button display too
  const [isEditIssue, setIsEditIssue] = useState<boolean>(false);
  const [isCreateIssue, setIsCreateIssue] = useState<boolean>(false);
  // Set the data of current selected team, to display detail, keep its data until modal detail has been closed
  const [selectedTeamCardData, setSelectedTeamCardData] = useState<TeamDetail>();
  // Trigger reset the form to make sure everything in the previous form have been replaced by the new form.
  // This happened when we changing the week in the modal team detail, the form still display data of previous week,
  // cause it havent re-render the component yet
  const [resetForm, setResetForm] = useState<boolean>(false);

  const getOverviewData = async (weekStartDate: Date) => {
    if (!weekStartDate) return;
    setLoading(true);
    var data = await getWeeklyStatusOverview(weekStartDate);
    setWeeklyOverviewData(data);
    setLoading(false);
  };

  const handleWeeklyOptionChange = (date: moment.Moment) => {
    if (date) {
      setSelectedWeekStartDate(date.startOf('week'));
    }
  };

  const disabledDates: RangePickerProps['disabledDate'] = (selected) => {
    return selected && (selected >= moment().endOf('week') || selected <= moment('01-01-2021', 'MM-DD-YYYY'));
  };

  const createWeeklyReport = async () => {
    let data = await createWeeklyStatusPageData(selectedWeekStartDate.toDate());
    setWeeklyOverviewData(data);
  };

  useEffect(() => {
    const refreshOverviewData = async () => {
      setLoading(true);
      getOverviewData(selectedWeekStartDate.toDate());
      setBreadcrumbs([
        {
          path: '#',
          text: 'Report',
          parentCrumb: null,
          renderAsLink: false,
        },
        createDefaultBreadCrumb('Weekly Status'),
      ]);
    };
    refreshOverviewData();
  }, [selectedWeekStartDate, setBreadcrumbs]);
  return (
    <>
      <Helmet>
        <title>ADM Tool | Report Weekly Status</title>
      </Helmet>

      <WeeklyStatusWrapper justify="space-between">
        <Col>
          <span className="title">
            WEEKLY STATUS
            <DatePicker
              style={{ marginLeft: 10 }}
              picker="week"
              format={'yyyy [Week] w'}
              disabledDate={disabledDates}
              value={selectedWeekStartDate}
              onChange={handleWeeklyOptionChange}
            />
          </span>
        </Col>

        {!loading &&
          weeklyOverviewData.portfolioDevelopmentTeams.length === 0 &&
          (user.role === roleNames.admin || user.role === roleNames.pqa) && (
            <Col>
              <Button className="btn-create" onClick={createWeeklyReport}>
                Create
              </Button>
            </Col>
          )}
      </WeeklyStatusWrapper>
      {loading && <Spin />}
      {!loading &&
        (weeklyOverviewData.portfolioDevelopmentTeams.length > 0 ? (
          <>
            {/* overview section */}
            <CommonSection title="Overview">
              <WeeklyOverview
                dataSource={weeklyOverviewData.chartWeeklyStatus}
                highlight={weeklyOverviewData.highlight}
                currentWeek={selectedWeekStartDate}
              />
            </CommonSection>
            {/* Status detail section */}
            <DetailSection
              setSelectedTeamCardData={setSelectedTeamCardData}
              setToggleModal={setToggleModal}
              teams={weeklyOverviewData.portfolioDevelopmentTeams}
            />

            {/* Modal team detail */}
            <TeamWeeklyStatusModal
              toggleModal={toggleModal}
              setToggleModal={setToggleModal}
              isCreateIssue={isCreateIssue}
              isEditIssue={isEditIssue}
              setIsCreateIssue={setIsCreateIssue}
              setIsEditIssue={setIsEditIssue}
              selectedWeek={selectedWeekStartDate}
              setResetForm={setResetForm}
              resetForm={resetForm}
              selectedTeamCardData={selectedTeamCardData}
              setSelectedTeamCardData={setSelectedTeamCardData}
              setWeeklyOverviewData={setWeeklyOverviewData}
            />
          </>
        ) : (
          <Row justify="center">
            <Image src="/assets/img/report-status-overview.png" alt="program-overview" preview={false} />
          </Row>
        ))}
    </>
  );
}

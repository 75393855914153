import { Treemap } from '@ant-design/plots';
import { TreemapConfig } from '@ant-design/plots/es/components/treemap';
import { Col, DatePicker, Empty, Image, notification, Progress, Row, Space, Spin, TablePaginationConfig } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import { createQuarterReleaseNote, updateQuarterReleaseNote } from 'app/apis/quarterReleaseNote';
import { getQuarterReleasePlanData } from 'app/apis/reportClient';
import {
  MobileBreakPoint,
  PROGRESS_GRADIENT_START__COLOR,
  PROGRESS_ON_TARGET__COLOR,
  TABLE_DEFAULT_PAGE_SIZE,
} from 'app/common/constants';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { StyledPrePlanWrapper } from 'app/pages/TeamDetail/Plan/Plan.styled';
import { PlanSection } from 'app/pages/TeamDetail/Plan/PlanSection/PlanSection';
import { QuarterReleaseNote } from 'app/types/entity';
import { FeatureStates, getWorkItemStateColour, UserStoryStates, WorkItemType } from 'app/types/PlannedWorkItem';
import {
  DrillDownColorFieldData,
  DrillDownPlotItemFormatterData,
  QuarterReleasePlanDrillDownPlot,
  QuarterReleasePlanTaskStatePrepending,
} from 'app/types/TeamDetail';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { EditableColumn, EditableTable } from '../EditableTable';
import './QuarterReleasePlan.scss';
import useIsMobile from 'app/utils/useIsMobile';

interface IProps {
  teamId: number;
  iterationName: string;
  className?: string;
}

export const QuarterReleasePlan = ({ ...props }: IProps) => {
  const yearFromIteration = (iteration: string) => {
    // iterations name takes the form '2021Q3-2'
    return iteration.substring(0, 4);
  };
  const monthFromIteration = (iteration: string) => {
    // iteration name takes the form '2021Q3-2'
    let quarterValue = iteration.substring(4, 6);
    switch (quarterValue) {
      case 'Q1':
        return '3';
      case 'Q2':
        return '6';
      case 'Q3':
        return '9';
      case 'Q4':
        return '12';
      default:
        return '3';
    }
  };

  const getQuarterDateFromIterationName = (name: string) => {
    const propsDateStr = `1-${monthFromIteration(name)}-${yearFromIteration(name)}`;
    const propsDate = moment(propsDateStr, `D-M-YYYY`, true);
    return propsDate;
  };
  const [selectedQuarterDate, setSelectedQuarterDate] = useState<moment.Moment>(
    getQuarterDateFromIterationName(props.iterationName)
  );
  const [chartData, setChartData] = useState<QuarterReleasePlanDrillDownPlot>();
  const [chartConfig, setChartConfig] = useState<TreemapConfig>();
  const [loadingData, setLoading] = useState<boolean>();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [tableData, setTableData] = useState<QuarterReleasePlanDrillDownPlot[]>();
  const [isMobile] = useIsMobile();
  const quarterOptionChange = async (date: moment.Moment, dateString: string) => {
    if (!date || !dateString) return;
    let iterationName = dateString.replace('-', ''); // 2022-Q2 >>> 2022Q2
    reloadQuarterReleasePlanData(getQuarterDateFromIterationName(iterationName));
    setSelectedQuarterDate(getQuarterDateFromIterationName(iterationName));
  };
  const [editingNoteItemCode, setEditingNoteItemCode] = useState<string>();
  const [oldEditingNoteContent, setOldEditingNoteContent] = useState<string>();
  const { team } = useContext(TeamDetailContext);

  const getFeatureProgress = (rec: QuarterReleasePlanDrillDownPlot) => {
    if (!rec.children || rec.children.length === 0) return 0;

    let acceptedUS = rec.children.filter(
      (us) =>
        us.itemType === WorkItemType.UserStory &&
        (us.taskState === UserStoryStates.Accepted + QuarterReleasePlanTaskStatePrepending.UserStory ||
          us.taskState === UserStoryStates.Released + QuarterReleasePlanTaskStatePrepending.UserStory)
    ).length;

    let totalUS = rec.children.filter((us) => us.itemType === WorkItemType.UserStory).length;

    return Math.round((100 * acceptedUS) / totalUS);
  };

  let getQuarterProgress = () => {
    if (!chartData || !chartData.children) return 0;

    let allFeatures = chartData.children.filter((f) => f.itemType === WorkItemType.Feature);
    let acceptedFeatures = allFeatures.filter(
      (f) =>
        f.taskState === FeatureStates.Accepted + QuarterReleasePlanTaskStatePrepending.Feature ||
        f.taskState === FeatureStates.Done + QuarterReleasePlanTaskStatePrepending.Feature
    );
    return Math.round((100 * acceptedFeatures.length) / allFeatures.length);
  };

  const getTaskStateTitle = (rec: QuarterReleasePlanDrillDownPlot) => {
    if (rec.itemType === WorkItemType.Feature)
      return rec.taskState.replace(QuarterReleasePlanTaskStatePrepending.Feature, '');
    if (rec.itemType === WorkItemType.UserStory)
      return rec.taskState.replace(QuarterReleasePlanTaskStatePrepending.UserStory, '');
  };

  const reloadQuarterReleasePlanData = useCallback(
    async (quarterDate: moment.Moment) => {
      if (!selectedQuarterDate || !quarterDate || loadingData) return;
      setLoading(true);
      const data: QuarterReleasePlanDrillDownPlot = await getQuarterReleasePlanData(
        props.teamId,
        quarterDate.year(),
        quarterDate.month()
      );
      for (let item of data.children) {
        if (item?.children?.length === 0) {
          item.children = null;
        } else {
          for (let subItem of item?.children) {
            if (subItem?.children?.length === 0) {
              subItem.children = null;
            }
          }
        }
      }
      setChartData(data);
      setTableData(data.children);
      setChartConfig({
        data: data,
        colorField: 'taskState',
        color: (taskState: DrillDownColorFieldData) => {
          return getWorkItemStateColour(taskState.taskState);
        },
        legend: {
          position: 'top-left',
        },
        label: {
          content: (item: QuarterReleasePlanDrillDownPlot) => {
            return `${item.itemCode}: ${item.itemTitle}`;
          },
          style: {
            fill: 'black',
          },
        },
        tooltip: {
          fields: ['name'],
          customContent: (title: string, data: DrillDownPlotItemFormatterData[]) => {
            if (!data || !data[0] || !data[0].data) return '';

            // This is not pretty, but it's how the data is provided by the antd customContent handler
            let itemType = data[0].data.itemType;
            let item = itemType === WorkItemType.UserStory ? data[0].data.data : data[0].data.data;

            if (!item) return '';

            let value = '';
            if (itemType === WorkItemType.UserStory) {
              value = `${item.value} Points`;
            } else if (itemType === WorkItemType.Feature && item.preliminaryEstimate) {
              value = `Preliminary Size: ${item.preliminaryEstimate}`;
            }

            let missingSizeDiv =
              value.length === 0
                ? `
            <div style="color: red; 
                        border: solid 1px red;
                        border-radius:2px; 
                        padding: 5px;
                        margin-bottom: 10px;
                        width:100%;
                        text-align:center;">
              Missing Preliminary Size
            </div>
            `
                : '';

            return `
            <div style="padding:1rem; max-width:400px">
              ${missingSizeDiv}
              <div style="font-weight: bold; font-size:large;margin-bottom:10px">${item.itemCode}</div>
              <div style="line-height:25px; font-size:medium;margin-bottom:10px">${item.name}</div>
              <div style="font-weight: bold; font-size:small;margin-bottom:4px">${value}</div>
            </div>`;
          },
        },
        drilldown: {
          enabled: true,
          breadCrumb: {
            rootText: 'Features',
            position: 'top-left',
          },
        },
      });
      setLoading(false);
    },
    [selectedQuarterDate, loadingData, props.teamId]
  );

  const columns = useMemo<EditableColumn<QuarterReleasePlanDrillDownPlot>[]>(
    () => [
      {
        title: '#',
        width: '60px',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
        fixed: !isMobile ? 'left' : false,
      },
      {
        title: 'ID',
        dataIndex: 'itemCode',
        key: 'itemCode',
        editable: false,
        width: '120px',
        render: (val, rec) => (
          <svg style={{ display: 'block' }} width={100} height={20}>
            <circle cx="5" cy="10" r="5" strokeWidth={3} fill={getWorkItemStateColour(rec.taskState)} />
            <text x={15} y={16}>
              {rec.itemCode}
            </text>
          </svg>
        ),
        fixed: !isMobile ? 'left' : false,
      },
      {
        title: 'Name',
        dataIndex: 'itemTitle',
        width: '300px',
      },
      {
        title: 'Preliminary Size',
        dataIndex: 'preliminaryEstimate',
        key: 'preliminaryEstimate',
        editable: false,
        width: 140,
        align: 'center',
      },
      {
        title: 'State',
        dataIndex: 'taskState',
        key: 'preliminaryEstimate',
        editable: false,
        width: 120,
        render: (val, rec) => getTaskStateTitle(rec),
      },
      {
        title: 'Release',
        dataIndex: 'quarterName',
        key: 'quarterName',
        editable: false,
        align: 'center',
        width: '90px',
      },
      {
        title: 'Planned End Date',
        width: '150px',
        dataIndex: 'plannedEndDate',
        align: 'center',
        editable: false,
        render: (text, rec) => rec.plannedEndDate && moment(rec.plannedEndDate).format('YYYY-MM-DD'),
      },
      {
        title: 'Current Progress',
        dataIndex: 'currentProgress',
        editable: false,
        render: (text, rec) => {
          let value = getFeatureProgress(rec);
          return (
            rec.itemType === WorkItemType.Feature && (
              <div className="feature-progress">
                <Progress
                  className="bar"
                  strokeColor={{
                    from: PROGRESS_GRADIENT_START__COLOR,
                    to: PROGRESS_ON_TARGET__COLOR,
                  }}
                  percent={value > 100 ? 100 : value}
                  showInfo={false}
                  strokeLinecap="square"
                />
                <div className="feature-progress-percent">
                  {value === Infinity ? 'N/A' : value}
                  {value !== Infinity && '%'}
                </div>
              </div>
            )
          );
        },
        width: 150,
      },
      {
        title: 'Notes',
        dataIndex: 'quarterReleaseNote',
        key: 'quarterReleaseNoteID',
        editable: true,
        width: '400px',
        inputType: 'custom',
        editingContent: (record) => (
          <TextArea
            autoSize
            placeholder=""
            value={record.quarterReleaseNote}
            onChange={(t) => {
              record.quarterReleaseNote = t.target.value;
            }}
          />
        ),
      },
    ],
    [page, pageSize]
  );
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const updateTableDataQuarterReleaseNote = (note: QuarterReleaseNote) => {
    let updatingRecIndex = tableData.findIndex((item) => item.itemCode === note.featureCode);
    if (updatingRecIndex > -1) {
      let updatingRec = tableData[updatingRecIndex];
      let newTableData = cloneDeep(tableData);
      updatingRec.quarterReleaseNote = note.content;
      updatingRec.quarterReleaseNoteID = note.id;
      newTableData[updatingRecIndex] = updatingRec;
      setTableData(newTableData);
    }
  };

  const onCancelUpdateNote = () => {
    let note = tableData.find((x) => x.itemCode === editingNoteItemCode);
    if (note) note.quarterReleaseNote = oldEditingNoteContent;
  };

  const onEditRecord = (record: QuarterReleasePlanDrillDownPlot) => {
    setOldEditingNoteContent(record.quarterReleaseNote);
    setEditingNoteItemCode(record.itemCode);
  };

  const onUpdateNote = async (id: number, record: any) => {
    try {
      let rec = record as QuarterReleasePlanDrillDownPlot;
      if (!rec) return;

      let request: QuarterReleaseNote = {
        id: rec.quarterReleaseNoteID,
        teamId: team.id,
        content: rec.quarterReleaseNote,
        featureCode: rec.itemCode,
        quarterName: rec.quarterName,
      };
      let response: QuarterReleaseNote =
        !request.id || request.id === 0
          ? await createQuarterReleaseNote(request)
          : await updateQuarterReleaseNote(request);

      updateTableDataQuarterReleaseNote(response);
    } catch (error) {
      console.error(error);
      notification.error({
        message: error.data.error.message,
        duration: 3,
      });
    }
  };

  useEffect(() => {
    if (firstLoad) {
      reloadQuarterReleasePlanData(selectedQuarterDate);
      setFirstLoad(false);
    }
  }, [firstLoad, selectedQuarterDate, setFirstLoad, reloadQuarterReleasePlanData]);

  let quarterProgress = getQuarterProgress();
  return (
    <PlanSection
      id="quarter-release-plan"
      key="quarter-release-plan"
      title="QUARTER RELEASE PLAN"
      withBottomSpace
      classProp={props.className}
    >
      <Space wrap style={{ marginBottom: '1rem' }}>
        <DatePicker
          allowClear={false}
          onChange={quarterOptionChange}
          picker="quarter"
          value={selectedQuarterDate}
          style={{ width: '166px', marginRight: '2rem' }}
        />

        {quarterProgress > 0 && (
          <div>
            <div className="quarter-progress-label">Progress</div>
            <div className="quarter-progress">
              <Progress
                className="bar"
                strokeColor={{
                  from: PROGRESS_GRADIENT_START__COLOR,
                  to: PROGRESS_ON_TARGET__COLOR,
                }}
                percent={quarterProgress > 100 ? 100 : quarterProgress}
                showInfo={false}
                strokeLinecap="square"
              />
              <div className="quarter-progress-percent">
                {quarterProgress === Infinity ? 'N/A' : quarterProgress}
                {quarterProgress !== Infinity && '%'}
              </div>
            </div>
          </div>
        )}
      </Space>

      <Row id="quarter-release-plan-chart" style={{ marginBottom: '1.5rem' }}>
        {loadingData ? (
          <Spin />
        ) : !chartData || !chartConfig || !chartConfig.data || chartData.children?.length === 0 ? (
          <StyledPrePlanWrapper>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            <Title level={5} className="head-text">
              No data available for this quarter
            </Title>
          </StyledPrePlanWrapper>
        ) : (
          <Col span={24}>
            <Treemap {...chartConfig} />
          </Col>
        )}
      </Row>
      <Row id="quarter-release-plan-table" style={{ marginBottom: '1.5rem' }}>
        <Col span={24}>
          {tableData && (
            <EditableTable
              columns={columns as any}
              data={tableData as any}
              onChange={onChange}
              onCancel={onCancelUpdateNote}
              onSave={onUpdateNote}
              onEditRecord={onEditRecord}
              showAction={true}
              isEdit={true}
              loading={loadingData}
              scroll={{
                x: 'max-content',
              }}
            />
          )}
        </Col>
      </Row>
    </PlanSection>
  );
};

import { useContext, useEffect, useMemo, useState } from 'react';
import { Card } from 'app/components/Card';
import { Avatar, Row, Select, Table, TableColumnProps, Typography } from 'antd';
import { StyledAvatarInfo, StyledBestPerformer } from './BestPerformer.styled';

import { TopPerformerRank } from 'app/components/TopPerformers/TopPerformers';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { useFetch } from 'app/hooks/useFetch';
import { fetchTopPerformersOverview } from 'app/apis/topPerformersClient';
import { getIterationsListNotFuture } from 'app/apis/iterationOverview';

interface ITopPerformer {
  id: number;
  fullName: string;
  memberId: number;
  ranksCount: {
    first: number;
    second: number;
    third: number;
  };
  avatarUrl: string;
}

type Option = {
  label: string;
  value: string;
};

export const BestPerformer = () => {
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>('default');

  const pageSize = 5;

  const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

  const { data: iterationsList } = useFetch<string[]>(() => getIterationsListNotFuture(), []);

  const generatedColor = (text: string) => {
    const value = text.toLocaleLowerCase().charCodeAt(0) - 96;
    if (value > 0 && value <= 6.5) return ColorList[0];
    else if (value <= 13) return ColorList[1];
    else if (value <= 19.5) return ColorList[2];
    else return ColorList[3];
  };

  const comparedTotal = (a: ITopPerformer, b: ITopPerformer) => {
    return (
      b.ranksCount.first * 3 +
      b.ranksCount.second * 2 +
      b.ranksCount.third -
      (a.ranksCount.first * 3 + a.ranksCount.second * 2 + a.ranksCount.third)
    );
  };

  const { team } = useContext(TeamDetailContext);

  useEffect(() => {
    if (iterationsList && iterationsList.length > 0 && options.length < 1) {
      let opts = [
        {
          value: 'default',
          label: 'YTD',
        },
      ];
      iterationsList.slice(0, 3).forEach((i) => {
        opts.push({
          value: i,
          label: i,
        });
      });
      setOptions(opts);
    }
  }, [iterationsList, options]);

  const { data: topPerformersData } = useFetch(
    () => fetchTopPerformersOverview(team?.id, selectedYear),
    [team, selectedYear]
  );

  var result: ITopPerformer[] = [];
  topPerformersData?.forEach((item) => {
    const existedIndex = result.findIndex((x) => x.memberId === item.memberId);
    if (existedIndex > -1) {
      let countfirst = result[existedIndex].ranksCount.first;
      let countSecond = result[existedIndex].ranksCount.second;
      let countThird = result[existedIndex].ranksCount.third;
      switch (item.rank) {
        case TopPerformerRank.First:
          countfirst++;

          break;
        case TopPerformerRank.Second:
          countSecond++;

          break;
        case TopPerformerRank.Third:
          countThird++;

          break;

        default:
          break;
      }
      result[existedIndex] = {
        ...result[existedIndex],
        ranksCount: {
          first: countfirst,
          second: countSecond,
          third: countThird,
        },
      };
    } else {
      result.push({
        avatarUrl: item.avatarUrl,
        ranksCount: {
          first: item.rank === TopPerformerRank.First ? 1 : 0,
          second: item.rank === TopPerformerRank.Second ? 1 : 0,
          third: item.rank === TopPerformerRank.Third ? 1 : 0,
        },
        fullName: item.fullName,
        id: item.id,
        memberId: item.memberId,
      });
    }
  });

  const columns = useMemo<TableColumnProps<ITopPerformer>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value, record, index) => (
          <StyledAvatarInfo>
            <Avatar
              size="large"
              gap={4}
              src={record.avatarUrl}
              shape="square"
              style={{ backgroundColor: generatedColor(record.fullName) }}
            >
              {record.fullName?.charAt(0).toLocaleUpperCase()}
            </Avatar>
            <span>{record.fullName}</span>
          </StyledAvatarInfo>
        ),
      },
      {
        title: '# 1st',
        dataIndex: ['ranksCount', 'first'],
        showSorterTooltip: true,
        width: '10%',
        align: 'right',
        sorter: {
          compare: (a, b) => a.ranksCount.first - b.ranksCount.first,
        },
      },
      {
        title: '# 2nd',
        dataIndex: ['ranksCount', 'second'],
        width: '10%',
        key: 'parentId',
        align: 'right',
        sorter: {
          compare: (a, b) => a.ranksCount.second - b.ranksCount.second,
        },
      },
      {
        title: '# 3rd',
        dataIndex: ['ranksCount', 'third'],
        width: '10%',
        align: 'right',
        sorter: {
          compare: (a, b) => a.ranksCount.third - b.ranksCount.third,
        },
      },
      {
        title: 'Score',
        width: '10%',
        render: (value, record, index) => (
          <b>{record.ranksCount.first * 3 + record.ranksCount.second * 2 + record.ranksCount.third}</b>
        ),
        align: 'right',
        sorter: {
          compare: (a, b) => comparedTotal(a, b),
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize]
  );
  return (
    <StyledBestPerformer>
      <Card title="Top Performers" clasTitleName="performer" className="best-performer-card">
        <Row justify="end" className="filter-dropdown">
          <Select value={selectedYear} options={options} onChange={(value) => setSelectedYear(value)} />
        </Row>
        <Table
          scroll={{ x: 'max-content' }}
          bordered
          dataSource={result.sort((a, b) => comparedTotal(a, b))}
          columns={columns}
          pagination={{
            pageSize,
            current: page,
            hideOnSinglePage: true,
            onChange: (page) => setPage(page),
          }}
        />
      </Card>
    </StyledBestPerformer>
  );
};

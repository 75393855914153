import { createContext, useContext, useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { fetchIterations } from 'app/apis/teamDetailClient';
import { Stage } from 'app/types/TeamDetailTypes';
import { DemoPlanColumn, Iteration } from 'app/types/Iteration';
import { IIterationContext } from 'app/types/Iteration';
import { TeamDetailContext } from './TeamDetailContext';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { TestCaseResponse } from 'app/types/TestCaseResponse';
import useForceUpdate from 'app/hooks/useForceUpdate';
import { AttendanceDataItem } from 'app/types/Attendance';
import { useHistory } from 'react-router';

export const IterationContext = createContext<IIterationContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const IterationContextProvider = ({ children }: IProps) => {
  const { team } = useContext(TeamDetailContext);

  const [forceUpdateState, dispatchforceUpdate] = useForceUpdate();
  const { data: iterations } = useFetch(() => fetchIterations(team?.id), [team?.id, forceUpdateState]);
  const [selectedIteration, setSelectedIteration] = useState<Iteration>();
  const [teamIterations, setTeamIterations] = useState<Iteration[]>();
  const [stage, setStage] = useState<Stage>();
  const [demoPlanData, setDemoPlanData] = useState<DemoPlanColumn[]>();
  const [reportStatus, setReportStatus] = useState(IterationReportStatusEnum.None);
  const [testCaseResults, setTestCaseResults] = useState<TestCaseResponse[]>();
  const [attendanceData, setAttendanceData] = useState<AttendanceDataItem[]>();

  const history = useHistory();
  const checkIteration = (value: string) => {
    if (isNaN(Number.parseInt(value))) {
      return false;
    } else {
      return iterations?.some(function (el) {
        return el.id === Number.parseInt(value);
      });
    }
  };

  useEffect(() => {
    if (iterations === undefined) return;
    const urlHash = new URLSearchParams(history.location.search);
    const currentIteration = urlHash.get('iteration');
    setTeamIterations(iterations);

    if (selectedIteration === undefined || checkIteration(currentIteration) === false) {
      const currentDate = new Date();
      var currentSprint = iterations?.find(
        (x) => new Date(x.dateFrom) <= currentDate && new Date(x.dateTo) >= currentDate
      );
      if (currentSprint === undefined) {
        currentSprint = iterations?.at(0);
      }
      setSelectedIteration(currentSprint);
    } else {
      setSelectedIteration(iterations?.find((x) => x.id.toString() === currentIteration));
    }
  }, [history.location.search, iterations, selectedIteration]);

  return (
    <IterationContext.Provider
      value={{
        teamId: team?.id,
        stage,
        setStage,
        teamIterations,
        selectedIteration,
        setSelectedIteration,
        reportStatus: reportStatus,
        setReportStatus: setReportStatus,
        demoPlanData,
        setDemoPlanData,
        testCaseResults: testCaseResults,
        setTestCaseResults: setTestCaseResults,
        forceRefetchIterationData: dispatchforceUpdate,
        attendanceData,
        setAttendanceData,
      }}
    >
      {children}
    </IterationContext.Provider>
  );
};

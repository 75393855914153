import styled from 'styled-components';
import { Modal } from 'antd';
export const StyledModal = styled(Modal)`
  .ant-modal-header {
    text-align: center;

    & .ant-modal-title {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .ant-modal-body {
    padding: 18px 88px;
    .ant-form-item-label {
      font-weight: bold;
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: none;
      content: '';
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & .ant-btn-primary {
      background: #0066ff;
    }
    & .ant-btn-primary.ant-btn-dangerous {
      background: #ff0000;
    }
  }
`;

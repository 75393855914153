import { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { IterationNoteWrapper } from './IterationNote.styled';
import { isEmpty, isNull } from 'lodash';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { Stage } from 'app/types/TeamDetailTypes';
import { IIterationNote } from 'app/types/IterationNote';
import { useRef } from 'react';
import { Iteration } from 'app/types/Iteration';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { canEditReport } from '../Report/Report';
import { UserContext } from 'app/contexts/UserContext';

interface IProps {
  data: IIterationNote;
  onSave: (note: IIterationNote) => Promise<void>;
  noMargin?: boolean;
  enableEdit?: boolean;
}

export const IterationNote = ({ data, noMargin, onSave, enableEdit = true }: IProps) => {
  const { stage: currentStage, selectedIteration, reportStatus } = useContext(IterationContext);
  const { isAuthorized } = useAuthz();
  const allowEdit =
    isAuthorized &&
    (selectedIteration?.iterationReport?.status === IterationReportStatusEnum.InProgress ||
      selectedIteration?.iterationPlan?.status === 1); /* InProgress */
  const {
    user: { permissions },
  } = useContext(UserContext);
  const stageWillAllowEdit = (stage: Stage, reportState: IterationReportStatusEnum) => {
    return (
      ((stage !== undefined && stage !== Stage.Planned) || stage === undefined) &&
      canEditReport(reportState, permissions)
    );
  };
  const [editable, setEditable] = useState<boolean>(
    allowEdit && enableEdit && stageWillAllowEdit(currentStage, reportStatus)
  );
  const [isEdit, setIsEdit] = useState(false);
  const [note, setNote] = useState<IIterationNote>(data);
  const noteRef = useRef(null);
  useEffect(() => {
    setNote(data);
  }, [data]);

  useEffect(() => {
    setEditable(allowEdit && enableEdit && stageWillAllowEdit(currentStage, reportStatus));
  }, [allowEdit, currentStage, enableEdit, reportStatus]);

  return (
    <IterationNoteWrapper className={!noMargin ? 'm-t' : ''}>
      <Row>
        <Col span={24}>
          <span>
            <strong>Note</strong>
          </span>
          {editable &&
            (isEdit ? (
              <span data-html2canvas-ignore>
                <Button
                  type="link"
                  onClick={async () => {
                    const content = noteRef.current.resizableTextArea.textArea.value.trim();
                    await onSave({ ...data, content });
                    setIsEdit(false);
                  }}
                >
                  Save
                </Button>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => {
                    // Revert back to old note

                    setIsEdit(false);
                  }}
                >
                  <Button type="link">Cancel</Button>
                </Popconfirm>
              </span>
            ) : (
              <Button
                data-html2canvas-ignore
                type="link"
                onClick={() => {
                  // Set this for revert back when click 'Cancel'
                  setIsEdit(true);
                }}
              >
                Edit
              </Button>
            ))}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {isEdit ? (
            <TextArea
              defaultValue={note?.content}
              rows={4}
              showCount
              autoSize={{
                minRows: 4,
                maxRows: 10,
              }}
              ref={noteRef}
            />
          ) : (
            <p className="notes">{!isEmpty(note?.content) ? note.content : <i>No note</i>}</p>
          )}
        </Col>
      </Row>
    </IterationNoteWrapper>
  );
};

import {
  OwnershipMonthlyHighlight,
  OwnershipStatusResponse,
  OwnershipUpdateStatusDetailRequest,
} from 'app/types/Ownership';
import { OwnershipProgramData, OwnershipProgramDate } from 'app/types/OwnershipProgramData';
import { OwnershipReportData } from 'app/types/OwnershipReportData';
import { fetchAsync } from 'app/utils/fetch';

export const getOwnershipStatusOverview = (
  teamId: number,
  year: number,
  month: number
): Promise<OwnershipStatusResponse> => fetchAsync(`/api/ownership/${teamId}/${year}/${month}`);

export const updateMonthlyHighlight = (data: OwnershipMonthlyHighlight): Promise<any> => {
  return fetchAsync(`/api/ownership/monthlyhighlight`, {
    method: 'POST',
    body: data,
  });
};

export const updateOwnershipStatusDetail = (data: OwnershipUpdateStatusDetailRequest): Promise<any> => {
  return fetchAsync(`/api/ownership/statusdetail`, {
    method: 'POST',
    body: data,
  });
};

export const fetchOwnershipStatusDateOptions = (teamId: number): Promise<OwnershipProgramDate[]> =>
  fetchAsync(`/api/ownership/ownership-status-dates/${teamId}`);

export const fetchOwnershipProgramData = (viewDate: string): Promise<OwnershipProgramData> =>
  fetchAsync(`api/ownership/program/monthdata/${viewDate}`);

export const fetchOwnershipReport = (viewDate: Date): Promise<OwnershipReportData[]> =>
  fetchAsync(`/api/ownership/ownership-report/${viewDate.toDateString()}`);

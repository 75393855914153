import { ProgramOverviewTeamData } from 'app/types/ProgramOverviewModel';
import { StyledProgramKanbanOverview, CardItem } from './ProgramKanbanOverview.style';
import { getOjectTeamData } from 'app/apis/statisticOrganization';
import { OjectTeamDataResponse } from 'app/types/Organizational';
import { SearchTree } from 'app/components/OrganizationSearchTree/OrganizationSearchTree';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { fetchProgramOverviewKanBanDataByIteration } from 'app/apis/programOverviewClient';
import { useState, useEffect, Key, useCallback } from 'react';
import { useFetch } from 'app/hooks/useFetch';
import { DataNode } from 'antd/lib/tree';
import { TeamType } from 'app/types/team';
import { Row, Col, Spin, Checkbox, Card, InputNumber, Switch, Empty, List, Button } from 'antd';
import { ProgramKanbanOverviewPageSize } from '../../common/constants';
import { filterFPTInvolved, filterNodeFPTInvolved } from 'app/utils/organizationSearchTreeUtils';
import { Link } from 'react-router-dom';

interface IProps {
  selectedIterationName: string;
}

export const ProgramKanbanOverview = (props: IProps) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [programOverViewKanbanData, setProgramOverviewKanbanData] = useState<ProgramOverviewTeamData[]>();
  const [dataTree, setdataTree] = useState<DataNode[]>();
  const [carriedOver, setCarriedOver] = useState(false);
  const [failGoal, setFailGoal] = useState(false);
  const [utilizedCapacity, setUtilizedCapacity] = useState<number>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [selectIds, setSelectedIds] = useState<Key[]>([]);
  const [isFPTInvolved, setIsFPTInvolved] = useState(true);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const { data: dataTeam } = useFetch(() => {
    return getOjectTeamData();
  }, []);

  useEffect(() => {
    setInitLoading(true);
    setProgramOverviewKanbanData([]);
    fetchKanbanData(selectIds, carriedOver, failGoal, utilizedCapacity, 1, false);
    setPageNumber(1);
  }, [props.selectedIterationName]);

  useEffect(() => {
    if (dataTeam) {
      let initFilteredData = filterFPTInvolved(dataTeam);
      setdataTree(filterNodeFPTInvolved(initFilteredData, isFPTInvolved));
      setIsFPTInvolved(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTeam]);

  const onLoadMore = async (e) => {
    setLoading(true);
    fetchKanbanData(selectIds, carriedOver, failGoal, utilizedCapacity, pageNumber + 1, true);
    setPageNumber(pageNumber + 1);
  };
  const loadMore =
    !initLoading && loadMoreFlag ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore} loading={loading}>
          Load More
        </Button>
      </div>
    ) : null;
  const serialize = (
    teamIds: Key[],
    hasCarriedOver: boolean,
    hasFailedGoal: boolean,
    hasUtilizedCapacity: number,
    pageNumber: number,
    pageSize: number
  ) => {
    let params = new URLSearchParams();
    for (var id in teamIds) {
      params.append('teamIds', teamIds[id].toString());
    }
    params.append('hasCarriedOver', hasCarriedOver?.toString());
    params.append('hasFailedGoal', hasFailedGoal?.toString());
    if (hasUtilizedCapacity) {
      params.append('hasUtilizedCapacity', hasUtilizedCapacity?.toString());
    }
    params.append('pageNumber', pageNumber?.toString());
    params.append('pageSize', pageSize?.toString());
    return params.toString();
  };

  const onCarriedOverChange = (e: CheckboxChangeEvent) => {
    setCarriedOver(e.target.checked);
    fetchKanbanData(selectIds, e.target.checked, failGoal, utilizedCapacity, 1, false);
    setPageNumber(1);
  };

  const onFailGoalChange = (e: CheckboxChangeEvent) => {
    setFailGoal(e.target.checked);
    fetchKanbanData(selectIds, carriedOver, e.target.checked, utilizedCapacity, 1, false);
    setPageNumber(1);
  };

  const onUtilizedCapacityChange = (value: number) => {
    setUtilizedCapacity(value);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (props.selectedIterationName !== '') {
        fetchKanbanData(selectIds, carriedOver, failGoal, utilizedCapacity, 1, false);
        setPageNumber(1);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [utilizedCapacity]);

  const transferObject = useCallback(
    (data: OjectTeamDataResponse[], level: number) => {
      if (level < 4) {
        let newObject: DataNode[] = data?.map((data) => ({
          title: data.name,
          key: data.id,
          children: transferObject(data.childrenItems, data.type),
          disableCheckbox: isFPTInvolved && data.type === TeamType.Team && !data.isFPTInvolvded,
        }));
        return newObject;
      }
    },
    [isFPTInvolved]
  );

  const onChangeSwitch = (checked: boolean) => {
    if (checked) {
      let filteredData = filterFPTInvolved(dataTeam);
      setdataTree(filterNodeFPTInvolved(filteredData, isFPTInvolved));
    } else {
      setdataTree(filterNodeFPTInvolved(dataTeam, isFPTInvolved));
    }
    setIsFPTInvolved(checked);
  };

  // On click OK to filter
  const onFilterByChecked = async (checkedNodes: Key[]) => {
    setSelectedIds(checkedNodes);
    fetchKanbanData(checkedNodes, carriedOver, failGoal, utilizedCapacity, 1, false);
    setPageNumber(1);
  };

  const fetchKanbanData = async (
    teamIds: Key[],
    hasCarriedOver: boolean,
    hasFailedGoal: boolean,
    hasUtilizedCapacity: number,
    pageNumber: number,
    isLoadMore: boolean
  ) => {
    // if (isLoadMore) setLoadingMore(true);
    // else setLoading(true);
    let data = await fetchProgramOverviewKanBanDataByIteration(
      props.selectedIterationName,
      serialize(teamIds, hasCarriedOver, hasFailedGoal, hasUtilizedCapacity, pageNumber, ProgramKanbanOverviewPageSize)
    );
    if (data) {
      if (isLoadMore) {
        setProgramOverviewKanbanData([...programOverViewKanbanData, ...data.data]);
      } else {
        setProgramOverviewKanbanData(data.data);
      }
      if (pageNumber < data.totalPage) {
        setLoadMoreFlag(true);
      } else {
        setLoadMoreFlag(false);
      }
    }
    setInitLoading(false);
    setLoading(false);
    // if (isLoadMore) setLoadingMore(false);
    // else setLoading(false);
  };

  const [isHovering, setIsHovering] = useState(-1);

  const handleMouseOver = (id: number) => {
    setIsHovering(id);
  };

  const handleMouseOut = () => {
    setIsHovering(-1);
  };

  return (
    <StyledProgramKanbanOverview>
      <div className="kanban-search responsive-search">
        {dataTree && (
          <SearchTree dataSource={dataTree} onFilterByChecked={onFilterByChecked} isFPTInvolded={isFPTInvolved} />
        )}
        <div className="kanban-filter">
          <div>
            <Switch defaultChecked onChange={onChangeSwitch} />
            <span className="text">FPT Involved</span>
          </div>
          <Checkbox checked={carriedOver} onChange={onCarriedOverChange}>
            Has carried-over
          </Checkbox>
          <Checkbox checked={failGoal} onChange={onFailGoalChange}>
            Has failed goal
          </Checkbox>
          <div className="utilized-filter">
            <span className="text">Has utilized capacity &#60;= </span>
            <InputNumber min={1} defaultValue={utilizedCapacity} onChange={onUtilizedCapacityChange} />
            <div className="add-on-affter-input-number">%</div>
          </div>
        </div>
      </div>
      {initLoading ? (
        <Spin />
      ) : (
        <>
          {programOverViewKanbanData?.length === 0 ? (
            <Empty />
          ) : (
            <div>
              {/* <Row gutter={[20, 20]}>
                {programOverViewKanbanData?.map((item, index) => (
                  <Col key={index} xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}> */}

              <List
                grid={{
                  gutter: 20,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 4,
                  xxl: 4,
                }}
                loading={initLoading}
                loadMore={loadMore}
                dataSource={programOverViewKanbanData}
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      size="small"
                      title={
                        <Link
                          style={{ color: 'black' }}
                          to={`teams/${item.teamId}`}
                          target="_blank"
                          onMouseOver={() => handleMouseOver(item.teamId)}
                          onMouseOut={handleMouseOut}
                        >
                          {isHovering === item.teamId ? <>Open in new tab</> : <>{item.name}</>}
                        </Link>
                      }
                      style={{ minWidth: 260 }}
                    >
                      <CardItem>
                        <span className="custom-title">Carried-over (S | D | TS)</span>
                        <span>
                          {item.carriedOverStory} | {item.carriedOverDefect} | {item.CarriedOverTestSuite}
                        </span>
                      </CardItem>
                      <CardItem>
                        <span className="custom-title">Iteration Goals</span>
                        <div>
                          {item.iterationGoalPass === 0 && item.iterationGoalFail === 0 && (
                            <span>{item.iterationGoalNone}</span>
                          )}
                          {item.iterationGoalPass > 0 && (
                            <span className="custom-item-pass">{item.iterationGoalPass}</span>
                          )}
                          {item.iterationGoalFail > 0 && (
                            <span className="custom-item-fail">{item.iterationGoalFail}</span>
                          )}
                        </div>
                      </CardItem>
                      <CardItem>
                        <span className="custom-title">Commited vs Delivered</span>
                        <span>
                          {item.commited} | {item.delivered}
                        </span>
                      </CardItem>
                      <CardItem>
                        <span className="custom-title">Team Delivery</span>
                        <span>{Math.round(item.teamDelivery)}%</span>
                      </CardItem>
                      <CardItem>
                        <span className="custom-title">Velocity</span>
                        <span>{item.velocity}</span>
                      </CardItem>
                      <CardItem>
                        <span className="custom-title">Utilized Capacity</span>
                        <span>{Math.round(item.utiliezedCapacity)}%</span>
                      </CardItem>
                      <CardItem>
                        <span className="custom-title">Demo Items</span>
                        <span>{item.demoItems}</span>
                      </CardItem>
                      <CardItem>
                        <span className="custom-title">Best Performer</span>
                        <span>{item.bestPerformer}</span>
                      </CardItem>
                    </Card>
                  </List.Item>
                )}
              />
              {/* </Col>
                ))}
              </Row> */}
              {loadingMore && (
                <div className="spin">
                  <Spin />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </StyledProgramKanbanOverview>
  );
};

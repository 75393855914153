import {
  OwnershipHeatmapHighlight,
  OwnershipHeatmapItem,
  OwnershipHeatmapModalDetail,
  OwnershipStatusItemResponse,
} from './../types/OwnershipHeatmap';
import { fetchAsync } from 'app/utils/fetch';
import { HighlightType } from 'app/types/Ownership';

export const fetchHeatmapList = (date: Date): Promise<OwnershipStatusItemResponse[]> =>
  fetchAsync(`/api/ownershipheatmap/${date.toDateString()}`);

export const fetchHeatmapChart = (date: Date): Promise<OwnershipHeatmapItem[]> =>
  fetchAsync(`/api/ownershipheatmap/chart/${date.toDateString()}`);

export const fetchHeatmapDetail = (date: Date, teamId: number): Promise<OwnershipHeatmapModalDetail> =>
  fetchAsync(`/api/ownershipheatmap/heatmapDetail/${date.toDateString()}/${teamId}`);

export const updateHeatmapDetail = (
  heatmapId: number,
  heatmapRequest: OwnershipHeatmapModalDetail
): Promise<OwnershipHeatmapModalDetail> =>
  fetchAsync(`/api/ownershipheatmap/${heatmapId}`, {
    method: 'PUT',
    body: heatmapRequest,
  });

export const fetchHighlightHeatmap = (date: string, type: HighlightType): Promise<OwnershipHeatmapHighlight> =>
  fetchAsync(`/api/ownershipheatmap/highlight/${type}/${date}`);

export const updateHighlightOwnershipByType = (
  request: OwnershipHeatmapHighlight,
  type: HighlightType
): Promise<OwnershipHeatmapHighlight> =>
  fetchAsync(`/api/ownershipheatmap/highlight/${type}`, { method: 'PUT', body: request });

import { Result, Button, Image } from 'antd';
import { Link } from 'react-router-dom';
import { GettingStartedFormEnum } from './GettingStartedForm';
interface IProps {
  form: GettingStartedFormEnum;
}

export const GettingStartedResult = ({ form }: IProps) => {
  const ResultForm = () => {
    if (form === GettingStartedFormEnum.Submitted) {
      return (
        <Result
          status="success"
          title="Sent successfully!"
          subTitle="System admin has been notified of your request to access this app. Once the admin reviews the request you will be notified via email."
          extra={[
            <Link to="login" key="console">
              <Button type="primary">Back to login</Button>
            </Link>,
          ]}
        />
      );
    } else if (form === GettingStartedFormEnum.WaitingApproval) {
      return (
        <Result
          status="warning"
          title="Waiting for approval!"
          icon={<Image src="/assets/img/hourglass.svg" height={'80px'} />}
          subTitle={
            <>
              System admin is reviewing your request! <br />
              Have you been waiting too long for the approval? Please contact
              <Link to="#"> MinhTD17@fsoft.com.vn</Link>
            </>
          }
          extra={[
            <Link to="login" key="console">
              <Button type="primary" className="submitBtn">
                Back to login
              </Button>
            </Link>,
          ]}
        />
      );
    } else if (form === GettingStartedFormEnum.Rejected) {
      return (
        <Result
          status="error"
          title="Sorry!"
          icon={<Image src="/assets/img/sadface.svg" height={'80px'} />}
          subTitle={
            <>
              We regret to inform you that your request has been rejected by System admin!
              <br />
              If you have any question, please contact
              <Link to="#"> MinhTD17@fsoft.com.vn</Link>
            </>
          }
          extra={[
            <Link to="login" key="console">
              <Button type="primary" className="submitBtn">
                Back to login
              </Button>
            </Link>,
          ]}
        />
      );
    }
  };

  return <ResultForm />;
};

import { Bar, BarConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import {
  Architecture,
  CantReproduce,
  CodeChange,
  ConfigurationChange,
  DatabaseChange,
  NeedMoreInformation,
  None,
  NotADefect,
  SoftwareLimitation,
  UserInterface,
  WontFix,
} from 'app/common/constants';
import { IDataChartProps } from 'app/types/CacheReportModel';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export const StyledEmpty = styled.div`
  .ant-empty {
    height: 300px;
    opacity: 0.5;
  }
  .ant-empty-image {
    height: 200px;
    img {
      padding-top: 50px;
    }
  }
`;

export const ResolutionByDefect = ({ teamId, iteration, dataSource }: IDataChartProps) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dataSource) {
      setData(dataSource.filter((x) => x.value !== 0));
    }
  }, [dataSource]);
  let maxValue = 0;
  if (data && data.length > 0) {
    maxValue = Math.max(
      ...data
        .filter((x) => x.value)
        .map((object) => {
          return object.value;
        })
    );
    maxValue = Math.ceil(maxValue * 1.1);
  }

  const config: BarConfig = {
    data,
    xField: 'value',
    yField: 'resolution',
    width: 500,
    height: 300,
    label: {
      position: 'right',
    },
    seriesField: 'resolution',
    legend: {
      position: 'top',
      marker: {
        symbol: 'circle',
      },
    },
    xAxis: {
      max: maxValue,
      tickInterval: 1,
    },
    maxBarWidth: 40,
    color: ({ resolution }) => {
      switch (resolution) {
        case 'None':
          return None;
        case "Can't Reproduce":
          return CantReproduce;
        case 'Architecture':
          return Architecture;
        case 'Code Change':
          return CodeChange;
        case 'Configuration Change':
          return ConfigurationChange;
        case 'Database Change':
          return DatabaseChange;
        case 'Need More Information':
          return NeedMoreInformation;
        case 'Not A Defect':
          return NotADefect;
        case 'Software Limitation':
          return SoftwareLimitation;
        case 'User Interface':
          return UserInterface;
        case "Won't Fix":
          return WontFix;
        default:
          break;
      }
    },
  };

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  if (data.length === 0)
    return (
      <StyledEmpty>
        <Empty image="/assets/img/NoBugFound.png" description={<>No Defect Found!</>} />
      </StyledEmpty>
    );

  return <Bar {...config} />;
};

import { Row, Col } from 'antd';
import { getOwnershipLevelColor, getOwnershipLevelNumber, OwnershipLevel } from 'app/types/Ownership';
import { Link } from 'react-router-dom';
import { TeamStatusComponent } from './TeamStatusCard.styled';

interface Props {
  id: number;
  name: string;
  level: OwnershipLevel;
}

export const TeamStatusCard = ({ name, level, id }: Props) => {
  return (
    <TeamStatusComponent color={getOwnershipLevelColor(level)}>
      <div className="card">
        <Link to={'/ownership/' + id}>
          <Row justify="space-between" align="middle">
            <Col span={level !== null ? 16 : 24}>
              <p className="name">{name}</p>
            </Col>
            {level !== null && (
              <Col>
                <div className="tags-list">
                  <b>{getOwnershipLevelNumber(level)}</b>
                </div>
              </Col>
            )}
          </Row>
        </Link>
      </div>
      <div className="color-tail" />
    </TeamStatusComponent>
  );
};

import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, notification, Popconfirm, Select, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import { createTopPerformer, updateTopPerformer, deleteTopPerformer } from 'app/apis/topPerformersClient';
import { TopPerformerRequest, TopPerformerResponse, TopPerformerSelectOptionResponse } from 'app/types/entity';
import { useState } from 'react';
import { TopPerformerRank } from './TopPerformers';
import './TopPerformersEdit.scss';
const { Option } = Select;
interface IProps {
  performers: TopPerformerResponse[];
  performerSelectOptions: TopPerformerSelectOptionResponse[];
  onCancel?: (hasUnsavedChanges: boolean) => void;
  onSaved?: (data: TopPerformerResponse, index: number) => void;
  onDelete?: (index: number) => void;
  onAvatarUploaded?: (memberID: number, avatarUrl: string) => void;
  iterationId: number;
  teamId: number;
}

class TopPerformer implements TopPerformerResponse {
  memberId: number = 0;
  id: number = 0;
  fullName: string = '';
  rank: TopPerformerRank;
  iterationId: number = 0;
  caption: string = '';
  avatarUrl: string = '';
  avatarUpload?: File;
  avatarUploadPreview: string = '';
  hasUnsavedChanges: boolean = false;
}

export const TopPerformersEdit = (props: IProps) => {
  let initData = { iterationId: props.iterationId, avatarUploadPreview: '', hasUnsavedChanges: false };
  let data: TopPerformer[] = [
    { ...props.performers[0], ...initData, rank: TopPerformerRank.First },
    { ...props.performers[1], ...initData, rank: TopPerformerRank.Second },
    { ...props.performers[2], ...initData, rank: TopPerformerRank.Third },
  ];

  const [editingData, setData] = useState(data);

  const updateCaptionText = (i: number, val: string) => {
    let tempArr = [...editingData];
    tempArr[i].caption = val;
    tempArr[i].hasUnsavedChanges = true;
    setData(tempArr);
  };

  const updateSelectedPerformer = (i: number, val: number) => {
    let selectedPerformer = props.performerSelectOptions.filter((x) => x.memberId === val)[0];

    if (!selectedPerformer) return;

    let tempArr = [...editingData];
    tempArr[i].memberId = selectedPerformer.memberId;
    tempArr[i].fullName = selectedPerformer.fullName;
    tempArr[i].avatarUrl = selectedPerformer.avatarUrl;
    tempArr[i].hasUnsavedChanges = true;

    setData(tempArr);
  };

  const showErrorNotification = (message: string, description: string) => {
    notification.open({
      message: message,
      description: description,
      // onClick: () => {
      //   console.log(description);
      // },
    });
  };

  const [savingIndex, setSavingndex] = useState(-1);
  const onSaveButtonCicked = async (i: number) => {
    setSavingndex(i);
    let savingData = editingData[i] as TopPerformerRequest;
    let fetch: Promise<TopPerformerResponse> =
      !savingData.id || savingData.id === 0
        ? createTopPerformer(savingData, props.iterationId)
        : updateTopPerformer(savingData, props.iterationId);

    await fetch
      .then((data) => {
        props.onSaved(data, i);
        let tempArr = [...editingData];
        tempArr[i] = { ...data, avatarUploadPreview: '', hasUnsavedChanges: false };
        setData(tempArr);
        if (data.avatarUrl && data.avatarUrl.length > 0) {
          props.onAvatarUploaded(data.memberId, data.avatarUrl);
        }
      })
      .catch((error) => {
        showErrorNotification('Server Error', error.data?.error?.message);
      })
      .finally(() => {
        setSavingndex(-1);
      });
  };

  const itemNotReady = (data: TopPerformer) => {
    return !data || !data.memberId || data.memberId === 0;
  };

  const shouldDisableRow = (index: number) => {
    let item = editingData[index];
    if (item.rank === TopPerformerRank.Second) {
      return itemNotReady(editingData[0]);
    }
    if (item.rank === TopPerformerRank.Third) {
      return itemNotReady(editingData[0]) || itemNotReady(editingData[1]);
    }
  };

  const isSaveButtonDisabled = (item: TopPerformer) => {
    let shouldDisable: boolean = false;
    if (!(item.memberId > 0)) {
      shouldDisable = true;
    } else if (item.rank === TopPerformerRank.Third) {
      let secondPerformer = editingData.filter((x) => x.rank === TopPerformerRank.Second);
      if (secondPerformer && secondPerformer[0]) {
        shouldDisable = !(secondPerformer[0].memberId > 0);
      }
    } else if (item.rank === TopPerformerRank.Second) {
      let firstPerformer = editingData.filter((x) => x.rank === TopPerformerRank.First);
      if (firstPerformer && firstPerformer[0]) {
        shouldDisable = !(firstPerformer[0].memberId > 0);
      }
    }
    return shouldDisable;
  };

  const isDeleteButtonDisabled = (item: TopPerformer) => {
    return !(item.id > 0);
  };

  const setAvatarPreview = (img, index: number) => {
    let tempArr = [...editingData];
    if (img) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        tempArr[index].avatarUploadPreview = reader.result as string;
        tempArr[index].hasUnsavedChanges = true;
        setData(tempArr);
      });
      reader.readAsDataURL(img);
    } else {
      tempArr[index].avatarUploadPreview = '';
      setData(tempArr);
    }
  };

  const handleAvatarChange = (file: RcFile, index: number) => {
    let tempArr = [...editingData];
    tempArr[index].avatarUpload = file;
    setData(tempArr);
    setAvatarPreview(file, index);
  };

  const deleteButtonClicked = async (item: TopPerformer, index: number) => {
    setSavingndex(index);
    await deleteTopPerformer(props.iterationId, item.id)
      .then(() => {
        let tempArr = [...editingData];
        tempArr[index] = new TopPerformer();
        tempArr[index].rank = item.rank;
        tempArr[index].iterationId = props.iterationId;
        props.onDelete(index);
        setData(tempArr);
      })
      .catch((error) => {
        console.error(error);
        showErrorNotification('Server Error', error.data?.error?.message);
      })
      .finally(() => {
        setSavingndex(-1);
      });
  };
  return (
    <div className="top-performers-edit">
      <table>
        <thead>
          <tr>
            <th align="center">Top</th>
            <th>Name</th>
            <th>Caption</th>
            <th>Image</th>
            <th align="center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {editingData.map((v, i) => {
            let avatar = v.avatarUploadPreview.length > 0 ? v.avatarUploadPreview : v.avatarUrl ? v.avatarUrl : '';
            let isProcessingFetch = savingIndex === i;
            return (
              <tr key={i}>
                <td align="center">
                  {i + 1} {i + 1 === 1 ? <span className="required-star">(*)</span> : null}
                </td>
                <td>
                  <Form.Item name={`${v.memberId}`} key={i} htmlFor={`${v.memberId}`} initialValue={v.memberId}>
                    <Select
                      id={`${v.memberId}`}
                      defaultValue={v.memberId}
                      value={v.memberId}
                      onChange={(val) => {
                        updateSelectedPerformer(i, val);
                      }}
                      key={v.memberId}
                      disabled={shouldDisableRow(i)}
                    >
                      <Option value={0} disabled={true}>
                        - SELECT -
                      </Option>
                      {props.performerSelectOptions.map((p) => (
                        <Option key={p.memberId} value={p.memberId}>
                          {p.fullName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </td>
                <td>
                  <Input.TextArea
                    className="edit-caption"
                    readOnly={false}
                    value={v.caption ?? ''}
                    bordered={true}
                    onChange={({ target }) => updateCaptionText(i, target.value)}
                    maxLength={500}
                    cols={5}
                    autoFocus={false}
                    disabled={!v || shouldDisableRow(i)}
                  />
                </td>
                <td>
                  <Avatar src={avatar} />
                  <Form.Item label="" name="images" className="img-upload-btn">
                    <Upload
                      directory={false}
                      multiple={false}
                      accept=".jpeg,.png,.jpg"
                      maxCount={1}
                      listType={null}
                      showUploadList={false}
                      beforeUpload={(file) => {
                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                        if (!isJpgOrPng) {
                          showErrorNotification('Warning', 'You can only upload JPG/PNG file!');
                        }
                        const isLt3M = file.size / 1024 / 1024 < 3;
                        if (!isLt3M) {
                          showErrorNotification('Warning', 'Image must smaller than 3MB!');
                        }
                        if (isJpgOrPng && isLt3M) {
                          handleAvatarChange(file, i);
                        }
                        return false;
                      }}
                    >
                      {!isSaveButtonDisabled(v) && (
                        <Button
                          style={{ paddingLeft: '0' }}
                          type="link"
                          className="btn-upload"
                          icon={<CloudUploadOutlined />}
                        >
                          {avatar.length > 0 || v.avatarUploadPreview.length > 0 ? (
                            <span>Replace</span>
                          ) : (
                            <span>Select</span>
                          )}
                        </Button>
                      )}
                      {v.avatarUploadPreview.length > 0 && (
                        <Button
                          danger
                          style={{ paddingLeft: '0' }}
                          type="link"
                          className="btn-upload"
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAvatarChange(null, i);
                          }}
                          icon={<DeleteOutlined />}
                        >
                          Clear
                        </Button>
                      )}
                    </Upload>
                  </Form.Item>
                </td>
                <td align="center">
                  <Button
                    type="primary"
                    className="btn"
                    onClick={() => onSaveButtonCicked(i)}
                    loading={isProcessingFetch}
                    disabled={isSaveButtonDisabled(v) || isProcessingFetch}
                    style={{ width: '100%' }}
                  >
                    <span>SAVE</span>
                  </Button>
                  {!itemNotReady(v) && v.rank !== TopPerformerRank.First ? (
                    <Popconfirm
                      title="Delete item. Are you sure?"
                      onConfirm={() => {
                        deleteButtonClicked(v, i);
                      }}
                      okText="Yes"
                      disabled={isDeleteButtonDisabled(v) || isProcessingFetch}
                      cancelText="Cancel"
                    >
                      <Button
                        type="primary"
                        className="btn performer-delete-btn"
                        danger
                        disabled={isDeleteButtonDisabled(v) || isProcessingFetch}
                      >
                        <span>Delete</span>
                      </Button>
                    </Popconfirm>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="action-buttons">
        {editingData.filter((x) => x.hasUnsavedChanges).length > 0 ? (
          <Popconfirm
            title="Lose unsaved changes. Are you sure?"
            onConfirm={() => {
              props.onCancel(editingData.filter((x) => x.hasUnsavedChanges).length > 0);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="btn btn-cancel" type="default">
              <span>CLOSE</span>
            </Button>
          </Popconfirm>
        ) : (
          <Button className="btn btn-cancel" type="default" onClick={() => props.onCancel(false)}>
            <span>CLOSE</span>
          </Button>
        )}
      </div>
    </div>
  );
};

import { Button, Col, Collapse, Divider, Row, Space, Image } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { IReleaseNoteResponse } from 'app/types/ReleaseNoteModel';
import { ModalFunc } from 'antd/lib/modal/confirm';
import { useState } from 'react';
import { formAction } from 'app/pages/AboutADM/ReleaseNote/ReleaseNote';
import { useForm } from 'antd/es/form/Form';
import Icon, { ExclamationCircleFilled } from '@ant-design/icons';
import StoryIcon from 'app/pages/AboutADM/storyicon';
import EnhanceIcon from 'app/pages/AboutADM/enhanceicon';
import IssueIcon from 'app/pages/AboutADM/issueicon';
import { getEditorState } from '../IssueReportIdea/IssueReportIdeaHandle';
import { UploadFile } from 'antd/lib/upload/interface';
import { EditorState, ContentState } from 'draft-js';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { ErrorType, SuccesType } from 'app/common/constants';
import { deleteReleaseNote } from 'app/apis/releaseNote';
import { IReleaseNoteItemResponse } from 'app/types/ReleaseNoteItemModel';
import { ReleaseNoteItemFormModal } from './ReleaseNoteItemFormModal';
import { deleteReleaseNoteItem } from 'app/apis/releaseNoteItem';
import { ReleaseNoteItemType, bindingReleaseNoteItemData } from './ReleaseNoteHelper';

interface ReleaseNoteItemListProps {
  releaseNoteItem: IReleaseNoteResponse;
  confirm: ModalFunc;
  releaseNoteItemFormData: IReleaseNoteItemResponse;
  setReleaseNoteItemFormData: React.Dispatch<React.SetStateAction<IReleaseNoteItemResponse>>;
  typeModal: formAction;
  setTypeModal: React.Dispatch<React.SetStateAction<formAction>>;
  setTypePrefixIcon: React.Dispatch<any>;
  editorState: any;
  setEditorState: React.Dispatch<any>;
  listData: IReleaseNoteResponse[];
  setListData: React.Dispatch<React.SetStateAction<IReleaseNoteResponse[]>>;
  typePrefixIcon: any;
  isAllowed: boolean;
  onShowModal: (formAct: formAction, formData: any) => void;
}

const { Panel } = Collapse;
export const ReleaseNoteItemList = (props: ReleaseNoteItemListProps) => {
  const item = props.releaseNoteItem;
  const confirm = props.confirm;
  const [releaseNoteItemModalState, setReleaseNoteItemModalState] = useState(false);
  const [formIssue] = useForm();
  const [typeValue, setTypeValue] = useState(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const setTypePrefixIcon = props.setTypePrefixIcon;

  const updateTypeSelect = (value: any) => {
    setTypeValue(value);
    switch (value) {
      case ReleaseNoteItemType.Feature:
        setTypePrefixIcon(<Icon component={StoryIcon} />);
        break;
      case ReleaseNoteItemType.Enhance:
        setTypePrefixIcon(<Icon component={EnhanceIcon} />);
        break;
      case ReleaseNoteItemType.BugFix:
        setTypePrefixIcon(<Icon component={IssueIcon} />);
        break;
      default:
        setTypePrefixIcon(null);
    }
  };

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: 'Are you sure delete this record?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        handleDelete(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDelete = async (id: number) => {
    await deleteReleaseNote(id)
      .then((res) => {
        props.setListData([...props.listData.filter((item) => item.id !== id)]);
        openNotificationByType(SuccesType, 'Delete Successfully !');
      })
      .catch((err) => {
        openNotificationByType(ErrorType, 'Something Went Wrong !!!');
      });
  };

  // Modal
  const showModalReleaseItem = (formAct: formAction, formData) => {
    setReleaseNoteItemModalState(true);
    props.setReleaseNoteItemFormData(formData);
    if (formAct === formAction.Edit) {
      props.setTypeModal(formAction.Edit);
      updateTypeSelect(formData.type);

      // Binding data for form
      formIssue.setFieldsValue({
        id: formData.id,
        type: formData.type,
        name: formData.name,
        // detail: EditorState.createWithContent(convertFromRaw(JSON.parse(formData.detail))),
        detail: formData.detail,
        releaseNoteId: formData.releaseNoteId,
      });

      // Convert formData.detail to EditorState which editor can read and display it.
      const newEditorState = getEditorState(formData.detail);
      props.setEditorState(newEditorState);

      // set FileList for Upload Field to display on Edit modal
      var listImgs = new Array();
      formData.releaseNoteItemImages?.map((item) => {
        var newFile = {
          uid: `${item.id}`,
          name: item.fileName,
          status: 'done',
          url: item.path,
        } as UploadFile;
        listImgs.push(newFile);
      });
      setFileList(listImgs);
    } else {
      props.setTypeModal(formAction.Create);
      updateTypeSelect(null);
      formIssue.resetFields();
      setFileList([]);
    }
  };

  const closeModalIssue = () => {
    setReleaseNoteItemModalState(false);
    formIssue.resetFields();
    // Reset Editor
    const emptyEditorState = EditorState.push(props.editorState, ContentState.createFromText(''), 'remove-range');
    props.setEditorState(emptyEditorState);

    // Reset Prefix Icon
    setTypePrefixIcon(null);
  };

  const resetModalIssue = () => {
    formIssue.resetFields();
    formIssue.setFieldsValue({
      releaseNoteId: props.releaseNoteItemFormData.releaseNoteId,
    });
    // Reset Editor
    const emptyEditorState = EditorState.push(props.editorState, ContentState.createFromText(''), 'remove-range');
    props.setEditorState(emptyEditorState);

    // Reset Image
    setFileList([]);

    // Reset Prefix Icon
    setTypePrefixIcon(null);
  };

  const showDeleteItemConfirm = (id: number, releaseNoteId: number) => {
    confirm({
      title: 'Are you sure delete this record?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        handleDeleteItem(id, releaseNoteId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDeleteItem = async (id: number, releaseNoteId: number) => {
    await deleteReleaseNoteItem(id)
      .then((res) => {
        props.setListData((prev) =>
          prev.map((item) =>
            item.id === releaseNoteId
              ? { ...item, releaseNoteItems: item.releaseNoteItems.filter((child) => child.id !== id) }
              : item
          )
        );
        openNotificationByType(SuccesType, 'Delete Successfully !');
      })
      .catch((err) => {
        openNotificationByType(ErrorType, 'Something Went Wrong !!!');
      });
  };

  const showContent = (detail: string) => {
    const newEditorState = getEditorState(detail);
    return newEditorState;
  };
  const renderIssueReportTitle = (record: IReleaseNoteItemResponse) => {
    return (
      <Space>
        <Icon
          component={
            record.type === ReleaseNoteItemType.Feature
              ? StoryIcon
              : record.type === ReleaseNoteItemType.BugFix
              ? IssueIcon
              : EnhanceIcon
          }
        />
        {record.name}
      </Space>
    );
  };
  return (
    <div style={{ marginBottom: '30px' }} key={item.id} id={'note-' + item.id}>
      <Row style={item.description == null ? { marginBottom: '20px' } : {}}>
        <Col span={2}>
          <b style={{ fontSize: '18px' }}>{item.name}</b>
        </Col>
        <Col span={2} style={{ textAlign: 'center', paddingTop: '2px' }}>
          <span style={{ display: 'inline-block', width: '75%', backgroundColor: '#F9F9F9' }}>{item.releaseDate}</span>
        </Col>
        {props.isAllowed ? (
          <>
            <Col span={3}>
              <Button
                type="dashed"
                style={{
                  float: 'right',
                  marginRight: '20px',
                }}
                onClick={() =>
                  showModalReleaseItem(formAction.Create, {
                    name: '',
                    type: 0,
                    status: 0,
                    detail: '',
                    releaseNoteId: item.id,
                    uploadImages: [],
                  })
                }
              >
                + Add Item
              </Button>
              <ReleaseNoteItemFormModal
                addModalState={releaseNoteItemModalState}
                editorState={props.editorState}
                setEditorState={props.setEditorState}
                releaseNoteItemFormData={props.releaseNoteItemFormData}
                setReleaseNoteItemFormData={props.setReleaseNoteItemFormData}
                typeModal={props.typeModal}
                typeValue={typeValue}
                typePrefixIcon={props.typePrefixIcon}
                fileList={fileList}
                setFileList={setFileList}
                releaseNoteData={props.listData}
                onCloseModal={() => closeModalIssue()}
                onResetModal={() => resetModalIssue()}
                onBindingData={(data: IReleaseNoteItemResponse) => bindingReleaseNoteItemData(data)}
                onUpdateTypeSelect={(value: any) => updateTypeSelect(value)}
                form={formIssue}
                addContinue={props.typeModal === formAction.Create ? true : false}
                // issueReportData={issueReportData}
              />
            </Col>

            <Col span={1}>
              <a
                style={{ float: 'right', color: '#108ee9', marginRight: '20px', paddingTop: '4px' }}
                onClick={() => showDeleteConfirm(item.id)}
              >
                Delete
              </a>
            </Col>
            <Col span={2}>
              <Button type="primary" onClick={() => props.onShowModal(formAction.Edit, item)}>
                Edit
              </Button>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {item.description != null ? (
            <Editor editorState={showContent(item.description)} readOnly={true} toolbarHidden={true} />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <div>
        <Collapse key={item.id}>
          {item.releaseNoteItems
            ?.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime())
            .map((child) => {
              return (
                <Panel header={renderIssueReportTitle(child)} key={child.id}>
                  <Row>
                    <Col span={20}>
                      <Editor editorState={showContent(child.detail)} readOnly={true} toolbarHidden={true} />
                    </Col>
                    {props.isAllowed ? (
                      <Col span={4}>
                        <div style={{ float: 'right' }}>
                          <a
                            className="btn-delete"
                            style={{ color: '#108ee9', marginRight: '20px', paddingTop: '4px' }}
                            onClick={() => showDeleteItemConfirm(child.id, child.releaseNoteId)}
                          >
                            Delete
                          </a>
                          <Button type="primary" onClick={() => showModalReleaseItem(formAction.Edit, child)}>
                            Edit
                          </Button>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {child.releaseNoteItemImages && child.releaseNoteItemImages.length > 0 ? (
                      <>
                        <Divider style={{ marginBottom: '10px' }} />
                        <b style={{ marginBottom: '15px' }}>Images: </b>
                        <Col span={24} style={{ marginBottom: '20px' }}>
                          {child.releaseNoteItemImages?.map((item) => {
                            return (
                              <>
                                <Image width={100} src={item.path} style={{ paddingRight: '15px' }} />
                              </>
                            );
                          })}
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Panel>
              );
            })}
        </Collapse>
      </div>
    </div>
  );
};

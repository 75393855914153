import { Button, Row } from 'antd';
import { updateHighlight } from 'app/apis/reportClient';
import { AuthContext } from 'app/contexts/AuthContext';
import { HighlightRequest } from 'app/types/WeeklyOverview';
import { useContext, useEffect, useState } from 'react';

import { HighlightWrapper } from './Highlight.styled';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { roleNames } from 'app/common/constants';

type IProps = {
  content: string;
  currentWeek: moment.Moment;
};

export const Highlight = ({ content, currentWeek }: IProps) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [isSubmit, setIsSubmitting] = useState<boolean>(false);
  const [cacheData, setCacheData] = useState(EditorState.createEmpty());
  const [highlightData, setHighlightData] = useState(EditorState.createEmpty());
  const { user } = useContext(AuthContext);

  const convertHtmlToState = (htmlStr: string) => {
    const contentBlock = htmlToDraft(htmlStr);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  useEffect(() => {
    let html = '';
    if (content) html = content;
    const editorState = convertHtmlToState(html);
    setHighlightData(editorState);
    setCacheData(editorState);
  }, [content]);

  const htmlStr = draftToHtml(convertToRaw(highlightData?.getCurrentContent()));

  const onSave = async () => {
    setIsSubmitting(true);

    try {
      let data: HighlightRequest = {
        highlight: htmlStr,
        reportDate: currentWeek.utc(true).toDate(),
      };
      let respData = await updateHighlight(data);
      const editorState = convertHtmlToState(respData.highlightNote);
      setHighlightData(editorState);
      setIsSubmitting(false);
      setEditable(false);
      setCacheData(editorState);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };
  const onCancelEdit = () => {
    setEditable(false);
    setHighlightData(cacheData);
  };
  return (
    <HighlightWrapper>
      <Row>
        <p className="title">Highlight</p>
        {editable ? (
          <>
            <Button type="link" htmlType="submit" onClick={onSave} loading={isSubmit}>
              Save
            </Button>
            <Button type="ghost" onClick={onCancelEdit}>
              Cancel
            </Button>
          </>
        ) : (
          (user.role === roleNames.admin || user.role === roleNames.pqa) && (
            <Button type="link" onClick={() => setEditable(true)}>
              Edit
            </Button>
          )
        )}
      </Row>
      {editable ? (
        <Editor
          wrapperClassName="editor-wrapper"
          editorClassName="editor-box"
          editorState={highlightData}
          onEditorStateChange={setHighlightData}
          toolbar={{
            image: false,
          }}
        />
      ) : (
        <Editor
          readOnly={true}
          toolbarHidden={true}
          editorClassName="editor-box-read-only"
          placeholder="Edit highlight here"
          editorState={cacheData}
        />
      )}
    </HighlightWrapper>
  );
};

import styled from 'styled-components';
export const QualitySummaryWrapper = styled.div`
  .summary-edit-button {
    position: absolute !important;
    right: 16px;
    top: 16px;
  }

  .quality-summary-item {
    min-height: 100%;

    .ant-progress {
      margin-top: 16px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      .ant-progress-text {
        font-size: 36px;
      }
    }

    .summary-loc {
      margin-top: 36px;
      text-align: center;
      span {
        font-size: 3rem;
      }
    }

    .card-title {
      font-size: 18px;
    }
  }

  .summary-defect-rate {
    min-height: 144px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 56px;
    }

    div {
      padding-top: 12px;
      margin-bottom: -12px;
      width: 118%;
      text-align: center;
      border-top: 1px solid #eeeeee;

      p {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  .quality-summary-modal.ant-modal {
    width: 40% !important;
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          font-size: normal normal bold 25px/24px Segoe UI;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: x-large;
        }
      }

      .ant-modal-body {
        padding: 3em 3em 1.5em 3em;
        .form-footer {
          padding-top: 2.5em;
          text-align: center;
          .btn {
            box-sizing: content-box;
            text-transform: uppercase;
            width: 7em;
            padding: 0.3em 0;
            span {
              font-weight: bold;
            }
            margin: 0 0.5em;
          }
          .btn-cancel:hover {
            border: solid 1px #f0f0f0;
            color: #595959;
            background-color: #f0f0f0;
          }
          .btn-cancel {
            border: solid 1px #eeeeee;
            background-color: #eeeeee;
          }
          text-align: center;
        }

        .ant-form {
          .ant-form-item {
            .ant-form-item-label {
              overflow: inherit;
              white-space: normal !important;
            }
          }
        }
      }
    }
  }
`;

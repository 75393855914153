import styled from 'styled-components';

export const StyledProgramBestPerformer = styled.div`
  .ant-carousel {
    flex: 1;
    max-width: 100%;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: black;
  }
  .ant-carousel .slick-dots li button {
    background: gray;
  }
  .ant-row {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
  }
  .ant-avatar {
    width: 100px;
    height: 100px;
    border-radius: 10%;
    margin: 0 auto;
    display: flex;
  }
  .user-name {
    font-weight: bold;
    text-align: left;
    height: 40px;
    margin: 5px 0;
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .team-name {
    text-align: left;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    color: gray;
    font-size: 25px;
  }

  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-next:focus {
    color: unset;
  }
  .ant-carousel .slick-prev {
    margin-left: 1%;
  }
  .ant-carousel .slick-next {
    margin-right: 1%;
  }
`;

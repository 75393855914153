import { Line } from '@ant-design/plots';
import { Empty } from 'antd';
import { Card } from 'app/components/Card';
import { LabelTrendsChart } from 'app/common/constants';
import { ProgramTrendDeliveryChartData } from 'app/types/ProgramTrends';
import { minBy } from 'lodash';
interface IProps {
  dataSource: ProgramTrendDeliveryChartData[];
  loading?: boolean;
}

const ProgramTrendDelivery = ({ dataSource, loading }: IProps) => {
  const config = {
    data: dataSource,
    xField: 'iterationName',
    yField: 'deliveredPercentage',
    seriesField: 'portfolioName',
    point: {
      size: 3,
      shape: 'square',
    },
    xAxis: {
      label: LabelTrendsChart,
    },
    yAxis: {
      max: 1.05,
      tickInterval: 0.05,
      min: Math.min(minBy(dataSource, (item) => item.deliveredPercentage)?.deliveredPercentage, 0.5),
    },
    color: ['#F7C73C', '#8778F9', '#7EA6FA', '#8FE4C3', '#808FAA'],
  };

  return (
    <Card title={'Program Delivery'} loading={loading}>
      {!loading && (!dataSource?.length ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <Line {...config} />)}
    </Card>
  );
};

export default ProgramTrendDelivery;

/* eslint-disable react/display-name */
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  TABLE_DEFAULT_PAGE_SIZE,
  ITERATION_CARRIED_OUT_COLOR,
  ITERATION_KEEP_COLOR,
  MobileBreakPoint,
} from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import {
  IterationState,
  TicketResponse,
  WorkItemType,
  UpdateWorkItem,
  TicketResponseCustom,
  ItemState,
  TaskState,
} from '../../types/PlannedWorkItem';
import { fetchIterationDetail, updatePlannedWorkItem, updateWorkItem } from 'app/apis/teamDetailClient';
import { Button, notification, Space, TablePaginationConfig, Tag } from 'antd';
import Icon from '@ant-design/icons';
import DefectIcon from '../TeamDetail/Plan/DefectIcon';
import UserStoryIcon from '../TeamDetail/Plan/UserStoryIcon';
import { updateItem } from 'app/utils/tableUtils';
import './WorkItem.scss';
import { isEmpty, isNull, orderBy } from 'lodash';
import { ScheduleState } from 'app/components/ScheduleState/ScheduleState';
import { CommitEditButton } from './CommitEditButton';
import { Stage } from 'app/types/TeamDetailTypes';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { SubPage } from 'app/types/TeamDetail';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { IterationBackLogContext } from 'app/contexts/IterationBackLogContext';
import { canEditReport } from '../TeamDetail/Report/Report';
import { UserContext } from 'app/contexts/UserContext';
import _ from 'lodash';
import { TeamContext } from 'app/contexts/TeamContext ';
import useIsMobile from 'app/utils/useIsMobile';

interface IProps {
  page: SubPage;
}
export const WorkItem = (props: IProps) => {
  const { isAuthorized } = useAuthz();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [iterationBacklogs, setIterationBackLog] = useState<TicketResponse[]>();
  const [workItemsOwners, setWorkItemsOwners] = useState<string[]>();
  const {
    stage: currentStage,
    selectedIteration,
    teamIterations,

    reportStatus,
  } = useContext(IterationContext);
  const {
    user: { permissions },
  } = useContext(UserContext);
  const { workItem, isUpdatedPlan } = useContext(IterationBackLogContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { page: activePage } = useContext(TeamContext);
  const [isMobile] = useIsMobile();
  // Change iteration
  useEffect(() => {
    if (activePage === SubPage.Plan) {
      setIterationBackLog(null);
      setLoading(true);
    }
  }, [selectedIteration?.id, activePage]);

  // After change iteration
  useEffect(() => {
    if (workItem?.length > 0) {
      // const nextIteration = _.orderBy([...teamIterations], (x) => x.dateFrom, 'desc')?.find(
      //   (x) => x.dateFrom >= selectedIteration.dateTo
      // );
      const nextIteration = teamIterations[teamIterations.indexOf(selectedIteration) - 1];

      if (nextIteration) {
        //need data of next iteration.
        setLoading(true);
        const getNextIterationData = async () => {
          const data = await fetchIterationDetail(nextIteration?.id);
          const nextIterationData = data?.tickets?.filter((x) => x.iterationState === IterationState.Monitor);
          setIterationBackLog(workItem);

          const clonedItems = [...workItem];
          if (props.page === SubPage.Report) {
            const today = new Date();
            const currentIteration = teamIterations?.find(
              (x) => new Date(x.dateFrom) <= today && new Date(x.dateTo) >= today
            );
            if (
              selectedIteration?.dateTo <= currentIteration?.dateFrom &&
              today >= new Date(selectedIteration?.dateTo)
            ) {
              clonedItems?.forEach((record) => {
                if (record.scheduleState !== 'Released' && record.scheduleState !== 'Accepted') {
                  if (nextIterationData?.findIndex((x) => x.code === record.code) ?? -1 >= 0) {
                    (record as TicketResponseCustom).itemState = ItemState.CARRIED_TO_NEXT_ITERATION;
                  } else {
                    (record as TicketResponseCustom).itemState = ItemState.KEEP;
                  }
                }
              });
            }
          }
          setIterationBackLog(clonedItems);
          setLoading(false);
        };
        getNextIterationData();
      } else {
        setIterationBackLog(workItem);
        setLoading(false);
      }
    }
  }, [workItem]);

  useEffect(() => {
    setPage(1);
  }, [currentStage, selectedIteration?.id]);

  const normalizeOwner = (owner) => (isEmpty(owner?.trim()) ? '' : owner);
  useEffect(() => {
    setWorkItemsOwners(
      Array.from(
        new Set([
          ...Array.from(new Set(iterationBacklogs?.map((i) => normalizeOwner(i.owner)))),
          ...Array.from(
            new Set(iterationBacklogs?.filter((i) => i.coOwner != null).map((i) => normalizeOwner(i.coOwner)))
          ),
          ...Array.from(
            new Set(iterationBacklogs?.filter((i) => i.qaOwner != null).map((i) => normalizeOwner(i.qaOwner)))
          ),
        ])
      )
    );
  }, [iterationBacklogs]);

  const getCellBackgroundColor = (record: TicketResponseCustom) => {
    return '';
    // if (props.page === SubPage.Plan) return '';

    // return record.taskState === TaskState.UNCOMPLETED_CARD && props.page === SubPage.Report
    //   ? ITERATION_CARRIED_OUT_COLOR
    //   : '';
  };

  const renderIDColumn = (record: TicketResponseCustom) => {
    return {
      props: {
        style: {
          background: getCellBackgroundColor(record),
        },
      },
      children: (
        <Space>
          <Icon component={record.type === WorkItemType.Defect ? DefectIcon : UserStoryIcon} />
          {record.code}
        </Space>
      ),
    };
  };

  const renderScheduleState = (record: TicketResponse) => {
    return <ScheduleState record={record} />;
  };

  const renderCommitColumn = (record: TicketResponse) => {
    if (record.isCommitted === true || isNull(record.isCommitted)) {
      return <Tag color="#108ee9">Y</Tag>;
    }
    return <Tag color="#f3c300">N</Tag>;
  };

  const renderStateColumn = (record: TicketResponse) => {
    if (!isNull(record.taskState)) {
      return <Button danger>{record.taskState}</Button>;
    }
  };

  const renderEffortColumn = (value, record) => {
    let className = '';
    if (record.effortUpdateResult == 0) {
      className = 'highlight-effort not-update-effort';
    } else if (record.effortUpdateResult < 0) {
      className = 'highlight-effort effort-decrease';
    }
    return {
      children: <div className={className}>{value}</div>,
    };
  };
  const renderPointColumn = (record: TicketResponse) => {
    return {
      props: {
        style: { background: isNull(record.point) && record.type === WorkItemType.UserStory ? '#ff9999' : '' },
      },
      children: <div>{record.point}</div>,
    };
  };

  const openNotification = (statusCode: number, message: string) => {
    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 3,
    });
  };

  const columns = useMemo<EditableColumn<TicketResponse>[]>(
    () => [
      {
        title: '#',
        width: '40px',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
        fixed: !isMobile ? 'left' : false,
      },
      {
        title: 'Feature',
        dataIndex: 'feature',
        key: 'feature',
        editable: false,
        render: (value, record, index) => {
          return (
            <>
              <span>{record.feature}</span>
              <div className="feature-status">{record.parent?.state}</div>
            </>
          );
        },
        width: '80px',
        fixed: !isMobile ? 'left' : false,
      },
      {
        title: 'ID',
        dataIndex: 'code',
        render: (text, record) => renderIDColumn(record),
        sorter: (a: any, b: any) => 1,
        width: '140px',
        fixed: !isMobile ? 'left' : false,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'parentId',
        editable: false,
        width: '200px',
      },
      {
        title: 'Points',
        dataIndex: 'point',
        sorter: (a, b) => a.point - b.point,
        width: '70px',
        render: (text, record) => renderPointColumn(record),
        align: 'center',
      },
      {
        title: 'Schedule State',
        dataIndex: 'scheduleStatePrefix',
        editable: false,
        render: (text, record) => renderScheduleState(record),
        filters: [
          { text: 'Idea', value: 'I' },
          { text: 'Defined', value: 'D' },
          { text: 'In-Progress', value: 'P' },
          { text: 'Completed', value: 'C' },
          { text: 'Accepted', value: 'A' },
          { text: 'Released', value: 'R' },
        ],
        onFilter: (value: any, record) => record.scheduleStatePrefix?.indexOf(value) === 0,
        width: '200px',
        inputType: 'select',
        align: 'center',
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'ownerId',
        editable: false,
        width: '200px',
        filters: orderBy(
          workItemsOwners?.map((owner) => ({
            text: isEmpty(owner) ? <i>Not assigned</i> : owner,
            value: normalizeOwner(owner),
          })),
          ['value']
        ),
        onFilter: (value: any, record) =>
          normalizeOwner(record.owner) === value ||
          normalizeOwner(record.coOwner) === value ||
          normalizeOwner(record.qaOwner) === value,
        render: (value, record, index) => {
          const obj = {
            children:
              value +
              (!isEmpty(record.coOwner) ? '\nCo Owner: ' + record.coOwner : '') +
              (!isEmpty(record.qaOwner) ? '\nQA Owner: ' + record.qaOwner : ''),
            props: {},
          };
          obj.props['className'] = 'breakline-cell';
          return obj;
        },
      },
      {
        title: 'Effort(h)',
        dataIndex: 'actual',
        key: 'parentId',
        editable: false,
        render: renderEffortColumn,
        visible: props.page === SubPage.Monitoring,
        align: 'center',
        width: '100px',
      },
      {
        title: 'Commit',
        dataIndex: 'isCommitted',
        editable: true,
        inputType: 'custom',
        editingContent: (record) => (
          <CommitEditButton
            record={record}
            onChange={(status) => {
              record.isCommitted = status;
            }}
          />
        ),
        render: (text, record) => renderCommitColumn(record),
        filters: [
          { text: 'Y', value: true },
          { text: 'N', value: false },
        ],
        onFilter: (value: any, record) => record?.isCommitted === value,
        width: '100px',
        align: 'center',
      },
      {
        title: 'Notes',
        dataIndex: 'note',
        editable: true,
        width: '200px',
        rules: [{ max: 200, message: 'The field Note must be a string with a maximum length of 200' }],
      },
    ],
    [page, pageSize, props.page, workItemsOwners, selectedIteration]
  );
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const onSave = async (id: number, { note, isCommitted }: TicketResponse) => {
    try {
      const updateWorkItemRequest: UpdateWorkItem = { note, isCommitted };
      let response: TicketResponse;
      if (isUpdatedPlan) {
        response = await updatePlannedWorkItem(id, updateWorkItemRequest);
      } else {
        response = await updateWorkItem(id, updateWorkItemRequest);
      }
      const newData = updateItem(response, iterationBacklogs);
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });
      setIterationBackLog(newData);
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };
  return (
    <EditableTable
      className="work-item"
      onChange={onChange}
      onSave={onSave}
      pageSize={pageSize}
      loading={loading}
      scroll={{ x: 'max-content' }}
      current={page}
      isEdit={
        isAuthorized &&
        (currentStage === Stage.Other ||
          currentStage === undefined ||
          ((selectedIteration?.isCurrentSprint || selectedIteration?.isFutureSprint) &&
            currentStage === Stage.Planning)) &&
        canEditReport(reportStatus, permissions)
      }
      columns={columns}
      data={iterationBacklogs}
    />
  );
};

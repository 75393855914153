import styled from 'styled-components';

export const StyleHallOfFame = styled.div`
  .row-header {
    display: flex;
    justify-content: space-between;
  }
  .title-label {
    color: #024fa5;
  }
  .reward-report-button {
    background: rgba(0, 129, 5, 0.8);
  }
  .content {
    width: 100%;
  }
`;

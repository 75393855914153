import styled from 'styled-components';

export const UncompletionReportWrapper = styled.div`
  .title {
    font-weight: bold;
    color: #0e50a4;
    padding-bottom: 1em;
  }
  span {
    .ant-select {
      margin-left: 2.2em;
      font-weight: normal;
    }
  }
  .ant-table-wrapper {
    padding-top: 1.5em;
  }
  .center-cell {
    text-align: center;
  }
  .overflow-cell {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

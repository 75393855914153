/* eslint-disable react-hooks/exhaustive-deps */
import { Progress, TablePaginationConfig, Tooltip } from 'antd';
import { GroupedTeamLinks, TABLE_DEFAULT_PAGE_SIZE } from 'app/common/constants';
import { EditableTable, EditableColumn } from 'app/components/EditableTable';
import { Entity } from 'app/types/entity';
import { useContext, useEffect, useMemo, useState } from 'react';
import '../IterationDetail.scss';
import {
  GroupedTeamDataReportResponse,
  GroupedTeamReportDetail,
  GroupedTeamReportOverview,
} from 'app/types/GroupedTeamModel';
import { TeamParentContext } from 'app/contexts/TeamParentContext';
import { TeamType } from 'app/types/team';
import { Link } from 'react-router-dom';

interface IIterationDetailTableProps {
  currentTab: string;
  dataSource: GroupedTeamDataReportResponse;
  type: TeamType;
}

export const IterationDetailTable = (props: IIterationDetailTableProps) => {
  const { iterationData, loading } = useContext(TeamParentContext);
  const [detailData, setDetailData] = useState<GroupedTeamReportDetail[]>();
  const [overviewData, setOverviewData] = useState<GroupedTeamReportOverview[]>();
  const [titleName, setTitleName] = useState<String>(null);
  const [data, setData] = useState<any>();

  const [id, setId] = useState('iteration-overview-table');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  useEffect(() => {
    if (props?.type === 1) setTitleName('Delivery Stream');
    if (props?.type === 2) setTitleName('Release Train');
    if (props?.type === 3) setTitleName('Team Name');
  }, [props.type]);

  useEffect(() => {
    setDetailData(props?.dataSource?.detail);
    setOverviewData(props?.dataSource?.overview);
  }, [iterationData]);

  const renderCardBelowCompleted = (value) => {
    return (
      <div
        style={{
          position: 'absolute',
          inset: 0,
          paddingRight: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          backgroundColor: `${value > 0 ? '#F3C300' : ''}`,
        }}
      >
        <span>{value}</span>
      </div>
    );
  };

  const renderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`/${GroupedTeamLinks[props.type + 1]}/${record.teamId}`}>
          {text}
        </Link>
      </>
    );
  };

  const renderColorProgress = (type: string) => {
    let bgColor = '';
    switch (type) {
      case 'Accepted/Released':
        bgColor = '#2785FF';
        break;
      case 'Carried Over':
        bgColor = '#F48080';
        break;
      case 'Keep':
        bgColor = '#E5B800';
        break;
      case 'Idea':
        bgColor = '#B1B1B1';
        break;
      case 'Defined':
        bgColor = '#B9D7FF';
        break;
      case 'In Progress':
        bgColor = '#E5B800';
        break;
      case 'Completed':
        bgColor = '#77C172';
        break;
      case 'Accepted':
        bgColor = '#2785FF';
        break;
      case 'Released':
        bgColor = '#0E50A4';
        break;
      default:
        break;
    }
    return bgColor;
  };

  const PercentageBar = ({ value }: any) => {
    return (
      <div className="progress-container">
        <Progress
          className="bar"
          strokeColor="#0066FF"
          percent={value > 100 ? 100 : value}
          showInfo={false}
          strokeLinecap="square"
        />
        <div className="percentage-value">
          {value === Infinity ? 'N/A' : Math.round(value * 10) / 10}
          {value !== Infinity && '%'}
        </div>
      </div>
    );
  };

  const MultiProgress = (value) => {
    const tooltipData = value?.map((item, i) => {
      return item.value === 0 ? (
        ''
      ) : (
        <div key={i} className="tooltip-card">
          <div>
            <svg className="bullet-point">
              <circle cx="5" cy="5" r="5" strokeWidth={3} fill={renderColorProgress(item.type)} />
            </svg>
            <span>{item.type}</span>
          </div>
          <div>
            <span>{Math.round(item.value * 10) / 10}%</span>
          </div>
        </div>
      );
    });

    return (
      <div className="multi-progress-container">
        {tooltipData?.length !== 0 && (
          <Tooltip
            title={tooltipData}
            overlayInnerStyle={{
              background: 'white',

              color: 'black',

              width: '240px',

              fontSize: '14px',

              padding: '10px',
            }}
          >
            <div className="row-multi-progress">
              {value?.map((item, i) => {
                return item.value === 0 ? (
                  ''
                ) : (
                  <div
                    key={i}
                    style={{
                      background: renderColorProgress(item.type),

                      color: 'white',

                      width: `${item.value}%`,
                    }}
                  >
                    <span>{item.value !== 0 && Math.round(item.value * 10) / 10}%</span>
                  </div>
                );
              })}
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const overviewColumns = useMemo<EditableColumn<GroupedTeamReportOverview>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: titleName,
        dataIndex: 'name',
        key: 'name',
        editable: false,
        width: '20%',
        sorter: () => 1,
        render: (value, record) => renderLink(value, record),
      },
      {
        title: 'Cards Status',
        dataIndex: 'storiesStatus',
        width: '20%',
        render: (value, record, index) => MultiProgress(value),
      },
      {
        title: 'Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
        editable: false,
        width: '20%',
        sorter: (a, b) => a.capacity - b.capacity,
        render: (value, record, index) => <PercentageBar value={value} />,
      },
      {
        title: 'Velocity',
        dataIndex: 'velocity',
        key: 'velocity',
        editable: false,
        width: '20%',
        sorter: (a, b) => a.velocity - b.velocity,
        render: (value, record, index) => <PercentageBar value={value} />,
      },
      {
        title: 'Schedule State',
        dataIndex: 'scheduleState',
        width: '40%',
        render: (value, record, index) => MultiProgress(value),
      },
    ],
    [page, pageSize, titleName]
  );

  const detailColumns = useMemo<EditableColumn<GroupedTeamReportDetail>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: titleName,
        dataIndex: 'name',
        key: 'name',
        editable: false,
        width: '19%',
        sorter: () => 1,
        render: (value, record) => renderLink(value, record),
      },
      {
        title: 'User Stories Carried Over',
        dataIndex: 'userStoriesCO',
        key: 'us',
        sorter: (a, b) => a.userStoriesCO - b.userStoriesCO,
        render: (value, record, index) => value,
        width: '8%',
        align: 'right',
      },
      {
        title: 'Defects Carried Over',
        dataIndex: 'defectsCO',
        key: 'defects',
        sorter: (a, b) => a.defectsCO - b.defectsCO,
        render: (value, record, index) => value,
        width: '8%',
        align: 'right',
      },
      {
        title: 'Total Capacity',
        dataIndex: 'totalCapacity',
        key: 'capacity',
        sorter: (a, b) => a.totalCapacity - b.totalCapacity,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
      {
        title: 'Estimate Capacity',
        dataIndex: 'estimateCapacity',
        key: 'estimate',
        sorter: (a, b) => a.estimateCapacity - b.estimateCapacity,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
      {
        title: 'Actual Capacity',
        dataIndex: 'actualCapacity',
        key: 'actual',
        sorter: (a, b) => a.actualCapacity - b.actualCapacity,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
      {
        title: 'Accepted points',
        dataIndex: 'acceptedPoint',
        key: 'accepted',
        sorter: (a, b) => a.acceptedPoint - b.acceptedPoint,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
      {
        title: 'Total points',
        dataIndex: 'totalPoint',
        key: 'total',
        sorter: (a, b) => a.totalPoint - b.totalPoint,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
      {
        title: 'Idea',
        dataIndex: 'idea',
        key: 'idea',
        sorter: (a, b) => a.idea - b.idea,
        render: (value, record, index) => renderCardBelowCompleted(value),
        width: '5%',
        align: 'right',
      },
      {
        title: 'Defined',
        dataIndex: 'defined',
        key: 'defined',
        sorter: (a, b) => a.defined - b.defined,
        render: (value, record, index) => renderCardBelowCompleted(value),
        width: '5%',
        align: 'right',
      },
      {
        title: 'In Progress',
        dataIndex: 'inprogress',
        key: 'inProgress',
        sorter: (a, b) => a.inprogress - b.inprogress,
        render: (value, record, index) => renderCardBelowCompleted(value),
        width: '6%',
        align: 'right',
      },
      {
        title: 'Completed',
        dataIndex: 'completed',
        key: 'completed',
        sorter: (a, b) => a.completed - b.completed,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
      {
        title: 'Accepted',
        dataIndex: 'accepted',
        key: 'accepted',
        sorter: (a, b) => a.accepted - b.accepted,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
      {
        title: 'Released',
        dataIndex: 'released',
        key: 'released',
        sorter: (a, b) => a.released - b.released,
        render: (value, record, index) => value,
        width: '6%',
        align: 'right',
      },
    ],
    [page, pageSize, titleName]
  );

  const [currentColumn, setCurrentColumn] = useState([]);
  useEffect(() => {
    if (props?.currentTab === 'Overview') {
      setCurrentColumn(overviewColumns);
      setId('iteration-overview-table');
      setData(overviewData);
    }
    if (props?.currentTab === 'Detail') {
      setCurrentColumn(detailColumns);
      setId('iteration-detail-table');
      setData(detailData);
    }
  }, [props, page, pageSize, detailData, overviewData]);

  return (
    <>
      <EditableTable
        className="iteration-detail-table"
        pageSize={pageSize}
        current={page}
        data={data}
        columns={currentColumn}
        showAction={false}
        onChange={onChange}
        loading={loading}
        id={id}
      />
    </>
  );
};

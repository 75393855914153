export enum WeeklyIssueTypeColour {
  Default,
  Good,
  Risk,
  Critical,
}

export enum WeeklyIssueTypeTitle {
  Resource,
  Delivery,
  Quality,
  Process,
  Cost,
}

export class WeeklyStatusPortfoliSection {
  portfoliosTitle: string = '';
  developmentTeams: DevelopmentTeamAndIssueData[] = [];
}

export class ChartWeeklyStatus {
  teams: number = 0;
  weekNum: number = 0;
  type: WeeklyIssueTypeColour = WeeklyIssueTypeColour.Default;
}

export class DevelopmentTeamAndIssueData {
  id: number = 0;
  name: string = '';
  type: WeeklyIssueTypeColour = WeeklyIssueTypeColour.Default;
  tags: TagData[] = [];
  cause: string = '';
  action: string = '';
  highlight: string = '';
}

export class TagData {
  type: WeeklyIssueTypeColour = WeeklyIssueTypeColour.Default;
  typeTitle: WeeklyIssueTypeTitle = WeeklyIssueTypeTitle.Delivery;
  numberOfIssues?: number;
}

export class WeeklyOverviewData {
  portfolioDevelopmentTeams: WeeklyStatusPortfoliSection[] = [];
  chartWeeklyStatus: ChartWeeklyStatus[] = [];
  highlight: string = '';
  totalCritical: number = 0;
  totalRisk: number = 0;
  totalGood: number = 0;
}

export interface WeeklyOverViewTotals {
  critical: number;
  risk: number;
  good: number;
}

export class TeamIssueOverviewMiniCard {
  teamWeeklyStatusId: number = 0;
  reportDate: Date = new Date();
  weekNum: number = 0;
  color: WeeklyIssueTypeColour = WeeklyIssueTypeColour.Default;
}

export interface WeekReponse {
  id: number;
  weekNum: number;
  weekStart: Date;
  weekEnd: Date;
}
export interface WeekOption {
  label: string;
  value: number;
}

export interface IssueResponse {
  id: number;
  name: string;
  type: TagData;
  cause: string;
  action: string;
  highlight: string;
  teamId: number;
  reportDate: Date;
  teamWeeklyStatusId: number;
}

export interface TeamDetail {
  id: number;
  name: string;
  issues: IssueResponse[];
}

export interface HighlightRequest {
  highlight: string;
  reportDate: Date;
}

export interface FilterStatus {
  critical: boolean;
  risk: boolean;
  good: boolean;
}

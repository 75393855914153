import styled from 'styled-components';
export const StyledTestCoverage = styled.div`
  .tc-container {
    width: 100%;
    display: flex;
    align-item: center;
    justify-content: center;
    margin-top: 26px;
  }
`;

import {
  IssueResponse,
  TeamDetail,
  TeamIssueOverviewMiniCard,
  WeeklyIssueTypeColour,
  WeeklyIssueTypeTitle,
} from 'app/types/WeeklyOverview';
import { Button, Divider, Form, Popconfirm, Row } from 'antd';
import { ColorCircle, TeamWeeklyStatusIssueDetailWrapper } from './TeamWeeklyStatusIssueDetail.styled';
import {
  CriticalStatusColor,
  DefaultStatusColor,
  GoodStatusColor,
  RiskStatusColor,
  roleNames,
} from 'app/common/constants';
import { useContext, useEffect, useState } from 'react';
import { TeamWeeklyStatusForm } from '../TeamWeeklyStatusForm/TeamWeeklyStatusForm';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { removeTeamIssueForWeek } from 'app/apis/reportClient';
import { AuthContext } from 'app/contexts/AuthContext';

interface IProps {
  data: IssueResponse;
  resetForm: boolean;
  setResetForm: (reset: boolean) => void;
  setIsEditIssue: (isEdit: boolean) => void;
  isEditIssue: boolean;
  setIsCreateIssue: (isCreate: boolean) => void;
  currentWeek: TeamIssueOverviewMiniCard;
  selectedTeamCardData: TeamDetail;
}

export const TeamWeeklyStatusIssueDetail = ({
  data,
  resetForm,
  setResetForm,
  setIsEditIssue,
  isEditIssue,
  currentWeek,
  setIsCreateIssue,
  selectedTeamCardData,
}: IProps) => {
  const { user } = useContext(AuthContext);
  const [isEditThisForm, setIsEditThisForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  let issueType = WeeklyIssueTypeTitle[data?.type?.typeTitle];
  useEffect(() => {
    setIsEditThisForm(false);
  }, [resetForm]);

  const onRemoveIssue = async () => {
    if (data) {
      try {
        setLoading(true);
        await removeTeamIssueForWeek(data.id);
        setLoading(false);
        setResetForm(!resetForm);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  const renderSimpleParagraph = (text: string) => {
    const list = text.split('\n');
    return (
      <div>
        {list.map((line, i) => (
          <p key={i} className="line-content">
            {line}
          </p>
        ))}
      </div>
    );
  };

  if (isEditThisForm)
    return (
      <TeamWeeklyStatusIssueDetailWrapper span={24}>
        <Row>
          <TeamWeeklyStatusForm
            initData={data}
            setIsEditIssue={setIsEditIssue}
            setIsCreateIssue={setIsCreateIssue}
            setResetForm={setResetForm}
            resetForm={resetForm}
            currentWeek={currentWeek}
            selectedTeamCardData={selectedTeamCardData}
          />
        </Row>
        <Row>
          <Button
            className="btn cancel"
            type="default"
            onClick={() => {
              setIsEditIssue(false);
              setIsEditThisForm(false);
            }}
          >
            Cancel
          </Button>
          <Button className="btn" type="primary" htmlType="submit" form="TeamWeeklyStatusForm">
            Save
          </Button>
        </Row>
        <Divider className="divider" />
      </TeamWeeklyStatusIssueDetailWrapper>
    );
  return (
    <TeamWeeklyStatusIssueDetailWrapper span={24}>
      {/* Using the form component to make sure data display is the same as when editting.
          Just for rendering */}
      <Form {...layout} colon={false} labelAlign="left">
        <Form.Item label="Issue">{data?.name}</Form.Item>
        <Form.Item label="Type">
          {data.type && (
            <Row align="middle" gutter={5}>
              <ColorCircle
                color={
                  data?.type?.type === WeeklyIssueTypeColour.Critical
                    ? CriticalStatusColor
                    : data.type.type === WeeklyIssueTypeColour.Risk
                    ? RiskStatusColor
                    : data.type.type === WeeklyIssueTypeColour.Good
                    ? GoodStatusColor
                    : DefaultStatusColor
                }
              />
              <span>{issueType.slice(0, 1).toLocaleUpperCase() + issueType.substring(1)}</span>
            </Row>
          )}
        </Form.Item>
        <Form.Item label="Root Cause">{renderSimpleParagraph(data?.cause)}</Form.Item>
        <Form.Item label="Action">{renderSimpleParagraph(data?.action)}</Form.Item>
        <Form.Item label="Highlight" style={{ marginBottom: 0 }} />
        <p>{renderSimpleParagraph(data?.highlight)}</p>
      </Form>
      {!isEditThisForm && !isEditIssue && (user.role === roleNames.admin || user.role === roleNames.pqa) && (
        <Row>
          <Button
            className="link-btn"
            type="link"
            onClick={() => {
              // open the form in edit state
              setIsEditIssue(true);
              setIsEditThisForm(true);
            }}
          >
            Edit <EditFilled />
          </Button>
          <Popconfirm
            className="popup"
            title="Are you sure to delete this issue?"
            onConfirm={onRemoveIssue}
            okText="Yes"
            cancelText="No"
            okType="primary"
          >
            <Button className="link-btn" type="link" danger loading={loading}>
              Delete <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Row>
      )}

      <Divider className="divider" />
    </TeamWeeklyStatusIssueDetailWrapper>
  );
};

import { Anchor, Col, Row, Button, Modal, Spin } from 'antd';
import { ReleaseNoteWrapper } from './ReleaseNote.styled';
import { useFetch } from 'app/hooks/useFetch';
import { getReleaseNotes } from 'app/apis/releaseNote';
import { IReleaseNoteResponse } from 'app/types/ReleaseNoteModel';
import { useContext, useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { getEditorState } from 'app/components/IssueReportIdea/IssueReportIdeaHandle';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { roleNames } from 'app/common/constants';
import { UserContext } from 'app/contexts/UserContext';
import { bindingReleaseNoteData } from 'app/components/ReleaseNote/ReleaseNoteHelper';
import { ReleaseNoteFormModal } from 'app/components/ReleaseNote/ReleaseNoteFormModal';
import { IReleaseNoteItemResponse } from 'app/types/ReleaseNoteItemModel';
import { ReleaseNoteItemList } from 'app/components/ReleaseNote/ReleaseNoteItemList';
import { TeamContext } from 'app/contexts/TeamContext ';
import { Helmet } from 'react-helmet';

const { Link } = Anchor;

export enum formAction {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}

export function ReleaseNote() {
  const [listData, setListData] = useState<IReleaseNoteResponse[]>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [modalState, setModalState] = useState(false);
  const [typeModal, setTypeModal] = useState(formAction.Create);
  const [formReleaseNote] = useForm();
  const [targetOffset, setTargetOffset] = useState<number | undefined>(1);
  const { setBreadcrumbs } = useContext(TeamContext);

  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'About ADM',
        parentCrumb: null,
        renderAsLink: false,
      },
      {
        path: '#',
        text: 'Release Note',
        parentCrumb: null,
        renderAsLink: true,
      },
    ]);
  }, [setBreadcrumbs]);
  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);
  // Handle Upload Image
  const [releaseNoteItemFormData, setReleaseNoteItemFormData] = useState<IReleaseNoteItemResponse>({
    id: 0,
    name: '',
    type: 0,
    detail: '',
    creatorUserId: 0,
    creationTime: null,
    lastModificationTime: null,
    lastModifierUserId: 0,
    releaseNoteItemImages: [],
    typeText: '',
  });
  const { confirm } = Modal;
  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const isAllowed = userRole === roleNames.admin;

  const { data: releaseNotes } = useFetch<IReleaseNoteResponse[]>(() => {
    return getReleaseNotes();
  });

  useEffect(() => {
    if (releaseNotes != undefined) {
      releaseNotes.map((item) => {
        bindingReleaseNoteData(item);
      });
    }
    setListData(releaseNotes);
  }, [releaseNotes]);

  useEffect(() => {
    if (typeModal === formAction.Create) {
      if (releaseNoteItemFormData.id > 0) {
        setListData((prev) =>
          prev.map((item) =>
            item.id === releaseNoteItemFormData.releaseNoteId
              ? {
                  ...item,
                  releaseNoteItems: [...item.releaseNoteItems, releaseNoteItemFormData].sort((a, b) => b.id - a.id),
                }
              : item
          )
        );
      }
    } else if (typeModal === formAction.Edit) {
      setListData((prev) =>
        prev.map((item) =>
          item.id === releaseNoteItemFormData.releaseNoteId
            ? {
                ...item,
                releaseNoteItems: item.releaseNoteItems.map((child) =>
                  child.id === releaseNoteItemFormData.id
                    ? {
                        ...child,
                        id: releaseNoteItemFormData.id,
                        name: releaseNoteItemFormData.name,
                        type: releaseNoteItemFormData.type,
                        detail: releaseNoteItemFormData.detail,
                        releaseNoteItemImages: releaseNoteItemFormData.releaseNoteItemImages
                          ? releaseNoteItemFormData.releaseNoteItemImages
                          : [],
                        releaseNoteId: releaseNoteItemFormData.releaseNoteId,
                      }
                    : child
                ),
              }
            : item
        )
      );
    }
  }, [releaseNoteItemFormData]);

  const showModal = (formAct: formAction, formData: any) => {
    setModalState(true);
    if (formAct === formAction.Edit) {
      setTypeModal(formAction.Edit);
      formReleaseNote.setFieldsValue({
        id: formData.id,
        name: formData.name,
        releaseDate: moment(formData.releaseDate),
      });
      // Convert formData.detail to EditorState which editor can read and display it.
      const newEditorState = getEditorState(formData.description);
      setEditorState(newEditorState);
    } else {
      setTypeModal(formAction.Create);
      formReleaseNote.resetFields();
    }
  };

  const [typePrefixIcon, setTypePrefixIcon] = useState(null);
  return (
    <>
      <ReleaseNoteWrapper>
        <Helmet>
          <title>ADM Tool | Release Note</title>
        </Helmet>
        {listData && listData.length > 0 ? (
          <>
            <Row gutter={[0, 24]}>
              <Col span={14}>
                <span className="title" style={{ color: '#0E50A4' }}>
                  RELEASE NOTE
                </span>
              </Col>
              {isAllowed ? (
                <Col span={24}>
                  <div style={{ float: 'right', marginBottom: '20px' }}>
                    <Button
                      type="primary"
                      className="btn-add"
                      onClick={() => showModal(formAction.Create, { name: '', releaseDate: '' })}
                    >
                      Create Release
                    </Button>
                  </div>
                  <ReleaseNoteFormModal
                    typeModal={typeModal}
                    setTypeModal={setTypeModal}
                    setTypePrefixIcon={setTypePrefixIcon}
                    listData={listData}
                    setListData={setListData}
                    confirm={confirm}
                    formReleaseNote={formReleaseNote}
                    modalState={modalState}
                    setModalState={setModalState}
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                </Col>
              ) : (
                <Col span={24} style={{ height: '30px' }}>
                  {' '}
                </Col>
              )}
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={3}>
                <div style={{ width: '72%', position: 'relative' }}>
                  <Anchor targetOffset={targetOffset}>
                    {listData
                      ?.sort((a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime())
                      .map((item) => {
                        return <Link key={item.id} href={'#note-' + item.id} title={item.name} />;
                      })}
                  </Anchor>
                </div>
              </Col>
              <Col span={21}>
                {listData
                  ?.sort((a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime())
                  .map((item) => {
                    return (
                      <ReleaseNoteItemList
                        key={item.id}
                        releaseNoteItem={item}
                        confirm={confirm}
                        releaseNoteItemFormData={releaseNoteItemFormData}
                        setReleaseNoteItemFormData={setReleaseNoteItemFormData}
                        typeModal={typeModal}
                        setTypeModal={setTypeModal}
                        typePrefixIcon={typePrefixIcon}
                        setTypePrefixIcon={setTypePrefixIcon}
                        editorState={editorState}
                        setEditorState={setEditorState}
                        listData={listData}
                        setListData={setListData}
                        isAllowed={isAllowed}
                        onShowModal={(formAct: formAction, formData: any) => showModal(formAct, formData)}
                      />
                    );
                  })}
              </Col>
            </Row>
          </>
        ) : (
          <Spin />
        )}
      </ReleaseNoteWrapper>
    </>
  );
}

export default ReleaseNote;

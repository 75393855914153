import {
  DefectChartByCategoryResponse,
  DefectChartByStateResponse,
  IIterationOverviewRequest,
  IIterationOverviewResponse,
} from 'app/types/entity';
import { fetchAsync } from 'app/utils/fetch';

export const getIterationsList = (): Promise<string[]> => {
  return fetchAsync('/api/iterations/listnames', { method: 'GET' });
};

export const getIterationsListNotFuture = (): Promise<string[]> => {
  return fetchAsync('/api/iterations/listnamesnotfuture', { method: 'GET' });
};

export const getIterationOverview = (
  iterationRequest: IIterationOverviewRequest
): Promise<IIterationOverviewResponse> =>
  fetchAsync(`/api/iterations/${iterationRequest?.iterationName}/teamOverview`, { method: 'GET' });

export const getDefectChartByState = (teamId: number): Promise<DefectChartByStateResponse> => {
  return fetchAsync(`/api/defectchart/${teamId}`, { method: 'GET' });
};

export const getDefectChartByCategory = (teamId: number): Promise<DefectChartByCategoryResponse> => {
  return fetchAsync(`/api/defectchart/getdefectchartbcategory/${teamId}`, { method: 'GET' });
};

import { useState } from 'react';
import { Button, Form, notification, Popover } from 'antd';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { SyncTeamModel } from 'app/types/SystemMonitoring';
import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import moment from 'moment';
import { StyledSyncNowButton } from './SyncButtonStyled';
import { SyncStatus } from 'app/common/constants';

interface IProps {
  renderStyle: 'link' | 'button';
  showSyncSpecificDate: boolean;
  syncStatus: string;
  syncAction: (syncDate: Date) => void;
}

export const SyncButton = ({ showSyncSpecificDate, renderStyle, syncAction, syncStatus }: IProps) => {
  const dateFormat = 'DD/MM/YYYY';
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [disableBtnSync, setDisableBtnSync] = useState(false);
  const [syncDate, setSyncDate] = useState<SyncTeamModel>({ syncDate: moment().toDate() });

  const onClickHandler = () => {
    try {
      setShowSyncModal((oldValue) => showSyncSpecificDate && !oldValue);

      !showSyncSpecificDate && syncAction(syncDate.syncDate); //trigger sync immediately if no need to choose sync date
    } catch (error) {
      setShowSyncModal(false);
      const err = error.data as ErrorResponse;
      notification.error({
        message: err?.error?.message ?? error.message ?? 'Unknown error',
        duration: 2,
      });
    }
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      setDisableBtnSync(false);
      setSyncDate({ syncDate: date.toDate() });
    } else {
      setDisableBtnSync(true);
    }
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setDisableBtnSync(newVisible);
  };

  return (
    <>
      <Popover
        visible={showSyncModal}
        placement="leftTop"
        trigger="click"
        onVisibleChange={() => handleVisibleChange}
        content={
          <>
            <Form>
              <Form.Item style={{ marginBottom: '0px' }} rules={[{ required: true, message: 'Please choose date' }]}>
                <span style={{ marginRight: '30px' }}>Date </span>
                <DatePicker onChange={onChange} defaultValue={moment()} format={dateFormat} />
              </Form.Item>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
              <Button key="back" size="middle" onClick={() => setShowSyncModal(false)}>
                Cancel
              </Button>

              <Button
                key="submit"
                type="primary"
                size="middle"
                onClick={() => {
                  syncAction(syncDate.syncDate);
                  setShowSyncModal(false);
                }}
                disabled={disableBtnSync}
              >
                Sync Now
              </Button>
            </div>
          </>
        }
      >
        {renderStyle === 'link' ? (
          <Button type="link" loading={syncStatus === SyncStatus.INPROGRESS} onClick={onClickHandler}>
            {syncStatus !== SyncStatus.INPROGRESS ? 'Sync Now' : 'Syncing...'}
          </Button>
        ) : (
          <StyledSyncNowButton
            className="ant-btn create-button edit"
            loading={syncStatus === SyncStatus.INPROGRESS}
            onClick={onClickHandler}
          >
            {syncStatus !== SyncStatus.INPROGRESS ? 'Sync Now' : 'Syncing...'}
          </StyledSyncNowButton>
        )}
      </Popover>
    </>
  );
};

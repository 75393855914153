import styled from 'styled-components';
export const ReportStatusWrapper = styled.div`
  $border-color: #d9d9d9;
  $border: 0.5px solid;
  $section-padding: 1.5em;

  .report-section {
    border: $border $border-color;
    padding: $section-padding;
    border-radius: 5px;
    .btn-tab {
      border-radius: 0 !important;
      padding: 0;
      width: 100%;
      height: 39px;
      float: right;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #0e50a4;
    }
    .report-filter {
      width: 100%;
      .ant-select-selector {
        .ant-select-selection-placeholder {
          font-style: italic;
        }
      }
    }
    .ant-table-small .ant-table-thead > tr > th {
      background-color: #d9d9d9;
      font-weight: bolder;
      .ant-dropdown-trigger.ant-table-filter-trigger {
        color: #292525;
      }
      .ant-dropdown-trigger.ant-table-filter-trigger.active {
        color: #1890ff;
      }
      .ant-table-column-sorter.ant-table-column-sorter-full {
        color: #292525;
      }
    }
    .anticon.anticon-pushpin.rightpin {
      float: right;
      color: #f3c300;
      margin-top: 0.35em;
      svg:hover {
        fill: #d3c074;
        transform: translateY(-0.2em);
        cursor: pointer;
      }
    }
  }
`;

import styled from 'styled-components';

export const StatusDetailWrapper = styled.div`
  width: 100%;
  .weekly-overview-group {
    margin-top: 2rem;
    &:first-child {
      margin-top: 0;
    }
    .manheim-card:hover {
      cursor: pointer;
    }
  }
`;

import { Button, Checkbox, Col, Form, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useForm } from 'antd/lib/form/Form';
import { DisplayColOptions, StakeholdersResponse } from '../../types/Stakeholders';
import { EditableColumn } from '../EditableTable';
interface IProps {
  displayColumns: EditableColumn<StakeholdersResponse>[];
  onCloseModal: () => void;
  setDisplayColumns: React.Dispatch<React.SetStateAction<EditableColumn<StakeholdersResponse>[]>>;
}
export const DisplaySettingForm = ({ displayColumns, onCloseModal, setDisplayColumns }: IProps) => {
  const [form] = useForm();
  const onChangeCheckbox = (e: CheckboxChangeEvent) => {};
  const handleSubmit = (formValues) => {
    const updatedDisplayColumns = displayColumns?.map((it) => {
      return {
        ...it,
        visible: formValues[it.key],
      };
    });
    setDisplayColumns(updatedDisplayColumns);
    onCloseModal();
  };

  const isCheck = (value): boolean => {
    return displayColumns?.find((x) => x.key === value)?.visible;
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit}>
        <Row style={{ marginLeft: '50px', marginBottom: '10px' }}>
          {DisplayColOptions?.map((item, index) => (
            <Col span={12} key={index}>
              <Form.Item name={item?.value} key={index} valuePropName="checked" initialValue={isCheck(item?.value)}>
                <Checkbox onChange={onChangeCheckbox}>{item.lable}</Checkbox>
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Form.Item wrapperCol={{ span: 24 }}>
            <div className="form-footer">
              <Button
                type="default"
                formAction=""
                onClick={() => {
                  onCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button key="submit" type="primary" formAction="" htmlType="submit">
                Apply
              </Button>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

import { IReleaseNoteResponse, IReleaseNoteRequest } from 'app/types/ReleaseNoteModel';
import { fetchAsync } from 'app/utils/fetch';

export const getReleaseNotes = (): Promise<IReleaseNoteResponse[]> => {
  const url = '/api/release-note/getall';
  return fetchAsync(url, { method: 'GET' });
};

export const createReleaseNote = (payload: IReleaseNoteRequest): Promise<IReleaseNoteResponse> => {
  const url = '/api/release-note';
  return fetchAsync(url, { method: 'POST', body: payload });
};

export const editReleaseNote = (payload: IReleaseNoteRequest): Promise<IReleaseNoteResponse> => {
  const url = '/api/release-note';
  return fetchAsync(url, { method: 'PUT', body: payload });
};

export const deleteReleaseNote = (id: number): Promise<IReleaseNoteResponse> => {
  const url = `api/release-note/${id}`;
  return fetchAsync(url, { method: 'DELETE' });
};

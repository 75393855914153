import styled from 'styled-components';

export const OverviewNoteWrapper = styled.div`
  &.m-t {
    margin-top: 1rem;
  }
  & .notes {
    white-space: pre-line;
    overflow-wrap: break-word;
    text-align: justify;
  }
`;

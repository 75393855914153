import styled from 'styled-components';

export const StyleRecognitionCard = styled.div`
  height: 100%;
  &:hover {
    .recognition-feedback {
      opacity: 1;
    }
  }
  .recognition-card {
    height: 100%;
    position: relative;
  }
  img {
    height: 100%;
    object-fit: contain;
  }

  .ant-card-cover {
    height: 270px;
    justify-content: center;
    vertical-align: middle;
  }

  .title {
    overflow: clip;
    :hover {
      overflow: visible;
      overflow-wrap: break-word;
    }
  }

  .recognition-feedback {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    color: white;
    transition: 0.5s ease;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
  }
  .recognition-feedback-content {
    inline-size: 90%;
    overflow-wrap: break-word;
    overflow: visible;
  }
`;

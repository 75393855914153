import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { roleNames, TABLE_DEFAULT_PAGE_SIZE } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { createStakeholders, fetchAllStakeholders, updateStakeholders } from 'app/apis/teamDetailClient';
import { Button, Col, Form, Input, notification, Row, Select, Switch, TablePaginationConfig } from 'antd';
import { Stage } from 'app/types/TeamDetailTypes';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { SubPage } from 'app/types/TeamDetail';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { UserContext } from 'app/contexts/UserContext';
import _, { values } from 'lodash';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { useFetch } from 'app/hooks/useFetch';
import { TeamContext } from 'app/contexts/TeamContext ';
import { StyledModal } from 'app/pages/ManageUsers/ManagedUsers.styled';
import TextArea from 'antd/lib/input/TextArea';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { DemoPlanWrapper } from '../DemoPlan/DemoPlan.styled';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import Title from 'antd/lib/typography/Title';
import { SelectOptions } from 'app/types/entity';
import { StakeholdersRequest, StakeholdersResponse } from 'app/types/Stakeholders';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { SettingOutlined } from '@ant-design/icons';
import { DisplaySettingForm } from './DisplaySettingForm';

const BooleanSelectOptions: SelectOptions<boolean>[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
const SiteSelectOptions: SelectOptions<string>[] = [
  {
    label: '-SELECT-',
    value: null,
  },
  {
    label: 'FPT',
    value: 'FPT',
  },
  {
    label: 'Cox Automotive',
    value: 'Cox Automotive',
  },
];
const LocationSelectOptions: SelectOptions<string>[] = [
  {
    label: '-SELECT-',
    value: null,
  },
  {
    label: 'VN',
    value: 'VN',
  },
  {
    label: 'US',
    value: 'US',
  },
];
const RoleSelectOptions: SelectOptions<string>[] = [
  {
    label: '-SELECT-',
    value: null,
  },
  {
    label: 'BA',
    value: 'BA',
  },
  {
    label: 'TA',
    value: 'TA',
  },
  {
    label: 'DEV',
    value: 'DEV',
  },
  {
    label: 'QA',
    value: 'QA',
  },
  {
    label: 'DBA',
    value: 'DBA',
  },
  {
    label: 'TA',
    value: 'TA',
  },
  {
    label: 'SRE',
    value: 'SRE',
  },
  {
    label: 'TSL',
    value: 'TSL',
  },
  {
    label: 'TL',
    value: 'TL',
  },
];

interface IProps {
  updateChartProductivity: boolean;
  setUpdateChartProductivity: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Stakeholders = ({ setUpdateChartProductivity, updateChartProductivity }: IProps) => {
  const { isAuthorized } = useAuthz();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [stakeholders, setStakeholders] = useState<StakeholdersResponse[]>();
  const { stage: currentStage, selectedIteration, reportStatus } = useContext(IterationContext);
  const { handleComponentClass } = useContext(DisplayComponentsContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [isOpenSettingPopup, setIsOpenSettingPopup] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { team } = useContext(TeamDetailContext);
  const {
    user: { userRole, email },
  } = useContext(UserContext);

  const stakeholdersCopy = useRef<StakeholdersResponse[]>([]);

  const [isExternalTeamValue, setIsExternalTeamValue] = useState<boolean>(true);
  const [isOffboardValue, setIsOffboardValue] = useState(true);
  const [isCountProductivity, setIsCountProductivity] = useState(false);
  const [siteValue, setSiteValue] = useState<string>(null);
  const [locationValue, setLocationValue] = useState<string>(null);
  const [roleValue, setRoleValue] = useState<string>(null);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const { data: stakeholdersData } = useFetch(
    () =>
      fetchAllStakeholders(team.id).then((items) => {
        setStakeholders(items);
        stakeholdersCopy.current = items;
        return items;
      }),
    [currentStage, reportStatus, page, selectedIteration]
  );
  const oldCount = stakeholdersData?.reduce((count, it) => {
    if (it.isCountProductivity && !it.isExternalTeam && !it.isOffboard) {
      count++;
    }
    return count;
  }, 0);
  const checkUpdateChartProductivity = (oldCount, newCount) => {
    if (newCount !== oldCount) {
      return true;
    }
    return false;
  };

  const isEdit =
    (currentStage !== Stage.Planned && page === SubPage.Plan) ||
    (reportStatus !== IterationReportStatusEnum.Submitted && page === SubPage.Report) ||
    page === SubPage.Monitoring;
  // authen
  const isAllowed = userRole === roleNames.admin || email === team?.scrumMaster?.email;

  const openNotification = (statusCode: number, message: string) => {
    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 3,
    });
  };
  const onCreateStakeholdersItem = async (values) => {
    try {
      let stakeholdersRequest: StakeholdersRequest = {
        teamId: team.id,
        note: values.note,
        name: values.name,
        role: values.role,
        location: values.location,
        site: values.site,
        isExternalTeam: values.isExternalTeam,
        isOffboard: values.isOffboardValue,
        isCommitted: values.isCommitted,
        isCountProductivity: values.isCountProductivity,
      };
      const resp = await createStakeholders(stakeholdersRequest);

      if (resp) {
        const newCount = [...stakeholders, resp]?.reduce((count, it) => {
          if (it.isCountProductivity && !it.isExternalTeam && !it.isOffboard) {
            count++;
          }
          return count;
        }, 0);
        if (checkUpdateChartProductivity(oldCount, newCount)) {
          setUpdateChartProductivity(!updateChartProductivity);
        }
        setStakeholders([...stakeholders, resp]);
        stakeholdersCopy.current.push(resp);
        setToggleModal(false);
      }
    } catch (error) {
      openNotification(error.status, error?.data?.error?.message);
    }
    setIsExternalTeamValue(true);
    setIsOffboardValue(true);
    setIsCountProductivity(false);
    setSiteValue(null);
    setLocationValue(null);
    setRoleValue(null);
  };

  const columns = useMemo<EditableColumn<StakeholdersResponse>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: true,
        width: '1000px',
        align: 'left',
        visible: true,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sorter: (a: any, b: any) => 1,
        editable: true,
        visible: true,
        width: '300px',
        inputType: 'select',
        options: RoleSelectOptions,
        align: 'center',
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        filters: LocationSelectOptions?.filter((it) => it.value !== null)?.map((it) => {
          return {
            text: it.value === 'US' ? 'United State' : 'VietNam',
            value: it.value,
          };
        }),
        onFilter: (value: string, record) => record.location.includes(value),
        editable: true,
        visible: true,
        width: '300px',
        inputType: 'select',
        options: LocationSelectOptions,
        align: 'center',
      },
      {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
        filters: SiteSelectOptions?.filter((it) => it.value !== null)?.map((it) => {
          return {
            text: it.label,
            value: it.value,
          };
        }),
        onFilter: (value: string, record) => record.site.includes(value),
        editable: true,
        visible: true,
        width: '600px',
        inputType: 'select',
        options: SiteSelectOptions,
        align: 'center',
      },
      {
        title: 'Include In Productivity',
        dataIndex: 'isCountProductivity',
        key: 'isCountProductivity',
        editable: true,
        visible: true,
        filters: BooleanSelectOptions?.map((it) => {
          return {
            text: it.label,
            value: it.value,
          };
        }),
        onFilter: (value: boolean, record) => {
          return value ? record.isCountProductivity : !record.isCountProductivity;
        },
        align: 'center',
        width: '300px',
        render(value, record, index) {
          return value ? 'Yes' : 'No';
        },
        inputType: 'select',
        options: BooleanSelectOptions,
      },
      {
        title: 'Is External Team?',
        dataIndex: 'isExternalTeam',
        key: 'isExternalTeam',
        visible: false,
        editable: true,
        filters: BooleanSelectOptions?.map((it) => {
          return {
            text: it.label,
            value: it.value,
          };
        }),
        onFilter: (value: boolean, record) => {
          return value ? record.isExternalTeam : !record.isExternalTeam;
        },
        align: 'center',
        width: '300px',
        render(value, record, index) {
          return value ? 'Yes' : 'No';
        },
        inputType: 'select',
        options: BooleanSelectOptions,
      },
      {
        title: 'Is Offboard?',
        dataIndex: 'isOffboard',
        width: '300px',
        filters: BooleanSelectOptions?.map((it) => {
          return {
            text: it.label,
            value: it.value,
          };
        }),
        onFilter: (value: boolean, record) => {
          return value ? record.isOffboard : !record.isOffboard;
        },
        key: 'isOffboard',
        visible: false,
        editable: true,
        render(value, record, index) {
          return value ? 'Yes' : 'No';
        },
        inputType: 'select',
        options: BooleanSelectOptions,
        align: 'center',
      },
      {
        title: 'Notes',
        dataIndex: 'note',
        key: 'note',
        editable: true,
        visible: true,
        width: '1100px',
        align: 'left',
        rules: [{ max: 200, message: 'The field Note must be a string with a maximum length of 200' }],
      },
    ],
    [page, pageSize, selectedIteration]
  );
  const [displayColumns, setDisplayColumns] = useState<EditableColumn<StakeholdersResponse>[]>(columns);

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const onSwitch = (checked: boolean) => {
    let expectedStakeholders = checked ? stakeholdersCopy.current : stakeholders.filter((e) => !e.isOffboard);
    setStakeholders(expectedStakeholders);
  };

  const onSave = async (id: number, stakeholder: StakeholdersResponse) => {
    try {
      const stakeholderRequest: StakeholdersRequest = {
        teamId: stakeholder.teamId,
        name: stakeholder.name,
        isCommitted: stakeholder.isCommitted,
        isExternalTeam: stakeholder.isExternalTeam,
        isOffboard: stakeholder.isOffboard,
        isCountProductivity: stakeholder.isCountProductivity,
        location: stakeholder.location,
        note: stakeholder.note,
        role: stakeholder.role,
        site: stakeholder.site,
      };
      await updateStakeholders(id, stakeholderRequest);
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });

      const newStakeholdersData = stakeholders.map((item) => {
        if (item.id === id) {
          item.note = stakeholder.note;
          item.isExternalTeam = stakeholder.isExternalTeam;
          item.isOffboard = stakeholder.isOffboard;
          item.location = stakeholder.location;
          item.name = stakeholder.name;
          item.site = stakeholder.site;
          item.role = stakeholder.role;
          item.isCountProductivity = stakeholder.isCountProductivity;
        }
        return item;
      });
      const newCount = newStakeholdersData?.reduce((count, it) => {
        if (it.isCountProductivity && !it.isExternalTeam && !it.isOffboard) {
          count++;
        }
        return count;
      }, 0);
      if (checkUpdateChartProductivity(oldCount, newCount)) {
        setUpdateChartProductivity(!updateChartProductivity);
      }
      setStakeholders(newStakeholdersData);
      stakeholdersCopy.current = newStakeholdersData;
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };
  const requiredValidationMessage = 'This field is required';

  return (
    <>
      <DemoPlanWrapper className={handleComponentClass('overview-stakeholders')}>
        <Row>
          <Col span={16} style={{ display: 'flex' }}>
            <PageTitle>
              <Title level={5}>STAKEHOLDERS</Title>
            </PageTitle>
            {isAllowed && (
              <div style={{ marginLeft: '20px', paddingTop: '3px' }}>
                <SettingOutlined
                  style={{ fontSize: '25px', color: '#09488a' }}
                  onClick={() => setIsOpenSettingPopup(true)}
                />
              </div>
            )}
          </Col>
          {isEdit && isAllowed && (
            <Col span={8} className="title-section-add-button">
              <Button
                data-html2canvas-ignore
                type="primary"
                onClick={() => {
                  form.resetFields();
                  setToggleModal(true);
                }}
                size="large"
              >
                Add
              </Button>
            </Col>
          )}
        </Row>
        <DeviderWrapper />
        {displayColumns?.find((it) => it.key === 'isOffboard').visible && (
          <Row>
            <Col>
              <Switch style={{ marginBottom: '10px' }} defaultChecked onChange={onSwitch} />
            </Col>
            <Col>
              <h1 style={{ marginBottom: '10px', marginLeft: '10px' }}>Display off-board people</h1>
            </Col>
          </Row>
        )}

        <EditableTable
          onChange={onChange}
          onSave={onSave}
          pageSize={pageSize}
          loading={loading}
          current={page}
          scroll={{ x: 992 }}
          isEdit={
            isAuthorized &&
            isAllowed &&
            (currentStage === Stage.Other ||
              currentStage === undefined ||
              (selectedIteration?.isCurrentSprint && currentStage === Stage.Planning))
          }
          columns={displayColumns}
          data={stakeholders}
        />
        <StyledModal
          title="ADD STAKEHOLDERS"
          visible={toggleModal}
          onCancel={() => setToggleModal(false)}
          width={700}
          centered
          footer={[
            <Button key="back" size="large" onClick={() => setToggleModal(false)}>
              Cancel
            </Button>,
            <Button key="submit" htmlType="submit" type="primary" size="large" form="stakeHolders">
              Submit
            </Button>,
          ]}
        >
          <Form
            {...layout}
            form={form}
            size={'middle'}
            onFinish={onCreateStakeholdersItem}
            name="stakeHolders"
            initialValues={{ ticket: '', personInCharge: '', note: '' }}
            autoComplete="off"
            colon={false}
            labelAlign="left"
          >
            <Form.Item label="Name" name="name" rules={[{ required: true, message: requiredValidationMessage }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Role" name="role" rules={[{ required: true, message: requiredValidationMessage }]}>
              <Select
                options={RoleSelectOptions}
                defaultValue={roleValue}
                value={roleValue}
                onChange={(value) => setRoleValue(value)}
              />
            </Form.Item>
            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true, message: requiredValidationMessage }]}
            >
              <Select
                options={LocationSelectOptions}
                defaultValue={locationValue}
                value={locationValue}
                onChange={(value) => setLocationValue(value)}
              />
            </Form.Item>
            <Form.Item label="Site" name="site" rules={[{ required: true, message: requiredValidationMessage }]}>
              <Select
                options={SiteSelectOptions}
                defaultValue={siteValue}
                value={siteValue}
                onChange={(value) => setSiteValue(value)}
              />
            </Form.Item>
            <Form.Item label="Is external team" name="isExternalTeam">
              <Select
                options={BooleanSelectOptions?.map((it) => {
                  return {
                    value: Number(it.value),
                    label: it.label,
                  };
                })}
                defaultValue={Number(isExternalTeamValue)}
                value={Number(isExternalTeamValue)}
                onChange={(value) => setIsExternalTeamValue(Boolean(value))}
              />
            </Form.Item>
            <Form.Item label="is Offboard" name="isOffboard">
              <Select
                options={BooleanSelectOptions?.map((it) => {
                  return {
                    value: Number(it.value),
                    label: it.label,
                  };
                })}
                defaultValue={Number(isOffboardValue)}
                value={Number(isOffboardValue)}
                onChange={(value) => setIsOffboardValue(Boolean(value))}
              />
            </Form.Item>
            <Form.Item label="Is Count productivity" name="isCountProductivity">
              <Select
                options={BooleanSelectOptions?.map((it) => {
                  return {
                    value: Number(it.value),
                    label: it.label,
                  };
                })}
                defaultValue={Number(isCountProductivity)}
                value={Number(isCountProductivity)}
                onChange={(value) => setIsCountProductivity(Boolean(value))}
              />
            </Form.Item>
            <Form.Item
              label="Note"
              name="note"
              rules={[{ max: 200, message: 'The field Note must be a string with a maximum length of 200' }]}
            >
              <TextArea autoSize={{ minRows: 4, maxRows: 6 }} className="text-note" />
            </Form.Item>
          </Form>
        </StyledModal>

        <StyledModal
          className="setting-display-columns-modal"
          title="DISPLAY COLUMNS"
          centered
          visible={isOpenSettingPopup}
          onCancel={() => setIsOpenSettingPopup(false)}
          footer={null}
          width={600}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
        >
          <DisplaySettingForm
            displayColumns={displayColumns}
            setDisplayColumns={setDisplayColumns}
            onCloseModal={() => setIsOpenSettingPopup(false)}
          />
        </StyledModal>
      </DemoPlanWrapper>
    </>
  );
};

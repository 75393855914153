import { Col, DatePicker, Row } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { fetchHeatmapChart, fetchHighlightHeatmap } from 'app/apis/ownershipHeatmap';
import { OverviewHeatChart } from 'app/charts/Ownership/OverviewHeatChart';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { OwnershipColor } from 'app/common/constants';
import { Card } from 'app/components/Card';
import { CommonSection } from 'app/components/CommonSection/CommonSection';
import { OwnershipHighlight } from 'app/components/Ownership/Highlight/OwnershipHighlight';
import { OwnershipDetailTable } from 'app/components/Ownership/StatusDetail/OwnershipDetailTable';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useFetch } from 'app/hooks/useFetch';
import { DotComponent } from 'app/pages/Reports/WeeklyStatus/ReportWeeklyStatus.styled';
import { PlanSection } from 'app/pages/TeamDetail/Plan/PlanSection/PlanSection';
import { HighlightType } from 'app/types/Ownership';
import { TeamResponse } from 'app/types/team';
import { FilterTeamOwnership } from 'app/utils/utils';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { StyledOwnershipWrapper, TailContent, Title } from './OwnershipHeatmap.styled';
import { Helmet } from 'react-helmet';

export function OwnershipHeatmap() {
  const { teams: tableTeamData, setBreadcrumbs } = useContext(TeamContext);
  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'Team Maturity',
        parentCrumb: null,
        renderAsLink: false,
      },
      createDefaultBreadCrumb('Maturity Heatmap'),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const disabledDates: RangePickerProps['disabledDate'] = (selected) => {
    return selected && (selected >= moment().endOf('week') || selected <= moment('01-01-2021', 'MM-DD-YYYY'));
  };
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment().startOf('month'));
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const handleMonthlyOptionChange = (date: moment.Moment) => {
    if (date) {
      setSelectedMonth(date.startOf('month'));
    }
  };
  const { data: chartData } = useFetch(() => fetchHeatmapChart(selectedMonth.toDate()), [selectedMonth, openEditor]);
  const { data: highlightData } = useFetch(
    () => fetchHighlightHeatmap(selectedMonth.startOf('day').format('YYYY-MM-01'), HighlightType.Heatmap),
    [selectedMonth]
  );

  const filterDataOwnership = (data: TeamResponse[]) => {
    return data?.filter((item) => !FilterTeamOwnership(item.id, data));
  };
  return (
    <StyledOwnershipWrapper className="ownership-overview-wrapper">
      <Helmet>
        <title>ADM Tool | Maturity Heatmap</title>
      </Helmet>
      <Col className="main-title">
        <Title>
          MATURITY HEATMAP
          <DatePicker
            style={{ marginLeft: 10 }}
            picker="month"
            format={'MMMM yyyy'}
            disabledDate={disabledDates}
            value={selectedMonth}
            onChange={handleMonthlyOptionChange}
          />
        </Title>
      </Col>
      <PlanSection title="OVERVIEW">
        <Row gutter={[50, { lg: 0, sm: 16 }]}>
          <Col className="gutter-row" lg={12} sm={24} xs={24}>
            <Card title="Maturity Overview" className="card-wrapper">
              <OverviewHeatChart dataSource={chartData} totalTeams={tableTeamData} />
            </Card>
          </Col>
          <Col className="gutter-row" lg={12} sm={24} xs={24}>
            <OwnershipHighlight content={highlightData} type={HighlightType.Heatmap} />
          </Col>
        </Row>
      </PlanSection>
      <CommonSection
        title="STATUS DETAIL"
        titleTooltip="Status detail"
        tailContent={
          <TailContent gutter={16} justify="end" className="tail-content-wrapper">
            <Col>
              <Row align="middle">
                <DotComponent color={OwnershipColor.notApplicable} />
                <div className="legend-title">Not Applicable</div>
              </Row>
            </Col>
            <Col>
              <Row align="middle">
                <DotComponent color={OwnershipColor.atRisk} />
                <div className="legend-title">At Risk</div>
              </Row>
            </Col>
            <Col>
              <Row align="middle">
                <DotComponent color={OwnershipColor.caution} />
                <div className="legend-title">Caution</div>
              </Row>
            </Col>
            <Col>
              <Row align="middle">
                <DotComponent color={OwnershipColor.satisfied} />
                <div className="legend-title">Satisfied</div>
              </Row>
            </Col>
          </TailContent>
        }
      >
        <OwnershipDetailTable
          selectedMonth={selectedMonth}
          tableTeamData={filterDataOwnership(tableTeamData)}
          openDrawer={openEditor}
          setOpenDrawer={setOpenEditor}
        />
      </CommonSection>
    </StyledOwnershipWrapper>
  );
}

import { Col, Row, Switch } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { getOjectTeamData } from 'app/apis/statisticOrganization';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { SearchTree } from 'app/components/OrganizationSearchTree/OrganizationSearchTree';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useFetch } from 'app/hooks/useFetch';
import { OrganizationModal } from 'app/pages/Statistic/OganizationModal/OganizationModal';
import { OjectTeamDataResponse } from 'app/types/Organizational';
import { checkByTeam, filterFPTInvolved, filterNodeFPTInvolved } from 'app/utils/organizationSearchTreeUtils';
import { Key, useContext, useEffect, useState } from 'react';
import { OrganizationChart } from './OrganizationalChart';
import './OrganizationalOverview.scss';
import { Helmet } from 'react-helmet';

export const OrganizationalOverview = () => {
  const [isFPTInvolved, setIsFPTInvolved] = useState(true);
  const { setBreadcrumbs } = useContext(TeamContext);
  // for modal
  const [currentTeam, setCurrentTeam] = useState<number>();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  // for filter
  const [dataTree, setdataTree] = useState<DataNode[]>();
  const [organizationData, setOrganizationData] = useState<OjectTeamDataResponse[]>();

  const { data: dataTeam } = useFetch(() => {
    return getOjectTeamData();
  }, []);

  useEffect(() => {
    if (dataTeam) {
      let initFilteredData = filterFPTInvolved(dataTeam);
      setOrganizationData(initFilteredData);
      setdataTree(filterNodeFPTInvolved(initFilteredData, isFPTInvolved));
      setIsFPTInvolved(true);
    }
    setBreadcrumbs([
      {
        path: 'organizational-chart',
        text: 'Organizational Chart',
        parentCrumb: null,
        renderAsLink: true,
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTeam]);

  const onChangeSwitch = (checked: boolean) => {
    if (checked) {
      let filteredData = filterFPTInvolved(dataTeam);
      setOrganizationData(filteredData);
      setdataTree(filterNodeFPTInvolved(filteredData, isFPTInvolved));
    } else {
      setOrganizationData(dataTeam);
      setdataTree(filterNodeFPTInvolved(dataTeam, isFPTInvolved));
    }
    setIsFPTInvolved(checked);
  };
  const onOpenModalDetail = (teamId: number) => {
    setCurrentTeam(teamId);
    setToggleModal(true);
  };

  // On click OK to filter
  const onFilterByChecked = (checkedNodes: Key[]) => {
    if (checkedNodes.length > 0) {
      if (isFPTInvolved) {
        let filtered = filterFPTInvolved(dataTeam);
        let checkData = checkByTeam(filtered, checkedNodes);
        setOrganizationData(checkData);
      } else {
        let checkData = checkByTeam(dataTeam, checkedNodes);
        setOrganizationData(checkData);
      }
    } else {
      if (isFPTInvolved) {
        let filteredData = filterFPTInvolved(dataTeam);
        setOrganizationData(filteredData);
      } else {
        setOrganizationData(dataTeam);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>ADM Tool | Organizational Chart</title>
      </Helmet>
      <Row className="organization-chart-overview-section">
        <OrganizationModal onClose={setToggleModal} visible={toggleModal} teamId={currentTeam} />

        <Col span={24}>
          <span className="title">AGILE DELIVERY STRUCTURE</span>
        </Col>
        <Col className="filter-section">
          <Row className="switch-btn">
            <Switch defaultChecked onChange={onChangeSwitch} />
            <span className="text-switch">FPT Involved</span>
          </Row>
          {dataTree && (
            <Row className="search-box">
              <SearchTree dataSource={dataTree} onFilterByChecked={onFilterByChecked} isFPTInvolded={isFPTInvolved} />
            </Row>
          )}
        </Col>

        <Col span={24} className="heading-title">
          <span>COX AUTOMOTIVE</span>
        </Col>
        <Col span={24}>
          <div className="container-overflow" id="container-drag">
            <OrganizationChart dataSource={organizationData} setCurrentTeam={onOpenModalDetail} />
          </div>
        </Col>
      </Row>
    </>
  );
};

import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Modal, Row } from 'antd';
import { IterationContext } from 'app/contexts/IterationContext';
import { UserContext } from 'app/contexts/UserContext';
import { useAuthz } from 'app/hooks/useAuthz';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { useContext, useEffect, useState } from 'react';
import ToolTip from '../Tooltip/ToolTip';
import './CustomReport.scss';
import { CustomReportForm } from './CustomReportForm';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { ErrorType, SuccesType } from 'app/common/constants';
import { useFetch } from 'app/hooks/useFetch';
import { createCustomReport, deleteCustomReport, editCustomReport, getCustomReport } from 'app/apis/customReportClient';
import { CustomReportRequest, CustomReportResponse } from 'app/types/entity';
import { CustomReportCard } from './CustomReportCard';

interface ICustomReportProps {
  iterationId: number;
  id?: string;
  className?: string;
}

export enum formAction {
  Create = 'create',
  Edit = 'edit',
  Read = 'readmore',
}

export const CustomReport = ({ iterationId, id, className }: ICustomReportProps) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const { selectedIteration, reportStatus } = useContext(IterationContext);
  const [typeModal, setTypeModal] = useState(formAction.Create);
  const [customReports, setCustomReports] = useState<CustomReportResponse[]>([]);
  const [customReportData, setCustomReportData] = useState<CustomReportResponse>({
    iterationId: iterationId,
    title: '',
    note: '',
    url: '',
    reportType: 1,
    reportMainData: '',
    unit: 1,
  });

  const { isAuthorized } = useAuthz();
  const {
    user: { permissions },
  } = useContext(UserContext);
  const allowEdit = isAuthorized && canEditReport(reportStatus, permissions);

  const showModal = (formAction: formAction, formData) => {
    setTypeModal(formAction);
    setVisibleModal(true);
    setCustomReportData(formData);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  //fetch data
  const { data: customReportsFetch } = useFetch<CustomReportResponse[]>(
    () => getCustomReport(iterationId),
    [iterationId]
  );
  //set section data
  useEffect(() => {
    if (customReportsFetch) setCustomReports(customReportsFetch);
  }, [customReportsFetch]);
  //set form data
  useEffect(() => {
    setCustomReportData(customReportData);
  }, [customReportData]);

  const handleDeleteCustomReport = async (id: number) => {
    deleteCustomReport(id)
      .then((res) => {
        openNotificationByType(SuccesType, 'Delete Successful');
        setCustomReports([...customReports.filter((item) => item.id !== id)]);
      })
      .catch((rej) => {
        openNotificationByType(ErrorType, 'Delete Failed');
      });
  };

  const handleSubmitForm = async (payload: CustomReportRequest) => {
    try {
      if (typeModal === formAction.Create) {
        const data = await createCustomReport(payload);
        setCustomReports([...customReports, data]);
        openNotificationByType(SuccesType, 'Create successfully');
      } else if (typeModal === formAction.Edit) {
        const data = await editCustomReport(payload);
        setCustomReports([
          ...customReports.map((item) =>
            item.id === data.id
              ? {
                  ...data,
                  note: data.note,
                  title: data.title,
                  url: data.url,
                }
              : item
          ),
        ]);
        openNotificationByType(SuccesType, 'Edit Successful');
      }
    } catch (error) {
      openNotificationByType(ErrorType, 'Something Went Wrong!!!');
    }
    closeModal();
  };

  return (
    <div id={id} className={`${className} custom-report-section`}>
      <div className="custom-report-header">
        <Row gutter={[24, 24]}>
          <Col span={16}>
            <div>
              <span className="custom-report-title">
                <span>ADDITIONAL REPORT</span>
                <ToolTip title={<span>Add other report here</span>} />
              </span>
            </div>
          </Col>
          <Col span={8}>
            {allowEdit && (
              <Button
                className="button-edit"
                onClick={() => {
                  showModal(formAction.Create, {
                    iterationId: iterationId,
                    title: '',
                    note: '',
                    url: '',
                    reportType: 1,
                    reportMainData: '',
                    unit: null,
                  });
                }}
              >
                <EditOutlined />
                Add
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Row gutter={[24, 24]}>
        {customReports?.length > 0 ? (
          customReports.map((item) => (
            <Col key={item.id} span={12}>
              <CustomReportCard
                editable={allowEdit}
                data={item}
                onDelete={() => {
                  handleDeleteCustomReport(item.id);
                }}
                onEdit={() => {
                  showModal(formAction.Edit, item);
                }}
              />
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        )}
      </Row>
      <Modal
        className="custom-report-modal"
        title="ADDITIONAL REPORT"
        visible={visibleModal}
        centered
        footer={null}
        onCancel={() => {
          setVisibleModal(false);
        }}
        destroyOnClose={true}
      >
        <CustomReportForm
          action={typeModal}
          customReportData={customReportData}
          onSubmit={handleSubmitForm}
          onCloseModal={() => {
            closeModal();
          }}
          iterationId={iterationId}
        />
      </Modal>
    </div>
  );
};

import { TeamType } from 'app/types/team';
import { UserResponse } from './user';

export interface OrganizationFormPayload {
  teamID: number;
  teamName?: string;
  stakeHolders: Stakeholder[];
  descriptions: string;
  roadmap: string;
  dependencies: Dependency[];
  scrumMaster: UserResponse;
  products?: Product[];
}

export enum CreateBy {
  Admin,
  User,
}

export enum ProductType {
  Workload,
  Tag,
  TechnicalSatck,
}

export interface ProductPayload {
  name: string;
  type: ProductType;
  createBy: CreateBy;
}

export interface Product {
  name: string;
  type: ProductType;
}

export interface Stakeholder {
  name: string;
  role: string;
}
export interface Dependency {
  teamId: number;
  teamName: string;
  descriptions: string;
}

export interface TeamItem {
  id: number;
  name: string;
  isFPTInvolvded: boolean;
  scrumMasterID?: number;
  scrumMasterName: string;
  model: string;
  type: TeamType;
  parentId: number;
  isFavorite: boolean;
}

export interface DependencyChartItem {
  source: string;
  target: string;
}

export interface OjectTeamDataResponse {
  id: number;
  name: string;
  type: TeamType;
  isFPTInvolvded: boolean;
  childrenItems: OjectTeamDataResponse[];
  isdisplay?: boolean;
}

import { useContext, useMemo, useState } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import { InovationHigherTargetColor, InovationLowerTargetColor } from 'app/common/constants';
import { Col, Radio, Row, Select } from 'antd';
import { SelectOptions } from 'app/types/entity';
import { ChartTarget } from '../ChartTarget/ChartTarget';
import { StyledChart } from '../Charts.styled';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { OverviewChartItem } from '../../../../types/OverviewChartData';
import { fetchOverviewIterationProductivity, saveChartSettingRequest } from 'app/apis/fetchTeamOverview';
import { useFetch } from 'app/hooks/useFetch';
import { ReportType } from 'app/types/CacheReportModel';
import { SaveChartSettingModel } from 'app/types/SaveChartSettingRequest';
import { OverviewNote } from '../../OverviewNote/OverviewNote';
import { OverviewViewType } from 'app/types/OverviewViewType';
import { Empty } from 'antd';
import { getOverviewQuarterSelectOptions } from 'app/common/helpers';

enum InovationColumnColor {
  Higher,
  Lower,
}

const reportType = ReportType.OverviewIterationDefects;
interface IProps {
  isUpdateData: boolean;
}
export const OverviewIterationProductivity = ({ isUpdateData }: IProps) => {
  const optionsList = getOverviewQuarterSelectOptions();
  const [viewType, setViewType] = useState(OverviewViewType.Lastest10Iteration);
  const [tagert, setTagert] = useState(0);
  const { team } = useContext(TeamDetailContext);
  const { data: chartData } = useFetch(
    () => fetchOverviewIterationProductivity(team?.id, viewType),
    [team, viewType, isUpdateData]
  );

  const summaryData = useMemo(() => {
    if (chartData == null || chartData.items === null) return null;

    // Use .slice to get last 3 velocities (assume it's ASC sorting)
    const lastItems = chartData.items?.slice(-3).sort((a, b) => b.completedPercent - a.completedPercent) || [];
    const data = chartData.items;
    const max = [...data].sort((a, b) => b.completedPercent - a.completedPercent).slice(0, 1);
    const min = [...data].sort((a, b) => a.completedPercent - b.completedPercent).slice(0, 1);
    const temp: SelectOptions<any>[] = [
      {
        label: `Last 3: ${lastItems[0]?.completedPercent}`,
        value: 1,
      },
      {
        label: `Highest 3: ${max[0]?.completedPercent}`,
        value: 2,
      },
      {
        label: `Lowest 3: ${min[0]?.completedPercent}`,
        value: 3,
      },
    ];
    return temp;
  }, [chartData]);

  const generateColumnConfig = (dataSource: OverviewChartItem[]): ColumnConfig => {
    const customTargetLegend: LegendItem[] = [
      {
        name: 'Over Target',
        value: InovationColumnColor[InovationColumnColor.Higher],
        marker: {
          symbol: 'circle',
          style: {
            fill: InovationHigherTargetColor,
          },
        },
      },
      {
        name: 'Below Target',
        value: InovationColumnColor[InovationColumnColor.Lower],
        marker: {
          symbol: 'circle',
          style: {
            fill: InovationLowerTargetColor,
          },
        },
      },
    ];

    const data = [];
    var colors = [InovationHigherTargetColor, InovationLowerTargetColor];

    dataSource?.forEach((item) => {
      let newItem = {
        iterationName: item.iterationName,
        completedPercent: item.completedPercent,
        type: item.completedPercent >= (chartData.target ?? 0) ? 'Over Target' : 'Below Target',
      };
      data.push(newItem);
    });

    if (data[0]?.type == 'Below Target') colors = [InovationLowerTargetColor, InovationHigherTargetColor];
    if (data?.filter((x) => x.type == 'Over Target').length == 0) colors = [InovationLowerTargetColor];
    if (data?.filter((x) => x.type == 'Below Target').length == 0) colors = [InovationHigherTargetColor];

    var max = Math.max(...data.map((o) => o.completedPercent));

    return {
      data,
      legend: {
        position: 'top',
        items: customTargetLegend,
        marker: {
          style: {
            width: 30,
            height: 30,
            fontSize: 30,
          },
        },
      },
      maxColumnWidth: 50,
      tooltip: {
        title: (title) => `${title}`,
        customContent: (value, data) => {
          return `
              <div class="tooltip-card">
                <div class="title">${value}</div>
                <div>
                Point(s): <span class="value">${data[0]?.data.completedPercent}</span>
                </div>
              </div>`;
        },
      },
      isStack: true,
      xField: 'iterationName',
      yField: 'completedPercent',
      seriesField: 'type',
      color: colors,
      label: {
        position: 'middle',
      },
      yAxis: {
        type: 'linear',
        max: max,
        title: {
          text: 'Point(s)',
          style: { fontSize: 16, fontWeight: 600 },
        },
        position: 'left',
      },
      meta: {
        iterationName: {
          alias: 'Iteration Name',
        },
        completedPercent: {
          alias: 'percent',
        },
        type: {
          alias: 'percent',
        },
      },
    };
  };

  const onTargetSave = async (value: number) => {
    chartData.target = value;
    const payload = new SaveChartSettingModel();
    payload.target = value;
    payload.note = chartData.note;
    payload.teamId = team.id;
    payload.chartType = reportType;
    setTagert(value);
    return await saveChartSettingRequest(payload);
  };

  const onNoteSave = async (value: string) => {
    chartData.note = value;
    const payload = new SaveChartSettingModel();
    payload.target = chartData.target;
    payload.note = value;
    payload.teamId = team.id;
    payload.chartType = reportType;

    return await saveChartSettingRequest(payload);
  };

  return (
    <StyledChart>
      {!!chartData?.items && (
        <Row>
          <Col span={5}>
            <Row>
              <ChartTarget value={chartData != null ? chartData.target ?? 0 : 0} onSave={onTargetSave} unit=" pts" />
            </Row>
          </Col>
          <Col span={14}>
            {chartData && chartData?.items.length > 0 && (
              <Row justify="center">
                <Radio.Group options={summaryData} optionType="button" buttonStyle="solid" />
              </Row>
            )}
          </Col>
          <Col span={5}>
            <Row justify="end">
              <Select
                options={optionsList}
                style={{ width: '100%' }}
                defaultValue={optionsList[0].value}
                onChange={(value) => setViewType(value)}
              />
            </Row>
          </Col>
        </Row>
      )}

      {chartData && !!chartData.items && chartData?.items.length > 0 ? (
        <Column {...generateColumnConfig(chartData.items)} />
      ) : (
        <Empty />
      )}

      {chartData && <OverviewNote content={chartData.note} onSave={onNoteSave} />}
    </StyledChart>
  );
};

import {
  AwardModel,
  AwardRequestTypeReponse,
  AwardResponse,
  IAwardRequest,
  Member,
  TimeTree,
  User,
} from 'app/types/AllAwardType';
import { fetchAsync } from '../utils/fetch';

export const getAwardTypes = async (): Promise<AwardRequestTypeReponse[]> => fetchAsync('/api/awardrequests');

export const getMember = async (): Promise<Member[]> => fetchAsync('/api/awards/get-members');

export const getUser = async (): Promise<User[]> => fetchAsync('/api/awards/get-users');

export const createAward = async (payload: IAwardRequest): Promise<AwardResponse> => {
  const request = new FormData();
  request.append('year', payload.year.toString());
  request.append('requestFor', payload.requestFor);
  request.append('requestForValue', payload.requestForValue.toString());
  request.append('requestType', payload.requestType.toString());
  request.append('type', payload.type.toString());
  request.append('amount', payload.amount.toString());
  request.append('awardTo', payload.awardTo);
  request.append('presentativeFor', payload.presentativeFor.toString());
  request.append('feedback', payload.feedback ?? '');
  request.append('image', payload.image ?? null);

  return fetchAsync('/api/awards', { type: 'text', method: 'POST', body: request });
};

export const getAllAward = async (): Promise<User[]> => fetchAsync('/api/awards/getall');
export const getTimeTree = async (): Promise<TimeTree[]> => fetchAsync('/api/awards/get-time-tree');
export const getAwardByTime = async (url: string): Promise<AwardModel[][]> => fetchAsync(url);

export const updateAward = async (payload: IAwardRequest): Promise<AwardResponse> => {
  const request = new FormData();
  request.append('year', payload.year.toString());
  request.append('requestFor', payload.requestFor);
  request.append('requestForValue', payload.requestForValue.toString());
  request.append('requestType', payload.requestType.toString());
  request.append('type', payload.type.toString());
  request.append('amount', payload.amount.toString());
  request.append('awardTo', payload.awardTo);
  request.append('presentativeFor', payload.presentativeFor.toString());
  request.append('feedback', payload.feedback ?? '');
  request.append('image', payload.image ?? null);

  return fetchAsync('/api/awards/' + payload.id, { type: 'text', method: 'PUT', body: request });
};

export const deleteAward = async (id: number): Promise<boolean> => {
  return fetchAsync('/api/awards/' + id, { type: 'text', method: 'DELETE' });
};

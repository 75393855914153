import { PagedList } from 'app/types/PagedList';
import { FavoriteRequest } from 'app/types/user';
import { toUrlSearchParams } from 'app/utils/stringUtils';
import { TeamModel, TeamRequest, TeamResponse, UpdateTeamModel } from '../types/team';
import { fetchAsync } from '../utils/fetch';

export const fetchTeams = (query: TeamRequest): Promise<PagedList<TeamResponse>> =>
  fetchAsync(`/api/team?${toUrlSearchParams(query)}`);

export const fetchAllTeams = (userId?: number | string): Promise<TeamResponse[]> =>
  fetchAsync(userId ? `/api/team/all?userId=${userId}` : '/api/team/all');

export const createTeam = (team: TeamModel): Promise<TeamResponse> =>
  fetchAsync(`/api/team`, {
    method: 'POST',
    body: team,
  });

export const updateTeam = (id: number, team: UpdateTeamModel): Promise<TeamResponse> =>
  fetchAsync(`/api/team/${id}`, {
    method: 'PUT',
    body: team,
  });

export const deleteTeam = (id: number): Promise<void> =>
  fetchAsync(`/api/team/${id}`, {
    method: 'DELETE',
  });

export const fetchTeamById = (teamId: number | string): Promise<TeamResponse> => fetchAsync(`/api/team/${teamId}`);

export const fetchTeamsByTeamName = (name: string): Promise<TeamResponse[]> =>
  fetchAsync(`/api/team/teamname`, {
    method: 'POST',
    body: {
      name: name,
    },
  });

export const fetchTeamsByUserId = (userId: number | string): Promise<TeamResponse[]> =>
  fetchAsync(`/api/team/all/${userId}`);

export const pinFavoriteTeam = (request: FavoriteRequest): Promise<void> =>
  fetchAsync(`/api/team/setFavoriteTeam`, {
    method: 'POST',
    body: request,
  });

export const ExportTeam = async () => {
  const result = await fetchAsync(`/api/team/ExportTeamData`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

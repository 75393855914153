import {
  TeamCategoryRequest,
  TeamCategoryResponse,
  TeamConfigureResponse,
  TeamCriteriaRequest,
  TeamCriteriaResponse,
} from 'app/types/CriteriaConfigure';
import { TargetDto } from 'app/types/Ownership';
import { fetchAsync } from 'app/utils/fetch';

export const fetchConfigure = (): Promise<TeamConfigureResponse[]> =>
  fetchAsync('/api/configureOwnership/team_configure');

export const fetchCategoryConfigure = (teamId: number, dateId: string): Promise<TeamCategoryResponse[]> =>
  fetchAsync(`/api/configureOwnership/team_category/${teamId}/${dateId}`);

export const fetchCriteriaConfigure = (teamId: number, dateId: string): Promise<TeamCriteriaResponse[]> =>
  fetchAsync(`/api/configureOwnership/team_criteria/${teamId}/${dateId}`);

export const fetchTarget = (teamId: number, dateId: string): Promise<TargetDto> =>
  fetchAsync(`/api/ownership/target/${teamId}/${dateId}`);

export const updateTarget = (teamId: number, dateId: string, request: TargetDto): Promise<TargetDto> =>
  fetchAsync(`/api/ownership/target/${teamId}/${dateId}`, {
    method: 'POST',
    body: request,
  });

export const cloneConfig = (teamId: number, dateId: string) =>
  fetchAsync(`/api/configureOwnership/clone-config/${teamId}/${dateId}`, {
    method: 'POST',
  });

export const CreatCategoryConfigure = (dateId: string, request: TeamCategoryRequest): Promise<TeamCategoryResponse[]> =>
  fetchAsync(`/api/configureOwnership/team_category/${dateId}`, {
    method: 'POST',
    body: request,
  });

export const CreatCriteriaConfigure = (dateId: string, request: TeamCriteriaRequest): Promise<TeamCriteriaResponse[]> =>
  fetchAsync(`/api/configureOwnership/team_criteria/${dateId}`, {
    method: 'POST',
    body: request,
  });

import { Button, Col, Form, notification, Row, Select, TablePaginationConfig, Tooltip } from 'antd';

import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import {
  createIterationGoal,
  deleteIterationGoal,
  getIterationGoal,
  updateIterationGoal,
} from 'app/apis/iterationGoalClient';
import { FieldRequiredText, USER_PAGE_NUMBER } from 'app/common/constants';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import { StyledModal } from 'app/pages/ManageUsers/ManagedUsers.styled';
import { SubPage } from 'app/types/TeamDetail';
import { IterationGoalRequest, IterationGoalResponse } from 'app/types/entity';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { Stage } from 'app/types/TeamDetailTypes';
import { openNotification } from 'app/utils/notificationUtils';
import { updateItem } from 'app/utils/tableUtils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { EditableColumn, EditableTable } from '../EditableTable';
import './IterationGoal.scss';
import { IterationGoalStatusItem } from './IterationGoalStatusItem';
import { withAuthz } from '../HOC/withAuthz';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import ToolTip from '../Tooltip/ToolTip';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { UserContext } from 'app/contexts/UserContext';

const { Option } = Select;

interface IProps {
  page: SubPage;
}

export enum IterationGoalStatus {
  None,
  Pass,
  Fail,
}

export const IterationGoal = (props: IProps) => {
  const { stage: currentStage, selectedIteration, setSelectedIteration, reportStatus } = useContext(IterationContext);
  const [allowEdit, setAllowEdit] = useState(false);

  const { isAuthorized } = useAuthz();
  const allowedRoleAndCondition =
    isAuthorized &&
    (currentStage === Stage.Other ||
      currentStage === undefined ||
      ((selectedIteration?.isCurrentSprint || selectedIteration?.isFutureSprint) && currentStage === Stage.Planning));
  const authzHoc = withAuthz('iteration:goal');
  const [data, setData] = useState<IterationGoalResponse[]>();
  const [pageNumber, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const {
    user: { permissions },
  } = useContext(UserContext);
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  useEffect(() => {
    setData(selectedIteration?.iterationGoals);
  }, [selectedIteration]);

  // Check sprint goal editable
  useEffect(() => {
    // As a failsafe measurement, we set this flag to false first.
    setAllowEdit(false);

    // If authorized, and select iteration belong to current sprint
    if (!allowedRoleAndCondition) return;

    switch (props.page) {
      case SubPage.Plan:
        setAllowEdit(currentStage === Stage.Planning);
        return;

      case SubPage.Report:
        setAllowEdit(canEditReport(reportStatus, permissions));
        return;

      case SubPage.Monitoring:
        setAllowEdit(true);
        return;
    }
  }, [allowEdit, currentStage, props.page, selectedIteration, allowedRoleAndCondition, reportStatus]);

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = useMemo<EditableColumn<IterationGoalResponse>[]>(() => {
    let defineColumns: EditableColumn<IterationGoalResponse>[] = [
      {
        title: '#',
        width: '3%',
        align: 'center',
        render: (value, record, index) => (pageNumber - 1) * pageSize + index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // Only allow user edit goal name in planning stage
        // and in Plan sub page
        editable: allowEdit && currentStage === Stage.Planning && props.page === SubPage.Plan,
        width: '37%',
      },
    ];

    if (props.page === SubPage.Report) {
      defineColumns.push({
        title: 'Status',
        dataIndex: 'status',
        render: (v, record, i) => renderIterationGoalStatus(record),
        width: '6%',
        className: 'iteration-goal-status-col',
        inputType: 'custom',
        key: 'status',
        editable: true,
        align: 'center',
        editingContent: (record) => (
          <Select
            defaultValue={IterationGoalStatus.None}
            onChange={(val) => {
              record.status = val;
            }}
          >
            <Option value={IterationGoalStatus.None} disabled={true}>
              N/A
            </Option>
            <Option value={IterationGoalStatus.Pass}>Pass</Option>
            <Option value={IterationGoalStatus.Fail}>Fail</Option>
          </Select>
        ),
      });
    }

    let notePropertyName: string;
    switch (props.page) {
      case SubPage.Plan:
        notePropertyName = 'notePlan';
        break;
      case SubPage.Monitoring:
        notePropertyName = 'noteMonitor';
        break;
      case SubPage.Report:
        notePropertyName = 'noteReport';
        break;
    }

    defineColumns.push({
      title: 'Notes',
      dataIndex: notePropertyName,
      key: notePropertyName,
      editable: true,
      width: 'auto',
      rules: [{ max: 200, message: 'This field must be a string with a maximum length of 200' }],
    });
    return defineColumns;
  }, [pageNumber, pageSize, props, allowEdit, currentStage]);

  const updateState = (newData: IterationGoalResponse[]) => {
    setData(newData);
    selectedIteration.iterationGoals = newData;
    setSelectedIteration(selectedIteration);
  };

  const onCreate = async () => {
    if (props.page !== SubPage.Plan) {
      console.error('Add Iteration Goal only available on Planning stage only!');
      return;
    }

    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    try {
      const formRquest = form.getFieldsValue();
      formRquest.iterationId = selectedIteration?.id;
      await createIterationGoal(formRquest);
      form.resetFields();
      const newData = await getIterationGoal(selectedIteration?.id);
      updateState(newData);
      showAddGoalDialog(false);
      notification.success({
        message: `Create successfully`,
        duration: 2,
      });
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };

  const onUpdate = async (
    id: number,
    { name, notePlan, noteMonitor, noteReport, iterationId, status }: IterationGoalResponse
  ) => {
    try {
      let subPage = props.page;
      await updateIterationGoal({ id, name, notePlan, noteMonitor, noteReport, iterationId, status, subPage });
      const newData = updateItem(
        { id, name, notePlan, noteMonitor, noteReport, iterationId, status },
        selectedIteration.iterationGoals
      );
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });
      updateState(newData);
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };

  const onDelete = async (id: number) => {
    try {
      await deleteIterationGoal(id);
      const newData = await getIterationGoal(selectedIteration?.id);
      updateState(newData);
    } catch (error) {
      openNotification(400, 'Delete failed!');
    }
  };

  const renderIterationGoalStatus = (item: IterationGoalResponse) => {
    return <IterationGoalStatusItem item={item} />;
  };

  const [addGoalDialog, showAddGoalDialog] = useState(false);
  const [form] = Form.useForm<IterationGoalRequest>();

  const AddButtonWithAuth = authzHoc(Button, 'create');

  return (
    <div className="title-section" id="iteration-goal-section">
      <Row>
        <Col span={16}>
          <PageTitle>
            <Title level={5}>
              ITERATION GOALS
              <ToolTip
                title={
                  <>
                    {props.page === SubPage.Plan ? (
                      // eslint-disable-next-line react/no-unescaped-entities
                      <>
                        <span>Let&apos;s set some goals for this iteration. Example: </span>
                        <br />
                        <span>Product Goal: Launch a website that allows sales to customers inside London.</span>
                        <ul className="tooltip-content">
                          <li>
                            <i>Sprint Goal 1 - Create basic website structure.</i>
                          </li>
                          <li>
                            <i>Sprint Goal 2 - Build capability to list & purchase products using a credit card.</i>
                          </li>
                        </ul>
                      </>
                    ) : props.page === SubPage.Monitoring ? (
                      <span>Stay focusing on your team&apos;s goals in this iteration.</span>
                    ) : (
                      <span>Fill the result of each goal, please explain reasons we fail to achieve our goals.</span>
                    )}
                  </>
                }
              />
            </Title>
          </PageTitle>
        </Col>
        <Col span={8} className="title-section-add-button">
          {allowedRoleAndCondition /* Make sure user have permission to add goal */ &&
            props.page === SubPage.Plan &&
            currentStage === Stage.Planning /* And only allow to add goal at Planning stage */ && (
              <AddButtonWithAuth
                type="primary"
                onClick={() => {
                  form.resetFields();
                  showAddGoalDialog(true);
                }}
                size="large"
              >
                Add
              </AddButtonWithAuth>
            )}
        </Col>
      </Row>
      <DeviderWrapper />
      <EditableTable
        data={data}
        isEdit={allowEdit}
        onSave={onUpdate}
        onDelete={onDelete}
        isDelete={allowEdit && props.page === SubPage.Plan && currentStage === Stage.Planning}
        columns={columns}
        onChange={onChange}
        pageSize={pageSize}
        current={pageNumber}
        // Number of sprint goals is only about 3 items
        // so that we might have to show 1 page is enough
        pagination={{ hideOnSinglePage: true }}
      />
      <StyledModal
        title="ADD NEW GOAL"
        visible={addGoalDialog}
        onOk={onCreate}
        onCancel={() => showAddGoalDialog(false)}
        width={550}
        centered
        footer={[
          <Button key="back" size="large" onClick={() => showAddGoalDialog(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" size="large" onClick={onCreate}>
            Create
          </Button>,
        ]}
      >
        <Form
          {...layout}
          form={form}
          size={'middle'}
          className="form-iteration-goals"
          name="createIterationGoal"
          initialValues={{ goal: '', note: '' }}
          autoComplete="off"
          colon={false}
          labelAlign="left"
        >
          <Form.Item
            label="Goal"
            name="name"
            initialValue={''}
            rules={[{ required: true, message: FieldRequiredText }]}
          >
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item
            label="Note"
            name="notePlan"
            initialValue={''}
            rules={[
              {
                max: 200,
                message: 'The field Note must be a string with a maximum length of 200',
              },
            ]}
          >
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} className="text-note" />
          </Form.Item>
        </Form>
      </StyledModal>
    </div>
  );
};

import styled from 'styled-components';

export const TestExecutionTableContainer = styled.div`
  position: relative;
  min-height: 378px;
  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    padding-bottom: 15px;
  }

  .ant-progress-inner {
    border-radius: 0;
  }
  .ant-progress-bg {
    height: 24px !important;
  }
  .ant-table-thead {
    //@at-root: nowrap;
  }
  .ant-progress-inner {
    border: 1px solid #d9d9d9;
    padding: 2px;
  }
  .container-table {
    .ant-table-content {
      border: 1px solid #d9d9d9;
    }
    .ant-table-thead > tr > th {
      font-size: 12px;
      font-weight: bold;
      color: #000000;
    }
    .ant-table-tbody > tr > td {
      font-size: 12px;
      font-weight: 500;
    }
    .ant-table.ant-table-small .ant-table-thead > tr > th {
      padding: 3px 1px;
    }
    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      padding: 3px 11px;
    }
    .ant-table.ant-table-small .ant-table-thead > tr > th,
    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      :nth-child(3) {
        padding: 3px 0px;
      }
    }

    /* .ant-table.ant-table-small .ant-table-tbody > tr > td:nth-ch {
      padding: 7px 11px;
    } */
  }
  .bar.ant-progess-outer.ant-progess-inner.ant-progress-bg {
    height: 18px !important;
  }
  .progress-wrapper {
    .percentage-value {
      padding: 3px 0px;
    }
    .progress-number {
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      justify-content: center;
    }
  }
`;

import { Spin } from 'antd';
import { ClaimTypes } from 'app/types/ClaimTypes';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { fetchMyUser } from '../apis/userClient';
import { useFetch } from '../hooks/useFetch';
import { UserResponse, UserStatusEnum } from '../types/user';
import { AuthContext } from './AuthContext';

interface IUserContext {
  user: UserResponse;
  setUser: (users: UserResponse) => void;
  isApproved: boolean;
  refreshUser: () => void;
}

export const UserContext = createContext<IUserContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const UserContextProvider = ({ children }: IProps) => {
  const { user: userInfoFromToken, logOut } = useContext(AuthContext);

  const [users, setUsers] = useState<UserResponse>();
  const [refreshUserState, setRefreshUserState] = useState(0);
  const { data: originalData, error: fetchError } = useFetch(fetchMyUser, [refreshUserState]);
  const [isApproved, setIsApproved] = useState<boolean>(false);

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!originalData) return;

    setUsers({ ...originalData, permissions: [...userInfoFromToken.permissions] });
    setIsApproved(originalData.userStatus === UserStatusEnum.Active);
  }, [originalData, userInfoFromToken]);

  useEffect(() => {
    if (fetchError) {
      const err = fetchError.data as ErrorResponse;
      if (err?.error?.message === 'InvalidSession') {
        // Logout user
        logOut();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchError]);

  if (!users) {
    return <Spin />;
  }

  if (users.userStatus !== UserStatusEnum.Active && !pathname.toLowerCase().includes('getting_started')) {
    // TODO: Need some refactoring in the future!!
    console.log('context', users.userStatus);

    history.push({
      pathname: '/getting_started',
      state: { userStatus: users.userStatus, isSso: users.IsSso },
    });
  }

  if (fetchError) {
    return <div>{JSON.stringify(fetchError)}</div>;
  }

  return (
    <UserContext.Provider
      value={{
        user: users,
        setUser: setUsers,
        isApproved,
        refreshUser: () => setRefreshUserState(new Date().getTime()),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import { Column, ColumnConfig } from '@ant-design/plots';
import { ClientSatisfaction, Evaluationtype, OwnershipHeatmapItem } from '../../types/OwnershipHeatmap';
import { LegendItem } from '@antv/g2/esm/interface';
import { OwnershipStatusTitle, OwnershipColor, OwnershipEvaluationTitle } from 'app/common/constants';
import { Empty } from 'antd';
import { TeamResponse } from 'app/types/team';

type IProps = {
  dataSource: OwnershipHeatmapItem[];
  totalTeams: TeamResponse[];
};

export const OverviewHeatChart = ({ dataSource, totalTeams }: IProps) => {
  const countTeams = totalTeams?.filter((x) => x.isFPTInvolvded === true).length;
  const definedStatusTitle = (type: ClientSatisfaction) => {
    switch (type) {
      case ClientSatisfaction.NotApplicable:
        return OwnershipStatusTitle.notApplicable;
      case ClientSatisfaction.NotSatisfied:
        return OwnershipStatusTitle.atRisk;
      case ClientSatisfaction.Satisfied:
        return OwnershipStatusTitle.satisfied;
      case ClientSatisfaction.SomewhatSatisfied:
        return OwnershipStatusTitle.caution;

      default:
        return OwnershipStatusTitle.atRisk;
    }
  };
  const definedEvaluationTitle = (type: Evaluationtype) => {
    switch (type) {
      case Evaluationtype.ClientSatisfaction:
        return OwnershipEvaluationTitle.clientSatisfaction;
      case Evaluationtype.OwnershipLevel:
        return OwnershipEvaluationTitle.ownershipLevel;
      case Evaluationtype.ProductionIssue:
        return OwnershipEvaluationTitle.ProdIssue;
      case Evaluationtype.SatisfactionRate:
        return OwnershipEvaluationTitle.SLARate;
    }
  };
  const countTeamsExceptEva = (eva: Evaluationtype) => {
    let dataToCount = dataSource.filter(
      (x) => x.evaluation === eva && x.type !== ClientSatisfaction.NA && x.type !== ClientSatisfaction.NotApplicable
    );
    let count = 0;
    dataToCount.forEach((item) => (count = count + item.teams));
    return count;
  };
  const countTeamsWithTypeAndEva = (type: ClientSatisfaction, eva: Evaluationtype) => {
    let dataToCount = dataSource.filter((item) => item.type === type && item.evaluation === eva);
    let count = 0;
    dataToCount.forEach((item) => (count = count + item.teams));
    return count;
  };
  const generateChartDataStructure = (type: ClientSatisfaction) => {
    const result: OwnershipHeatmapItem[] = [
      {
        evaluation: Evaluationtype.ClientSatisfaction,
        teams: countTeamsWithTypeAndEva(type, Evaluationtype.ClientSatisfaction),
        type: type,
      },
      {
        evaluation: Evaluationtype.OwnershipLevel,
        teams: countTeamsWithTypeAndEva(type, Evaluationtype.OwnershipLevel),
        type: type,
      },
      {
        evaluation: Evaluationtype.SatisfactionRate,
        teams: countTeamsWithTypeAndEva(type, Evaluationtype.SatisfactionRate),
        type: type,
      },
      {
        evaluation: Evaluationtype.ProductionIssue,
        teams: countTeamsWithTypeAndEva(type, Evaluationtype.ProductionIssue),
        type: type,
      },
    ];
    return result;
  };
  const generateColumnConfig = (dataSource: OwnershipHeatmapItem[]): ColumnConfig => {
    const customTargetLegend: LegendItem[] = [
      {
        name: definedStatusTitle(ClientSatisfaction.NotApplicable),
        value: definedStatusTitle(ClientSatisfaction.NotApplicable),
        marker: {
          symbol: 'circle',
          style: {
            fill: OwnershipColor.notApplicable,
          },
        },
      },
      {
        name: definedStatusTitle(ClientSatisfaction.NotSatisfied),
        value: definedStatusTitle(ClientSatisfaction.NotSatisfied),
        marker: {
          symbol: 'circle',
          style: {
            fill: OwnershipColor.atRisk,
          },
        },
      },
      {
        name: definedStatusTitle(ClientSatisfaction.SomewhatSatisfied),
        value: definedStatusTitle(ClientSatisfaction.SomewhatSatisfied),
        marker: {
          symbol: 'circle',
          style: {
            fill: OwnershipColor.caution,
          },
        },
      },
      {
        name: definedStatusTitle(ClientSatisfaction.Satisfied),
        value: definedStatusTitle(ClientSatisfaction.Satisfied),
        marker: {
          symbol: 'circle',
          style: {
            fill: OwnershipColor.satisfied,
          },
        },
      },
    ];
    const notSatisfiedData = generateChartDataStructure(ClientSatisfaction.NotSatisfied);

    const somewhatSatisfieddata = generateChartDataStructure(ClientSatisfaction.SomewhatSatisfied);
    const satifiedData = generateChartDataStructure(ClientSatisfaction.Satisfied);
    const notApplicableData: OwnershipHeatmapItem[] = [
      {
        evaluation: Evaluationtype.ClientSatisfaction,
        teams: countTeams - countTeamsExceptEva(Evaluationtype.ClientSatisfaction),
        type: ClientSatisfaction.NotApplicable,
      },
      {
        evaluation: Evaluationtype.OwnershipLevel,
        teams: countTeams - countTeamsExceptEva(Evaluationtype.OwnershipLevel),
        type: ClientSatisfaction.NotApplicable,
      },
      {
        evaluation: Evaluationtype.SatisfactionRate,
        teams: countTeams - countTeamsExceptEva(Evaluationtype.SatisfactionRate),
        type: ClientSatisfaction.NotApplicable,
      },
      {
        evaluation: Evaluationtype.ProductionIssue,
        teams: countTeams - countTeamsExceptEva(Evaluationtype.ProductionIssue),
        type: ClientSatisfaction.NotApplicable,
      },
    ];

    const data = notApplicableData
      .concat(notSatisfiedData)
      .concat(somewhatSatisfieddata)
      .concat(satifiedData)
      .map((d) => ({
        evaluation: definedEvaluationTitle(d.evaluation),
        teams: d.teams,
        type: definedStatusTitle(d.type),
      }));
    return {
      data,
      legend: {
        position: 'top',
        items: customTargetLegend,
      },
      tooltip: {
        title: (title) => `${title}`,
      },
      maxColumnWidth: 70,
      isStack: true,
      xField: 'evaluation',
      yField: 'teams',
      seriesField: 'type',
      color: [OwnershipColor.notApplicable, OwnershipColor.atRisk, OwnershipColor.caution, OwnershipColor.satisfied],
      yAxis: {
        type: 'linear',
        title: {
          text: 'Team',
          style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
        },
        position: 'left',
      },
    };
  };
  if (!dataSource) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return <Column {...generateColumnConfig(dataSource)} />;
};

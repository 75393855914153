import { Col, DatePicker, notification, Row, Space, Spin } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import Image from 'antd/lib/image';
import { getOwnershipStatusOverview, updateOwnershipStatusDetail } from 'app/apis/ownershipClient';
import { DATE_ID_FORMAT, OwnershipTableCellColor, roleNames } from 'app/common/constants';
import { Card } from 'app/components/Card';
import { CommonSection } from 'app/components/CommonSection/CommonSection';
import { TeamContext } from 'app/contexts/TeamContext ';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { UserContext } from 'app/contexts/UserContext';
import {
  DotComponent,
  Label,
  TailInfo,
  WeeklyStatusWrapper,
} from 'app/pages/Reports/WeeklyStatus/ReportWeeklyStatus.styled';
import { PlanSection } from 'app/pages/TeamDetail/Plan/PlanSection/PlanSection';
import {
  OwnershipStatusDetailItem,
  OwnershipStatusResponse,
  OwnershipUpdateStatusDetailRequest,
  TargetType,
} from 'app/types/Ownership';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { OwnershipHighlight } from './OwnershipHighlight';
import { OwnershipScoreByCategoryChart } from './OwnershipScoreByCategoryChart';
import { OwnershipScoreByLevelChart } from './OwnershipScoreByLevelChart';
import { OwnershipScoreTrendChart } from './OwnershipScoreTrendChart';
import { OwnershipStatusTable } from './OwnershipStatusTable';

import './OwnershipStatus.scss';
import { OwnershipTotalScore } from './OwnershipTotalScore';
import { OwnershipTrendByActualScore } from './OwnershipTrendByActualScore';

export interface IOwnershipStatusProps {}

export function OwnershipStatus({ ...props }: IOwnershipStatusProps) {
  const { setBreadcrumbs } = useContext(TeamContext);
  const { team } = useContext(TeamDetailContext);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment().startOf('month'));
  const [overviewData, setOverviewData] = useState<OwnershipStatusResponse>();
  /** Increment reloadKey every time data is refreshed. This is used to ensure components know to re-render */
  const [reloadKey, setReloadKey] = useState(0);

  const scoreType = useMemo(() => overviewData?.scoreByLevels?.[0]?.scoreType, [overviewData]);

  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const isAllowedEdit =
    (userRole === roleNames.scrumMaster && team.scrumMaster.email === email) || userRole === roleNames.admin;

  useEffect(() => {
    if (team) {
      setBreadcrumbs([
        {
          path: '#',
          text: 'Statistics',
          parentCrumb: null,
          renderAsLink: false,
        },
        {
          path: 'ownership',
          text: 'Ownership',
          parentCrumb: null,
          renderAsLink: true,
        },
        {
          path: '#',
          text: team.name,
          parentCrumb: null,
          renderAsLink: false,
        },
      ]);
    }
    if (!overviewData) {
      loadData(selectedMonth.toDate());
    }
  }, [setBreadcrumbs, team.name, selectedMonth, setSelectedMonth]);

  const loadData = async (date: Date) => {
    setReloadKey(reloadKey + 1);
    const loadedData = await getOwnershipStatusOverview(team.id, date.getFullYear(), date.getMonth() + 1);
    setOverviewData(loadedData);
  };

  const handleMonthlyOptionChange = (date: moment.Moment) => {
    if (date) {
      setSelectedMonth(date.startOf('month'));
      loadData(date.toDate());
    }
  };

  const disabledDates: RangePickerProps['disabledDate'] = (selected) => {
    return selected && (selected >= moment().endOf('week') || selected <= moment('01-01-2021', 'MM-DD-YYYY'));
  };

  if (!overviewData) return <Spin />;

  const updateStatusTableRow = (record: OwnershipStatusDetailItem) => {
    let data: OwnershipUpdateStatusDetailRequest = {
      actionToImprove: record.actionToImprove,
      level: Number(record.selfEvaluation),
      teamCriteriaId: record.teamCriteriaId,
      selfEvaluationId: record.selfEvaluationId,
      levelString: Number(record.selfEvaluation).toString(),
      dateId: selectedMonth.format(DATE_ID_FORMAT),
    };
    return updateOwnershipStatusDetail(data)
      .then(() => {
        let clonedOverviewData = cloneDeep(overviewData);
        let updatingIndex = clonedOverviewData.statusDetails.findIndex(
          (i) => i.teamCriteriaId === record.teamCriteriaId
        );
        clonedOverviewData.statusDetails[updatingIndex] = record;
        setOverviewData(clonedOverviewData);
      })
      .catch((err) => {
        notification.error(err);
      })
      .finally(() => {
        loadData(selectedMonth.toDate());
      });
  };

  let tailHeader = (
    <TailInfo className="tail-header">
      <Row justify="end" align="middle">
        <Space size={40}>
          <Label>
            <DotComponent color={OwnershipTableCellColor.levelNotReached} />
            <span className="label-text">Not Pass</span>
          </Label>
          <Label>
            <DotComponent color={OwnershipTableCellColor.levelPassed} />
            <span className="label-text">Pass</span>
          </Label>
          <Label>
            <DotComponent color={OwnershipTableCellColor.default} />
            <span className="label-text">Not Applicable</span>
          </Label>
        </Space>
      </Row>
    </TailInfo>
  );

  return (
    overviewData && (
      <>
        <WeeklyStatusWrapper>
          <span className="title">
            MATURITY STATUS - {team.name}
            <DatePicker
              style={{ marginLeft: 10 }}
              picker="month"
              format={'MMMM yyyy'}
              disabledDate={disabledDates}
              value={selectedMonth}
              onChange={handleMonthlyOptionChange}
            />
          </span>
        </WeeklyStatusWrapper>
        <PlanSection title="Overview">
          <Row gutter={[16, 16]} className="top-row">
            <Col className="gutter-row" md={12} sm={24} xs={24}>
              <Card title="Ranking Overview">
                <div className="ranking-image-container">
                  <Image
                    src={`/assets/img/OwnershipLevel${
                      overviewData.rankingOverview < 1 ? 'None' : overviewData.rankingOverview
                    }.png`}
                    crossOrigin="anonymous"
                    preview={false}
                  />
                </div>
              </Card>
            </Col>
            <Col className="gutter-row" md={12} sm={24} xs={24}>
              <Card title={null}>
                <OwnershipHighlight
                  highlight={overviewData.monthlyHighlight}
                  selectedDate={selectedMonth}
                  isAllowedEdit={isAllowedEdit}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16} className="chart-container" justify="center">
            {scoreType === TargetType.Score && (
              <>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                  <Card title="Total Score">
                    <OwnershipTotalScore data={overviewData.scoreByLevels ?? []} />
                  </Card>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                  <Card title="Score Trend">
                    <OwnershipTrendByActualScore
                      data={overviewData.scoreTrendChartItems ?? []}
                      scoreDetails={overviewData.scoreByLevels ?? []}
                    />
                  </Card>
                </Col>
              </>
            )}
            {scoreType === TargetType.Percentage && (
              <>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                  <Card title="Score by Level">
                    <OwnershipScoreByLevelChart dataSource={overviewData.scoreByLevels} />
                  </Card>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                  <Card title="Score by Category">
                    <OwnershipScoreByCategoryChart key={reloadKey} dataSource={overviewData.scoresByCategoryChart} />
                  </Card>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                  <Card title="Score Trend">
                    <OwnershipScoreTrendChart dataSource={overviewData.scoreTrendChartItems} />
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </PlanSection>
        <CommonSection title="Status Detail" tailContent={tailHeader}>
          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <Card title={null}>
                <OwnershipStatusTable
                  isAllowedEdit={isAllowedEdit}
                  key={selectedMonth.format()}
                  dataSource={overviewData.statusDetails}
                  onRecordUpdated={updateStatusTableRow}
                />
              </Card>
            </Col>
          </Row>
        </CommonSection>
      </>
    )
  );
}

import { Line, LineConfig } from '@ant-design/charts';
import { Empty } from 'antd';
import { LabelTrendsChart } from 'app/common/constants';
import { Card } from 'app/components/Card';
import { StoryCarriedOverPortItem } from 'app/types/ProgramTrends';
import { minBy } from 'lodash';

interface IStoryCarriedOverByPort {
  dataSource: StoryCarriedOverPortItem[];
  loading?: boolean;
}
export const StoryCarriedOverByPort = ({ dataSource, loading }: IStoryCarriedOverByPort) => {
  const dataFilter = dataSource?.map((item) => ({
    iterationName: item.iterationName,
    teamName: item.teamName,
    points: Number(item.points.toFixed(2)),
  }));
  const config: LineConfig = {
    data: dataFilter,
    height: 400,
    xField: 'iterationName',
    yField: 'points',
    seriesField: 'teamName',
    point: {
      size: 3,
      shape: 'square',
    },
    label: {
      position: 'top',
    },
    legend: {
      position: 'top',
      flipPage: true,
    },
    yAxis: {
      max: 1,
      tickInterval: 0.1,
      min: Math.min(minBy(dataFilter, (item) => item.points)?.points, 0),
    },
    xAxis: {
      label: LabelTrendsChart,
    },
  };

  return (
    <Card title={'Story Carried-Over'} loading={loading}>
      {!loading && (!dataSource?.length ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <Line {...config} />)}
    </Card>
  );
};

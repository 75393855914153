import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { PercentGrowthDownColor, PercentGrowthUpColor } from 'app/common/constants';
import { isNumber } from 'lodash';
import './PercentGrowth.scss';

export interface IProps {
  value: number;
  position: string;
}

export const PercentGrowth = ({ value, position }: IProps) => {
  if (isNaN(value) || !isNumber(value)) return <></>;

  const icon = (status: string, color: string) => {
    if (value >= 0) return <CaretUpOutlined style={{ fontSize: '20px', color: color }} />;
    else return <CaretDownOutlined style={{ fontSize: '20px', color: color }} />;
  };
  const PERCENT_ELEMENT = (status: string, color: string) => {
    return (
      <div className={`percent-icon ${position}`}>
        {icon(status, color)}
        <span style={{ color: color }}>{Math.abs(value)}%</span>
      </div>
    );
  };
  if (value >= 0) {
    return PERCENT_ELEMENT('up', PercentGrowthUpColor);
  } else {
    return PERCENT_ELEMENT('down', PercentGrowthDownColor);
  }
};

import { OwnershipLevel } from './Ownership';
import { TeamDataResponse } from './team';

export interface OwnershipHeatmapItem {
  teams: number;
  evaluation: Evaluationtype;
  type: ClientSatisfaction;
}
export enum Evaluationtype {
  ClientSatisfaction,
  OwnershipLevel,
  SatisfactionRate,
  ProductionIssue,
}
export enum OwnershipHeatmapType {
  Satisfied,
  SomewhatSatisfied,
  NotSatisfied,
  NotApplicable,
}
export enum ClientSatisfaction {
  NA,
  Satisfied,
  SomewhatSatisfied,
  NotSatisfied,
  NotApplicable,
}
export enum SatisfactionRate {
  NA,
  High,
  Medium,
  Low,
  NoTracking,
}
export enum ProductionIssue {
  NoIssue,
  NA,
  RollbackIssue,
  NoProductionSupport,
}
export enum EvaluationProgress {
  NotStarted,
  Completed,
  OnTrack,
  AtRisk,
  CriticalRisk,
}
export interface OwnershipStatusItemResponse {
  id: number;
  teamId: number;
  date: Date;
  clientSatisfaction: ClientSatisfaction;
  ownershipLevel: OwnershipLevel;
  satisfactionRate: number;
  productionIssue: number;
  highlight: string;
}
export interface OwnershipHeatmapTableColumn {
  id: number;
  name: string;
  teamId: number;
  teamDataResponse: TeamDataResponse;
  date: Date;
  clientSatisfaction: ClientSatisfaction;
  ownershipLevel: OwnershipLevel;
  satisfactionRate: number;
  productionIssue: number;
  highlight: string;
  projectGroupName: string;
  scrumMasterEmail: string;
}
export interface OwnershipHeatmapModalDetail {
  id: number;
  name: string;
  teamId: number;
  date: Date;
  clientSatisfaction: ClientSatisfaction;
  ownershipLevel: OwnershipLevel;
  satisfactionRate: number;
  productionIssue: number;
  highlight: string;
  roadmaps: OwnershipRoadmap[];
  actions: OwnershipAction[];
}
export interface OwnershipRoadmap {
  quarter: string;
  objective: string;
  currentStatus: string;
  evalution: EvaluationProgress;
}
export interface OwnershipAction {
  dueDate: Date;
  keyResult: string;
  currentStatus: string;
  personInCharge: string;
  evalution: EvaluationProgress;
}

export interface OwnershipHeatmapHighlight {
  id: number;
  highlightDate: Date;
  highlightNote: string;
}

import { Pie } from '@ant-design/plots';
import { Empty } from 'antd';
import { IterationScheduleStateResponse, ScheduleStateChartData } from 'app/types/IterationScheduleStateResponse';
import { useMemo } from 'react';
import {
  AcceptedChartColor,
  CompletedChartColor,
  DefinedChartColor,
  IdeaChartColor,
  InProgressChartColor,
  ReleasedChartColor,
  scheduleStates,
} from 'app/common/constants';

import './IterationScheduleState.scss';

interface IProps {
  dataSource: IterationScheduleStateResponse[];
  checkHaveOtherData?: boolean;
}

export const IterationScheduleState = ({ dataSource, checkHaveOtherData = true }: IProps) => {
  const config = useMemo(() => {
    // Define list colors follow the order of schedule state:
    // Idea, Define, In-Progress, Completed, Accepted, Released

    // Count total us
    let countUS = 0;
    if (dataSource?.length > 0) {
      countUS = dataSource.reduce((countUS, item) => countUS + item.count, 0);
    }

    const getColourForScheduleState = (scheduleState: string) => {
      switch (scheduleState) {
        case 'Completed':
          return CompletedChartColor;
        case 'In-Progress':
          return InProgressChartColor;
        case 'Accepted':
          return AcceptedChartColor;
        case 'Released':
          return ReleasedChartColor;
        case 'Defined':
          return DefinedChartColor;
        case 'Idea':
          return IdeaChartColor;
        default:
          return '';
      }
    };

    let scheduleStateData: ScheduleStateChartData[] = dataSource
      ? scheduleStates.map((i) => {
          return {
            color: getColourForScheduleState(i.name as string),
            order: i.order ?? 0,
            chartItem: {
              scheduleState: i.name,
              count: dataSource.filter((s) => s.scheduleState === i.name)[0]?.count ?? 0,
            } as IterationScheduleStateResponse,
          };
        })
      : [];

    scheduleStateData.sort((a, b) => {
      return a.order - b.order;
    });

    // Chart's config
    let colors = scheduleStateData.map((i) => i.color);

    const config = {
      appendPadding: 10,
      animation: false,
      data: scheduleStateData?.map((i) => i.chartItem) ?? [],
      angleField: 'count',
      colorField: 'scheduleState',
      color: colors,
      radius: 1,
      innerRadius: 0.65,
      label: {
        autoRotate: false,
        type: 'inner',
        offset: '-50%',
        content: ({ percent }) => {
          // Should not show label if percent == 0
          if (percent === 0) {
            return '';
          }

          return `${(percent * 100).toFixed(0)}%`;
        },
        style: {
          textAlign: 'center',
          fontSize: 14,
        },
      },
      statistic: {
        title: {
          content: 'Total',
        },
        content: {
          content: countUS.toString(),
        },
      },
      // Custom tooltip when hover mouse on pie of chart
      tooltip: {
        customContent: (title, data) => {
          if (dataSource === undefined) {
            return ``;
          }

          let items = ``;

          for (let i = 0; i < dataSource.length; i++) {
            let stateName = dataSource.at(i).scheduleState;
            items += `
            <div class="ant-row ${stateName === title ? 'schedule-state-active' : ''}">
              <div class="ant-col ant-col-20">
                <span class="ant-badge ant-badge-status ant-badge-not-a-wrapper">
                  <span class="ant-badge-status-dot" style="background: ${colors.at(i)};"></span>
                  <span class="ant-badge-status-text">${stateName}</span>
                </span>
              </div>
              <div class="ant-col ant-col-4 schedule-state-lable-count">
                ${dataSource.at(i).count}
              </div>
            </div>
            <br>`;
          }

          return `<div class="schedule-state-tooltip">${items}</div>`;
        },
      },
      // Turn off legend-filter
      interactions: [{ type: 'legend-filter', enable: false }],
    };

    return config;
  }, [dataSource]);

  // Show no result data
  if (!dataSource && !checkHaveOtherData) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  // Show pie chart
  return (
    <div style={{ maxWidth: 351 }}>
      <Pie {...config} />
    </div>
  );
};

import styled from 'styled-components';
export const TeamHighLightStyled = styled.div`
  .ant-table-thead {
    display: none;
  }
  pre {
    font-family: 'Segoe UI';
    white-space: pre-wrap;
    margin-bottom: 0;
  }
  .column-width {
    width: 25%;
  }
  @media only screen and (max-width: 650px) {
    .column-width {
      width: 123px;
    }
  }
`;

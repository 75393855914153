import { Line, LineConfig } from '@ant-design/charts/es';
import { Empty } from 'antd';
import { Card } from 'app/components/Card';
import { LabelTrendsChart } from 'app/common/constants';
import { ProgramUltilizedCapacityItem } from 'app/types/ProgramTrends';
import { minBy, maxBy } from 'lodash';

interface IProps {
  dataSource: ProgramUltilizedCapacityItem[];
  loading?: boolean;
}

export const ProgramUltilizedCapacity = ({ dataSource, loading }: IProps) => {
  const config: LineConfig = {
    data: dataSource,
    xField: 'iterationName',
    yField: 'capacity',
    seriesField: 'teamName',
    point: {
      size: 3,
      shape: 'square',
    },
    label: {
      position: 'top',
    },
    legend: {
      position: 'top',
      flipPage: false,
    },
    xAxis: {
      label: LabelTrendsChart,
    },
    yAxis: {
      max: Math.max(maxBy(dataSource, (item) => item.capacity)?.capacity, 1.05),
      tickInterval: 0.05,
      min: Math.min(minBy(dataSource, (item) => item.capacity)?.capacity, 0.5),
    },
  };

  return (
    <Card title={'Utilized Capacity'} loading={loading}>
      {!loading && (!dataSource?.length ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <Line {...config} />)}
    </Card>
  );
};

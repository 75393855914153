import { useState, useContext, useEffect } from 'react';
import './ProgramNormalOverview.scss';
// Components
import { Row, Col, Spin } from 'antd';
import { ReportStatus } from 'app/components/Overview/ReportStatus/ReportStatus';
// Context
import { TeamContext } from 'app/contexts/TeamContext ';
import { Card } from 'app/components/Card';
import { IterationTeamDelivery } from 'app/components/IterationReport/IterationTeamDelivery';
import { IterationScheduleState } from 'app/components/IterationReport/IterationScheduleState';
import { UtilizedCapacity } from 'app/charts/Report/UtilizedCapacity/UltilizedCapacity';
import { CardOverview } from 'app/components/CardOverview/CardOverview';
import { CumulativeDiagram } from 'app/charts/CumulativeDiagram';
import { PercentGrowth } from 'app/components/PercentGrowth/PercentGrowth';
import { ProgramOverviewDataResponse } from 'app/types/ProgramOverviewModel';
import { fetchProgramOverviewDataByIteration } from 'app/apis/programOverviewClient';
import { ProgramBestPerformer } from 'app/components/ProgramBestPerformer/ProgramBestPerformer';
import { TypeProgress } from 'app/types/entity';
import { CfdUserStory } from 'app/charts/CfdUserStory';
import { getOverViewBaseBreadCrumb } from 'app/common/breadcrumb';

interface IProps {
  selectedIterationName: string;
  showPercentChange: boolean;
}

export const ProgramNormalOverview = (props: IProps) => {
  const { teams: teamsData, setBreadcrumbs } = useContext(TeamContext);
  const [loading, setLoading] = useState(true);
  const [programOverViewData, setProgramOverviewData] = useState<ProgramOverviewDataResponse>();
  let data = programOverViewData ?? new ProgramOverviewDataResponse();

  useEffect(() => {
    setBreadcrumbs([getOverViewBaseBreadCrumb()]);
    fetchNormalData();
  }, [props.selectedIterationName]);
  const fetchNormalData = async () => {
    setLoading(true);
    let data = await fetchProgramOverviewDataByIteration(props?.selectedIterationName);
    setLoading(false);
    if (data) {
      setProgramOverviewData(data);
    }
  };
  return (
    <Row gutter={{ lg: 24, md: 0, xs: 0 }} style={{ justifyContent: 'space-between' }}>
      {loading ? (
        <Col xs={24} md={24} lg={15}>
          <div className="iteration-section">
            <Row>
              <span className="title">ITERATION OVERVIEW</span>
            </Row>
            <Row>
              <Col>
                <Spin />
              </Col>
            </Row>
          </div>
        </Col>
      ) : (
        <Col xs={24} md={24} lg={15}>
          <div className="iteration-section">
            <Row>
              <span className="title">ITERATION OVERVIEW</span>
            </Row>
            <Row gutter={[24, 16]}>
              <Col xs={24} xl={24} xxl={7} className="program-chart-div">
                <Card title="Program Delivery">
                  <IterationTeamDelivery dataSource={data.programDelivery.chartData} />
                  {props.showPercentChange && (
                    <PercentGrowth position="bottom" value={data.programDelivery.percentChange} />
                  )}
                </Card>
              </Col>
              <Col xs={24} xl={24} xxl={7} className="program-utilized-chart-div">
                <Card title="Utilized Capacity">
                  <UtilizedCapacity dataSource={data.utilizedCapacity.chartData} />
                  {props.showPercentChange && (
                    <PercentGrowth position="bottom" value={data.utilizedCapacity.percentChange} />
                  )}
                </Card>
              </Col>
              <Col xs={24} xl={24} xxl={10} className="program-schedule-state-chart-div">
                <Card title="Schedule State">
                  <IterationScheduleState dataSource={data.programScheduleState} />
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col xs={24} xl={12} xxl={6}>
                <CardOverview
                  title="Story Point"
                  firstLabel="Plan"
                  secondLabel="Accepted"
                  firstValue={data.storyPoint.maxValue}
                  secondValue={data.storyPoint.currentValue}
                  typeProgress={TypeProgress.ProgressBar}
                />
                {props.showPercentChange && <PercentGrowth position="top" value={data.storyPoint.percentChange} />}
              </Col>
              <Col xs={24} xl={12} xxl={6}>
                <CardOverview
                  title="User Story"
                  firstLabel="Plan"
                  secondLabel="Accepted"
                  firstValue={data.userStory.maxValue}
                  secondValue={data.userStory.currentValue}
                  typeProgress={TypeProgress.ProgressBar}
                />
                {props.showPercentChange && <PercentGrowth position="top" value={data.userStory.percentChange} />}
              </Col>
              <Col xs={24} xl={12} xxl={6}>
                <CardOverview
                  title="Test Coverage"
                  firstLabel="TCs"
                  secondLabel="Executed"
                  firstValue={data.testCoverage.maxValue}
                  secondValue={data.testCoverage.currentValue}
                  typeProgress={TypeProgress.ProgressBar}
                />
                {props.showPercentChange && <PercentGrowth position="top" value={data.testCoverage.percentChange} />}
              </Col>
              <Col xs={24} xl={12} xxl={6}>
                <CardOverview
                  title="Cards Carried-Over"
                  firstLabel="User Story"
                  secondLabel="Defect"
                  firstValue={data.cardsCarriedOver.userStories}
                  secondValue={data.cardsCarriedOver.defects}
                  typeProgress={TypeProgress.SegmentBar}
                />
                {props.showPercentChange && (
                  <PercentGrowth position="top" value={data.cardsCarriedOver.percentChange} />
                )}
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col xs={24} xl={12}>
                <Card title="Cumulative Flow Diagram of User Stories">
                  <CfdUserStory teamId={null} iteration={null} dataSource={programOverViewData?.cfd_UserStory} />
                </Card>
              </Col>
              <Col xs={24} xl={12}>
                <Card title="Cumulative Flow Diagram of Tasks">
                  <CumulativeDiagram teamId={null} dataSource={programOverViewData?.cfd_Tasks} />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Card title="The Best Performance">
                  <ProgramBestPerformer data={data.bestPerformers} />
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      )}

      <Col xs={24} md={24} lg={9}>
        <div className="report-section">
          <ReportStatus iterationName={props.selectedIterationName} teamsData={teamsData} key="report" />
        </div>
      </Col>
    </Row>
  );
};

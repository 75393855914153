import { Bar, BarConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import {
  AmbiguousRequirement,
  BranchEscapes,
  CodeDefects,
  CouldNotDetermine,
  Environment,
  FormsDefect,
  IncorrectImpactAnalysis,
  NeverWorked,
  NonDefect,
  NotApplicable,
  Observation,
  PostRefresh,
  Regression,
  QATestDeficiency,
  ConfigurationRoot,
  ExternalRoot,
} from 'app/common/constants';
import { IDataChartProps } from 'app/types/CacheReportModel';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export const StyledEmpty = styled.div`
  .ant-empty {
    height: 300px;
    opacity: 0.5;
  }
  .ant-empty-image {
    height: 200px;
    img {
      padding-top: 50px;
    }
  }
`;

export const RootCauseCategoryByDefect = ({ teamId, iteration, dataSource }: IDataChartProps) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dataSource) {
      setData(dataSource.filter((x) => x.value !== 0));
    }
  }, [dataSource]);

  let maxValue = 0;
  if (data && data.length > 0) {
    maxValue = Math.max(
      ...data
        .filter((x) => x.value)
        .map((object) => {
          return object.value;
        })
    );
    maxValue = Math.ceil(maxValue * 1.1);
  }

  const config: BarConfig = {
    data,
    xField: 'value',
    yField: 'rootCauseCategory',
    width: 500,
    height: 300,
    label: {
      position: 'right',
    },
    seriesField: 'rootCauseCategory',
    legend: {
      position: 'top',
      marker: {
        symbol: 'circle',
      },
    },
    xAxis: {
      max: maxValue,
      tickInterval: 1,
    },
    maxBarWidth: 40,
    color: ({ rootCauseCategory }) => {
      switch (rootCauseCategory) {
        case 'Ambiguous Requirement':
          return AmbiguousRequirement;
        case 'Branch Escapes':
          return BranchEscapes;
        case 'Code Defects':
          return CodeDefects;
        case 'Configuration':
          return ConfigurationRoot;
        case 'Could Not Determine':
          return CouldNotDetermine;
        case 'Environment':
          return Environment;
        case 'External':
          return ExternalRoot;
        case 'Forms Defect':
          return FormsDefect;
        case 'Incorrect Impact Analysis':
          return IncorrectImpactAnalysis;
        case 'Never Worked':
          return NeverWorked;
        case 'NonDefect':
          return NonDefect;
        case 'Not Applicable':
          return NotApplicable;
        case 'Observation':
          return Observation;
        case 'PostRefresh':
          return PostRefresh;
        case 'Regression':
          return Regression;
        case 'QA Test Deficiency':
          return QATestDeficiency;
        default:
          break;
      }
    },
  };

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  if (data.length === 0)
    return (
      <StyledEmpty>
        <Empty image="/assets/img/NoBugFound.png" description={<>No Defect Found!</>} />
      </StyledEmpty>
    );

  return <Bar {...config} />;
};

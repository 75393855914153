import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { useEffect, useState } from 'react';
import { OverviewTable } from './OverviewTable/OverviewTable';
import { DetailTable } from './DetailTable/DetailTable';

import './IterationDetailSection.scss';
import { TeamIterationMonitorDetail, TeamIterationMonitorOverview } from 'app/types/TeamDetailTypes';
import ToolTip from 'app/components/Tooltip/ToolTip';
import { PageTitle } from 'app/pages/TeamDetail/TeamDetail.styled';
import { TeamType } from 'app/types/team';

interface IIterationDetailSectionProps {
  dataSource: TeamIterationMonitorDetail[];
  type: TeamType;
}

const tabs = [
  { label: 'Overview', value: 'Overview' },
  { label: 'Detail', value: 'Detail' },
];

export const IterationDetailSection = ({ dataSource, type }: IIterationDetailSectionProps) => {
  const [currentTab, setCurrentTab] = useState('Overview');
  const [titleName, setTitleName] = useState<String>(null);

  useEffect(() => {
    if (type === 1) setTitleName('Delivery Stream');
    if (type === 2) setTitleName('Release Train');
    if (type === 3) setTitleName('Team Name');
  }, [type]);

  const onChangeTab = ({ target: { value } }: RadioChangeEvent) => {
    setCurrentTab(value);
  };
  const filterData = () => {
    const transformData: TeamIterationMonitorOverview[] = dataSource?.map((item, index) => ({
      id: item.id,
      type: item.type,
      teamName: item.teamName,
      capacity: item.capacityPercent,
      points: item.pointsPercent,
      cards: {
        defects: item.defectNumber,
        us: item.usNumber,
      },
    }));
    return transformData;
  };

  return (
    <div className="iteration-detail-section">
      <Row>
        <Col span={24}>
          <Row className="iteration-detail-title" gutter={16}>
            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
              <PageTitle level={5}>ITERATION DETAIL</PageTitle>
              <ToolTip title="Iteration detail" />
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col className="gutter-row" style={{ marginLeft: 'auto' }}>
                  <Radio.Group
                    options={tabs}
                    onChange={onChangeTab}
                    value={currentTab}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <div>
        <Row>
          <Col span={24}>
            <Row>
              {currentTab === 'Overview' && (
                <Col span={24}>
                  <OverviewTable dataSource={filterData()} title={titleName} />
                </Col>
              )}
            </Row>
            <Row>
              {currentTab === 'Detail' && (
                <Col span={24}>
                  <DetailTable dataSource={dataSource} title={titleName} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default IterationDetailSection;

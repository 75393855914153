import { useState, useEffect } from 'react';
import { Column } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/plots';
import './IterationDefectsByState.scss';
import { IDataChartProps, IterationDefect } from 'app/types/CacheReportModel';
import { Empty } from 'antd';
import {
  CLOSED_STATE_COLOR,
  CLOSE_DECLINED_STATE_COLOR,
  FIXED_STATE_COLOR,
  OPEN_STATE_COLOR,
  SUBMITTED_STATE_COLOR,
} from 'app/common/constants';
import { LegendItem } from '@antv/g2/esm/interface';
import { StyledEmpty } from '../IterationDefectsByEnvironment';
import { isEmpty } from 'lodash';

export const IterationDefectsByState = ({ teamId, iteration, dataSource }: IDataChartProps) => {
  const [data, setData] = useState<IterationDefect[]>([]);
  const [noBug, setNoBug] = useState(false);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (!isEmpty(data) && data.filter((x) => x.amount === null).length === data.length) {
      setNoBug(true);
    }
  }, [data]);

  const config: ColumnConfig = {
    data,
    isStack: true,
    xField: 'date',
    yField: 'amount',
    yAxis: {
      title: {
        text: 'Defects',
        position: 'center',
        spacing: 20,
        style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
      },
      tickInterval: 1,
    },
    label: {
      position: 'middle',
      content: ({ amount }) => {
        if (amount === 0) {
          return '';
        }
        return amount;
      },
    },
    seriesField: 'state',
    height: 300,
    legend: {
      position: 'top',
      marker: {
        symbol: 'circle',
      },
      items: customTargetLegend,
    },
    color: [SUBMITTED_STATE_COLOR, OPEN_STATE_COLOR, FIXED_STATE_COLOR, CLOSED_STATE_COLOR, CLOSE_DECLINED_STATE_COLOR],
  };

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  if (noBug) {
    return (
      <StyledEmpty>
        <Empty image="/assets/img/NoBugFound.png" description={<>No Defect Found!</>} />
      </StyledEmpty>
    );
  }
  return <Column {...config} />;
};

export const customTargetLegend: LegendItem[] = [
  {
    name: 'Submitted',
    value: 'Submitted',
    marker: {
      symbol: 'circle',
      style: {
        fill: SUBMITTED_STATE_COLOR,
      },
    },
  },
  {
    name: 'Open',
    value: 'Open',
    marker: {
      symbol: 'circle',
      style: {
        fill: OPEN_STATE_COLOR,
      },
    },
  },
  {
    name: 'Fixed',
    value: 'Fixed',
    marker: {
      symbol: 'circle',
      style: {
        fill: FIXED_STATE_COLOR,
      },
    },
  },
  {
    name: 'Closed',
    value: 'Closed',
    marker: {
      symbol: 'circle',
      style: {
        fill: CLOSED_STATE_COLOR,
      },
    },
  },
  {
    name: 'Closed Declined',
    value: 'Closed Declined',
    marker: {
      symbol: 'circle',
      style: {
        fill: CLOSE_DECLINED_STATE_COLOR,
      },
    },
  },
];

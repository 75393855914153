import { useContext, useEffect, useState } from 'react';
import { Button, Row, Col, Modal, Image } from 'antd';
import './ComplimentSection.scss';
import { ComplimentCard } from '../ComplimentCard/ComplimentCard';
import { PlusOutlined } from '@ant-design/icons';
import { IComplimentRequest, IComplimentResponse } from 'app/types/entity';
import { ComplimentForm } from './ComplimentForm';
import { createCompliment, deleteCompliment, editCompliment, getCompliments } from 'app/apis/compliments';
import { useFetch } from 'app/hooks/useFetch';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { ErrorType, SuccesType } from 'app/common/constants';
import { IterationContext } from 'app/contexts/IterationContext';
import { useAuthz } from 'app/hooks/useAuthz';
import { Empty } from 'antd';
import { Card } from '../Card';
import ToolTip from '../Tooltip/ToolTip';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { UserContext } from 'app/contexts/UserContext';
import { Stage } from 'app/types/TeamDetailTypes';

export interface IComplimentSectionProps {
  header: string;
  iterationId: number;
  id?: string;
  className?: string;
}
export enum formAction {
  Create = 'create',
  Edit = 'edit',
  Read = 'readmore',
}
export function ComplimentSection({ header, id = 'compliments', className, ...props }: IComplimentSectionProps) {
  const [visibleModal, setVisibleModal] = useState(false);
  const [typeModal, setTypeModal] = useState(formAction.Create);
  const [compliments, setCompliments] = useState<IComplimentResponse[]>([]);
  const { selectedIteration, stage, reportStatus } = useContext(IterationContext);
  const {
    user: { permissions },
  } = useContext(UserContext);
  const { isAuthorized } = useAuthz();
  const allowEdit =
    isAuthorized &&
    (stage === Stage.Other ||
      stage === undefined ||
      (selectedIteration?.isCurrentSprint && stage === Stage.Planning)) &&
    canEditReport(reportStatus, permissions);
  const [complimentFormData, setcomplimentFormData] = useState<IComplimentResponse>({
    id: 0,
    iterationId: props.iterationId,
    title: '',
    message: '',
    from: '',
    complimentImages: [],
  });

  //fetch data
  const { data: complimentsFetch } = useFetch<IComplimentResponse[]>(
    () => getCompliments(props.iterationId),
    [props.iterationId]
  );

  //set section data
  useEffect(() => {
    if (complimentsFetch) setCompliments(complimentsFetch);
  }, [complimentsFetch]);

  //set form data
  useEffect(() => {
    setcomplimentFormData(complimentFormData);
  }, [complimentFormData]);

  const handleDeleteCompliment = async (complimentDeleteId: number) => {
    deleteCompliment(complimentDeleteId)
      .then((res) => {
        openNotificationByType(SuccesType, 'Delete Successful');
        setCompliments([...compliments.filter((compliment) => compliment.id !== complimentDeleteId)]);
      })
      .catch((rej) => {
        openNotificationByType(ErrorType, 'Delete Failed');
      });
  };
  const handleSubmitForm = async (payload: IComplimentRequest) => {
    try {
      if (typeModal === formAction.Create) {
        const data = await createCompliment(payload);
        setCompliments([...compliments, data]);
        openNotificationByType(SuccesType, 'Create successfully');
      } else if (typeModal === 'edit') {
        const data = await editCompliment(payload);
        setCompliments([
          ...compliments.map((compliment) =>
            compliment.id === data.id
              ? {
                  ...data,
                  message: data.message,
                  from: data.from,
                  title: data.title,
                  complimentImages: data.complimentImages,
                }
              : compliment
          ),
        ]);
        openNotificationByType(SuccesType, 'Edit Successful');
      }
    } catch (error) {
      openNotificationByType(ErrorType, 'Something Went Wrong!!!');
    }
    closeModal();
  };

  const showModal = (formAction: formAction, formData) => {
    setTypeModal(formAction);
    setVisibleModal(true);
    setcomplimentFormData(formData);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  return (
    <div id={id} className={`${className} compliment-section`}>
      <div className="compliment-header">
        <Row>
          <Col span={8}>
            <div>
              <span className="compliment-title">
                {header}
                <ToolTip title={<span>Compliments from Clients.</span>} />
              </span>
            </div>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Image alt="5stars" preview={false} src="/assets/img/5stars.png" />
          </Col>
          <Col span={8}>
            {allowEdit && (
              <Button
                data-html2canvas-ignore
                className="button-add"
                onClick={() => {
                  showModal(formAction.Create, {
                    id: 0,
                    iterationId: props.iterationId,
                    title: '',
                    message: '',
                    from: '',
                    images: [],
                  });
                }}
              >
                <PlusOutlined />
                Add
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Row gutter={[24, 24]}>
        {compliments?.length > 0 ? (
          compliments.map((compliment) => (
            <Col key={compliment.id} span={12}>
              <ComplimentCard
                editable={allowEdit}
                data={compliment}
                onDelete={() => {
                  handleDeleteCompliment(compliment.id);
                }}
                onEdit={() => {
                  showModal(formAction.Edit, compliment);
                }}
                onReadmore={() => {
                  showModal(formAction.Read, compliment);
                }}
              />
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        )}
      </Row>
      <Modal
        className="compliment-modal"
        title="5-Star Compliment"
        visible={visibleModal}
        centered
        footer={null}
        onCancel={() => {
          closeModal();
        }}
        destroyOnClose={true}
      >
        <ComplimentForm
          complimentData={complimentFormData}
          action={typeModal}
          onSubmit={handleSubmitForm}
          onCloseModal={() => {
            closeModal();
          }}
        />
      </Modal>
    </div>
  );
}

import { Col, Row } from 'antd';
import { Helmet } from 'react-helmet';
import { TrendsWrapper } from './Trends.styled';
import { UserStoryStatusChart } from 'app/charts/Trends/UserStoryStatus';
import { StoryCarriedOverProgram } from 'app/charts/Trends/StoryCarriedOverProgram';
import { Card } from 'app/components/Card';
import { useContext, useEffect, useState } from 'react';
import { ProgramTrendVelocity } from 'app/charts/Trends/ProgramTrendVelocity';
import { StoryCarriedOverByPort } from 'app/charts/Trends/StoryCarriedOverByPort';
import {
  fetchDeliveryTrendChart,
  fetchVelocityChart,
  fetchCarriedOverPortfolioChart,
  fetchUserStoryStatusChart,
  fetchUserStoryCarriedOverChart,
  fetchUtilizedCapacityChart,
} from 'app/apis/programTrends';
import { useFetch } from 'app/hooks/useFetch';
import ProgramTrendDelivery from 'app/charts/Trends/ProgramTrendDelivery';
import { ProgramUltilizedCapacity } from 'app/charts/Trends/ProgramUltilizedCapacity';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { TeamContext } from 'app/contexts/TeamContext ';
export interface ITrendsProps {}

export function Trends() {
  const { setBreadcrumbs } = useContext(TeamContext);

  const delivery = useFetch(fetchDeliveryTrendChart, []);
  const velocity = useFetch(fetchVelocityChart, []);
  const carriedOverPortfolio = useFetch(fetchCarriedOverPortfolioChart, []);
  const userStoryStatus = useFetch(fetchUserStoryStatusChart, []);
  const userStoryCarriedOver = useFetch(fetchUserStoryCarriedOverChart, []);
  const utilizedCapacity = useFetch(fetchUtilizedCapacityChart, []);

  useEffect(() => {
    setBreadcrumbs([createDefaultBreadCrumb('Trends')]);
  }, []);

  return (
    <>
      <Helmet>
        <title>ADM Tool | Trends</title>
      </Helmet>
      <TrendsWrapper>
        <Row>
          <Col>
            <span className="title">Program Trends</span>
          </Col>
        </Row>
        <Row className="chart-section" gutter={15}>
          <Col lg={8} md={12} sm={24}>
            <ProgramTrendDelivery dataSource={delivery.data} loading={delivery.loading} />
          </Col>
          <Col lg={8} md={12} sm={24}>
            <ProgramUltilizedCapacity dataSource={utilizedCapacity.data} loading={utilizedCapacity.loading} />
          </Col>
          <Col lg={8} md={12} sm={24}>
            <StoryCarriedOverByPort dataSource={carriedOverPortfolio.data} loading={carriedOverPortfolio.loading} />
          </Col>
          <Col lg={8} md={12} sm={24}>
            <UserStoryStatusChart dataSource={userStoryStatus.data} loading={userStoryStatus.loading} />
          </Col>
          <Col lg={8} md={12} sm={24}>
            <ProgramTrendVelocity dataSource={velocity.data} loading={velocity.loading} />
          </Col>
          <Col lg={8} md={12} sm={24}>
            <StoryCarriedOverProgram dataSource={userStoryCarriedOver.data} loading={userStoryCarriedOver.loading} />
          </Col>
        </Row>
      </TrendsWrapper>
    </>
  );
}

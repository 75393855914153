import { RefAttributes, useMemo } from 'react';
import { Column as PlotChart, ColumnConfig } from '@ant-design/plots';
import { ProgramTrendVelocityItem } from '../../types/ProgramTrends';
import { Col, Empty } from 'antd';
import { Card } from 'app/components/Card';
import { LabelTrendsChart, PlannedVelocityColor, TitleChartStyle } from 'app/common/constants';

interface IProgramVelocitychart {
  dataSource: ProgramTrendVelocityItem[];
  loading?: boolean;
}

export const ProgramTrendVelocity = ({ dataSource, loading }: IProgramVelocitychart) => {
  const config = useMemo(() => {
    const data = dataSource?.map((v) => ({ iterationName: v.iterationName, velocityPoints: v.points })) || [];

    const config: ColumnConfig & RefAttributes<ProgramTrendVelocityItem> = {
      data: data,
      xField: 'iterationName',
      yField: 'velocityPoints',
      columnWidthRatio: 0.5,
      xAxis: {
        label: LabelTrendsChart,
      },
      yAxis: {
        title: {
          text: 'Points',
          position: 'center',
          spacing: 20,
          style: TitleChartStyle,
        },
      },
      autoFit: true,
      meta: {
        velocityPoints: {
          alias: 'Points',
        },
      },
      legend: false,
      color: PlannedVelocityColor,
    };

    return config;
  }, [dataSource]);

  return (
    <Card title={'Velocity'} loading={loading}>
      {!loading &&
        (!dataSource?.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Col span={24}>
            <PlotChart {...config} />
          </Col>
        ))}
    </Card>
  );
};

import { Col, Row } from 'antd';
import { SubCommonSection } from '../../../../components/SubCommonSection/SubCommonSection';
import { StatusDetailWrapper } from 'app/pages/Reports/WeeklyStatus/StatusDetail/StatusDetail.styled';
import { TeamStatusCard } from './TeamStatusCard';
import { OwnershipTeamStatusDetail } from 'app/types/OwnershipProgramData';
interface Props {
  teams: OwnershipTeamStatusDetail[];
  title: string;
}

export const TeamStatusDetail = ({ title, teams }: Props) => {
  return (
    <StatusDetailWrapper>
      <SubCommonSection title={title}>
        <Row gutter={[19, 30]}>
          {teams?.map((data, index) => (
            <Col key={index} xl={4} sm={8} xs={24}>
              <TeamStatusCard name={data.projectName} level={data.level} id={data.id} />
            </Col>
          ))}
        </Row>
      </SubCommonSection>
    </StatusDetailWrapper>
  );
};

import styled from 'styled-components';

export const OwnershipReportWrapper = styled.div`
  .title {
    font-weight: bold;
    color: #0e50a4;
    padding-bottom: 1em;
  }
  span {
    .ant-select {
      margin-left: 2.2em;
      font-weight: normal;
    }
  }

  .ant-table-wrapper {
    padding-top: 1.5em;
  }
`;

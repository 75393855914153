import { Avatar, Button, Col, Divider, Row, Space, Image } from 'antd';
import { IssueStatusItem } from 'app/components/IssueReportIdea/IssueReportIdeaHelper';
import { formAction } from 'app/pages/AboutADM/IssueReportIdea/IssueReportIdea';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import { nameAbbreviation } from 'app/utils/stringUtils';
import moment from 'moment';
import { AuthContext } from 'app/contexts/AuthContext';
import { useContext } from 'react';
import { roleNames } from 'app/common/constants';

interface IIssueReportDetailProps {
  record: any;
  isAllowed: boolean;
  onShowModal: (formAct: formAction, formData: any) => void;
  onShowDeleteConfirm: (id: number) => void;
  onGetEditorState: (detail: string) => EditorState;
}

export const IssueReportDetailInfo = (props: IIssueReportDetailProps) => {
  const record = props.record;
  const { user } = useContext(AuthContext);

  return (
    <Row>
      <Col span={16} style={{ paddingRight: 30, borderRight: '1px solid #f0f0f0' }}>
        <Row>
          {props.isAllowed ? (
            <Col span={24}>
              <Button
                type="primary"
                className="btn-edit"
                style={{ float: 'right' }}
                onClick={() => {
                  props.onShowModal(formAction.Edit, {
                    id: record.id,
                    name: record.name,
                    type: record.type,
                    status: record.status,
                    detail: record.detail,
                    issueReportIdeaImages: record.issueReportIdeaImages,
                    feedback: record.feedback,
                  });
                }}
              >
                Edit
              </Button>
              <a
                className="btn-delete"
                style={{ float: 'right', color: '#108ee9', marginRight: '20px', paddingTop: '4px' }}
                onClick={() => props.onShowDeleteConfirm(record.id)}
              >
                Delete
              </a>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Editor editorState={props.onGetEditorState(record.detail)} readOnly={true} toolbarHidden={true} />
          </Col>
          {record.issueReportIdeaImages && record.issueReportIdeaImages.length > 0 ? (
            <>
              <Divider style={{ marginBottom: '10px' }} />
              <b style={{ marginBottom: '15px' }}>Images: </b>
              <Col span={24} style={{ marginBottom: '20px' }}>
                {record.issueReportIdeaImages?.map((item) => {
                  return (
                    <>
                      <Image width={100} src={item.path} style={{ paddingRight: '15px' }} />
                    </>
                  );
                })}
              </Col>
            </>
          ) : (
            <></>
          )}

          <b>Admin comment: </b>
          <Col span={24}>{record.feedback}</Col>
        </Row>
      </Col>
      <Col span={8} style={{ paddingLeft: 30 }}>
        <div style={{ textAlign: 'center' }}>
          <b>History</b>
        </div>
        <Divider />
        {record.issueReportStatusHistories
          .sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime())
          .map((item) => {
            return (
              <Row key={item.id} style={{ marginBottom: '16px' }}>
                <Col span={7}>
                  <IssueStatusItem item={item} />
                </Col>
                <Col span={11}>
                  <Space>
                    <Avatar
                      style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle' }}
                      size="large"
                      src={item.userInfo?.avatarUrl}
                    >
                      {nameAbbreviation(item.userInfo?.fullName)}
                    </Avatar>
                    {item.userInfo?.fullName}
                  </Space>
                </Col>
                <Col span={6}>
                  <div style={{ float: 'right', paddingTop: '8px', paddingRight: '10px' }}>
                    {moment(item.creationTime).format('MM-DD-YY HH:mm')}
                  </div>
                </Col>
              </Row>
            );
          })}
      </Col>
    </Row>
  );
};

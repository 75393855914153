import { SelectOptions } from 'app/types/entity';
import { Entity, PagedRequest } from './entity';
import { UserResponse } from './user';
import { GroupedTeamModel } from './GroupedTeamModel';

export enum TeamType {
  MainGroup = -1,
  Portfolio = 1,
  DeliveryStream = 2,
  ReleaseTrain = 3,
  Team = 4,
  IssueReportIdea = 5,
}

export enum TeamWorkingType {
  Development = 1,
  KTLO = 2,
  SRE = 3,
  Triage = 4,
}

export const TeamTypeConst = {
  Portfolio: 1,
  DeliveryStream: 2,
  ReleaseTrain: 3,
  Team: 4,
} as const;

export const teamOptions: SelectOptions<TeamType>[] = [
  {
    label: 'Portfolio',
    value: 1,
  },
  {
    label: 'Delivery Stream',
    value: 2,
  },
  {
    label: 'Release Train',
    value: 3,
  },
  {
    label: 'Team',
    value: 4,
  },
];

export interface TeamModel {
  name: string;
}

export interface UpdateTeamModel {
  scrumMasterID?: number;
  isFPTInvolvded: boolean;
  model: string;
  workingType: TeamWorkingType;
  authorizedUserIds: number[];
}
export interface TeamRequest extends PagedRequest {
  name: string;
  users: UserResponse[];
  parentId?: number;
  location?: string;
  type?: TeamType;
  isFPTInvolvded: boolean;
  scrumMaster?: number;
}

export interface TeamResponse extends Entity {
  name: string;
  isFPTInvolvded: boolean;
  scrumMasterID?: number;
  model: string;
  type: TeamType;
  workingType: TeamWorkingType;
  scrumMaster: UserResponse;
  parentId: number;
  isFavorite: boolean;
  teamDataResponse: TeamDataResponse;
  projectGroupName: string;
  authorizedUserIds: number[];
}

export interface TeamDataResponse {
  subTeamName: string;
  subTeamId: number;
  releaseTrainId: number;
  releaseTrainName: string;
  deliveryStreamId: number;
  deliveryStreamName: string;
  portfolioId: number;
  portfolioName: string;
}

export interface TeamResponseModel {
  totalRows: number;
  teams: TeamResponse[];
}

export interface ITeamParentContext {
  selectedIterationName: string;
  setSelectedIterationName: (iterationName: string) => void;
  iterationData: GroupedTeamModel;
  loading: boolean;
}

import { Tag } from 'antd';
import { TicketResponse } from 'app/types/PlannedWorkItem';
import { useState } from 'react';
import { CommitEditButtonWrapper } from './CommitEditButton.styled';

interface IProps {
  record: TicketResponse;
  onChange?: (status: boolean) => void;
}
export const CommitEditButton = ({ record, onChange }: IProps) => {
  const [commitStatus, setCommitStatus] = useState(record.isCommitted);

  const change = (status: boolean) => {
    onChange(status);
    setCommitStatus(status);
  };

  return (
    <CommitEditButtonWrapper>
      <Tag color="#108ee9" className={commitStatus && 'is-selected'} onClick={() => change(true)}>
        Y
      </Tag>
      <Tag color="#f3c300" className={!commitStatus && 'is-selected'} onClick={() => change(false)}>
        N
      </Tag>
    </CommitEditButtonWrapper>
  );
};

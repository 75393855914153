import { Avatar, Card, Image } from 'antd';
import { TopPerformerResponse } from 'app/types/entity';
import { useEffect, useRef } from 'react';
import './TopPerformerCard.scss';

interface IProps {
  performer: TopPerformerResponse;
}

export const TopPerformerCard = (props: IProps) => {
  let performer = props.performer;
  if (!performer) return <div />;
  let avatar = performer.avatarUrl ? performer.avatarUrl : '/assets/img/c99-default-avatar.png';
  return performer == null ? (
    <div />
  ) : (
    <div className={`top-performer-card`}>
      <Card className={`rank-${performer.rank}`}>
        <div className="name">{performer.fullName}</div>
        <table>
          <tbody>
            <tr>
              <td>
                <Avatar src={<Image src={avatar} crossOrigin="anonymous" />} />
              </td>
              <td className="caption-cell">
                <div className="caption">{performer.caption}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </div>
  );
};

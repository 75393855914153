import { Tag } from 'antd';
import { IIssueReportIdeaResponse } from 'app/types/IssueReportIdeaModel';
import './IssueReportIdea.scss';

export enum IssueReportStatus {
  Todo = 0,
  Confirm = 1,
  Inprogress = 2,
  Done = 3,
  Reject = 4,
  Duplicate = 5,
  Reopen = 6,
}

export function IssueReportStatusToText(enumValue: IssueReportStatus): string {
  switch (enumValue) {
    case IssueReportStatus.Todo:
      return 'To do';
    case IssueReportStatus.Confirm:
      return 'Confirmed';
    case IssueReportStatus.Inprogress:
      return 'In progress';
    case IssueReportStatus.Done:
      return 'Done';
    case IssueReportStatus.Reject:
      return 'Rejected';
    case IssueReportStatus.Duplicate:
      return 'Duplicated';
    case IssueReportStatus.Reopen:
      return 'Reopen';
  }
}

export enum IssueReportType {
  Idea = 0,
  Issue = 1,
  Enhance = 2,
}

export function IssueReportTypeToText(enumValue: IssueReportType): string {
  switch (enumValue) {
    case IssueReportType.Idea:
      return 'Idea';
    case IssueReportType.Issue:
      return 'Issue';
    case IssueReportType.Enhance:
      return 'Enhancement';
  }
}

interface IProps {
  item: IIssueReportIdeaResponse;
}

export const IssueStatusItem = ({ item }: IProps) => {
  let bgColor = '';
  let text = '';
  switch (item.status) {
    case IssueReportStatus.Todo:
      text = 'To do';
      bgColor = 'todo';
      break;
    case IssueReportStatus.Confirm:
      text = 'Confirmed';
      bgColor = 'confirmed';
      break;
    case IssueReportStatus.Done:
      text = 'Done';
      bgColor = 'done';
      break;
    case IssueReportStatus.Inprogress:
      text = 'In progress';
      bgColor = 'inprogress';
      break;
    case IssueReportStatus.Reject:
      text = 'Rejected';
      bgColor = 'reject';
      break;
    case IssueReportStatus.Duplicate:
      text = 'Duplicated';
      bgColor = 'duplicate';
      break;
    case IssueReportStatus.Reopen:
      text = 'Reopen';
      bgColor = 'reopen';
      break;
  }

  return (
    <Tag className={`issue-report-status ${bgColor}`} style={{ width: '102px' }}>
      <span>{text}</span>
    </Tag>
  );
};

import React, { useContext, useEffect } from 'react';
import { LinkBoxArrowIcon, OganizationModalWrapper } from './OganizationModal.styled';
import { ModalSection } from './ModalSection';
import { useState } from 'react';
import { Button, Col, Row, Space, Tag } from 'antd';
import { OrganizationForm } from './../OganizationForm/OrganizationForm';
import { OrganizationFormPayload, ProductType } from './../../../types/Organizational';
import { Editor } from 'react-draft-wysiwyg';
import { DependenciesChart } from '../DependenciesChart/DependenciesChart';
import { fetchTeamDetail } from 'app/apis/statisticOrganization';
import { UserContext } from 'app/contexts/UserContext';
import { roleNames } from 'app/common/constants';
import UserProfileIcon from 'app/pages/TeamDetail/UserProfileIcon';
import Icon from '@ant-design/icons';
import BoxArrowIcon from './BoxArrowIcon';
interface IOrganizationModal {
  teamId: number;
  visible: boolean;
  onClose: (visible: boolean) => void;
}

export const OrganizationModal = ({ visible, onClose, teamId }: IOrganizationModal) => {
  const [isSubmiting, setIsSubmitting] = useState<boolean>(false);
  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [teamData, setTeamData] = useState<OrganizationFormPayload>();

  const {
    user: { userRole },
  } = useContext(UserContext);
  useEffect(() => {
    if (teamId) {
      setLoading(true);
      const GetDataTeam = async () => {
        setTeamData(undefined);
        const data = await fetchTeamDetail(teamId);
        setLoading(false);
        setTeamData(data);
      };
      GetDataTeam();
    }
  }, [teamId, isFinish]);
  return (
    <>
      <OganizationModalWrapper
        title="Detail"
        visible={visible}
        onClose={() => {
          onClose(false);
          setIsEdit(false);
        }}
        width={800}
        footer={false}
        destroyOnClose={true}
      >
        {loading && <span>loading..</span>}
        {!loading && (
          <div className="modal-body">
            <Row align="middle" justify="space-between">
              <Col>
                <div className="title-header">
                  <p>
                    {teamData?.teamName}
                    <LinkBoxArrowIcon to={`/teams/${teamData?.teamID}`}>
                      <Icon component={BoxArrowIcon} />
                    </LinkBoxArrowIcon>
                  </p>
                  {teamData?.scrumMaster && teamData?.scrumMaster?.email && (
                    <Space>
                      <Icon component={UserProfileIcon} />
                      <span style={{ color: '#024fa5' }}>Scrum Master: {teamData.scrumMaster.email}</span>
                    </Space>
                  )}
                </div>
              </Col>
              {(userRole === roleNames.pqa || userRole === roleNames.admin) && (
                <Col>
                  {!isEdit && (
                    <Button className="btn" type="primary" onClick={() => setIsEdit(true)}>
                      Edit
                    </Button>
                  )}
                  {isEdit && (
                    <>
                      <Button className="btn first" type="default" onClick={() => setIsEdit(false)}>
                        Cancel
                      </Button>
                      <Button
                        className="btn"
                        type="primary"
                        htmlType="submit"
                        form="organization"
                        loading={isSubmiting}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Col>
              )}
            </Row>

            <Row>
              <Col span={24}>
                {isEdit && (
                  <OrganizationForm
                    dataSource={teamData}
                    setIsSubmitting={setIsSubmitting}
                    setEditForm={setIsEdit}
                    setIsFinish={setIsFinish}
                    isFinish={isFinish}
                  />
                )}
                {!isEdit && (
                  <>
                    <ModalSection title="workload">
                      {teamData &&
                        (teamData.products && teamData.products.length > 0 ? (
                          teamData.products
                            ?.filter((x) => x.type === ProductType.Workload)
                            ?.map((item) => {
                              return (
                                <>
                                  <Tag>{item.name}</Tag>
                                </>
                              );
                            })
                        ) : (
                          <Row justify="center" className="default-no-data-text">
                            No data
                          </Row>
                        ))}
                    </ModalSection>
                    <ModalSection title="Keywords">
                      {teamData &&
                        (teamData.products && teamData.products.length > 0 ? (
                          teamData.products
                            ?.filter((x) => x.type === ProductType.Tag)
                            ?.map((item) => {
                              return (
                                <>
                                  <Tag>{item.name}</Tag>
                                </>
                              );
                            })
                        ) : (
                          <Row justify="center" className="default-no-data-text">
                            No data
                          </Row>
                        ))}
                    </ModalSection>
                    <ModalSection title="Technical Stack">
                      {teamData &&
                        (teamData.products && teamData.products.length > 0 ? (
                          teamData.products
                            ?.filter((x) => x.type === ProductType.TechnicalSatck)
                            ?.map((item) => {
                              return (
                                <>
                                  <Tag>{item.name}</Tag>
                                </>
                              );
                            })
                        ) : (
                          <Row justify="center" className="default-no-data-text">
                            No data
                          </Row>
                        ))}
                    </ModalSection>

                    <ModalSection title="stakeholders">
                      {teamData &&
                        (teamData.stakeHolders && teamData.stakeHolders.length > 0 ? (
                          teamData.stakeHolders?.map((stakeholder, index) => {
                            let isEnd = teamData?.stakeHolders.length === index + 1;
                            return (
                              <span key={index}>
                                <span>{stakeholder.name} </span>
                                <span className="stakeholder-name">
                                  ({stakeholder.role}){isEnd ? null : ', '}
                                </span>
                              </span>
                            );
                          })
                        ) : (
                          <Row justify="center" className="default-no-data-text">
                            No data
                          </Row>
                        ))}
                    </ModalSection>

                    <ModalSection title="description">
                      {teamData &&
                        (teamData.descriptions && JSON.parse(teamData?.descriptions)?.blocks?.length > 0 ? (
                          <Editor
                            readOnly={true}
                            toolbarHidden={true}
                            wrapperClassName="editor-wrapper"
                            editorClassName="editor-box"
                            toolbarClassName="editor-toolbar"
                            defaultContentState={JSON.parse(teamData?.descriptions)}
                          />
                        ) : (
                          <Row justify="center" className="default-no-data-text">
                            No data
                          </Row>
                        ))}
                    </ModalSection>

                    <ModalSection title="product roadmap">
                      {teamData &&
                        (teamData.roadmap && JSON.parse(teamData?.roadmap)?.blocks?.length > 0 ? (
                          <Editor
                            readOnly={true}
                            toolbarHidden={true}
                            wrapperClassName="editor-wrapper"
                            editorClassName="editor-box"
                            toolbarClassName="editor-toolbar"
                            defaultContentState={JSON.parse(teamData?.roadmap)}
                          />
                        ) : (
                          <Row justify="center" className="default-no-data-text">
                            No data
                          </Row>
                        ))}
                    </ModalSection>

                    <ModalSection title="dependencies">
                      {teamData && teamData.dependencies && teamData.dependencies.length > 0 && (
                        <DependenciesChart teamData={teamData} />
                      )}
                      {teamData &&
                        (teamData.dependencies && teamData.dependencies.length > 0 ? (
                          teamData.dependencies?.map((depend, index) => {
                            if (depend.descriptions) {
                              const list = depend.descriptions.split('\n');
                              return (
                                <div key={index} className="dependencies-list">
                                  <div className="title-content">{depend.teamName}</div>
                                  {list.map((line, i) => (
                                    <p key={i} className="line-content">
                                      {line}
                                    </p>
                                  ))}
                                </div>
                              );
                            }
                            return (
                              <div key={index} className="dependencies-list">
                                <div className="title-content">{depend.teamName}</div>
                              </div>
                            );
                          })
                        ) : (
                          <Row justify="center" className="default-no-data-text">
                            No data
                          </Row>
                        ))}
                    </ModalSection>
                  </>
                )}
              </Col>
            </Row>
          </div>
        )}
      </OganizationModalWrapper>
    </>
  );
};

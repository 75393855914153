import { CaretDownFilled } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import Dashboard from 'app/pages/Dashboard/Dashboard';
import { ManageUsers } from 'app/pages/ManageUsers/ManageUsers';
import MyProfile from 'app/pages/MyProfile/MyProfile';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { Link, Route } from 'react-router-dom';
import { ManageTeams } from 'app/pages/ManageTeams/ManageTeams';
import { TeamDetailContextProvider } from 'app/contexts/TeamDetailContext';
import { IterationContextProvider } from 'app/contexts/IterationContext';
import { IterationBackLogContextProvider } from 'app/contexts/IterationBackLogContext';
import { TeamDetail } from 'app/pages/TeamDetail/TeamDetail';
import { SystemMonitoring } from 'app/pages/SystemMonitoring/SystemMonitoring';
import { withAuthz } from 'app/components/HOC/withAuthz';
import { TopPerformersContextProvider } from 'app/contexts/TopPerformersContext';
import { IterationNoteContextProvider } from 'app/contexts/IterationNoteContext';
import { ReleaseTrain } from 'app/pages/ReleaseTrain/ReleaseTrain';
import { ReportWeeklyStatus } from 'app/pages/Reports/WeeklyStatus/ReportWeeklyStatus';
import { Trends } from 'app/pages/Trends/Trends';
import { LastSync } from 'app/components/LastSync/LastSync';
import { TeamParentContextProvider } from 'app/contexts/TeamParentContext';
import { DeliveryStream } from 'app/pages/DeliveryStream/DeliveryStream';
import { Portfolio } from 'app/pages/Portfolio/Portfolio';
import { OrganizationalOverview } from 'app/pages/Statistic/OrganizationChart/OrganizationalOverview';
import { HangFireDashboard } from '../components/HangFireDashboard';
import { ProjectManagement } from '../pages/ProjectManagement/ProjectManagement';
import { OwnershipOverview } from 'app/pages/Statistic/Ownership/OwnershipOverview/OwnershipOverview';
import { OwnershipStatus } from 'app/pages/Statistic/Ownership/OwnershipStatus/OwnershipStatus';
import { OwnershipHeatmap } from 'app/pages/Statistic/Ownership/OwnershipHeatmap/OwnershipHeatmap';
import { OwnershipDefinition } from 'app/pages/OwnershipDefinition/OwnershipDefinition';
import { ManageCriteria } from 'app/pages/CriteriaManagement/ManageCriteria';
import { OwnershipConfigContextProvider } from 'app/contexts/OwnershipConfigContext';
import { TeamOwnershipConfig } from 'app/pages/CriteriaManagement/CriteriaConfigure/TeamOwnershipConfig';
import { OwnershipReport } from 'app/pages/Reports/OwnershipReport/OwnershipReport';

import { Holiday } from 'app/pages/Holiday/Holiday';
import { DisplayComponentsContextProvider } from 'app/contexts/DisplayComponentsContext';
import { IssueReportIdea } from 'app/pages/AboutADM/IssueReportIdea/IssueReportIdea';
import { ReleaseNote } from 'app/pages/AboutADM/ReleaseNote/ReleaseNote';
import { GuidelineFaq } from 'app/pages/GuidelineFaq/GuidelineFaq';
import { IterationStatus } from 'app/pages/Reports/IterationStatus/IterationStatus';
import { UncompletionReport } from 'app/pages/Reports/UncompletionReport/UncompletionReport';
import { Definition } from 'app/pages/Definition/Definition';
import { DefinitionConfigContextProvider } from 'app/contexts/DefinitionConfigContext';
import { HallOfFame } from 'app/pages/HallOfFame/HallOfFame';

import { HallOfFameReport } from 'app/pages/HallOfFame/HallOfFameReport';
const { SubMenu } = Menu;

// https://v5.reactrouter.com/web/api/Route
export interface IRoute {
  path: string;
  component?: ReactElement;
  exact?: boolean;
  // For permission
  claim?: string;
  scopes?: string;
  relativeWithParent?: boolean; // If true, we prepend parent path to this child
}

export interface AppRoute {
  text?: string;
  route: IRoute;
  visibleOnMenu?: boolean;
  childRoutes?: AppRoute[];
}

export const UserRoutes: AppRoute[] = [
  {
    text: 'Overview',
    route: {
      path: '/dashboard',
      component: <Dashboard />,
      exact: true,
      claim: 'iterations::get_team_overview',
    },
    visibleOnMenu: true,
  },
  {
    text: 'Trends',
    route: {
      path: '/trending',
      component: <Trends />,
      exact: true,
      // claim: 'trend',
    },
    visibleOnMenu: false,
  },
  {
    text: 'Org Chart',
    route: {
      path: '/organizational-chart',
      component: <OrganizationalOverview />,
      exact: true,
      // claim: 'stats',
    },
    visibleOnMenu: true,
  },
  {
    text: 'Reports',
    route: {
      path: '/reports',
      component: null,
      exact: true,
      claim: 'rpt',
    },
    visibleOnMenu: true,
    childRoutes: [
      {
        text: 'Weekly Status',
        route: {
          path: '/weekly-status',
          component: <ReportWeeklyStatus />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Iteration Status',
        route: {
          path: '/iteration-status',
          component: <IterationStatus />,
          exact: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Uncompletion Report',
        route: {
          path: '/uncompletion-report',
          component: <UncompletionReport />,
          exact: true,
        },
        visibleOnMenu: true,
      },
    ],
  },
  {
    text: 'Team Maturity',
    route: {
      path: '/ownership',
      component: null,
      exact: true,
      claim: 'ons',
    },
    visibleOnMenu: true,
    childRoutes: [
      {
        text: 'Maturity Level',
        route: {
          path: '/ownership-level',
          component: <OwnershipOverview />,
          exact: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Maturity Heatmap',
        route: {
          path: '/ownership-heatmap',
          component: <OwnershipHeatmap />,
          exact: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Maturity Report',
        route: {
          path: '/ownership-report',
          component: <OwnershipReport />,
          exact: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Maturity Status',
        route: {
          path: '/ownership/:teamId',
          component: (
            <TeamDetailContextProvider>
              <OwnershipStatus />
            </TeamDetailContextProvider>
          ),
          exact: true,
          relativeWithParent: false,
        },
        visibleOnMenu: false,
      },
    ],
  },
  // {
  //   text: 'Guideline',
  //   route: {
  //     path: '/guideline',
  //     component: <Guideline />,
  //     exact: true,
  //     // claim: 'guideline',
  //   },
  //   visibleOnMenu: true,
  // },
  {
    text: 'My profile',
    route: {
      path: '/profile',
      component: <MyProfile />,
      exact: true,
      claim: 'myuser::get',
    },
    visibleOnMenu: false,
  },
  {
    text: 'Hall of Fame',
    route: {
      path: '/hall-of-fame',
      component: null,
      exact: true,
    },
    childRoutes: [
      {
        text: 'Hall of Fame',
        route: {
          path: '/',
          relativeWithParent: true,
          component: <HallOfFame />,
          exact: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'HoF Report',
        route: {
          path: '/report',
          relativeWithParent: true,
          component: <HallOfFameReport />,
          exact: true,
        },
        visibleOnMenu: false,
      },
    ],
    visibleOnMenu: false,
  },
  {
    text: 'About ADM',
    route: {
      path: '/about-adm',
      component: null,
      exact: true,
      claim: 'about',
    },
    visibleOnMenu: true,
    childRoutes: [
      {
        text: 'Release Notes',
        route: {
          path: '/release-note',
          component: <ReleaseNote />,
          exact: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Feedbacks',
        route: {
          path: '/feed-back',
          component: <IssueReportIdea />,
          exact: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'FAQ & Guideline',
        route: {
          path: '/faq-and-guideline',
          component: <GuidelineFaq />,
          exact: true,
        },
        visibleOnMenu: true,
      },
    ],
  },
  {
    route: {
      path: '/teams/:teamId',
      component: (
        <TeamDetailContextProvider>
          <IterationContextProvider>
            <IterationBackLogContextProvider>
              <IterationNoteContextProvider>
                <DisplayComponentsContextProvider>
                  <TopPerformersContextProvider>
                    <LastSync />
                    <TeamDetail />
                  </TopPerformersContextProvider>
                </DisplayComponentsContextProvider>
              </IterationNoteContextProvider>
            </IterationBackLogContextProvider>
          </IterationContextProvider>
        </TeamDetailContextProvider>
      ),
      exact: true,
    },
    visibleOnMenu: false,
  },
  {
    route: {
      path: '/release-train/:teamId',
      component: (
        <TeamDetailContextProvider>
          <IterationContextProvider>
            <TeamParentContextProvider>
              <ReleaseTrain />
            </TeamParentContextProvider>
          </IterationContextProvider>
        </TeamDetailContextProvider>
      ),
      exact: true,
    },
    visibleOnMenu: false,
  },
  {
    route: {
      path: '/delivery-stream/:teamId',
      component: (
        <TeamDetailContextProvider>
          <IterationContextProvider>
            <TeamParentContextProvider>
              <DeliveryStream />
            </TeamParentContextProvider>
          </IterationContextProvider>
        </TeamDetailContextProvider>
      ),
      exact: true,
    },
    visibleOnMenu: false,
  },
  {
    route: {
      path: '/portfolio/:teamId',
      component: (
        <TeamDetailContextProvider>
          <IterationContextProvider>
            <TeamParentContextProvider>
              <Portfolio />
            </TeamParentContextProvider>
          </IterationContextProvider>
        </TeamDetailContextProvider>
      ),
      exact: true,
    },
    visibleOnMenu: false,
  },
];

export const AdminRoutes: AppRoute[] = [
  {
    text: 'Admin',
    route: {
      path: '/admin',
    },
    visibleOnMenu: false,
    childRoutes: [
      {
        text: 'System Monitoring',
        route: {
          path: '/system-monitoring',
          component: <SystemMonitoring />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Team Management',
        route: {
          path: '/teams',
          component: <ManageTeams />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'User Management',
        route: {
          path: '/users',
          component: <ManageUsers />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Background Jobs Mgmt',
        route: {
          path: '/hangfire',
          component: <HangFireDashboard />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Project Mangement',
        route: {
          path: '/projects',
          component: <ProjectManagement />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Holiday',
        route: {
          path: '/holiday',
          component: <Holiday />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Maturity Criteria Management',
        route: {
          path: '/criteriamanagement',
          component: <ManageCriteria />,
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Configure Criteria',
        route: {
          path: '/configure-criteria/:teamId',
          component: (
            <OwnershipConfigContextProvider>
              <TeamOwnershipConfig />
            </OwnershipConfigContextProvider>
          ),
          exact: true,
          relativeWithParent: true,
        },
      },
      {
        text: 'Maturity Definition',
        route: {
          path: '/ownership/category',
          component: (
            <OwnershipConfigContextProvider>
              <OwnershipDefinition />
            </OwnershipConfigContextProvider>
          ),
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
      {
        text: 'Definition',
        route: {
          path: '/definition',
          component: (
            <DefinitionConfigContextProvider>
              <Definition />,
            </DefinitionConfigContextProvider>
          ),
          exact: true,
          relativeWithParent: true,
        },
        visibleOnMenu: true,
      },
    ],
  },
];

function mapRoutesToMenuItems(routes: AppRoute[], pathPrefix?: string) {
  return routes
    .filter((route) => route.visibleOnMenu || route.childRoutes?.some((cr) => cr.visibleOnMenu))
    .flatMap((route) => {
      const {
        text,
        childRoutes,
        route: { path, relativeWithParent },
        visibleOnMenu,
      } = route;

      if (childRoutes) {
        const childNodes = mapRoutesToMenuItems(childRoutes, path);
        if (!visibleOnMenu) {
          return childNodes;
        }

        return (
          <SubMenu key={`${path.replaceAll('/', '_')}_submenu`} title={text}>
            {childNodes}
          </SubMenu>
        );
      }

      return (
        <Menu.Item key={`${relativeWithParent ? pathPrefix : ''}_${path}_menuitem`.replaceAll('/', '_')}>
          <Link to={`${relativeWithParent ? pathPrefix : ''}${path}`}>{route.text}</Link>
        </Menu.Item>
      );
    });
}

// Prototype idea (using Button)
export function mapRoutesToButtonItems(
  routes: AppRoute[],
  pathPrefix: string = '',
  subMenuDropdownPlacement: 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter',
  dropDownClassname: string = 'menu-dropdown',
  isActivePredicate?: (route: AppRoute, parentRoute?: AppRoute) => boolean,
  onItemClickCallback?: () => void
) {
  return routes
    .filter((route) => route.visibleOnMenu || route.childRoutes?.some((cr) => cr.visibleOnMenu))
    .flatMap((route) => {
      const {
        text,
        route: { relativeWithParent, path },
        childRoutes,
        visibleOnMenu,
      } = route;

      if (childRoutes) {
        if (!visibleOnMenu) {
          return mapRoutesToButtonItems(
            childRoutes,
            path,
            subMenuDropdownPlacement,
            dropDownClassname,
            (r) => isActivePredicate(r, route /* parent route */),
            onItemClickCallback
          );
        }

        // Exist child node
        const menu = <Menu className={dropDownClassname}>{mapRoutesToMenuItems(childRoutes, path)}</Menu>;

        return (
          <Dropdown
            key={`${dropDownClassname}_${path}_dropdown`.replaceAll('/', '_')}
            overlay={menu}
            placement={subMenuDropdownPlacement}
          >
            <Button
              type="text"
              size="large"
              className={classNames({ 'is-active': isActivePredicate ? isActivePredicate(route) : false })}
            >
              {route.text} <CaretDownFilled />
            </Button>
          </Dropdown>
        );
      }

      return (
        <Button
          key={`${text}`}
          type="text"
          size="large"
          className={classNames({ 'is-active': isActivePredicate ? isActivePredicate(route) : false })}
          onClick={onItemClickCallback}
        >
          <Link to={`${relativeWithParent ? pathPrefix : ''}${path}`}>{text}</Link>
        </Button>
      );
    });
}

export function generateRoutesForRouter(routes: AppRoute[], pathPrefix?: string) {
  return routes.map(({ route, childRoutes }) => {
    if (childRoutes) {
      return generateRoutesForRouter(childRoutes, route.path);
    }

    const ProtectedRoute = withAuthz(route.claim)(Route);

    return (
      <ProtectedRoute
        key={`route_${route.relativeWithParent ? pathPrefix : ''}_${route.path}`.replaceAll(/\/|:/gi, '_')}
        path={`${route.relativeWithParent ? pathPrefix : ''}${route.path}`}
        exact={route.exact}
        unauthorizedRenderType="render403"
      >
        {route.component}
      </ProtectedRoute>
    );
  });
}

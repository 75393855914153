import styled from 'styled-components';

export const TeamStatusComponent = styled.div`
  height: 70px;
  padding: 0;
  cursor: pointer;
  .card {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    opacity: 1;
    border-bottom: ${(props) => props.color};
    padding-top: 1.1rem;
    width: 100%;
    height: 100%;
    /* padding: auto 10px auto 10px; */

    .name {
      text-align: left;
      font: 'normal normal normal 14px/18px Segoe UI';
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
  .color-tail {
    background-color: ${(props) => props.color};
    height: 6px;
    width: 100%;
  }

  .tags-list {
    color: ${(props) => props.color};
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 4px;
    background: #fff;
    border: 2px solid ${(props) => props.color};
    text-align: center;
  }
`;

export const TagComponent = styled.div`
  width: 25px;
  height: 25px;
  /* UI Properties */
  border: 1px solid ${(props) => props.color};
  opacity: 1;
  border-radius: 50%;
  .text-cap {
    text-align: center;
    font: normal normal bold 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: ${(props) => props.color};
    opacity: 1;
    &.more {
      font: normal normal normal 16px/21px Segoe UI;
    }
  }
`;

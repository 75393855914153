import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './common/authConfig';
import { Helmet } from 'react-helmet';
import { AppHeader } from './components/PageHeader/PageHeader';

import { Login } from './pages/Login';
import { LoginRedirect } from './pages/LoginRedirect';

import { AdminRoutes, UserRoutes, generateRoutesForRouter } from './common/routes';

import { AuthContext, AuthContextProvider } from './contexts/AuthContext';
import { TeamContextProvider } from './contexts/TeamContext ';
import { UserContextProvider } from './contexts/UserContext';
import { GettingStarted } from './pages/GettingStarted';
import { ADMFooter } from './components/ADMFooter';
import { useContext } from 'react';
import { ForbiddenMessage } from './pages/ForbiddenMessage';
import { Layout, BackTop } from 'antd';
import { ADMBreadcrumb } from './components/Breadcrumb/ADMBreadcrumb';
import NotFound from './components/404/NotFound';
import { HallOfFame } from './pages/HallOfFame/HallOfFame';
const msalInstance = new PublicClientApplication(msalConfig);

const { Content } = Layout;

const AdminRoute = ({ children, ...props }: RouteProps) => {
  const {
    user: { isAdmin },
  } = useContext(AuthContext);

  return <>{isAdmin ? children : <ForbiddenMessage />}</>;
};

export const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Helmet>
          <title>ADM Tool | Dashboard</title>
        </Helmet>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="/signin_redirect">
            <LoginRedirect />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <AuthContextProvider>
            <UserContextProvider>
              <TeamContextProvider>
                <Switch>
                  <Route path="/getting_started">
                    <GettingStarted />
                  </Route>
                  <Route>
                    <AppHeader />
                    <Layout>
                      <Layout className="site-layout">
                        <Content style={{ overflow: 'initial', padding: '0 30px' }}>
                          <div className="app-container">
                            <ADMBreadcrumb />
                            <div className="content">
                              <Switch>
                                {generateRoutesForRouter(UserRoutes)}
                                <AdminRoute>{generateRoutesForRouter(AdminRoutes)}</AdminRoute>
                                <Route path="*">
                                  <NotFound />
                                </Route>
                              </Switch>
                            </div>
                          </div>
                        </Content>
                        <ADMFooter />
                      </Layout>
                    </Layout>
                    <BackTop />
                  </Route>
                </Switch>
              </TeamContextProvider>
            </UserContextProvider>
          </AuthContextProvider>
        </Switch>
      </BrowserRouter>
    </MsalProvider>
  );
};

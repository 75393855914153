import { Select } from 'antd';
import { Search } from 'react-bootstrap-icons';
interface Props {
  onFilterChange: (value: string) => void;
  onFilterClear: () => void;
  data: Option[];
  placeholder: string;
  defaultVal?: string | number;
}
type Option = {
  label: string;
  value: number;
};
export const SearchBox = ({ onFilterChange, onFilterClear, data, placeholder, defaultVal }: Props) => {
  const onSelect = (value: string) => {
    onFilterChange(value);
  };
  const onClear = () => {
    onFilterClear();
  };

  return (
    <Select
      defaultValue={defaultVal}
      onClear={onClear}
      onSelect={onSelect}
      suffixIcon={<Search style={{ color: '#d9d9d9' }} />}
      showSearch
      allowClear
      placeholder={placeholder}
      options={data}
      optionFilterProp="label"
    />
  );
};

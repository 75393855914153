// This function use to convert UTC time which not follow ISO 8601 standard
// (missing Z at the end, ex: 2022-06-16T09:57:52) to the local time which
// client running (ex: client's local time is UTC+7, so the result will be
// 2022-06-16T16:57:52)
//

import { OverviewViewType } from 'app/types/OverviewViewType';
import moment from 'moment';

// References: https://stackoverflow.com/a/47441370/12401758
export const convertUtcDateToLocalDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

export const base64ToArrayBuffer = (data: string) => {
  var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

/**
 * This function exists because it was not possible to handle a PDF doc as binary/blob from server.
 * After some thorough googling, it was found that the best way is to send the doc
 * as base64 and handle entirely on the client side.
 * See: https://stackoverflow.com/a/45669785
 */
export const convertBase64ToPDFAndDownload = (base64Data: string, fileName: string) => {
  var arrBuffer = base64ToArrayBuffer(base64Data);

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([arrBuffer], { type: 'application/pdf' });

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  var data = window.URL.createObjectURL(newBlob);

  var link = document.createElement('a');
  document.body.appendChild(link); //required in FF, optional for Chrome
  link.href = data;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();
};

export const getOverviewQuarterSelectOptions = () => {
  return [
    {
      label: 'Latest 10 Iterations',
      value: OverviewViewType.Lastest10Iteration,
    },
    {
      label: `${moment().year()}Q${moment().quarter()} (Current)`,
      value: OverviewViewType.CurrentQuarter,
    },
    {
      label: `${moment().add(-3, 'month').year()}Q${moment().add(-3, 'month').quarter()}`,
      value: OverviewViewType.Previous1Quarter,
    },
    {
      label: `${moment().add(-6, 'month').year()}Q${moment().add(-6, 'month').quarter()}`,
      value: OverviewViewType.Previous2Quarter,
    },
    {
      label: `${moment().add(-9, 'month').year()}Q${moment().add(-9, 'month').quarter()}`,
      value: OverviewViewType.Previous3Quarter,
    },
  ];
};

import { Col, Row, Image } from 'antd';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { IterationContext } from 'app/contexts/IterationContext';
import { useFetch } from 'app/hooks/useFetch';

import { Card } from 'app/components/Card';
import { CumulativeDiagram } from 'app/charts/CumulativeDiagram';
import { IterationGoal } from 'app/components/IterationGoals/IterationGoal';
import RetrospectiveNoteSection from 'app/components/RetrospectiveNote/RetrospectiveNoteSection';
import { TopPerformers } from 'app/components/TopPerformers/TopPerformers';
import { TeamCapacityMonitor } from 'app/components/TeamCapacity/TeamCapacityMonitor/TeamCapacityMonitor';
import { IterationNote } from '../IterationNote/IterationNote';
import { PlanSection } from '../Plan/PlanSection/PlanSection';
import { ComplimentSection } from 'app/components/Compliments/ComplimentsSection';
import { UtilizedCapacity } from 'app/charts/Report/UtilizedCapacity/UltilizedCapacity';
import { IterationTeamDelivery } from 'app/components/IterationReport/IterationTeamDelivery';
import { IterationScheduleState } from 'app/components/IterationReport/IterationScheduleState';
import { CarriedOver } from 'app/components/CarriedOver/CarriedOver';

import { WorkItem } from 'app/pages/WorkItem/WorkItem';
import { CarriedOverItem } from 'app/pages/CarriedOverItem/CarriedOverItem';

import {
  fetchTaskByIterationReport,
  fetchTestcase,
  getIterationReport,
  getListCarriedOverToNextIteration,
} from 'app/apis/teamDetailClient';

import { ReportType } from 'app/types/CacheReportModel';
import { RetrospectiveType } from 'app/types/entity';
import { Iteration } from 'app/types/Iteration';
import { SubPage } from 'app/types/TeamDetail';
import { Stage, TeamCapacityResponse } from 'app/types/TeamDetailTypes';
import { orderBy } from 'lodash';

import './Report.scss';
import { ItemCommittedDelivery } from 'app/charts/ItemCommittedDelivery';
import { TicketResponse, WorkItemType } from 'app/types/PlannedWorkItem';
import { getNoteData, IIterationNote, NoteCategory } from 'app/types/IterationNote';
import Title from 'antd/lib/typography/Title';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import { CircleFillWithLabel } from 'app/components/CircleFillWithLabel/CircleFillWithLabel';
import { ReportVelocity } from 'app/charts/Report/ReportVelocity';
import { IterationNoteContext } from 'app/contexts/IterationNoteContext';
import ToolTip from 'app/components/Tooltip/ToolTip';
import { IterationBurnDown } from 'app/charts/IterationBurndown';
import { StyledPrePlanWrapper } from '../Plan/Plan.styled';
import { TestExecutionState } from 'app/charts/TestExecutionState/TestExecutionState';
import { IterationDefectsByEnvironment } from 'app/charts/IterationDefectsByEnvironment';
import { IterationDefectsByState } from 'app/charts/IterationDefects/IterationDefectsByState';
import { TestExecutionTable } from 'app/components/TestExecution/TestExecutionTable';
import { IterationBackLogContext } from 'app/contexts/IterationBackLogContext';
import { QualitySummary } from 'app/components/QualitySummary/QualitySummary';
import { TestCaseResponse } from 'app/types/TestCaseResponse';
import { merge, keyBy, values } from 'lodash';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { TestCoverage } from 'app/components/TestCoverage/TestCoverage';
import { HealthReporModel } from 'app/types/IterationStatusAndHealthResponse';
import { useContext, useEffect, useState } from 'react';
import { CustomReport } from 'app/components/CustomReport/CustomReport';
import { TeamContext } from 'app/contexts/TeamContext ';
import { DemoPlan } from 'app/components/DemoPlan/DemoPlan';
import { Attendance } from 'app/components/Attendance/Attendance';
import _ from 'lodash';
import { TeamHighLight } from 'app/components/TeamHighlight/TeamHighLight';
import { QuarterReleasePlan } from 'app/components/QuarterReleasePlan/QuarterReleasePlan';
import { ResolutionByDefect } from 'app/charts/ResolutionByDefect';
import { RootCauseCategoryByDefect } from 'app/charts/RootCauseCategoryByDefect';
import { DefectRootCauseTable } from 'app/components/Quality Report/DefectRootCauseTable';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { IterationBurnUp } from 'app/charts/IterationBurnup';

interface IProps {
  stage: Stage;
  saveNoteHandler: (iterationNote: IIterationNote) => Promise<void>;
  showReport: boolean;
}

export interface TestCaseMerge extends TicketResponse, TestCaseResponse {}
export function canEditReport(reportStatus: IterationReportStatusEnum, permissions: string[]): boolean {
  let hasPermission = permissions.some(
    (p) => p === 'iteration:report::update' || 'iteration:report::create' || 'iteration:report::update_edit_report'
  );
  return (
    (hasPermission && reportStatus !== undefined && reportStatus !== IterationReportStatusEnum.Submitted) ||
    reportStatus === undefined
  );
}

export const Report = ({ ...props }: IProps) => {
  const { page } = useContext(TeamContext);
  const { team: selectedTeam } = useContext(TeamDetailContext);
  const { selectedIteration, teamIterations } = useContext(IterationContext);
  const { iterationNotes } = useContext(IterationNoteContext);
  const [showReport, setShowReport] = useState(false);
  const { workItem } = useContext(IterationBackLogContext);
  const { handleComponentClass, handleSectionClass } = useContext(DisplayComponentsContext);

  const [mergedDataTestCase, setMergedDataTestCase] = useState<TestCaseMerge[]>();
  const [defectTickets, setDefectTickets] = useState<TicketResponse[]>();

  // Get test case state
  const { data: testCaseResults } = useFetch(() => {
    return fetchTestcase(selectedIteration.id);
  }, [selectedIteration?.id]);

  // Set showReport
  useEffect(() => {
    if (selectedIteration.iterationReport === null) {
      setShowReport(false);
    } else {
      setShowReport(props.showReport);
    }
  }, [props, selectedIteration.iterationReport]);

  useEffect(() => {
    if (!workItem || workItem.length === 0) return;
    if (!testCaseResults || testCaseResults.length === 0) return;

    var mergedResult = values(merge(keyBy(workItem, 'id'), keyBy(testCaseResults, 'ticketId')));
    const isTestCaseMerge = (r: TestCaseMerge) => {
      // r.ticketId and r.id are independent variables from TicketResponse and TestCaseReponse,
      // and are required for the merge to succeed, so we can expect both to be defined.
      // Otherwise, it ain't a TestCaseMerge.
      return r.ticketId && r.id;
    };
    setMergedDataTestCase(mergedResult.filter((r) => isTestCaseMerge(r))?.sort((a, b) => b.code.localeCompare(a.code)));
    setDefectTickets(workItem?.filter((x) => x.type === WorkItemType.Defect));
  }, [workItem, testCaseResults]);

  // Get iteration report charts
  const { data: iterationReport } = useFetch(() => {
    return getIterationReport(selectedIteration.iterationReport.id);
  }, [selectedIteration, showReport]);
  // Get data carriedOver from API
  const { data: listCarriedOver } = useFetch(() => {
    return getListCarriedOverToNextIteration(selectedIteration?.id);
  }, [selectedIteration, showReport]);
  // Filter report type
  const filterReport = (type: ReportType) => {
    const chart = iterationReport?.reportCharts?.filter((x) => x.type === type);
    if (chart === undefined || chart === null || chart.length < 1) {
      return undefined;
    }
    return chart.at(0).chartObject;
  };

  // Get report for iteration health
  const filetrReportIterationHealth = () => {
    var iterationHealth = filterReport(ReportType.IterationHealth);

    if (iterationHealth === undefined || iterationHealth === null) {
      iterationHealth = new HealthReporModel();
    }
    return iterationHealth;
  };

  const { data: dataSource } = useFetch<TeamCapacityResponse>(
    () => fetchTaskByIterationReport(selectedIteration?.id),
    [selectedIteration?.id]
  );

  const getPreviousIteration = (currentIterationId: number, source: Iteration[]): Iteration => {
    const currentIteration = source?.find((x) => x.id === currentIterationId);
    if (currentIteration === undefined) return null;
    const previousIteration = orderBy(
      source.filter((x) => x.dateTo <= currentIteration.dateFrom),
      (x) => x.dateTo,
      'desc'
    )?.[0];
    return previousIteration;
  };

  if (!showReport) {
    return (
      <StyledPrePlanWrapper>
        <Image src="/assets/img/dashboard.jpg" preview={false} width="100%" />
        <Title level={3} className="head-text">
          ITERATION REPORT
        </Title>
        <Title level={5} className="head-text">
          {`Click "Create Report" button to start reporting`}
        </Title>
      </StyledPrePlanWrapper>
    );
  }
  return (
    <div className="team-report-wrapper">
      {/* 
        Temporary ignore wrapping this block with PlanSection, waiting for future implementation from PlanSection
        (Adding custom action on the right of the title row)
      */}
      <Row
        id="iteration-highlight-section"
        className={`${handleComponentClass('report-iteration-highlight')} iteration-highlight`}
        style={{ marginBottom: '1.5rem' }}
      >
        <Col span={24}>
          <TeamHighLight page={SubPage.Report} />
        </Col>
      </Row>
      <Row
        id="iteration-goal-section"
        className={`${handleComponentClass('report-iteration-goal')} iteration-goal`}
        style={{ marginBottom: '1.5rem' }}
      >
        <Col span={24}>
          <IterationGoal page={SubPage.Report} />
        </Col>
      </Row>
      <Row className={`${handleComponentClass('report-day-off-schedule')} work-item`}>
        <Col span={24}>
          <Attendance page={SubPage.Report} />
        </Col>
      </Row>
      <PlanSection
        id="iteration-summary-section"
        key="sumary"
        title="ITERATION SUMMARY"
        titleTootip={<span>Summary of the team performance.</span>}
        withBottomSpace
        classProp={handleSectionClass('report-iteration-summary')}
      >
        <Row gutter={[24, 16]} className="chart-container">
          <Col
            id="team-delivery-export"
            xs={24}
            xxl={5}
            xl={12}
            className={`${handleComponentClass('report-team-delivery')} team-delivery-div`}
          >
            <Card
              title="Team Delivery"
              titleTooltip={
                <span>
                  Team delivery = Total points of &quot;Accepted&quot; and &quot;Released&quot; cards (User stories and
                  Defects) / Total points of all cards.
                </span>
              }
            >
              <IterationTeamDelivery dataSource={filterReport(ReportType.TeamDelivery)} isReport />
            </Card>
          </Col>
          <Col
            id="schedule-state-export"
            xs={24}
            xxl={7}
            xl={12}
            className={`${handleComponentClass('report-schedule-state')} schedule-state-div`}
          >
            <Card title="Schedule State" titleTooltip={<span>Shows number of User Stories in each state.</span>}>
              <IterationScheduleState dataSource={filterReport(ReportType.ScheduleState)} />
            </Card>
          </Col>
          <Col
            id="utilized-capacity-export"
            xs={24}
            xxl={6}
            xl={12}
            className={`${handleComponentClass('report-utilized-capacity')} utilized-capacity-div`}
          >
            <Card
              title="Utilized Capacity"
              titleTooltip={<span>Utilized capacity = (Team actual effort / Team total capacity) * 100%</span>}
            >
              <UtilizedCapacity dataSource={filterReport(ReportType.UtilizedCapacity)} />
            </Card>
          </Col>
          <Col xs={24} xxl={6} xl={12} className={handleComponentClass('report-carried-over')}>
            <Card className="carried-container">
              <CarriedOver dataIterationHealth={filetrReportIterationHealth()} dataCarriedOver={listCarriedOver} />
            </Card>
          </Col>
        </Row>
        {listCarriedOver?.length ? (
          <div id="carriedOver-section-new" className={handleComponentClass('report-carried-over-detail')}>
            <Row className="work-item-title">
              <Col span={12}>
                <PageTitle>
                  <Title level={5}>{'CARRIED OVER DETAIL'}</Title>
                </PageTitle>
              </Col>
            </Row>
            <Row className="work-item">
              <Col span={24}>
                <CarriedOverItem
                  key={`${page}-${selectedIteration.id}-${listCarriedOver?.length}`}
                  page={SubPage.Report}
                  dataSource={listCarriedOver}
                />
              </Col>
            </Row>
          </div>
        ) : (
          ''
        )}
        <Row gutter={[24, 16]} className="chart-container">
          <Col xs={24} xxl={12} xl={12} id="velocity-export" className={handleComponentClass('report-velocity')}>
            <Card
              title="Velocity"
              titleTooltip={
                <span>
                  VELOCITY allows you to easily view the velocity and capacity of your team in current iteration, and to
                  see whether the team is over or under expectation. This section pulls work from the backlog and from
                  the detailed task plan in the current iteration.
                </span>
              }
            >
              <ReportVelocity dataSource={filterReport(ReportType.Velocity)} teamId={selectedTeam?.id} />
              <IterationNote
                data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Report, NoteCategory.Velocity)}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
          <Col id="cfd-export" xs={24} xxl={12} xl={12} className={handleComponentClass('report-cfd')}>
            <Card
              title="Cumulative Flow Diagram (CFD)"
              titleTooltip={
                <>
                  <span>
                    The chart tracks the total number of tasks in the columns of the &quot;In Progress&quot; section on
                    your board each day.
                  </span>
                  <br />
                  <span>
                    The horizontal axis of the CFD represents the days in iteration for which the chart is visualizing
                    data. The vertical axis shows the cumulative number of tasks in the iteration backlogs at various
                    points in time.
                  </span>
                </>
              }
            >
              <CumulativeDiagram
                teamId={selectedTeam?.id}
                dataSource={filterReport(ReportType.CumulativeFlowDiagram)}
              />
              <IterationNote
                data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Report, NoteCategory.CFD)}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
          <Col
            id="iteration-burndown-export"
            xs={24}
            xxl={12}
            xl={12}
            className={`${handleComponentClass('report-iteration-burndown')} chart-container`}
          >
            <Card
              title="Iteration Burndown"
              titleTooltip={
                <span>
                  The Sprint Burndown Chart makes the work of the Team visible. It is a graphic representation of the
                  rate at which work is completed (hours) and how much work remains to be done. Work remaining in the
                  iteration to proactively anticipate whether the committed work will be delivered by the end of the
                  iteration.
                </span>
              }
            >
              <IterationBurnDown dataSource={filterReport(ReportType.IterationBurndown)} isReport={true} />
              <IterationNote
                data={getNoteData(
                  selectedIteration?.id,
                  iterationNotes,
                  SubPage.Report,
                  NoteCategory.IterationBurnDown
                )}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
          <Col
            id="iteration-burnup-export"
            xs={24}
            xxl={12}
            xl={12}
            className={`${handleComponentClass('report-iteration-burnup')} chart-container`}
          >
            <Card
              title="Iteration Burnup"
              titleTooltip={
                <span>
                  Work delivered so far in the iteration to proactively anticipate whether the iteration scope will be
                  delivered
                </span>
              }
            >
              <IterationBurnUp dataSource={filterReport(ReportType.IterationBurnup)} isReport={true} />
              <IterationNote
                data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Report, NoteCategory.IterationBurnUp)}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 16]} className="chart-container">
          <Col
            id="us-committed-export"
            xs={24}
            xxl={12}
            xl={12}
            className={handleComponentClass('report-us-committed')}
          >
            <Card
              title="User Stories Committed vs. Delivered by Iteration"
              titleTooltip={
                <>
                  <span>
                    The Committed vs. Delivered app shows User Stories Committed (scheduled into the iteration) and
                    Delivered (Accepted during the iteration) for current and past Iterations
                  </span>
                  <br />
                  <span>Each Iteration has two columns:</span>
                  <ul className="tooltip-content">
                    <li>
                      Committed: All stories scheduled into the sprint are shown as Committed. Includes any story
                      scheduled into the iteration at any time during the sprint, even if later removed.
                    </li>
                    <li>Delivered: Stories accepted during the sprint are shown as Delivered</li>
                  </ul>
                  <br />
                  <span>Within each column:</span>
                  <ul className="tooltip-content">
                    <li>Planned: Stories added to the Iteration before the start of the first* day </li>
                  </ul>
                  <br />
                  <span>NOTE: Committed & Planned (green) = baseline Sprint Commitment</span>
                  <ul className="tooltip-content">
                    <li>Unplanned: Stories added to the Iteration on or after the first day</li>
                  </ul>
                </>
              }
            >
              <ItemCommittedDelivery
                dataSource={filterReport(ReportType.UserStoriesCommittedVsDelivered)}
                itemType={WorkItemType.UserStory}
              />
              <IterationNote
                data={getNoteData(
                  selectedIteration?.id,
                  iterationNotes,
                  SubPage.Report,
                  NoteCategory.UserStoriesCommittedDelivered
                )}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
          <Col
            id="defect-commited-export"
            xs={24}
            xxl={12}
            xl={12}
            className={handleComponentClass('report-defect-committed')}
          >
            <Card
              title="Defects Committed vs. Delivered by Iteration"
              titleTooltip={
                <>
                  <span>
                    The Committed vs. Delivered app shows Defects Committed (scheduled into the iteration) and Delivered
                    (Accepted during the iteration) for current and past Iterations
                  </span>
                  <br />
                  <span>Each Iteration has two columns:</span>
                  <ul className="tooltip-content">
                    <li>
                      Committed: All defects scheduled into the sprint are shown as Committed. Includes any story
                      scheduled into the iteration at any time during the sprint, even if later removed.
                    </li>
                    <li>Delivered: Defects accepted during the sprint are shown as Delivered</li>
                  </ul>
                  <br />
                  <span>Within each column:</span>
                  <ul className="tooltip-content">
                    <li>Planned: Defects added to the Iteration before the start of the first* day </li>
                  </ul>
                  <br />
                  <span>NOTE: Committed & Planned (green) = baseline Sprint Commitment</span>
                  <ul className="tooltip-content">
                    <li>Unplanned: Defects added to the Iteration on or after the first day</li>
                  </ul>
                </>
              }
            >
              <ItemCommittedDelivery
                dataSource={filterReport(ReportType.DefectsCommittedVsDelivered)}
                itemType={WorkItemType.Defect}
              />
              <IterationNote
                data={getNoteData(
                  selectedIteration?.id,
                  iterationNotes,
                  SubPage.Report,
                  NoteCategory.DefectsAndDelivered
                )}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
        </Row>
      </PlanSection>

      <PlanSection
        id="quality-report-section"
        key="quality"
        title="QUALITY REPORT"
        withBottomSpace
        classProp={handleSectionClass('report-quality-report')}
      >
        <Row gutter={[20, 16]} className="chart-container">
          <Col
            id="test-coverage-export"
            xs={24}
            xl={12}
            xxl={7}
            className={handleComponentClass('report-test-coverage')}
          >
            <Card title="Test Coverage">
              <TestCoverage dataSource={testCaseResults} />
            </Card>
          </Col>
          <Col
            xs={24}
            xl={12}
            xxl={7}
            id="test-execution-state-export"
            className={handleComponentClass('report-test-execution-state')}
          >
            <Card title="Test Execution State">
              <TestExecutionState dataSource={filterReport(ReportType.TestExecutionState)?.testCaseResults} />
            </Card>
          </Col>
          <Col
            id="quality-summary-export"
            xs={24}
            xl={24}
            xxl={10}
            className={handleComponentClass('report-quality-summary')}
          >
            <Card title="Quality Summary">
              <QualitySummary
                qualitySummary={iterationReport?.qualitySummary}
                iterationReportID={selectedIteration?.iterationReport?.id}
              />
            </Card>
          </Col>
        </Row>
        <Row className={`${handleComponentClass('report-test-execution-detail')} test-execution-table`}>
          <Col id="test-execution-detail-export" span={24}>
            <TestExecutionTable page={SubPage.Report} dataSource={mergedDataTestCase} />
            <IterationNote
              data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Report, NoteCategory.TestExecutionTable)}
              onSave={async (note) => await props.saveNoteHandler(note)}
            />
          </Col>
        </Row>
        <Row gutter={[24, 16]} className="chart-container">
          <Col
            id="defects-by-state-export"
            xs={24}
            xxl={12}
            xl={12}
            className={handleComponentClass('report-defects-by-state')}
          >
            <Card
              title="Iteration Defects by State"
              titleTooltip={
                <span>
                  All defects in the iteration, categorized by state to assess end of iteration technical debt.
                </span>
              }
            >
              <IterationDefectsByState
                teamId={selectedTeam?.id}
                iteration={selectedIteration}
                dataSource={filterReport(ReportType.IterationDefectsByState)}
              />
              <IterationNote
                data={getNoteData(
                  selectedIteration?.id,
                  iterationNotes,
                  SubPage.Report,
                  NoteCategory.IterationDefectsByState
                )}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
          <Col
            id="defects-by-environment-export"
            xs={24}
            xxl={12}
            xl={12}
            className={handleComponentClass('report-defects-by-environment')}
          >
            <Card
              title="Iteration Defects by Environment"
              titleTooltip={
                <span>
                  All defects in the iteration, categorized by environment to assess end of iteration technical debt.
                </span>
              }
            >
              <IterationDefectsByEnvironment
                teamId={selectedTeam?.id}
                iteration={selectedIteration}
                dataSource={filterReport(ReportType.IterationDefectsByEnvironment)}
              />
              <IterationNote
                data={getNoteData(
                  selectedIteration?.id,
                  iterationNotes,
                  SubPage.Report,
                  NoteCategory.IterationDefectsByEnvironment
                )}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
          <Col
            id="root-cause-category-by-defect-export"
            xs={24}
            xxl={12}
            xl={12}
            className={handleComponentClass('report-root-cause-category-by-defect')}
          >
            <Card
              title="Root Cause Category by Defect"
              titleTooltip={
                <span>
                  All defects in the iteration, categorized by state to assess end of iteration technical debt.
                </span>
              }
            >
              <RootCauseCategoryByDefect
                teamId={selectedTeam?.id}
                iteration={selectedIteration}
                dataSource={filterReport(ReportType.RootCauseCategoryByDefect)}
              />
              <IterationNote
                data={getNoteData(
                  selectedIteration?.id,
                  iterationNotes,
                  SubPage.Report,
                  NoteCategory.RootCauseCategoryByDefect
                )}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
          <Col
            id="resolution-by-defect-export"
            xs={24}
            xxl={12}
            xl={12}
            className={handleComponentClass('report-resolution-by-defect')}
          >
            <Card
              title="Resolution by Defect"
              titleTooltip={
                <span>
                  All defects in the iteration, categorized by environment to assess end of iteration technical debt.
                </span>
              }
            >
              <ResolutionByDefect
                teamId={selectedTeam?.id}
                iteration={selectedIteration}
                dataSource={filterReport(ReportType.ResolutionByDefect)}
              />
              <IterationNote
                data={getNoteData(
                  selectedIteration?.id,
                  iterationNotes,
                  SubPage.Report,
                  NoteCategory.ResolutionByDefect
                )}
                onSave={async (note) => await props.saveNoteHandler(note)}
              />
            </Card>
          </Col>
        </Row>
        <Row className="chart-container defect-root-cause-table">
          <Col
            id="defect-root-cause-analysis-export"
            span={24}
            className={`${handleComponentClass('report-defect-root-cause-analysis')} section`}
          >
            <Card
              title="Defect Root Cause Analysis"
              titleTooltip={
                <span>
                  All defects in the iteration, categorized by state to assess end of iteration technical debt.
                </span>
              }
            >
              {defectTickets ? <DefectRootCauseTable dataSource={defectTickets} /> : null}
            </Card>
          </Col>
        </Row>
      </PlanSection>
      <PlanSection
        id="capacity-detail-export"
        title="CAPACITY DETAILS"
        titleTootip={
          <span>
            Effort column: Red circle indicator shows that this card has NO CHANGE in effort for 2 consecutive working
            days. SM should work with owner to detect reason.
          </span>
        }
        withBottomSpace
        classProp={handleComponentClass('report-capacity-details')}
      >
        <Row>
          <Col span={24}>
            <TeamCapacityMonitor dataMonitor={dataSource} page={SubPage.Report} />
            <IterationNote
              data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Report, NoteCategory.CapacityDetail)}
              onSave={async (note) => await props.saveNoteHandler(note)}
            />
          </Col>
        </Row>
      </PlanSection>
      <div id="iteration-backlog-section" className={handleComponentClass('report-iteration-backlog')}>
        <Row className="work-item-title">
          <Col span={14}>
            <PageTitle>
              <Title level={5}>
                {'ITERATION BACKLOG'}
                <ToolTip title={<span>Updating...</span>} />
              </Title>
            </PageTitle>
          </Col>
          <Col span={10}>
            {/* <Row justify="end">
              <CircleFillWithLabel color="#FF5A5A" size="25px" label="Uncompleted Card" id="carried-over-icon" />
            </Row> */}
          </Col>
          <DeviderWrapper />
        </Row>
        <Row className="work-item">
          <Col span={24}>
            <WorkItem key={`${page}-${selectedIteration.id}-${workItem?.length}`} page={SubPage.Report} />
          </Col>
        </Row>
      </div>
      <Col span={24} className={`${handleComponentClass('report-demo-item')} section`}>
        <DemoPlan page={SubPage.Report} />
      </Col>
      <Col className={handleComponentClass('report-compliments')}>
        <ComplimentSection iterationId={selectedIteration?.id} header="5 star compliments" />
      </Col>
      <PlanSection
        id="retrospective-section"
        key="retro"
        title="RETROSPECTIVE"
        titleTootip={
          <span>
            By the end of the Offline Sprint Retrospective, the Scrum Master should have identified key conclusions
            about what went well? What could be improved in this iteration and improvements that it will implement in
            the next iteration. Anytime you finish an action, SM can mark it done on tool.
          </span>
        }
        withBottomSpace
        classProp={handleComponentClass('report-retrospective')}
      >
        <Row>
          <Col id="previous-action-export" span={24}>
            <RetrospectiveNoteSection
              backgroundColor="#FFEEFD"
              iterationId={getPreviousIteration(selectedIteration.id, teamIterations)?.id}
              header="Actions of previous iteration"
              type={RetrospectiveType.Todo}
              iterationStage={props.stage}
              isSelectedIteration={false}
              isAction={true}
            />
          </Col>
        </Row>
        <Row>
          <Col id="went-well-export" span={24}>
            <RetrospectiveNoteSection
              backgroundColor="#D5FFE9"
              iterationId={selectedIteration.id}
              header="What did we do well?"
              type={RetrospectiveType.Well}
              iterationStage={props.stage}
              isSelectedIteration={true}
            />
          </Col>
        </Row>
        <Row>
          <Col id="need-improve-export" span={24}>
            <RetrospectiveNoteSection
              backgroundColor="#FFEBEB"
              iterationId={selectedIteration.id}
              header="What should we have done better?"
              type={RetrospectiveType.Bad}
              iterationStage={props.stage}
              isSelectedIteration={true}
            />
          </Col>
        </Row>
        <Row>
          <Col id="follow-up-export" span={24}>
            <RetrospectiveNoteSection
              backgroundColor="#D7E7FD"
              iterationId={selectedIteration.id}
              header="Follow-Up Actions"
              type={RetrospectiveType.Todo}
              iterationStage={props.stage}
              isSelectedIteration={true}
            />
          </Col>
        </Row>
      </PlanSection>
      <TopPerformers id="best-performance-section" className={handleComponentClass('report-best-performance')} />
      <CustomReport
        id="custom-export"
        iterationId={selectedIteration?.id}
        className={handleComponentClass('report-additional-report')}
      />
    </div>
  );
};

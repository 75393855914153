import React from 'react';

interface IProps {
  value?: any;
  as?: React.ElementType;
}

export const HtmlText = ({
  value,
  as: Component = 'div',
  ...props
}: IProps & React.HTMLAttributes<React.ElementType>) => (
  <Component {...props} dangerouslySetInnerHTML={{ __html: value ?? '' }} />
);

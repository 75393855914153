import React from 'react';
import { Drawer, Spin } from 'antd';
import { TagsDependenciesChart } from '../DependenciesChart/TagsDependenciesChart';
import { useFetch } from 'app/hooks/useFetch';
import { fetchTagsDependencies } from 'app/apis/statisticOrganization';
import { ProductType } from 'app/types/Organizational';
import { TeamDetailLinkTags } from 'app/components/TeamDetailLinkTags/TeamDetailLinkTags';

interface ITagsModal {
  visible: boolean;
  onClose: () => void;
  tagName: string;
  currentType: ProductType;
}

export const TagsModal = ({ visible, onClose, tagName, currentType }: ITagsModal) => {
  const { data: teams, loading } = useFetch(() => fetchTagsDependencies(tagName, currentType), [tagName]);

  return (
    <Drawer title={`#${tagName}`} placement="right" onClose={onClose} visible={visible} width={800}>
      {loading ? <Spin /> : <TeamDetailLinkTags datasource={teams} />}
    </Drawer>
  );
};

import { useContext, useEffect, useState } from 'react';
import { Card, Empty, Space, Form, DatePicker, Button, Row, Tooltip, Col } from 'antd';
import { PlusLg } from 'react-bootstrap-icons';
import RetrospectiveNoteCard from '../RetrospectiveNoteCard/RetrospectiveNoteCard';
import SubSection from '../SubSection/SubSection';
import { AuthContext } from 'app/contexts/AuthContext';
import { useFetch } from 'app/hooks/useFetch';
import { ActionStatus, RetrospectiveNoteRequest, RetrospectiveNoteResponse, RetrospectiveType } from 'app/types/entity';
import { Stage } from 'app/types/TeamDetailTypes';
import { deleteItem, insertItem, updateItem } from 'app/utils/tableUtils';
import { createNote, deleteNote, getRetrospectiveNote, updateNote } from 'app/apis/retrospectiveNote';
import './RetrospectiveNoteSection.scss';
import { UserContext } from 'app/contexts/UserContext';
import { IterationContext } from 'app/contexts/IterationContext';
import { useAuthz } from 'app/hooks/useAuthz';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { StyledModal } from 'app/pages/ManageUsers/ManagedUsers.styled';
import TextArea from 'antd/lib/input/TextArea';
import { SearchBox } from '../SearchBox/SearchBox';
import { TopPerformersContext } from 'app/contexts/TopPerformersContext';
import moment from 'moment';
import { FormStyle, Icon, TimeStyle } from './RetrospectiveNote.styled';

export interface IProps {
  backgroundColor: string;
  iterationStage: Stage;
  header: string;
  iterationId: number;
  type: RetrospectiveType;
  isSelectedIteration: Boolean;
  onSave?: (form: RetrospectiveNoteRequest) => void;
  isAction?: boolean;
}

export interface RetrospectiveDataFormat {
  textnote: string;
  personInCharge: number;
  deadline: Date;
}

export enum SectionState {
  Initial,
  Editing,
  Creating,
}

type Option = {
  label: string;
  value: number;
};

const RetrospectiveNoteSection = ({
  header,
  backgroundColor,
  onSave,
  iterationStage: stage,
  isAction,
  ...props
}: IProps) => {
  const { user } = useContext(AuthContext);
  const { selectedIteration, reportStatus } = useContext(IterationContext);
  const { isAuthorized } = useAuthz();
  const { topPerformerSelectOptions } = useContext(TopPerformersContext);

  const {
    user: { permissions },
  } = useContext(UserContext);
  const allowEdit =
    isAuthorized &&
    (stage === Stage.Other ||
      stage === undefined ||
      (selectedIteration?.isCurrentSprint && stage === Stage.Planning)) &&
    canEditReport(reportStatus, permissions);
  const [notes, setNotes] = useState([]);
  const { data: originalData, error: fetchError } = useFetch<RetrospectiveNoteResponse[]>(
    () => getRetrospectiveNote(props.iterationId, [props.type]),
    [props.iterationId, props.type, reportStatus]
  );

  const [sectionState, setSectionState] = useState(SectionState.Initial);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [members, setMembers] = useState([]);
  const [form] = Form.useForm<RetrospectiveDataFormat>();
  const [modalData, setModalData] = useState({
    id: 0,
    textnote: '',
    personInCharge: 0,
    deadline: null,
  });

  useEffect(() => {
    if (sectionState === 1) {
      form.setFieldsValue({
        textnote: modalData.textnote,
        personInCharge: modalData.personInCharge,
        deadline: modalData?.deadline ? moment(modalData.deadline) : null,
      });
    } else {
      form.setFieldsValue({
        textnote: null,
        personInCharge: null,
        deadline: null,
      });
    }
  }, [toggleModal]);
  useEffect(() => {
    if (topPerformerSelectOptions && selectedIteration) {
      let memberOptions: Option[] = topPerformerSelectOptions.map((item) => ({
        label: item.fullName,
        value: item.memberId,
      }));

      setMembers(memberOptions);
    }
    setNotes(originalData);
  }, [originalData, topPerformerSelectOptions]);

  const onFilterPersonInChargeChange = (value: string) => {
    form.setFieldsValue({
      personInCharge: Number(value),
    });
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const deleteNoteHandler = async (id: number) => {
    await deleteNote(id);

    setNotes(deleteItem(id, notes));
  };

  const onSaveHandler = async () => {
    const { textnote, personInCharge, deadline } = form.getFieldsValue();
    if (modalData.id > 0) {
      try {
        const payload = await updateNote({
          id: modalData.id,
          content: textnote,
          iterationId: props.iterationId,
          status: ActionStatus.Active,
          type: props.type,
          personalInCharge: personInCharge,
          deadline: deadline,
        });
        setNotes(updateItem(payload, notes));
        form.resetFields();
        setToggleModal(false);
        setSectionState(SectionState.Initial);
        setModalData({
          id: 0,
          textnote: '',
          personInCharge: 0,
          deadline: null,
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      const payload = await createNote({
        id: undefined,
        content: textnote,
        iterationId: props.iterationId,
        status: ActionStatus.Active,
        type: props.type,
        personalInCharge: personInCharge,
        deadline: deadline,
      });
      setNotes(insertItem(payload, notes));
      form.resetFields();
      setToggleModal(false);
      setSectionState(SectionState.Initial);
    }
  };
  const saveActionHandler = async (note: RetrospectiveNoteResponse) => {
    if (note.id > 0) {
      const payload = await updateNote(note);
      setNotes(updateItem(payload, notes));
    } else {
      const payload = await createNote({
        id: undefined,
        content: note.content,
        iterationId: note.iterationId,
        status: note.status,
        type: note.type,
        personalInCharge: null,
        deadline: note.deadline,
      });
      setNotes(insertItem(payload, notes));
    }

    setSectionState(SectionState.Initial);
  };
  return (
    <SubSection header={header} backgroundColor={backgroundColor}>
      {props.iterationId === undefined ||
      (originalData?.length < 1 && (!allowEdit || props.isSelectedIteration === false)) ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Space direction="horizontal" className="space-horizontal" style={{ alignItems: 'normal' }}>
          {notes?.length > 0 &&
            notes.map((action) => (
              <RetrospectiveNoteCard
                dataSource={originalData}
                isSelectedIteration={props.isSelectedIteration}
                key={action.id}
                note={action}
                isAction={isAction ?? false}
                footer={(note) =>
                  note.type === RetrospectiveType.Todo && (
                    <>
                      {
                        <Space align="end" className={`footer-container ${isAction ? 'footer-preAction' : ''}`}>
                          <Tooltip
                            title={
                              <div>
                                <span>
                                  Person In Charge:{' '}
                                  {(topPerformerSelectOptions &&
                                    topPerformerSelectOptions
                                      .find((item) => item.memberId === note.personalInCharge)
                                      ?.fullName.toString()) ??
                                    'N/A'}
                                </span>{' '}
                                |{' '}
                                <span>
                                  Deadline:{' '}
                                  {note && note.deadline ? moment(note?.deadline).format('MMMM DD YYYY') : 'N/A'}
                                </span>
                              </div>
                            }
                            color={'white'}
                            overlayStyle={{ maxWidth: '700px' }}
                            overlayInnerStyle={{ color: '#000', padding: '10px' }}
                            placement="topRight"
                            arrowPointAtCenter
                          >
                            <Row>
                              <Col span={12}>
                                <div className="text-footer">
                                  <FormStyle />
                                  {(topPerformerSelectOptions &&
                                    topPerformerSelectOptions
                                      .find((item) => item.memberId === note.personalInCharge)
                                      ?.fullName.toString()) ??
                                    'N/A'}
                                </div>
                              </Col>
                              <Col span={12}>
                                <div className="text-footer">
                                  <TimeStyle />
                                  {note && note.deadline ? moment(note?.deadline).format('MM/DD/YYYY') : 'N/A'}
                                </div>
                              </Col>
                            </Row>
                          </Tooltip>
                        </Space>
                      }
                    </>
                  )
                }
                onSave={saveActionHandler}
                onDelete={deleteNoteHandler}
                onEdit={() => setSectionState(SectionState.Editing)}
                onCancel={() => {
                  setSectionState(SectionState.Initial);
                  setModalData({
                    id: 0,
                    textnote: '',
                    personInCharge: 0,
                    deadline: null,
                  });
                }}
                onOpenRetroNoteModal={() => {
                  setModalData({
                    id: action.id,
                    textnote: action.content,
                    personInCharge: action.personalInCharge,
                    deadline: action.deadline,
                  });
                  setToggleModal(true);
                }}
              />
            ))}

          {allowEdit && props.isSelectedIteration === true && sectionState === SectionState.Initial && (
            <Card
              className="create-action-card"
              onClick={() => {
                if (props.type === 3) {
                  form.resetFields();
                  setToggleModal(true);
                } else {
                  setSectionState(SectionState.Creating);
                }
              }}
            >
              <div>
                <PlusLg color="#D9D9D9" size={42} />
              </div>
            </Card>
          )}

          {sectionState === SectionState.Creating && (
            <RetrospectiveNoteCard
              note={{
                id: 0,
                content: '',
                personalInCharge: 0,
                personalInChargeName: '',
                deadline: null,
                lastModifiedUserName: user.userName,
                status: ActionStatus.Active,
                type: props.type,
                iterationId: props.iterationId,
              }}
              isAction={isAction ?? false}
              onSave={saveActionHandler}
              isSelectedIteration={props.isSelectedIteration}
              isEditing={true}
              onCancel={() => setSectionState(SectionState.Initial)}
            />
          )}
        </Space>
      )}
      <StyledModal
        title="ADD FOLLOW-UP ACTIONS"
        visible={toggleModal}
        onCancel={() => {
          setToggleModal(false);
          setSectionState(SectionState.Initial);
          setModalData({
            id: 0,
            textnote: '',
            personInCharge: 0,
            deadline: null,
          });
        }}
        destroyOnClose
        width={700}
        centered
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => {
              setToggleModal(false);
              setSectionState(SectionState.Initial);
              setModalData({
                id: 0,
                textnote: '',
                personInCharge: 0,
                deadline: null,
              });
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            size="large"
            form="popupAction"
            formAction="popupAction"
          >
            {sectionState === 1 ? 'Edit' : 'Create'}
          </Button>,
        ]}
      >
        <Form
          {...layout}
          form={form}
          size={'middle'}
          name="popupAction"
          autoComplete="off"
          colon={false}
          labelAlign="left"
          onFinish={onSaveHandler}
        >
          <Form.Item
            label="Action"
            name="textnote"
            rules={[{ max: 500, message: 'Maximum content length: 500 characters', required: true, whitespace: true }]}
          >
            <TextArea autoSize={{ minRows: 4, maxRows: 6 }} className="text-note" />
          </Form.Item>
          <Form.Item label="Person In Charge" name="personInCharge">
            <SearchBox
              placeholder="Search"
              onFilterChange={onFilterPersonInChargeChange}
              onFilterClear={function (): void {
                throw new Error('Function not implemented.');
              }}
              defaultVal={sectionState === 1 ? modalData?.personInCharge : ''}
              data={members}
            />
          </Form.Item>
          <Form.Item label="Deadline" name="deadline">
            <DatePicker format={'dd, yyyy/MM/DD'} />
          </Form.Item>
        </Form>
      </StyledModal>
    </SubSection>
  );
};

export default RetrospectiveNoteSection;

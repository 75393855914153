import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { Search } from 'react-bootstrap-icons';
import { TeamResponse } from 'app/types/team';

export interface ITeamSearchBoxProps {
  initialValues?: string;
  onFilterChange: (value: string, option) => void;
  onFilterClear: () => void;
  groupsOfTeams: {
    deliveryStream: TeamResponse[];
    portfolio: TeamResponse[];
    releaseTrain: TeamResponse[];
    teams: TeamResponse[];
  };
  teamOnly?: boolean;
  placeholder: string;
  clearOnSelect: boolean;
}

export const TeamSearchBox = (props: ITeamSearchBoxProps) => {
  const [filterSelect, setFilterSelect] = useState<string>(props.initialValues ? props.initialValues : '');
  useEffect(() => {
    setFilterSelect(props.initialValues ? props.initialValues : '');
  }, [props.initialValues, setFilterSelect]);

  const onSelect = (value: string, option): void => {
    props.onFilterChange(value, option);
    if (props.clearOnSelect) {
      setFilterSelect('');
    }
  };

  return (
    <Select
      value={filterSelect === '' ? null : filterSelect}
      onChange={(value, option) => {
        if (value) setFilterSelect(value.toString());
      }}
      onClear={() => {
        props.onFilterClear();
        setFilterSelect('');
      }}
      onSelect={onSelect}
      suffixIcon={<Search style={{ color: '#d9d9d9' }} />}
      showSearch
      allowClear
      className="report-filter"
      placeholder={filterSelect === '' && props.placeholder}
    >
      {props.teamOnly ? (
        <Select.OptGroup label="Teams">
          {props.groupsOfTeams.teams.map((team) => (
            <Select.Option key={team.id} value={team.name}>
              {team.name}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ) : (
        <>
          <Select.OptGroup label="Portfolio">
            {props.groupsOfTeams.portfolio.map((team) => (
              <Select.Option key={team.id} value={team.name}>
                {team.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup label="Delivery Stream">
            {props.groupsOfTeams.deliveryStream.map((team) => (
              <Select.Option key={team.id} value={team.name}>
                {team.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup label="Release Train">
            {props.groupsOfTeams.releaseTrain.map((team) => (
              <Select.Option key={team.id} value={team.name}>
                {team.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup label="Teams">
            {props.groupsOfTeams.teams.map((team) => (
              <Select.Option key={team.id} value={team.name}>
                {team.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </>
      )}
    </Select>
  );
};

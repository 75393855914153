import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { CriticalStatusColor, RiskStatusColor, roleNames } from 'app/common/constants';
import { AttendanceWrapper, LegendItem } from './Attendance.styled';
import { Col, Empty } from 'antd';
import { DotComponent } from 'app/pages/Reports/WeeklyStatus/ReportWeeklyStatus.styled';
import { CommonSection } from '../CommonSection/CommonSection';
import holidays from 'date-holidays';
import {
  AttendanceDataItem,
  AttendanceType,
  DateNoteItem,
  DateNoteType,
  DayDefined,
  SingleAttend,
} from 'app/types/Attendance';
import { RenderHeaderAttendance } from './TableCostume/AttendanceCellHeader';
import { RenderCellAttendance } from './TableCostume/AttendanceCell';
import { IterationContext } from 'app/contexts/IterationContext';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { UserContext } from 'app/contexts/UserContext';
import { fetchDayOffs, fetchScheduleDate, fetchTaskByIterationMonitor } from 'app/apis/teamDetailClient';
import { useFetch } from 'app/hooks/useFetch';
import { SubPage } from 'app/types/TeamDetail';
import { Stage, TeamCapacityResponse } from 'app/types/TeamDetailTypes';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { fetchAllHoliday } from 'app/apis/holiday';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';

interface IProps {
  page: SubPage;
  classNames?: string;
}
export const Attendance = ({ page, classNames }: IProps) => {
  const { team } = useContext(TeamDetailContext);
  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const { selectedIteration, stage, reportStatus, attendanceData, setAttendanceData } = useContext(IterationContext);
  const { handleComponentClass } = useContext(DisplayComponentsContext);

  const { data: memberData } = useFetch<TeamCapacityResponse>(
    () => fetchTaskByIterationMonitor(selectedIteration?.id),
    [selectedIteration?.id]
  );
  const [currentSprintDates, setCurrentPrintDates] = useState<DateNoteItem[]>();

  const isAllowed = userRole === roleNames.pqa || userRole === roleNames.admin || email === team?.scrumMaster?.email;
  const hd = new holidays('VN');
  const currentTime = new Date();
  const dateFormat = 'YYYY/MM/DD';
  const publicHolidays = hd.getHolidays(currentTime.getFullYear(), 'VN').filter((x) => x.type === 'public');

  // fetch date header
  const { data: attendanceMemberList } = useFetch(
    () => fetchDayOffs(selectedIteration.id),
    [selectedIteration, stage, reportStatus]
  );

  // Fetch holiday data from manage holiday
  const { data: listManageHoliday } = useFetch(async () => await fetchAllHoliday());

  // fetch date body
  const { data: scheduleDateList } = useFetch(
    () => fetchScheduleDate(selectedIteration.id),
    [selectedIteration, stage, reportStatus]
  );

  // edit
  const isEdit =
    (stage !== Stage.Planned && page === SubPage.Plan) ||
    (reportStatus !== IterationReportStatusEnum.Submitted && page === SubPage.Report) ||
    page === SubPage.Monitoring;

  // whenever Plan tab was submitted
  const isDataForSubmittedPlan = stage === Stage.Planned && page === SubPage.Plan;

  // whenever Report tab was submitted
  const isDataForSubmittedReport = reportStatus === IterationReportStatusEnum.Submitted && page === SubPage.Report;

  // This method is used to check current date has equal with holiday or not
  const checkDayDefined = (currentDate: string, dayDefined?: DayDefined) => {
    let result: DayDefined = dayDefined ?? {
      name: '',
      isHoliday: false,
      isManageHoliday: false,
    };
    let dateCompare = moment(currentDate, dateFormat);

    for (let item of listManageHoliday ?? []) {
      if (
        (item.toDateOfHoliday &&
          dateCompare.isBetween(item.fromDateOfHoliday, item.toDateOfHoliday, undefined, '[]')) ||
        dateCompare.isSame(item.fromDateOfHoliday)
      ) {
        result.isManageHoliday = true;
        result.name = item.name;
        break;
      }
    }

    return result;
  };

  useEffect(() => {
    if (memberData && attendanceMemberList && scheduleDateList) {
      // function update data for Planned/Unplanned
      const getAttendenceList = () => {
        const listAttendClone = attendanceMemberList && [...attendanceMemberList];
        listAttendClone?.forEach((item) => {
          const listOther = listAttendClone?.filter((x) => x.id !== item.id);
          listOther?.forEach((it) => {
            if (item.memberID === it.memberID && item.dayOff === it.dayOff) {
              item.isPlanned = true;
            }
          });
        });
        return listAttendClone;
      };

      // init data
      let initData: AttendanceDataItem[] = [];
      memberData.tasks?.forEach((member) => {
        let objMember: AttendanceDataItem = {
          memberId: member.id,
          name: member.owner,
          attendance: getAttendenceList().filter(
            (x) =>
              x.memberID === member.id &&
              (isDataForSubmittedPlan
                ? x.pageType === SubPage.Plan
                : isDataForSubmittedReport
                ? x.pageType === SubPage.Report
                : x.pageType === SubPage.Monitoring)
          ),
        };
        initData.push(objMember);
      });

      // generate schedule date for header table
      let listGenerateScheduleDate: DateNoteItem[] = [];
      for (let index = 0; index < 14; index++) {
        const currentDate = moment(selectedIteration.dateFrom).add(index, 'days');
        let dayDefined: DayDefined = {
          name: '',
          isHoliday: false,
          isManageHoliday: false,
        };

        if (currentDate.toDate().getDay() === 6 || currentDate.toDate().getDay() === 0)
          dayDefined = {
            name: 'Weekend',
            isHoliday: true,
          };

        // Check date if same with holiday in manage holiday
        dayDefined = checkDayDefined(currentDate.format(dateFormat), dayDefined);

        for (let holiday of publicHolidays) {
          if (currentDate.isBetween(holiday.start, holiday.end)) {
            dayDefined = {
              name: holiday.name,
              isHoliday: true,
            };
            break;
          }
        }

        let findHeaderDate = scheduleDateList.find(
          (x) =>
            moment(x.scheduleDate).isSame(currentDate.toDate(), 'dates') &&
            (isDataForSubmittedPlan
              ? x.pageType === SubPage.Plan
              : isDataForSubmittedReport
              ? x.pageType === SubPage.Report
              : x.pageType === SubPage.Monitoring)
        );

        listGenerateScheduleDate.push({
          id: findHeaderDate ? findHeaderDate.id : null,
          scheduleDate: currentDate.toDate(),
          note: findHeaderDate ? findHeaderDate.note : '',
          type: findHeaderDate
            ? findHeaderDate.type
            : dayDefined.isHoliday && !dayDefined.isManageHoliday && dayDefined.name !== 'Weekend'
            ? DateNoteType.First
            : dayDefined.isManageHoliday
            ? DateNoteType.Holiday
            : DateNoteType.None,
          holiday: dayDefined,
          pageType: findHeaderDate ? findHeaderDate.pageType : SubPage.Monitoring,
        });
      }

      // generate attendance data for table body
      let newAttendanceData: AttendanceDataItem[] = [];
      initData.forEach((data) => {
        let newAttend: SingleAttend[] = [];
        for (let index = 0; index < 14; index++) {
          const currentDate = moment(selectedIteration.dateFrom).add(index, 'days');
          let findDate = data.attendance.find((x) => moment(x.dayOff).isSame(currentDate.toDate(), 'dates'));
          let dayDefined: DayDefined = {
            name: '',
            isHoliday: false,
            isManageHoliday: false,
          };

          let date = currentDate;
          if (findDate && findDate.dayOff) {
            date = moment(findDate.dayOff);
          }
          dayDefined = checkDayDefined(date.format(dateFormat), dayDefined);

          if (currentDate.toDate().getDay() === 6 || currentDate.toDate().getDay() === 0)
            dayDefined = {
              name: 'Weekend',
              isHoliday: true,
              isManageHoliday: dayDefined.isManageHoliday,
            };
          else {
            publicHolidays.forEach((holiday) => {
              if (currentDate.isBetween(holiday.start, holiday.end)) {
                dayDefined = {
                  name: holiday.name,
                  isHoliday: true,
                  isManageHoliday: dayDefined.isManageHoliday,
                };
                return;
              }
            });
          }

          newAttend.push({
            id: findDate ? findDate.id : null,
            dayOff: currentDate.toDate(),
            type:
              findDate && findDate.type !== AttendanceType.None
                ? findDate.type
                : dayDefined.isManageHoliday
                ? AttendanceType.Holiday
                : AttendanceType.None,
            note: findDate ? findDate.note : '',
            holiday: dayDefined,
            memberID: findDate ? findDate.memberID : null,
            iterationID: findDate ? findDate.iterationID : null,
            pageType: findDate ? findDate.pageType : SubPage.Monitoring,
            isPlanned: findDate?.isPlanned,
          });
        }
        newAttendanceData.push({
          ...data,
          attendance: newAttend,
        });
      });

      setAttendanceData(newAttendanceData);
      setCurrentPrintDates(listGenerateScheduleDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIteration, attendanceMemberList, scheduleDateList, memberData]);

  const onUpdateDate = (i: number, updateItem: DateNoteItem) => {
    let newData = [...currentSprintDates];
    newData[i].id = updateItem.id;
    newData[i].note = updateItem.note;
    newData[i].type = updateItem.type;
    newData[i].pageType = updateItem.pageType;
    setCurrentPrintDates(newData);
  };
  const onUpdateStatus = (i: number, j: number, updateItem: SingleAttend) => {
    let newData = [...attendanceData];
    newData[i].attendance[j].id = updateItem.id;
    newData[i].attendance[j].type = updateItem.type;
    newData[i].attendance[j].note = updateItem.note;
    newData[i].attendance[j].pageType = updateItem.pageType;

    setAttendanceData(newData);
  };
  return (
    <CommonSection
      title="DAY-OFF SCHEDULE"
      titleTooltip="Schedule day-off for iteration"
      className={classNames}
      id="dayoff-schedule"
      tailContent={
        <LegendItem className="legend" justify="end" gutter={50}>
          <Col className="item">
            <b>P: </b>
            <p>Planned</p>
          </Col>
          <Col className="item">
            <b>U: </b>
            <p>Unplanned</p>
          </Col>
          <Col className="item">
            <DotComponent color={CriticalStatusColor} />
            <p>Full day off</p>
          </Col>
          <Col className="item">
            <DotComponent color={RiskStatusColor} />
            <p>Half day off</p>
          </Col>
        </LegendItem>
      }
    >
      <AttendanceWrapper>
        <div className="table-container">
          {attendanceData ? (
            <table>
              <thead>
                <tr className="custom">
                  <th />
                  <th />
                  <th colSpan={15}>{selectedIteration?.name}</th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  {currentSprintDates &&
                    currentSprintDates?.map((a, index) => (
                      <RenderHeaderAttendance
                        isEdit={isEdit && isAllowed}
                        key={index}
                        date={a}
                        onUpdate={onUpdateDate}
                        index={index}
                        dataAttendenceList={attendanceData}
                        updateAttendenceList={setAttendanceData}
                      />
                    ))}
                </tr>
              </thead>
              <tbody>
                {attendanceData && attendanceData.length > 0 ? (
                  attendanceData?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item.name}</td>
                      {item.attendance?.map((attend, j) => (
                        <RenderCellAttendance
                          isEdit={isEdit}
                          record={item}
                          attend={attend}
                          memberId={item.memberId}
                          key={j}
                          index={i}
                          subIndex={j}
                          onUpdate={onUpdateStatus}
                        />
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={16}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </AttendanceWrapper>
    </CommonSection>
  );
};

import { DataNode } from 'antd/lib/tree';
import { OjectTeamDataResponse } from 'app/types/Organizational';
import { TeamType } from 'app/types/team';

export const filterFPTInvolved = (dataTeam: OjectTeamDataResponse[]) => {
  let list = [];
  dataTeam?.forEach((portfolio) => {
    let portfolioObject: OjectTeamDataResponse = null;
    portfolioObject = {
      ...portfolio,
      childrenItems: [],
    };
    portfolio.childrenItems?.forEach((deliverystream) => {
      let deliveryObj: OjectTeamDataResponse = {
        ...deliverystream,
        childrenItems: [],
      };
      deliverystream.childrenItems?.forEach((releasetrain) => {
        let releaseTrainObj: OjectTeamDataResponse = null;
        let filteredData = checkFPTInvolved(releasetrain);

        releaseTrainObj = {
          ...releasetrain,
          childrenItems: filteredData,
        };
        deliveryObj.childrenItems.push(releaseTrainObj);
      });
      portfolioObject.childrenItems.push(deliveryObj);
    });
    list.push(portfolioObject);
  });
  return list;
};

const checkFPTInvolved = (dataTeam: OjectTeamDataResponse) => {
  if (dataTeam.type === 3) {
    let sub = dataTeam.childrenItems;
    let result = sub?.filter((x) => x.isFPTInvolvded === true);
    return result;
  }
};

export const filterNodeFPTInvolved = (data: OjectTeamDataResponse[], isFPTInvolved: boolean) => {
  let newNodes = transferObject(data, 0, isFPTInvolved);
  let highestLevel: DataNode[] = [
    {
      key: 0,
      title: 'Select all',
      children: newNodes,
      // @ts-ignore
      value: 0,
    },
  ];
  return highestLevel;
};

const transferObject = (
  data: OjectTeamDataResponse[],
  level: number,
  isFPTInvolved: boolean,
  parentName: string = ''
) => {
  if (level < 4) {
    let newObject: CustomNode[] = data?.map((data) => ({
      key: data.id,
      title: data.name,
      value: data.id,
      children: transferObject(data.childrenItems, data.type, isFPTInvolved, combineName(parentName, data.name)),
      disableCheckbox: isFPTInvolved && data.type === TeamType.Team && !data.isFPTInvolvded,
      showValue: combineName(parentName, data.name),
    }));
    return newObject;
  }

  return [];
};

interface CustomNode extends DataNode {
  showValue?: string;
}

const combineName = (old, name) => {
  return [old, name].filter(Boolean).join(' | ');
};

import { HallOfFameReportResponse, HallOfFameReportItem, HallOfFameReportFilter } from 'app/types/HallOfFameReport';
import { fetchAsync } from '../utils/fetch';

export const fetchAllAward = (): Promise<HallOfFameReportResponse> =>
  fetchAsync('/api/halloffame/report', {
    method: 'GET',
  });

export const exportAward = (filter: HallOfFameReportFilter) =>
  fetchAsync(`/api/halloffame/report/export`, {
    method: 'POST',
    type: 'blob',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    body: JSON.stringify(filter),
  });

import { CustomReportRequest, CustomReportResponse } from 'app/types/entity';
import { fetchAsync } from 'app/utils/fetch';

export const getCustomReport = (iterationId: number): Promise<CustomReportResponse[]> => {
  const url = `/api/customreport?iterationId=${iterationId}`;
  return fetchAsync(url, { method: 'GET' });
};
export const createCustomReport = (payload: CustomReportRequest): Promise<CustomReportResponse> => {
  const url = `/api/customreport`;
  const data = new FormData();

  data.append('title', payload.title);
  data.append('note', payload.note);
  data.append('iterationId', payload.iterationId.toString());
  data.append('uploadImages', payload.uploadImages);
  data.append('reportMainData', payload.reportMainData);
  data.append('reportType', payload.reportType.toString());
  data.append('unit', payload.unit ? payload.unit.toString() : '0');
  return fetchAsync(url, { type: 'text', method: 'POST', body: data });
};
export const editCustomReport = (payload: CustomReportRequest): Promise<CustomReportResponse> => {
  const url = `/api/customreport/${payload.id}`;
  const data = new FormData();

  data.append('id', payload.id.toString());
  data.append('title', payload.title);
  data.append('note', payload.note);
  data.append('iterationId', payload.iterationId.toString());
  data.append('uploadImages', payload.uploadImages);
  data.append('url', payload.url);
  data.append('reportMainData', payload.reportMainData);
  data.append('reportType', payload.reportType.toString());
  data.append('unit', payload.unit ? payload.unit.toString() : '');
  return fetchAsync(url, { type: 'text', method: 'PUT', body: data });
};
export const deleteCustomReport = (id: number): Promise<CustomReportResponse> => {
  const url = `/api/customreport/${id}`;
  return fetchAsync(url, { method: 'DELETE' });
};

import { Spin } from 'antd';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TeamResponse } from 'app/types/team';
import { TeamContext } from './TeamContext ';
import { ITeamDetailContext } from 'app/types/TeamDetail';

export const TeamDetailContext = createContext<ITeamDetailContext>(null);

interface IProps {
  children: React.ReactNode;
}

interface IRouteParams {
  teamId?: string;
}

export const TeamDetailContextProvider = ({ children }: IProps) => {
  const { teamId } = useParams<IRouteParams>();
  const { teams } = useContext(TeamContext);
  const [team, setTeam] = useState<TeamResponse>();
  const [lastSyncOn, setLastSyncOn] = useState<Date>(null);

  useEffect(() => {
    setTeam(teams.find((x) => x.id === Number.parseInt(teamId)) ?? null);
  }, [teamId, teams]);

  if (isNaN(Number.parseInt(teamId))) return <p>Invalid teamId</p>;
  if (!teams.find((x) => x.id === Number.parseInt(teamId))) return <p>Team with ID#{teamId} Not found!</p>;

  if (!team) {
    return <Spin />;
  }

  return (
    <TeamDetailContext.Provider
      key={team?.id ?? 0}
      value={{
        lastSyncOn,
        team,
        setTeam,
      }}
    >
      {children}
    </TeamDetailContext.Provider>
  );
};

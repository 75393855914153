import { IterationPlanRequest, IterationPlanResponse } from 'app/types/Iteration';
import { TicketResponse } from 'app/types/PlannedWorkItem';
import { fetchAsync } from 'app/utils/fetch';

export const fetchPlannedWorkItem = (iterationID: number): Promise<TicketResponse[]> =>
  fetchAsync(`/api/iterationplan/plannedworkitem/${iterationID}`);

export const createIterationPlan = (plan: IterationPlanRequest): Promise<IterationPlanResponse> =>
  fetchAsync(`/api/iterationplan`, {
    method: 'POST',
    body: plan,
  });

export const submitPlan = (iterationPlanID: number): Promise<IterationPlanResponse> =>
  fetchAsync(`/api/iterationplan/submit/${iterationPlanID}`, { method: 'POST' });

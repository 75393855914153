import parse from 'html-react-parser';
import { TablePaginationConfig } from 'antd';
import React, { useMemo, useState } from 'react';
import { USER_PAGE_NUMBER } from 'app/common/constants';
import { DefectRootCauseColumn, TicketResponse } from 'app/types/PlannedWorkItem';
import { EditableColumn, EditableTable } from '../EditableTable';

interface IProps {
  dataSource: TicketResponse[];
}

export const DefectRootCauseTable = ({ dataSource }: IProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);

  const onChange = (pagination: TablePaginationConfig) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const renderClosedDuration = (value, record) => {
    if (value) {
      if (value === 1) {
        return <span>{value + ` day`}</span>;
      } else {
        return <span>{value + ` days`}</span>;
      }
    }
    return null;
  };

  const renderResolutionDetail = (value) => {
    return value ? <span>{parse(value)}</span> : null;
  };

  const getDataFilterColumn = (column) => {
    var dataFilter = [];
    let temp = [];
    dataSource?.forEach((item) => {
      if (!temp.includes(item[column])) {
        temp.push(item[column]);
        dataFilter.push({ text: item[column], value: item[column] });
      }
    });
    return dataFilter;
  };

  const columns = useMemo<EditableColumn<DefectRootCauseColumn>[]>(() => {
    let defineColumns: EditableColumn<DefectRootCauseColumn>[] = [
      {
        title: '#',
        width: '3%',
        align: 'center',
        render: (value, record, index) => (pageNumber - 1) * pageSize + index + 1,
      },
      {
        title: 'ID',
        dataIndex: 'code',
        align: 'left',
        width: '7%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        width: '15%',
      },
      {
        title: 'Root Cause Category',
        dataIndex: 'rootCauseCategory',
        align: 'left',
        width: '8%',
        filters: getDataFilterColumn('rootCauseCategory'),
        onFilter: (value: string, record) => record.rootCauseCategory === value,
      },
      {
        title: 'Resolution',
        dataIndex: 'resolution',
        align: 'left',
        width: '8%',
        filters: getDataFilterColumn('resolution'),
        onFilter: (value: string, record) => record.resolution === value,
      },
      {
        title: 'Resolution Detail',
        dataIndex: 'resolutionDetail',
        align: 'left',
        width: '16%',
        render: (value, record) => renderResolutionDetail(value),
      },
      {
        title: 'Environment',
        dataIndex: 'environment',
        align: 'left',
        width: 110,
        filters: getDataFilterColumn('environment'),
        onFilter: (value: string, record) => record.environment === value,
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        align: 'left',
        width: '6%',
        filters: getDataFilterColumn('priority'),
        onFilter: (value: string, record) => record.priority === value,
      },
      {
        title: 'Schedule State',
        dataIndex: 'scheduleState',
        align: 'left',
        width: '8%',
        filters: getDataFilterColumn('scheduleState'),
        onFilter: (value: string, record) => record.scheduleState === value,
      },
      {
        title: 'State',
        dataIndex: 'state',
        align: 'left',
        width: '6%',
        filters: getDataFilterColumn('state'),
        onFilter: (value: string, record) => record.state === value,
      },
      {
        title: 'Closed Duration',
        dataIndex: 'closedDuration',
        align: 'left',
        width: 80,
        render: (value, record) => renderClosedDuration(value, record),
        filters: getDataFilterColumn('closedDuration'),
        sorter: (recent, next) => recent.closedDuration - next.closedDuration,
        onFilter: (value: number, record) => record.closedDuration === value,
      },
    ];

    return defineColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <EditableTable
        data={dataSource}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          onChange: () => onChange,
          current: pageNumber,
        }}
      />
    </div>
  );
};

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { OjectTeamDataResponse } from 'app/types/Organizational';
import { hex2rgba } from 'app/utils/utils';
import React, { useState } from 'react';
import { BadgeCountTeam, ReleaseTrainWrapper, TeamWrapper } from '../OrginazationChart.styled';
import TeamNode from './TeamNode';

interface IProps {
  releaseTrain: OjectTeamDataResponse;
  color: string;
  setCurrentTeam: (id: number) => void;
}

function ReleaseTrainNode({ releaseTrain, color, setCurrentTeam }: IProps) {
  const [toggle, setToggle] = useState(true);
  const rgbaReleaseTrain = hex2rgba(color, 0.6);
  const rgbaBadgeCountTeam = hex2rgba(color, 1);
  const rgbaTeam = hex2rgba(color, 0.4);

  return (
    <Row>
      <Col span={7} className="release-train-section-column">
        <ReleaseTrainWrapper color={rgbaReleaseTrain}>
          <span className="box-rt">{releaseTrain.name}</span>
        </ReleaseTrainWrapper>
        <BadgeCountTeam color={rgbaBadgeCountTeam}>
          <span>
            {releaseTrain?.childrenItems ? releaseTrain?.childrenItems?.filter((x) => x.isdisplay !== false).length : 0}
          </span>
        </BadgeCountTeam>
      </Col>
      {releaseTrain?.childrenItems?.length > 0 && (
        <Col span={16}>
          <TeamWrapper color={rgbaTeam}>
            {toggle &&
              releaseTrain?.childrenItems
                ?.filter((x) => x.isdisplay !== false)
                .map((team, i) => <TeamNode team={team} key={i} color={color} setCurrentTeam={setCurrentTeam} />)}
            <Col span={1}>
              {toggle && <MinusOutlined onClick={() => setToggle(!toggle)} />}
              {!toggle && <PlusOutlined onClick={() => setToggle(!toggle)} />}
            </Col>
          </TeamWrapper>
        </Col>
      )}
    </Row>
  );
}

export default ReleaseTrainNode;

import { useEffect, useState } from 'react';
import { LegendItem } from '@antv/g2/esm/interface';
import { Column, ColumnConfig } from '@ant-design/plots';
import { WorkItemType } from 'app/types/PlannedWorkItem';
import { Empty } from 'antd';
import { ItemCommittedDeliveryResponse } from 'app/types/ItemCommittedDeliveryResponse';

interface IProps {
  itemType: WorkItemType;
  dataSource: ItemCommittedDeliveryResponse[];
}
export const ItemCommittedDelivery = ({ itemType, dataSource }: IProps) => {
  let stepInterval = 0;
  let maxValue = 0;
  if (dataSource && dataSource.length > 0) {
    maxValue = Math.max(
      ...dataSource
        .filter((x) => x.value)
        .map((object) => {
          return object.value;
        })
    );
    stepInterval = Math.ceil((maxValue * 1.1) / 5);
  }

  const config: ColumnConfig = {
    data: dataSource,
    xField: 'iterationName',
    yField: 'value',
    isGroup: true,
    isStack: true,
    seriesField: 'type',
    groupField: 'name',
    color: ({ type }) => {
      if (type === 'unplanned') return '#DFB300';
      return '#77C172';
    },
    legend: {
      position: 'top',
      marker: {
        symbol: 'circle',
      },
      items: customTargetLegend,
    },
    label: {
      position: 'middle',
      content: ({ value }) => {
        if (value === 0) {
          return '';
        }
        return value;
      },
    },
    yAxis: {
      title: {
        text: itemType === WorkItemType.UserStory ? 'User Stories' : 'Defects',
        position: 'center',
        spacing: 20,
        style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
      },
      tickInterval: stepInterval,
    },
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.name === 'committed' ? 'Committed' : 'Delivery'} ${
          datum.type === 'planned' ? 'Planned' : 'Unplanned'
        }`,
        value: datum.value,
      }),
    },
  };
  return !dataSource?.length ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <Column {...config} />;
};

const customTargetLegend: LegendItem[] = [
  {
    name: 'Planned',
    value: 'planned',
    marker: {
      symbol: 'circle',
      style: {
        fill: '#77C172',
      },
    },
  },
  {
    name: 'Unplanned',
    value: 'unplanned',
    marker: {
      symbol: 'circle',
      style: {
        fill: '#DFB300',
      },
    },
  },
];

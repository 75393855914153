/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
//components
import { Row, Col, Button, Spin, TablePaginationConfig, Alert } from 'antd';
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';

//api
import { getIterationOverview } from 'app/apis/iterationOverview';
import { IIterationOverviewResponse, ITeamStatus, TeamStatus } from 'app/types/entity';
//
import { Done, InProgress, TABLE_DEFAULT_PAGE_INDEX } from 'app/common/constants';
import { TeamResponse, TeamType } from 'app/types/team';
import './ReportStatus.scss';
import { useFetch } from 'app/hooks/useFetch';
import { TeamSearchBox } from 'app/components/TeamSearchBox/TeamSearchBox';
import { TeamContext } from 'app/contexts/TeamContext ';
import { createGroupsOfTeams } from 'app/pages/Dashboard/Overview/ProgramOverview';
import { SubPage } from 'app/types/TeamDetail';
import { FavoriteRequest } from 'app/types/user';
import { updateItem } from 'app/utils/tableUtils';
import { pinFavoriteTeam } from 'app/apis/teamClient';
import { keyBy, merge } from 'lodash';
import { ReportStatusWrapper } from './ReportStatus.styled';
export interface IReportStatusProps {
  iterationName: string;
  teamsData: TeamResponse[];
}
export enum SelectedTab {
  Plan = 0,
  Report,
}

export interface IOverviewTableRow {
  id: number;
  key: number;
  teamName: string;
  parentId?: number;
  status: number;
  isFavorite: boolean;
}

export function ReportStatus({ ...props }: IReportStatusProps) {
  const { setSubPage } = useContext(TeamContext);
  const [page, setPage] = useState<number>(TABLE_DEFAULT_PAGE_INDEX);
  const pageSize = 20;
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(SelectedTab.Plan);
  const { teams: teamsData } = useContext(TeamContext);
  const [hasError, setHasError] = useState<string>();
  //report status table
  const { data: reportStatus, loading } = useFetch<IIterationOverviewResponse>(() => {
    if (props.iterationName !== '') {
      return getIterationOverview({ iterationName: props.iterationName });
    }
  }, [props.iterationName]);
  const [filterValue, setFilterValue] = useState<number>();
  const [tableData, setTableData] = useState<IOverviewTableRow[]>([]);
  const [groupTeams, setGroupTeams] = useState<TeamResponse[]>(createGroupsOfTeams(teamsData)?.teams);
  var groupsOfTeams = createGroupsOfTeams(teamsData);

  const teamsWithIterationOverview = useCallback(
    (teamOverview: ITeamStatus[]): IOverviewTableRow[] => {
      const mergedArray = merge([], keyBy([...groupTeams], 'id'), keyBy([...teamOverview], 'teamId'));
      const data = mergedArray
        .filter((x) => x.isFPTInvolvded)
        .map((y, index) => ({
          id: index,
          key: y.id,
          teamName: y.name,
          status: y.status ? y.status : 0,
          parentId: y.parentId,
          isFavorite: y.isFavorite,
        }));
      return data
        ?.sort((a, b) => a.teamName.localeCompare(b.teamName))
        ?.sort((a, b) => (a.isFavorite > b.isFavorite ? -1 : 1));
    },
    [groupTeams]
  );

  //report status table
  useEffect(() => {
    if (!loading && reportStatus) {
      switch (selectedTab) {
        case SelectedTab.Plan:
          setTableData(teamsWithIterationOverview(reportStatus.plan));
          setSubPage(SubPage.Plan);
          break;
        case SelectedTab.Report:
          setTableData(teamsWithIterationOverview(reportStatus.report));
          setSubPage(SubPage.Report);
          break;
      }
    }
  }, [props.iterationName, reportStatus, loading, selectedTab]);

  const getFilteredTableData = () => {
    let allData = tableData
      ?.sort((a, b) => a.teamName.localeCompare(b.teamName))
      ?.sort((a, b) => (a.isFavorite > b.isFavorite ? -1 : 1));

    if (!filterValue) {
      return allData;
    }

    let queue = teamsData.filter((row) => row.id === filterValue);
    let filterResult: TeamResponse[] = [];

    while (queue && queue.length > 0) {
      const pointer = queue.shift();
      const children = teamsData.filter((row) => row.parentId === pointer.id);
      [pointer, ...children].forEach((obj) => {
        if (obj.type === TeamType.Team) {
          filterResult.push(obj);
        }
      });

      queue.push(...children);
    }
    return [...allData.filter((row) => filterResult.map((x) => x.id).indexOf(row.key) >= 0)];
  };

  //filter
  const handleFilterChange = (option) => {
    setFilterValue(Number(option.key));
    setPage(TABLE_DEFAULT_PAGE_INDEX);
  };
  const handleFilterClear = () => {
    setFilterValue(null);
  };

  const handleSelectedTabChange = (tab: SelectedTab) => {
    setSelectedTab(tab);
  };
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
  };
  const handlePinChange = (status: boolean, record: IOverviewTableRow) => {
    var query = { teamId: record?.key, isFavorite: status } as FavoriteRequest;
    (async function () {
      try {
        await pinFavoriteTeam(query);
        const newdata = getFilteredTableData()?.find((item) => item.key === record.key);
        newdata.isFavorite = status;
        const newGroupTeamData = groupTeams?.find((item) => item.id === record.key);
        newGroupTeamData.isFavorite = status;
        setGroupTeams(updateItem(newGroupTeamData, groupTeams));
      } catch (error) {
        setHasError(error?.data?.error?.message);
      }
    })();
  };
  //table columns
  const RenderText = (status: number, key: number) => {
    return (
      <Row
        style={{
          color: status === TeamStatus.InProgress ? InProgress : status === TeamStatus.Done ? Done : '#000000',
          textAlign: 'center',
        }}
      >
        <Col span={24}>
          {status === TeamStatus.InProgress ? 'In Progress' : status === TeamStatus.Done ? 'Done' : 'To do'}
        </Col>
      </Row>
    );
  };
  const RenderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`teams/${record.key}`}>
          {text}
        </Link>
        {record?.isFavorite ? (
          <PushpinFilled className="rightpin" onClick={() => handlePinChange(false, record)} />
        ) : (
          <PushpinOutlined
            className="rightpin"
            style={{ color: '#D9d9d9' }}
            onClick={() => handlePinChange(true, record)}
          />
        )}
      </>
    );
  };
  const columns: EditableColumn<any>[] = [
    {
      title: '#',
      width: '10%',
      align: 'center',
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: 'Team Name',
      dataIndex: 'teamName',
      key: 'teamName',
      editable: false,
      ellipsis: true,
      render: (value, record) => RenderLink(value, record),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      editable: false,
      width: '23.5%',
      filters: [
        { text: 'To do', value: 0 },
        { text: 'In Progress', value: 1 },
        { text: 'Done', value: 2 },
      ],
      render: (value, record, index) => RenderText(value, index),
      onFilter: (value, record) => record.status === value,
      ellipsis: true,
    },
  ];
  return (
    <ReportStatusWrapper>
      {hasError && <Alert message={hasError} closable={true} type="error" />}
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Row>
            <Col span={14}>
              <span className="title">REPORT STATUS</span>
            </Col>
            <Col span={10}>
              <Row>
                <Col xl={12} md={12} sm={24}>
                  <Button
                    className="btn-tab"
                    type={selectedTab === SelectedTab.Plan ? 'primary' : 'ghost'}
                    onClick={() => handleSelectedTabChange(SelectedTab.Plan)}
                    block
                  >
                    PLAN
                  </Button>
                </Col>
                <Col xl={12} md={12} sm={24}>
                  <Button
                    className="btn-tab"
                    // disabled
                    type={selectedTab === SelectedTab.Report ? 'primary' : 'ghost'}
                    onClick={() => handleSelectedTabChange(SelectedTab.Report)}
                    block
                  >
                    REPORT
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={4}>
              <span style={{ fontWeight: 'bolder' }}>Filter by</span>
            </Col>
            <Col span={20}>
              <TeamSearchBox
                onFilterChange={(value, options) => handleFilterChange(options)}
                onFilterClear={handleFilterClear}
                groupsOfTeams={groupsOfTeams}
                placeholder={'Search Portfolio, Delivery Stream, Release Train, Team'}
                clearOnSelect={false}
              />
            </Col>
          </Row>
        </Col>
        {props.iterationName === '' ? (
          <Spin />
        ) : (
          <Col span={24}>
            <div>
              {tableData.length <= 0 || loading ? (
                <Spin />
              ) : (
                <EditableTable
                  columns={columns}
                  data={getFilteredTableData()}
                  current={page}
                  onChange={(pagination) => onChange(pagination)}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  showAction={false}
                />
              )}
            </div>
          </Col>
        )}
      </Row>
    </ReportStatusWrapper>
  );
}

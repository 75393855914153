import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Button, Form, Modal, Space, Spin, Tabs } from 'antd';
import { TeamContext } from 'app/contexts/TeamContext ';
import { SelectOptions } from 'app/types/entity';
import { subPageOptions, SubPage } from 'app/types/TeamDetail';
import { UserContext } from 'app/contexts/UserContext';
import { createDisplayComponent, updateDisplayComponent } from 'app/apis/teamDetailClient';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { monitorSection, overviewSection, planSection, reportOptions } from 'app/types/DetailSections';
import { CheckboxAll } from '../Report/DownloadReport/CheckboxAll';
import { IterationContext } from 'app/contexts/IterationContext';
import './DisplayComponents.scss';
import { TabOptions } from 'app/common/constants';
import { DisplayType } from 'app/types/Stakeholders';

export const CheckboxModal = ({ isModalVisible, setIsModalVisible }: any) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const { sections, setSelectAll, handleCheckboxValues, setUnCheckedItems, unCheckedItems } =
    useContext(DisplayComponentsContext);
  const { page } = useContext(TeamContext);
  const { selectedIteration } = useContext(IterationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabOptions, setTabOptions] = useState(TabOptions);

  const handleApply = async () => {
    setLoading(true);
    const value = form.getFieldsValue();
    const newFormValue = {};
    const monitorTabName = subPageOptions[2].label.toLowerCase();
    if (!selectedIteration?.isCurrentSprint && sections[monitorTabName]) {
      newFormValue[monitorTabName] = sections[monitorTabName];
    }

    // handle checked component
    Object.keys(value).forEach((item) => {
      const sectionName = item.split('-')[0];
      if (value[item] && value[item].length) {
        newFormValue[sectionName] = { ...newFormValue[sectionName], [item]: value[item] };
      }
    });

    // handle unchecked component
    Object.keys(value).forEach((item) => {
      const sectionName = item.split('-')[0];
      if (!newFormValue[sectionName]) {
        const section = subPageOptions.find((i) => i.label.toLowerCase() === sectionName);
        TabOptions[section.value].forEach((component) => {
          newFormValue[sectionName] = {
            ...newFormValue[sectionName],
            [component.value]: component.options.map((o) => o.value),
          };
        });
      }
    });

    const request = {
      userId: user.id,
      value: JSON.stringify(newFormValue),
      displayType: DisplayType.DisplayForm,
    };

    if (sections === '') {
      await createDisplayComponent(request)
        .then((res) => {
          handleCheckboxValues(res?.value);
          handleCancel();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await updateDisplayComponent(request)
        .then((res) => {
          handleCheckboxValues(res?.value);
          handleCancel();
        })
        .finally(() => {
          setLoading(false);
          setUnCheckedItems(null);
          setSelectAll(null);
        });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectAll(null);
  };

  const getSectionValue = (sections: any[]) => {
    return sections.map((item) => item.value);
  };

  const handleSelectAll = (sections: any[]) => {
    setSelectAll(getSectionValue(sections));
  };

  const handleClearAll = (sections: any[]) => {
    setUnCheckedItems(getSectionValue(sections));
  };

  const handleActiveKey = subPageOptions.find((item) => item.value === page).label;

  const pagesOptions = selectedIteration?.isCurrentSprint
    ? subPageOptions
    : subPageOptions.filter((page) => page.value !== SubPage.Monitoring);

  return (
    <Modal
      className="display-components-modal"
      title="DISPLAY COMPONENTS"
      centered
      visible={isModalVisible}
      onCancel={handleCancel}
      maskClosable={false}
      width={1000}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleApply}>
          <Tabs defaultActiveKey={handleActiveKey}>
            {pagesOptions.map((page: SelectOptions<SubPage>) => (
              <Tabs.TabPane tab={page.label} key={page.label} forceRender>
                <span>Select components display on screen</span>
                <Space size="large" style={{ marginLeft: '24px', marginBottom: '10px' }}>
                  <Button className="select-all" type="link" onClick={() => handleSelectAll(TabOptions[page.value])}>
                    Select All
                  </Button>
                  <Button className="select-all" type="link" onClick={() => handleClearAll(TabOptions[page.value])}>
                    Clear All
                  </Button>
                </Space>
                {TabOptions[page.value]?.map((item, index) => {
                  return (
                    <Form.Item name={item.value} key={index}>
                      <CheckboxAll
                        form={form}
                        title={item.title}
                        sectionValue={item.value}
                        options={item.options}
                        initialValue={
                          sections[page.label.toLowerCase()] && sections[page.label.toLowerCase()][item.value]
                        }
                      />
                    </Form.Item>
                  );
                })}
              </Tabs.TabPane>
            ))}
          </Tabs>

          <Form.Item className="footer">
            <Space>
              <Button className="btn btn-cancel" type="default" onClick={handleCancel}>
                CANCEL
              </Button>
              <Button key="submit" type="primary" htmlType="submit" className="btn">
                APPLY
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

import { SubPage } from 'app/types/TeamDetail';
import { TicketResponse } from './PlannedWorkItem';

export enum Stage {
  PrePlan = 0,
  Planning,
  Planned,
  Other, // Use in other sub-page (Monitoring, Report)
}

export interface TeamCapacityModel {
  id: number;
  owner: string;
  capacityPlanning: number;
  capacity: number;
  plan: number;
  available: number;
  point: number;
  note: string;
}

export interface NoteReportCapacityModel {
  note: string;
}

export interface TeamCapacityMonitorModel {
  id: number;
  owner: string;
  capacity: number;
  plan: number;
  actual: number;
  todo: number;
  note?: string;
  effortUpdateResult: number;
}

export interface TeamCapacityResponse {
  plannedCapacity: TeamCapacityMonitorModel[];
  tasks: TeamCapacityMonitorModel[];
}

export interface TargetCapacityModel {
  target: number;
}

export class BreadcrumbResponse {
  path: string = '';
  text: string = '';
  parentCrumb?: BreadcrumbResponse = null;
  renderAsLink: boolean = true;
}

export interface TeamIterationMonitorOverview {
  id: number;
  type: number;
  teamName: string;
  capacity: number;
  points: number;
  cards: CardsCounter;
}

export interface CardsCounter {
  defects: number;
  us: number;
}

export interface TeamIterationMonitorDetail {
  id: number;
  type: number;
  teamName: string;
  capacityPercent: number;
  pointsPercent: number;
  defectNumber: number;
  usNumber: number;
  totalCapacity: number;
  estimatedCapacity: number;
  actualCapacity: number;
  acceptedPoints: number;
  totalPoints: number;
  idea: number;
  defined: number;
  inProgress: number;
  completed: number;
  accepted: number;
  released: number;
}
export interface MemberInfo {
  memberId: number;
  teamId: number;
  firstName: string;
  lastName: string;
  avatarUrl: string;
}
export interface DemoPlanResponse {
  id: number;
  title: string;
  iterationId: number;
  ticketId: number;
  ticket: TicketResponse;
  memberId: number;
  personInCharge: MemberInfo;
  note: string;
  pageType: SubPage;
  demoDate: Date;
}
export interface DemoPlanItemRequest {
  title: string;
  iterationId: number;
  ticketId: number;
  memberId: number;
  note: string;
  pageType: SubPage;
  demoDate: Date;
}

import { Checkbox, Divider, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { isBuffer } from 'lodash';
import { FieldData } from 'rc-field-form/es/interface';
import { useContext, useEffect, useState } from 'react';

import './DownloadReport.scss';
interface IProps {
  title: string;
  sectionValue: string;
  options: { label: string; value: string }[];
  form: FormInstance;
  initialValue?: any;
  initialValueForExport?: any;
  isSelectAll?: boolean;
  isClearAll?: boolean;
  isExportReportForm?: boolean;
}
function updateFormValue(form: FormInstance<any>, sectionName: string, list: CheckboxValueType[]) {
  const values = form.getFieldsValue();
  form.setFieldsValue({ ...values, [sectionName]: list });
}

export const CheckboxAll = ({
  title,
  sectionValue,
  options,
  form,
  initialValue,
  isSelectAll,
  isClearAll,
  isExportReportForm,
  initialValueForExport,
}: IProps) => {
  const CheckboxGroup = Checkbox.Group;
  const [allValues, setAllValues] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const { selectAll, unCheckedItems } = useContext(DisplayComponentsContext);

  const updateValue = (selectedList: any[], indeterminateValue: boolean, checkAllValue?: boolean) => {
    setAllValues(selectedList);
    updateFormValue(form, sectionValue, selectedList);
    setIndeterminate(indeterminateValue);
    setCheckAll(checkAllValue);
  };

  const onChange = (list: CheckboxValueType[]) => {
    const indeterminateValue = !!list.length && list.length < options.length;
    const checkAllValue = list.length === options.length;
    updateValue(list, indeterminateValue, checkAllValue);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const selectedList = e.target.checked ? options.map((o) => o.value) : [];
    updateValue(selectedList, false, e.target.checked);
  };

  useEffect(() => {
    // Config for Export Report Form
    if (isExportReportForm) {
      if (initialValueForExport) {
        const indeterminateValue = !!initialValueForExport.length && initialValueForExport.length < options.length;
        const checkAllValue = initialValueForExport.length === options.length;
        updateValue(initialValueForExport, indeterminateValue, checkAllValue);
      } else {
        // set check all default
        const selectedList = options.map((o) => o.value);
        updateValue(selectedList, false, true);
      }
      if (isSelectAll) {
        const selectedList = options.map((o) => o.value);
        updateValue(selectedList, false, true);
      }
      if (isClearAll) {
        updateValue([], false, false);
      }
    } else {
      // Config for DisplayComponent Form
      if (initialValue) {
        const indeterminateValue = !!initialValue.length && initialValue.length < options.length;
        const checkAllValue = initialValue.length === options.length;
        updateValue(initialValue, indeterminateValue, checkAllValue);
      }
    }
  }, [isSelectAll, isClearAll, initialValue, initialValueForExport]);

  useEffect(() => {
    if (selectAll && selectAll.length && selectAll.includes(sectionValue)) {
      const selectedList = options.map((o) => o.value);
      updateValue(selectedList, false, true);
    }
  }, [selectAll]);

  useEffect(() => {
    if (unCheckedItems && unCheckedItems.length && unCheckedItems.includes(sectionValue)) {
      updateValue([], false, false);
    }
  }, [unCheckedItems]);

  return (
    <div className="checkbox-all-container">
      <Checkbox
        checked={checkAll}
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        style={{ fontWeight: 'bold' }}
      >
        {title}
      </Checkbox>
      <Divider style={{ margin: '8px 0 12px 0' }} />
      <CheckboxGroup options={options} value={allValues} onChange={onChange} />
    </div>
  );
};

import { PageHeader } from 'antd';
import styled from 'styled-components';
import { COLOR_PRIMARY } from '../../common/constants';

import './PageHeader.scss';

export const StyledMenu = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;

  .header-btn {
    position: relative;
    height: 100%;
  }

  .ant-btn {
    color: ${COLOR_PRIMARY};
    height: 100%;
    width: 8.5rem;
  }
  @media only screen and (max-width: 1650px) {
    .ant-btn {
      width: 6.5rem;
    }
  }

  .is-active {
    border-top: 4px solid #024fa5;
    color: ${COLOR_PRIMARY};
    opacity: 1;
    font-weight: bold;
  }
`;

export const logoHolder = styled.div`
  height: 100%;
`;

export const StyledPageHeader = styled(PageHeader)`
  background-color: white;
  box-shadow: 5px 5px 5px #ddd;
  position: relative;
  width: 100%;
  .ant-page-header-content {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
  }

  .adm-icon {
    display: flex;
    margin: 0 2.5rem;
  }
  @media only screen and (max-width: 576px) {
    .adm-icon {
      margin: 0 1.5rem;
    }
  }
  @media only screen and (max-width: 499px) {
    .adm-icon {
      display: none;
    }
  }

  .header-btn {
    .ant-dropdown-trigger {
      width: 10.5rem;
      padding: 4.9px 0px;
    }
    @media only screen and (max-width: 1600px) {
      .ant-dropdown-trigger {
        width: 8.5rem;
      }
    }
    @media only screen and (max-width: 1500px) {
      .ant-dropdown-trigger {
        width: 7.5rem;
      }
    }
    .ant-btn-lg {
      font-size: 16px;
    }
  }

  .end-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .end-container-avatar {
      display: inline-block;
    }

    .end-container-team-select {
      margin-right: 16px;
      width: 200px;
    }

    .ant-select {
      width: 250px;
      margin-right: 1rem;
    }
    @media only screen and (max-width: 1350px) {
      .ant-select {
        width: 220px;
      }
    }
    @media only screen and (max-width: 768px) {
      .ant-select {
        width: 200px;
      }
    }
  }
`;

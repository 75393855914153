import { Col, Radio, Row } from 'antd';
import { RefAttributes, useContext, useMemo } from 'react';
import { Column as PlotChart, ColumnConfig } from '@ant-design/plots';
import { SelectOptions } from 'app/types/entity';
import { PlannedVelocity } from 'app/types/planEntity';
import _ from 'lodash';

import './Velocity.scss';
import { IterationBackLogContext } from 'app/contexts/IterationBackLogContext';
import { IterationNoteContext } from 'app/contexts/IterationNoteContext';
import { SubPage } from 'app/types/TeamDetail';
import { NoteCategory } from 'app/types/IterationNote';

export const Velocity = () => {
  const { velocities } = useContext(IterationBackLogContext);
  const { iterationNotesAll } = useContext(IterationNoteContext);

  const velocitySummary = useMemo(() => {
    // Use .slice to get last 3 velocities (assume it's ASC sorting)
    const data = velocities?.slice(-6) || [];
    const max = [...data].sort((a, b) => b.points - a.points).slice(0, 1);
    const min = [...data].sort((a, b) => a.points - b.points).slice(0, 1);
    const avg = _.mean(data.map((dt: PlannedVelocity) => dt.points | 0));
    const temp: SelectOptions<any>[] = [
      {
        label: `Avg 6: ${Math.round(avg * 10) / 10} points`,
        value: 1,
      },
      {
        label: `Highest 6: ${max[0]?.points} points`,
        value: 2,
      },
      {
        label: `Lowest 6: ${min[0]?.points} points`,
        value: 3,
      },
    ];
    return temp;
  }, [velocities]);

  const config = useMemo(() => {
    const data =
      velocities.map((v) => ({
        code: v.iterationPlanCode,
        value: v.points,
        note: iterationNotesAll?.find(
          (item) =>
            item?.pageType === SubPage.Plan &&
            item?.iterationId === v?.currentIterationId &&
            item.noteCategory === NoteCategory.Velocity
        )?.content,
      })) || [];
    const config: ColumnConfig & RefAttributes<PlannedVelocity> = {
      data: data,
      xField: 'code',
      yField: 'value',
      maxColumnWidth: 60,
      label: {
        position: 'top',
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      autoFit: true,
      color: '#2785FF',
      meta: {
        code: {
          alias: 'Iteration Name',
        },
        value: {
          alias: 'Points',
        },
      },
      tooltip: {
        customContent: (value, data) => {
          return `
              <div class="velocity-card">
                <div class="velocity-card-header">${value}</div>
                <div class="velocity-card-body">
                  <p class="card-text">Velocity: ${data[0]?.data?.value} points</p>
                  <p class="card-text">Note:</p>
                  <p class="card-text text-note">${data[0]?.data?.note ? data[0]?.data?.note : 'No note'} </p>
                </div>
              </div>`;
        },
      },
    };
    return config;
  }, [velocities, iterationNotesAll]);

  return (
    <Row>
      <Col span={24}>
        <div className="container">
          <Row className="" justify="center">
            <div className="title">Planned Velocity</div>
          </Row>
          <Row className="" justify="center">
            <Radio.Group options={velocitySummary} optionType="button" buttonStyle="solid" />
          </Row>
          <Row style={{ marginTop: '25px' }}>
            <Col span={24}>
              <PlotChart {...config} />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

import { Breadcrumb } from 'antd';
import { BreadcrumbResponse } from 'app/types/TeamDetailTypes';
import { Link } from 'react-router-dom';

const { Item: Crumb } = Breadcrumb;

export function createDefaultBreadCrumb(text: string): BreadcrumbResponse {
  return {
    path: '#',
    text: text,
    parentCrumb: new BreadcrumbResponse(),
    renderAsLink: true,
  };
}

export function getOverViewBaseBreadCrumb(): BreadcrumbResponse {
  return { path: 'dashboard', text: 'Overview', parentCrumb: null, renderAsLink: true };
}

export function generateCrumbs(crumbs: BreadcrumbResponse[]) {
  if (crumbs) {
    if (crumbs.length === 0) {
      //If empty array provided, resort to default
      crumbs.push(createDefaultBreadCrumb('Overview'));
    }

    if (crumbs.length > 1) {
      // Ensure parent crumb is assigned at base
      // (others are assigned on server as they're all teams, sub-teams etc)
      crumbs[1].parentCrumb = crumbs[0];
    }

    crumbs.slice(1, crumbs.length).forEach((c) => (c.renderAsLink = c.parentCrumb !== null));

    // Last item should never be clickable.
    crumbs[crumbs.length - 1].renderAsLink = false;
  }
  return (
    <>
      {crumbs?.map((c, i) => (
        <Crumb key={i}>
          {c.renderAsLink ? (
            //Render as clickable
            <Link to={`/${c.path}`}>{c.text}</Link>
          ) : (
            //Render as text
            c.text
          )}
        </Crumb>
      ))}
    </>
  );
}

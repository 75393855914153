import { Row } from 'antd';
import styled from 'styled-components';

export const OrginazationChartWrapper = styled(Row)`
  margin: 20px 0;
  .portfolio-row {
    background: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
`;

export const DeliveryStreamWrapper = styled(Row)`
  margin: 5px 0;
  margin-left: 20px;
  min-height: 60px;
  .delivery-stream-row {
    padding: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.color};
  }
  .release-train-and-team-row {
    margin-left: 20px;
    .release-train-section-column {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const ReleaseTrainWrapper = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  padding: 10px 0;
  margin: 7px 0;
  margin-right: 10px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  width: 100%;
  .box-rt {
    padding: 0 2rem;
  }
`;

export const TeamWrapper = styled(Row)`
  height: 100%;
  align-items: center;
  display: flex;
  flex-flow: nowrap;
  .team-row {
    background: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
    font-size: smaller;
    padding: 0 10px;
    .team-name {
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }
  }
`;

export const EdgePoint = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.color};
  .edge-point::after {
    content: '0';
    visibility: hidden;
  }
`;

export const BadgeCountTeam = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  z-index: 1;
  background-color: ${(props) => props.color};
  color: white;
`;

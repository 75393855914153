import { CustomReportResponse } from 'app/types/entity';
import { Popconfirm, Col, Row, Progress } from 'antd';
import { Trash } from 'react-bootstrap-icons';
import './CustomReportCard.scss';
import { createRef, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Editor } from 'react-draft-wysiwyg';
import { CustomReportType, UnitOptions } from 'app/types/TeamDetail';

export interface ICustomReportCardProps {
  data: CustomReportResponse;
  editable: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

export function CustomReportCard({ ...props }: ICustomReportCardProps) {
  const [layout, setLayout] = useState<boolean>();
  const imgRef = createRef<HTMLImageElement>();
  const decideLayout = (imgHeight: number, imgWidth: number) => {
    if (imgHeight * 3 >= imgWidth * 2) return true;
    return false;
  };

  const onLoad = ({ target: img }) => {
    const { offsetWidth, offsetHeight } = img;
    setLayout(decideLayout(offsetHeight, offsetWidth));
  };
  return (
    <div className="custom-report-card">
      <div className="card-title">
        <div className="title">{props.data.title}</div>
        {props.editable && (
          <div className="card-actions">
            <Popconfirm
              title="Are you sure to delete compliment?"
              onConfirm={() => props.onDelete()}
              placement="topRight"
              okText="Yes"
              cancelText="No"
            >
              <Trash className="edit delete" />
            </Popconfirm>
            <span className="edit" onClick={() => props.onEdit()}>
              Edit
            </span>
          </div>
        )}
      </div>

      <Row>
        {+props.data.reportType === CustomReportType.ImgReport ? (
          <Col span={layout ? 12 : 24}>
            <img crossOrigin="anonymous" ref={imgRef} onLoad={onLoad} src={props.data.url} alt={props.data.url} />
          </Col>
        ) : +props.data.unit === UnitOptions[0].value ? (
          <Progress
            className="progress-chart"
            type="circle"
            width={250}
            percent={+props.data.reportMainData}
            format={(e) => `${e}%`}
          />
        ) : (
          <p className="content-text">{props.data.reportMainData}</p>
        )}

        <Col span={layout ? 12 : 24}>
          <p className="note">Note</p>
          <p className="note-detail">
            {!isEmpty(props.data.note) && JSON.parse(props.data.note)?.blocks?.length > 0 ? (
              <Editor
                readOnly={true}
                toolbarHidden={true}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-box"
                toolbarClassName="editor-toolbar"
                defaultContentState={JSON.parse(props.data.note)}
              />
            ) : (
              <i className="default-no-data-text">No note</i>
            )}
          </p>
        </Col>
      </Row>
    </div>
  );
}

import { useMsal } from '@azure/msal-react';
import { message, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import styled from 'styled-components';
import { exchangeToken } from '../apis/authClient';
import { loginRequest } from '../common/authConfig';
import { ACCESS_TOKEN_KEY } from '../common/constants';
import { UserStatusEnum } from 'app/types/user';
import { ErrorResponse } from 'app/types/ErrorResponse';

const StyledLoginRedirect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoginRedirect = () => {
  const {
    instance,
    accounts: [account],
    inProgress,
  } = useMsal();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function login() {
      try {
        const { accessToken } = await instance.acquireTokenSilent(loginRequest);
        const {
          accessToken: token,
          userStatus,
          teamId,
        } = await exchangeToken({
          accessToken,
          provider: 'Microsoft',
        });
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        userStatus === UserStatusEnum.Active
          ? teamId
            ? history.push(`/teams/${teamId}`)
            : history.push(`/dashboard`)
          : history.push({
              pathname: '/getting_started',
              search: '?isSSO=true',
              state: { userStatus: userStatus },
            });
      } catch (err) {
        // return <Redirect to={`/login?error=${'An error occurred validating the access token'}`} />;
        // history.push(`/login`, { error: err.data });
        let error;

        if (err.status === 404) {
          error = 'Account is not registered. Please contact administration to register your account';
        } else {
          const _ = err.data as ErrorResponse;
          if (_.error.validationErrors?.length > 0) {
            error = _.error.validationErrors.map((e) => e.message).join('\r\n');
          } else {
            error = _.error.message;
          }
        }
        setErrorMessage(error);
        notification.error({
          message: error,
        });
      }
    }

    if (account) {
      instance.setActiveAccount(account);
      login();
    }
  }, [history, instance, account]);

  useEffect(() => {
    if (!account && inProgress === 'none') {
      history.push('/login');
    }
  }, [account, history, inProgress]);

  if (errorMessage) {
    return <StyledLoginRedirect>{errorMessage}</StyledLoginRedirect>;
  }

  return (
    <StyledLoginRedirect>
      <Spin tip="Signing in with Microsoft account" />
    </StyledLoginRedirect>
  );
};

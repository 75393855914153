import { Alert } from 'antd';
import { ErrorResponse } from 'app/types/ErrorResponse';
import React from 'react';

interface IProps {
  error: Response | any;
  message?: string;
}

const GENERIC_MESSAGE = 'There has been an error processing your request. Please contact support for assistance.';

export const ErrorBox = ({ error, message = GENERIC_MESSAGE }: IProps) => {
  if (!error) {
    return null;
  }

  if (error.status === 401) {
    const err = error.data as ErrorResponse;
    if (err.error.validationErrors?.length > 0) {
      message = err.error.validationErrors.map((e) => e.message).join('\r\n');
    } else {
      message = err.error.message;
    }
  }

  return (
    <div className="submit-error">
      <Alert message={message} type="error" />
    </div>
  );
};

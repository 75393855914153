import { useContext, useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier, convertToRaw } from 'draft-js';
import { Button, Modal, Spin, notification } from 'antd';
import { createOrUpdateContent, getGuidelineFaqs, uploadImageEditor } from 'app/apis/guidelineFaq';
import { roleNames } from 'app/common/constants';
import { AuthContext } from 'app/contexts/AuthContext';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ICustomEditor } from 'app/types/CustomEditorModel';
import { getEditorState } from 'app/components/IssueReportIdea/IssueReportIdeaHandle';
import './CustomEditor.scss';

interface ICustomEditorProps {
  tabType: number;
}

export const CustomEditor = ({ tabType }: ICustomEditorProps) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [data, setData] = useState<ICustomEditor>();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);
  const { confirm } = Modal;

  const handleImageUploadEditor = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const res = await uploadImageEditor(file);
        resolve({ data: { link: res.path } });
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const contentState = editorState.getCurrentContent();
    const contentStateJson = JSON.stringify(convertToRaw(contentState));
    const payload: any = {
      type: tabType,
      content: contentStateJson,
    };
    await createOrUpdateContent(payload)
      .then((res: ICustomEditor) => {
        notification.success({
          message: 'Submit successfully',
          duration: 3,
        });
        setData(res);
        setEditMode(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showUpdateConfirm = () => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleSubmit();
      },
    });
  };

  const cancelEditing = () => {
    const newEditorState = getEditorState(data.content);
    setEditorState(newEditorState);
    setEditMode(false);
  };

  useEffect(() => {
    setLoading(true);
    getGuidelineFaqs(tabType)
      .then((res: ICustomEditor) => {
        // Convert content to EditorState which editor can read and display it.
        const newEditorState = getEditorState(res.content);
        setEditorState(newEditorState);
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Spin spinning={loading} wrapperClassName="custom-editor-wrapper">
      {user.role === roleNames.admin ? (
        <Button type="primary" className="edit-btn" onClick={() => setEditMode(true)}>
          Edit
        </Button>
      ) : null}
      {editMode && user.role === roleNames.admin ? (
        <>
          <Editor
            readOnly={false}
            toolbarHidden={false}
            editorState={editorState}
            editorStyle={{ minHeight: '50vh' }}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-box"
            toolbarClassName="editor-toolbar"
            toolbar={{
              image: {
                uploadCallback: handleImageUploadEditor,
                previewImage: true,
                alt: { present: true, mandatory: false },
              },
            }}
            onEditorStateChange={(values) => {
              setEditorState(values);
            }}
            onTab={(e) => {
              const tabCharacter = '              ';
              e.preventDefault();
              let newContentState = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                tabCharacter
              );
              setEditorState(EditorState.push(editorState, newContentState, 'insert-characters'));
            }}
          />
          <div className="action-btn">
            <Button key="back" size="large" onClick={cancelEditing} className="cancel-btn">
              Cancel
            </Button>
            <Button size="large" htmlType="submit" type="primary" onClick={showUpdateConfirm}>
              Submit
            </Button>
          </div>
        </>
      ) : (
        <Editor editorState={editorState} readOnly={true} toolbarHidden={true} />
      )}
    </Spin>
  );
};

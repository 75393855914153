import {
  ProgramTrendDeliveryChartData,
  ProgramTrendVelocityItem,
  ProgramUltilizedCapacityItem,
  StoryCarriedOverPortItem,
  StoryCarriedOverProgramItem,
  UserStoryItem,
} from 'app/types/ProgramTrends';
import { fetchAsync } from 'app/utils/fetch';

export const fetchDeliveryTrendChart = (): Promise<ProgramTrendDeliveryChartData[]> =>
  fetchAsync(`/api/programtrend/delivery-trend`);
export const fetchVelocityChart = (): Promise<ProgramTrendVelocityItem[]> => fetchAsync(`/api/programtrend/velocity`);
export const fetchCarriedOverPortfolioChart = (): Promise<StoryCarriedOverPortItem[]> =>
  fetchAsync(`/api/programtrend/carried-over-portfolio`);
export const fetchUserStoryStatusChart = (): Promise<UserStoryItem[]> => fetchAsync(`/api/programtrend/us-status`);
export const fetchUserStoryCarriedOverChart = (): Promise<StoryCarriedOverProgramItem[]> =>
  fetchAsync(`/api/programtrend/us-carried-over`);
export const fetchUtilizedCapacityChart = (): Promise<ProgramUltilizedCapacityItem[]> =>
  fetchAsync(`/api/programtrend/utilized-capacity`);

import { CANCEL_BUTTON } from 'app/common/constants';
import { Col } from 'antd';
import styled from 'styled-components';

export const ColorCircle = styled.span`
  height: 21px;
  width: 21px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;

export const TeamWeeklyStatusIssueDetailWrapper = styled(Col)`
  color: #000000;
  margin-top: 1rem;
  .ant-form-item {
    margin-bottom: 15px;
  }
  label {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .divider {
    border-color: #707070;
  }
  .link-btn {
    padding-left: 0;
  }
  .btn {
    width: 100px;
    height: 39px;
    margin-right: 1rem;
    &.cancel {
      background-color: ${CANCEL_BUTTON};
    }
  }
  p {
    font-size: 16px;
  }
  .line-content {
    margin-bottom: 0;
  }
`;

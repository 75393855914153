import { ACCEPTED, NOT_ACCEPTED, AVG_ACCEPTED, TOTAL_ACCEPTED } from 'app/common/constants';
import { useContext, useMemo } from 'react';
import { DualAxesConfig, DualAxes } from '@ant-design/plots';
import { Col, Empty, Radio, Row } from 'antd';
import { VelocityReportModel } from 'app/types/Iteration';
import { SelectOptions } from 'app/types/entity';
import _ from 'lodash';
import './ReportVelocity.scss';
import { IterationNoteContext } from 'app/contexts/IterationNoteContext';
import { SubPage } from 'app/types/TeamDetail';
import { IterationContext } from 'app/contexts/IterationContext';
import { NoteCategory } from 'app/types/IterationNote';

interface IProps {
  dataSource: VelocityReportModel[];
  teamId: number;
}

export const ReportVelocity = ({ dataSource, teamId }: IProps) => {
  const { iterationNotesAll } = useContext(IterationNoteContext);
  const { teamIterations } = useContext(IterationContext);
  const velocitySummary = useMemo(() => {
    const data = dataSource?.filter((p) => p.state === 'Accepted').slice(-6) || [];
    const max = [...data]?.sort((a, b) => b.point - a.point)?.slice(0, 1);
    const min = [...data]?.sort((a, b) => a.point - b.point)?.slice(0, 1);
    const avg = _.mean(data.map((dt: VelocityReportModel) => dt.point | 0));
    const temp: SelectOptions<any>[] = [
      {
        label: `Avg 6: ${avg.toFixed(2)} points`,
        value: 1,
      },
      {
        label: `Highest 6: ${max[0]?.point} points`,
        value: 2,
      },
      {
        label: `Lowest 6: ${min[0]?.point} points`,
        value: 3,
      },
    ];
    return temp;
  }, [dataSource]);

  // test data for get max of sum point
  const getMaxOfSumPoint = (arrSoure: VelocityReportModel[]) => {
    const accArr = arrSoure?.filter((item) => item.iterationName === 'Accepted');
    const notAccArr = arrSoure?.filter((item) => item.iterationName !== 'Accepted');
    const dataSum = accArr?.reduce((arrSum, item) => {
      arrSum.forEach((it) => {
        it.state = 'Sum of Acc and NotAcc';
        if (it.iterationName === item.iterationName) {
          it.point += item.point;
        }
      });
      return arrSum;
    }, notAccArr);
    // get data maxSumPoint
    const maxSumPoint = dataSum?.reduce((maxValue, item) => {
      if (item.point > maxValue) {
        maxValue = item.point;
      }
      return maxValue;
    }, 0);
    return maxSumPoint;
  };
  // Config for chart
  const config = useMemo(() => {
    const data = dataSource || [];
    const columnData = data?.map((item) => {
      const { state, point, iterationName } = item;
      item.iterationId = teamIterations?.find(
        (x) => x.name.toLowerCase() === item.iterationName.toLowerCase() && x.teamId === teamId
      )?.id;
      return {
        time: iterationName,
        value: point,
        type: state,
        note:
          iterationNotesAll?.find(
            (v) =>
              v?.pageType === SubPage.Report &&
              v?.noteCategory === NoteCategory.Velocity &&
              v?.iterationId === item.iterationId
          )?.content || 'No note',
      };
    });
    const dt = data?.filter((item) => item.state === 'Accepted');
    const avgAccepted = (dt?.reduce((sum, currItem) => sum + currItem.point, 0) / dt?.length).toFixed(2);
    const totalData = dt?.map((item) => {
      const { point, iterationName } = item;
      return {
        time: iterationName,
        count: point,
        name: 'Total Accepted Point',
      };
    });
    const avgData = dt?.map((item) => {
      const { iterationName } = item;
      return {
        time: iterationName,
        count: avgAccepted,
        name: 'Average Accepted Point',
      };
    });
    const lineData = avgData && totalData && [...totalData, ...avgData];
    const maxPoint = Math.round(getMaxOfSumPoint(data)) + 10;
    const config: DualAxesConfig = {
      data: [columnData, lineData],
      xField: 'time',
      yField: ['value', 'count'],
      height: 300,
      geometryOptions: [
        {
          geometry: 'column',
          isStack: true,
          seriesField: 'type',
          label: {
            position: 'middle',
            content: ({ value }) => {
              if (value === 0) {
                return '';
              }
              return value;
            },
          },
          columnWidthRatio: 0.4,
          color: [NOT_ACCEPTED, ACCEPTED],
        },
        {
          geometry: 'line',
          seriesField: 'name',
          lineStyle: {
            lineWidth: 1,
          },
          point: {
            color: [TOTAL_ACCEPTED, AVG_ACCEPTED],
            size: 3,
          },
          color: [TOTAL_ACCEPTED, AVG_ACCEPTED],
        },
      ],
      legend: {
        position: 'top',
        custom: false,
        label: {
          style: {
            fontSize: 24,
          },
        },
      },
      yAxis: [
        {
          type: 'linear',
          min: 0,
          max: maxPoint,
          position: 'left',
          title: {
            text: 'Points',
            position: 'center',
            spacing: 20,
            style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
          },
        },
        { type: 'linear', min: 0, max: maxPoint, position: 'left' },
      ],
      tooltip: {
        customContent: (value, data) => {
          return `
          <div class="g2-tooltip-title">${value}</div>
          <ul class="g2-tooltip-list">
            <li class="g2-tooltip-list-item" data-index="">
                <span class="g2-tooltip-marker" style="background: ${data[0]?.color};"></span>
                <span class="g2-tooltip-name">Not Accepted</span>:
                <span class="g2-tooltip-value">${data[0]?.value}</span>
            </li>
            <li class="g2-tooltip-list-item" data-index="">
                <span class="g2-tooltip-marker" style="background: ${data[1]?.color};"></span>
                <span class="g2-tooltip-name">Accepted</span>:
                <span class="g2-tooltip-value">${data[1]?.value}</span>
            </li>
            <li class="g2-tooltip-list-item" data-index="">
                <span class="g2-tooltip-marker" style="background: ${data[2]?.color};"></span>
                <span class="g2-tooltip-name">Total Accepted Point</span>:
                <span class="g2-tooltip-value">${data[2]?.value}</span>
            </li>
            <li class="g2-tooltip-list-item" data-index="">
                <span class="g2-tooltip-marker" style="background: ${data[3]?.color};"></span>
                <span class="g2-tooltip-name">Average Accepted Point</span>:
                <span class="g2-tooltip-value">${data[3]?.value}</span>
            </li>
            <li class="g2-tooltip-list-item" data-index="">
                <span class="g2-tooltip-name">Note</span>:
                <span>${data[0]?.data?.note}</span>
            </li>
        </ul>`;
        },
      },
    };
    return config;
  }, [dataSource, iterationNotesAll]);

  return dataSource?.length === 0 ? (
    <div className="report">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  ) : (
    <>
      <Row justify="center">
        <Radio.Group options={velocitySummary} optionType="button" buttonStyle="solid" />
      </Row>
      <Row className="body-report" justify="center">
        <Col span={24}>
          <DualAxes {...config} />
        </Col>
      </Row>
    </>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ModalSection } from '../OganizationModal/ModalSection';
import { OrganizationFormWrapper } from './OrganizationForm.styled';
import { TeamSearchBox } from 'app/components/TeamSearchBox/TeamSearchBox';
import { TeamContext } from './../../../contexts/TeamContext ';
import { createGroupsOfTeams } from 'app/pages/Dashboard/Overview/ProgramOverview';
import { FormListFieldData } from 'antd/lib/form/FormList';
// editor
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CreateBy, OrganizationFormPayload, Product, ProductPayload, ProductType } from 'app/types/Organizational';
//
import { createProduct, createTeamDetail, fetchProduct } from 'app/apis/statisticOrganization';
import { TeamResponse } from 'app/types/team';
interface IOganizationForm {
  dataSource?: OrganizationFormPayload;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setEditForm: (isEdit: boolean) => void;
  setIsFinish: (isFinish: boolean) => void;
  isFinish: boolean;
}
type Option = {
  type: ProductType;
  value: string;
};

export const OrganizationForm = ({
  dataSource,
  setIsSubmitting,
  setEditForm,
  setIsFinish,
  isFinish,
}: IOganizationForm) => {
  const [form] = Form.useForm();
  const { teams: teamsData } = useContext(TeamContext);
  const [teamOptions, setTeamOptions] = useState<TeamResponse[]>(teamsData);
  const [productOpts, setProductOptions] = useState<Option[]>();
  const [allProduct, setAllProduct] = useState<Product[]>();
  const filterTeamData = (data: TeamResponse[]) => {
    let temp = [...data];

    dataSource.dependencies?.forEach((dependency) => (temp = temp.filter((team) => team.id !== dependency.teamId)));

    return temp;
  };
  const GetProduct = async () => {
    const data = await fetchProduct();
    let options: Option[] = data.map((prods) => ({
      type: prods.type,
      value: prods.name,
    }));
    let productData: Product[] = data.map((prods) => ({
      name: prods.name,
      type: prods.type,
    }));
    setProductOptions(options);
    setAllProduct(productData);
  };
  useEffect(() => {
    let result = filterTeamData(teamOptions);
    setTeamOptions(result);
    GetProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const groupsOfTeams = createGroupsOfTeams(teamOptions, true);
  const initValues = {
    workload: dataSource?.products?.filter((x) => x.type === ProductType.Workload)?.map((item) => item.name),
    tag: dataSource?.products?.filter((x) => x.type === ProductType.Tag)?.map((item) => item.name),
    technicalStack: dataSource?.products
      ?.filter((x) => x.type === ProductType.TechnicalSatck)
      ?.map((item) => item.name),
    description: JSON.parse(dataSource?.descriptions) || '',
    roadmap: JSON.parse(dataSource?.roadmap) || '',
    stakeholders: dataSource?.stakeHolders || [],
    dependencies: dataSource?.dependencies?.map((item) => ({
      description: item.descriptions,
      team: {
        id: item.teamId,
        name: item.teamName,
        isFPTInvolvded: null,
        isFavorite: null,
        model: null,
        parentId: null,
        scrumMaster: null,
        type: null,
        scrumMasterID: null,
      } as TeamResponse,
    })),
  };
  const onFinish = async (values: any) => {
    const products: Product[] = [];
    values?.workload?.forEach((x) => {
      products.push({ name: x, type: ProductType.Workload });
    });
    values?.tag?.forEach((x) => {
      products.push({ name: x, type: ProductType.Tag });
    });
    values?.technicalStack?.forEach((x) => {
      products.push({ name: x, type: ProductType.TechnicalSatck });
    });
    var newProduct = products.filter(function (pro) {
      return !allProduct.some(function (newPro) {
        return pro.name.toLowerCase() === newPro.name.toLowerCase() && pro.type === newPro.type;
      });
    });
    setIsSubmitting(true);
    try {
      let result: OrganizationFormPayload = {
        teamID: dataSource?.teamID,
        stakeHolders: values.stakeholders,
        products: products,
        descriptions: JSON.stringify(values.description),
        roadmap: JSON.stringify(values.roadmap),
        dependencies: values.dependencies?.map((item) => ({
          teamId: item.team.id,
          teamName: item.team.name,
          descriptions: item.description,
        })),
        scrumMaster: dataSource?.scrumMaster,
      };
      if (newProduct.length !== 0) {
        let result: ProductPayload[] = newProduct.map((item) => ({
          name: item.name,
          type: item.type,
          createBy: CreateBy.User,
        }));
        await createProduct(result);
      }
      await createTeamDetail(result);
      setIsSubmitting(false);
      setEditForm(false);
      setIsFinish(!isFinish);
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
    }
  };
  const onRemoveDependency = () => {
    const dependencies = form.getFieldValue(`dependencies`);
    let temp = teamsData;
    dependencies.forEach((element) => {
      if (element) {
        temp = temp.filter((opt) => opt.id !== element.team.id);
        setTeamOptions(temp);
      }
    });
  };
  const onSearchItemSelected = (opt, field: FormListFieldData, isClear: boolean) => {
    let teamId = Number(opt.key);
    let team = teamOptions.filter((i) => i.id === teamId)[0];
    setTeamOptions(teamOptions.filter((i) => i.id !== teamId));
    const dependencies = form.getFieldValue(`dependencies`);
    let after = dependencies.map((dep, index) => {
      if (index === field.key) {
        let obj = {
          ...dep,
          team: isClear ? null : team,
        };
        return obj;
      }
      return dep;
    });
    form.setFieldsValue({ dependencies: after });
  };
  const onItemCleared = (field: FormListFieldData) => {
    const dependencies = form.getFieldValue(`dependencies`);
    let after = dependencies.map((dep, index) => {
      if (index === field.key) {
        let obj = {
          ...dep,
          team: null,
        };
        return obj;
      }
      return dep;
    });
    form.setFieldsValue({ dependencies: after });
  };
  return (
    <OrganizationFormWrapper
      form={form}
      id="organization"
      name="organizationForm"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark={false}
      colon={false}
      initialValues={initValues}
    >
      <ModalSection title="workload">
        <Form.Item name="workload">
          <Select
            options={productOpts?.filter((x) => x.type === ProductType.Workload)}
            placeholder="Workload"
            style={{ width: '100%' }}
            mode="tags"
          />
        </Form.Item>
      </ModalSection>
      <ModalSection title="Keywords">
        <Form.Item name="tag">
          <Select
            options={productOpts?.filter((x) => x.type === ProductType.Tag)}
            placeholder="Tag"
            style={{ width: '100%' }}
            mode="tags"
          />
        </Form.Item>
      </ModalSection>
      <ModalSection title="Technical Stack">
        <Form.Item name="technicalStack">
          <Select
            options={productOpts?.filter((x) => x.type === ProductType.TechnicalSatck)}
            placeholder="Technical Stack"
            style={{ width: '100%' }}
            mode="tags"
          />
        </Form.Item>
      </ModalSection>
      <ModalSection title="stakeholders">
        <Form.List name="stakeholders">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} align="middle" justify="space-between" gutter={10}>
                  <Col span={11}>
                    <Form.Item
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      {...field}
                      label="Name"
                      name={[field.name, 'name']}
                      rules={[{ required: true, message: `Missing Stakeholder's Name` }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="Role"
                      name={[field.name, 'role']}
                      rules={[{ required: true, message: `Missing Stakeholder's Role` }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <CloseOutlined onClick={() => remove(field.name)} />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Button type="link" className="btn-link" onClick={() => add()}>
                  Add new
                </Button>
              </Row>
            </>
          )}
        </Form.List>
      </ModalSection>
      <ModalSection title="description">
        <Form.Item name="description">
          <Editor
            wrapperClassName="editor-wrapper"
            editorClassName="editor-box"
            toolbarClassName="editor-toolbar"
            onEditorStateChange={(values) => {
              form.setFieldsValue({
                description: values,
              });
            }}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'remove',
                'history',
              ],
            }}
            defaultContentState={JSON.parse(dataSource?.descriptions)}
            onTab={(e) => {
              const tabCharacter = '              ';
              e.preventDefault();
              let currentState = form.getFieldValue('description');
              let newContentState = Modifier.replaceText(
                currentState.getCurrentContent(),
                currentState.getSelection(),
                tabCharacter
              );
              form.setFieldsValue({
                description: EditorState.push(currentState, newContentState, 'insert-characters'),
              });
            }}
          />
        </Form.Item>
      </ModalSection>
      <ModalSection title="product roadmap">
        <Form.Item name="roadmap">
          <Editor
            wrapperClassName="editor-wrapper"
            editorClassName="editor-box"
            toolbarClassName="editor-toolbar"
            onEditorStateChange={(values) => {
              form.setFieldsValue({
                roadmap: values,
              });
            }}
            defaultContentState={JSON.parse(dataSource?.roadmap)}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'remove',
                'history',
              ],
            }}
            onTab={(e) => {
              const tabCharacter = '              ';
              e.preventDefault();
              let currentState = form.getFieldValue('roadmap');
              let newContentState = Modifier.replaceText(
                currentState.getCurrentContent(),
                currentState.getSelection(),
                tabCharacter
              );
              form.setFieldsValue({
                description: EditorState.push(currentState, newContentState, 'insert-characters'),
              });
            }}
          />
        </Form.Item>
      </ModalSection>
      <ModalSection title="dependencies">
        <Form.List name="dependencies">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} align="middle" justify="space-between" gutter={10}>
                  <Col span={23}>
                    <Form.Item
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      {...field}
                      label="Team"
                      name={[field.name, 'team']}
                      rules={[{ required: true, message: `Missing Team` }]}
                    >
                      <TeamSearchBox
                        initialValues={
                          (dataSource?.dependencies && dataSource?.dependencies[field.key]?.teamName) || ''
                        }
                        onFilterChange={(value, options) => onSearchItemSelected(options, field, false)}
                        onFilterClear={() => onItemCleared(field)}
                        groupsOfTeams={groupsOfTeams}
                        teamOnly={true}
                        placeholder={'Search'}
                        clearOnSelect={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                          onRemoveDependency();
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={23}>
                    <Form.Item
                      {...field}
                      labelAlign="left"
                      labelCol={{
                        span: 5,
                      }}
                      label="What &amp; How?"
                      name={[field.name, 'description']}
                    >
                      <Input.TextArea rows={5} />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Button type="link" className="btn-link" onClick={() => add()}>
                  Add new
                </Button>
              </Row>
            </>
          )}
        </Form.List>
      </ModalSection>
    </OrganizationFormWrapper>
  );
};

import React, { ReactNode } from 'react';
import ToolTip from './Tooltip/ToolTip';
import { Skeleton } from 'antd';

interface IProps {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  titleTooltip?: ReactNode;
  id?: string;
  clasTitleName?: string;
  loading?: boolean;
}

export const Card = ({ id, title, children, className, titleTooltip, clasTitleName, loading }: IProps) => (
  <div id={id} className={`card-container ${className}`}>
    {title && (
      <div className={`card-title ${clasTitleName}`}>
        {title}
        {titleTooltip && <ToolTip title={titleTooltip} />}
      </div>
    )}
    {loading && <Skeleton loading={loading} active />}
    {children}
  </div>
);

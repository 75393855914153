import {
  CategoryModel,
  CategoryResponse,
  CriteriaDetailModel,
  CriteriaDetailResponse,
  CriteriaModel,
  CriteriaResponse,
} from '../types/Ownership';
import { fetchAsync } from '../utils/fetch';

export const fetchCategory = (): Promise<CategoryResponse[]> => fetchAsync('/api/ownership/category');

export const updateCategory = (id: number, metric: CategoryModel): Promise<CategoryResponse> =>
  fetchAsync(`/api/ownership/update-category/${id}`, {
    method: 'PUT',
    body: metric,
  });

export const deleteCategory = (id: number): Promise<void> =>
  fetchAsync(`/api/ownership/del-category/${id}`, {
    method: 'DELETE',
  });

export const createCategory = (metric: CategoryModel): Promise<CategoryResponse> =>
  fetchAsync(`/api/ownership/create-category`, {
    method: 'POST',
    body: metric,
  });

export const fetchCriteria = (): Promise<CriteriaResponse[]> => fetchAsync('/api/ownership/criteria');

export const updateCriteria = (id: number, metric: CriteriaModel): Promise<CriteriaResponse> =>
  fetchAsync(`/api/ownership/update-criteria/${id}`, {
    method: 'PUT',
    body: metric,
  });

export const deleteCriteria = (id: number): Promise<void> =>
  fetchAsync(`/api/ownership/del-criteria/${id}`, {
    method: 'DELETE',
  });

export const createCriteria = (metric: CriteriaModel): Promise<CriteriaResponse> =>
  fetchAsync(`/api/ownership/create-criteria`, {
    method: 'POST',
    body: metric,
  });

export const fetchCriteriaDetail = (): Promise<CriteriaDetailResponse[]> =>
  fetchAsync('/api/ownership/criteria-detail');

export const updateCriteriaDetail = (id: number, metric: CriteriaDetailModel): Promise<CriteriaDetailResponse> =>
  fetchAsync(`/api/ownership/update-criteria-detail/${id}`, {
    method: 'PUT',
    body: metric,
  });

export const deleteCriteriaDetail = (id: number): Promise<void> =>
  fetchAsync(`/api/ownership/del-criteria-detail/${id}`, {
    method: 'DELETE',
  });

export const createCriteriaDetail = (metric: CriteriaDetailModel): Promise<CriteriaDetailResponse> =>
  fetchAsync(`/api/ownership/create-criteria-detail`, {
    method: 'POST',
    body: metric,
  });

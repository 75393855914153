import { Column, ColumnConfig } from '@ant-design/plots';
import { Row, Select } from 'antd';
import { IDataChartProps, ReportType } from 'app/types/CacheReportModel';
import { useState } from 'react';
import moment from 'moment';
import { useFetch } from 'app/hooks/useFetch';
import { getDefectChartByState } from 'app/apis/iterationOverview';
import { OverviewNote } from 'app/components/TeamOverview/OverviewNote/OverviewNote';
import { saveChartSettingRequest } from 'app/apis/fetchTeamOverview';
import { SaveChartSettingModel } from 'app/types/SaveChartSettingRequest';

const getStartMonth = (): moment.Moment => {
  return moment().subtract(10, 'months').startOf('month');
};

const getEndMonth = (): moment.Moment => {
  return moment().endOf('month');
};

const getMonths = (startMonth: moment.Moment, endMonth: moment.Moment): moment.Moment[] => {
  endMonth = moment(endMonth);

  const result: moment.Moment[] = [];

  while (true) {
    if (endMonth.diff(startMonth, 'day') >= 1) {
      result.push(moment(endMonth));
      endMonth = endMonth.add(-1, 'month');
    } else {
      break;
    }
  }

  return result;
};

const selectionOptions = [
  { label: 'YTD', value: 'YTD' },
  ...getMonths(getStartMonth(), getEndMonth()).map((date) => {
    return {
      label: `${date.format('MMMM')}/${date.year()}`,
      value: `${date.month() + 1}/${date.year()}`,
    };
  }),
];

export const DefectByStateTrend = ({ teamId }: IDataChartProps) => {
  const [data, setData] = useState([]);

  const { data: rawData } = useFetch(
    () =>
      getDefectChartByState(teamId).then((value) => {
        setData(value.data);
        return value;
      }),
    [teamId]
  );

  const onNoteSave = async (value: string) => {
    const payload = new SaveChartSettingModel();
    payload.teamId = teamId;
    payload.chartType = ReportType.IterationDefectsByState;
    payload.note = value;

    return await saveChartSettingRequest(payload);
  };

  const config: ColumnConfig = {
    data,
    isStack: true,
    xField: 'iteration',
    yField: 'value',
    seriesField: 'state',
    color: ({ state }) => {
      switch (state) {
        case 'Submitted':
          return '#5b9acb';
        case 'Open':
          return '#f47168';
        case 'Fixed':
          return '#acacac';
        case 'Closed':
          return '#6ab17d';
        case 'Closed Declined':
          return '#e5d038';
      }
    },
    label: {
      position: 'middle',
    },
    legend: {
      position: 'top',
      marker: {
        symbol: 'circle',
      },
    },
    maxColumnWidth: 40,
    yAxis: {
      title: {
        text: 'Defect',
        position: 'center',
        spacing: 20,
        style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
      },
    },
  };

  const onChangeState = (value) => {
    if (value === 'YTD') {
      setData(rawData.data);
    } else {
      var temp = rawData?.data.filter((x) => x.iteration === value);
      setData(temp);
    }
  };
  return (
    <>
      <Row style={{ justifyContent: 'end' }}>
        <Select defaultValue="YTD" options={selectionOptions} style={{ width: 150 }} onChange={onChangeState} />
      </Row>{' '}
      <Column {...config} />
      <OverviewNote content={rawData?.note} onSave={onNoteSave} />
    </>
  );
};

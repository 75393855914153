import { SearchOutlined } from '@ant-design/icons';
import { Button, Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { SEARCHED_TEXT } from 'app/common/constants';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DividerSearchPopup,
  RowAction,
  SearchBox,
  SearchTreeWrapper,
  SearchValueWrapper,
} from './SearchTreeWrapper.styled';

interface IProps {
  dataSource: DataNode[];
  onFilterByChecked: (checkedNodes: React.Key[]) => void;
  isFPTInvolded: boolean;
}

export const SearchTree = ({ dataSource, onFilterByChecked, isFPTInvolded }: IProps) => {
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);

  // control dropdown
  const [display, setDisplay] = useState<boolean>(false);

  const dataList: { key: React.Key; title: string }[] = [];
  const generateList = (data: DataNode[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      dataList.push({ key, title: title.toString() });
      if (node.children) {
        generateList(node.children);
      }
    }
  };
  generateList(dataSource);

  const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([0]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  // for check node
  useEffect(() => {
    setCheckedKeys([]);
  }, [isFPTInvolded]);
  const onCheck = (checkedKeysValue: React.Key[]) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = (value: string) => {
    if (value.trim() === '') {
      setExpandedKeys([0]);
      setSearchValue('');
      setAutoExpandParent(true);
    } else {
      const newExpandedKeys = dataList
        .map((item) => {
          if (item.title.toString().toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return getParentKey(item.key, dataSource);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      setExpandedKeys(newExpandedKeys as React.Key[]);
      setSearchValue(value);
      setAutoExpandParent(true);
    }
  };

  const onFilterOk = (e: React.MouseEvent<HTMLAnchorElement>) => {
    onFilterByChecked(checkedKeys);
    setDisplay(false);
  };

  const treeData = useMemo(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map((item) => {
        // wherether is the select all node, ignore it
        const isTheFirstNode = item.key === 0;
        const strTitle = item.title as string;
        const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase());
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const foundedStr = strTitle.slice(index, searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <SearchValueWrapper color={SEARCHED_TEXT}>{foundedStr}</SearchValueWrapper>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return { title: isTheFirstNode ? item.title : title, key: item.key, children: loop(item.children) };
        }
        return {
          title,
          key: item.key,
        };
      });
    return loop(dataSource);
  }, [searchValue, dataSource]);
  return (
    <SearchTreeWrapper>
      <SearchBox
        placeholder="Search"
        onSearch={onChange}
        open={display}
        onDropdownVisibleChange={(visible) => setDisplay(visible)}
        showSearch
        suffixIcon={<SearchOutlined />}
        dropdownRender={() => (
          <div>
            <Tree
              height={500}
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              treeData={treeData}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
            />
            <DividerSearchPopup />
            <RowAction justify="space-between" align="middle">
              <Button type="link" onClick={() => onCheck([])}>
                Reset
              </Button>
              <Button type="primary" onClick={onFilterOk}>
                OK
              </Button>
            </RowAction>
          </div>
        )}
      />
    </SearchTreeWrapper>
  );
};

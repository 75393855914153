import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Dropdown, Form, Input, Menu, Modal, notification, Space } from 'antd';
import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';

import { ChangePasswordModel } from 'app/types/user';
import { nameAbbreviation } from '../utils/stringUtils';
import { useFetch } from 'app/hooks/useFetch';
import { resetPasswordMyUser, fetchMyUser } from 'app/apis/userClient';

import { AuthContext } from '../contexts/AuthContext';
import { UserContext } from 'app/contexts/UserContext';
import './AvatarUser.scss';

export const AvatarAndUser = () => {
  const { logOut } = useContext(AuthContext);
  const { user } = useContext(UserContext);

  const { data: myUserData } = useFetch(fetchMyUser);
  // const { url, setUrl } = useContext(UserContext);
  const [form] = Form.useForm<ChangePasswordModel>();
  const [isModalVisisble, setModalVisible] = useState(false);
  // useEffect(() => {
  //   if (user) {
  //     const urlUser = myUserData?.avatarUrl;
  //     setUrl(urlUser);
  //   }
  // }, [user, myUserData, setUrl]);
  const menu = (
    <Menu>
      <Menu.Item>
        <Button type="text">
          <Link to={`/profile`}>My profile</Link>
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="text" onClick={logOut}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  const onModalSubmit = async () => {
    try {
      await form.validateFields();
      const { newPassword, oldPassword } = form.getFieldsValue();
      const result = resetPasswordMyUser({
        newPassword,
        oldPassword,
      })
        .then((a) => {
          notification.success({
            message: `Change password successfully`,
            duration: 2,
          });
        })
        .catch((error) => {
          console.error(error);
          notification.error({
            message: `Error: ${error.message}`,
            description: error,
            duration: 2,
          });
        });
      form.resetFields();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
      notification.error({
        message: `Error: ${error.message}`,
        description: error,
        duration: 2,
      });
    }
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return (
    <div className="user-container-avatar">
      <Space>
        <span className="user-fullname">{user.fullName}</span>
        <Dropdown overlay={menu} placement="bottomRight">
          <div style={{ minWidth: '56px' }}>
            <Avatar style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle' }} size="large" /* src={url} */>
              {nameAbbreviation(user.fullName)}
            </Avatar>
            <CaretDownOutlined />
          </div>
        </Dropdown>
      </Space>
      <Modal
        title="Change Password"
        visible={isModalVisisble}
        onOk={onModalSubmit}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <Form {...formItemLayout} form={form} name="basic" labelAlign="left">
          <Form.Item name="userName" label="User Name">
            <Input type="text" disabled defaultValue={user.fullName} />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            hasFeedback
            rules={[
              {
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                message: 'Please input at least a letter, at least a number, and at least a special character!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                message: 'Please input at least a letter, at least a number, and at least a special character!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

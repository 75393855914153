import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { getIntroduction, updateIntroduction } from 'app/apis/teamDetailClient';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import { StyledModal } from 'app/pages/ManageUsers/ManagedUsers.styled';
import { IntroductionRequest } from 'app/types/Stakeholders';
import { useContext, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromRaw, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import { DemoPlanWrapper } from '../DemoPlan/DemoPlan.styled';
import { isJson } from 'app/utils/utils';
import { isEmpty } from 'lodash';

const createEditorState = (content: string) => {
  let editorState;

  if (isJson(content)) {
    editorState = convertFromRaw(JSON.parse(content));
  } else {
    const { contentBlocks, entityMap } = convertFromHTML(`<p>${content}<p>`);
    editorState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  }

  return EditorState.createWithContent(editorState);
};
const defaultEditorValues =
  '{"blocks":[{"key":"6nr2t","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';
export const Introduction = () => {
  const { team } = useContext(TeamDetailContext);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [rawData, setRawData] = useState('');
  const [cacheData, setCacheData] = useState('');
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const layout = {
    wrapperCol: { span: 35 },
  };
  const requiredValidationMessage = 'This field is required';
  const optionsEdit = [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'emoji',
    'image',
    'remove',
    'history',
  ];
  const onUpdateIntroduction = async (values) => {
    setIsSubmit(true);
    let toDB = null;
    let checkValidation = false;
    if (values.content.blocks) {
      toDB = JSON.stringify(values.content);
      checkValidation = values.content.blocks[0].text !== '';
    } else {
      toDB = JSON.stringify(convertToRaw(values.content.getCurrentContent()));
      checkValidation = values.content?.getCurrentContent().getPlainText() !== '';
    }

    // const checkValidation = false;
    if (checkValidation) {
      setIsError(false);
      let editIntroductionRequest: IntroductionRequest = {
        content: toDB,
      };
      const resp = await updateIntroduction(team.id, editIntroductionRequest);

      if (resp) {
        setRawData(toDB);
        setToggleModal(false);
        setIsSubmit(false);
      }
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      const value = await getIntroduction(team.id);
      if (value) {
        setRawData(value.content);
      }
    };
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal]);

  const onEdit = () => {
    form.resetFields();
    setToggleModal(true);
    setCacheData(rawData);
  };

  const onCancel = () => {
    setToggleModal(false);
    setRawData(cacheData);
    setIsError(false);
  };

  return (
    <>
      <DemoPlanWrapper>
        <Row>
          <Col span={16}>
            <PageTitle>
              <Title level={5}>INTRODUCTION</Title>
            </PageTitle>
          </Col>
          <Col span={8} className="title-section-add-button">
            <Button data-html2canvas-ignore type="primary" onClick={() => onEdit()} size="large">
              Edit
            </Button>
          </Col>
          <DeviderWrapper />
          <Col span={24}>
            {rawData !== '' &&
              (JSON.parse(rawData)?.blocks?.length > 0 ? (
                <Editor
                  readOnly
                  toolbarHidden={true}
                  wrapperClassName="editor-wrapper-demo-plan-text"
                  toolbarClassName="editor-toolbar"
                  editorState={isEmpty(rawData) ? createEditorState(defaultEditorValues) : createEditorState(rawData)}
                />
              ) : (
                <Row justify="center" className="default-no-data-text">
                  No data
                </Row>
              ))}
          </Col>
        </Row>
        <StyledModal
          title="EDIT INTRODUCTION"
          visible={toggleModal}
          width={700}
          onCancel={() => onCancel()}
          centered
          footer={[
            <Button key="back" size="large" onClick={() => onCancel()}>
              Cancel
            </Button>,
            <Button key="submit" htmlType="submit" type="primary" size="large" form="stakeHolders">
              Submit
            </Button>,
          ]}
        >
          <Form
            {...layout}
            form={form}
            size={'large'}
            onFinish={onUpdateIntroduction}
            name="stakeHolders"
            autoComplete="off"
            colon={false}
            labelAlign="left"
            initialValues={{
              content: isEmpty(rawData) ? JSON.parse(defaultEditorValues) : JSON.parse(rawData),
            }}
          >
            <Form.Item name="content">
              <Editor
                wrapperClassName="editor-wrapper"
                editorClassName="editor-box"
                toolbarClassName="editor-toolbar"
                onEditorStateChange={(values) => {
                  form.setFieldsValue({
                    content: values,
                  });
                }}
                defaultContentState={isEmpty(rawData) ? JSON.parse(defaultEditorValues) : JSON.parse(rawData)}
                toolbar={{
                  options: optionsEdit,
                }}
              />
              {isError && isSubmit && (
                <div className="ant-form-item-explain ant-form-item-explain-error">{requiredValidationMessage}</div>
              )}
            </Form.Item>
          </Form>
        </StyledModal>
      </DemoPlanWrapper>
    </>
  );
};

import 'app/extensions';

import ReactDOM from 'react-dom';
import { App } from './app/App';

import './theme/color.scss';
import './styles.scss';
import './antd.less';

ReactDOM.render(<App />, document.getElementById('root'));

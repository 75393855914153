const IssueIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 58 56" version="1.1">
      <path
        d="M 0 28 C 0 53.686, 0.140 56, 1.700 56 C 3.022 56, 3.133 55.733, 2.200 54.800 C 0.492 53.092, 0.492 2.908, 2.200 1.200 C 3.133 0.267, 3.022 0, 1.700 0 C 0.140 0, 0 2.314, 0 28 M 55.800 1.200 C 57.508 2.908, 57.508 53.092, 55.800 54.800 C 54.867 55.733, 54.978 56, 56.300 56 C 57.860 56, 58 53.686, 58 28 C 58 2.314, 57.860 0, 56.300 0 C 54.978 0, 54.867 0.267, 55.800 1.200 M 22.873 17.684 C 15.098 22.509, 15.189 33.727, 23.040 38.364 C 30.781 42.937, 41 37.040, 41 28 C 41 18.939, 30.489 12.957, 22.873 17.684"
        stroke="none"
        fill="#fbf5f4"
        fillRule="evenodd"
      />
      <path
        d="M 2.200 1.200 C 0.492 2.908, 0.492 53.092, 2.200 54.800 C 3.908 56.508, 54.092 56.508, 55.800 54.800 C 57.508 53.092, 57.508 2.908, 55.800 1.200 C 54.092 -0.508, 3.908 -0.508, 2.200 1.200 M 22.873 17.684 C 15.098 22.509, 15.189 33.727, 23.040 38.364 C 30.781 42.937, 41 37.040, 41 28 C 41 18.939, 30.489 12.957, 22.873 17.684"
        stroke="none"
        fill="#d14536"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default IssueIcon;

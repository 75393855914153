import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Modal, Row } from 'antd';
import { IterationContext } from 'app/contexts/IterationContext';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { TopPerformersContext } from 'app/contexts/TopPerformersContext';
import { UserContext } from 'app/contexts/UserContext';
import { useAuthz } from 'app/hooks/useAuthz';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { TopPerformerResponse } from 'app/types/entity';
import { useContext, useState } from 'react';
import ToolTip from '../Tooltip/ToolTip';
import { TopPerformerCard } from './TopPerformerCard';
import './TopPerformers.scss';
import { TopPerformersEdit } from './TopPerformersEdit';

interface IProps {
  id?: string;
  className?: string;
}

export enum TopPerformerRank {
  First = 1,
  Second,
  Third,
}

export const TopPerformers = ({ id, className }: IProps) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const { topPerformers, setTopPerformers, topPerformerSelectOptions, setTopPerformerSelectOptions } =
    useContext(TopPerformersContext);
  const { team } = useContext(TeamDetailContext);
  const { selectedIteration, reportStatus } = useContext(IterationContext);
  const { isAuthorized } = useAuthz();
  const {
    user: { permissions },
  } = useContext(UserContext);
  const allowEdit = isAuthorized && canEditReport(reportStatus, permissions);

  const showModal = () => {
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  const onEditSaved = (data: TopPerformerResponse, index: number) => {
    let tempArray = [...topPerformers];
    tempArray[index] = data;
    setTopPerformers(tempArray);
  };

  const onItemDelete = (index: number) => {
    let tempArray = [...topPerformers];
    tempArray[index] = null;
    setTopPerformers(tempArray);
  };

  const onAvatarUploaded = (memberID: number, avatarUrl: string) => {
    let member = topPerformerSelectOptions.filter((x) => x.memberId === memberID);
    if (member && member[0]) {
      member[0].avatarUrl = avatarUrl;
      let index = topPerformerSelectOptions.indexOf(member[0]);
      let tempArray = [...topPerformerSelectOptions];
      tempArray[index] = member[0];
      setTopPerformerSelectOptions(tempArray);
    }
  };

  return (
    <div id={id} className={`${className} top-performers-section`}>
      <div className="top-performers-header">
        <Row gutter={[24, 24]}>
          <Col span={16}>
            <div>
              <span className="top-performers-title">
                <span>THE BEST PERFORMERS</span>
                <ToolTip
                  title={<span>Who gets the honor for the best performance and contribution in this iteration?</span>}
                />
              </span>
            </div>
          </Col>
          <Col data-html2canvas-ignore span={8}>
            {allowEdit && (
              <Button
                className="button-edit"
                onClick={() => {
                  showModal();
                }}
              >
                <EditOutlined />
                Edit
              </Button>
            )}
          </Col>
        </Row>
      </div>

      {!topPerformers || topPerformers.length === 0 || !topPerformers[0] ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Row id="top-performers">
          {topPerformers.map((v, i) => (
            <Col key={i} md={8} xs={24}>
              <TopPerformerCard performer={v} />
            </Col>
          ))}
        </Row>
      )}

      <Modal
        className="top-performers-modal"
        title="THE BEST PERFORMERS"
        visible={visibleModal}
        centered
        footer={null}
        closable={false}
        destroyOnClose={true}
      >
        <TopPerformersEdit
          performers={topPerformers}
          onCancel={closeModal}
          onSaved={onEditSaved}
          onDelete={onItemDelete}
          onAvatarUploaded={onAvatarUploaded}
          performerSelectOptions={topPerformerSelectOptions}
          iterationId={selectedIteration?.id}
          teamId={team?.id}
        />
      </Modal>
    </div>
  );
};

/* eslint-disable react/display-name */
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Input,
  Select,
  notification,
  Radio,
  Switch,
  RadioChangeEvent,
  Popconfirm,
  Popover,
  Typography,
} from 'antd';
import { TablePaginationConfig, Form, Row, Col } from 'antd';
import { fetchRoles, fetchTeamRoles } from 'app/apis/fetchRole';
import {
  deleteUser,
  updateUser,
  createUser,
  fetchUsers,
  resetPassword,
  fetchApprovalList,
  rejectUser,
  approveUser,
} from 'app/apis/userClient';
import { EditableColumn } from 'app/components/EditableTable';
import { useFetch } from 'app/hooks/useFetch';
import { PageTitle } from 'app/layouts/AdminLayout.styled';
import {
  CreateUserRequest,
  initFilterData,
  RejectUserRequest,
  UserResponse,
  UsersRequest,
  UserStatusEnum,
} from 'app/types/user';
import { updateItem } from 'app/utils/tableUtils';
import { MessageOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from 'app/contexts/UserContext';
import { StyledModal, StyledEditableTable, StyledHeader, StyledRejectPopoverWrapper } from './ManagedUsers.styled';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { openNotification } from 'app/utils/notificationUtils';
import { FieldRequiredText } from 'app/common/constants';
import { TeamContext } from 'app/contexts/TeamContext ';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import TextArea from 'antd/lib/input/TextArea';
import { Helmet } from 'react-helmet';

import Title from 'antd/lib/typography/Title';
import { PagedList } from 'app/types/PagedList';
const { Search } = Input;
const { Text } = Typography;

enum UsersMgmtPages {
  UsersMgmt = 'users',
  ApprovalList = 'approval',
}

const layout = {
  labelCol: { span: 6, offset: 1 },
  wrapperCol: { span: 16 },
};

const hasBeenRejected = (v) => v === UserStatusEnum.HasBeenRejected;

export const ManageUsers = () => {
  const searchEl = useRef(null);

  // ******* CONTEXT ******
  const { setBreadcrumbs } = useContext(TeamContext);
  const { refreshUser } = useContext(UserContext);
  // ************************************

  // ********** FORMS & MODALS **********
  const [addUserForm] = Form.useForm<CreateUserRequest>();
  const [rejectForm] = Form.useForm<RejectUserRequest>();

  const [addUserDialog, showAddUserDialog] = useState(false);
  const [rejectUserDialog, showRejectUserDialog] = useState(false);
  // ************************************

  // ***** DATA FOR ROLE SELECTION *****
  const { data: userRoles } = useFetch(fetchRoles);
  const { data: teamRoles } = useFetch(fetchTeamRoles);

  const roleOptions = userRoles?.map((item) => ({ label: item.displayName, value: item.name }));
  const roleOptionsTextArray = userRoles?.map((item) => ({ value: item.name, text: item.displayName }));

  const teamRoleOptions = teamRoles?.map((item) => ({ label: item.displayName, value: item.value }));
  const teamOptionsTextArray = teamRoles?.map((item) => ({ value: item.value, text: item.displayName }));
  // ************************************

  // ************** STATES **************
  const [data, setData] = useState<UserResponse[]>();
  const [filterData, setFilterData] = useState<UsersRequest>({ ...initFilterData });

  const [currentPage, setCurrentPage] = useState<UsersMgmtPages>(UsersMgmtPages.UsersMgmt);
  const [totalRows, setTotalRows] = useState<number>();
  // ************************************

  const {
    data: usersMgmtData,
    loading: usersLoading,
    error: userFetchError,
  } = useFetch<PagedList<UserResponse>>(() => {
    // Only fetch when the page is changing
    if (currentPage === UsersMgmtPages.UsersMgmt) return fetchUsers({ ...filterData });
    return new Promise(() => usersMgmtData);
  }, [filterData, currentPage]);

  const {
    data: approvalListData,
    loading: approvalListLoading,
    error: approvalListFetchError,
  } = useFetch<PagedList<UserResponse>>(() => {
    // Only fetch when the page is changing
    if (currentPage === UsersMgmtPages.ApprovalList) return fetchApprovalList({ ...filterData });
    return new Promise(() => approvalListData);
  }, [filterData, currentPage]);

  // First load page
  useEffect(() => {
    setCurrentPage(UsersMgmtPages.UsersMgmt);

    setBreadcrumbs([createDefaultBreadCrumb('User Management')]);
  }, [setBreadcrumbs]);

  // When changing page or update filters
  useEffect(() => {
    const dataSource = currentPage === UsersMgmtPages.UsersMgmt ? usersMgmtData : approvalListData;

    // Sort Approval List first before set to the state
    // (Read the reason why we need to do this at `approvalListColumns`)
    const items =
      currentPage === UsersMgmtPages.UsersMgmt
        ? dataSource?.items
        : dataSource?.items
            // Sort userName ASC
            ?.sort((a, b) => a.userName.localeCompare(b.userName))
            // Sort userStatus ASC (Waiting first, then Rejected)
            ?.sort((a, b) => {
              const r1 = hasBeenRejected(a.userStatus) ? 1 : 0;
              const r2 = hasBeenRejected(b.userStatus) ? 1 : 0;
              return r1 - r2;
            });

    setData(items);
    setTotalRows(dataSource?.totalItems);
  }, [approvalListData, currentPage, usersMgmtData, filterData]);

  const onTabChange = useCallback(async ({ target: { value } }: RadioChangeEvent) => {
    // Reset pageIndex
    setFilterData((data) => ({
      ...data,
      pageIndex: 1,
    }));

    setCurrentPage(value);
  }, []);

  // ********** ADD USER MODAL **********
  const onAddUserFormSubmit = async () => {
    try {
      await addUserForm.validateFields();
    } catch (error) {
      return;
    }

    try {
      await createUser(addUserForm.getFieldsValue());
      refreshUser();
      addUserForm.resetFields();
      showAddUserDialog(false);
      notification.success({
        message: `Create user successfully`,
        duration: 2,
      });
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors?.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    } finally {
      setFilterData({ ...filterData });
    }
  };
  // ************************************

  // ********* APPROVE & REJECT *********
  const onApproveSubmit = useCallback(
    async (id: number) => {
      try {
        await approveUser(id);
        notification.success({
          message: `Approve user successfully`,
          duration: 2,
        });
      } catch (error) {
        const err = error.data as ErrorResponse;
        if (err.error.validationErrors?.length > 0) {
          openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
        } else {
          openNotification(error.status, err.error.message);
        }
      } finally {
        setFilterData({ ...filterData });
      }
    },
    [filterData]
  );

  const onRejectSubmit = async () => {
    try {
      await rejectForm.validateFields();
    } catch (error) {
      return;
    }

    const { userId, note } = rejectForm.getFieldsValue();

    try {
      await rejectUser(userId, note);
      showRejectUserDialog(false);
      notification.success({
        message: `Reject user successfully`,
        duration: 2,
      });
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors?.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    } finally {
      setFilterData({ ...filterData });
    }
  };
  // ************************************

  // ************** TABLE ***************

  ///===== Handlers
  const onTableSave = async (id: number, { customerEmail, userRole, teamRole, fullName, isActive }: UserResponse) => {
    try {
      await updateUser(id, { customerEmail, userRole, teamRole, fullName, isActive });
      const newData = updateItem({ id, customerEmail, userRole, teamRole, fullName, isActive }, data);
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });
      setData(newData);
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors?.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };

  const onTableDelete = useCallback(
    async (id: number) => {
      try {
        await deleteUser(id);
        const newData =
          currentPage === UsersMgmtPages.UsersMgmt
            ? await fetchUsers({ ...filterData })
            : await fetchApprovalList({ ...filterData });
        setData(newData.items);
        setTotalRows(newData.totalItems);
      } catch (error) {
        openNotification(400, 'Delete failed!');
      }
    },
    [currentPage, filterData]
  );

  const onTableChangePassword = async (id: number) => {
    try {
      await resetPassword(id);
      notification.success({
        message: `New password has been sent to user`,
        duration: 2,
      });
      // setIsShowDiaglog(true);
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors?.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };

  // Page changed, Filter updated...
  const onTableChange = useCallback(async (pagination: TablePaginationConfig, filterData: UsersRequest) => {
    setFilterData({
      ...filterData,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current,
    });
  }, []);
  ///==============

  ///===== Columns
  const handleSearch = (setSelectedKeys, selectedKeys, confirm, dataIndex) => {
    filterData[dataIndex] = selectedKeys;
    setFilterData({
      ...filterData,
    });

    confirm();
  };

  const handleReset = (dataIndex, clearFilters) => {
    filterData[dataIndex] = '';
    setFilterData({
      ...filterData,
    });

    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            defaultValue={selectedKeys[0]}
            onChange={(e) => {
              if (e.type === 'change') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                handleReset(dataIndex, clearFilters);
              }
            }}
            placeholder={`Search ${dataIndex}`}
            onSearch={(value) => handleSearch(setSelectedKeys, [value], confirm, dataIndex)}
            onPressEnter={(e: any) => handleSearch(setSelectedKeys, [e.target.value], confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    // eslint-disable-next-line react/display-name
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const baseColumns = useMemo<EditableColumn<UserResponse>[]>(
    () => [
      {
        title: '#',
        width: '3.5%',
        align: 'center',
        render: (value, record, index) => (filterData.pageIndex - 1) * filterData.pageSize + index + 1,
      },
      {
        title: 'UserName',
        dataIndex: 'userName',
        key: 'userName',
        editable: false,
        rules: [{ required: true, message: FieldRequiredText }],
        ...getColumnSearchProps('userName'),
      },
      {
        title: 'FPT Email',
        dataIndex: 'email',
        key: 'email',
        editable: false,
        ...getColumnSearchProps('email'),
      },
      {
        title: 'Cox Account',
        dataIndex: 'customerEmail',
        key: 'customerEmail',
        editable: true,
        ...getColumnSearchProps('customerEmail'),
      },
      {
        title: 'User Role',
        dataIndex: 'userRole',
        key: 'userRoles',
        filters: roleOptionsTextArray,
        editable: true,
        options: roleOptions,
        render: (_, record) => {
          return roleOptionsTextArray?.find((role) => role.value === record.userRole)?.text ?? '';
        },
        inputType: 'select',
      },
      {
        title: 'Team Role',
        dataIndex: 'teamRole',
        key: 'teamRoles',
        filters: teamOptionsTextArray,
        editable: true,
        options: teamRoleOptions,
        render: (_, record) => {
          return record.teamRole;
        },
        inputType: 'select',
      },
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        editable: true,
        width: '20%',
        ...getColumnSearchProps('fullName'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterData.pageIndex,
      filterData.pageSize,
      teamRoleOptions,
      teamOptionsTextArray,
      roleOptions,
      roleOptionsTextArray,
    ]
  );

  const userMgmtColumns = useMemo<EditableColumn<UserResponse>[]>(
    () => [
      ...baseColumns,
      {
        title: 'Active',
        dataIndex: 'isActive',
        inputType: 'select',
        editable: true,
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
        filters: [
          { text: 'True', value: true },
          { text: 'False', value: false },
        ],
      },
    ],
    [baseColumns]
  );

  const approvalListColumns = useMemo<EditableColumn<UserResponse>[]>(
    () => [
      ...baseColumns,
      {
        title: 'User Status',
        dataIndex: 'userStatus',
        inputType: 'text',
        render: (value, record) => {
          if (!hasBeenRejected(record.userStatus)) return <span>Waiting</span>;

          return (
            <>
              <Text className="reject-lbl">Rejected</Text>

              {/* SO: https://stackoverflow.com/a/63500556 */}
              <StyledRejectPopoverWrapper>
                <Popover
                  placement="right"
                  getPopupContainer={(triggerNode) => triggerNode}
                  title={'Admin notes'}
                  content={<span>{record?.note}</span>}
                >
                  <MessageOutlined style={{ cursor: 'help' }} />
                </Popover>
              </StyledRejectPopoverWrapper>
            </>
          );
        },

        // Sort userStatus ASC (Waiting first, then Rejected)
        // Dunno why this don't work (as some thread say this only work at first render)
        // So, I've to do some hacks with the dataSource instead
        // defaultSortOrder: 'ascend',

        // And the reason I don't use this prop, although it works, is because if you've set this,
        // you can't do sort on the UI anymore. :(
        // sortOrder: 'ascend',

        sorter: (a, b) => {
          const r1 = hasBeenRejected(a.userStatus) ? 1 : 0;
          const r2 = hasBeenRejected(b.userStatus) ? 1 : 0;
          return r1 - r2;
        },
        filters: [
          { value: 'Waiting', text: 'Waiting' },
          { value: 'HasBeenRejected', text: 'Rejected' },
        ],
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        width: '5%',
        inputType: 'custom',
        inputProps: {
          withEdit: true,
          style: {
            display: 'flex',
          },
        },
        render: (value, record) => {
          return (
            <>
              <Popconfirm
                icon={<QuestionCircleOutlined style={{ color: '#0172db' }} />}
                title="Do you want to approve this user?"
                onConfirm={async () => await onApproveSubmit(record.id)}
              >
                <Button type="link" className="approval-btn">
                  Approve
                </Button>
              </Popconfirm>
              <Button
                type="link"
                danger
                onClick={() => {
                  rejectForm.resetFields();
                  // Set userId and note (if exist)
                  rejectForm.setFieldsValue({ userId: record.id, note: record.note });
                  showRejectUserDialog(true);
                }}
              >
                <Text type="danger">Reject</Text>
              </Button>
              <Popconfirm
                icon={<QuestionCircleOutlined style={{ color: '#ff4d4f' }} />}
                title="Do you want to delete this user?"
                onConfirm={async () => await onTableDelete(record.id)}
              >
                <Button type="primary" danger>
                  DELETE!!
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ],
    [baseColumns, onApproveSubmit, onTableDelete, rejectForm]
  );
  ///==============

  // ************************************

  if (userFetchError) {
    return <div>{JSON.stringify(userFetchError)}</div>;
  }

  if (approvalListFetchError) {
    return <div>{JSON.stringify(approvalListFetchError)}</div>;
  }

  return (
    <div>
      <Helmet>
        <title>ADM Tool | User management</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>USER MANAGEMENT</Title>
      </PageTitle>
      <StyledHeader>
        <Col span={8} data-position="left">
          <Button
            type="primary"
            onClick={() => {
              addUserForm.resetFields();
              showAddUserDialog(true);
            }}
            size="large"
          >
            Add new user
          </Button>
        </Col>
        <Col span={8} offset={8} data-position="right">
          <Radio.Group value={currentPage} onChange={onTabChange} size="large" optionType="button" buttonStyle="solid">
            <Radio.Button value="approval">Approval list</Radio.Button>
            <Radio.Button value="users">User list</Radio.Button>
          </Radio.Group>
        </Col>
      </StyledHeader>
      <StyledEditableTable
        data={data}
        totalRows={totalRows}
        loading={currentPage === UsersMgmtPages.UsersMgmt ? usersLoading : approvalListLoading}
        columns={currentPage === UsersMgmtPages.UsersMgmt ? userMgmtColumns : approvalListColumns}
        onChange={onTableChange}
        onSave={onTableSave}
        onDelete={onTableDelete}
        onChangePassword={onTableChangePassword}
        isChangePassword={true}
        isEdit={true}
        isDelete={true}
        current={filterData.pageIndex}
        pageSize={filterData.pageSize}
      />
      <StyledModal
        title="ADD NEW USER"
        visible={addUserDialog}
        onOk={onAddUserFormSubmit}
        onCancel={() => showAddUserDialog(false)}
        width={800}
        centered
        footer={[
          <Button key="back" size="large" onClick={() => showAddUserDialog(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="large"
            loading={currentPage === UsersMgmtPages.UsersMgmt ? usersLoading : approvalListLoading}
            onClick={onAddUserFormSubmit}
          >
            Create
          </Button>,
        ]}
      >
        <Form
          {...layout}
          className="add-user-modal"
          form={addUserForm}
          size={'large'}
          name="createUser"
          autoComplete="off"
          colon={false}
          labelAlign="left"
        >
          <Form.Item
            label="Username"
            name="userName"
            initialValue={''}
            rules={[{ required: true, message: FieldRequiredText }]}
          >
            <Input placeholder="Input Username" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Fullname"
            name="fullName"
            initialValue={''}
            rules={[{ required: true, message: FieldRequiredText }]}
          >
            <Input placeholder="Input Full Name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="FPT Email"
            name="email"
            initialValue={''}
            rules={[
              { required: true, message: FieldRequiredText },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input type={'email'} placeholder="Input Email " />
          </Form.Item>
          <Form.Item
            label="Cox Account"
            name="customerEmail"
            initialValue={''}
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input type={'email'} placeholder="Input Cox Account" autoComplete="off" />
          </Form.Item>
          <Form.Item label="User Role" name="userRole" rules={[{ required: true, message: FieldRequiredText }]}>
            <Select placeholder="Select Role" options={roleOptions} />
          </Form.Item>
          <Form.Item label="Team Role" name="teamRole" rules={[{ required: true, message: FieldRequiredText }]}>
            <Select placeholder="Select Team Role" options={teamRoleOptions} />
          </Form.Item>
          <Form.Item
            label="Active"
            name="isActive"
            initialValue={true}
            valuePropName={'checked'}
            rules={[{ required: true, message: FieldRequiredText }]}
          >
            <Switch defaultChecked />
          </Form.Item>
        </Form>
      </StyledModal>
      <StyledModal
        title="REJECT THIS REQUEST"
        visible={rejectUserDialog}
        onOk={onRejectSubmit}
        onCancel={() => showRejectUserDialog(false)}
        centered
        width={800}
        footer={[
          <Button key="back" size="large" onClick={() => showRejectUserDialog(false)}>
            Cancel
          </Button>,
          <Popconfirm key="submit" title="Do you want to reject this user?" onConfirm={onRejectSubmit}>
            <Button
              type="primary"
              size="large"
              loading={currentPage === UsersMgmtPages.UsersMgmt ? usersLoading : approvalListLoading}
              danger
            >
              Reject
            </Button>
          </Popconfirm>,
        ]}
      >
        <Form
          labelCol={{ span: 4, offset: 1 }}
          wrapperCol={{ span: 18 }}
          className="reject-user-modal"
          form={rejectForm}
          size={'large'}
          name="rejectUser"
          autoComplete="off"
          colon={false}
          labelAlign="left"
        >
          <Form.Item hidden name="userId" rules={[{ required: true, message: FieldRequiredText }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Reason"
            name="note"
            initialValue={''}
            rules={[{ required: true, message: FieldRequiredText }]}
          >
            <TextArea placeholder="Explain why you are rejecting this user." autoComplete="off" rows={5} />
          </Form.Item>
        </Form>
      </StyledModal>
    </div>
  );
};

import { Col, Row } from 'antd';
import { SubCommonSection } from '../../../../components/SubCommonSection/SubCommonSection';
import { DevelopmentTeamAndIssueData, FilterStatus, TeamDetail, WeeklyIssueTypeColour } from 'app/types/WeeklyOverview';
import { WeeklyStatusCard } from 'app/components/WeeklyStatus/WeeklyStatusCard';
import { StatusDetailWrapper } from './StatusDetail.styled';
interface Props {
  riskTeams: DevelopmentTeamAndIssueData[];
  criticalTeams: DevelopmentTeamAndIssueData[];
  goodTeams: DevelopmentTeamAndIssueData[];
  title: string;
  setToggleModal: (visible: boolean) => void;
  setSelectedTeamCardData: (data: TeamDetail) => void;
  filter: FilterStatus;
}

export const StatusDetail = ({
  riskTeams,
  criticalTeams,
  goodTeams,
  title,
  setToggleModal,
  setSelectedTeamCardData,
  filter,
}: Props) => {
  const onOpenModal = (teamData: DevelopmentTeamAndIssueData) => {
    let convertData: TeamDetail = {
      id: teamData.id,
      name: teamData.name,
      issues: [],
    };
    setToggleModal(true);
    setSelectedTeamCardData(convertData);
  };

  return (
    <StatusDetailWrapper>
      <SubCommonSection title={title}>
        <Col span={24}>
          {criticalTeams.length > 0 && filter.critical && (
            <div className="weekly-overview-group">
              <Row gutter={[19, 30]}>
                {criticalTeams?.map((data) => (
                  <Col key={data.id}>
                    <div onClick={() => onOpenModal(data)} className="weekly-overview-card">
                      <WeeklyStatusCard name={data.name} type={WeeklyIssueTypeColour.Critical} tags={data.tags} />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {riskTeams.length > 0 && filter.risk && (
            <div className="weekly-overview-group">
              <Row gutter={[19, 30]}>
                {riskTeams?.map((data) => (
                  <Col key={data.id}>
                    <div onClick={() => onOpenModal(data)} className="weekly-overview-card">
                      <WeeklyStatusCard name={data.name} type={WeeklyIssueTypeColour.Risk} tags={data.tags} />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {goodTeams.length > 0 && filter.good && (
            <div className="weekly-overview-group">
              <Row gutter={[19, 30]}>
                {goodTeams?.map((data) => (
                  <Col key={data.id}>
                    <div onClick={() => onOpenModal(data)} className="weekly-overview-card">
                      <WeeklyStatusCard name={data.name} type={WeeklyIssueTypeColour.Good} tags={[]} />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Col>
      </SubCommonSection>
    </StatusDetailWrapper>
  );
};

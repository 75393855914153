import { CalendarOutlined, UserOutlined } from '@ant-design/icons';
import Col from 'antd/es/grid/col';
import styled from 'styled-components';

export const Icon = styled(Col)`
  margin-top: 10px;
`;
export const FormStyle = styled(UserOutlined)`
  margin-right: 10px;
  margin-top: 5px;
`;

export const TimeStyle = styled(CalendarOutlined)`
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
`;

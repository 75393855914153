import { Chord } from '@ant-design/plots';
import { ChordConfig } from '@ant-design/plots/es/components/chord';
import { DependencyChartItem, OrganizationFormPayload } from 'app/types/Organizational';
interface IDependencychart {
  teamData: OrganizationFormPayload;
}

export const DependenciesChart = ({ teamData }: IDependencychart) => {
  const dataConverted = teamData.dependencies.map((item) => item.teamName);
  const mappingDataToChart = (data: string[]) => {
    const alterData: string[] = [teamData.teamName, ...data];
    let result: DependencyChartItem[] = [];

    for (let i = 0; i < alterData.length; i++) {
      const sourceItem = alterData[i];
      const subList = data.slice(i);
      for (let j = 0; j < subList.length; j++) {
        const targetItem = subList[j];
        const chartItem: DependencyChartItem = {
          source: sourceItem,
          target: targetItem,
        };
        result.push(chartItem);
      }
    }
    return result;
  };
  const dataChart = mappingDataToChart(dataConverted);
  const config: ChordConfig = {
    data: dataChart,
    sourceField: 'source',
    targetField: 'target',
    weightField: '1',
    style: {
      display: 'block',
      rotate: 'auto',
    },
    nodePaddingRatio: 0.9999,
    tooltip: {
      showContent: false,
    },
  };
  return <Chord {...config} />;
};

import { CloudUploadOutlined, DeleteOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, notification, Select, TreeSelect, Upload } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { createAward, deleteAward, getAwardTypes, getUser, updateAward } from 'app/apis/award';
import { getOjectTeamData } from 'app/apis/statisticOrganization';
import { ErrorType } from 'app/common/constants';
import { useFetch } from 'app/hooks/useFetch';
import { AwardModel } from 'app/types/AllAwardType';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { filterFPTInvolved, filterNodeFPTInvolved } from 'app/utils/teamTreeSelectUtils';
import { range } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { StyleCreateAward } from './CreateAward.styled';

const enum AwardType {
  Individual = 1,
  Team = 2,
}

interface IProps {
  data: AwardModel;
  open: boolean;
  onClose: () => void;
  onChange: () => void;
}

//Notification
type NotificationType = 'success' | 'warning' | 'error';
type Action = 'Create' | 'Update' | 'Delete';

const openNotificationWithIcon = (type: NotificationType, action: Action) => {
  notification[type]({
    message: action + ' ' + type,
    description: '',
  });
};

export const CreateAwardPannel = (props: IProps) => {
  const [requestForValue, setRequestForValue] = useState([]);
  const [awardToType, setAwardToType] = useState<number>(0);
  const [memberValue, setMemberValue] = useState([]);
  const [create, setCreate] = useState<boolean>(true);

  const [teamSelectOpt, setTeamSelectOpt] = useState<DataNode[]>();
  const [memberSelectData, setMemberSelectData] = useState([]);

  const [uploadFiles, setUploadFiles] = useState<File>(null);
  const [form] = Form.useForm();

  const { data: awardRequestTypes } = useFetch(getAwardTypes, []);
  const { data: memberData } = useFetch(getUser, []);
  const { data: dataTeam } = useFetch(getOjectTeamData, []);

  useEffect(() => {
    setInitValue();
  }, [props.data, props.open]);

  useEffect(() => {
    if (!dataTeam) return;

    setTeamSelectOpt(filterNodeFPTInvolved(filterFPTInvolved(dataTeam), true));
  }, [dataTeam]);

  useEffect(() => {
    let members = [];
    if (memberData) {
      memberData?.map((e) =>
        members.push({
          value: e.userName,
          label: e.userName,
        })
      );
      setMemberSelectData(members);
    }
  }, [memberData]);

  const handleAwardTypeSelected = (value) => {
    switch (value) {
      case 1:
        setAwardToType(AwardType.Individual);
        break;
      case 2:
        setAwardToType(AwardType.Team);
        break;
    }

    form.resetFields(['awardTo']);
  };

  const handleRequestForChange = (value) => {
    switch (value) {
      case 'MONTH':
        setRequestForValue(range(0, 12, 1).map((i) => ({ label: moment().month(i).format('MMM'), value: i + 1 })));
        break;
      case 'QUARTER':
        setRequestForValue([
          { label: 'Q1 (Jan-Mar)', value: 1 },
          { label: 'Q2 (Apr-Jun)', value: 2 },
          { label: 'Q3 (Jul-Sep)', value: 3 },
          { label: 'Q4 (Oct-Dec)', value: 4 },
        ]);
        break;
      case 'YEAR':
        setRequestForValue(range(2020, moment().year() + 1, 1).map((i) => ({ label: i, value: i })));
        break;
    }
  };

  const setInitValue = () => {
    if (props.data === null) {
      form.resetFields();
      setAwardToType(0);
      setCreate(true);
    } else {
      const value = {
        ...props.data,
        awardTo: Array.from(props.data.awardTo, (x) => x.id),
        presentativeFor: props.data.presentativeFor?.id,
        requestType: props.data.requestType?.id,
        image: {
          file: {
            uid: '',
            name: '',
            url: props.data.imageUrl,
            thumbUrl: '',
            status: 'removed',
          },
          fileList: [],
        },
      };
      form.setFieldsValue(value);
      setAwardToType(props.data.type);
      setCreate(false);
    }
  };

  const handleSubmitForm = async () => {
    const payload = form.getFieldsValue();
    try {
      const response = await createAward({ ...payload, image: uploadFiles });
      if (response) {
        openNotificationWithIcon('success', 'Create');
        props.onChange();
      }
    } catch {
      openNotificationWithIcon('error', 'Create');
    } finally {
      props.onClose();
    }
  };
  const handleUpdate = async () => {
    const payload = form.getFieldsValue();
    try {
      const response = await updateAward({ ...payload, id: props.data?.id, image: uploadFiles });
      if (response) {
        openNotificationWithIcon('success', 'Update');
        props.onChange();
      }
    } catch {
      openNotificationWithIcon('error', 'Update');
    } finally {
      props.onClose();
    }
  };
  const handleDelete = async () => {
    try {
      const response = await deleteAward(props.data?.id);
      if (response) {
        openNotificationWithIcon('success', 'Delete');
        props.onChange();
      }
    } catch {
      console.log('error');
    } finally {
      props.onClose();
    }
  };

  const onCancle = () => {
    form.resetFields();
  };

  return (
    <Modal
      open={props.open}
      title={create ? <b>CREATE AWARD</b> : <b>UPDATE AWARD</b>}
      onOk={props.onClose}
      onCancel={props.onClose}
      footer={null}
      width={800}
    >
      <StyleCreateAward>
        <Form
          initialValues={{ year: 2024 }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18, offset: 1 }}
          layout="horizontal"
          form={form}
          onFinish={() => handleSubmitForm()}
        >
          <Form.Item style={{ width: '100%', marginBottom: '0' }} name="requestfor-group" label="Request for" required>
            <Form.Item
              name="requestFor"
              style={{ display: 'inline-block', marginBottom: '0', minWidth: '45%', marginRight: 'calc(3%)' }}
              rules={[{ required: true, message: 'Please select your request for!' }]}
            >
              <Select placeholder="Select Request for" onChange={handleRequestForChange}>
                <Select.Option value="MONTH">Month</Select.Option>
                <Select.Option value="QUARTER">Quarter</Select.Option>
                <Select.Option value="YEAR">Year</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="requestForValue"
              rules={[{ required: true, message: 'Please select your request for!' }]}
              style={{ display: 'inline-block', width: '33%', marginRight: 'calc(3%)' }}
            >
              <Select options={requestForValue} placeholder="Select value" />
            </Form.Item>
            <Form.Item name="year" style={{ display: 'inline-block' }}>
              <InputNumber />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="requestType"
            label="Request Type"
            rules={[{ required: true, message: 'Please select award request type!' }]}
          >
            <Select>
              {awardRequestTypes?.map((e, index) => (
                <Select.Option key={index} value={e.id}>
                  {e.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type" label="Award Type" rules={[{ required: true, message: 'Please select award type!' }]}>
            <Select onSelect={handleAwardTypeSelected}>
              <Select.Option value={AwardType.Individual}>Individual</Select.Option>
              <Select.Option value={AwardType.Team}>Team</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="amount"
            label="Award Amount"
            rules={[{ required: true, message: 'Please fill the award amount!' }]}
          >
            <Input placeholder="000,000,000" suffix="VNĐ" />
          </Form.Item>
          <Form.Item
            name="awardTo"
            label="Award To"
            style={{ marginBottom: '0' }}
            rules={[{ required: true, message: 'Please select award to!' }]}
          >
            {(awardToType === AwardType.Individual || awardToType === 0) && (
              <Form.Item name={['awardTo']}>
                <Select
                  showSearch
                  optionFilterProp="label"
                  mode="tags"
                  tokenSeparators={[',']}
                  options={memberSelectData}
                  disabled={awardToType === 0}
                  value={memberValue}
                  onChange={(newValue) => {
                    setMemberValue(newValue.sort());
                  }}
                />
              </Form.Item>
            )}

            {awardToType === AwardType.Team && (
              <Form.Item name={['awardTo']}>
                <TreeSelect
                  treeCheckable
                  showSearch
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandAll
                  treeData={teamSelectOpt}
                  treeNodeFilterProp="title"
                  filterTreeNode
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name="presentativeFor"
            label="Presentative for"
            rules={[{ required: true, message: 'Please select presentative for!' }]}
          >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Please select"
              allowClear
              treeDefaultExpandAll
              treeData={teamSelectOpt}
              treeNodeLabelProp="showValue"
              treeNodeFilterProp="title"
              filterTreeNode
            />
          </Form.Item>
          <Form.Item label="Feedback for award" name="feedback">
            <Input.TextArea showCount maxLength={10000} style={{ height: '100px' }} />
          </Form.Item>
          <Form.Item label="Image" name="image">
            <Upload
              defaultFileList={[
                {
                  uid: '',
                  name: '',
                  url: '',
                  thumbUrl: '',
                },
              ]}
              showUploadList={{
                showDownloadIcon: false,
                showRemoveIcon: true,
              }}
              action={''}
              listType="picture"
              maxCount={1}
              multiple
              accept=".jpeg,.png,.jpg"
              beforeUpload={(file) => {
                if (file.size / 1024 / 1024 >= 3) {
                  openNotificationByType(ErrorType, file.name + ' size is too big. File must be under 3MB ');
                  return Upload.LIST_IGNORE;
                }
                setUploadFiles(file);
                return false;
              }}
            >
              {
                <Button style={{ paddingLeft: '0' }} type="link" className="btn-upload" icon={<CloudUploadOutlined />}>
                  Upload Picture
                </Button>
              }
            </Upload>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              className="mr-2"
              htmlType="button"
              onClick={() => {
                props.onClose();
                onCancle();
              }}
            >
              Cancel
            </Button>
            {create ? (
              <>
                <Button className="mr-2" type="primary" htmlType="submit" icon={<PlusOutlined />}>
                  Create
                </Button>
                <Button
                  className="mr-2"
                  type="link"
                  htmlType="button"
                  onClick={() => {
                    form.submit();
                    form.resetFields();
                  }}
                >
                  Save and create new
                </Button>
              </>
            ) : (
              <>
                <Button className="mr-2" type="primary" icon={<SyncOutlined />} onClick={handleUpdate}>
                  Update
                </Button>
                <Button className="mr-2" type="primary" icon={<DeleteOutlined />} onClick={handleDelete} danger>
                  Delete
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </StyleCreateAward>
    </Modal>
  );
};

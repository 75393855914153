import { Col, Progress, Row, Space, TablePaginationConfig, Tooltip, notification } from 'antd';
import {
  PROGRESS_BAR_MAX_COLOR,
  PROGRESS_BAR_MIN_COLOR,
  TABLE_DEFAULT_PAGE_SIZE,
  TESTCASE_BLOCKED_COLOR,
  TESTCASE_ERROR_COLOR,
  TESTCASE_FAIL_COLOR,
  TESTCASE_INCONCLUSIVE_COLOR,
  TESTCASE_NOT_APPLICABLE_COLOR,
  TESTCASE_NOT_RUN_COLOR,
  TESTCASE_PASS_COLOR,
} from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { SubPage } from 'app/types/TeamDetail';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TestExecutionTableContainer } from './TestExecutionTable.styled';
import './TestExecutionTable.scss';
import { TicketResponse, UpdateTestCase, WorkItemType } from 'app/types/PlannedWorkItem';
import DefectIcon from 'app/pages/TeamDetail/Plan/DefectIcon';
import UserStoryIcon from 'app/pages/TeamDetail/Plan/UserStoryIcon';
import Icon from '@ant-design/icons';
import { IterationContext } from 'app/contexts/IterationContext';
import { useAuthz } from 'app/hooks/useAuthz';
import { updateTestCaseNote } from 'app/apis/teamDetailClient';
import { ErrorResponse } from 'app/types/ErrorResponse';
import { openNotification } from 'app/utils/notificationUtils';
import { updateItem } from 'app/utils/tableUtils';
import { TestCaseMerge } from 'app/pages/TeamDetail/Report/Report';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { UserContext } from 'app/contexts/UserContext';

interface IProps {
  page: SubPage;
  dataSource: TestCaseMerge[];
}

export const TestExecutionTable = ({ dataSource }: IProps) => {
  const { isAuthorized } = useAuthz();
  const [page, setPage] = useState(1);
  const [testCase, setTestCase] = useState<TestCaseMerge[]>();
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState<boolean>(false);

  //After change iteration
  useEffect(() => {
    if (!dataSource) return;

    setTestCase(dataSource);
    setLoading(false);
  }, [dataSource]);
  const { reportStatus } = useContext(IterationContext);
  const {
    user: { permissions },
  } = useContext(UserContext);

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const renderIDColumn = (record: TestCaseMerge) => {
    const workItemType = record.type;
    const workItemCode = record.code;
    if (workItemType === WorkItemType.Defect) {
      return (
        <Space>
          <Icon component={DefectIcon} />
          {workItemCode}
        </Space>
      );
    }

    return (
      <Space>
        <Icon component={UserStoryIcon} />
        {workItemCode}
      </Space>
    );
  };

  const countTCs = (record: TestCaseMerge): number => {
    const stateArray = record.testCaseResults;
    const totalCount = 0;
    const sumTCs = stateArray?.reduce((total: number, currentValue) => {
      return total + currentValue.value ?? 0;
    }, totalCount);
    return isNaN(sumTCs) ? 0 : sumTCs;
  };

  const countExecuted = (record: TestCaseMerge) => {
    const stateArray = record.testCaseResults;
    const totalCount = 0;
    const sumExecuted = stateArray?.reduce((total: number, currentValue) => {
      if (currentValue.type === 'Pass' || currentValue.type === 'Fail') {
        return total + currentValue.value ?? 0;
      } else {
        return total;
      }
    }, totalCount);
    return isNaN(sumExecuted) ? 0 : sumExecuted;
  };

  const countRemaining = (record: TestCaseMerge) => {
    const remainingNumber = Number(countTCs(record) - countExecuted(record));
    return isNaN(remainingNumber) ? 0 : remainingNumber;
  };

  const PercentageBar = ({ value }: any) => {
    const middlePoint = value - 100;

    const strokes =
      value > 100
        ? {
            '0%': middlePoint > 100 ? PROGRESS_BAR_MAX_COLOR : PROGRESS_BAR_MIN_COLOR,
            [`${100 - middlePoint}%`]: PROGRESS_BAR_MAX_COLOR,
          }
        : {
            from: PROGRESS_BAR_MIN_COLOR,
            to: PROGRESS_BAR_MAX_COLOR,
          };

    return (
      <>
        <Row gutter={[8, 8]} className="progress-wrapper">
          <Col span={24} className="progress-number">
            <Progress
              className="bar"
              strokeColor={strokes}
              percent={value > 100 ? 100 : value}
              showInfo={false}
              strokeLinecap="square"
            />
            <div className="percentage-value inthebar">
              {value === Infinity ? 'N/A' : value}
              {value !== Infinity && '%'}
            </div>
          </Col>
        </Row>
      </>
    );
  };
  const MyTestProgressNumber = (record: TestCaseMerge) => {
    const progressNumber = Number(((countExecuted(record) / countTCs(record)) * 100).toFixed());
    if (isNaN(progressNumber)) {
      return 0;
    } else {
      return progressNumber;
    }
  };

  const MyPercentageBar = (record: TestCaseMerge) => {
    const value = Number(((countExecuted(record) / countTCs(record)) * 100).toFixed());
    if (isNaN(value)) {
      return <PercentageBar value={0} />;
    } else {
      return <PercentageBar value={value} />;
    }
  };

  const calculateWidth = (cellValue: number, TCS: number) => {
    return `${(cellValue / TCS) * 100} %`;
  };

  const renderBgColorState = (itemType: string) => {
    let bgColor = '';
    switch (itemType) {
      case 'Pass':
        bgColor = TESTCASE_PASS_COLOR;
        break;
      case 'Fail':
        bgColor = TESTCASE_FAIL_COLOR;
        break;
      case 'Blocked':
        bgColor = TESTCASE_BLOCKED_COLOR;
        break;
      case 'Not Run':
        bgColor = TESTCASE_NOT_RUN_COLOR;
        break;
      case 'Error':
        bgColor = TESTCASE_ERROR_COLOR;
        break;
      case 'Inconclusive':
        bgColor = TESTCASE_INCONCLUSIVE_COLOR;
        break;
      case 'Not Applicable':
        bgColor = TESTCASE_NOT_APPLICABLE_COLOR;
        break;
      default:
        break;
    }
    return bgColor;
  };

  const StateTestcase = (record: TestCaseMerge) => {
    const data = record.testCaseResults;
    const tooltipData = data?.map((item, i) => {
      return (
        <div key={i} className="tooltip-card">
          <div>
            <svg className="bullet-point">
              <circle cx="5" cy="5" r="5" strokeWidth={3} fill={renderBgColorState(item.type)} />
            </svg>
            <span>{item.type}</span>
          </div>
          <div>
            <span>{item.value}</span>
          </div>
        </div>
      );
    });

    return (
      <table className="test-executed-table">
        <Tooltip
          title={tooltipData}
          overlayInnerStyle={{ background: 'white', color: 'black', width: '150px', fontSize: '14px', padding: '10px' }}
        >
          <tr className="row-test-executed-state">
            {data?.map((item, i) => {
              return item.value === 0 ? (
                ''
              ) : (
                <td
                  key={i}
                  width={calculateWidth(item.value, countTCs(record))}
                  style={{ background: renderBgColorState(item.type) }}
                >
                  {item.value !== 0 && item.value}
                </td>
              );
            })}
          </tr>
        </Tooltip>
      </table>
    );
  };

  const onSave = async (id: number, { testCaseNote }: any) => {
    try {
      const updateTestCaseRequest: UpdateTestCase = { testCaseNote };
      let response: TicketResponse;
      response = await updateTestCaseNote(id, updateTestCaseRequest);
      const newData = updateItem(response, testCase);
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });
      setTestCase(newData);
    } catch (error) {
      const err = error.data as ErrorResponse;
      if (err.error.validationErrors.length > 0) {
        openNotification(error.status, err.error.validationErrors.map((e) => e.message).join('\r\n'));
      } else {
        openNotification(error.status, err.error.message);
      }
    }
  };

  const columns = useMemo<EditableColumn<TestCaseMerge>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Feature',
        dataIndex: 'feature',
        key: 'feature',
        editable: false,
        width: '6%',
      },
      {
        title: 'ID',
        dataIndex: 'code',
        render: (text, record) => renderIDColumn(record),
        sorter: (a: any, b: any) => 1,
        width: '8%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: false,
        width: '1000px',
      },
      {
        title: 'Total TCs',
        dataIndex: 'tcs',
        key: 'tcs',
        sorter: (a: any, b: any) => countTCs(a) - countTCs(b),
        width: '5%',
        align: 'right',
        render: (text, record) => countTCs(record),
      },
      {
        title: 'Executed',
        dataIndex: 'executed',
        key: 'executed',
        sorter: (a: any, b: any) => countExecuted(a) - countExecuted(b),
        width: '5%',
        align: 'right',
        render: (text, record) => countExecuted(record),
      },
      {
        title: 'Remaining',
        dataIndex: 'remaining',
        key: 'remaining',
        sorter: (a: any, b: any) => countRemaining(a) - countRemaining(b),
        width: '5%',
        align: 'right',
        render: (value, record) => countRemaining(record),
      },
      {
        title: 'Progress',
        dataIndex: 'progress',
        key: 'progress',
        width: '10%',
        align: 'center',
        sorter: (a, b) => MyTestProgressNumber(a) - MyTestProgressNumber(b),
        sortDirections: ['ascend', 'descend'],
        render: (value, record, index) => MyPercentageBar(record),
      },
      {
        title: 'Test Result',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (value, record, index) => StateTestcase(record),
      },
      {
        title: 'Note',
        dataIndex: 'testCaseNote',
        editable: true,
        width: '600px',
        rules: [{ max: 200, message: 'The field Note must be a string with a maximum length of 200' }],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize]
  );

  return (
    <TestExecutionTableContainer>
      <EditableTable
        scroll={{ x: 'max-content' }}
        pageSize={pageSize}
        current={page}
        data={testCase}
        columns={columns}
        onChange={onChange}
        loading={loading}
        onSave={onSave}
        isEdit={isAuthorized && canEditReport(reportStatus, permissions)}
      />
    </TestExecutionTableContainer>
  );
};

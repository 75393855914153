import { AppstoreOutlined, RiseOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, DatePicker, Menu, MenuProps, Row, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { lazy, Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import {
  cloneConfig,
  fetchCategoryConfigure,
  fetchCriteriaConfigure,
  fetchTarget,
} from 'app/apis/CriteriaManageClient';
import { DATE_ID_FORMAT, MONTH_PICKER_FORMAT } from 'app/common/constants';
import { TeamContext } from 'app/contexts/TeamContext ';
import { TeamCategoryResponse, TeamCriteriaResponse } from 'app/types/CriteriaConfigure';
import { TargetDto, TargetResponse } from 'app/types/Ownership';

import { PageTitle } from 'app/layouts/AdminLayout.styled';

const TeamCategoryConfig = lazy(() =>
  import('./TeamCategoryConfig').then(({ TeamCategoryConfig }) => ({ default: TeamCategoryConfig }))
);
const TeamCriteriaConfig = lazy(() =>
  import('./TeamCriteriaConfig').then(({ TeamCriteriaConfig }) => ({ default: TeamCriteriaConfig }))
);

interface RouteParams {
  teamId: string;
}
interface RouteState {
  defaultDate: Date;
}

export const TeamOwnershipConfig = () => {
  const { teams } = useContext(TeamContext);

  const { state } = useLocation<RouteState>();
  const { teamId } = useParams<RouteParams>();

  const [current, setCurrent] = useState('sub1');
  const [mode, setMode] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(
    state?.defaultDate ? moment(state.defaultDate).startOf('month') : moment().startOf('month')
  );
  const [teamCategoriesData, setTeamCategoriesData] = useState<TeamCategoryResponse[]>([]);
  const [teamCriteriasData, setTeamCriteriasData] = useState<TeamCriteriaResponse[]>([]);
  const [teamTarget, setTeamTarget] = useState<TargetDto | undefined>({} as TargetDto);

  const getTeamContext = () => {
    var team = teams?.find((item) => item.id === Number(teamId));
    return team;
  };
  const getInitData = async (teamId: number, selectedDate: moment.Moment) => {
    if (selectedDate) {
      try {
        let teamCategories = await fetchCategoryConfigure(teamId, selectedDate.format(DATE_ID_FORMAT));
        let teamCriterias = await fetchCriteriaConfigure(teamId, selectedDate?.format(DATE_ID_FORMAT));
        let teamTarget = await fetchTarget(teamId, selectedDate?.format(DATE_ID_FORMAT));
        setTeamCategoriesData(teamCategories || []);
        setTeamCriteriasData(teamCriterias || []);
        setTeamTarget(teamTarget);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    getInitData(Number(teamId), selectedDate);
  }, [teamId]);

  const disabledDates: RangePickerProps['disabledDate'] = (selected) => {
    return selected && selected <= moment('01-01-2021', 'MM-DD-YYYY');
  };
  const handleClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key.toString());
    setMode(e.key.toString() === 'sub1' ? true : false);
  };
  const handleWeeklyOptionChange = (date: moment.Moment) => {
    if (date) {
      setSelectedDate(date.startOf('month'));
      getInitData(Number(teamId), date.startOf('month'));
    }
  };

  const cloneTheConfigs = async () => {
    try {
      await cloneConfig(Number(teamId), selectedDate.format(DATE_ID_FORMAT));
    } catch (error) {
      console.error(error);
    }
  };

  const latestTargetDate = useMemo(
    () => (teamTarget?.date ? moment(teamTarget?.date).format('MM-YYYY') : 'N/A'),
    [teamTarget]
  );
  const latestCategoryDate = useMemo(
    () => (teamCategoriesData?.[0]?.date ? moment(teamCategoriesData?.[0]?.date).format('MM-YYYY') : 'N/A'),
    [teamCategoriesData]
  );
  const latestCriteriaDate = useMemo(
    () => (teamCriteriasData?.[0]?.date ? moment(teamCriteriasData?.[0]?.date).format('MM-YYYY') : 'N/A'),
    [teamCriteriasData]
  );

  const isLatestConfig = (selected) =>
    selected === latestTargetDate && selected === latestCategoryDate && selected === latestCriteriaDate;

  const renderTitleTail = () => {
    const selected = selectedDate.format('MM-YYYY');

    const isLatest = isLatestConfig(selected);
    const isNotConfigured = latestTargetDate === 'N/A' || latestCategoryDate === 'N/A' || latestCriteriaDate === 'N/A';

    return (
      <Row>
        <Col flex={'auto'}>
          {isLatest && <i>Latest configurations</i>}
          {!isLatest && (
            <Typography.Text>
              Use config from:
              <br />
              {'- Target: '}
              <code>{selected === latestTargetDate ? 'Latest' : latestTargetDate}</code>
              {' - Category: '}
              <code>{selected === latestCategoryDate ? 'Latest' : latestCategoryDate}</code>
              {' - Criteria: '}
              <code>{selected === latestCriteriaDate ? 'Latest' : latestCriteriaDate}</code>
            </Typography.Text>
          )}
        </Col>
        {!isLatest && !isNotConfigured && (
          <Col flex={'10rem'} style={{ textAlign: 'right' }}>
            <Button onClick={() => void cloneTheConfigs()}>Clone to current</Button>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <>
      <Row>
        <Col span={5}>
          <PageTitle>
            <Title level={3}>CRITERIA MANAGEMENT</Title>
          </PageTitle>
        </Col>
        <DatePicker
          className="configure-datepicker"
          picker="month"
          format={MONTH_PICKER_FORMAT}
          disabledDate={disabledDates}
          value={selectedDate}
          size={'middle'}
          onChange={handleWeeklyOptionChange}
        />
      </Row>
      <Row>
        <Breadcrumb className="breadcumb-modifyWeight" separator=">">
          <Breadcrumb.Item>{getTeamContext()?.name}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      {renderTitleTail()}
      <Row className="weight">
        <Col xs={24} xl={4}>
          <Menu className="menu-teamweight" onClick={handleClick} mode="vertical" selectedKeys={[current]}>
            <Menu.Item key="sub1" icon={<AppstoreOutlined />} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
              Category
            </Menu.Item>
            <Menu.Item key="sub2" icon={<RiseOutlined />} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
              Criteria
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={24} xl={20}>
          <div className="weight-configuration">
            <Suspense fallback={'Loading...'}>
              {mode ? (
                <TeamCategoryConfig
                  teamId={Number(teamId)}
                  selectedDate={selectedDate}
                  setTeamCategoriesData={setTeamCategoriesData}
                  setTeamTarget={setTeamTarget}
                  teamCategoriesData={teamCategoriesData}
                  teamTarget={teamTarget}
                />
              ) : (
                <TeamCriteriaConfig
                  teamId={Number(teamId)}
                  selectedDate={selectedDate}
                  setTeamCriteriasData={setTeamCriteriasData}
                  teamCategoriesData={teamCategoriesData}
                  teamCriteriasData={teamCriteriasData}
                />
              )}
            </Suspense>
          </div>
        </Col>
      </Row>
    </>
  );
};

/* eslint-disable array-callback-return */
import { TeamHighLightRequest, TeamHighLightResponse } from 'app/types/entity';
import { SubPage } from 'app/types/TeamDetail';
import { fetchAsync } from 'app/utils/fetch';

export const getTeamHighLight = (iterationId: number, type: SubPage): Promise<TeamHighLightResponse> => {
  let url = `/api/teamhighlight?iterationId=${iterationId}&type=${type}`;
  return fetchAsync(url, {
    method: 'GET',
  });
};

export const updateTeamHighLight = (action: TeamHighLightRequest): Promise<TeamHighLightResponse> => {
  return fetchAsync(`/api/teamhighlight/update/${action.iterationId}/${action.highlightType}`, {
    method: 'PUT',
    body: action,
  });
};

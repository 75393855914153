import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Image, Space } from 'antd';

import { AvatarAndUser } from '../AvatarUser';
import { LeftMenu } from '../LeftMenu/LeftMenu';

import { UserRoutes, mapRoutesToButtonItems } from 'app/common/routes';

// Styles
import { StyledMenu, StyledPageHeader } from './PageHeader.styled';

// Contexts
import { AuthContext } from 'app/contexts/AuthContext';
import { UserContext } from 'app/contexts/UserContext';
import { TeamSearchBox } from '../TeamSearchBox/TeamSearchBox';
import { createGroupsOfTeams } from 'app/pages/Dashboard/Overview/ProgramOverview';
import { TeamContext } from 'app/contexts/TeamContext ';
import { TeamResponse, TeamType } from 'app/types/team';
import useIsMobile from 'app/utils/useIsMobile';

export function createUrlForTeam(team: TeamResponse): string {
  let typeString: string;
  switch (team.type) {
    case TeamType.MainGroup:
      typeString = 'main-group';
      break;
    case TeamType.Portfolio:
      typeString = 'portfolio';
      break;
    case TeamType.DeliveryStream:
      typeString = 'delivery-stream';
      break;
    case TeamType.ReleaseTrain:
      typeString = 'release-train';
      break;
    case TeamType.Team:
      typeString = 'teams';
      break;
  }

  return `/${typeString}/${team.id}`;
}

export const AppHeader = () => {
  const { teams: teamsData } = useContext(TeamContext);
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const dateId = params.get('dateId');
  const subMenuAlignment: 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' =
    'bottomLeft';

  const {
    user: { isAdmin },
  } = useContext(AuthContext);

  const { isApproved } = useContext(UserContext);
  const groupsOfTeams = createGroupsOfTeams(teamsData);
  const [isMobile] = useIsMobile();

  let history = useHistory();

  const onSearchItemSelected = (opt) => {
    let teamId = Number(opt.key);
    let team = teamsData.filter((i) => i.id === teamId)[0];
    if (team) {
      history.push(createUrlForTeam(team));
    }
  };

  return (
    <StyledPageHeader className="site-page-header">
      <Space align="center" size="middle" className="logo-holder">
        <LeftMenu
          isAdmin={isAdmin}
          isApproved={isApproved}
          isMobileSize={isMobile}
          subMenuAlignment={subMenuAlignment}
          dateId={dateId}
        />
        <Link className="adm-icon" to="/">
          <Image className="logo" width={80} src="/assets/img/logo-adm.png" preview={false} />
        </Link>
        {!isMobile && (
          <StyledMenu>
            <div className="header-btn">
              {mapRoutesToButtonItems(UserRoutes, '', subMenuAlignment, 'ph-menu', (route, parentRoute) => {
                const {
                  route: { path, relativeWithParent },
                } = route;

                if (!parentRoute) return pathname.includes(path);

                const {
                  route: { path: pathPrefix },
                } = parentRoute;

                return pathname.includes(`${relativeWithParent ? pathPrefix : ''}${path}`);
              })}
            </div>
          </StyledMenu>
        )}
      </Space>
      <div className="end-container">
        <TeamSearchBox
          onFilterChange={(value, options) => onSearchItemSelected(options)}
          onFilterClear={() => {}}
          groupsOfTeams={groupsOfTeams}
          placeholder={'Search'}
          clearOnSelect={true}
        />
        <AvatarAndUser />
      </div>
    </StyledPageHeader>
  );
};

import { useCallback, useContext } from 'react';

import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { IterationContext } from 'app/contexts/IterationContext';
import { UserContext } from 'app/contexts/UserContext';
import { Stage } from 'app/types/TeamDetailTypes';
import { roleNames } from 'app/common/constants';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';

type AuthzMetadata = {
  isAuthorized: boolean;
  hasClaim: (claim: string) => boolean;
};

export const useAuthz = (): AuthzMetadata => {
  const { stage, reportStatus } = useContext(IterationContext);
  const { team } = useContext(TeamDetailContext);

  const {
    user: { userRole, managedTeams, permissions },
  } = useContext(UserContext);

  const verifyAuthority = useCallback(() => {
    if (userRole === roleNames.admin) return true;

    // Check for PQA
    if (userRole === roleNames.pqa) {
      // Additional check in the future

      return stage === Stage.Planned || reportStatus === IterationReportStatusEnum.Submitted;
    }

    if (userRole === roleNames.scrumMaster || userRole === roleNames.user) {
      return managedTeams && managedTeams.length > 0 && managedTeams.some((t) => t === team?.id);
    }
    return false;
  }, [managedTeams, team, stage, userRole]);

  const hasClaim = useCallback(
    (claim: string) => {
      return permissions?.some((p) => p === claim) ?? false;
    },
    [permissions]
  );

  return {
    isAuthorized: verifyAuthority(),
    hasClaim,
  };
};

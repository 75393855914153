import { ReactNode } from 'react';
import { Col, Row } from 'antd';
import './CommonSection.scss';
import ToolTip from '../Tooltip/ToolTip';

type Props = {
  title: string;
  titleTooltip?: string;
  children: ReactNode;
  tailContent?: ReactNode;
  id?: string;
  subTitle?: ReactNode;
  titleLowerCase?: boolean;
  className?: string;
};

export const CommonSection = ({
  title,
  titleTooltip,
  children,
  tailContent,
  id,
  subTitle,
  titleLowerCase,
  className,
}: Props) => {
  return (
    <div className={`${className ?? ''} section-common-wrapper`} id={id}>
      <div className="section-header">
        <Row>
          <Col xs={24} sm={24} md={8}>
            <div className="section-title-container">
              <h5 className="section-title-common">{titleLowerCase ? title : title.toUpperCase()}</h5>
              <div className="section-subtitle-common">
                {subTitle && <span>{subTitle}</span>}
                {titleTooltip && <ToolTip title={<span>{titleTooltip}</span>} />}
              </div>
            </div>
          </Col>
          {tailContent && (
            <Col xs={24} sm={24} md={16}>
              {tailContent}
            </Col>
          )}
        </Row>
      </div>
      <div className="content-wrapper">{children}</div>
    </div>
  );
};

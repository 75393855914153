import { useContext, useEffect, useState } from 'react';
import { StyleHallOfFame } from './StyleHallOfFame.styled';
import { Helmet } from 'react-helmet';
import { TeamContext } from 'app/contexts/TeamContext ';
import { Button, Col, Empty, Form, Menu, Row, Steps } from 'antd';
import { ProfileOutlined, PlusOutlined, CaretDownOutlined } from '@ant-design/icons';
import { HallOfFameSection } from 'app/components/HallOfFame/HallOfFameSection';
import { CreateAwardPannel } from 'app/components/HallOfFame/CreateAward';
import { getAwardByTime, getTimeTree } from 'app/apis/award';
import { AwardModel, Child, TimeTree } from 'app/types/AllAwardType';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'app/contexts/UserContext';
import { roleNames } from 'app/common/constants';

const { Step } = Steps;

export const HallOfFame = () => {
  const { setBreadcrumbs } = useContext(TeamContext);
  const {
    user: { userRole, managedTeams, permissions },
  } = useContext(UserContext);

  const [openCreate, setOpenCreate] = useState(false);
  const [action, setAction] = useState('');
  const [permission, setPermission] = useState(userRole === roleNames.admin || userRole === roleNames.pqa);
  const [data, setData] = useState<AwardModel[][]>(null);
  const [current, setCurrent] = useState<number>(0);
  const [modal, setModal] = useState<AwardModel>(null);
  const [timeSelected, setTimeSelected] = useState<Child>({ year: 2024, key: 'YEAR', value: '2024' });
  const [timeTrees, setTimeTree] = useState<TimeTree[]>([]);
  const history = useHistory();

  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'Hall of Fame',
        parentCrumb: null,
        renderAsLink: true,
      },
    ]);
  }, [setBreadcrumbs]);

  const getData = (year, key, value) => {
    getTimeTree().then((result) => {
      if (result !== null) {
        const time = result?.at(0).childs.at(0);
        setTimeTree(result);

        let url;
        if (year == null || key == null || value == null) {
          url = '/api/awards/get-by-time?year=' + result?.at(0).year + '&key=' + time?.key + '&value=' + time?.value;
          setTimeSelected({ year: Number(result?.at(0).year), key: time?.key, value: time?.value });
        } else {
          url = '/api/awards/get-by-time?year=' + year + '&key=' + key + '&value=' + value;
          setTimeSelected({ year: year, key: key, value: value });
        }
        getAwardByTime(url).then((result) => {
          setData(result);
        });
      }
    });
  };

  useEffect(() => {
    getData(null, null, null);
  }, []);

  const openCreateModal = () => {
    setModal(null);
    setOpenCreate(true);
    setAction('create');
  };

  const openUpdateModal = (value) => {
    if (permission) {
      setModal(value);
      setOpenCreate(true);
      setAction('update');
    }
  };
  const handleClose = () => {
    setOpenCreate(false);
  };

  const createTimeTree = () => {
    return timeTrees?.map((e) => (
      <Step key={e.year} title={e.year} description={StepItem(e.childs, e.year)} icon={<CaretDownOutlined />} />
    ));
  };

  const handleOnChange = async () => {
    getData(timeSelected.year, timeSelected.key, timeSelected?.value);
  };
  const StepItem = (childs: Child[], year) => {
    return (
      <Menu>
        {childs?.map((e, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              filterAward(e, year);
              setCurrent(timeTrees.findIndex((time) => time.year === year));
              setTimeSelected({ ...e, year: year });
            }}
          >
            {e.title}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const filterAward = async (child: Child, year) => {
    getData(year, child?.key, child?.value);
  };

  return (
    <>
      <StyleHallOfFame>
        <Helmet>
          <title>ADM Tool | Hall of Fame</title>
        </Helmet>
        <Row className="row-header">
          <Col>
            <span className="title" style={{ color: '#0E50A4' }}>
              Hall of Fame
            </span>
          </Col>
          <Col>
            {permission && (
              <Form layout="inline">
                <Form.Item>
                  <Button
                    type="primary"
                    icon={<ProfileOutlined />}
                    className="reward-report-button"
                    onClick={() => {
                      history.push('/hall-of-fame/report');
                    }}
                  >
                    Reward Report
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" icon={<PlusOutlined />} className="create-button" onClick={openCreateModal}>
                    Create
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
        <Row>
          {data ? (
            <>
              <Col span={4}>
                <Steps current={current} direction="vertical">
                  {createTimeTree()}
                </Steps>
              </Col>
              <Col span={20}>
                {data?.map((e, index) => (
                  <HallOfFameSection key={index} data={e} onclick={openUpdateModal} />
                ))}
              </Col>
            </>
          ) : (
            <Col span={12} offset={5}>
              <Empty />
            </Col>
          )}
        </Row>
      </StyleHallOfFame>
      <CreateAwardPannel open={openCreate} onClose={handleClose} data={modal} onChange={handleOnChange} />
    </>
  );
};

export interface UncompletionReportData {
  id: number;
  teamName: string;
  releaseTrain: string;
  deliveryStream: string;
  portfolio: string;
  project: string;
  releaseTrainId: number;
  deliveryStreamId: number;
  portfolioId: number;
  userStory: string;
  scheduleState: string;
  isCommitted: boolean;
  point: number;
  note: string;
}

export enum UncompletionReportFilterType {
  ByPortfolio,
  ByDeliveryStream,
  ByReleaseTrain,
  ByScheduleState,
  ByCommit,
}

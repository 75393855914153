import { Col, Row } from 'antd';
import { fetchTeamDetail } from 'app/apis/statisticOrganization';
import { OrganizationFormPayload, ProductType } from 'app/types/Organizational';
import { InfomationTags } from '../../../components/TeamOverview/InfomationTags/InfomationTags';
import { QuarterReleasePlan } from 'app/components/QuarterReleasePlan/QuarterReleasePlan';
import { OverviewIterationCompleteness } from 'app/components/TeamOverview/Charts/IterationCompleteness/IterationCompletenessChart';
import { Compliment } from 'app/components/TeamOverview/5StarCompliment/Compliment';
import { BestPerformer } from 'app/components/TeamOverview/BestPerformer/BestPerformer';
import { Introduction } from 'app/components/Introduction/Introduction';
import { Stakeholders } from 'app/components/Stakeholders/Stakeholders';
import { DefectByStateTrend } from 'app/charts/Trends/DefectByStateTrend';
import { DefectRootCauseCategoryTrend } from 'app/charts/Trends/DefectRootCauseByCategoryTrend';
import { Card } from 'app/components/Card';
import { IterationContext } from 'app/contexts/IterationContext';
import { OverviewUtilizedCapcity } from 'app/components/TeamOverview/Charts/OverviewUtilizedCapcity/OverviewUtilizedCapcity';
import { OverviewIterationDefects } from 'app/components/TeamOverview/Charts/OverviewIterationDefects/OverviewIterationDefects';
import { OverviewIterationProductivity } from 'app/components/TeamOverview/Charts/OverviewIterationProductivity/OverviewIterationProductivity';
import { useContext, useEffect, useState } from 'react';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { PlanSection } from '../Plan/PlanSection/PlanSection';
import { OverviewVelocityChart } from 'app/components/TeamOverview/Charts/Velocity/OverviewVelocityChart';
import { OverviewThroughputChart } from 'app/components/TeamOverview/Charts/Throughput/OverviewThroughputChart';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';

export const TeamOverview = () => {
  const { teamIterations } = useContext(IterationContext);
  const { team } = useContext(TeamDetailContext);
  const { handleComponentClass, handleSectionClass } = useContext(DisplayComponentsContext);

  const [teamDataSubmit, setTeamDataSubmit] = useState<OrganizationFormPayload>();
  const [resetTags, setResetTags] = useState<boolean>(false);
  const [updateChartProductivity, setUpdateChartProductivity] = useState<boolean>(false);
  useEffect(() => {
    const getTeamDetail = async () => {
      let resp = await fetchTeamDetail(team?.id);
      setTeamDataSubmit(resp);
    };
    if (team) {
      getTeamDetail();
    }
  }, [team, resetTags]);
  const [iteration] = useState(teamIterations.filter((it) => it.isCurrentSprint)[0]);

  return (
    <>
      <Row className="mb-5">
        <Col span={24} className={handleComponentClass('overview-introduction')}>
          <Introduction />
          <Row>
            <InfomationTags
              teamDataSubmit={teamDataSubmit}
              type={ProductType.TechnicalSatck}
              title="Technical Stacks"
              resetTags={setResetTags}
              resetFlag={resetTags}
            />
          </Row>
          <Row>
            <InfomationTags
              teamDataSubmit={teamDataSubmit}
              type={ProductType.Tag}
              title="Keywords"
              resetTags={setResetTags}
              resetFlag={resetTags}
            />
          </Row>
          <Row>
            <InfomationTags
              teamDataSubmit={teamDataSubmit}
              type={ProductType.Workload}
              title="Workloads"
              resetTags={setResetTags}
              resetFlag={resetTags}
            />
          </Row>
        </Col>
      </Row>
      <br />
      <QuarterReleasePlan
        teamId={team.id}
        iterationName={iteration.name}
        className={handleComponentClass('overview-quarter-release-plan')}
      />
      <PlanSection title="Team Trends" withBottomSpace classProp={handleSectionClass('overview-team-trend')}>
        <Row gutter={[20, 20]}>
          <Col md={12} xs={24} className={handleComponentClass('overview-throughput')}>
            <Card title="Throughput">
              <OverviewThroughputChart />
            </Card>
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-velocity')}>
            <Card title="Velocity">
              <OverviewVelocityChart />
            </Card>
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-productivity')}>
            <Card title="Productivity">
              <OverviewIterationProductivity isUpdateData={updateChartProductivity} />
            </Card>
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-iteration-completeness')}>
            <Card title="Iteration Completeness">
              <OverviewIterationCompleteness />
            </Card>
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-utilized-capacity')}>
            <Card title="Utilized Capacity">
              <OverviewUtilizedCapcity />
            </Card>
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-iteration-defects')}>
            <Card title="Iteration Defects">
              <OverviewIterationDefects />
            </Card>
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-defect-root-cause')}>
            <Card
              title="Defect Root Cause by Category"
              titleTooltip={<span>All Defect by State to assess end of iteration technical debt.</span>}
            >
              <DefectRootCauseCategoryTrend teamId={team.id} iteration={null} dataSource={null} />
            </Card>
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-defect-by-state')}>
            <Card
              title="Defect by State"
              titleTooltip={<span>All Defect Root Cause by Category to assess end of iteration technical debt.</span>}
            >
              <DefectByStateTrend teamId={team.id} iteration={null} dataSource={null} />
            </Card>
          </Col>
        </Row>
      </PlanSection>

      <Row>
        <Col span={24}>
          <Stakeholders
            setUpdateChartProductivity={setUpdateChartProductivity}
            updateChartProductivity={updateChartProductivity}
          />
        </Col>
      </Row>

      <PlanSection
        title="Top Performance & Compliments"
        classProp={handleSectionClass('overview-top-performance-and-compliments')}
      >
        <Row gutter={[20, 20]} id="adjust-row">
          <Col md={12} xs={24} className={handleComponentClass('overview-top-performers')}>
            <BestPerformer />
          </Col>
          <Col md={12} xs={24} className={handleComponentClass('overview-5-star-compliments')}>
            <Compliment />
          </Col>
        </Row>
      </PlanSection>
    </>
  );
};

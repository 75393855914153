import { Col, Input, Row } from 'antd';
import { getTeamHighLight, updateTeamHighLight } from 'app/apis/teamIterationHighLight';
import { IterationContext } from 'app/contexts/IterationContext';
import { useAuthz } from 'app/hooks/useAuthz';
import { useFetch } from 'app/hooks/useFetch';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import { TeamHighLightRequest, TeamHighLightResponse } from 'app/types/entity';
import { SubPage } from 'app/types/TeamDetail';
import { Stage } from 'app/types/TeamDetailTypes';
import { useContext, useEffect, useMemo, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { IterationReportStatusEnum } from 'app/types/IterationReportStatusEnum';
import { updateItem } from 'app/utils/tableUtils';
import { TeamHighLightStyled } from './TeamHighLight.styled';

const { TextArea } = Input;
interface IProps {
  page: SubPage;
}

interface IFormatData {
  id: number;
  name: string;
  key: string;
  value: any;
}

export const TeamHighLight = ({ page }: IProps) => {
  const { stage: currentStage, selectedIteration, reportStatus } = useContext(IterationContext);
  const [allowEdit, setAllowEdit] = useState(false);
  const [data, setData] = useState<TeamHighLightRequest>();
  const [formatData, setFormatData] = useState<IFormatData[]>();
  const { isAuthorized } = useAuthz();

  const { data: originalData, error: fetchError } = useFetch<TeamHighLightResponse>(
    () => getTeamHighLight(selectedIteration.id, page),
    [selectedIteration, page]
  );

  const renderTitle = (value) => {
    return {
      props: {
        style: {
          background: '#fafafa',
        },
      },
      children: <p style={{ fontWeight: 'bold' }}>{value}</p>,
    };
  };

  const columns = useMemo<EditableColumn<IFormatData>[]>(
    () => [
      {
        title: 'name',
        dataIndex: 'name',
        className: 'column-width',
        editable: false,
        render: (value, record, index) => renderTitle(value),
      },
      {
        title: 'value',
        dataIndex: 'value',
        editable: true,
        inputType: 'custom',
        editingContent: (record) => (
          <TextArea
            autoSize
            value={record.value}
            onChange={(item) => {
              record.value = item;
            }}
          />
        ),
        render: (value, record, index) => <pre>{record.value} </pre>,
      },
    ],
    [allowEdit, currentStage]
  );
  useEffect(() => {
    setFormatData([
      { id: 1, name: 'Highlight', key: 'highlight', value: originalData?.highlight },
      { id: 2, name: 'Risks/Issues', key: 'riskIssue', value: originalData?.riskIssue },
      { id: 3, name: 'Blockers', key: 'blocker', value: originalData?.blocker },
      { id: 4, name: 'Dependencies', key: 'dependency', value: originalData?.dependency },
    ] as IFormatData[]);
    setData(originalData);
  }, [originalData]);
  // Check sprint editable
  useEffect(() => {
    setAllowEdit(false);
    const allowedRoleAndCondition =
      isAuthorized &&
      (currentStage === Stage.Other ||
        currentStage === undefined ||
        ((selectedIteration?.isCurrentSprint || selectedIteration?.isFutureSprint) && currentStage === Stage.Planning));
    if (!allowedRoleAndCondition) return;
    switch (page) {
      case SubPage.Plan:
        setAllowEdit(currentStage === Stage.Planning);
        return;
      case SubPage.Report:
        setAllowEdit(reportStatus !== IterationReportStatusEnum.Submitted);
        return;
    }
  }, [isAuthorized, currentStage, page, selectedIteration, reportStatus]);

  const onUpdate = async (id: number, record) => {
    try {
      let request: TeamHighLightRequest = {
        ...data,
        iterationId: selectedIteration.id,
        highlightType: page,
      };
      request[record.key] = record.value;
      setData(request);
      await updateTeamHighLight(request);

      let newdata = updateItem(record, formatData);
      setFormatData(newdata);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TeamHighLightStyled className="title-section" id="iteration-highlight-section">
      <Row>
        <Col span={24}>
          <PageTitle>
            <Title level={5}>ITERATION HIGHLIGHTS</Title>
          </PageTitle>
        </Col>
      </Row>
      <DeviderWrapper />
      <EditableTable
        scroll={{ x: 'max-content' }}
        columns={columns}
        className="team-highlight-table"
        data={formatData}
        isEdit={allowEdit}
        onSave={onUpdate}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </TeamHighLightStyled>
  );
};

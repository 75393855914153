export const reportOptions = [
  {
    title: 'Iteration Highlight',
    value: 'report-iteration-highlight',
    options: [{ label: 'Iteration Highlight', value: 'report-iteration-highlight' }],
  },
  {
    title: 'Iteration Goals',
    value: 'report-iteration-goal',
    options: [{ label: 'Iteration Goals', value: 'report-iteration-goal' }],
  },
  {
    title: 'Day-off Schedule',
    value: 'report-day-off-schedule',
    options: [{ label: 'Day-off Schedule', value: 'report-day-off-schedule' }],
  },
  {
    title: 'Iteration Summary',
    value: 'report-iteration-summary',
    options: [
      { label: 'Team Delivery', value: 'report-team-delivery' },
      { label: 'Schedule State', value: 'report-schedule-state' },
      { label: 'Utilized Capacity', value: 'report-utilized-capacity' },
      { label: 'Carried Over', value: 'report-carried-over' },
      { label: 'Carried Over Detail', value: 'report-carried-over-detail' },
      { label: 'Velocity', value: 'report-velocity' },
      { label: 'CFD', value: 'report-cfd' },
      { label: 'Iteration Burndown', value: 'report-iteration-burndown' },
      { label: 'Iteration Burnup', value: 'report-iteration-burnup' },
      { label: 'US Committed', value: 'report-us-committed' },
      { label: 'Defect Committed', value: 'report-defect-committed' },
    ],
  },
  {
    title: 'Quality Report',
    value: 'report-quality-report',
    options: [
      { label: 'Test Coverage', value: 'report-test-coverage' },
      { label: 'Test Execution State', value: 'report-test-execution-state' },
      { label: 'Quality Summary', value: 'report-quality-summary' },
      { label: 'Test Execution Detail', value: 'report-test-execution-detail' },
      { label: 'Defects by State', value: 'report-defects-by-state' },
      { label: 'Defects by Environment', value: 'report-defects-by-environment' },
      { label: 'Root Cause Category by Defect', value: 'report-root-cause-category-by-defect' },
      { label: 'Resolution by Defect', value: 'report-resolution-by-defect' },
      { label: 'Defect Root Cause Analysis', value: 'report-defect-root-cause-analysis' },
    ],
  },
  {
    title: 'Capacity Details',
    value: 'report-capacity-details',
    options: [{ label: 'Capacity detail', value: 'report-capacity-details' }],
  },
  {
    title: 'Iteration Backlog',
    value: 'report-iteration-backlog',
    options: [{ label: 'Backlog Detail', value: 'report-iteration-backlog' }],
  },
  {
    title: 'Demo Items',
    value: 'report-demo-item',
    options: [{ label: 'Demo Items', value: 'report-demo-item' }],
  },
  {
    title: '5 Star Compliments',
    value: 'report-compliments',
    options: [{ label: '5 Star Compliment', value: 'report-compliments' }],
  },
  {
    title: 'Retrospective',
    value: 'report-retrospective',
    options: [{ label: 'Retrospective', value: 'report-retrospective' }],
  },
  {
    title: 'The Best Performers',
    value: 'report-best-performance',
    options: [{ label: 'The Best Performers', value: 'report-best-performance' }],
  },
  {
    title: 'Additional Report',
    value: 'report-additional-report',
    options: [{ label: 'Additional Report', value: 'report-additional-report' }],
  },
];

export const overviewSection = [
  {
    title: 'Introduction',
    value: 'overview-introduction',
    options: [{ label: 'Introduction', value: 'overview-introduction' }],
  },
  {
    title: 'Quarter release plan',
    value: 'overview-quarter-release-plan',
    options: [{ label: 'Quarter release plan', value: 'overview-quarter-release-plan' }],
  },
  {
    title: 'Team trend',
    value: 'overview-team-trend',
    options: [
      { label: 'Throughput', value: 'overview-throughput' },
      { label: 'Velocity', value: 'overview-velocity' },
      { label: 'Productivity', value: 'overview-productivity' },
      { label: 'Iteration Completeness', value: 'overview-iteration-completeness' },
      { label: 'Utilized Capacity', value: 'overview-utilized-capacity' },
      { label: 'Iteration Defects', value: 'overview-iteration-defects' },
      { label: 'Defect Root Cause by Category', value: 'overview-defect-root-cause' },
      { label: 'Defect by State', value: 'overview-defect-by-state' },
    ],
  },
  {
    title: 'Stakeholders',
    value: 'overview-stakeholders',
    options: [{ label: 'stakeholders', value: 'overview-stakeholders' }],
  },
  {
    title: 'Top performance & compliments',
    value: 'overview-top-performance-and-compliments',
    options: [
      { label: 'Top Performers', value: 'overview-top-performers' },
      { label: '5-star Compliments', value: 'overview-5-star-compliments' },
    ],
  },
];

export const planSection = [
  {
    title: 'Iteration Highlight',
    value: 'plan-iteration-highlight',
    options: [{ label: 'Iteration Highlight', value: 'plan-iteration-highlight' }],
  },
  {
    title: 'Iteration Goals',
    value: 'plan-iteration-goal',
    options: [{ label: 'Iteration Goals', value: 'plan-iteration-goal' }],
  },
  {
    title: 'Day-off Schedule',
    value: 'plan-day-off-schedule',
    options: [{ label: 'Day-off Schedule', value: 'plan-day-off-schedule' }],
  },
  {
    title: 'Iteration Backlog',
    value: 'plan-iteration-backlog',
    options: [{ label: 'Iteration Backlog', value: 'plan-iteration-backlog' }],
  },
  {
    title: 'Demo plan',
    value: 'plan-demo-plan',
    options: [{ label: 'Demo plan', value: 'plan-demo-plan' }],
  },
  {
    title: 'Velocity and capacity',
    value: 'plan-velocity-and-capacity',
    options: [
      { label: 'Planned Velocity', value: 'plan-planned-velocity' },
      { label: 'Team detail', value: 'plan-team-detail' },
    ],
  },
  {
    title: 'Follow-up actions',
    value: 'plan-follow-up-actions',
    options: [{ label: 'Follow-up actions', value: 'plan-follow-up-actions' }],
  },
  {
    title: 'Quarter release plan',
    value: 'plan-quarter-release-plan',
    options: [{ label: 'Quarter release plan', value: 'plan-quarter-release-plan' }],
  },
];

export const monitorSection = [
  {
    title: 'Team trend',
    value: 'monitor-team-trend',
    options: [
      { label: 'Utilized Capacity', value: 'monitor-utilized-capacity' },
      { label: 'Iteration Burndown', value: 'monitor-iteration-burndown' },
      { label: 'Iteration Burnup', value: 'monitor-iteration-burnup' },
      { label: 'Schedule State', value: 'monitor-schedule-state' },
      { label: 'Throughput', value: 'monitor-throughput' },
      { label: 'Cumulative Flow', value: 'monitor-cumulative-flow' },
      { label: 'Team Delivery', value: 'monitor-team-delivery' },
      { label: 'Iteration Defects by State', value: 'monitor-iteration-defects-by-state' },
      { label: 'Iteration Defects by Environment', value: 'monitor-iteration-defects-by-environment' },
    ],
  },
  {
    title: 'Capacity detail',
    value: 'monitor-capacity-detail',
    options: [{ label: 'Capacity detail', value: 'monitor-capacity-detail' }],
  },
  {
    title: 'Day-off Schedule',
    value: 'monitor-day-off-schedule',
    options: [{ label: 'Day-off Schedule', value: 'monitor-day-off-schedule' }],
  },
  {
    title: 'Iteration Goals',
    value: 'monitor-iteration-goal',
    options: [{ label: 'Iteration Goals', value: 'monitor-iteration-goal' }],
  },
  {
    title: 'Iteration Backlog',
    value: 'monitor-iteration-backlog',
    options: [{ label: 'Backlog Detail', value: 'monitor-iteration-backlog' }],
  },
  {
    title: 'Demo Plan',
    value: 'monitor-demo-plan',
    options: [{ label: 'Demo Plan', value: 'monitor-demo-plan' }],
  },
  {
    title: '5 Star compliments',
    value: 'monitor-5-star-compliments',
    options: [{ label: '5 Star compliments', value: 'monitor-5-star-compliments' }],
  },
  {
    title: 'Retrospective',
    value: 'monitor-retrospective',
    options: [{ label: 'Retrospective', value: 'monitor-retrospective' }],
  },
];

/* eslint-disable react/display-name */
import { Row, Col, Radio, Select } from 'antd';
import { PageTitle } from '../../layouts/AdminLayout.styled';
import { Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { SelectOptions } from 'app/types/entity';
import { TeamContext } from 'app/contexts/TeamContext ';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { Helmet } from 'react-helmet';
import { TechStacksDefinition } from './TechStacksDefinition';
import { KeywordsDefinition } from './KeywordsDefinition';
import { WorkloadsDefinition } from './WorkloadsDefinition';

const { Title } = Typography;
type TableType = 'Technical Stacks' | 'Keywords' | 'Workloads';

const tableOptions: SelectOptions<TableType>[] = [
  {
    label: 'Technical Stacks',
    value: 'Technical Stacks',
  },
  {
    label: 'Keywords',
    value: 'Keywords',
  },
  {
    label: 'Workloads',
    value: 'Workloads',
  },
];

export const Definition = () => {
  const [activeMetrics, setActiveMetrics] = useState<TableType>('Technical Stacks');
  const { setBreadcrumbs } = useContext(TeamContext);
  useEffect(() => {
    setBreadcrumbs([createDefaultBreadCrumb('Definition')]);
  }, [setBreadcrumbs]);

  return (
    <div className="page-home page-container">
      <Helmet>
        <title>ADM Tool | Definition</title>
      </Helmet>
      <section className="section-container">
        <div className="heatmap-container">
          <Row>
            <PageTitle>
              <Title level={3}>Definition</Title>
            </PageTitle>
          </Row>
          <Row className="filter-container">
            <Col className="heatmap-measure-options">
              <Radio.Group
                options={tableOptions}
                onChange={({ target: { value } }) => setActiveMetrics(value)}
                value={activeMetrics}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
        </div>
        {activeMetrics === 'Technical Stacks' && <TechStacksDefinition />}
        {activeMetrics === 'Keywords' && <KeywordsDefinition />}
        {activeMetrics === 'Workloads' && <WorkloadsDefinition />}
      </section>
    </div>
  );
};

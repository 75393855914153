import { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import jwtdecode from 'jwt-decode';
import { ACCESS_TOKEN_KEY, roleNames } from '../common/constants';

import { ClaimTypes } from 'app/types/ClaimTypes';

// Token response from server
interface IJwtToken {
  [ClaimTypes.NameIdentifier]: string;
  [ClaimTypes.Name]: string;
  [ClaimTypes.Role]: string;
  [ClaimTypes.Permission]: string;
  sub: string;
  jti: string;
  iat: number;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
}

interface IUserIdentity {
  id: string | number;
  userName: string;
  role: RoleName;
  isAdmin: boolean;
  permissions: string[];
  token: string;
}

interface IAuthContext {
  user: IUserIdentity;
  logOut: () => void;
}

type RoleName = 'admin' | 'user' | 'scrum_master' | 'pqa';

interface IProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<IAuthContext>(null);

export const AuthContextProvider = ({ children }: IProps) => {
  const [ctxValue, setCtxValue] = useState<IAuthContext>(null);
  const [token, setToken] = useState(localStorage.getItem(ACCESS_TOKEN_KEY));
  const history = useHistory();

  const logOut = useCallback(() => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    setCtxValue(null);
    history.push('/login');
  }, [history]);

  useEffect(() => {
    setToken(localStorage.getItem(ACCESS_TOKEN_KEY));

    // Logout if token invalid
    if (!token) {
      logOut();
    }

    try {
      const decodedToken = jwtdecode<IJwtToken>(token);

      // Logout if token expired
      if (decodedToken.exp < new Date().getTime() / 1000) {
        logOut();
      }

      // Because old token don't have ClaimTypes.Permission key
      // So we force logout to make the user login again
      if (!decodedToken[ClaimTypes.Permission]) {
        logOut();
      }

      setCtxValue({
        user: {
          id: decodedToken[ClaimTypes.NameIdentifier],
          userName: decodedToken[ClaimTypes.Name],
          role: decodedToken[ClaimTypes.Role] as RoleName,
          isAdmin: decodedToken[ClaimTypes.Role] === roleNames.admin,
          permissions: decodedToken[ClaimTypes.Permission]?.split(' '),
          token: token,
        },
        logOut,
      });
    } catch (err) {
      console.error(err);
    }
  }, [history, token, logOut]);

  return <AuthContext.Provider value={ctxValue}>{ctxValue?.user && children}</AuthContext.Provider>;
};

import { Col, Row } from 'antd';
import { Card } from 'app/components/Card';
import { ChartWeeklyStatus } from 'app/types/WeeklyOverview';
import { Highlight } from './Highlight/Highlight';
import { WeeklyHealthChart } from './HealthTrendChart/WeeklyHealthChart';

interface IProps {
  dataSource: ChartWeeklyStatus[];
  highlight: string;
  currentWeek: moment.Moment;
}

export const WeeklyOverview = ({ dataSource, highlight, currentWeek }: IProps) => {
  return (
    <Row gutter={41}>
      {/* Chart Healthy Trend */}
      <Col span={12}>
        <Card title="Weekly Status Trend">
          <WeeklyHealthChart dataSource={dataSource} />
        </Card>
      </Col>
      {/* Highlight */}
      <Col span={12}>
        <Highlight content={highlight} currentWeek={currentWeek} />
      </Col>
    </Row>
  );
};

import { Modal, Row } from 'antd';
import styled from 'styled-components';
import { EditableTable } from 'app/components/EditableTable';

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    text-align: center;

    & .ant-modal-title {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .ant-modal-body {
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: none;
      content: '';
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & .ant-btn-primary {
      background: #0066ff;
    }
    & .ant-btn-primary.ant-btn-dangerous {
      background: #ff0000;
    }
  }
`;

export const StyledEditableTable = styled(EditableTable)`
  .ant-table-thead {
    .ant-table-cell {
      &:last-child {
        width: 11rem;
      }
    }
  }

  .ant-table-cell {
    word-break: break-word;
  }

  .approval-btn {
    color: #0ba800;
  }

  .reject-lbl {
    margin-right: 0.5rem;
  }
`;

export const StyledHeader = styled(Row)`
  margin-bottom: 1.5em;

  & [data-position='left'] {
    button {
      border-radius: 2px !important;
    }
  }

  & [data-position='right'] {
    text-align: end;
    border-radius: 0;
  }
`;

export const StyledRejectPopoverWrapper = styled.div`
  /* For Icon */
  display: inline-block;

  .ant-popover-content {
    width: 350px;

    .ant-popover-inner-content {
      white-space: pre-line;
      word-break: break-word;
      word-wrap: break-word;
    }
  }
`;

import { IImageFileForEditorResponse } from 'app/types/ImageFileForEditor';
import { IReleaseNoteItemRequest, IReleaseNoteItemResponse } from 'app/types/ReleaseNoteItemModel';
import { fetchAsync } from 'app/utils/fetch';

export const getAll = (): Promise<IReleaseNoteItemResponse[]> => {
  var url = 'api/releaseNoteItem/getall';
  return fetchAsync(url, { method: 'GET' });
};
export const createReleaseNoteItem = (payload: IReleaseNoteItemRequest): Promise<IReleaseNoteItemResponse> => {
  const data = new FormData();
  data.append('name', payload.name);
  data.append('type', payload.type.toString());
  data.append('status', '0');
  data.append('detail', payload.detail);
  data.append('releaseNoteId', payload.releaseNoteId?.toString());

  payload.uploadedImages?.forEach((x) => {
    data.append('uploadedImages', x);
  });
  const url = '/api/releaseNoteItem';
  return fetchAsync(url, { type: 'text', method: 'POST', body: data });
};

export const editReleaseNoteItem = (payload: IReleaseNoteItemRequest): Promise<IReleaseNoteItemResponse> => {
  const data = new FormData();
  data.append('id', payload.id.toString());
  data.append('name', payload.name);
  data.append('type', payload.type.toString());
  data.append('detail', payload.detail);
  data.append('releaseNoteId', payload.releaseNoteId?.toString());
  data.append('releaseNoteItemImages', JSON.stringify(payload.releaseNoteItemImages));
  payload.uploadedImages?.forEach((x) => {
    data.append('uploadedImages', x);
  });

  const url = '/api/releaseNoteItem';
  return fetchAsync(url, { type: 'text', method: 'PUT', body: data });
};

export const uploadImageEditor = (file: any): Promise<IImageFileForEditorResponse> => {
  const formData = new FormData();
  formData.append('uploadedImage', file);

  return fetchAsync('/api/releaseNoteItem/upload-image-editor', {
    type: 'text',
    method: 'POST',
    body: formData,
  });
};

export const deleteReleaseNoteItem = (id: number): Promise<IReleaseNoteItemResponse> => {
  const url = `/api/releaseNoteItem/${id}`;
  return fetchAsync(url, { method: 'DELETE' });
};

import PortfolioNode from './OrganizationNode/PortfolioNode';
import { OjectTeamDataResponse } from 'app/types/Organizational';

interface IProps {
  dataSource: OjectTeamDataResponse[];
  setCurrentTeam: (id: number) => void;
}

export const OrganizationChart = ({ dataSource, setCurrentTeam }: IProps) => {
  const colors = ['#ff6396', '#0093a5', '#9b04ab', '#008105', '#003da7', '#b8af33'];

  return (
    <>
      {dataSource?.map((portfolio, i) => {
        // loop color
        let indexColor = (i + 1) % colors.length;

        return (
          <PortfolioNode portfolio={portfolio} key={i} color={colors[indexColor]} setCurrentTeam={setCurrentTeam} />
        );
      })}
    </>
  );
};

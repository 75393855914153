import styled from 'styled-components';

export const StyledInfomationTags = styled.div`
  .header {
    svg {
      color: #969696ed;
    }

    .title {
      margin-left: 5px;
      font-weight: 600;
    }
  }

  .ant-select.ant-select-multiple.ant-select-show-search {
    padding: 5px;
  }

  .ant-tag {
    padding: 8px 14px;
    border-radius: 20px;
    font-size: 16px;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tags-container {
    margin-top: 5px;

    .ant-tag:hover {
      cursor: pointer;
    }
  }

  margin-bottom: 10px;
`;

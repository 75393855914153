import { ReactNode } from 'react';
import { Col, Row } from 'antd';

type Props = {
  title: string;
  children: ReactNode;
  tailContent?: ReactNode;
  id?: string;
};

export const TeamStatusSection = ({ title, children, tailContent, id }: Props) => {
  return (
    <div className="section-common-wrapper" id={id}>
      <div className="section-header">
        <Row>
          <Col lg={6} sm={24} xs={24}>
            <div className="section-title-container">
              <h5 className="section-title-common">{title.toUpperCase()}</h5>
            </div>
          </Col>
          <Col lg={18} sm={24} xs={24}>
            {tailContent}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">{children}</div>
    </div>
  );
};

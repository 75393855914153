import './SubSection.scss';

export interface IProps {
  header: string;
  backgroundColor?: string;
  children: React.ReactNode;
}
const SubSection = ({ header, backgroundColor, ...restProps }: IProps) => (
  <div className="sub-section-container" style={{ backgroundColor: backgroundColor }}>
    <div className="header">
      <span>{header}</span>
    </div>
    {restProps.children}
  </div>
);

export default SubSection;

import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { ReactNode, useState } from 'react';

interface IProps {
  title?: ReactNode;
}

function ToolTip({ title }: IProps) {
  return (
    <Tooltip
      title={title}
      color={'white'}
      overlayStyle={{ maxWidth: '500px' }}
      overlayInnerStyle={{ color: '#000', padding: '10px' }}
      placement="topRight"
      arrowPointAtCenter
    >
      <InfoCircleOutlined style={{ marginLeft: '10px', color: '#bdc3c7' }} />
    </Tooltip>
  );
}

export default ToolTip;

import { Button, Col, Divider, Row } from 'antd';
import { TeamWeeklyStatusForm } from '../TeamWeeklyStatusForm/TeamWeeklyStatusForm';
import { TeamWeeklyStatusIssueDetail } from '../TeamWeeklyStatusIssueDetail/TeamWeeklyStatusIssueDetail';
import { WeeklyModalWrapper, WeeklyRect } from './TeamWeeklyStatusModal.styled';
import { ADMBreadcrumb } from 'app/components/Breadcrumb/ADMBreadcrumb';
import {
  TeamDetail,
  TeamIssueOverviewMiniCard,
  WeeklyIssueTypeColour,
  WeeklyOverviewData,
} from 'app/types/WeeklyOverview';

import { TeamWeeklyStatusIssueDetailWrapper } from '../TeamWeeklyStatusIssueDetail/TeamWeeklyStatusIssueDetail.styled';
import { useContext, useEffect, useState } from 'react';
import {
  getTeamIssueDetailsForWeek,
  getTeamIssueOverviewItemsForYear,
  getWeeklyStatusOverview,
} from 'app/apis/reportClient';
import {
  CriticalStatusColor,
  DefaultStatusColor,
  GoodStatusColor,
  RiskStatusColor,
  roleNames,
} from 'app/common/constants';
import { AuthContext } from 'app/contexts/AuthContext';
import moment from 'moment';

interface IProps {
  toggleModal: boolean;
  setToggleModal: (toggle: boolean) => void;
  isCreateIssue: boolean;
  isEditIssue: boolean;
  setIsCreateIssue: (isCreate: boolean) => void;
  setIsEditIssue: (isEdit: boolean) => void;
  selectedWeek: moment.Moment;
  selectedTeamCardData: TeamDetail;
  setSelectedTeamCardData: (data: TeamDetail) => void;
  resetForm: boolean;
  setResetForm: (reset: boolean) => void;
  setWeeklyOverviewData: (data: WeeklyOverviewData) => void;
}

export const TeamWeeklyStatusModal = ({
  toggleModal,
  setToggleModal,
  isCreateIssue,
  isEditIssue,
  setIsCreateIssue,
  setIsEditIssue,
  selectedWeek,
  setResetForm,
  resetForm,
  selectedTeamCardData,
  setSelectedTeamCardData,
  setWeeklyOverviewData,
}: IProps) => {
  const { user } = useContext(AuthContext);
  // Save the current list of weeks of current team
  const [weeksInYear, setWeeksInYear] = useState<TeamIssueOverviewMiniCard[]>([]);
  // Save the current selected week in the dropdown + in the modal team detail
  const [currentWeek, setCurrentWeek] = useState<TeamIssueOverviewMiniCard>({
    color: WeeklyIssueTypeColour.Default,
    reportDate: selectedWeek.toDate(),
    teamWeeklyStatusId: null,
    weekNum: selectedWeek.week(),
  });
  const realTimeWeek = moment().clone().startOf('isoWeek').week();
  // Render list of week of each team
  const renderListOfWeeklyRect = () => {
    const listOfWeek = [...weeksInYear].sort((a, b) => a.weekNum - b.weekNum);
    return (
      <Row gutter={[1, 1]}>
        {listOfWeek?.map((week, i) => (
          <Col key={i}>
            <WeeklyRect
              color={
                week.weekNum > realTimeWeek
                  ? DefaultStatusColor
                  : week.color === WeeklyIssueTypeColour.Critical
                  ? CriticalStatusColor
                  : week.color === WeeklyIssueTypeColour.Risk
                  ? RiskStatusColor
                  : week.color === WeeklyIssueTypeColour.Good
                  ? GoodStatusColor
                  : DefaultStatusColor
              }
              onClick={() => {
                if (week.color !== WeeklyIssueTypeColour.Default) {
                  setCurrentWeek(week);
                  setIsCreateIssue(false);
                  setResetForm(!resetForm);
                }
              }}
              className={`${week?.weekNum === currentWeek.weekNum ? 'active' : ''} ${
                week.weekNum > realTimeWeek ? '' : 'select'
              }`}
            >
              {week.weekNum}
            </WeeklyRect>
          </Col>
        ))}
      </Row>
    );
  };
  useEffect(() => {
    if (toggleModal) {
      const getTeamDetail = async () => {
        try {
          let weekList = await getTeamIssueOverviewItemsForYear(selectedTeamCardData?.id);
          let newWeek = weekList.find((week) => week?.weekNum === currentWeek.weekNum) || currentWeek;
          setCurrentWeek(newWeek);
          setWeeksInYear(weekList);

          if (newWeek) {
            let dataTeam = await getTeamIssueDetailsForWeek(newWeek.teamWeeklyStatusId, selectedTeamCardData?.id);
            let storeData: TeamDetail = {
              ...selectedTeamCardData,
              issues: dataTeam,
            };
            setSelectedTeamCardData(storeData);
          }
        } catch (error) {
          console.error(error);
        }
      };
      getTeamDetail();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, resetForm]);
  return (
    <WeeklyModalWrapper
      title="DETAIL"
      visible={toggleModal}
      onClose={async () => {
        try {
          // fetch a new data changed before closing a modal team detail
          var data = await getWeeklyStatusOverview(selectedWeek.toDate());
          setWeeklyOverviewData(data);
          // after changing week in 52 weeks, we should return the initial week
          let alterCurrentWeek: TeamIssueOverviewMiniCard = {
            color: WeeklyIssueTypeColour.Default,
            reportDate: selectedWeek.toDate(),
            teamWeeklyStatusId: null,
            weekNum: selectedWeek.week(),
          };
          setCurrentWeek(alterCurrentWeek);
          // reset all state about edit/create issue
          setToggleModal(false);
          setIsEditIssue(false);
          setIsCreateIssue(false);
        } catch (error) {
          console.error(error);
        }
      }}
      width={800}
      footer={false}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className="drawer-body">
        <ADMBreadcrumb />
        <Row gutter={20}>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <div className="title-header">
                  <p>{selectedTeamCardData?.name}</p>
                </div>
              </Col>
            </Row>
          </Col>
          {/* Team issues */}
          {selectedTeamCardData?.issues
            ?.filter((issue) => issue.type.type !== WeeklyIssueTypeColour.Good)
            .map((issue, index) => (
              <TeamWeeklyStatusIssueDetail
                currentWeek={currentWeek}
                selectedTeamCardData={selectedTeamCardData}
                setIsCreateIssue={setIsCreateIssue}
                key={index}
                data={issue}
                resetForm={resetForm}
                isEditIssue={isEditIssue}
                setIsEditIssue={setIsEditIssue}
                setResetForm={setResetForm}
              />
            ))}
          {/* Adding new issue */}
          {isCreateIssue && (
            <TeamWeeklyStatusIssueDetailWrapper span={24}>
              <Row>
                <TeamWeeklyStatusForm
                  formForCreate
                  setIsEditIssue={setIsEditIssue}
                  setIsCreateIssue={setIsCreateIssue}
                  setResetForm={setResetForm}
                  resetForm={resetForm}
                  currentWeek={currentWeek}
                  selectedTeamCardData={selectedTeamCardData}
                />
              </Row>
              <Row>
                <Button
                  className="btn cancel"
                  type="default"
                  onClick={() => {
                    setIsEditIssue(false);
                    setIsCreateIssue(false);
                  }}
                >
                  Cancel
                </Button>
                <Button className="btn" type="primary" htmlType="submit" form="TeamWeeklyStatusForm">
                  Save
                </Button>
              </Row>
              <Divider className="divider" />
            </TeamWeeklyStatusIssueDetailWrapper>
          )}
          {!isCreateIssue && !isEditIssue && (user.role === roleNames.admin || user.role === roleNames.pqa) && (
            <Col span={24}>
              <Button
                className="add-new-btn"
                type="link"
                onClick={() => {
                  setIsEditIssue(true);
                  setIsCreateIssue(true);
                }}
              >
                Add new issue
              </Button>
            </Col>
          )}
          {/* List of weeks */}
          <div className="week-list-section">{renderListOfWeeklyRect()}</div>
        </Row>
      </div>
    </WeeklyModalWrapper>
  );
};

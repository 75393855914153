import { Button, Col, Form, Input, Row, Select, TablePaginationConfig } from 'antd';
import { DEFAULT_MEMBER_ID, USER_PAGE_NUMBER } from 'app/common/constants';
import { Helmet } from 'react-helmet';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { Option, ProjectItem, ProjectItemRequest } from 'app/types/ProjectManagement';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AddProjBtn, TeamTag } from './ProjectManagement.styled';
import { PageTitle } from 'app/layouts/AdminLayout.styled';
import Title from 'antd/lib/typography/Title';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { TeamContext } from 'app/contexts/TeamContext ';
import { SearchOutlined } from '@ant-design/icons';
import { isNull } from 'lodash';
import Search from 'antd/lib/input/Search';
import { deleteItem, updateItem } from 'app/utils/tableUtils';
import { StyledModal } from '../ManageUsers/ManagedUsers.styled';
import { useFetch } from 'app/hooks/useFetch';
import {
  createProjectGroups,
  deleteProjectGroups,
  fetchProjectGroups,
  fetchProjectLeads,
  updateProjectGroups,
} from 'app/apis/projectGroup';
import { openNotification } from 'app/utils/notificationUtils';
import { TeamType } from 'app/types/team';
type Props = {};

export const ProjectManagement = (props: Props) => {
  const searchEl = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const [projects, setProjects] = useState<ProjectItem[]>();
  const [teamOptions, setTeamOptions] = useState<Option[]>();
  const [projectLeadOptions, setProjectLeadOptions] = useState<Option[]>();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [reset, triggerReset] = useState<boolean>(false);
  const { setBreadcrumbs, teams: teamsData } = useContext(TeamContext);
  const { data: projectLeadData } = useFetch(() => fetchProjectLeads(), [reset]);
  const { data: projectGroupData } = useFetch(() => fetchProjectGroups(), [reset]);
  useEffect(() => {
    setBreadcrumbs([createDefaultBreadCrumb('Project Management')]);
  }, [setBreadcrumbs]);
  useEffect(() => {
    if (teamsData && projectGroupData && projectLeadData) {
      let teamOpts: Option[] = teamsData
        .filter((x) => x.type === TeamType.Team && x.isFPTInvolvded === true)
        .map((team) => ({
          label: team.name,
          value: team.id,
        }));
      setTeamOptions(teamOpts);
      setProjectLeadOptions(projectLeadData);
      setProjects(projectGroupData);
    }
  }, [reset, projectGroupData, projectLeadData, teamsData]);
  const renderTeams = (record: ProjectItem) => {
    return record?.teamIds?.map((team) => {
      let teamInfo = teamOptions?.find((x) => x.value === team);
      return <TeamTag key={teamInfo?.value}>{teamInfo?.label}</TeamTag>;
    });
  };
  const renderProjectLead = (record: ProjectItem) => {
    let projectLeadInfo = projectLeadOptions?.find((x) => x.value === record.userId);
    return <span>{projectLeadInfo?.label}</span>;
  };
  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ${dataIndex}`}
            onSearch={() => confirm()}
            onPressEnter={() => confirm()}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      if (dataIndex === 'name') return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const columns = useMemo<EditableColumn<ProjectItem>[]>(() => {
    let defineColumns: EditableColumn<ProjectItem>[] = [
      {
        title: 'Project Name',
        dataIndex: 'name',
        width: '15%',
        editable: true,
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Teams',
        dataIndex: 'teamIds',
        render: (value, record, index) => renderTeams(record),
        editable: true,
        width: '55%',
        inputType: 'select',
        options: teamOptions,
        inputProps: { searchOnSelect: true, mode: 'multiple' },
      },
      {
        title: 'Project Lead',
        dataIndex: 'userId',
        render: (value, record, index) => renderProjectLead(record),
        editable: true,
        width: '20%',
        inputType: 'select',
        options: projectLeadOptions,
        inputProps: { searchOnSelect: true },
      },
    ];

    return defineColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamOptions, projectLeadOptions, projects]);
  const onChange = (pagination: TablePaginationConfig) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const onAdd = async (values) => {
    try {
      let newRecord: ProjectItemRequest = {
        name: values.name,
        userId: values.projectLead,
        teamIds: values.teams,
        memberId: DEFAULT_MEMBER_ID,
      };
      let res = await createProjectGroups(newRecord);
      if (res) {
        triggerReset(!reset);
        setToggleModal(false);
      }
    } catch (error) {
      console.error(error);
      openNotification(error.status, error?.data?.error?.message);
    }
  };
  const onUpdate = async (id: number, record: ProjectItem) => {
    try {
      const res = await updateProjectGroups(record.id, record);
      if (res) {
        let newData = updateItem(record, projects);
        setProjects(newData);
      }
    } catch (error) {
      console.error(error);
      openNotification(error.status, error?.data?.error?.message);
    }
  };
  const onDelete = async (id: number, record: ProjectItem) => {
    try {
      await deleteProjectGroups(record.id);
      let newData = deleteItem(id, projects);
      setProjects(newData);
    } catch (error) {
      console.error(error);
      openNotification(error.status, error?.data?.error?.message);
    }
  };
  const requiredValidationMessage = 'Required this field';
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  return (
    <div>
      <Helmet>
        <title>ADM Tool | Project Management</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>PROJECT MANAGEMENT</Title>
      </PageTitle>
      <Row style={{ marginBottom: '1.5em' }}>
        <Col style={{ marginLeft: 'auto' }}>
          <AddProjBtn type="primary" onClick={() => setToggleModal(true)}>
            Add Project Group
          </AddProjBtn>
        </Col>
      </Row>
      <EditableTable
        data={projects}
        onSave={onUpdate}
        onDelete={onDelete}
        columns={columns}
        isEdit={true}
        isDelete={true}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          onChange: () => onChange,
          current: pageNumber,
        }}
      />
      <StyledModal
        destroyOnClose
        title="ADD PROJECT GROUP"
        visible={toggleModal}
        onCancel={() => setToggleModal(false)}
        width={700}
        centered
        footer={[
          <Button key="back" size="large" onClick={() => setToggleModal(false)}>
            Cancel
          </Button>,
          <Button key="submit" htmlType="submit" type="primary" size="large" form="demoPlan">
            Create
          </Button>,
        ]}
      >
        <Form
          {...layout}
          size={'middle'}
          onFinish={onAdd}
          name="demoPlan"
          initialValues={{ ticket: '', personInCharge: '', note: '' }}
          autoComplete="off"
          colon={false}
          labelAlign="left"
        >
          <Form.Item label="Project Name" name="name" rules={[{ required: true, message: requiredValidationMessage }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Teams" name="teams" rules={[{ required: true, message: requiredValidationMessage }]}>
            <Select
              options={teamOptions}
              showSearch={true}
              optionFilterProp="label"
              mode="multiple"
              filterOption={(value, option) => option.label.toString().toLowerCase().includes(value.toLowerCase())}
            />
          </Form.Item>
          <Form.Item
            label="Project Lead"
            name="projectLead"
            rules={[{ required: true, message: requiredValidationMessage }]}
          >
            <Select
              options={projectLeadOptions}
              showSearch={true}
              optionFilterProp="label"
              filterOption={(value, option) => option.label.toString().toLowerCase().includes(value.toLowerCase())}
            />
          </Form.Item>
        </Form>
      </StyledModal>
    </div>
  );
};

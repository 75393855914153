import { Col, Row, Space, Tooltip } from 'antd';
import { CriticalStatusColor, GoodStatusColor, RiskStatusColor } from 'app/common/constants';
import { TagData, WeeklyIssueTypeColour, WeeklyIssueTypeTitle } from 'app/types/WeeklyOverview';
import { WeeklyStatusComponent, TagComponent } from './WeeklyStatusCard.styled';
type Props = {
  type: WeeklyIssueTypeColour;
  name: string;
  tags: TagData[];
};
interface TagConfig {
  type: WeeklyIssueTypeColour;
  typeTitle: WeeklyIssueTypeTitle;
  remainingTags: number;
}
const WeeklyStatusTag = (tag: TagConfig) => {
  return (
    <TagComponent
      color={
        tag.type === WeeklyIssueTypeColour.Critical
          ? CriticalStatusColor
          : tag.type === WeeklyIssueTypeColour.Risk
          ? RiskStatusColor
          : GoodStatusColor
      }
    >
      {tag.remainingTags <= 1 && (
        <p className="text-cap">{WeeklyIssueTypeTitle[tag.typeTitle]?.charAt(0).toString().toLocaleUpperCase()}</p>
      )}
      {tag.remainingTags > 1 && <p className="text-cap more">+{tag.remainingTags}</p>}
    </TagComponent>
  );
};
const WeeklyStatusTagsList = (tags: TagData[]) => {
  let criticalOnly = tags.find((tag) => tag.type === WeeklyIssueTypeColour.Critical);
  let riskOnly = tags.find((tag) => tag.type === WeeklyIssueTypeColour.Risk);
  let mainTag: TagConfig = null;
  let subTag: TagConfig = null;
  if (criticalOnly?.numberOfIssues > 0 && riskOnly?.numberOfIssues > 0) {
    mainTag = {
      remainingTags: 0,
      type: criticalOnly?.type,
      typeTitle: criticalOnly?.typeTitle,
    };
    subTag = {
      typeTitle: riskOnly?.typeTitle,
      type: criticalOnly?.numberOfIssues > 1 ? WeeklyIssueTypeColour.Critical : WeeklyIssueTypeColour.Risk,
      remainingTags: riskOnly
        ? riskOnly?.numberOfIssues + criticalOnly?.numberOfIssues - 1
        : criticalOnly?.numberOfIssues - 1,
    };
  } else if (riskOnly?.numberOfIssues > 0) {
    mainTag = {
      remainingTags: 0,
      type: WeeklyIssueTypeColour.Risk,
      typeTitle: riskOnly?.typeTitle,
    };
    subTag = {
      typeTitle: riskOnly?.typeTitle,
      type: WeeklyIssueTypeColour.Risk,
      remainingTags: riskOnly?.numberOfIssues > 0 ? riskOnly?.numberOfIssues - 1 : riskOnly?.numberOfIssues,
    };
  } else if (criticalOnly?.numberOfIssues > 0) {
    mainTag = {
      remainingTags: 0,
      type: WeeklyIssueTypeColour.Critical,
      typeTitle: criticalOnly?.typeTitle,
    };
    subTag = {
      typeTitle: criticalOnly?.typeTitle,
      type: WeeklyIssueTypeColour.Critical,
      remainingTags: criticalOnly?.numberOfIssues > 0 ? criticalOnly?.numberOfIssues - 1 : criticalOnly?.numberOfIssues,
    };
  }
  if (mainTag) {
    return (
      <Row justify="end">
        <Space size={5}>
          <Tooltip
            title={WeeklyIssueTypeTitle[mainTag?.typeTitle]}
            color={'white'}
            overlayInnerStyle={{ color: '#000', padding: '10px' }}
            placement="top"
          >
            <Col>
              <WeeklyStatusTag {...mainTag} />
            </Col>
          </Tooltip>
          {subTag.remainingTags === 1 ? (
            <Tooltip
              title={WeeklyIssueTypeTitle[subTag?.typeTitle]}
              color={'white'}
              overlayInnerStyle={{ color: '#000', padding: '10px' }}
              placement="top"
            >
              <Col>
                <WeeklyStatusTag {...subTag} />
              </Col>
            </Tooltip>
          ) : subTag.remainingTags > 1 ? (
            <Col>
              <WeeklyStatusTag {...subTag} />
            </Col>
          ) : null}
        </Space>
      </Row>
    );
  } else return null;
};
const checkType = (tags: TagData[]) => {
  const crit = tags.find((x) => x.type === WeeklyIssueTypeColour.Critical);
  const risk = tags.find((x) => x.type === WeeklyIssueTypeColour.Risk);
  if (crit?.numberOfIssues > 0 || risk?.numberOfIssues > 0) return true;
  return false;
};
export const WeeklyStatusCard = ({ type, name, tags }: Props) => {
  return (
    <WeeklyStatusComponent
      color={
        type === WeeklyIssueTypeColour.Critical
          ? CriticalStatusColor
          : type === WeeklyIssueTypeColour.Risk
          ? RiskStatusColor
          : GoodStatusColor
      }
    >
      <div className="card">
        <Row justify="space-between" align="middle">
          <Col span={checkType(tags) ? 16 : 24}>
            <p className="name">{name}</p>
          </Col>

          <Col>
            <div className="tags-list">{WeeklyStatusTagsList(tags)}</div>
          </Col>
        </Row>
      </div>
      <div className="color-tail" />
    </WeeklyStatusComponent>
  );
};

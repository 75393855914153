// **References**
// HOC: https://itnext.io/react-higher-order-components-with-typescript-e41c64954825
// Currying: https://levelup.gitconnected.com/devmade-curry-ing-powder-recipe-in-functional-programming-c6e0e45cfbae
// Generic type in TS: https://stackoverflow.com/a/45576880/12401758

/* eslint-disable react/display-name */
import { ComponentType, PropsWithChildren, useContext } from 'react';

import { useAuthz } from 'app/hooks/useAuthz';
import { UserContext } from 'app/contexts/UserContext';
import { ForbiddenMessage } from 'app/pages/ForbiddenMessage';

interface IWithAuthzProps {
  unauthorizedRenderType?: 'notRender' | 'render403';
}

export const withAuthz = (claimPrefix: string) => {
  return <T,>(Component: ComponentType<PropsWithChildren<unknown> | IWithAuthzProps | T>, ...claims: string[]) => {
    return ({
      children,
      unauthorizedRenderType: renderType = 'notRender',
      ...props
    }: PropsWithChildren<unknown> & IWithAuthzProps & T): JSX.Element => {
      // Use to ignore routes which not have claim yet!
      // (if this happened, this will act like a proxy only and not modify anything)
      if (!claimPrefix || (claimPrefix === '' && (!claims || claims.every((c) => c === '')))) {
        return <Component {...props}>{children}</Component>;
      }

      const {
        user: { permissions },
      } = useContext(UserContext);

      const allowedClaims =
        claims && claims.filter((c) => c && c !== '').length > 0
          ? claims.map((c) => `${claimPrefix}::${c}`)
          : // If we don't have any child claims, we'll set the defined claimPrefix as a claim to check
            [claimPrefix];

      // If we have one claim match with the claim in allowedClaims
      // we'll know that user is having permission on this component.
      // So, we'll render this component
      if (allowedClaims.some((allowedClaim) => permissions.some((p) => p === allowedClaim))) {
        return <Component {...props}>{children}</Component>;
      }

      // Otherwise, we'll based on renderType to decide if we want
      // to render 403 Forbidden or not
      switch (renderType) {
        case 'render403':
          return <ForbiddenMessage />;
        case 'notRender':
        default:
          return <></>;
      }
    };
  };
};

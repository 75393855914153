import { Spin } from 'antd';
import { fetchPerformersByIterationId, fetchPerformersSelectOptionsByTeamId } from 'app/apis/topPerformersClient';
import { TopPerformerResponse, TopPerformerSelectOptionResponse } from 'app/types/entity';
import { createContext, useContext, useEffect, useState } from 'react';
import { IterationContext } from './IterationContext';
import { TeamDetailContext } from './TeamDetailContext';

interface ITopPerformersContext {
  topPerformers: TopPerformerResponse[];
  setTopPerformers: (performers: TopPerformerResponse[]) => void;
  topPerformerSelectOptions: TopPerformerSelectOptionResponse[];
  setTopPerformerSelectOptions: (data: TopPerformerSelectOptionResponse[]) => void;
}

export const TopPerformersContext = createContext<ITopPerformersContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const TopPerformersContextProvider = ({ children }: IProps) => {
  const [performers, setPerformers] = useState<TopPerformerResponse[]>();
  const [topPerformerSelectOptions, setTopPerformerSelectOptions] = useState<TopPerformerSelectOptionResponse[]>();
  const { team } = useContext(TeamDetailContext);
  const { selectedIteration } = useContext(IterationContext);

  useEffect(() => {
    if (selectedIteration && team) {
      fetchPerformersByIterationId(selectedIteration?.id).then((data) => {
        setPerformers(data);
      });
      fetchPerformersSelectOptionsByTeamId(team?.id).then((data) => {
        setTopPerformerSelectOptions(data);
      });
    }
  }, [selectedIteration, team]);

  if (!performers) {
    return <Spin />;
  }

  return (
    <TopPerformersContext.Provider
      value={{
        topPerformers: performers,
        setTopPerformers: setPerformers,
        topPerformerSelectOptions: topPerformerSelectOptions,
        setTopPerformerSelectOptions: setTopPerformerSelectOptions,
      }}
    >
      {children}
    </TopPerformersContext.Provider>
  );
};

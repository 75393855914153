import { PagedList } from 'app/types/PagedList';
import { toUrlSearchParams } from 'app/utils/stringUtils';
import {
  ChangePasswordModel,
  CreateUserRequest,
  MyUserRequest,
  UpdateUserRequest,
  UserResponse,
  UsersRequest,
} from '../types/user';
import { fetchAsync } from '../utils/fetch';

export const fetchMyUser = (): Promise<UserResponse> => fetchAsync('/api/myuser');

export const fetchUsers = (query: UsersRequest): Promise<PagedList<UserResponse>> =>
  fetchAsync(`/api/users?${toUrlSearchParams(query)}`);

export const fetchApprovalList = (query: UsersRequest): Promise<PagedList<UserResponse>> =>
  fetchAsync(`/api/users/approval?${toUrlSearchParams(query)}`);

export const fetchScrumMasterUsers = (): Promise<UserResponse[]> => fetchAsync('/api/users/scrumMaster');
export const fetchUserRoleUsers = (): Promise<UserResponse[]> => fetchAsync('/api/users/user');

export const fetchAllUsers = (): Promise<UserResponse[]> => fetchAsync('/api/users/all');

export const createUser = (user: CreateUserRequest): Promise<UserResponse> => {
  const formData = new FormData();
  const { ...res } = user;

  for (const key in res) {
    if (res[key] !== undefined || res[key] !== null) {
      formData.append(key, res[key]);
    }
  }
  const result = fetchAsync(`/api/users`, {
    method: 'POST',
    body: formData,
    type: 'blob',
  });
  return result;
};

export const approveUser = (id: number | string): Promise<void> =>
  fetchAsync(`/api/users/${id}/approve`, {
    method: 'POST',
  });

export const rejectUser = (id: number | string, note: string): Promise<void> =>
  fetchAsync(`/api/users/${id}/reject`, {
    method: 'POST',
    body: {
      note: note,
    },
  });

export const fetchUsersById = (id: number | string): Promise<UserResponse> => fetchAsync(`/api/users/${id}`);

// TODO: Check to make sure if we need this?
export const fetchUsersByName = (name: string): Promise<UserResponse[]> => fetchAsync(`/api/users/name?name=${name}`);

// TODO: Move this api to Team service!
export const fetchUsersByTeamName = (teamName: string): Promise<UserResponse[]> =>
  fetchAsync(`/api/users/teamName?teamName=${teamName}`);

export const updateUser = (id: number, user: UpdateUserRequest): Promise<UserResponse> =>
  fetchAsync(`/api/users/${id}`, {
    method: 'PUT',
    body: user,
  });

export const deleteUser = (id: number): Promise<void> =>
  fetchAsync(`/api/users/${id}`, {
    method: 'DELETE',
  });

export const resetPassword = (id: number | string): Promise<void> =>
  fetchAsync(`/api/users/${id}/reset-password`, {
    method: 'PATCH',
  });

export const updateImageUser = (payload: { file: File }) => {
  const formData = new FormData();
  if (payload) {
    formData.append('file', payload.file);
  }

  const result = fetchAsync(`/api/myuser`, {
    method: 'PUT',
    body: formData,
    type: 'blob',
  });
  return result;
};

export const updateMyUser = (id: number, payload: MyUserRequest) => {
  const data = { userId: id, ...payload };
  fetchAsync(`/api/myuser`, {
    method: 'PUT',
    body: data,
  });
};

export const resetPasswordMyUser = (model: ChangePasswordModel): Promise<void> =>
  fetchAsync(`/api/myuser/changepassword`, {
    method: 'PATCH',
    body: model,
  });

export const AccessRequest = (id: number, payload: MyUserRequest) => {
  const data = { userId: id, ...payload };
  fetchAsync(`/api/myuser/accessrequest`, {
    method: 'POST',
    body: data,
  });
};

export const SetDefaultUserInfo = (id: number, payload: MyUserRequest): Promise<UserResponse> => {
  const data = { userId: id, ...payload };
  const x = fetchAsync(`/api/myuser/defaultuserinfo`, {
    method: 'POST',
    body: data,
  });
  return x;
};

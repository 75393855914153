export interface IterationStatusAndHealthResponse {
  carriedOverUserStories: number;
  carriedOverDefects: number;

  userStoriesHealth: number;
  featuresHealth: number;
}

export class IterationStatusAndHealthModel implements IterationStatusAndHealthResponse {
  carriedOverUserStories: number = -1;
  carriedOverDefects: number = -1;

  userStoriesHealth: number = -1;
  featuresHealth: number = -1;
}

export interface HealthReportResponse {
  userStoriesHealth: number;
  featuresHealth: number;
}

export class HealthReporModel implements HealthReportResponse {
  userStoriesHealth: number = -1;
  featuresHealth: number = -1;
}

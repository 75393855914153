import type { ComponentProps } from 'react';

import { EditableTable } from 'app/components/EditableTable';
import { useState } from 'react';
import { Entity } from 'app/types/entity';
import classNames from 'classnames';

interface IProps extends ComponentProps<typeof EditableTable> {}

// https://github.com/ant-design/ant-design/blob/master/components/table/ExpandIcon.tsx#L14
interface DefaultExpandIconProps<RecordType extends Entity> {
  onExpand: (record: RecordType, e: React.MouseEvent<HTMLElement>) => void;
  record?: RecordType;
  expanded: boolean;
  expandable: boolean;
}

const ExpandIconButton = ({ onExpand, record, expanded, expandable }: DefaultExpandIconProps<Entity>) => {
  const prefixCls = 'ant-table';

  const iconPrefix = `${prefixCls}-row-expand-icon`;
  return (
    <button
      type="button"
      onClick={(e) => {
        onExpand(record ?? ({} as Entity), e!);
        e.stopPropagation();
      }}
      className={classNames(iconPrefix, {
        [`${iconPrefix}-spaced`]: !expandable,
        [`${iconPrefix}-expanded`]: expandable && expanded,
        [`${iconPrefix}-collapsed`]: expandable && !expanded,
      })}
      aria-label={expanded ? 'Collapse row' : 'Expand row'}
      aria-expanded={expanded}
    />
  );
};

// https://codesandbox.io/p/sandbox/expandable-row-antd4112-forked-o544t?file=%2Findex.js
export const ExpandableTable = (props: IProps) => {
  const { columns, data, expandable } = props;
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);
  const allAvailableKeys = data?.map((e) => e.id) ?? [];
  const processedColumns = [...columns];
  processedColumns.unshift({
    title: (
      <ExpandIconButton
        expandable
        expanded={allAvailableKeys.every((e) => expandedKeys.includes(e))}
        onExpand={() => (allAvailableKeys.every((e) => expandedKeys.includes(e)) ? collapseAll() : expandAll())}
      />
    ),
    key: 'expand',
    render: (_, record) => (
      <ExpandIconButton
        expandable
        expanded={expandedKeys.includes(record.id)}
        record={record}
        onExpand={(record) => toggleExpandedKeys(record.id)}
      />
    ),
    width: '35px',
  });

  const toggleExpandedKeys = (key) => {
    setExpandedKeys((prev) => {
      const outArr = [...prev];
      if (outArr.includes(key)) {
        return outArr.filter((e) => e !== key);
      } else {
        outArr.push(key);
        return outArr;
      }
    });
  };

  const expandAll = () => {
    setExpandedKeys(allAvailableKeys);
  };

  const collapseAll = () => {
    setExpandedKeys([]);
  };

  return (
    <EditableTable
      {...props}
      columns={processedColumns}
      expandable={{
        ...expandable,
        expandedRowKeys: expandedKeys,
        expandIconColumnIndex: -1,
      }}
    />
  );
};

import { Button, Col, InputNumber, Popconfirm, Row } from 'antd';
import { useAuthz } from 'app/hooks/useAuthz';
import { IterationContext } from 'app/contexts/IterationContext';
import { useContext, useEffect, useState } from 'react';
import { StyledTargetPlan } from './TargetPlan.styled';
import { Stage } from 'app/types/TeamDetailTypes';

interface IProps {
  value: number;
  onSave?: (value: number) => Promise<boolean>;
}

export const TargetPlan = ({ onSave, value: propValue }: IProps) => {
  const { stage: currentStage, selectedIteration } = useContext(IterationContext);

  const { isAuthorized } = useAuthz();
  const allowEdit =
    isAuthorized &&
    (currentStage === Stage.Other ||
      currentStage === undefined ||
      (selectedIteration?.isCurrentSprint && currentStage === Stage.Planning));
  const [isEditing, setIsEditing] = useState(false);
  const [targetValue, setTargetValue] = useState<number>(propValue);

  useEffect(() => {
    setTargetValue(propValue);
  }, [propValue]);

  return (
    <StyledTargetPlan>
      <Row gutter={8}>
        <Col className={`gutter-row ${isEditing ? 'p-top' : ''}`}>
          <span>Your target capacity:</span>
        </Col>
        <Col className="gutter-row">
          {isEditing ? (
            <InputNumber
              width={'2rem'}
              min={0}
              max={100}
              value={targetValue}
              onChange={(value) => setTargetValue(value)}
            />
          ) : (
            <span>
              <strong>{targetValue}%</strong>
            </span>
          )}
        </Col>
        {isEditing && (
          <Col className={`gutter-row ${isEditing ? 'p-top' : ''}`}>
            <span>%</span>
          </Col>
        )}
        <Col>
          {isEditing ? (
            <Row style={{ marginTop: '2px' }}>
              <Button
                type="link"
                size="small"
                onClick={async () => {
                  if (!(await onSave?.(targetValue))) {
                    console.error('Error when saving target plan!');
                    return;
                  }

                  setIsEditing(false);
                }}
              >
                Save
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  setTargetValue(propValue);
                  setIsEditing(false);
                }}
              >
                <Button type="link" size="small">
                  Cancel
                </Button>
              </Popconfirm>
            </Row>
          ) : (
            <Row style={{ padding: '0px 8px 8px 1px', color: '#1890ff', cursor: 'pointer' }}>
              {allowEdit && <span onClick={() => setIsEditing(true)}>Edit</span>}
            </Row>
          )}
        </Col>
      </Row>
    </StyledTargetPlan>
  );
};

import { Form, Input, Button, Select, Row, Col, notification } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { ResetPassword } from '../../components/ResetPassword/ResetPassword';
import { MyUserRequest, UserResponse } from '../../types/user';
import { fetchMyUser, updateMyUser } from '../../apis/userClient';
import { TeamContext } from 'app/contexts/TeamContext ';
import { SelectOptions } from 'app/types/entity';
import { teamRoles } from 'app/common/constants';
import { useFetch } from 'app/hooks/useFetch';
import { useEffect } from 'react';
import { validateEmail } from 'app/utils/utils';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

export const MyProfile = () => {
  const { teams, setBreadcrumbs } = useContext(TeamContext);
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm<UserResponse>();
  const teamOptions: SelectOptions<number>[] = teams.map((team) => ({
    value: team.id,
    label: team.name,
  }));
  const teamRolesOptions: SelectOptions<number>[] = useMemo(() => {
    return teamRoles.map((r) => ({
      value: r.code,
      label: r.name,
    }));
  }, []);

  const {
    user: { id },
  } = useContext(AuthContext);

  const { data: userData } = useFetch(fetchMyUser);
  useEffect(() => {
    if (userData) {
      // form.setFieldsValue({ ...userData });
    }
    setBreadcrumbs([createDefaultBreadCrumb('My Profile')]);
  }, [userData, setBreadcrumbs]);

  const onFinish = async (values: MyUserRequest) => {
    try {
      await updateMyUser(Number(id), values);
      notification.success({
        message: `update profile successfully`,
        duration: 2,
      });
      setDisabled(true);
    } catch (error) {
      notification.error({
        message: `There is an error`,
        duration: 2,
      });
    }
  };

  if (userData && teams) {
    const userRolesOps = [
      {
        value: userData.userRole,
        label: userData.userRole,
      },
    ];
    return (
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <Row justify="center">
              <Col span={13}>
                <Row justify="end">
                  <Col span={18}>
                    <Row justify="center">
                      <Col offset={7}>
                        <span className="" style={{ color: '#0E50A4', fontWeight: 'bold', fontSize: '25px' }}>
                          My Profile
                        </span>
                      </Col>
                    </Row>
                    <div className="form" style={{ paddingTop: '20px' }}>
                      <Form
                        name="basic"
                        form={form}
                        colon={false}
                        {...layout}
                        onFinish={onFinish}
                        initialValues={{ ...userData }}
                      >
                        <Form.Item
                          label="Fullname"
                          name="fullName"
                          labelAlign="left"
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          <Input size="middle" disabled={disabled} />
                        </Form.Item>
                        <Form.Item label="Username" name="userName" labelAlign="left" style={{ fontWeight: 'bold' }}>
                          <Input size="middle" disabled />
                        </Form.Item>
                        <Form.Item
                          label={<span style={{ fontWeight: 'bold' }}>User Role</span>}
                          name="userRole"
                          labelAlign="left"
                        >
                          <Select
                            size="middle"
                            disabled
                            options={userRolesOps}
                            style={{ width: '100%' }}
                            defaultValue={userData?.userRole}
                          />{' '}
                        </Form.Item>
                        <Form.Item
                          label={<span style={{ fontWeight: 'bold' }}>Team Role</span>}
                          name="teamRoles"
                          labelAlign="left"
                          // style={{ fontWeight: 'bold' }}
                        >
                          <Select
                            size="middle"
                            disabled={disabled}
                            style={{ width: '100%' }}
                            options={teamRolesOptions}
                            onChange={(value: number) => {
                              form.setFieldsValue({
                                teamRole: value,
                              });
                            }}
                            defaultValue={userData?.teamRole}
                          />{' '}
                        </Form.Item>
                        <Form.Item label="Email" name="email" labelAlign="left" style={{ fontWeight: 'bold' }}>
                          <Input size="middle" disabled />
                        </Form.Item>
                        <Form.Item
                          label="COX Email"
                          name="customerEmail"
                          labelAlign="left"
                          style={{ fontWeight: 'bold' }}
                        >
                          <Input size="middle" disabled={disabled} />
                        </Form.Item>
                        <Form.Item label=" ">
                          <Button
                            type="link"
                            htmlType="button"
                            onClick={() => setVisible(true)}
                            style={{ paddingLeft: '0' }}
                          >
                            Change password
                          </Button>
                        </Form.Item>
                        <Form.Item
                          name="defaultTeamId"
                          labelAlign="left"
                          label={<span style={{ fontWeight: 'bold' }}>Default Team</span>}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option: any) => {
                              return option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                            }}
                            size="middle"
                            disabled={disabled}
                            style={{ width: '100%' }}
                            options={teamOptions}
                            onChange={(value: number) => {
                              form.setFieldsValue({
                                defaultTeamId: value,
                              });
                            }}
                            defaultValue={userData?.defaultTeamId}
                          />{' '}
                        </Form.Item>
                        <Form.Item
                          name="reportTo"
                          labelAlign="left"
                          label={<span style={{ fontWeight: 'bold' }}>Report To</span>}
                          rules={[
                            {
                              validateTrigger: 'onChange',
                              validator: (rule, value: any, cb) => {
                                if (!value || value.length === 0) {
                                  cb();
                                  return;
                                }
                                const inValid = value.some((x) => !validateEmail(x));
                                inValid ? cb('The email is invalid') : cb();
                              },
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            size="middle"
                            disabled={disabled}
                            style={{ width: '100%' }}
                            onChange={(value: string[]) => {
                              // if (!value || value.length === 0) {
                              //   const inValid = value.some((x) => !validateEmail(x));
                              //   form.setFields({
                              //     reportTo: {
                              //       value: value,
                              //       erorrs: [new Error('forbid ha')],
                              //     },
                              //   });
                              // }
                              form.setFieldsValue({
                                reportTo: value,
                              });
                            }}
                            defaultValue={userData?.reportTo}
                          />{' '}
                        </Form.Item>
                        {!disabled && (
                          <Row justify="center">
                            <Col offset={7}>
                              <Button
                                onClick={() => {
                                  setDisabled(true);
                                  form.resetFields();
                                }}
                                type="default"
                                size={'middle'}
                                style={{
                                  marginRight: '20px',
                                  fontWeight: 'bold',
                                  backgroundColor: '#EEEEEE',
                                  width: '100px',
                                  height: '40px',
                                }}
                              >
                                CANCEL
                              </Button>
                              <Button
                                type="default"
                                size={'middle'}
                                htmlType="submit"
                                style={{
                                  color: 'white',
                                  backgroundColor: '#7BBB5E',
                                  fontWeight: 'bold',
                                  width: '100px',
                                  height: '40px',
                                }}
                              >
                                SAVE
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col offset={3} span={4}>
                {disabled && (
                  <Button
                    onClick={() => {
                      setDisabled(false);
                    }}
                    type="primary"
                    size={'middle'}
                    style={{
                      width: '100px',
                      height: '39px',
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <ResetPassword id={id} isOpenDialog={visible} setVisible={setVisible} />
        </section>
      </div>
    );
  } else {
    return <div>{JSON.stringify(error)}</div>;
  }
};
export default MyProfile;

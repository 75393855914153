import { TagsResponse } from 'app/types/TagsDefinition';
import {
  OjectTeamDataResponse,
  OrganizationFormPayload,
  Product,
  ProductPayload,
  ProductType,
} from '../types/Organizational';
import { fetchAsync } from './../utils/fetch';
export const createTeamDetail = (data: OrganizationFormPayload): Promise<any> =>
  fetchAsync('/api/statistics/team-detail', { method: 'POST', body: data });
export const fetchTeamDetail = (teamId: number): Promise<OrganizationFormPayload> =>
  fetchAsync(`/api/statistics/team-detail/${teamId}`);
export const fetchProduct = (): Promise<Product[]> => fetchAsync(`/api/statistics/product`);
export const fetchProductWithId = (): Promise<TagsResponse[]> => fetchAsync(`/api/statistics/product`);
export const fetchTagsDependencies = (tagName: string, type: number): Promise<string[]> =>
  fetchAsync(`/api/statistics/product/${type}`, {
    method: 'POST',
    body: {
      tagName: tagName,
    },
  });
export const createProduct = (data: ProductPayload[]): Promise<any> =>
  fetchAsync('/api/statistics/product', { method: 'POST', body: data });
export const getOjectTeamData = (): Promise<OjectTeamDataResponse[]> => fetchAsync('/api/statistics/Oject_teamData');
export const deleteProductTeamDetailSync = (productId: number): Promise<any> =>
  fetchAsync(`/api/statistics/product/${productId}`, { method: 'DELETE' });
export const updateProductTeamDetailSync = (productId: number, newProductName: string): Promise<any> =>
  fetchAsync(`/api/statistics/product/${productId}`, {
    method: 'PUT',
    body: {
      newName: newProductName,
    },
  });

import { Form } from 'antd';
import styled from 'styled-components';

export const ColorBadge = styled.span`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 80%;
  display: block;
  margin-top: 4%;
`;

export const TeamWeeklyStatusFormWrapper = styled(Form)`
  .fields-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  label {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff0000;
    font-size: 16px;
    content: '(*)';
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
`;

import { createContext, useContext, useEffect, useState } from 'react';
import { IIterationBackLogContext, Iteration } from 'app/types/Iteration';
import { IterationContext } from './IterationContext';
import { SubPage } from 'app/types/TeamDetail';
import { Stage } from 'app/types/TeamDetailTypes';
import { IterationState, TicketResponse, WorkItemType } from 'app/types/PlannedWorkItem';
import { PlannedVelocity } from 'app/types/planEntity';
import { isNull, orderBy } from 'lodash';
import { fetchIterationDetail, fetchWorkItemsForDemoPlan } from 'app/apis/teamDetailClient';
import { TeamContext } from './TeamContext ';

export const IterationBackLogContext = createContext<IIterationBackLogContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const IterationBackLogContextProvider = ({ children }: IProps) => {
  const [workItem, setWorkItem] = useState<TicketResponse[]>();
  const [demoItems, setDemoItems] = useState<TicketResponse[]>();
  const [velocities, setVelocities] = useState<PlannedVelocity[]>([]);
  const [isUpdatedPlan, setIsUpdatedPlan] = useState(false);
  const [previousIterationId, setPreviousIterationId] = useState<number>();
  const [dataSource, setDataSource] = useState<Iteration>();

  const { stage: currentStage, setStage, teamIterations, selectedIteration } = useContext(IterationContext);
  const { page } = useContext(TeamContext);

  useEffect(() => {
    if (selectedIteration === undefined) return;
    setPreviousIterationId(getPreviousIteration(selectedIteration?.id, teamIterations)?.id);
    fetchIteration(selectedIteration);
  }, [teamIterations, selectedIteration?.id, currentStage]);

  useEffect(() => {
    const newStage =
      page !== SubPage.Plan
        ? undefined
        : selectedIteration === undefined || isNull(selectedIteration?.iterationPlan)
        ? Stage.PrePlan
        : selectedIteration?.iterationPlan?.status;
    setStage(newStage);

    if (page === SubPage.Plan) {
      if (
        newStage === Stage.Planning ||
        selectedIteration?.tickets?.filter((x) => x.iterationState === IterationState.Plan)?.length > 0
      ) {
        setIsUpdatedPlan(true);
      }
    } else {
      setIsUpdatedPlan(false);
    }

    if (dataSource) {
      let workItems: TicketResponse[] = [];
      if (page === SubPage.Plan) {
        workItems = dataSource?.tickets.filter((x) => x.iterationState === IterationState.Plan);
      } else if (page === SubPage.Report) {
        workItems = dataSource?.tickets.filter((x) => x.iterationState === IterationState.Report);
      } else {
        workItems = dataSource?.tickets.filter((x) => x.iterationState === IterationState.Monitor);
      }
      setWorkItem(workItems);
    }
  }, [page, dataSource]);

  const fetchIteration = async (selectedIteration: Iteration) => {
    if (!selectedIteration) return;

    try {
      const result = await fetchIterationDetail(selectedIteration?.id);
      const demoItemsResult = await fetchWorkItemsForDemoPlan(selectedIteration?.id);
      setVelocities(result?.velocities);
      setDataSource(result);
      setDemoItems(demoItemsResult);
    } catch (error) {
      console.error(error);
    }
  };

  const getPreviousIteration = (currentIterationId: number, source: Iteration[]): Iteration => {
    const currentIteration = source?.find((x) => x.id === currentIterationId);

    if (currentIteration === undefined) return null;

    const previousIteration = orderBy(
      source.filter((x) => x.dateTo <= currentIteration.dateFrom),
      (x) => x.dateTo,
      'desc'
    )?.[0];

    return previousIteration;
  };

  return (
    <IterationBackLogContext.Provider
      value={{
        previousIterationId,
        workItem,
        setWorkItem,
        velocities,
        setVelocities,
        isUpdatedPlan,
        demoItems,
        setDemoItems,
      }}
    >
      {children}
    </IterationBackLogContext.Provider>
  );
};

import { Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import { ModalSectionWrapper } from './OganizationModal.styled';

type Props = {
  title: string;
  align?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  titleUpperCase?: boolean;
  children: ReactNode;
};

export const ModalSection = ({ title, children, align = 'center', titleUpperCase = true }: Props) => {
  return (
    <ModalSectionWrapper>
      <Col span={24} className="title">
        <Row justify={align}>{titleUpperCase ? title.toUpperCase() : title}</Row>
      </Col>
      <Col span={24}>{children}</Col>
    </ModalSectionWrapper>
  );
};

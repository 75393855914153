import { ReactNode } from 'react';
import { Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import { StyledPlanSection } from './PlanSection.styled';
import ToolTip from 'app/components/Tooltip/ToolTip';

interface IPlanSectionProps {
  title: string;
  withBottomSpace?: boolean;
  withTopSpace?: boolean;
  children?: ReactNode;
  titleTootip?: ReactNode;
  id?: string;
  classProp?: string;
}

export const PlanSection: React.FC<IPlanSectionProps> = ({
  title,
  withBottomSpace,
  withTopSpace,
  children,
  titleTootip,
  id,
  classProp,
}: IPlanSectionProps) => {
  let classNames = '';
  if (withBottomSpace) {
    classNames += 'space-bottom ';
  }
  if (withTopSpace) {
    classNames += 'space-top ';
  }
  return (
    <StyledPlanSection id={id} className={`${classNames} ${classProp}`}>
      <Row>
        <Col span={24}>
          <PageTitle>
            <Title level={5}>
              <span>{title.toUpperCase()}</span>
              {titleTootip && <ToolTip title={titleTootip} />}
            </Title>
          </PageTitle>
          <DeviderWrapper />
        </Col>
      </Row>
      <div className="content-wrapper">{children}</div>
    </StyledPlanSection>
  );
};

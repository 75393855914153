import { Col, Radio, Row, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import React, { Suspense, useContext, useEffect, useState } from 'react';

import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { OwnershipConfigContextProvider } from 'app/contexts/OwnershipConfigContext';
import { TeamContext } from 'app/contexts/TeamContext ';
import { SelectOptions } from 'app/types/entity';
import { PageTitle } from 'app/layouts/AdminLayout.styled';

import './OwnershipDefinition.scss';

const CategoryDefinition = React.lazy(() =>
  import('./CategoryDefinition').then(({ CategoryDefinition }) => ({ default: CategoryDefinition }))
);
const CriteriaDefinition = React.lazy(() =>
  import('./CriteriaDefinition').then(({ CriteriaDefinition }) => ({ default: CriteriaDefinition }))
);
const CriteriaDetailDefinition = React.lazy(() =>
  import('./CriteriaDetailDefinition').then(({ CriteriaDetailDefinition }) => ({ default: CriteriaDetailDefinition }))
);

const { Title } = Typography;
type TableType = 'Category' | 'Criteria' | 'Criteria Detail';

const tableOptions: SelectOptions<TableType>[] = [
  {
    label: 'Category',
    value: 'Category',
  },
  {
    label: 'Criteria',
    value: 'Criteria',
  },
  {
    label: 'Criteria Detail',
    value: 'Criteria Detail',
  },
];

const PAGE_TITLE = 'Maturity Definition';

export const OwnershipDefinition = () => {
  const [activeMetrics, setActiveMetrics] = useState<TableType>('Category');
  const { setBreadcrumbs } = useContext(TeamContext);
  useEffect(() => {
    setBreadcrumbs([createDefaultBreadCrumb(PAGE_TITLE)]);
  }, [setBreadcrumbs]);

  return (
    <div className="page-home page-container">
      <Helmet>
        <title>ADM Tool | {PAGE_TITLE}</title>
      </Helmet>
      <section className="section-container">
        <OwnershipConfigContextProvider>
          <div className="heatmap-container">
            <Row>
              <PageTitle>
                <Title level={3}>{PAGE_TITLE}</Title>
              </PageTitle>
            </Row>
            <Row className="filter-container">
              <Col className="heatmap-measure-options">
                <Radio.Group
                  options={tableOptions}
                  onChange={({ target: { value } }) => setActiveMetrics(value)}
                  value={activeMetrics}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
            </Row>
          </div>
          <Suspense fallback="Loading...">
            {activeMetrics === 'Category' && <CategoryDefinition />}
            {activeMetrics === 'Criteria' && <CriteriaDefinition />}
            {activeMetrics === 'Criteria Detail' && <CriteriaDetailDefinition />}
          </Suspense>
        </OwnershipConfigContextProvider>
      </section>
    </div>
  );
};

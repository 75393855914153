import { Button, Row } from 'antd';
import { AuthContext } from 'app/contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';

import { HighlightWrapper } from './OwnershipHighlight.styled';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { roleNames } from 'app/common/constants';
import { Card } from 'app/components/Card';
import { OwnershipHeatmapHighlight } from 'app/types/OwnershipHeatmap';
import { updateHighlightOwnershipByType } from 'app/apis/ownershipHeatmap';
import { HighlightType } from 'app/types/Ownership';

type IProps = {
  content: OwnershipHeatmapHighlight;
  type: HighlightType;
};

export const OwnershipHighlight = ({ content, type }: IProps) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [isSubmit, setIsSubmitting] = useState<boolean>(false);
  const [cacheData, setCacheData] = useState(EditorState.createEmpty());
  const [highlightData, setHighlightData] = useState(EditorState.createEmpty());
  const { user } = useContext(AuthContext);

  const convertHtmlToState = (htmlStr: string) => {
    const contentBlock = htmlToDraft(htmlStr);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  useEffect(() => {
    let html = '';
    if (content) html = content.highlightNote;
    const editorState = convertHtmlToState(html);
    setHighlightData(editorState);
    setCacheData(editorState);
  }, [content]);

  const htmlStr = draftToHtml(convertToRaw(highlightData?.getCurrentContent()));

  const onSave = async () => {
    setIsSubmitting(true);

    try {
      let data: OwnershipHeatmapHighlight = {
        highlightNote: htmlStr,
        highlightDate: content.highlightDate,
        id: content.id,
      };
      let respData = await updateHighlightOwnershipByType(data, type);
      const editorState = convertHtmlToState(respData.highlightNote);
      setHighlightData(editorState);
      setIsSubmitting(false);
      setEditable(false);
      setCacheData(editorState);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };
  const onCancelEdit = () => {
    setEditable(false);
    setHighlightData(cacheData);
  };
  return (
    <HighlightWrapper>
      <Card title="Monthly Highlight" className="card-container">
        <Row className="action-button">
          {editable ? (
            <>
              <Button type="link" htmlType="submit" onClick={onSave} loading={isSubmit}>
                Save
              </Button>
              <Button type="ghost" onClick={onCancelEdit}>
                Cancel
              </Button>
            </>
          ) : (
            (user.role === roleNames.admin || user.role === roleNames.pqa) && (
              <Button type="link" onClick={() => setEditable(true)}>
                Edit
              </Button>
            )
          )}
        </Row>
        {editable ? (
          <Editor
            wrapperClassName="editor-wrapper"
            editorClassName="editor-box"
            editorState={highlightData}
            onEditorStateChange={setHighlightData}
            toolbar={{
              image: false,
            }}
          />
        ) : (
          <Editor
            readOnly={true}
            toolbarHidden={true}
            editorClassName="editor-box-read-only"
            placeholder="Edit highlight here"
            editorState={cacheData}
          />
        )}
      </Card>
    </HighlightWrapper>
  );
};

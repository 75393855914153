import { Button, Form, Input, InputNumber } from 'antd';
import { IQualitySummaryRequest, IQualitySummaryResponse } from 'app/types/entity';
import { useEffect } from 'react';

interface IProps {
  onSubmit: (form: IQualitySummaryRequest) => void;
  onCloseModal: () => void;
  qualitySummary: IQualitySummaryResponse;
}

export function QualitySummaryForm({ onSubmit, onCloseModal, qualitySummary }: IProps) {
  const [form] = Form.useForm();

  const handleSubmitForm = () => {
    const formValues = form.getFieldsValue(['unitTestCoverage', 'numberLineOfCode']);
    const payload: IQualitySummaryRequest = {
      unitTestCoverage: Number(formValues.unitTestCoverage),
      numberLineOfCode: Number(formValues.numberLineOfCode),
    };
    onSubmit(payload);
    form.resetFields();
    onCloseModal();
  };

  useEffect(() => {
    if (qualitySummary) {
      const { ...rest } = qualitySummary;
      form.setFieldsValue({
        ...rest,
      });
    }
  }, [qualitySummary, form]);

  return (
    <Form
      form={form}
      onFinish={handleSubmitForm}
      colon={false}
      labelAlign="left"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        unitTestCoverage: qualitySummary?.unitTestCoverage,
        numberLineOfCode: qualitySummary?.numberLineOfCode,
      }}
    >
      <Form.Item
        name="unitTestCoverage"
        label="Unit test coverage (%)"
        rules={[
          () => ({
            validator(_, value) {
              let unitTestCoverage = Number(value);
              if (unitTestCoverage < 0 || unitTestCoverage > 100) {
                return Promise.reject('Value must be between 1 and 100');
              } else if (isNaN(unitTestCoverage)) {
                return Promise.reject('Invalid Value');
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input type="number" max={100} min={1} />
      </Form.Item>
      <Form.Item
        name="numberLineOfCode"
        label="Number Line Of Code"
        rules={[
          () => ({
            validator(_, value) {
              let numberLineOfCode = Number(value);
              if (isNaN(numberLineOfCode)) {
                return Promise.reject('Invalid Value');
              } else if (numberLineOfCode > 99999 || numberLineOfCode < -99999) {
                return Promise.reject('Value Too Large');
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <div className="form-footer">
          <Button className="btn btn-cancel" type="default" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button className="btn" type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, MinusOutlined } from '@ant-design/icons';
import { Select, TablePaginationConfig, Typography, Space } from 'antd';
import Search from 'antd/lib/input/Search';
import { chain, groupBy, isNull } from 'lodash';
import { useMemo, useRef, useState, ReactNode } from 'react';

import { OwnershipLevelTitle, OwnershipTableCellColor, USER_PAGE_NUMBER } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import {
  CriteriaDetailResponse,
  getOwnershipLevelTitle,
  OwnershipCriteriaDetail,
  OwnershipDetailEvaluationChange,
  OwnershipLevel,
  OwnershipStatusDetailItem,
} from 'app/types/Ownership';

import './OwnershipStatusTable.scss';
import { Entity } from 'app/types/entity';
import { ExpandableTable } from 'app/pages/ExpandableTable';
import { HtmlText } from 'app/components/HtmlText';

const { Title, Text } = Typography;

interface IProps {
  dataSource: OwnershipStatusDetailItem[];
  onRecordUpdated: (OwnershipStatusDetailItem) => Promise<any>;
  isAllowedEdit: boolean;
}

type OptionItem = {
  label: string;
  value: number;
};

export const OwnershipStatusTable = ({ dataSource, onRecordUpdated, isAllowedEdit }: IProps) => {
  const [pageSize, setPageSize] = useState(50); //useState(USER_PAGE_NUMBER);
  const [pageNumber, setPageNumber] = useState(1);
  const searchEl = useRef(null);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const renderStatusLevelCell = (val: string, rec: OwnershipStatusDetailItem, cellLevel: OwnershipLevel) => {
    let cellStyle: React.CSSProperties = {};
    if (!val || val.length === 0) {
      cellStyle.backgroundColor = OwnershipTableCellColor.default;
    } else {
      switch (cellLevel) {
        case OwnershipLevel.None:
        case OwnershipLevel.Level_0:
        case OwnershipLevel.NotYet:
          cellStyle.backgroundColor = OwnershipTableCellColor.default;
          break;
        case OwnershipLevel.Level_1:
          // cellStyle.backgroundColor = getColorForLevelCell(rec.level_1_Detail, rec.selfEvaluation, cellLevel);
          break;
        case OwnershipLevel.Level_2:
          // cellStyle.backgroundColor = getColorForLevelCell(rec.level_2_Detail, rec.selfEvaluation, cellLevel);
          break;
        case OwnershipLevel.Level_3:
          // cellStyle.backgroundColor = getColorForLevelCell(rec.level_3_Detail, rec.selfEvaluation, cellLevel);
          break;
        case OwnershipLevel.Level_4:
          // cellStyle.backgroundColor = getColorForLevelCell(rec.level_4_Detail, rec.selfEvaluation, cellLevel);
          break;
      }
    }
    return (
      <div className="status-table-level-cell-inner" style={cellStyle}>
        <span>{val}</span>
      </div>
    );
  };

  const getColorForLevelCell = (
    cellText: string,
    selfEvaluation: OwnershipLevel,
    cellLevel: OwnershipLevel
  ): string => {
    if (!cellText || cellText.length === 0) return OwnershipTableCellColor.default;
    switch (selfEvaluation) {
      case OwnershipLevel.NotYet:
        return OwnershipTableCellColor.levelNotReached;
      case OwnershipLevel.None:
        return OwnershipTableCellColor.white;
      default:
        if (selfEvaluation >= cellLevel) return OwnershipTableCellColor.levelPassed;
        else return OwnershipTableCellColor.levelNotReached;
    }
  };

  const renderSelfEvaluationCell = (rec: OwnershipStatusDetailItem) => {
    let cellData = getSelfEvaluationChangeData(rec.evaluationChange);
    let cellText = '<i>Not yet</i>';

    if (rec.selfEvaluation === 0) {
      cellText = 'None';
    } else if (rec.selfEvaluation > 0) {
      cellText =
        rec.levelDetails.find((x) => x.level === rec.selfEvaluation)?.criteriaDetail ?? `Level ${rec.selfEvaluation}`;
    }

    return (
      <Space>
        <span style={{ color: cellData.color }}>{cellData.icon}</span>
        <HtmlText className="detail-container" value={cellText} />
      </Space>
    );
  };

  const getLevelName = (lv: number) => {
    // TODO: Move this to constants
    const levelNames = ['None', 'Novice', 'Competent', 'Proficient', 'Advanced', 'Expert'];

    if (lv < 0 || lv >= levelNames.length) {
      return `Level ${lv}`;
    }

    return levelNames[lv];
  };

  const renderSelfEvaluationEdit = (rec: OwnershipStatusDetailItem) => {
    return (
      <Select>
        <Select.Option value={-1} style={{ display: 'none' }}>
          &nbsp;
        </Select.Option>
        <Select.Option value={0}>0: None</Select.Option>
        <Select.Option value={-2} disabled>
          <Typography.Text type="secondary">
            <hr />
          </Typography.Text>
        </Select.Option>
        {rec.levelDetails.map((x) => (
          <Select.Option key={`${rec.teamCriteriaId}_${x.level}`} value={x.level}>
            {x.level}: {getLevelName(x.level)}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const getSelfEvaluationChangeData = (
    change: OwnershipDetailEvaluationChange
  ): { icon: JSX.Element; color: string } => {
    switch (change) {
      case OwnershipDetailEvaluationChange.Up:
        return { icon: <ArrowUpOutlined />, color: 'green' };
      case OwnershipDetailEvaluationChange.Down:
        return { icon: <ArrowDownOutlined />, color: 'red' };
      default:
        return { icon: <MinusOutlined />, color: 'black' };
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ...`}
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const columnsV2: EditableColumn<OwnershipStatusDetailItem>[] = [
    {
      title: '#',
      width: '50px',
      align: 'center',
      render: (value, record, index) => (pageNumber - 1) * pageSize + index + 1,
    },
    {
      title: 'Criteria',
      key: 'category-criteria',
      editable: false,
      width: '25%',
      render: (_v, record) => (
        <Space direction="vertical">
          <Text strong>{record.criteriaName}</Text>
          {!record.categoryName.includes('2024 Team Maturity') && <Text type="secondary">{record.categoryName}</Text>}
        </Space>
      ),
      filters: chain(dataSource)
        .groupBy((r) => r.categoryName)
        .map((v) => ({
          text: v?.[0]?.categoryName,
          value: v?.[0]?.categoryName,
          children: v.map((x) => ({ text: x.criteriaName, value: `${x.criteriaId}` })),
        }))
        .value(),
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) =>
        record.categoryName.toLowerCase() === value.toString().toLowerCase() ||
        record.criteriaId.toString() === value.toString(),
    },
    {
      title: 'Self Assessment',
      width: '40%',
      sorter: {
        compare: (a, b) => a.selfEvaluation - b.selfEvaluation,
      },
      // @ts-ignore
      children: [
        {
          title: 'Level',
          dataIndex: 'selfEvaluation',
          // align: 'center',
          inputType: 'custom',
          editable: isAllowedEdit,
          render: (value, record) =>
            value > -1 ? (
              <Text strong>
                {value}: {getLevelName(value)}
              </Text>
            ) : (
              <></>
            ),
          editingContent: (record) => renderSelfEvaluationEdit(record),
        },
        {
          width: '30%',
          title: 'Details',
          dataIndex: 'selfEvaluationDetail',
          render: (value, record) => renderSelfEvaluationCell(record),
        },
      ],
    },
    {
      title: 'Action to Improve',
      dataIndex: 'actionToImprove',
      width: '25%',
      editable: isAllowedEdit,
      inputType: 'text',
      // sorter: (a, b) => a.actionToImprove?.localeCompare(b.actionToImprove),
    },
  ];
  const expandedRowRender = (record: OwnershipStatusDetailItem) => {
    type OwnershipCriteriaDetailAsEntity = Entity & OwnershipCriteriaDetail;
    const newColumns: EditableColumn<OwnershipCriteriaDetailAsEntity>[] = [
      {
        title: 'Criteria Level',
        dataIndex: 'level',
        inputType: 'number',
        align: 'right',
        width: '30px',
      },
      {
        title: 'Detail',
        dataIndex: 'criteriaDetail',
        width: '40%',
        render: (value) => <HtmlText className="detail-container" value={value} />,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        inputType: 'text',
        width: '60%',
        render: (value) => <HtmlText className="detail-container" value={value} />,
      },
    ];

    return (
      <EditableTable
        data={record.levelDetails?.map((r) => ({ id: r.level, ...r } as OwnershipCriteriaDetailAsEntity))}
        columns={newColumns}
        showAction={false}
        bordered={false}
        showSizeChanger={false}
        pagination={{ hideOnSinglePage: true, pageSize: 9999 }}
      />
    );
  };

  return (
    <ExpandableTable
      className="ownership-status-table"
      data={dataSource}
      isEdit={true}
      onSave={(id, record) => {
        if (onRecordUpdated) {
          return onRecordUpdated(record);
        }
      }}
      isDelete={false}
      columns={columnsV2}
      pagination={{
        pageSize: pageSize,
        onChange: onPaginationChange,
        current: pageNumber,
      }}
      expandable={{
        expandedRowRender,
      }}
    />
  );
};

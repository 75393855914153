import { CreateIterationGoal, IterationGoalRequest, IterationGoalResponse } from 'app/types/entity';
import { fetchAsync } from 'app/utils/fetch';

export const getIterationGoal = (iterationID: number): Promise<IterationGoalResponse[]> =>
  fetchAsync(`/api/iterationgoal/iteration/${iterationID}`);

export const createIterationGoal = (iterationGoal: CreateIterationGoal): Promise<IterationGoalResponse> => {
  const formData = new FormData();
  const { ...res } = iterationGoal;

  for (const key in res) {
    if (res[key] !== undefined || res[key] !== null) {
      formData.append(key, res[key]);
    }
  }
  const result = fetchAsync(`/api/iterationgoal`, {
    method: 'POST',
    body: formData,
    type: 'blob',
  });
  return result;
};

export const updateIterationGoal = (action: IterationGoalRequest): Promise<IterationGoalResponse> => {
  return fetchAsync(`/api/iterationgoal/${action.id}`, {
    method: 'PUT',
    body: action,
  });
};

export const deleteIterationGoal = (id: number): Promise<void> => {
  return fetchAsync(`/api/iterationgoal/${id}`, {
    method: 'DELETE',
  });
};

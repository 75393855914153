import styled from 'styled-components';
export const DemoPlanWrapper = styled.div`
  margin-top: 2rem;
  .add-button {
    border-radius: 0px !important;
  }
  .title-section-add-button {
    text-align: end;

    button {
      font-size: 16px;
      padding: 0;
      height: 35px;
      width: 70px;
      background-color: #2785ff;
      border-radius: 3px;
    }
  }
`;

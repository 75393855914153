import { notification } from 'antd';
import { PostRefresh } from 'app/common/constants';
import { convertBase64ToPDFAndDownload } from 'app/common/helpers';
import { CacheReportModel, ChartCollectionModel } from 'app/types/CacheReportModel';
import { IExportReportPDFResponse } from 'app/types/ExportReportPDFResponse';
import { ReportType } from 'app/types/CacheReportModel';
import {
  Iteration,
  IterationPlanRequest,
  IterationPlanResponse,
  IterationReportRequest,
  IterationReportResponse,
} from 'app/types/Iteration';
import { IIterationNote } from 'app/types/IterationNote';
import {
  DisplayComponentRequest,
  GetDisplayRequest,
  IntroductionRequest,
  IntroductionResponse,
  StakeholdersRequest,
  StakeholdersResponse,
} from 'app/types/Stakeholders';
import { SubPage } from 'app/types/TeamDetail';
import {
  BreadcrumbResponse,
  NoteReportCapacityModel,
  TargetCapacityModel,
  TeamCapacityModel,
  TeamCapacityResponse,
} from 'app/types/TeamDetailTypes';
import { TestCaseResponse } from 'app/types/TestCaseResponse';
import { fetchAsync } from 'app/utils/fetch';

import {
  DateNoteItem,
  DayOffRequest,
  DayOffRequestForList,
  ScheduleDateRequest,
  SingleAttend,
} from './../types/Attendance';
import { CarriedOverUpdateTicket, TicketResponse, UpdateTestCase, UpdateWorkItem } from './../types/PlannedWorkItem';
import { DemoPlanItemRequest, DemoPlanResponse } from './../types/TeamDetailTypes';

export const fetchIterations = (teamId: number): Promise<Iteration[]> => fetchAsync(`/api/iterations?teamId=${teamId}`);

export const fetchIterationDetail = (iterationID: number): Promise<Iteration> =>
  fetchAsync(`/api/iterations/${iterationID}`);

export const fetchAllWorkItems = (): Promise<TicketResponse[]> => fetchAsync(`/api/workitem/all`);

export const fetchAllPlannedWorkItems = (): Promise<TicketResponse[]> => fetchAsync(`/api/plannedworkitem/all`);

export const updateWorkItem = (id: number, workItem: UpdateWorkItem): Promise<TicketResponse> =>
  fetchAsync(`/api/workitem/${id}`, {
    method: 'PUT',
    body: workItem,
  });

export const updatePlannedWorkItem = (id: number, workItem: UpdateWorkItem): Promise<TicketResponse> =>
  fetchAsync(`/api/plannedworkitem/${id}`, {
    method: 'PUT',
    body: workItem,
  });

export const fetchAllWorkItemsTask = (): Promise<TicketResponse[]> => fetchAsync(`/api/workitem/allTask`);
export const fetchTeamBreadcrumbs = (teamId: number): Promise<BreadcrumbResponse[]> =>
  fetchAsync(`/api/team/breadcrumbs/${teamId}`);

export const createIterationPlan = (plan: IterationPlanRequest): Promise<IterationPlanResponse> =>
  fetchAsync(`/api/iterationplan`, {
    method: 'POST',
    body: plan,
  });
export const allowEditIterationPlan = (iterationPlanID: number): Promise<IterationPlanResponse> =>
  fetchAsync(`/api/iterationplan/allowedit/${iterationPlanID}`, {
    method: 'POST',
  });
export const fetchTaskByIteration = (iterationID: number): Promise<TeamCapacityModel[]> =>
  fetchAsync(`/api/workitem/task/${iterationID}`);

export const saveTeamCapacity = (iterationPlanID: number, request: TeamCapacityModel[]): Promise<TeamCapacityModel> =>
  fetchAsync(`/api/plannedcapacity/${iterationPlanID}`, {
    method: 'POST',
    body: request,
  });

export const saveNoteCapacity = (id: number, request: NoteReportCapacityModel): Promise<NoteReportCapacityModel> =>
  fetchAsync(`/api/plannedcapacity/updateCapacityNoteReport/${id}`, { method: 'PUT', body: request });

export const fetchDataChartByType = (teamId: number, type: number): Promise<CacheReportModel> =>
  fetchAsync(`/api/cachereport?teamId=${teamId}&type=${type}`, { method: 'GET' });

export const fetchAllChart = (teamId: number): Promise<ChartCollectionModel> =>
  fetchAsync(`/api/cachereport/all?teamId=${teamId}`, { method: 'GET' });

export const fetchIterationBurndownChart = (teamId: number, type: ReportType): Promise<CacheReportModel> =>
  fetchAsync(`/api/cachereport/?teamId=${teamId}&type=${type}`, { method: 'GET' });

export const fetchThroughtputChart = (teamId: number): Promise<ChartCollectionModel> =>
  fetchAsync(`/api/cachereport/throughtput?teamId=${teamId}`, { method: 'GET' });

export const fetchTargetCapacity = (iterationId: number): Promise<TargetCapacityModel> =>
  fetchAsync(`/api/plannedcapacity/${iterationId}/target`);

export const setTargetCapacity = (iterationId: number, target: TargetCapacityModel): Promise<TargetCapacityModel> =>
  fetchAsync(`/api/plannedcapacity/${iterationId}/target`, { method: 'POST', body: target });

export const fetchTaskByIterationMonitor = (iterationID: number): Promise<TeamCapacityResponse> =>
  fetchAsync(`/api/workitem/monitor/tasks/${iterationID}`);

export const fetchTaskByIterationReport = (iterationID: number): Promise<TeamCapacityResponse> =>
  fetchAsync(`/api/workitem/report/tasks/${iterationID}`);

export const updateIterationNote = (iterationNote: IIterationNote): Promise<IIterationNote> =>
  fetchAsync(`/api/iterationnote`, { method: 'PATCH', body: iterationNote });
export const createIterationNote = (iterationNote: IIterationNote): Promise<IIterationNote> =>
  fetchAsync(`/api/iterationnote`, { method: 'POST', body: iterationNote });
export const getAllNoteByPage = (page: SubPage): Promise<IIterationNote[]> =>
  fetchAsync(`/api/iterationnote?pageType=${page}`);
export const getNoteByPage = (iterationId: number, page: SubPage): Promise<IIterationNote[]> =>
  fetchAsync(`/api/iterationnote/${iterationId}?pageType=${page}`);

export const getIterationReport = (iterationID: number): Promise<IterationReportResponse> =>
  fetchAsync(`/api/iterationreport/${iterationID}`);
export const getListCarriedOverToNextIteration = (iterationID: number): Promise<TicketResponse[]> =>
  fetchAsync(`/api/iterationreport/get-list-carriedover-tickets/${iterationID}`);

export const createIterationReport = (iterationReport: IterationReportRequest): Promise<IterationReportResponse> =>
  fetchAsync(`/api/iterationreport`, {
    method: 'POST',
    body: iterationReport,
  });
export const updateCarriedOverNoteTicket = (ticketCarriedOver: CarriedOverUpdateTicket): Promise<TicketResponse> =>
  fetchAsync(`/api/iterationreport/update-carriedover-note-ticket`, {
    method: 'PUT',
    body: ticketCarriedOver,
  });

export const fetchTestcase = (iterationId: number): Promise<TestCaseResponse[]> =>
  fetchAsync(`/api/workitem/testcases/${iterationId}`);

export const updateTestCaseNote = (id: number, testCaseNote: UpdateTestCase): Promise<TicketResponse> =>
  fetchAsync(`/api/workitem/testcases/${id}`, {
    method: 'PUT',
    body: testCaseNote,
  });
export const editIterationReport = (iterationReport: IterationReportRequest): Promise<IterationReportResponse> =>
  fetchAsync(`/api/iterationreport/edit`, {
    method: 'PUT',
    body: iterationReport,
  });

export const submitIterationReport = (iterationReport: IterationReportRequest): Promise<IterationReportResponse> =>
  fetchAsync(`/api/iterationreport/submit`, {
    method: 'PUT',
    body: iterationReport,
  });

export const fetchDemoPlan = (iterationId: number, teamId: number): Promise<DemoPlanResponse[]> =>
  fetchAsync(`/api/demoplan/${iterationId}/${teamId}`);
export const fetchWorkItemsForDemoPlan = (iterationId: number): Promise<TicketResponse[]> =>
  fetchAsync(`/api/demoplan/workItems/${iterationId}`);

export const createDemoPlanItem = (demoPlanRequest: DemoPlanItemRequest): Promise<DemoPlanResponse> =>
  fetchAsync(`/api/demoplan`, {
    method: 'POST',
    body: demoPlanRequest,
  });
export const saveDemoPlanByType = (iterationId: number, pageType: SubPage): Promise<DemoPlanResponse> =>
  fetchAsync(`/api/demoplan/saveDemoPlanByType/${iterationId}/${pageType}`, {
    method: 'POST',
  });
export const updateDemoPlan = (id: number, demoPlanRequest: DemoPlanItemRequest): Promise<DemoPlanResponse> =>
  fetchAsync(`/api/demoplan/update/${id}`, {
    method: 'PUT',
    body: demoPlanRequest,
  });
export const removeDemoPlan = (id: number): Promise<DemoPlanResponse> =>
  fetchAsync(`/api/demoplan/delete/${id}`, {
    method: 'DELETE',
  });
// attendance
export const fetchDayOffs = (iterationId: number): Promise<SingleAttend[]> =>
  fetchAsync(`/api/teamAttendance/dayOffs/${iterationId}`);
export const fetchScheduleDate = (iterationId: number): Promise<DateNoteItem[]> =>
  fetchAsync(`/api/teamAttendance/scheduleDates/${iterationId}`);

export const createDateOff = (dayOffRequest: DayOffRequest): Promise<SingleAttend> =>
  fetchAsync(`/api/teamAttendance/dayOff`, {
    method: 'POST',
    body: dayOffRequest,
  });
export const createListDateOff = (dayOffRequest: DayOffRequest[]): Promise<any[]> =>
  fetchAsync(`/api/teamAttendance/listDayOff`, {
    method: 'POST',
    body: dayOffRequest,
  });
export const createScheduleDate = (scheduleDateRequest: ScheduleDateRequest): Promise<DateNoteItem> =>
  fetchAsync(`/api/teamAttendance/scheduleDate`, {
    method: 'POST',
    body: scheduleDateRequest,
  });
export const saveAttendanceByType = (iterationId: number, pageType: SubPage): Promise<DemoPlanResponse> =>
  fetchAsync(`/api/teamAttendance/saveAttendanceByType/${iterationId}/${pageType}`, {
    method: 'GET',
  });
export const updateDayOff = (id: number, dayOffRequest: DayOffRequest): Promise<SingleAttend> =>
  fetchAsync(`/api/teamAttendance/updateDayOff/${id}`, {
    method: 'PUT',
    body: dayOffRequest,
  });
export const updateListDayOff = (dayOffRequest: DayOffRequestForList[]): Promise<any[]> =>
  fetchAsync(`/api/teamAttendance/updateListDayOff`, {
    method: 'PUT',
    body: dayOffRequest,
  });
export const updateScheduleDate = (id: number, scheduleDateRequest: ScheduleDateRequest): Promise<DateNoteItem> =>
  fetchAsync(`/api/teamAttendance/updateScheduleDate/${id}`, {
    method: 'PUT',
    body: scheduleDateRequest,
  });
export const removeDayOff = (id: number): Promise<SingleAttend> =>
  fetchAsync(`/api/teamAttendance/deleteDayOff/${id}`, {
    method: 'DELETE',
  });
export const removeScheduleDate = (id: number): Promise<DateNoteItem> =>
  fetchAsync(`/api/teamAttendance/deleteScheduleDate/${id}`, {
    method: 'DELETE',
  });

export const downloadReportExportPDF = async (
  imageDataString: string,
  filename: string,
  teamId: number
): Promise<any> => {
  await fetchAsync('/api/iterationreport/report-image-pdf', {
    type: 'json',
    method: 'PUT',
    body: {
      imageDataString: imageDataString,
      filename: filename,
      teamId: teamId,
    },
  }).then((response: IExportReportPDFResponse) => {
    if (response.hasError) {
      notification.error({
        message: `PDF generation failed`,
        duration: 3,
        description: response.errorMessage,
      });
    } else {
      convertBase64ToPDFAndDownload(response.base64Data, filename);
    }
  });
};

export const fetchAllStakeholders = (teamId: number): Promise<StakeholdersResponse[]> =>
  fetchAsync(`/api/stakeholders/getall/${teamId}`);

export const updateStakeholders = (id: number, request: StakeholdersRequest): Promise<StakeholdersResponse> =>
  fetchAsync(`/api/stakeholders/updateStakeholders/${id}`, {
    method: 'PUT',
    body: request,
  });

export const createStakeholders = (request: StakeholdersRequest): Promise<StakeholdersResponse> =>
  fetchAsync(`/api/stakeholders/updateStakeholders`, {
    method: 'PUT',
    body: request,
  });

export const getIntroduction = (teamId: number): Promise<IntroductionResponse> =>
  fetchAsync(`/api/introduction/getIntroductionByTeamId/${teamId}`);
export const updateIntroduction = (id: number, request: IntroductionRequest): Promise<IntroductionResponse> =>
  fetchAsync(`/api/introduction/updateIntroductionByTeamId/${id}`, {
    method: 'PUT',
    body: request,
  });

export const getDisplayComponents = (request: GetDisplayRequest): Promise<any> =>
  fetchAsync(`/api/displayComponents/`, {
    method: 'POST',
    body: request,
  });

export const updateDisplayComponent = (request: DisplayComponentRequest): Promise<any> =>
  fetchAsync('/api/displayComponents/updateDisplayComponent', {
    method: 'PUT',
    body: request,
  });

export const createDisplayComponent = (request: DisplayComponentRequest): Promise<any> =>
  fetchAsync('/api/displayComponents/createDisplayComponent', {
    method: 'POST',
    body: request,
  });

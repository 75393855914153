import { useContext, useEffect, useState } from 'react';
import { Row, Col, Radio, RadioChangeEvent, Select } from 'antd';

// Contexts
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { Stage } from 'app/types/TeamDetailTypes';

// Styles

import { fetchTeamBreadcrumbs } from 'app/apis/teamDetailClient';
import { SubPage, subPageOptions } from 'app/types/TeamDetail';
import { IterationContext } from 'app/contexts/IterationContext';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useFetch } from 'app/hooks/useFetch';
import { PageTitle, StyledTag } from '../TeamDetail/TeamDetail.styled';
import { TeamParentContext } from 'app/contexts/TeamParentContext';
import { getIterationsListNotFuture } from 'app/apis/iterationOverview';
import { GroupedTeamPlan } from 'app/components/GroupedTeamPlanComponent/GroupedTeamPlanComponent';
import { GroupedTeamReport } from 'app/components/GroupedTeamReport/GroupedTeamReport';
import { GroupedTeamMonitor } from 'app/components/GroupedTeamMonitor/GroupedTeamMonitor';
import { TeamType } from 'app/types/team';
import { TitleTeamType } from 'app/types/GroupedTeamModel';
import { getOverViewBaseBreadCrumb } from 'app/common/breadcrumb';
import { UnderConstruction } from 'app/components/UnderConstruction/UnderConstruction';

export const Portfolio = () => {
  const { team } = useContext(TeamDetailContext);
  const { setStage } = useContext(IterationContext);
  const { setBreadcrumbs, page, setSubPage } = useContext(TeamContext);
  const { data: iterationsList } = useFetch<string[]>(() => getIterationsListNotFuture(), []);
  const [iterationOptions, setIterationOptions] = useState<{ label: string; value: string }[]>([]);
  const { selectedIterationName, setSelectedIterationName, iterationData } = useContext(TeamParentContext);

  const [titleTableColumn, setTitleTableColumn] = useState('');

  const handleIterationOptionChange = (selectedValue: string) => {
    setSelectedIterationName(selectedValue);
    if (selectedValue !== iterationOptions[0]?.value) {
      setSubPage(SubPage.Report);
    } else setSubPage(SubPage.Monitoring);
  };

  useEffect(() => {
    if (iterationsList?.length > 0) {
      setSelectedIterationName(iterationsList[0].toString());
      const options = iterationsList.map((iteration) => ({
        label: iteration.toString(),
        value: iteration.toString(),
      }));
      setIterationOptions(options);
    }
  }, [iterationsList]);

  useFetch(
    () =>
      fetchTeamBreadcrumbs(team?.id).then((data) => {
        setBreadcrumbs([getOverViewBaseBreadCrumb(), ...data]);
      }),
    [team?.id]
  );

  useEffect(() => {
    setTitleTableColumn(TitleTeamType.DeliveryStream);
  }, [team?.id]);

  useEffect(() => {
    if (page !== SubPage.Plan) {
      setStage(Stage.Other);
    }
  }, [page, setStage]);

  const pagesOptions =
    selectedIterationName === iterationOptions[0]?.value
      ? subPageOptions
      : subPageOptions.filter((page) => page.value !== SubPage.Monitoring);
  const onChangeTeamType = ({ target: { value } }: RadioChangeEvent) => {
    setSubPage(value);
  };
  return (
    <>
      <Row style={{ marginBottom: '1.5em' }} gutter={16}>
        <Col className="gutter-row">
          <PageTitle level={5}>{team?.name.toUpperCase()}</PageTitle>
        </Col>
        <Col className="gutter-row">
          <StyledTag color="#108ee9">{team?.model ?? 'TBD'}</StyledTag>
        </Col>
        <Col className="gutter-row">
          <Select
            onChange={handleIterationOptionChange}
            value={selectedIterationName}
            options={iterationOptions}
            style={{ width: 120 }}
          />
        </Col>

        <Col className="gutter-row" style={{ marginLeft: 'auto' }}>
          <Radio.Group
            options={pagesOptions}
            onChange={onChangeTeamType}
            value={page}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
      </Row>
      <Row>
        {true && (
          <Col style={{ minWidth: '100% ' }}>
            {page === SubPage.Overview && <UnderConstruction />}
            {page === SubPage.Plan && <GroupedTeamPlan titleColumn={titleTableColumn} teamType={TeamType.Portfolio} />}
            {page === SubPage.Monitoring && <GroupedTeamMonitor iteration={iterationData} type={TeamType.Portfolio} />}
            {page === SubPage.Report && <GroupedTeamReport iteration={iterationData} type={TeamType.Portfolio} />}
          </Col>
        )}
      </Row>
    </>
  );
};

import { ReportType } from './CacheReportModel';

export interface SaveChartSettingRequest {
  teamId: number;
  chartType: ReportType;
  target?: number;
  note: string;
}

export class SaveChartSettingModel implements SaveChartSettingRequest {
  teamId: number;
  chartType: ReportType;
  target?: number;
  note: string;
}

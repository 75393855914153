import { CloudUploadOutlined } from '@ant-design/icons';
import { Form, Input, Upload, Button, Image } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { ErrorType } from 'app/common/constants';
import { IComplimentRequest, IComplimentResponse } from 'app/types/entity';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { useEffect, useState } from 'react';
import { formAction } from './ComplimentsSection';
export interface IComplimentFormProps {
  action: formAction;
  complimentData: IComplimentResponse;
  onSubmit: (form: IComplimentRequest) => void;
  onCloseModal: () => void;
}

export function ComplimentForm(props: IComplimentFormProps) {
  const [complimentData, setComplimentData] = useState<IComplimentResponse>({ ...props.complimentData });
  const [uploadFiles, setUpLoadFiles] = useState<File[]>([]);
  const [previewImg, setPreviewImg] = useState<{ visible: boolean; src: string }>({ visible: false, src: '' });
  useEffect(() => {
    form.setFieldsValue({
      title: props.complimentData.title,
      message: props.complimentData.message,
      from: props.complimentData.from,
      images: props.complimentData.complimentImages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.complimentData]);

  const [form] = Form.useForm();

  const handleSubmitForm = () => {
    const formValues = form.getFieldsValue(['title', 'message', 'from', 'images']);
    const payload: IComplimentRequest = {
      id: props.complimentData.id,
      title: formValues.title,
      message: formValues.message,
      from: formValues.from,
      iterationId: props.complimentData.iterationId,
      complimentImages: complimentData.complimentImages ? complimentData.complimentImages : [],
      uploadImages: uploadFiles,
    };

    props.onSubmit(payload);
    form.resetFields();
    props.onCloseModal();
  };
  const onPreview = async (file: UploadFile) => {
    const url = file?.originFileObj instanceof File ? URL.createObjectURL(file.originFileObj) : file.url;
    setPreviewImg({ visible: true, src: url });
  };

  return (
    <Form
      form={form}
      initialValues={{
        title: complimentData.title,
        message: complimentData.message,
        from: complimentData.from,
        images: complimentData.complimentImages,
      }}
      validateTrigger="onSubmit"
      onFinish={() => handleSubmitForm()}
      labelAlign="right"
      colon={false}
      className="form"
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
    >
      <Form.Item
        shouldUpdate
        name="title"
        label="Title"
        rules={[
          { required: true, min: 5, max: 200, message: 'Length must be between 5 and 200' },
          { whitespace: true, message: 'Field must not be empty' },
        ]}
      >
        <Input
          readOnly={props.action === formAction.Read && true}
          style={props.action === formAction.Read ? { backgroundColor: '#D9D9D9' } : {}}
        />
      </Form.Item>
      <div className="message-section">
        <Form.Item
          name="message"
          label="Message"
          rules={[
            { required: true, min: 5, max: 500, message: 'Length must be between 5 and 500' },
            { whitespace: true, message: 'Field must not be empty' },
          ]}
        >
          <Input.TextArea
            maxLength={500}
            showCount={true}
            className={props.action === formAction.Read && 'read-only'}
            readOnly={props.action === formAction.Read && true}
          />
        </Form.Item>
      </div>
      <Form.Item
        shouldUpdate
        name="from"
        label="From"
        rules={[
          { required: true, min: 5, max: 100, message: 'Length must be between 5 and 100' },
          { whitespace: true, message: 'Field must not be empty' },
        ]}
      >
        <Input
          style={props.action === formAction.Read ? { backgroundColor: '#D9D9D9' } : {}}
          readOnly={props.action === formAction.Read && true}
        />
      </Form.Item>
      <Form.Item label="Picture" name="images">
        <Upload
          defaultFileList={props.complimentData?.complimentImages?.map((x) => {
            return {
              uid: x.id.toString(),
              name: x.fileName,
              url: x.href,
              thumbUrl: x.href,
            };
          })}
          showUploadList={{ showDownloadIcon: false, showRemoveIcon: props.action === formAction.Read ? false : true }}
          action={''}
          onPreview={(file) => {
            onPreview(file);
          }}
          listType="picture"
          //maxCount={3}
          multiple
          accept=".jpeg,.png,.jpg"
          beforeUpload={(file, fileList) => {
            if (complimentData.complimentImages) {
              if (complimentData.complimentImages?.length + uploadFiles?.length + fileList?.length > 3) {
                if (file.uid === fileList[fileList.length - 1].uid) {
                  openNotificationByType(ErrorType, 'Maximum is 3 images!!!');
                }
                return Upload.LIST_IGNORE;
              }
            } else {
              if (uploadFiles?.length + fileList?.length > 3) {
                if (file.uid === fileList[fileList.length - 1].uid) {
                  openNotificationByType(ErrorType, 'Maximum is 3 images!!!');
                }
                return Upload.LIST_IGNORE;
              }
            }
            if (file.size / 1024 / 1024 >= 3) {
              openNotificationByType(ErrorType, file.name + ' size is too big. File must be under 3MB ');
              return Upload.LIST_IGNORE;
            }
            //sometimes undf or empty array
            const arr = complimentData?.complimentImages ? [...complimentData.complimentImages] : [];
            const dbExisted = arr.filter((x) => x.fileName === file.name);
            const uploadExisted = uploadFiles?.filter((x) => x.name === file.name);
            if (dbExisted.length > 0 || uploadExisted.length > 0) {
              openNotificationByType(ErrorType, file.name + ' already existed');
              return Upload.LIST_IGNORE;
            }
            setUpLoadFiles([...uploadFiles, ...fileList.filter((x) => x.uid !== undefined)]);
            return false;
          }}
          onRemove={(file) => {
            const x = complimentData.complimentImages?.filter((compliment) => compliment.id.toString() === file.uid);
            if (x?.length > 0) {
              setComplimentData({
                ...complimentData,
                complimentImages: [
                  ...complimentData.complimentImages.filter((compliment) => compliment.id.toString() !== file.uid),
                ],
              });
            } else {
              setUpLoadFiles([...uploadFiles?.filter((upFile) => upFile.name !== file.name)]);
            }
          }}
        >
          {props.action !== formAction.Read && (
            <Button style={{ paddingLeft: '0' }} type="link" className="btn-upload" icon={<CloudUploadOutlined />}>
              Upload Picture
            </Button>
          )}
        </Upload>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <div className="form-footer">
          <Button
            className="btn btn-cancel"
            type="default"
            formAction=""
            onClick={() => {
              props.onCloseModal();
              form.resetFields();
            }}
          >
            {props.action === formAction.Read ? 'close' : 'cancel'}
          </Button>
          {props.action !== formAction.Read && (
            <Button key="submit" type="primary" formAction="" htmlType="submit" className="btn">
              {props.action.toUpperCase()}
            </Button>
          )}
        </div>
      </Form.Item>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            destroyOnClose: true,
            visible: previewImg.visible,
            onVisibleChange: (vis) => setPreviewImg({ visible: vis, src: previewImg.src }),
          }}
        >
          <Image src={previewImg.src} />
        </Image.PreviewGroup>
      </div>
    </Form>
  );
}

export const exportOptions = [
  {
    title: 'Iteration Highlight',
    value: 'iteration-highlight-section',
    options: [{ label: 'Iteration Highlight', value: 'iteration-highlight-section' }],
  },
  {
    title: 'Iteration Goals',
    value: 'iteration-goal-section',
    options: [{ label: 'Iteration Goals', value: 'iteration-goal-section' }],
  },
  {
    title: 'Iteration Summary',
    value: 'iteration-summary-section',
    options: [
      { label: 'Team Delivery', value: 'team-delivery-export' },
      { label: 'Schedule State', value: 'schedule-state-export' },
      { label: 'Utilized Capacity', value: 'utilized-capacity-export' },
      { label: 'Carried Over', value: 'carried-over-export' },
      { label: 'Iteration Health', value: 'iteration-health-export' },
      { label: 'Velocity', value: 'velocity-export' },
      { label: 'CFD', value: 'cfd-export' },
      { label: 'US Committed', value: 'us-committed-export' },
      { label: 'Defect Committed', value: 'defect-commited-export' },
      { label: 'Iteration Burndown', value: 'iteration-burndown-export' },
      { label: 'Iteration Burnup', value: 'iteration-burnup-export' },
    ],
  },
  {
    title: 'Quality Report',
    value: 'quality-report-section',
    options: [
      { label: 'Test Coverage', value: 'test-coverage-export' },
      { label: 'Test Execution State', value: 'test-execution-state-export' },
      { label: 'Quality Summary', value: 'quality-summary-export' },
      { label: 'Test Execution Detail', value: 'test-execution-detail-export' },
      { label: 'Defects by State', value: 'defects-by-state-export' },
      { label: 'Defects by Environment', value: 'defects-by-environment-export' },
      { label: 'Root Cause Category by Defect', value: 'root-cause-category-by-defect-export' },
      { label: 'Resolution by Defect', value: 'resolution-by-defect-export' },
      { label: 'Defect Root Cause Analysis', value: 'defect-root-cause-analysis-export' },
    ],
  },
  {
    title: 'Capacity detail',
    value: 'capacity-detail-export',
    options: [{ label: 'Capacity detail', value: 'capacity-detail-export' }],
  },
  {
    title: 'Iteration Backlog',
    value: 'iteration-backlog-section',
    options: [{ label: 'Backlog Detail', value: 'iteration-backlog-section' }],
  },
  {
    title: 'Retrospective',
    value: 'retrospective-section',
    options: [
      { label: 'Previous Actions', value: 'previous-action-export' },
      { label: 'Went well', value: 'went-well-export' },
      { label: 'Need improve', value: 'need-improve-export' },
      { label: 'Follow-up Actions', value: 'follow-up-export' },
    ],
  },
  {
    title: 'Demo Plan',
    value: 'demo-plan',
    options: [{ label: 'Demo Plan', value: 'demo-plan' }],
  },
  {
    title: 'Day-off Schedule',
    value: 'dayoff-schedule',
    options: [{ label: 'Day-off Schedule', value: 'dayoff-schedule' }],
  },
  {
    title: 'Compliment Section',
    value: 'compliments',
    options: [{ label: 'Compliment Section', value: 'compliments' }],
  },
  {
    title: 'Best Performance',
    value: 'best-performance-section',
    options: [{ label: 'Best Performance', value: 'best-performance-section' }],
  },
  {
    title: 'Additional Report',
    value: 'custom-export',
    options: [{ label: 'Additional Report', value: 'custom-export' }],
  },
];

export const overviewSection = [
  {
    title: 'Iteration Highlight',
    value: 'iteration-highlight-section',
    options: [{ label: 'Iteration Highlight', value: 'iteration-highlight-section' }],
  },
];

export const planSection = [
  {
    title: 'Iteration Highlight',
    value: 'iteration-highlight-section',
    options: [{ label: 'Iteration Highlight', value: 'iteration-highlight-section' }],
  },
  {
    title: 'Iteration Goals',
    value: 'iteration-goal-section',
    options: [{ label: 'Iteration Goals', value: 'iteration-goal-section' }],
  },
];

export const monitorSection = [
  {
    title: 'Quality Report',
    value: 'quality-report-section',
    options: [
      { label: 'Test Coverage', value: 'test-coverage-export' },
      { label: 'Test Execution State', value: 'test-execution-state-export' },
      { label: 'Quality Summary', value: 'quality-summary-export' },
      { label: 'Test Execution Detail', value: 'test-execution-detail-export' },
      { label: 'Defects by State', value: 'defects-by-state-export' },
      { label: 'Defects by Environment', value: 'defects-by-environment-export' },
    ],
  },
];

import React from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import { ChartWeeklyStatus, WeeklyIssueTypeColour } from 'app/types/WeeklyOverview';
import { CriticalStatusColor, GoodStatusColor, RiskStatusColor } from 'app/common/constants';

type IProps = {
  dataSource: ChartWeeklyStatus[];
};

export const WeeklyHealthChart = ({ dataSource }: IProps) => {
  const generateColumnConfig = (dataSource: ChartWeeklyStatus[]): ColumnConfig => {
    const customTargetLegend: LegendItem[] = [
      {
        name: WeeklyIssueTypeColour[WeeklyIssueTypeColour.Critical],
        value: WeeklyIssueTypeColour[WeeklyIssueTypeColour.Critical],
        marker: {
          symbol: 'circle',
          style: {
            fill: CriticalStatusColor,
          },
        },
      },
      {
        name: WeeklyIssueTypeColour[WeeklyIssueTypeColour.Risk],
        value: WeeklyIssueTypeColour[WeeklyIssueTypeColour.Risk],
        marker: {
          symbol: 'circle',
          style: {
            fill: RiskStatusColor,
          },
        },
      },
      {
        name: WeeklyIssueTypeColour[WeeklyIssueTypeColour.Good],
        value: WeeklyIssueTypeColour[WeeklyIssueTypeColour.Good],
        marker: {
          symbol: 'circle',
          style: {
            fill: GoodStatusColor,
          },
        },
      },
    ];
    const criticalData = dataSource.filter((item) => item.type === WeeklyIssueTypeColour.Critical);
    const riskData = dataSource.filter((item) => item.type === WeeklyIssueTypeColour.Risk);
    const goodData = dataSource.filter((item) => item.type === WeeklyIssueTypeColour.Good);
    const data = criticalData
      .concat(riskData)
      .concat(goodData)
      .map((d) => ({
        week: d.weekNum.toString(),
        teams: d.teams,
        type: WeeklyIssueTypeColour[d.type],
      }));
    return {
      data,
      legend: {
        position: 'top',
        items: customTargetLegend,
      },
      tooltip: {
        title: (title) => `Week ${title}`,
      },
      isStack: true,
      xField: 'week',
      yField: 'teams',
      seriesField: 'type',
      color: [CriticalStatusColor, RiskStatusColor, GoodStatusColor],
      yAxis: {
        type: 'linear',

        title: {
          text: 'Team',
          style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
        },
        position: 'left',
      },
    };
  };

  return <Column {...generateColumnConfig(dataSource)} />;
};

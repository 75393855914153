import { Col, Divider, Progress, Row } from 'antd';
import { DefectColor, PROGRESS_COLOR, UnfillProgressBarColor, UserStoriesColor } from 'app/common/constants';
import { TypeProgress } from 'app/types/entity';

import './CardOverview.scss';

interface IProps {
  title?: string;
  firstLabel: string;
  secondLabel: string;
  firstValue?: number;
  secondValue?: number;
  typeProgress?: number;
}

export const CardOverview = ({ title, firstLabel, secondLabel, firstValue, secondValue, typeProgress }: IProps) => {
  const value = (secondValue / firstValue) * 100;
  const middlePoint = value - 100;

  const totalValue = firstValue + secondValue;
  const progressBarValueCarriedOver = (firstValue / totalValue) * 100;

  const strokes =
    value > 100
      ? {
          '0%': middlePoint > 100 ? PROGRESS_COLOR.end : PROGRESS_COLOR.start,
          [`${100 - middlePoint}%`]: PROGRESS_COLOR.end,
          '100%': PROGRESS_COLOR.over,
        }
      : {
          from: PROGRESS_COLOR.start,
          to: PROGRESS_COLOR.end,
        };

  return (
    <div className="card-container">
      {title && (
        <div className="card-title title-overview">
          <span style={{ marginLeft: '-10px' }}>{title}</span>
        </div>
      )}
      <Row style={{ marginBottom: '4px' }}>
        <Col>
          {firstValue ? (
            <span>
              {firstLabel}: {firstValue}
            </span>
          ) : (
            <span>
              {firstLabel}: {0}
            </span>
          )}
        </Col>
        <Divider type="vertical" orientation="right" style={{ height: '1.4em', borderLeft: '1px solid #8a8a8a' }} />
        <Col>
          {secondValue ? (
            <span>
              {secondLabel}: {secondValue}
            </span>
          ) : (
            <span>
              {secondLabel}: {0}
            </span>
          )}
        </Col>
      </Row>
      {typeProgress === TypeProgress.ProgressBar ? (
        <div className="percent-progress-container">
          <Progress
            className="percent-progress"
            percent={value}
            strokeLinecap="square"
            strokeColor={strokes}
            showInfo={false}
          />
          <span>{value.toFixed(0)}%</span>
        </div>
      ) : (
        <Progress
          className="percent-progress"
          percent={progressBarValueCarriedOver}
          strokeLinecap="square"
          strokeColor={progressBarValueCarriedOver === 0 ? UnfillProgressBarColor : UserStoriesColor}
          trailColor={isNaN(progressBarValueCarriedOver) ? UnfillProgressBarColor : DefectColor}
          showInfo={false}
        />
      )}
    </div>
  );
};

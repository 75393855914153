import { Tabs } from 'antd';
import { GuidelineAndFaqs } from 'app/common/constants';
import { CustomEditor } from 'app/components/CustomEditor/CustomEditor';
import './GuidelineFaq.scss';

export const GuidelineFaq = () => {
  return (
    <Tabs className="guideline-faq-wrapper">
      <Tabs.TabPane className="tab-item" tab="Guideline" key="Guideline" forceRender>
        <CustomEditor tabType={GuidelineAndFaqs.guideline} />
      </Tabs.TabPane>
      <Tabs.TabPane className="tab-item" tab="FAQ" key="FAQ" forceRender>
        <CustomEditor tabType={GuidelineAndFaqs.faq} />
      </Tabs.TabPane>
    </Tabs>
  );
};

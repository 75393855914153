import { Column, ColumnConfig, Datum } from '@ant-design/charts';
import { Empty } from 'antd';
import { SUBMITTED_STATE_COLOR } from 'app/common/constants';
import { IDataChartProps } from 'app/types/CacheReportModel';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

export const StyledEmpty = styled.div`
  .ant-empty {
    height: 300px;
    opacity: 0.5;
  }
  .ant-empty-image {
    height: 200px;
    img {
      padding-top: 50px;
    }
  }
`;

export const IterationDefectsByEnvironment = ({ teamId, iteration, dataSource }: IDataChartProps) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dataSource) {
      setData(dataSource.filter((x) => x.value !== 0));
    }
  }, [dataSource]);

  let maxValue = 0;
  if (data && data.length > 0) {
    maxValue = Math.max(
      ...data
        .filter((x) => x.value)
        .map((object) => {
          return object.value;
        })
    );
    maxValue = Math.ceil(maxValue * 1.1);
  }

  const config: ColumnConfig = {
    data,
    xField: 'environment',
    yField: 'value',
    width: 500,
    height: 300,
    label: {
      position: 'top',
    },
    yAxis: {
      title: {
        text: 'Defects',
        position: 'center',
        spacing: 20,
        style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
      },
      max: maxValue,
      tickInterval: 1,
    },
    color: SUBMITTED_STATE_COLOR,
    maxColumnWidth: 50,
    tooltip: {
      formatter: (datum: Datum) => ({
        name: 'Defects',
        value: datum.value,
      }),
    },
  };

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  if (data.length === 0)
    return (
      <StyledEmpty>
        <Empty image="/assets/img/NoBugFound.png" description={<>No Defect Found!</>} />
      </StyledEmpty>
    );

  return <Column {...config} />;
};

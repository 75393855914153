import { Line, LineConfig } from '@ant-design/plots';
import { OwnershipScoreLevel, OwnershipScoreTrendItem, TargetType } from 'app/types/Ownership';
import moment from 'moment';
import { chain } from 'lodash';
import { GROWING_COLOR, BUILDING_COLOR, SOLID_COLOR, STRONG_COLOR, LEADING_COLOR } from 'app/common/constants';

interface IProps {
  scoreDetails: OwnershipScoreLevel[];
  data: OwnershipScoreTrendItem[];
}

const getColor = (idx) =>
  [GROWING_COLOR, BUILDING_COLOR, SOLID_COLOR, STRONG_COLOR, LEADING_COLOR][idx] ?? GROWING_COLOR;

export const OwnershipTrendByActualScore = ({ data, scoreDetails }: IProps) => {
  const maxScore = Math.max(...data.filter((x) => x.scoreType === TargetType.Score).map((x) => x.maxScore));
  const processedData = chain(data)
    .filter((x) => x.scoreType === TargetType.Score)
    .groupBy((x) => x.itemDate)
    .map((items, date) => ({
      date: moment(date).format('MMM YY'),
      totalScore: items.map((x) => x.actualScore).reduce((partialSum, a) => partialSum + a, 0),
    }))
    .value();

  const config: LineConfig = {
    data: processedData,
    padding: 'auto',
    smooth: true,
    xField: 'date',
    yField: 'totalScore',
    label: {
      content: (data) => `${data['totalScore']} pts`,
    },
    point: {
      shape: 'diamond',
      size: 5,
    },
    yAxis: {
      base: 0,
      max: maxScore,
    },
    tooltip: {
      showMarkers: false,
      formatter: ({ totalScore }) => ({
        name: 'Total Score',
        value: totalScore,
      }),
    },
    lineStyle: {
      opacity: 1,
    },
    annotations: scoreDetails
      .map((x) => x.scoreTarget)
      .flatMap((x, idx, arr) => [
        {
          type: 'line',
          start: ['min', x.from],
          end: ['max', x.from],
          text: {
            content: `Level ${x.level}`,
          },
          style: {
            stroke: getColor(idx),
            lineDash: [4, 4],
          },
        },
        {
          type: 'regionFilter',
          start: [-5, x.from],
          end: [105, x.to ?? arr[idx + 1]?.from ?? 'max'],
          color: getColor(idx),
        },
      ]),
  };

  return <Line {...config} />;
};

import { Mix as PlotMix, MixConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import { Empty } from 'antd';
import { DataModel, IterationBurndown, TooltipLabelConfig } from 'app/types/CacheReportModel';
import { useEffect, useState } from 'react';

interface IProps {
  isReport: Boolean;
  dataSource: any;
}

export const IterationBurnDown = ({ dataSource, isReport }: IProps) => {
  const [data, setData] = useState<IterationBurndown>(undefined);
  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);
  const [height, setHeight] = useState<number>(300);
  useEffect(() => {
    setHeight(() => (isReport ? 328 : 300));
  }, [isReport]);

  const formatDate = (date: string | Date) => {
    return new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'numeric' }).replace('/', '-');
  };

  const generatePlotMixConfig = (data: IterationBurndown): MixConfig => {
    if (!data) return null;

    let toDoHours: DataModel[] = [];
    let acceptedPts: DataModel[] = [];
    let lineData: DataModel[] = [];

    const maxHrsValue = Math.ceil((data?.maxHours * 1.1) / 100) * 100;
    const maxPtsValue = Math.ceil(data?.maxPoints / 5 > 0 ? data?.maxPoints / 5 : 1) * 5;

    data?.dataPoints?.forEach((obj) => {
      const date = formatDate(obj.date);
      toDoHours.push(
        { time: date, value: obj.todo !== 0 ? obj.todo : null, type: TooltipLabelConfig['task'] },
        { time: date, value: null, type: 'hidden' }
      );
      acceptedPts.push(
        { time: date, value: null, type: 'hidden' },
        { time: date, value: obj.accepted !== 0 ? obj.accepted : null, type: TooltipLabelConfig['accepted'] }
      );
      lineData.push({ time: date, value: obj.ideal, type: TooltipLabelConfig['ideal'] });
    });

    return {
      tooltip: {
        shared: true,
        customItems: (originItems) => {
          return originItems.filter((x) => x.name !== 'hidden');
        },
      },
      legend: {
        // eslint-disable-next-line prettier/prettier
        type: {
          position: 'top',
          items: customTargetLegend,
        },
      },
      syncViewPadding: true,
      height: height,
      width: 500,
      plots: [
        {
          type: 'column',
          options: {
            data: toDoHours,
            xField: 'time',
            yField: 'value',
            label: {
              position: 'top',
              content: ({ value }) => {
                if (value === 0) {
                  return '';
                }
                return value;
              },
            },
            yAxis: {
              type: 'linear',
              max: maxHrsValue,
              tickInterval: maxHrsValue / 5,
              position: 'left',
              title: {
                text: 'Hours',
                position: 'center',
                style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
              },
            },
            isGroup: true,
            seriesField: 'type',
            columnWidthRatio: 0.6,
            color: ['#71AFFF', '#77C172'],
          },
        },
        {
          type: 'column',
          options: {
            data: acceptedPts,
            xField: 'time',
            yField: 'value',
            label: {
              position: 'top',
              labelLine: null,
              content: ({ value }) => {
                if (value === 0) {
                  return '';
                }
                return value;
              },
            },
            yAxis: {
              type: 'linear',
              max: maxPtsValue,
              tickInterval: maxPtsValue / 5,
              position: 'right',
              title: {
                position: 'center',
                text: 'Points',
                style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
              },
            },
            isGroup: true,
            seriesField: 'type',
            columnWidthRatio: 0.6,
            color: ['#71AFFF', '#77C172'],
          },
        },
        {
          type: 'line',
          options: {
            data: lineData,
            xField: 'time',
            yField: 'value',
            xAxis: false,
            yAxis: {
              type: 'linear',
              max: maxHrsValue,
              tickInterval: maxHrsValue / 5,
              position: 'left',
            },
            meta: {
              date: {
                sync: 'date',
              },
            },
            smooth: true,
            point: {
              shape: 'circle',
              size: 3,
            },
            seriesField: 'type',
            color: '#0066FF',
          },
        },
      ],
    };
  };

  return !data?.dataPoints?.length ? (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  ) : (
    <PlotMix {...generatePlotMixConfig(data)} />
  );
};
const customTargetLegend: LegendItem[] = [
  {
    name: TooltipLabelConfig['task'],
    value: TooltipLabelConfig['task'],
    marker: {
      symbol: 'circle',
      style: {
        fill: '#71AFFF',
      },
    },
  },
  {
    name: TooltipLabelConfig['accepted'],
    value: TooltipLabelConfig['accepted'],
    marker: {
      symbol: 'circle',
      style: {
        fill: '#77C172',
      },
    },
  },
  {
    name: TooltipLabelConfig['ideal'],
    value: TooltipLabelConfig['ideal'],
    marker: {
      symbol: 'circle',
      style: {
        fill: '#0066ff',
      },
    },
  },
];

import { monitorSection, overviewSection, planSection, reportOptions } from 'app/types/DetailSections';
import { SubPage } from 'app/types/TeamDetail';

export const ACCESS_TOKEN_KEY = 'access_token';
export const USER_PAGE_NUMBER = 20;
export const CERTIFICATE_PAGE_NUMBER = 15;
export const CONTENT_PAGE_NUMBER = 15;
export const PROGRAM_OVERVIEW_BEST_PERFORMER_NUMBER = 8;
export const MAX_LENGTH_DEMO_PLAN = 2000;

export const TABLE_DEFAULT_PAGE_INDEX = 1;
export const TABLE_DEFAULT_PAGE_SIZE = 20;
export const REQUEST_PAGE_NUMBER = 20;
export const DRAFT_CONSTANT = 'export constant here';
export const COLOR_PRIMARY = '#024fa5';

export const OPEN_STATE = 'Open';
export const SUBMITTED_STATE = 'Submitted';
export const FIXED_STATE = 'Fixed';
export const CLOSED_STATE = 'Closed';
export const CLOSE_DECLINED_STATE = 'Closed Declined';

export const OPEN_STATE_COLOR = '#FF8A8A';
export const FIXED_STATE_COLOR = '#B1B1B1';
export const SUBMITTED_STATE_COLOR = '#71AFFF';
export const CLOSED_STATE_COLOR = '#77C172';
export const CLOSE_DECLINED_STATE_COLOR = '#BEDAFF';

export const ACCEPTED = '#3A874F';
export const NOT_ACCEPTED = '#F47168';
export const AVG_ACCEPTED = '#4d9ae8';
export const TOTAL_ACCEPTED = '#42e0f5';

export const None = '#5B8FF9';
export const Architecture = '#CDDDFD';
export const CantReproduce = '#5AD8A6';
export const CodeChange = ' #CDF3E4';
export const ConfigurationChange = ' #5D7092';
export const Converted = '#CED4DE';
export const DatabaseChange = '#F6BD16';
export const Duplicate = '#FCEBB9';
export const NeedMoreInformation = '#6F5EF9';
export const NotADefect = '#D3CEFD';
export const SoftwareLimitation = '#6DC8EC';
export const UserInterface = '#D3EEF9';
export const WontFix = '#945FB9';

export const AmbiguousRequirement = '#5B8FF9';
export const BranchEscapes = '#CDDDFD';
export const CodeDefects = '#5AD8A6';
export const ConfigurationRoot = '#CDF3E4';
export const CouldNotDetermine = '#5D7092';
export const Environment = '#CED4DE';
export const ExternalRoot = '#F6BD16';
export const FormsDefect = '#FCEBB9';
export const IncorrectImpactAnalysis = '#6F5EF9';
export const NeverWorked = '#D3CEFD';
export const NonDefect = '#6DC8EC';
export const NotApplicable = '#D3EEF9';
export const Observation = '#945FB9';
export const PostRefresh = '#DECFEA';
export const QATestDeficiency = '#FF9845';
export const Regression = '#FFE0C7';

export const DUPLICATE_CERTIFICATE_MESSAGE = 'Certificate name already exist.';
export const GENERIC_MESSAGE = 'There has been an error processing your request.';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_CREATE_FORMAT = 'HH:mmA DD/MM/YYYY';
export const MONTH_PICKER_FORMAT = 'MMMM YYYY';
export const DATE_ID_FORMAT = 'YYYYMM';
export const MIN_DATE_ID = new Date(2020, 0);

export const MONTH_FORMAT = 'YYYY-MM';

export const MONTH_YEAR_FORMAT = 'MM/YYYY';

export const QUARTER_FORMAT = 'YYYY-\\QQ';
export const YEAR_FORMAT = 'YYYY';

export const NUMBER_REGEX_FORMAT = /\B(?=(\d{3})+(?!\d))/g;
export const PARSE_NUMBER_REGEX_FORMAT = /\$\s?|(,*)/g;

export const ACCEPT_TYPE_UPLOAD: string = '.png, .jpg, .jpeg';

export const TOPCOLORS = {
  // eslint-disable-next-line prettier/prettier
  '1': 'gold',
  '2': '#C0C0C0',
  '3': '#cd7f32',
};

export const REWARDTYPECOLORS = {
  // eslint-disable-next-line prettier/prettier
  '1': 'pink',
  '2': 'geekblue',
  '3': 'lime',
  '4': 'purple',
  '5': 'orange',
};

export const awardSearchType = {
  // eslint-disable-next-line prettier/prettier
  '1': 'members',
  '2': 'projects',
};

export const teamRoles = [
  { code: 1, name: 'Scrum Master' },
  { code: 2, name: 'BA' },
  { code: 3, name: 'TA' },
  { code: 4, name: 'Dev' },
  { code: 5, name: 'QA' },
  { code: 6, name: 'DBA' },
  { code: 7, name: 'SRE' },
  { code: 8, name: 'TSL' },
  { code: 9, name: 'TL' },
];

export const scheduleStates = [
  { order: 1, name: 'Idea' },
  { order: 2, name: 'Defined' },
  { order: 3, name: 'In-Progress' },
  { order: 4, name: 'Completed' },
  { order: 5, name: 'Accepted' },
  { order: 6, name: 'Released' },
];

export const roleNames = {
  admin: 'admin',
  user: 'user',
  scrumMaster: 'scrum_master',
  pqa: 'pqa',
};

export const regexFullName =
  /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s|_\-().']+$/;

export const Todo = '#e0e0df';
export const InProgress = '#fcbc2d';
export const Done = '#0d9f3f';

export const IdeaChartColor = '#B1B1B1';
export const DefinedChartColor = '#D7E7FD';
export const InProgressChartColor = '#F3C300';
export const CompletedChartColor = '#77C172';
export const AcceptedChartColor = '#2785FF';
export const ReleasedChartColor = '#0E50A4';

export const FeatureChartColor_Intake = '#F9F9F9';
export const FeatureChartColor_IdeaPrioritization = '#D9D9D9';
export const FeatureChartColor_ProblemDiscovery = '#B1B1B1';
export const FeatureChartColor_SolutionDiscovery = '#707070';
export const FeatureChartColor_FeaturePrioritization = '#00AEBB';
export const FeatureChartColor_Developing = '#F3C300';
export const FeatureChartColor_Accepted = '#77C172';
export const FeatureChartColor_Measuring = '#71AFFF';
export const FeatureChartColor_Done = '#2785FF';
export const FeatureChartColor_Cancelled = '#FF8A8A';

export const CHART_COLOR_LESS_THAN_50 = '#86B4FF';
export const CHART_COLOR_50_TO_80 = '#86B4FF';
export const CHART_COLOR_80_TO_100 = '#0D61EA';

export const PROGRESS_BELOW_TARGET__COLOR = '#86B4FF';
export const PROGRESS_ON_TARGET__COLOR = '#267cff';
export const PROGRESS_GRADIENT_START__COLOR = '#d8e8ff';

export const ErrorType = 'error';
export const SuccesType = 'success';

export const FieldRequiredText = 'This field is required';

export const ITERATION_CARRIED_OUT_COLOR = '#FF5A5A';
export const ITERATION_KEEP_COLOR = '#FFE787';

export const PROGRESS_COLOR = {
  start: '#d8e8ff',
  end: '#0066ff',
  over: '#ff4d4d',
};
export const PROGRESS_BAR_MIN_COLOR = '#d8e8ff';
export const PROGRESS_BAR_MAX_COLOR = '#0066ff';

export const TESTCASE_PASS_COLOR = '#77C172';
export const TESTCASE_FAIL_COLOR = '#FF0000';
export const TESTCASE_BLOCKED_COLOR = '#707070';
export const TESTCASE_NOT_RUN_COLOR = '#B4B4B4';
export const TESTCASE_ERROR_COLOR = '#FF8A8A';
export const TESTCASE_INCONCLUSIVE_COLOR = '#00AEBB';
export const TESTCASE_NOT_APPLICABLE_COLOR = '#0E50A4';

export const DefectColor = '#F3C300';
export const UserStoriesColor = '#0066FF';
export const HighlighterColor = '#F3C300';

export const PlannedCapacityColor = '#2785FF';
export const TotalCapacityColor = '#B5C5DC';

export const PlannedVelocityColor = '#2785FF';
export const TotalVelocityColor = '#B5C5DC';

export const DefaultStatusColor = '#D9D9D9';
export const CriticalStatusColor = '#FF0000';
export const RiskStatusColor = '#F3C300';
export const GoodStatusColor = '#098A00';

export const CANCEL_BUTTON = '#d9d9d9';

export const SEARCHED_TEXT = '#f50';

export const InovationHigherTargetColor = '#098A00';
export const InovationLowerTargetColor = '#FF8A8A';

export enum ChartTargetValue {
  Higher,
  Lower,
}

export const ChartTargetColors = {
  [ChartTargetValue.Higher]: '#098A00',
  [ChartTargetValue.Lower]: '#FF8A8A',
};

export const TitleChartStyle = {
  fontSize: 16,
  r: 120,
  fontWeight: 700,
};

export const LabelTrendsChart = {
  rotate: -Math.PI / 6,
  offset: 20,
  style: {
    fontSize: 12,
  },
};
export const GroupedTeamLinks = ['', 'portfolio', 'delivery-stream', 'release-train', 'teams'];

export const PercentGrowthUpColor = '#0ca801';
export const PercentGrowthDownColor = '#ff0000';
export const UnfillProgressBarColor = '#ecf0f1';
export const DEFAULT_MEMBER_ID = 1;
export const AttendanceMark = {
  header: '#fafafa',
  normal: '#ffff',
  red: '#FFC9C9',
  yellow: '#FCFF7E',
  pink: '#FFBEF5',
  blue: '#A8E0FF',
  grey: '#CFCFCF',
  pinkDefault: '#ffd6f3',
};

export const SyncStatus = {
  INPROGRESS: 'In Progress',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
};

// Ownership
export const OwnershipClientSatisfactionTitle = {
  notApplicable: 'Not Applicable',
  notSatisfied: 'Not Satisfied',
  someWhatSatisfied: 'Somewhat Satisfied',
  satisfied: 'Satisfied',
  notAvailable: 'N/A',
};
export const OwnershipStatusTitle = {
  notApplicable: 'Not Applicable',
  atRisk: 'At Risk',
  caution: 'Caution',
  satisfied: 'Satisfied',
  notAvailable: 'N/A',
};
export const OwnershipSLARateTitle = {
  high: '>= 95% on-time tickets',
  medium: '85% < = on-time tickets <95%',
  low: '< 85 % on-time tickets',
  notTracking: 'No Ticket/SLA tracking',
  notAvailable: 'N/A',
};
export const OwnershipLevelTitle = {
  none: 'None',
  level_0: 'Level 0',
  level_1: 'Level 1',
  level_2: 'Level 2',
  level_3: 'Level 3',
  level_4: 'Level 4',
  not_yet: 'Not Yet',
};
export const OwnershipProdIssueTitle = {
  noIssue: 'No issue',
  notAvailable: 'N/A',
  rollbackIssue: 'Has P1/P2/rollback issue (s)',
  noProductionsupport: 'No production Support',
};
export const OwnershipEvaluationTitle = {
  clientSatisfaction: 'Client Satisfaction',
  ownershipLevel: 'Maturity Level',
  SLARate: 'SLA Satisfaction Rate',
  ProdIssue: '# of Production Issue',
};
export const OwnershipColor = {
  notApplicable: '#D9D9D9',
  atRisk: '#FF8A8A',
  caution: '#D1BF21',
  satisfied: '#098A00',
  completed: '#0066FF',
};
export const OwnershipLevelcolor = {
  none: '#D9D9D9',
  level_0: '#FFA685',
  level_1: '#77C172',
  level_2: '#098A00',
  level_3: '#71AFFF',
  level_4: '#0066FF',
  not_yet: '#FFA685',
};
export const OwnershipLevelColors = ['#ffa685', '#71afff', '#0066ff', '#77c172', '#098a00', '#1a9641'];

export const OwnershipChartColor = {
  lineColor: '#74bd6f',
  barColor: '#0066ff',
  barBackground: '#D9D9D9',
};

export const OwnershipTableCellColor = {
  default: '#d9d9d9',
  white: '#ffffff',
  levelPassed: '#098a00',
  levelNotReached: '#ff8a8a',
};

export const FilterTeam = [
  'Banana - F&I',
  'Booster - COR',
  'Client Services SRE - COR',
  'Damage Reference Data Mgmt - CIO',
  'Data Whisperers - F&I',
  'Explorers - COR',
  'Fast & Furious - F&I',
  'LINK - MDM',
  'Mandalorians - MDM',
  'Midnight Sun - FIN',
  'Platform Enablement - CE',
  'Seekers - COR',
  'SHIELD - LOC',
  'Team X (Research, Editorial, mApps, CMS) - FD&P',
  'The Fantastics - EC',
  'Throughput - MDM',
  'Velocity - MDM',
];

export const ProgramKanbanOverviewPageSize = 24;

export const GuidelineAndFaqs = {
  guideline: 0,
  faq: 1,
};

export const TabOptions = {
  [SubPage.Plan]: planSection,
  [SubPage.Monitoring]: monitorSection,
  [SubPage.Report]: reportOptions,
  [SubPage.Overview]: overviewSection,
};

export const MobileBreakPoint = 1293;

export const GROWING_COLOR = '#FFD700';
export const BUILDING_COLOR = '#ff8800';
export const SOLID_COLOR = '#37b551';
export const STRONG_COLOR = '#2fabe7';
export const LEADING_COLOR = '#92278f';

import { RefAttributes, useContext, useEffect, useMemo, useState } from 'react';
import { Column as PlotChart, ColumnConfig } from '@ant-design/plots';
import { Col, Empty, Radio, Row } from 'antd';
import { SelectOptions } from 'app/types/entity';
import _ from 'lodash';
import { PlanVelocity, GroupedTeamPlanVelocityChartStatistic } from 'app/types/GroupedTeamModel';
import { PlannedVelocityColor, TotalVelocityColor } from 'app/common/constants';
import { TeamParentContext } from 'app/contexts/TeamParentContext';

export const PlanGroupedTeamVelocity = () => {
  const [velocities, setVelocities] = useState<PlanVelocity[]>();
  const [velocityStatistic, setVelocityStatistic] = useState<GroupedTeamPlanVelocityChartStatistic>();
  const { iterationData, loading } = useContext(TeamParentContext);

  useEffect(() => {
    setVelocityStatistic(iterationData?.planData?.planVelocityStatistic);
    setVelocities(iterationData?.planData?.planVelocity);
  }, [iterationData]);

  const velocitySummary = useMemo(() => {
    // Use .slice to get last 3 velocities (assume it's ASC sorting)
    const data = velocities?.filter((p) => p.state === 'Planned Velocity').slice(-3) || [];
    const avg = _.mean(data.map((dt: PlanVelocity) => dt.point | 0));
    const temp: SelectOptions<any>[] = [
      {
        label: `Last 3: ${Math.round(avg * 10) / 10} points`,
        value: 1,
      },
      {
        label: `Best 3: ${velocityStatistic?.best3} points`,
        value: 2,
      },
      {
        label: `Worst 3: ${velocityStatistic?.worst3} points`,
        value: 3,
      },
    ];
    return temp;
  }, [velocities]);

  const config = useMemo(() => {
    const data = velocities?.map((v) => ({ code: v.code, value: v.point, state: v.state })) || [];

    const config: ColumnConfig & RefAttributes<PlanVelocity> = {
      data: data,
      xField: 'code',
      yField: 'value',
      // renderer: 'svg',
      seriesField: 'state',
      columnWidthRatio: 0.5,
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      yAxis: {
        title: {
          text: 'Points',
          position: 'center',
          spacing: 20,
          style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
        },
      },
      autoFit: true,
      meta: {
        code: {
          alias: 'Iteration Name',
        },
        value: {
          alias: 'Planned Velocity',
        },
      },
      legend: false,
      color: [TotalVelocityColor, PlannedVelocityColor],
      loading: loading,
    };

    return config;
  }, [velocities]);

  return (
    <Row>
      <Col span={24}>
        <div className="container">
          <Row className="" justify="center">
            <Radio.Group options={velocitySummary} optionType="button" buttonStyle="solid" />
          </Row>
          <Row style={{ marginTop: '25px' }}>
            <Col span={24}>
              <PlotChart {...config} />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

import { DualAxes, DualAxesConfig } from '@ant-design/plots';
import { OwnershipChartColor } from 'app/common/constants';
import { OwnershipScoreLevel } from 'app/types/Ownership';
import { useMemo } from 'react';

interface IProps {
  dataSource: OwnershipScoreLevel[];
}

export const OwnershipScoreByLevelChart = ({ dataSource }: IProps) => {
  const chartData = useMemo(() => dataSource ?? [], [dataSource]);

  const columnData = chartData.map((x) => ({ Level: `Level ${x.level}`, Score: Math.round(x.scorePercent) }));

  const lineData = chartData.map((x) => ({ Level: `Level ${x.level}`, Target: Math.round(x.scoreTarget?.from ?? 0) }));

  const chartConfig: DualAxesConfig = {
    legend: {
      position: 'top',
    },
    data: [columnData, lineData],
    xField: 'Level',
    yField: ['Score', 'Target'],
    meta: {
      Score: {
        formatter: (value) => `${value}%`,
      },
      Target: {
        formatter: (value) => `${value}%`,
      },
    },
    yAxis: [
      {
        type: 'linear',
        min: 0,
        max: 100,
        position: 'left',
        grid: {
          line: {
            style: {
              stroke: 'transparent',
            },
          },
        },
      },
      { type: 'linear', min: 0, max: 100, position: 'left' },
    ],
    padding: [35, 20, 20, 20],
    geometryOptions: [
      {
        geometry: 'column',
        color: OwnershipChartColor.barColor,
        columnStyle: {
          fill: OwnershipChartColor.barColor,
          shadowColor: OwnershipChartColor.barColor,
        },
        columnWidthRatio: 0.3,
      },
      {
        geometry: 'line',
        color: OwnershipChartColor.lineColor,
      },
    ],
  };

  return <DualAxes {...chartConfig} />;
};

/* eslint-disable array-callback-return */
import { RetrospectiveNoteRequest, RetrospectiveNoteResponse, RetrospectiveType } from 'app/types/entity';
import { fetchAsync } from 'app/utils/fetch';

export const getRetrospectiveNote = (
  iterationId: number,
  types: RetrospectiveType[]
): Promise<RetrospectiveNoteResponse[]> => {
  let url = `/api/retrospectivenotes?iterationId=${iterationId}`;
  types?.map((x) => {
    url = url.concat(`&types=${x}`);
  });
  return fetchAsync(url, {
    method: 'GET',
  });
};

export const createNote = (action: RetrospectiveNoteRequest): Promise<RetrospectiveNoteResponse> => {
  return fetchAsync(`/api/retrospectivenotes`, {
    method: 'POST',
    body: action,
  });
};

export const updateNote = (action: RetrospectiveNoteRequest): Promise<RetrospectiveNoteResponse> => {
  return fetchAsync(`/api/retrospectivenotes/${action.id}`, {
    method: 'PUT',
    body: action,
  });
};

export const deleteNote = (id: number): Promise<void> => {
  return fetchAsync(`/api/retrospectivenotes/${id}`, {
    method: 'DELETE',
  });
};

import { IImageFileForEditorResponse } from 'app/types/ImageFileForEditor';
import { IIssueReportIdeaRequest, IIssueReportIdeaResponse } from './../types/IssueReportIdeaModel';
import { fetchAsync } from './../utils/fetch';

export const getIssueReportIdeas = (): Promise<IIssueReportIdeaResponse[]> => {
  var url = '/api/issuereportidea/getall';
  return fetchAsync(url, { method: 'GET' });
};

export const createIssueReportIdea = (payload: IIssueReportIdeaRequest): Promise<IIssueReportIdeaResponse> => {
  const data = new FormData();
  data.append('name', payload.name);
  data.append('type', payload.type.toString());
  data.append('status', '0');
  data.append('detail', payload.detail);

  payload.uploadedImages?.forEach((x) => {
    data.append('uploadedImages', x);
  });
  const url = '/api/issuereportidea';
  return fetchAsync(url, { type: 'text', method: 'POST', body: data });
};

export const editIssueReportIdea = (payload: IIssueReportIdeaRequest): Promise<IIssueReportIdeaResponse> => {
  const data = new FormData();
  data.append('id', payload.id.toString());
  data.append('name', payload.name);
  data.append('type', payload.type.toString());
  data.append('status', payload.status.toString());
  data.append('detail', payload.detail);
  data.append('issueReportIdeaImages', JSON.stringify(payload.issueReportIdeaImages));
  data.append('feedback', payload.feedback);
  payload.uploadedImages?.forEach((x) => {
    data.append('uploadedImages', x);
  });

  const url = '/api/issuereportidea';
  return fetchAsync(url, { type: 'text', method: 'PUT', body: data });
};

export const uploadImageEditor = (file: any): Promise<IImageFileForEditorResponse> => {
  const formData = new FormData();
  formData.append('uploadedImage', file);

  return fetchAsync('/api/issuereportidea/upload-image-editor', {
    type: 'text',
    method: 'POST',
    body: formData,
  });
};

export const deleteIssueReportIdea = (id: number): Promise<IIssueReportIdeaResponse> => {
  const url = `/api/issuereportidea/${id}`;
  return fetchAsync(url, { method: 'DELETE' });
};

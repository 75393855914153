import { Key } from 'react';
import { ActualCapacityResponse, DeliveryResponse } from './CacheReportModel';
import { IterationScheduleStateResponse } from './IterationScheduleStateResponse';
export class ProgramOverviewChart {
  chartData: DeliveryResponse = { total: 0, delivered: 0 };
  percentChange: number = 0;
}

export class ProgramUtilizedCapacity {
  chartData: ActualCapacityResponse = { total: 0, actual: 0, plan: 0, todo: 0 };
  percentChange: number = 0;
}

export class ProgramOverviewProgressDataItem {
  maxValue: number = 0;
  currentValue: number = 0;
  percentChange: number = 0;
}

export class UserStoryStateModel {
  dateId: string = '';
  accepted: number = 0;
  completed: number = 0;
  defined: number = 0;
  idea: number = 0;
  inProgress: number = 0;
  released: number = 0;
}

export class TaskStateModel {
  state: string = '';
  tasks: number = 0;
  dateId: string = '';
}

export class ProgramOverviewBestPerformer {
  userId: number = 0;
  imageUrl: string = '';
  name: string = '';
  teamName: string = '';
  caption: string = '';
}

export class ProgramOverviewCarriedOver {
  userStories: number = 0;
  defects: number = 0;
  percentChange: number = 0;
}

export class ProgramOverviewDataResponse {
  programDelivery: ProgramOverviewChart = new ProgramOverviewChart();
  utilizedCapacity: ProgramUtilizedCapacity = new ProgramUtilizedCapacity();
  programScheduleState: IterationScheduleStateResponse[] = [];
  storyPoint: ProgramOverviewProgressDataItem = new ProgramOverviewProgressDataItem();
  userStory: ProgramOverviewProgressDataItem = new ProgramOverviewProgressDataItem();
  testCoverage: ProgramOverviewProgressDataItem = new ProgramOverviewProgressDataItem();
  cardsCarriedOver: ProgramOverviewCarriedOver = new ProgramOverviewCarriedOver();
  cfd_UserStory: UserStoryStateModel[] = [];
  cfd_Tasks: TaskStateModel[] = [];
  bestPerformers: ProgramOverviewBestPerformer[] = [];
}

export class ProgramOverviewTeamData {
  teamId: number = 0;
  name: string = '';
  bestPerformer: string = '';
  teamDelivery: number = 0;
  velocity: number = 0;
  demoItems: number = 0;
  utiliezedCapacity: number = 0;
  carriedOverStory: number = 0;
  carriedOverDefect: number = 0;
  CarriedOverTestSuite: number = 0;
  commited: number = 0;
  delivered: number = 0;
  iterationGoalPass: number = 0;
  iterationGoalFail: number = 0;
  iterationGoalNone: number = 0;
}

export class ProgramOverviewKanbanDataResponse {
  data: ProgramOverviewTeamData[] = [];
  totalPage: number = 0;
}

export class ProgramOverviewKanbanDataRequest {
  teamIds: Key[] = [];
  hasCarriedOver: boolean = false;
  hasFailedGoal: boolean = false;
  hasUtilizedCapacity: number = null;
  pageNumber: number = 1;
  pageSize: number = 1;
}

import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ExportType } from 'app/types/TeamDetail';
import { useState } from 'react';

import './DownloadReport.scss';
import { DownloadReportForm } from './DownloadReportForm';

interface IProps {
  exportReportHandler: (formValues, exportType: ExportType) => void;
  exportProcessing: boolean;
}
export const DownloadReport = ({ exportReportHandler, exportProcessing }: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportExportType, setReportExportType] = useState<ExportType>();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menuItemClick = (exportType: ExportType) => {
    setReportExportType(exportType);
    showModal();
  };

  const exportMenuItems = (
    <Menu className="export-menu-items">
      <Menu.Item onClick={() => menuItemClick(ExportType.PDF)}>Document (PDF)</Menu.Item>
      <Menu.Item onClick={() => menuItemClick(ExportType.Image)}>Image (PNG)</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown.Button
        data-html2canvas-ignore
        className="export-button"
        type="ghost"
        disabled={exportProcessing}
        icon={<DownOutlined />}
        overlay={exportMenuItems}
      >
        {exportProcessing ? <Spin size="small" style={{ marginRight: '10px' }} /> : <DownloadOutlined />}
        Download Report
      </Dropdown.Button>

      <Modal
        className="download-report-modal"
        title="CREATE REPORT"
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
      >
        <DownloadReportForm
          exportReportHandler={async (values) => {
            await exportReportHandler(values, reportExportType);
            setIsModalVisible(false);
          }}
          onCloseModal={handleCancel}
        />
      </Modal>
    </>
  );
};

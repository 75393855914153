import { QuarterReleasePlanDrillDownPlot } from 'app/types/TeamDetail';
import { UncompletionReportData } from 'app/types/UncompletionReport';
import {
  HighlightRequest,
  IssueResponse,
  TeamIssueOverviewMiniCard,
  WeeklyOverviewData,
} from 'app/types/WeeklyOverview';
import { fetchAsync } from 'app/utils/fetch';
import { IQualitySummaryRequest, IQualitySummaryResponse } from './../types/entity';

export const updateQualitySummary = (id: number, action: IQualitySummaryRequest): Promise<IQualitySummaryResponse> => {
  return fetchAsync(`/api/iterationreport/${id}`, {
    method: 'PUT',
    body: action,
  });
};

export const getWeeklyStatusOverview = (startDateOfWeek: Date): Promise<WeeklyOverviewData> => {
  return fetchAsync(`/api/weeklyoverview/getDetailOverview/${startDateOfWeek.toUTCString()}`, {
    method: 'GET',
  });
};

export const createWeeklyStatusPageData = (startDateOfWeek: Date): Promise<WeeklyOverviewData> => {
  return fetchAsync(`/api/weeklyoverview/createOverviewForWeek/${startDateOfWeek.toUTCString()}`, {
    method: 'POST',
  });
};

export const updateHighlight = (data: HighlightRequest): Promise<any> => {
  return fetchAsync(`/api/weeklyoverview/updateHighlighNote`, {
    method: 'PUT',
    body: data,
  });
};

export const getTeamIssueOverviewItemsForYear = (teamId: number): Promise<TeamIssueOverviewMiniCard[]> => {
  return fetchAsync(`/api/weeklyoverview/getTeamIssueOverviewItemsForYear/${teamId}`, {
    method: 'GET',
  });
};

export const getTeamIssueDetailsForWeek = (teamWeeklyStatusId: number, teamId: number): Promise<IssueResponse[]> => {
  return fetchAsync(`/api/weeklyoverview/getTeamIssueDetailsForWeek/${teamWeeklyStatusId}/${teamId}`, {
    method: 'GET',
  });
};

export const createTeamIssueForWeek = (data: IssueResponse): Promise<any> => {
  return fetchAsync(`/api/weeklyoverview/createNewIssue`, {
    method: 'POST',
    body: data,
  });
};
export const updateTeamIssueForWeek = (data: IssueResponse): Promise<any> => {
  return fetchAsync(`/api/weeklyoverview/editIssue/${data.id}`, {
    method: 'PUT',
    body: data,
  });
};
export const removeTeamIssueForWeek = (issueId: number): Promise<any> => {
  return fetchAsync(`/api/weeklyoverview/deleteIssue/${issueId}`, {
    method: 'DELETE',
  });
};
export const getQuarterReleasePlanData = (
  teamId: number,
  year: number,
  month: number
): Promise<QuarterReleasePlanDrillDownPlot> => {
  return fetchAsync(`/api/iterationreport/quarterReleasePlan/${teamId}/${year}/${month}`, {
    method: 'GET',
  });
};

export const fetchUncompletionReport = (iterationName: string): Promise<UncompletionReportData[]> =>
  fetchAsync(`/api/iterationreport/uncompletionReport/${iterationName}`);

import styled from 'styled-components';
import { Form } from 'antd';

export const OrganizationFormWrapper = styled(Form)`
  .ant-input {
    &.half-width {
      width: 50%;
    }
  }
  .btn-link {
    padding-left: 0;
  }
  .editor-wrapper {
    border: 1px solid #d9d9d9;
  }
  .editor-box {
    padding: 5px 20px;
    min-height: 100px;
  }
`;

import { Area, AreaConfig } from '@ant-design/charts';
import { Empty } from 'antd';
import {
  AcceptedChartColor,
  CompletedChartColor,
  DefinedChartColor,
  IdeaChartColor,
  InProgressChartColor,
  ReleasedChartColor,
} from 'app/common/constants';

import { IDataChartProps } from 'app/types/CacheReportModel';
import { useEffect, useState } from 'react';
import { LegendItem } from '@antv/g2/esm/interface';
import { UserStoryStateModel } from 'app/types/ProgramOverviewModel';

export const CfdUserStory = ({ dataSource }: IDataChartProps) => {
  const [chartData, setChartData] = useState<UserStoryStateModel[]>([]);
  useEffect(() => {
    setChartData(dataSource);
  }, [dataSource]);

  let data: { time: string; value: number; type: string }[] = [];
  if (chartData && chartData.length > 0) {
    for (var i = 0; i < chartData.length; i++) {
      let date = chartData[i].dateId;
      data.push({
        time: date,
        value: chartData[i]?.idea,
        type: 'Idea',
      });
      data.push({
        time: date,
        value: chartData[i]?.defined,
        type: 'Defined',
      });
      data.push({
        time: date,
        value: chartData[i]?.inProgress,
        type: 'In Progress',
      });
      data.push({
        time: date,
        value: chartData[i]?.completed,
        type: 'Completed',
      });
      data.push({
        time: date,
        value: chartData[i]?.accepted,
        type: 'Accepted',
      });
      data.push({
        time: date,
        value: chartData[i]?.released,
        type: 'Released',
      });
    }
  }

  let stepInterval = 0;
  let maxValue = 0;
  if (data && data.length > 0) {
    maxValue = Math.max(
      ...data
        .filter((x) => x.value)
        .map((object) => {
          return object.value;
        })
    );
    stepInterval = Math.ceil((maxValue * 1.1) / 5);
  }

  const config: AreaConfig = {
    data,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    width: 500,
    height: 300,
    color: [
      IdeaChartColor,
      DefinedChartColor,
      InProgressChartColor,
      CompletedChartColor,
      AcceptedChartColor,
      ReleasedChartColor,
    ],
    legend: {
      position: 'top',
      flipPage: false,
      items: customTargetLegend,
    },
    yAxis: {
      title: {
        text: 'User Stories',
        position: 'center',
        spacing: 20,
        style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
      },
      tickInterval: stepInterval,
    },
    xAxis: {
      range: [0, 1],
    },
    areaStyle: () => {
      return {
        fillOpacity: 1,
      };
    },
  };
  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return <Area {...config} />;
};

export const customTargetLegend: LegendItem[] = [
  {
    name: 'Idea',
    value: 'Idea',
    marker: {
      symbol: 'circle',
      style: {
        fill: IdeaChartColor,
      },
    },
  },
  {
    name: 'Defined',
    value: 'Defined',
    marker: {
      symbol: 'circle',
      style: {
        fill: DefinedChartColor,
      },
    },
  },
  {
    name: 'In Progress',
    value: 'In Progress',
    marker: {
      symbol: 'circle',
      style: {
        fill: InProgressChartColor,
      },
    },
  },
  {
    name: 'Completed',
    value: 'Completed',
    marker: {
      symbol: 'circle',
      style: {
        fill: CompletedChartColor,
      },
    },
  },
  {
    name: 'Accepted',
    value: 'Accepted',
    marker: {
      symbol: 'circle',
      style: {
        fill: AcceptedChartColor,
      },
    },
  },
  {
    name: 'Released',
    value: 'Released',
    marker: {
      symbol: 'circle',
      style: {
        fill: ReleasedChartColor,
      },
    },
  },
];

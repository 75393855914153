import { Tag } from 'antd';
import './TeamDetailLinkTags.scss';
import { fetchTeamsByTeamName } from 'app/apis/teamClient';
import { fetchIterations } from 'app/apis/teamDetailClient';

interface IDependencyTag {
  datasource: string[];
}

const COLORS = [
  'darkmagenta',
  'darkred',
  'darkorange',
  'goldenrod',
  'olivedrab',
  'forestgreen',
  'teal',
  'darkcyan',
  'navy',
  'indigo',
  'rebeccapurple',
];

export const TeamDetailLinkTags = ({ datasource }: IDependencyTag) => {
  const onTagClick = async (item: string) => {
    const teams = await fetchTeamsByTeamName(item);
    const iterationIds = await fetchIterations(teams[0].id);
    const url = `/teams/${teams[0].id}?iteration=${iterationIds[1].id}#page=Overview`;
    window.open(url, '_blank');
  };

  if (datasource.length === 0) return <div>{`Currently, no team is using this tag`}</div>;
  return (
    <>
      <h3>The following is a list of teams that are currently using this tag:</h3>
      <div className="tags-container">
        {datasource?.map((item) => (
          <Tag key={item} onClick={() => onTagClick(item)} color={COLORS[Math.floor(Math.random() * COLORS.length)]}>
            {item}
          </Tag>
        ))}
      </div>
    </>
  );
};

import { Modal, Row } from 'antd';
import { AttendanceMark } from 'app/common/constants';
import styled from 'styled-components';

export const AttendanceWrapper = styled.div`
  .table-container {
    width: 100%;
    overflow-x: scroll;
    table {
      width: 100%;
      thead {
        background-color: #fafafa;
      }
      tr {
        td:first-child {
          text-align: center;
        }
        td:nth-child(2),
        th:nth-child(2) {
          text-align: left;
        }
      }
    }
    table,
    th,
    td {
      border: 1px solid #f0f0f0;
      border-collapse: collapse;
      padding: 10px 8px;
    }
    .custom {
      th {
        text-align: center;
      }
    }
  }
`;
export const BtnGroups = styled(Row)`
  .edit-btn {
    font-size: 16px;
    padding: 0;
    height: 35px;
    width: 90px;
    border-radius: 3px;
  }
  .edit-btn:last-child {
    margin-left: 0.5rem;
  }
`;

export const AttendanceCell = styled.td`
  &.editable:hover {
    cursor: pointer;
    filter: brightness(90%);
  }
  color: #ffff;
  text-align: center;
  background-color: ${(props) => props.color};
`;
export const AttendanceHeaderCell = styled.th`
  &.editable:hover {
    cursor: pointer;
  }
  background-color: ${(props) => props.color};
  text-align: center;
  position: relative;
  &::before {
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    content: '';
    background-color: ${(props) => props.color};
    filter: ${(props) => (props.color === AttendanceMark.header ? 'brightness(100%)' : 'brightness(50%)')};
    clip-path: polygon(100% 0, 100% 100%, 0 0);
    width: 20%;
    height: 20%;
  }
`;

export const AssignModal = styled(Modal)`
  .title-modal {
    text-align: center;
  }
`;

export const LegendItem = styled(Row)`
  margin-bottom: 1rem;
  .item {
    display: flex;
    align-items: center;
    vertical-align: middle;
    p {
      margin-bottom: 2px;
      font-size: 16px;
      margin-left: 0.5rem;
    }
  }
`;

export const PopoverContent = styled.div`
  .description-text {
    p {
      font-style: italic;
    }
  }
  max-width: 500px;
`;

export const ColorRadio = styled.div`
  border: 1px solid ${(props) => props.color};
  width: 16px;
  height: 16px;
  position: relative;
  background-color: ${(props) => props.color};
  outline: 4px double #cfcfcf;
  margin-right: 1.5rem;
  cursor: pointer;
  &::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: block;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #000;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 0;
    content: ' ';
  }
  &.checked {
    outline: 4px double ${(props) => props.color};
  }
  &.checked::after {
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
`;

import { Helmet } from 'react-helmet';
import { GettingStartedForm, GettingStartedFormEnum } from '../components/GettingStartedForm';
import './GettingStarted.scss';
import { useLocation } from 'react-router-dom';
import { UserStatusEnum } from 'app/types/user';

export const GettingStarted = () => {
  const location = useLocation();
  const userStatus = Number(location.state['userStatus']);
  const isSSO = Boolean(location.state['isSso']);

  const getFormType = () => {
    let formType: GettingStartedFormEnum;
    switch (userStatus) {
      case UserStatusEnum.NeedToUpdateInfo:
        formType = isSSO ? GettingStartedFormEnum.GettingStartedSSO : GettingStartedFormEnum.GettingStarted;
        break;

      case UserStatusEnum.WaitingForApproval:
        formType = GettingStartedFormEnum.WaitingApproval;
        break;

      case UserStatusEnum.HasBeenRejected:
        formType = GettingStartedFormEnum.Rejected;
        break;
    }
    return formType;
  };

  return (
    <>
      <Helmet>
        <title>ADM | Getting Started</title>
      </Helmet>
      <div className="page-getting-started">
        <div className="form">
          <GettingStartedForm form={getFormType()} isSso={isSSO} />
        </div>
      </div>
    </>
  );
};

import moment from 'moment';
import { IReleaseNoteResponse } from 'app/types/ReleaseNoteModel';
import { IReleaseNoteItemResponse } from 'app/types/ReleaseNoteItemModel';

// binding data for table
export const bindingReleaseNoteData = (data: IReleaseNoteResponse) => {
  const releaseDateFormat = moment(data.releaseDate);
  data.releaseDate = releaseDateFormat.format('MM-DD-YYYY');
  if (!data.releaseNoteItems) {
    data.releaseNoteItems = [];
  }
  return data;
};

export const bindingReleaseNoteItemData = (data: IReleaseNoteItemResponse) => {
  const creationTimeFormat = moment(data.creationTime);
  data.creationTime = creationTimeFormat.format('MM-DD-YY HH:mm');
  data.typeText = ReleaseNoteItemTypeToText(data.type);
  return data;
};

export enum ReleaseNoteItemType {
  Feature = 0,
  BugFix = 1,
  Enhance = 2,
}

export function ReleaseNoteItemTypeToText(enumValue: ReleaseNoteItemType): string {
  switch (enumValue) {
    case ReleaseNoteItemType.Feature:
      return 'Feature';
    case ReleaseNoteItemType.BugFix:
      return 'Bug Fixed';
    case ReleaseNoteItemType.Enhance:
      return 'Enhancement';
  }
}

import { Button, Checkbox, Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { exportOptions } from 'app/types/exportOptions';
import { CheckboxAll } from './CheckboxAll';
import { Space } from 'antd';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { useContext, useState } from 'react';
import { TeamContext } from 'app/contexts/TeamContext ';
import { subPageOptions } from 'app/types/TeamDetail';
import { reportOptions } from 'app/types/DetailSections';
export interface IProps {
  onCloseModal: () => void;
  exportReportHandler: (formValues) => void;
}

export const DownloadReportForm = ({ onCloseModal, exportReportHandler }: IProps) => {
  const [form] = useForm();
  const [isSelectAll, setSelectAll] = useState(false);
  const [isClearAll, setClearAll] = useState(false);
  const { exportSection } = useContext(DisplayComponentsContext);

  return (
    <>
      <Row justify="space-between" className="create-report-title">
        <Col>
          <span>Select components for your report</span>
        </Col>
        <Space size="large" style={{ marginLeft: '24px', marginBottom: '10px' }}>
          <Button
            className="select-all"
            type="link"
            onClick={() => {
              setSelectAll(true);
              setClearAll(false);
            }}
          >
            Select All
          </Button>
          <Button
            className="select-all"
            type="link"
            onClick={() => {
              setClearAll(true);
              setSelectAll(false);
            }}
          >
            Clear All
          </Button>
        </Space>
      </Row>
      <Form form={form} onFinish={exportReportHandler}>
        {exportOptions?.map((item, index) => (
          <Form.Item name={item.value} key={index}>
            <CheckboxAll
              form={form}
              title={item.title}
              sectionValue={item.value}
              options={item.options}
              isSelectAll={isSelectAll}
              isClearAll={isClearAll}
              isExportReportForm={true}
              initialValueForExport={exportSection[item?.value]}
            />
          </Form.Item>
        ))}

        <Form.Item wrapperCol={{ span: 24 }}>
          <div className="form-footer">
            <Button
              className="btn btn-cancel"
              type="default"
              formAction=""
              onClick={() => {
                onCloseModal();
              }}
            >
              CANCEL
            </Button>
            <Button key="submit" type="primary" formAction="" htmlType="submit" className="btn">
              CREATE
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

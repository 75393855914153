/* eslint-disable react/display-name */
import { Col, DatePicker, Input } from 'antd';
import { Row } from 'antd';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TeamContext } from 'app/contexts/TeamContext ';
import { OwnershipReportWrapper } from './OwnershipReport.styled';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { OwnershipReportData, OwnershipReportFilterType } from 'app/types/OwnershipReportData';
import { fetchOwnershipReport } from 'app/apis/ownershipClient';
import { ColumnFilterItem, TablePaginationConfig } from 'antd/lib/table/interface';
import { USER_PAGE_NUMBER } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { SearchOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
const { Search } = Input;

export const OwnershipReport = () => {
  const [data, setData] = useState<OwnershipReportData[]>([]);
  const { setBreadcrumbs } = useContext(TeamContext);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment().startOf('month'));
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const searchEl = useRef(null);

  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'Report',
        parentCrumb: null,
        renderAsLink: false,
      },
      {
        path: '#',
        text: 'Ownernship Report',
        parentCrumb: null,
        renderAsLink: true,
      },
    ]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    loadData(selectedMonth.toDate());
  }, [selectedMonth]);

  const loadData = async (viewDate: Date) => {
    const data = await fetchOwnershipReport(viewDate);
    setData(data);
  };

  const handleMonthlyOptionChange = (date: moment.Moment) => {
    if (date) {
      setSelectedMonth(date.startOf('month'));
    }
  };

  const disabledDates: RangePickerProps['disabledDate'] = (selected) => {
    return selected && (selected >= moment().endOf('week') || selected <= moment('01-01-2021', 'MM-DD-YYYY'));
  };

  const filterDataOption = (type: OwnershipReportFilterType) => {
    var newData: ColumnFilterItem[];
    switch (type) {
      case OwnershipReportFilterType.ByPortfolio:
        newData = data?.map((item) => ({
          text: item?.portfolio,
          value: item?.portfolioId,
        }));
        break;
      case OwnershipReportFilterType.ByReleaseTrain:
        newData = data?.map((item) => ({
          text: item?.releaseTrain,
          value: item?.releaseTrainId,
        }));
        break;
      case OwnershipReportFilterType.ByDeliveryStream:
        newData = data?.map((item) => ({
          text: item?.deliveryStream,
          value: item?.deliveryStreamId,
        }));
        break;
      case OwnershipReportFilterType.ByProject:
        newData = data?.map((item) => ({
          text: item?.project,
          value: item?.projectId,
        }));
        break;
    }
    return newData.filter(
      (value, index, self) =>
        value.value !== null && index === self.findIndex((t) => t.value === value.value && t.text === value.text)
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ...`}
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record.teamName.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const columns = useMemo<EditableColumn<OwnershipReportData>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Portfolio',
        dataIndex: 'portfolio',
        key: 'portfolio',
        editable: false,
        filters: filterDataOption(OwnershipReportFilterType.ByPortfolio),
        onFilter: (value: number, record) => record?.portfolioId === value,
      },
      {
        title: 'Delivery Steam',
        dataIndex: 'deliveryStream',
        key: 'deliveryStream',
        editable: false,
        filters: filterDataOption(OwnershipReportFilterType.ByDeliveryStream),
        onFilter: (value: number, record) => record?.deliveryStreamId === value,
      },
      {
        title: 'Release Train',
        dataIndex: 'releaseTrain',
        key: 'releaseTrain',
        editable: false,
        filters: filterDataOption(OwnershipReportFilterType.ByReleaseTrain),
        onFilter: (value: number, record) => record?.releaseTrainId === value,
      },
      {
        title: 'Project',
        dataIndex: 'project',
        key: 'project',
        editable: false,
        filters: filterDataOption(OwnershipReportFilterType.ByProject),
        onFilter: (value: number, record) => record?.projectId === value,
      },
      {
        title: 'Team Name',
        dataIndex: 'teamName',
        key: 'teamName',
        editable: false,
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Status',
        dataIndex: 'statusChanged',
        filters: [
          { text: 'No change', value: false },
          { text: 'Has changed', value: true },
        ],
        editable: false,
        render: (value, record, index) => renderStatusChangedColumn(record),
        onFilter: (value: boolean, record) => record?.statusChanged === value,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize]
  );

  const renderStatusChangedColumn = (record: OwnershipReportData) => {
    return {
      children: (
        <span style={{ color: record.statusChanged ? 'green' : 'black' }}>
          {record.statusChanged ? 'Has changed' : 'No change'}
        </span>
      ),
    };
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <>
      <Helmet>
        <title>ADM Tool | Maturity Report</title>
      </Helmet>
      <OwnershipReportWrapper>
        <span className="title">
          MATURITY REPORT
          <DatePicker
            style={{ marginLeft: 10 }}
            picker="month"
            format={'MMMM yyyy'}
            disabledDate={disabledDates}
            value={selectedMonth}
            onChange={handleMonthlyOptionChange}
          />
        </span>
        <EditableTable
          pageSize={pageSize}
          current={page}
          data={data}
          showAction={false}
          columns={columns}
          onChange={onChange}
        />
      </OwnershipReportWrapper>
    </>
  );
};

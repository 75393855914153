import { useState } from 'react';
import { Pie, PieConfig } from '@ant-design/plots';
import { IDataChartProps, ReportType } from 'app/types/CacheReportModel';
import { Row, Select } from 'antd';
import { useFetch } from 'app/hooks/useFetch';
import { getDefectChartByCategory } from 'app/apis/iterationOverview';
import moment from 'moment';
import { OverviewNote } from 'app/components/TeamOverview/OverviewNote/OverviewNote';
import { SaveChartSettingModel } from 'app/types/SaveChartSettingRequest';
import { saveChartSettingRequest } from 'app/apis/fetchTeamOverview';

const getStartMonth = (): moment.Moment => {
  return moment().subtract(10, 'months').startOf('month');
};

const getEndMonth = (): moment.Moment => {
  return moment().endOf('month');
};

const getMonths = (startMonth: moment.Moment, endMonth: moment.Moment): moment.Moment[] => {
  endMonth = moment(endMonth);

  const result: moment.Moment[] = [];

  while (true) {
    if (endMonth.diff(startMonth, 'day') >= 1) {
      result.push(moment(endMonth));
      endMonth = endMonth.add(-1, 'month');
    } else {
      break;
    }
  }

  return result;
};

const selectionOptions = [
  { label: 'YTD', value: 'YTD' },
  ...getMonths(getStartMonth(), getEndMonth()).map((date) => {
    return {
      label: `${date.format('MMMM')}/${date.year()}`,
      value: `${date.month() + 1}/${date.year()}`,
    };
  }),
];

export const DefectRootCauseCategoryTrend = ({ teamId }: IDataChartProps) => {
  const [data, setData] = useState([]);
  const { data: rawData } = useFetch(
    () =>
      getDefectChartByCategory(teamId).then((value) => {
        setData(value.data);
        return value;
      }),
    [teamId]
  );

  const onChangeCategory = (value) => {
    if (value === 'YTD') {
      setData(rawData?.data);
    } else {
      var random = Math.floor(Math.random() * 9);
      var temp = rawData?.data.filter((x) => x.value === random);
      setData(temp);
    }
  };

  const onNoteSave = async (value: string) => {
    const payload = new SaveChartSettingModel();
    payload.teamId = teamId;
    payload.chartType = ReportType.IterationDefectsByState;
    payload.note = value;

    return await saveChartSettingRequest(payload);
  };

  const config: PieConfig = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'category',
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name} {percentage}',
    },
    legend: {
      position: 'right',
      marker: {
        symbol: 'circle',
      },
    },
  };
  return (
    <>
      <Row style={{ justifyContent: 'end' }}>
        <Select defaultValue="YTD" options={selectionOptions} style={{ width: 150 }} onChange={onChangeCategory} />
      </Row>
      <Pie {...config} />
      <OverviewNote content={rawData?.note} onSave={onNoteSave} />
    </>
  );
};

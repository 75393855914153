import { createContext, useContext, useEffect, useState } from 'react';
import { keys, values } from 'lodash';
import { getDisplayComponents } from 'app/apis/teamDetailClient';
import { UserContext } from './UserContext';
import { TeamContext } from './TeamContext ';
import { subPageOptions } from 'app/types/TeamDetail';
import { DisplayType } from 'app/types/Stakeholders';
interface IProps {
  children: React.ReactNode;
}

export const DisplayComponentsContext = createContext<any>(null);

export const DisplayComponentsContextProvider = ({ children }: IProps) => {
  const { user } = useContext(UserContext);
  const { page } = useContext(TeamContext);

  const [components, setComponents] = useState([]);
  const [sections, setSections] = useState('');
  const [selectAll, setSelectAll] = useState(null);
  const [unCheckedItems, setUnCheckedItems] = useState(null);

  // add state for exportSection Form
  const [exportSection, setExportSection] = useState('');
  const pageName = subPageOptions.find((item) => item.value === page)?.label.toLowerCase();

  const displayFormRequest = {
    displayType: DisplayType.DisplayForm,
  };
  const exportFormRequest = {
    displayType: DisplayType.ExportReportForm,
  };
  useEffect(() => {
    getDisplayComponents(displayFormRequest).then((res) => {
      handleCheckboxValues(res?.value);
    });
    getDisplayComponents(exportFormRequest).then((res) => {
      handleCheckboxValueExport(res?.value);
    });
  }, [user.id]);
  const handleCheckboxValueExport = (value: string) => {
    if (value) {
      const parsedJson = value ? JSON.parse(value) : '';
      setExportSection(parsedJson);
    }
  };
  const handleCheckboxValues = (value: string) => {
    if (value) {
      const parsedJson = value ? JSON.parse(value) : '';
      setSections(parsedJson);
      if (parsedJson) {
        const output = values(parsedJson).map((item) => {
          return values(item).flat(1);
        });
        setComponents(values(output).flat(1));
      }
    }
  };

  const handleComponentClass = (className: string) => {
    // all components are not checked or a component is checked => display component
    if (!keys(sections).includes(pageName) || (components && components.includes(className))) {
      return className;
    } else {
      return `${className} hide-element`;
    }
  };

  const handleSectionClass = (className: string) => {
    // all components are not checked or a section is checked => display section
    if (!keys(sections).includes(pageName) || (sections[pageName] && sections[pageName][className])) {
      return className;
    } else {
      return `${className} hide-element`;
    }
  };

  return (
    <DisplayComponentsContext.Provider
      value={{
        components,
        setComponents,
        selectAll,
        setSelectAll,
        sections,
        setSections,
        handleCheckboxValues,
        handleComponentClass,
        handleSectionClass,
        unCheckedItems,
        setUnCheckedItems,
        exportSection,
      }}
    >
      {children}
    </DisplayComponentsContext.Provider>
  );
};

import styled from 'styled-components';

export const ReleaseNoteWrapper = styled.div`
  $border-color: #d9d9d9;
  $border: 0.5px solid;
  $section-padding: 1.5em;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #0e50a4;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #d9d9d9;
    font-weight: bolder;
    .ant-dropdown-trigger.ant-table-filter-trigger {
      color: #292525;
    }
    .ant-dropdown-trigger.ant-table-filter-trigger.active {
      color: #1890ff;
    }
    .ant-table-column-sorter.ant-table-column-sorter-full {
      color: #292525;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 8px 16px;
    display: flex;
    align-items: center;
  }
  .anticon.anticon-pushpin.rightpin {
    float: right;
    color: #f3c300;
    margin-top: 0.35em;
    svg:hover {
      fill: #d3c074;
      transform: translateY(-0.2em);
      cursor: pointer;
    }
  }
  .ant-anchor-ink {
    right: 0;
    left: unset;
  }
  .ant-anchor-wrapper {
    overflow: inherit;
  }
  .ant-anchor-link {
    padding: 7px 16px 7px 0;
  }
  .ant-anchor-link .ant-anchor-link-title {
    text-align: right;
  }
`;

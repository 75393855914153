import { Liquid } from '@ant-design/plots';
import { Col, Empty, Row } from 'antd';
import { CHART_COLOR_50_TO_80, CHART_COLOR_80_TO_100, CHART_COLOR_LESS_THAN_50 } from 'app/common/constants';
import { DeliveryResponse } from 'app/types/CacheReportModel';
import { useMemo } from 'react';
import { DetailChartDelivery } from '../ChartDetail/DetailChartDelivery';

interface IProps {
  dataSource: DeliveryResponse;
  isMonitor?: boolean;
  isReport?: boolean;
  checkHaveOtherData?: boolean;
}

export const IterationTeamDelivery = ({ dataSource, isMonitor, isReport, checkHaveOtherData = true }: IProps) => {
  const result = dataSource?.delivered / dataSource?.total || 0;
  const deliveredPoint = dataSource?.delivered || 0;
  const totalPoint = dataSource?.total || 0;
  const config = useMemo(() => {
    // Select color base on dataSource range
    // Read more from US 3740

    let colors = [CHART_COLOR_LESS_THAN_50, CHART_COLOR_50_TO_80, CHART_COLOR_80_TO_100];
    let colorsRange = [0.5, 0.8, 1];
    let colorIndex = 0;
    for (; colorIndex < colorsRange.length; colorIndex++) {
      if (result < colorsRange.at(colorIndex)) {
        break;
      }
    }

    // Prepare config for liquid chart
    const config = {
      // Liquid chart does not trigger auto fit height if percent = 100%
      // 1.001 doest not change or impact to UI requirement, it is just a trick to trigger auto fit height of liquid chart
      percent: result >= 1 ? 1.001 : result,
      color: colors.at(colorIndex),
      outline: {
        border: 4,
        distance: 8,
      },
      wave: {
        length: 128,
      },
      statistic: {
        content: {
          // Round to zero
          content: isReport
            ? `<div>
                      <h4>${(result * 100).toFixed(0)}%</h4>
                      <p style="font-size: medium;">${deliveredPoint?.toString()}/${totalPoint?.toString()} points</p>
                    </div>`
            : (result * 100).toFixed(0) + `%`,
        },
      },
    };

    return config;
  }, [result, totalPoint, deliveredPoint]);

  // Show no result data
  if (!dataSource && !checkHaveOtherData) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  // Show detail if in monitor tab
  if (isMonitor)
    return (
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <Liquid {...config} />
        </Col>
        <Col span={10}>
          <DetailChartDelivery loading={false} dataSource={dataSource} />
        </Col>
      </Row>
    );
  // Show liquid chart
  return <Liquid {...config} />;
};

import { Table, TablePaginationConfig } from 'antd';
import { OwnershipLevelTitle } from 'app/common/constants';
import { EditableColumn } from 'app/components/EditableTable';
import { TeamContext } from 'app/contexts/TeamContext ';
import { OwnershipLevel } from 'app/types/Ownership';
import { OwnershipImprove } from 'app/types/OwnershipProgramData';
import { useContext, useMemo, useState } from 'react';

type IProps = {
  data: OwnershipImprove[];
};

export const TopTeamImprove = ({ data }: IProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { teams } = useContext(TeamContext);

  const renderChange = (record: number, withPercent: boolean = true) => {
    return (
      <div style={{ color: record < 0 ? 'red' : record > 0 ? 'green' : 'black' }}>
        {record == null || isNaN(record)
          ? ''
          : record < 0
          ? `▼ ${Number(Number(record).toFixed(2))}${withPercent ? '%' : ''}`
          : record > 0
          ? `▲ ${Number(Number(record).toFixed(2))}${withPercent ? '%' : ''}`
          : '-'}
      </div>
    );
  };

  const onChangePage = (pagination: TablePaginationConfig) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };
  const columns = useMemo<EditableColumn<OwnershipImprove>[]>(() => {
    let defineColumns: EditableColumn<OwnershipImprove>[] = [
      {
        title: '#',
        width: 30,
        align: 'center',
        render: (value, record, index) => (pageNumber - 1) * pageSize + index + 1,
      },
      {
        title: 'Team',
        dataIndex: 'teamId',
        render: (value, record, index) => teams.find((item) => item.id === record.teamId)?.name,
        width: 160,
      },
      {
        title: 'Score',
        align: 'center',
        dataIndex: 'score',
        sorter: (a, b) => a.score - b.score,
        render: (value) => (value < 0 ? '-' : Math.round(value)),
        width: 70,
      },
      {
        title: 'Score Change',
        align: 'center',
        dataIndex: 'scoreChange',
        sorter: (a, b) => a.scoreChange - b.scoreChange,
        render: (value) => renderChange(value, true),
        width: 130,
      },
      {
        title: 'Level',
        align: 'center',
        dataIndex: 'level',
        sorter: (a, b) => a.level - b.level,
        render: (value) => (value < 0 ? '-' : `Level ${value}`),
        width: 70,
      },
      {
        title: 'Level Change',
        align: 'center',
        dataIndex: 'levelChange',
        sorter: (a, b) => a.levelChange - b.levelChange,
        render: (value) => renderChange(value, false),
        width: 130,
      },
    ];

    return defineColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, data]);
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChangePage}
      pagination={{
        hideOnSinglePage: false,
        pageSize: pageSize,
        onChange: () => onChangePage,
        current: pageNumber,
      }}
      scroll={{ x: 'max-content', y: 500 }}
    />
  );
};

import { Liquid } from '@ant-design/plots';
import { useMemo } from 'react';
import { Col, Empty, Row } from 'antd';
import { ActualCapacityResponse } from 'app/types/CacheReportModel';
import { DetailChartActualCapacity } from 'app/components/ChartDetail/DetailChartActualCapacity';
import { CHART_COLOR_50_TO_80, CHART_COLOR_80_TO_100, CHART_COLOR_LESS_THAN_50 } from 'app/common/constants';

interface IProps {
  dataSource: ActualCapacityResponse;
  isMonitor?: boolean;
  checkHaveOtherData?: boolean;
}

export const UtilizedCapacity = ({ dataSource, isMonitor, checkHaveOtherData = true }: IProps) => {
  const result = dataSource?.actual / dataSource?.total || 0;
  const config = useMemo(() => {
    let colors = [CHART_COLOR_LESS_THAN_50, CHART_COLOR_50_TO_80, CHART_COLOR_80_TO_100];
    let colorsRange = [0.5, 0.8, 1];
    let colorIndex = 0;
    for (; colorIndex < colorsRange.length; colorIndex++) {
      if (result < colorsRange.at(colorIndex)) {
        break;
      }
    }
    const config = {
      // Liquid chart does not trigger auto fit height if percent = 100%
      // 1.001 doest not change or impact to UI requirement, it is just a trick to trigger auto fit height of liquid chart
      percent: result >= 1 ? 1.001 : result,
      color: colors.at(colorIndex),
      shape: 'rect',
      outline: {
        border: 2,
        distance: 4,
      },
      wave: {
        length: 128,
      },
      statistic: {
        content: {
          content: (result * 100).toFixed(0) + '%',
        },
      },
    };
    return config;
  }, [result]);

  // Show no result data
  if (!dataSource && !checkHaveOtherData) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  // Show detail if in monitor tab
  if (isMonitor)
    return (
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <Liquid {...config} />
        </Col>
        <Col span={12}>
          <DetailChartActualCapacity loading={false} dataSource={dataSource} />
        </Col>
      </Row>
    );
  return <Liquid {...config} />;
};

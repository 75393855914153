import { CacheReportModel } from './CacheReportModel';
import { Entity } from './entity';
import { IterationReportChartResponse } from './Iteration';

export interface GroupedTeamModel {
  planData: GroupedTeamPlanData;
  reportData: GroupedTeamDataReportResponse;
  monitorData: GroupedTeamDataMonitorResponse;
}

export interface GroupedTeamPlanData {
  detail: DetailTableData[];
  overview: OverviewTableData[];
  planVelocity: PlanVelocity[];
  planCapacity: PlanCapacity[];
  planCapacityStatistic: GroupedTeamPlanCapacityChartStatistic;
  planVelocityStatistic: GroupedTeamPlanVelocityChartStatistic;
}

export interface DetailTableData extends Entity {
  teamId: number;
  name: string;
  targetVelocity: number;
  plannedVelocity: number;
  targetCapacity: number;
  planCapacity: number;
  feature: number;
  userStories: number;
  defects: number;
  tasks: number;
}
export interface OverviewTableData extends Entity {
  teamId: number;
  name: string;
  velocity: number;
  targetVelocity: number;
  capacity: number;
  targetCapacity: number;
  cards: Cards[];
}

export interface Cards {
  teamId: number;
  defects: number;
  userStories: number;
  tasks: number;
  feature: number;
}

export interface PlanVelocity {
  state: string;
  code: string;
  point: number;
}

export interface PlanCapacity {
  state: string;
  code: string;
  point: number;
}

export interface GroupedTeamPlanCapacityChartStatistic {
  best3: number;
  worst3: number;
}
export interface GroupedTeamPlanVelocityChartStatistic {
  best3: number;
  worst3: number;
}

export interface GroupedTeamDataMonitorResponse {
  cacheReportModels: CacheReportModel[];
}

export interface GroupedTeamDataReportResponse {
  reportChart: IterationReportChartResponse[];
  detail: GroupedTeamReportDetail[];
  overview: GroupedTeamReportOverview[];
}

export interface GroupedTeamReportDetail extends Entity {
  teamId: number;
  name: string;
  userStoriesCO: number;
  defectsCO: number;
  totalCapacity: number;
  estimateCapacity: number;
  actualCapacity: number;
  acceptedPoint: number;
  totalPoint: number;
  idea: number;
  defined: number;
  inprogress: number;
  completed: number;
  accepted: number;
  released: number;
}

export interface GroupedTeamReportOverview extends Entity {
  teamId: number;
  name: string;
  capacity: number;
  velocity: number;
  storiesStatus: ReportDetailItemType[];
  scheduleStatus: ReportDetailItemType[];
}

export interface ReportDetailItemType {
  type: string;
  value: number;
}

export enum TitleTeamType {
  TeamName = 'Team Name',
  ReleaseTrain = 'Release Train',
  DeliveryStream = 'Delivery Stream',
  Portfolio = 'Portfolio',
}

import { Row, Col, Image, Button } from 'antd';
import { Link } from 'react-router-dom';
import './UnderConstruction.scss';

export const UnderConstruction = () => {
  return (
    <Row justify="center" className="under-construction">
      <Col xs={24} sm={20} xl={13}>
        <Image src="/assets/img/under-construction.png" alt="under-construction" preview={false} />
        <Button type="primary" shape="round" className="redirect-button">
          <Link to="/dashboard">Overview</Link>
        </Button>
      </Col>
    </Row>
  );
};

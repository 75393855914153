import { Col, Row } from 'antd';
import { OjectTeamDataResponse } from 'app/types/Organizational';
import { hex2rgba } from 'app/utils/utils';
import React from 'react';
import { OrginazationChartWrapper } from '../OrginazationChart.styled';
import DeliveryStreamNode from './DeliveryStreamNode';

interface IProps {
  portfolio: OjectTeamDataResponse;
  color: string;
  setCurrentTeam: (id: number) => void;
}

function PortfolioNode({ portfolio, color, setCurrentTeam }: IProps) {
  const rgba = hex2rgba(color);
  return (
    <OrginazationChartWrapper color={rgba}>
      <Col span={3} className="portfolio-row">
        <span>{portfolio.name}</span>
      </Col>
      {portfolio?.childrenItems?.length > 0 && (
        <Col span={21}>
          {portfolio?.childrenItems?.map((deliverystream, i) => (
            <Row align="middle" key={i}>
              <Col span={24}>
                <DeliveryStreamNode
                  deliveryStream={deliverystream}
                  key={i}
                  color={color}
                  setCurrentTeam={setCurrentTeam}
                />
              </Col>
            </Row>
          ))}
        </Col>
      )}
    </OrginazationChartWrapper>
  );
}

export default PortfolioNode;

import { Column, ColumnConfig } from '@ant-design/plots';
import { StoryCarriedOverProgramItem } from '../../types/ProgramTrends';
import { Col, Empty } from 'antd';
import { Card } from 'app/components/Card';
import { AcceptedChartColor, LabelTrendsChart, TitleChartStyle } from 'app/common/constants';
interface IStoryCarriedOverProgram {
  dataSource: StoryCarriedOverProgramItem[];
  loading?: boolean;
}

export const StoryCarriedOverProgram = ({ dataSource, loading }: IStoryCarriedOverProgram) => {
  const generateColumnConfig = (dataSource: StoryCarriedOverProgramItem[]): ColumnConfig => {
    return {
      data: dataSource,
      columnWidthRatio: 0.5,
      meta: {
        carriedOver: {
          alias: 'Carried Over',
        },
      },
      xField: 'iteration',
      yField: 'carriedOver',
      color: AcceptedChartColor,
      yAxis: {
        type: 'linear',
        title: {
          text: 'User Stories',
          position: 'center',
          spacing: 20,
          style: TitleChartStyle,
        },
        position: 'left',
      },
      xAxis: {
        label: LabelTrendsChart,
      },
    };
  };

  return (
    <Card title={'Story Carried-Over For Program'} loading={loading}>
      {!loading &&
        (!dataSource?.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Col span={24}>
            <Column {...generateColumnConfig(dataSource)} />
          </Col>
        ))}
    </Card>
  );
};

/* eslint-disable react/display-name */
import { useContext, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Col, Input, RadioChangeEvent, Row, TablePaginationConfig, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Helmet } from 'react-helmet';
import { isNull, merge, keyBy, values } from 'lodash';
import { EditableColumn } from 'app/components/EditableTable';
import { GroupedTeamLinks, TABLE_DEFAULT_PAGE_INDEX, TABLE_DEFAULT_PAGE_SIZE } from 'app/common/constants';
import { teamOptions, TeamType } from 'app/types/team';
import { MonitorStatusResponse, TeamWithMonitorStatus } from 'app/types/SystemMonitoring';
import { PageTitle } from 'app/layouts/AdminLayout.styled';
import { StyledRadioGroup, StyledEditableTable } from './SystemMonitoring.styled';
import { TeamContext } from 'app/contexts/TeamContext ';
import { useFetch } from 'app/hooks/useFetch';
import { fetchStatus, triggerSync, triggerSyncHighLevelTeam } from 'app/apis/systemMonitoringClient';
import { FilterValue, SortOrder } from 'antd/lib/table/interface';
import { SyncButton } from './SyncButton';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { convertUtcDateToLocalDate } from 'app/common/helpers';
import { Link } from 'react-router-dom';
import useSyncStatus from 'app/hooks/useSyncStatus';

const { Search } = Input;

const getParentType = (type: TeamType): TeamType => {
  switch (type) {
    case TeamType.Team:
      return TeamType.ReleaseTrain;
    case TeamType.ReleaseTrain:
      return TeamType.DeliveryStream;
    case TeamType.DeliveryStream:
      return TeamType.Portfolio;
    default:
      return null;
  }
};

export const SystemMonitoring = () => {
  const searchEl = useRef(null);
  const { teams: teamData, setBreadcrumbs } = useContext(TeamContext);
  useEffect(() => {
    setBreadcrumbs([createDefaultBreadCrumb('System Monitoring')]);
  }, [setBreadcrumbs]);
  const [mergedData, setMergedData] = useState<TeamWithMonitorStatus[]>();
  const [activeType, setActiveType] = useState<number>(TeamType.Team);
  const [page, setPage] = useState(TABLE_DEFAULT_PAGE_INDEX);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);

  const [filteredData, setFilteredData] = useState<Record<string, FilterValue>>(null);

  let { data: originalMonitoringData, loading } = useFetch(fetchStatus);
  const [monitoringData, setMonitoringData] = useState<MonitorStatusResponse[]>(originalMonitoringData);
  const [index, setIndex] = useState<number>(0);

  const statusReceivedCallback = useCallback((syncStatusResponse: MonitorStatusResponse) => {
    setMonitoringData((oldData) => {
      const rowIdx = oldData.findIndex((x) => x.teamId === syncStatusResponse.teamId);
      if (rowIdx !== -1) {
        oldData[rowIdx] = syncStatusResponse;
        return [...oldData];
      } else {
        return [...oldData, syncStatusResponse];
      }
    });
  }, []);

  useSyncStatus(statusReceivedCallback);

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ${dataIndex}`}
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      if (dataIndex === 'name') return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const columns = useMemo<EditableColumn<TeamWithMonitorStatus>[]>(
    () => [
      {
        title: '#',
        width: '3.5%',
        align: 'center',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        render: (value, record) => renderLink(value, record),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        options: [
          { label: 'Portfolio', value: 1 },
          { label: 'Delivery Stream', value: 2 },
          { label: 'Release Train', value: 3 },
          { label: 'Development Team', value: 4 },
        ],
        inputType: 'select',
      },
      {
        title: 'Parent',
        dataIndex: 'parentId',
        key: 'parentId',
        inputType: 'select',
        onFilter: (value: number, record) => record.parentId === value,
      },
      {
        title: 'Last synced on',
        dataIndex: 'lastSyncOn',
        key: 'lastSyncOn',
        render: (value, record, index) => {
          if (!value) return '-';

          let dt = new Date(record.lastSyncOn);
          if (isNaN(dt.getTime())) {
            // Invalid time
            return value;
          }

          const tzOffset: Number = new Date().getTimezoneOffset() / -60;

          return (
            <Tooltip
              placement="topLeft"
              title={`This time is based on your local time (UTC${tzOffset.toStringWithSign()}).`}
            >
              {convertUtcDateToLocalDate(dt).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              })}
            </Tooltip>
          );
        },
        sorter: (a, b, order: SortOrder) => {
          const fallback = order === 'ascend' ? Date() : 0;

          const d1 = new Date(a.lastSyncOn || fallback);
          const d2 = new Date(b.lastSyncOn || fallback);

          return d1.getTime() - d2.getTime();
        },
      },
      {
        title: 'Updated by',
        dataIndex: 'lastSyncBy',
        render: (value, record) => {
          if (!value) return '-';

          return value;
        },
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        width: '5%',
        render: (_value, record) => {
          return (
            <SyncButton
              renderStyle="link"
              showSyncSpecificDate={record.type === TeamType.Team}
              syncStatus={record.status}
              syncAction={async (syncDate) => {
                record.type === TeamType.Team
                  ? statusReceivedCallback(await triggerSync(record.teamId ?? record.id, { syncDate }))
                  : statusReceivedCallback(await triggerSyncHighLevelTeam(record.teamId));
              }}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize]
  );

  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (!originalMonitoringData) {
      return;
    }

    setMonitoringData([...originalMonitoringData]);
  }, [originalMonitoringData]);

  useEffect(() => {
    if (!monitoringData) {
      return;
    }

    setMergedData(values(merge(keyBy(teamData, 'id'), keyBy(monitoringData, 'teamId'))));
  }, [teamData, monitoringData]);

  useEffect(() => {
    if (!teamData) {
      return;
    }

    const newColumns = [...columns];
    const indexParent = newColumns.findIndex((item) => item.dataIndex === 'parentId');
    const parentCol = newColumns[indexParent];

    parentCol.options = teamData.map((item) => ({ value: item.id, label: item.name }));

    parentCol.filters = teamData
      ?.filter((item) => item.type === getParentType(activeType))
      .map((item) => ({ text: item.name, value: item.id }));

    setTableColumns(newColumns);
  }, [activeType, teamData, page, columns]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const onChange = (pagination: TablePaginationConfig, filter: Record<string, FilterValue>) => {
    setFilteredData(filter);

    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const onChangeTeamType = ({ target: { value } }: RadioChangeEvent) => {
    // TODO: Reset filter input! (Still trying to implement this)
    setFilteredData(null);

    setActiveType(value);
    setPage(TABLE_DEFAULT_PAGE_INDEX);
    setIndex(index + 1);
  };

  const setRowClassName = (record: TeamWithMonitorStatus, index) => {
    if (record?.status?.toLowerCase() === 'failed') {
      return 'is-sync-fail';
    }

    return '';
  };

  const renderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`/${GroupedTeamLinks[record.type]}/${record.id}`}>
          {text}
        </Link>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>ADM Tool | System Monitoring</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>SYSTEM MONITORING</Title>
      </PageTitle>
      <Row style={{ marginBottom: '1.5em' }}>
        <Col style={{ marginLeft: 'auto' }}>
          <StyledRadioGroup
            options={teamOptions}
            onChange={onChangeTeamType}
            value={activeType}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ minWidth: '100% ' }}>
          <StyledEditableTable
            rowClassName={setRowClassName}
            data={mergedData?.filter((item) => item.type === activeType)}
            columns={tableColumns}
            pageSize={pageSize}
            current={page}
            onChange={onChange}
            loading={loading}
            key={index}
          />
        </Col>
      </Row>
    </>
  );
};

import { useContext, useEffect, useState } from 'react';
import './IterationStatus.scss';
// Components
import { Col, Image, Row, Select, Tooltip } from 'antd';
//apis
import { getIterationsListNotFuture } from 'app/apis/iterationOverview';
import { useFetch } from 'app/hooks/useFetch';
// Context
import { TeamContext } from 'app/contexts/TeamContext ';

import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { ProgramKanbanOverview } from 'app/components/ProgramKanbanOverview/ProgramKanbanOverview';
import { TeamResponse, TeamType } from 'app/types/team';

export enum SelectedTab {
  Plan = 0,
  Report,
}

export interface IOverviewTableRow {
  id: number;
  key: number;
  teamName: string;
  parentId?: number;
  status: number;
}

export function createGroupsOfTeams(
  teamsData: TeamResponse[],
  ignoreFPTInvolvded?: boolean
): {
  deliveryStream: TeamResponse[];
  portfolio: TeamResponse[];
  releaseTrain: TeamResponse[];
  teams: TeamResponse[];
} {
  if (ignoreFPTInvolvded)
    return {
      deliveryStream: teamsData.filter((team) => team.type === TeamType.DeliveryStream),
      portfolio: teamsData.filter((team) => team.type === TeamType.Portfolio),
      releaseTrain: teamsData.filter((team) => team.type === TeamType.ReleaseTrain),
      teams: teamsData.filter((team) => team.type === TeamType.Team),
    };
  return {
    deliveryStream: teamsData.filter((team) => team.type === TeamType.DeliveryStream && team.isFPTInvolvded),
    portfolio: teamsData.filter((team) => team.type === TeamType.Portfolio && team.isFPTInvolvded),
    releaseTrain: teamsData.filter((team) => team.type === TeamType.ReleaseTrain && team.isFPTInvolvded),
    teams: teamsData.filter((team) => team.type === TeamType.Team && team.isFPTInvolvded),
  };
}

export function IterationStatus({ ...props }) {
  const { teams: teamsData, setBreadcrumbs } = useContext(TeamContext);
  const { data: iterationsList } = useFetch<string[]>(() => getIterationsListNotFuture(), []);
  const [selectedIterationName, setSelectedIterationName] = useState<string>('');
  const [iterationOptions, setIterationOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'Report',
        parentCrumb: null,
        renderAsLink: false,
      },
      createDefaultBreadCrumb('Iteration Status'),
    ]);
    if (iterationsList?.length > 0) {
      if (!selectedIterationName) {
        iterationsList.shift();
        setSelectedIterationName(iterationsList[0].toString());
      }
      const options = iterationsList.map((iteration) => ({
        label: iteration.toString(),
        value: iteration.toString(),
      }));
      setIterationOptions(options);

      if (selectedIterationName) handleIterationOptionChange(selectedIterationName);
    }
  }, [iterationsList, setBreadcrumbs, selectedIterationName]);

  //Destructuring teams's data to groups by teamType
  const groupsOfTeams = createGroupsOfTeams(teamsData);
  //iteration change
  const handleIterationOptionChange = async (selectedValue: string) => {
    setSelectedIterationName(selectedValue);
  };

  //Do not show percent change for latest iteration because
  //the data work is all still being done.
  let showPercentChange = iterationsList?.length > 0 && selectedIterationName !== iterationsList[0].toString();

  return (
    <>
      <Row className="overview-section">
        <Col span={15}>
          <span className="title">
            ITERATION STATUS
            <Select
              onChange={handleIterationOptionChange}
              value={selectedIterationName}
              options={iterationOptions}
              style={{ width: 120 }}
            />
          </span>
        </Col>
        <Col span={9}>
          <Tooltip
            title={
              <>
                <table>
                  <tr>
                    <td className="td-filter">Delivery Streams:</td>
                    <td className="td-filter">{groupsOfTeams.deliveryStream.length}</td>
                  </tr>
                  <tr>
                    <td className="td-filter">Release Trains:</td>
                    <td className="td-filter">{groupsOfTeams.releaseTrain.length}</td>
                  </tr>
                  <tr>
                    <td className="td-filter">Teams:</td>
                    <td className="td-filter">{groupsOfTeams.teams.length}</td>
                  </tr>
                </table>
              </>
            }
            color={'white'}
            overlayInnerStyle={{ color: '#000' }}
            placement="right"
            arrowPointAtCenter
          >
            <Row className="teamgroups-count-section">
              <Col className="teamgroups-count-team">
                <Image preview={false} className="team-icon" src="/assets/img/teams.svg" />
                <span className="team-count">{groupsOfTeams.deliveryStream.length}</span>
              </Col>
              <Col className="teamgroups-count-team">
                <Image preview={false} className="team-icon" src="/assets/img/releaseTrain.svg" />
                <span className="team-count">{groupsOfTeams.releaseTrain.length}</span>
              </Col>
              <Col className="teamgroups-count-team">
                <Image preview={false} className="team-icon" src="/assets/img/deliveryStream.svg" />
                <span className="team-count">{groupsOfTeams.teams.length}</span>
              </Col>
            </Row>
          </Tooltip>
        </Col>
      </Row>
      <ProgramKanbanOverview selectedIterationName={selectedIterationName} />
    </>
  );
}

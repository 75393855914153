import styled from 'styled-components';

export const OwnershipHighlightWrapper = styled.div`
  .title {
    text-align: 'center';
    font: normal normal bold 16px/40px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .highlight-content {
    text-align: 'left';
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin: 0;
    padding: 0;
  }
  .no-content {
    text-align: 'left';
    font: normal normal normal 16px/21px Segoe UI;
    font-style: italic;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin: 0;
    padding: 0;
  }
  .editor-box-read-only {
    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }
  .editor-box {
    padding: 5px 20px;
    min-height: 100px;
  }
  .editor-wrapper {
    border: 1px solid #d9d9d9;
    .rdw-editor-toolbar {
      .rdw-embedded-wrapper {
        display: none;
      }
    }
  }
`;

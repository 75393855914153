import { Iteration } from './Iteration';

export interface CacheReportModel {
  cacheDataResult?: any;
  teamId: number;
  type: ReportType;
}

export interface ChartCollectionModel {
  cacheReportModels?: CacheReportModel[];
  teamId: number;
}

export enum ReportType {
  Throughput = 1,
  IterationBurndown = 2,
  CumulativeFlowDiagram = 3,
  IterationDefectsByState = 4,
  IterationDefectsByEnvironment = 5,
  CapacityDetail = 6,
  TeamDelivery = 7,
  ScheduleState = 8,
  UtilizedCapacity = 9,
  CarriedOver = 10,
  IterationHealth = 11,
  Velocity = 12,
  UserStoriesCommittedVsDelivered = 13,
  DefectsCommittedVsDelivered = 14,
  TestExecutionState = 16,
  CfdForUserStory = 17,
  ResolutionByDefect = 18,
  RootCauseCategoryByDefect = 19,
  IterationBurnup = 20,

  // Release Train
  ReleaseTrainDeliveryMonitor = 100,
  ReleaseTrainScheduleStateMonitor = 101,
  ReleaseTrainActualCapacityMonitor = 102,
  ReleaseTrainIterationBurndown = 103,
  ReleaseTrainDeliveryReport = 104,
  ReleaseTrainScheduleStateReport = 105,
  ReleaseTrainActualCapacityReport = 106,
  ReleaseTrainCarriedOver = 107,
  ReleaseTrainIterationDetail = 108,

  // Delivery Stream
  DeliveryStreamDeliveryMonitor = 200,
  DeliveryStreamScheduleStateMonitor = 201,
  DeliveryStreamActualCapacityMonitor = 202,
  DeliveryStreamIterationBurndown = 203,
  DeliveryStreamDeliveryReport = 204,
  DeliveryStreamScheduleStateReport = 205,
  DeliveryStreamActualCapacityReport = 206,
  DeliveryStreamCarriedOver = 207,
  DeliveryStreamIterationDetail = 208,

  // Portfolio
  PortfolioDeliveryMonitor = 300,
  PortfolioScheduleStateMonitor = 301,
  PortfolioActualCapacityMonitor = 302,
  PortfolioIterationBurndown = 303,
  PortfolioDeliveryReport = 304,
  PortfolioScheduleStateReport = 305,
  PortfolioActualCapacityReport = 306,
  PortfolioCarriedOver = 307,
  PortfolioIterationDetail = 308,

  // Grouped Team
  GroupedTeamChild = 900,

  // Over view page (1000 - 1099)
  OverviewThroughput = 1000,
  OverviewVelocity = 1001,
  OverviewProductivity = 1002,
  OverviewIterationCompleteness = 1003,
  OverviewUtilizedCapcity = 1004,
  OverviewIterationDefects = 1005,
}

export interface IterationDefect {
  state: string;
  amount?: number;
  date: string;
}

export interface TaskState {
  tasks?: number;
  state: string;
  dateId: string;
}

export interface IterationBurndown {
  maxHours: number;
  maxPoints: number;
  dataPoints: IterationBurndownDataPoint[];
}

export interface IterationBurndownDataPoint {
  ideal: number;
  todo: number;
  accepted: number;
  date: string;
}

export interface ThroughputModel {
  idea: number;
  defined: number;
  inprogess: number;
  completed: number;
  accepted: number;
  released: number;
  date: string;
}

export interface IDataChartProps {
  teamId?: number;
  iteration: Iteration;
  dataSource: any;
}

export interface DataModel {
  time: string;
  value: number;
  type: string;
}

export const TooltipLabelConfig = {
  task: 'Task Todo (hrs)',
  ideal: 'Ideal (hrs)',
  accepted: 'Accepted (pts)',
  estimate: 'Estimate (hrs)',
  scope: 'Scope (hrs)',
  scopePoint: 'Scope (pts)',
};

export interface DeliveryResponse {
  total: number;
  delivered: number;
}

export interface ActualCapacityResponse {
  total: number;
  plan: number;
  todo: number;
  actual: number;
}

export interface IterationBurnup {
  maxHours: number;
  maxPoints: number;
  dataPoints: IterationBurnupDataPoint[];
}

export interface IterationBurnupDataPoint {
  scopeHour: number;
  scopePoint: number;
  estimate: number;
  accepted: number;
  date: string;
}

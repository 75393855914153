import styled from 'styled-components';

export const StyledPrePlanWrapper = styled.div`
  display: block !important;
  margin: 0 auto;
  width: 700px;

  .ant-typography.head-text {
    color: #0e50a4 !important;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .iteration-goal {
    margin-bottom: 1.5rem;
  }
  .velocity-goal {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const StyledPlanWrapper = styled.div`
  .section {
    margin-bottom: 1.5rem;
  }
  .velocity-goal {
    margin-bottom: 20px;
  }
`;

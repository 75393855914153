import { OjectTeamDataResponse } from 'app/types/Organizational';
import { DataNode } from 'antd/lib/tree';
import { TeamType } from 'app/types/team';

// filter data FPT Involved
export const filterFPTInvolved = (dataTeam: OjectTeamDataResponse[]) => {
  let list = [];
  dataTeam?.forEach((portfolio) => {
    let portfolioObject: OjectTeamDataResponse = null;
    portfolioObject = {
      ...portfolio,
      childrenItems: [],
    };
    portfolio.childrenItems?.forEach((deliverystream) => {
      let deliveryObj: OjectTeamDataResponse = {
        ...deliverystream,
        childrenItems: [],
      };
      deliverystream.childrenItems?.forEach((releasetrain) => {
        let releaseTrainObj: OjectTeamDataResponse = null;
        let filteredData = checkFPTInvolved(releasetrain);

        releaseTrainObj = {
          ...releasetrain,
          childrenItems: filteredData,
        };
        deliveryObj.childrenItems.push(releaseTrainObj);
      });
      portfolioObject.childrenItems.push(deliveryObj);
    });
    list.push(portfolioObject);
  });
  return list;
};

// filter Chart data by Tree data
export const checkByTeam = (data: OjectTeamDataResponse[], checkedNodes: React.Key[]) => {
  let result: OjectTeamDataResponse[] = [];
  data?.forEach((port) => {
    let portToAdd: OjectTeamDataResponse = {
      ...port,
      childrenItems: [],
    };
    port.childrenItems?.forEach((deli) => {
      let deliToAdd: OjectTeamDataResponse = {
        ...deli,
        childrenItems: [],
      };

      deli.childrenItems?.forEach((rt) => {
        let countInvisibleItem = 0;

        let releasTrainToAdd: OjectTeamDataResponse = {
          ...rt,
          childrenItems: [],
        };
        rt.childrenItems?.forEach((team) => {
          let check = checkedNodes.findIndex((x) => x === team.id);
          if (check > -1) {
            releasTrainToAdd.childrenItems.push(team);
            countInvisibleItem++;
          } else {
            releasTrainToAdd.childrenItems.push({ ...team, isdisplay: false });
          }
        });
        let checkRt = checkedNodes.findIndex((x) => x === rt.id);
        if (checkRt > -1 || countInvisibleItem > 0) deliToAdd.childrenItems.push(releasTrainToAdd);
      });
      let checkDeli = checkedNodes.findIndex((x) => x === deli.id);
      if (deliToAdd.childrenItems.length > 0 || checkDeli > -1) {
        portToAdd.childrenItems.push(deliToAdd);
      }
    });
    if (portToAdd.childrenItems.length > 0) {
      result.push(portToAdd);
    }
  });
  return result;
};

// create dataNode for Filter Tree
export const filterNodeFPTInvolved = (data: OjectTeamDataResponse[], isFPTInvolved: boolean) => {
  let newNodes = transferObject(data, 0, isFPTInvolved);
  let highestLevel: DataNode[] = [
    {
      title: 'Select all',
      key: 0,
      children: newNodes,
    },
  ];
  return highestLevel;
};

const transferObject = (data: OjectTeamDataResponse[], level: number, isFPTInvolved: boolean) => {
  if (level < 4) {
    let newObject: DataNode[] = data?.map((data) => ({
      title: data.name,
      key: data.id,
      children: transferObject(data.childrenItems, data.type, isFPTInvolved),
      disableCheckbox: isFPTInvolved && data.type === TeamType.Team && !data.isFPTInvolvded,
    }));
    return newObject;
  }
};

const checkFPTInvolved = (dataTeam: OjectTeamDataResponse) => {
  if (dataTeam.type === 3) {
    let sub = dataTeam.childrenItems;
    let result = sub?.filter((x) => x.isFPTInvolvded === true);
    return result;
  }
};

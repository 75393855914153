import notification from 'antd/lib/notification';

export const openNotificationByType = (type: string, description: string) => {
  notification[type]({
    message: `Notification!!!`,
    description,
    placement: 'topRight',
  });
};

export const openNotification = (statusCode: number, message: string) => {
  notification.error({
    message: `Error: ${statusCode}`,
    description: message,
    duration: 2,
  });
};

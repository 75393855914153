import { useEffect, useState } from 'react';
import { MobileBreakPoint } from '../common/constants';

const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setIsMobile(width <= MobileBreakPoint);
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return [isMobile];
};

export default useIsMobile;

/* eslint-disable react/display-name */
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { exportAward, fetchAllAward } from 'app/apis/halloffame';
import { EditableColumn } from 'app/components/EditableTable';
import { ErrorBox } from 'app/components/ErrorBox';
import { useFetch } from 'app/hooks/useFetch';
import { HallOfFameReportFilter, HallOfFameReportItem } from 'app/types/HallOfFameReport';
import { downloadFileFromBlob } from 'app/utils/importUtils';
import { isNull } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { PageTitle } from 'app/layouts/AdminLayout.styled';

const { Search } = Input;

export const HallOfFameReport = () => {
  const searchEl = useRef(null);

  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();
  const [total, setTotal] = useState(null);
  const [filterData, setFilterData] = useState<HallOfFameReportFilter>();

  const [page, setPage] = useState(1);

  const { data: awards, loading: awardsLoading } = useFetch(fetchAllAward);
  const yearOptions = useMemo(
    () =>
      (awards?.listAwards ?? [])
        .map((x) => x.year)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((x) => ({ text: x, value: x })),
    [awards]
  );
  const teamOptions = useMemo(
    () =>
      (awards?.listAwards ?? [])
        .map((x) => x.presentativeFor)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((x) => ({ text: x, value: x })),
    [awards]
  );
  const requestTypeOptions = useMemo(
    () =>
      (awards?.listAwards ?? [])
        .map((x) => x.requestType)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((x) => ({ text: x, value: x })),
    [awards]
  );

  const pageSize = 20;

  useEffect(() => {
    if (!awards) return;

    setTotal(awards.total);
  }, [awards]);

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ...`}
            onSearch={() => confirm()}
            onPressEnter={() => confirm()}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const columns = useMemo<EditableColumn<HallOfFameReportItem>[]>(
    () => [
      {
        title: '#',
        width: '5%',
        render: (value, record, index) => index + 1,
        align: 'center',
      },
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        filters: yearOptions,
        onFilter: (value: string, record) => record.year === value,
      },
      {
        title: 'Request For',
        dataIndex: 'requestFor',
        key: 'requestFor',
        filters: [
          { text: 'Quarter', value: 'Quarter' },
          { text: 'Month', value: 'Month' },
          { text: 'Year', value: 'Year' },
        ],
        onFilter: (value: string, record) => record.requestFor === value,
      },
      {
        title: 'Date',
        dataIndex: 'requestForValue',
        key: 'requestForValue',
      },
      {
        title: 'Request Type',
        dataIndex: 'requestType',
        filters: requestTypeOptions,
        onFilter: (value: string, record) => record.requestType === value,
      },
      {
        title: 'Award Type',
        dataIndex: 'type',
        filters: [
          { text: 'Individual', value: 'Individual' },
          { text: 'Team', value: 'Team' },
        ],
        onFilter: (value: string, record) => record.type === value,
      },
      {
        title: 'Presentative For',
        dataIndex: 'presentativeFor',
        filters: teamOptions,
        onFilter: (value: string, record) => record.presentativeFor === value,
      },
      {
        title: 'Award To',
        dataIndex: 'awardTo',
        inputType: 'select',
        ...getColumnSearchProps('awardTo'),
      },
      {
        title: 'Award Amount',
        dataIndex: 'amount',
        inputType: 'select',
        sorter: {
          compare: (a, b) => a.amount - b.amount,
        },
        render: (value) => (
          <div style={{ textAlign: 'right' }}>{value?.toLocaleString('de-DE', { minimumFractionDigits: 0 })}</div>
        ),
      },
    ],
    [yearOptions, requestTypeOptions, teamOptions]
  );

  const handleFilter = (filter, extra) => {
    setFilterData(filter);
    const filteredData = extra.currentDataSource;
    setTotal(
      filteredData.reduce(function (acc, obj) {
        return acc + obj.amount;
      }, 0)
    );
  };

  const downloadFile = async () => {
    try {
      setDownloadLoading(true);
      const result = await exportAward(filterData);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
      setHasError(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>ADM Tool | Hall of Fame</title>
      </Helmet>
      <Row>
        <Col span={15}>
          <PageTitle>
            <Title level={3}>HALL OF FAME</Title>
          </PageTitle>
        </Col>
        <Col span={9} style={{ display: 'table' }}>
          <Button
            className="btn-DownloadXML"
            size="large"
            onClick={() => downloadFile()}
            loading={downloadLoading}
            style={{ float: 'right' }}
          >
            <DownloadOutlined />
          </Button>
        </Col>
      </Row>
      <Row className="team-header">
        <p style={{ float: 'right' }}>
          Total spend for filter below:&nbsp;
          <strong>{total?.toLocaleString('en-US', { minimumFractionDigits: 0 })} VND</strong>
        </p>
      </Row>
      <ErrorBox error={hasError} message={hasError} />
      <Table
        rowKey="id"
        loading={awardsLoading}
        pagination={{
          pageSize,
          current: page,
          hideOnSinglePage: true,
          onChange: (page) => setPage(page),
        }}
        dataSource={awards?.listAwards}
        columns={columns}
        bordered
        onChange={(paging, filter, sorter, extra) => handleFilter(filter, extra)}
      />
    </div>
  );
};

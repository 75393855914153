import { Button, notification, Select, Tag } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
import { StyledInfomationTags } from './InfomationTags.styled';
import { useEffect, useState } from 'react';
import { createProduct, createTeamDetail, fetchProduct } from 'app/apis/statisticOrganization';
import { CreateBy, OrganizationFormPayload, Product, ProductPayload, ProductType } from 'app/types/Organizational';
import { TagsModal } from '../TagsModal/TagsModal';

type Option = {
  value: string;
  label: string;
};

interface IInfomationTags {
  type: ProductType;
  title: string;
  resetFlag: boolean;
  teamDataSubmit: OrganizationFormPayload;
  resetTags: (reset: boolean) => void;
}

export const InfomationTags = ({ type, title, resetTags, resetFlag, teamDataSubmit }: IInfomationTags) => {
  const [data, setData] = useState<string[]>([]);
  const [tempData, setTempData] = useState<string[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [globalOptions, setGlobalOption] = useState<Product[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string>();

  const getTags = async () => {
    const data = await fetchProduct();
    let options: Option[] = data
      .filter((x) => x.type === type)
      .map((prods) => ({
        value: prods.name,
        label: prods.name,
      }));
    let productData: Product[] = data.map((prods) => ({
      name: prods.name,
      type: prods.type,
    }));
    setOptions(options);
    setGlobalOption(productData);
  };

  useEffect(() => {
    getTags();
    if (teamDataSubmit && data && data.length < 1) {
      setData(teamDataSubmit.products?.filter((x) => x.type === type).map((x) => x.name) ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDataSubmit]);

  const onEditTags = () => {
    setIsEdit(true);
    setTempData(data);
  };

  const onCancelEditTags = () => {
    setIsEdit(false);
    setData(tempData);
  };

  const onOpenModal = (tagName: string) => {
    setOpenModal(true);
    setSelectedTag(tagName);
  };

  const onSaveTags = async () => {
    setIsSubmit(true);
    try {
      const rest = teamDataSubmit.products?.filter((x) => x.type !== type) ?? [];
      let result: OrganizationFormPayload = {
        ...teamDataSubmit,
        products: data
          .map((x) => ({
            name: x,
            type: type,
          }))
          .concat(rest),
      };
      var newProduct = data.filter(function (pro) {
        return !globalOptions.some(function (newPro) {
          return pro.toLowerCase() === newPro.name.toLowerCase() && newPro.type === type;
        });
      });
      if (newProduct.length > 0) {
        let result: ProductPayload[] = newProduct.map((item) => ({
          name: item,
          type: type,
          createBy: CreateBy.User,
        }));
        await createProduct(result);
      }
      await createTeamDetail(result);
      setIsEdit(false);
      resetTags(!resetFlag);
    } catch (error) {
      notification.error({
        message: `Error: ${error.message}`,
        description: error,
        duration: 2,
      });
    } finally {
      setIsSubmit(false);
    }
  };
  return (
    <StyledInfomationTags>
      {selectedTag && (
        <TagsModal tagName={selectedTag} visible={openModal} onClose={() => setOpenModal(false)} currentType={type} />
      )}
      <div className="header">
        <span>
          <TagsOutlined />
        </span>
        <span className="title">{title}</span>
        {teamDataSubmit && (
          <>
            {isEdit ? (
              <span>
                <Button type="link" onClick={() => onSaveTags()} loading={isSubmit}>
                  Save
                </Button>
                <Button type="link" onClick={() => onCancelEditTags()} disabled={isSubmit}>
                  Cancel
                </Button>
              </span>
            ) : (
              <span>
                <Button type="link" onClick={() => onEditTags()}>
                  Edit
                </Button>
              </span>
            )}
          </>
        )}
      </div>
      {teamDataSubmit && (
        <>
          {isEdit ? (
            <Select
              tagRender={(props) => {
                // eslint-disable-next-line react/prop-types
                const { value } = props;
                return <Tag>{value}</Tag>;
              }}
              options={options}
              placeholder="Tag"
              style={{ width: '100%' }}
              mode="tags"
              defaultValue={data}
              onChange={(value) => setData(value)}
            />
          ) : (
            <div className="tags-container">
              {data?.map((item) => (
                <Tag key={item} onClick={() => onOpenModal(item)}>
                  {item}
                </Tag>
              ))}
            </div>
          )}
        </>
      )}
    </StyledInfomationTags>
  );
};

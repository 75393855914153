import { Progress, TablePaginationConfig, Tooltip } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import './OverviewTable.scss';
import { DefectColor, GroupedTeamLinks, TABLE_DEFAULT_PAGE_SIZE, UserStoriesColor } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { CardsCounter, TeamIterationMonitorOverview } from 'app/types/TeamDetailTypes';
import { TeamParentContext } from 'app/contexts/TeamParentContext';
import { Link } from 'react-router-dom';

type IOverviewTableProps = {
  dataSource: TeamIterationMonitorOverview[];
  title: String;
};

export const OverviewTable = ({ dataSource, title }: IOverviewTableProps) => {
  const { loading } = useContext(TeamParentContext);

  const [data, setData] = useState<TeamIterationMonitorOverview[]>();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  useEffect(() => {
    if (!dataSource) return;

    setData(dataSource);
  }, [dataSource]);
  const PercentageBar = ({ value }: any) => {
    return (
      <div className="progress-container">
        <Progress
          className="bar"
          strokeColor="#0066FF"
          percent={value > 100 ? 100 : value}
          showInfo={false}
          strokeLinecap="square"
        />
        <div className="percentage-value">
          {value === Infinity ? 'N/A' : value === 0 || value === 100 ? value : value?.toFixed(1)}
          {value !== Infinity && '%'}
        </div>
      </div>
    );
  };
  const renderCapacity = (capacity) => {
    return {
      children: <PercentageBar value={capacity} />,
    };
  };
  const renderPoints = (points) => {
    return {
      children: <PercentageBar value={points} />,
    };
  };

  const calculateWidth = (defects: number, total: number) => {
    return (defects / total) * 100;
  };

  const renderCards = (cards: CardsCounter) => {
    const tooltipData = (
      <>
        <div className="tooltip-card">
          <div>
            <svg className="bullet-point">
              <circle cx="5" cy="5" r="5" strokeWidth={3} fill={DefectColor} />
            </svg>
            <span>Defects</span>
          </div>
          <div>
            <span>{Math.round(cards.defects)}</span>
          </div>
        </div>
        <div className="tooltip-card">
          <div>
            <svg className="bullet-point">
              <circle cx="5" cy="5" r="5" strokeWidth={3} fill={UserStoriesColor} />
            </svg>
            <span>User Stories</span>
          </div>
          <div>
            <span>{Math.round(cards.us)}</span>
          </div>
        </div>
      </>
    );

    return (
      <table className="test-executed-table">
        <Tooltip
          title={tooltipData}
          overlayInnerStyle={{ background: 'white', color: 'black', width: '240px', fontSize: '14px', padding: '10px' }}
        >
          <tr className="row-test-executed-state">
            {cards.defects > 0 && (
              <td
                width={`${
                  calculateWidth(cards.defects, cards.defects + cards.us) > 10
                    ? calculateWidth(cards.defects, cards.defects + cards.us)
                    : 10
                }%`}
                style={{ background: '#F3C300' }}
              >
                <span className="hidden-record">{cards.defects}</span>
              </td>
            )}
            {cards.us > 0 && (
              <td
                width={`${
                  calculateWidth(cards.us, cards.defects + cards.us) > 10
                    ? calculateWidth(cards.us, cards.defects + cards.us)
                    : 10
                }%`}
                style={{ background: '#0066ff' }}
              >
                <span className="hidden-record">{cards.us}</span>
              </td>
            )}
          </tr>
        </Tooltip>
      </table>
    );
  };

  const renderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`/${GroupedTeamLinks[record.type]}/${record.id}`}>
          {text}
        </Link>
      </>
    );
  };

  const columns = useMemo<EditableColumn<any>[]>(
    () => [
      {
        title: '#',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        width: '3%',
        align: 'center',
      },
      {
        title: title,
        dataIndex: 'teamName',
        width: '37%',
        sorter: (a, b) => a.teamName.localeCompare(b.teamName),
        render: (value, record) => renderLink(value, record),
      },
      {
        title: 'Capacity',
        dataIndex: 'capacity',
        render: (capacity) => renderCapacity(capacity),
        className: 'bg-capacity',
        width: '20%',
        sorter: (a, b) => a.capacity - b.capacity,
      },
      {
        title: 'Velocity',
        dataIndex: 'points',
        render: (points) => renderPoints(points),
        className: 'bg-points',
        sorter: (a, b) => a.points - b.points,
        width: '20%',
      },
      {
        title: 'Cards',
        dataIndex: 'cards',
        render: (cards) => renderCards(cards),
        width: '20%',
        className: 'bg-cards',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, title]
  );

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  return (
    <div className="iteration-overview-monitor-container">
      <EditableTable
        onChange={onChange}
        pageSize={pageSize}
        current={page}
        loading={loading}
        columns={columns}
        data={data}
        bordered
        showAction={false}
      />
    </div>
  );
};

import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { PageTitle } from 'app/pages/TeamDetail/TeamDetail.styled';
import { GroupedTeamDataReportResponse } from 'app/types/GroupedTeamModel';
import { TeamType } from 'app/types/team';
import { useState } from 'react';
import ToolTip from '../Tooltip/ToolTip';
import './IterationDetail.scss';
import { IterationDetailTable } from './IterationDetailTable/IterationDetailTable';

interface IIterationDetail {
  dataSource: GroupedTeamDataReportResponse;
  type: TeamType;
}

export const IterationDetail = ({ dataSource, type }: IIterationDetail) => {
  const tabs = [
    { label: 'Overview', value: 'Overview' },
    { label: 'Detail', value: 'Detail' },
  ];
  const [currentTab, setCurrentTab] = useState('Overview');
  const onChangeTab = ({ target: { value } }: RadioChangeEvent) => {
    setCurrentTab(value);
  };
  return (
    <>
      <Row className="iteration-detail-title" gutter={16}>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <PageTitle level={5}>ITERATION DETAIL</PageTitle>
          <ToolTip title={null} />
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <Radio.Group
            options={tabs}
            onChange={onChangeTab}
            value={currentTab}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
      </Row>
      <IterationDetailTable currentTab={currentTab} dataSource={dataSource} type={type} />
    </>
  );
};

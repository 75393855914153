import { useEffect, useState } from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { OverviewNoteWrapper } from './OverviewNote.styled';
import { isEmpty } from 'lodash';
import { useRef } from 'react';

interface IProps {
  content: string;
  onSave: (note: string) => Promise<void>;
  noMargin?: boolean;
  enableEdit?: boolean;
}

export const OverviewNote = ({ content, noMargin, onSave }: IProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [note, setNote] = useState<string>(content);
  const noteRef = useRef(null);

  useEffect(() => {
    setNote(content);
  }, [content]);

  return (
    <OverviewNoteWrapper className={!noMargin ? 'm-t' : ''}>
      <Row>
        <Col span={24}>
          <span>
            <strong>Note</strong>
          </span>
          {isEdit ? (
            <span data-html2canvas-ignore>
              <Button
                type="link"
                onClick={async () => {
                  const content = noteRef.current.resizableTextArea.textArea.value;
                  await onSave(content);
                  setNote(content);
                  setIsEdit(false);
                }}
              >
                Save
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  // Revert back to old note

                  setIsEdit(false);
                }}
              >
                <Button type="link">Cancel</Button>
              </Popconfirm>
            </span>
          ) : (
            <Button
              data-html2canvas-ignore
              type="link"
              onClick={() => {
                // Set this for revert back when click 'Cancel'
                setIsEdit(true);
              }}
            >
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {isEdit ? (
            <TextArea
              defaultValue={note}
              rows={4}
              showCount
              autoSize={{
                minRows: 4,
                maxRows: 10,
              }}
              ref={noteRef}
            />
          ) : (
            <p className="notes">{!isEmpty(note) ? note : <i>No note</i>}</p>
          )}
        </Col>
      </Row>
    </OverviewNoteWrapper>
  );
};

import React, { ReactNode } from 'react';
import { Row } from 'antd';
import './SubCommonSection.scss';

type Props = {
  title: string;
  children: ReactNode;
};

export const SubCommonSection = ({ title, children }: Props) => {
  return (
    <div className="sub-section-wrapper">
      <Row justify="center">
        <p className="title">{title}</p>
      </Row>
      {children}
    </div>
  );
};

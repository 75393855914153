import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Tag, notification } from 'antd';
import Search from 'antd/lib/input/Search';
import {
  createProduct,
  createTeamDetail,
  deleteProductTeamDetailSync,
  fetchProduct,
  fetchTagsDependencies,
  fetchTeamDetail,
  updateProductTeamDetailSync,
} from 'app/apis/statisticOrganization';
import { fetchAllTeams } from 'app/apis/teamClient';
import { DefinitionConfigContext } from 'app/contexts/DefinitionConfigContext';
import { CreateBy, OrganizationFormPayload, Product, ProductPayload, ProductType } from 'app/types/Organizational';
import { TagsTable } from 'app/types/TagsDefinition';
import { isNull, orderBy } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { EditableColumn, EditableTable } from '../../components/EditableTable';
import { ErrorBox } from '../../components/ErrorBox';
import { deleteItem, insertItem, updateItem } from '../../utils/tableUtils';
import './Definition.scss';
import { TagsModal } from 'app/components/TeamOverview/TagsModal/TagsModal';
// import { OptionType } from 'antd/lib/select';

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

interface TagForm {
  name: string;
  teamId?: number;
}

type Option = {
  value: number;
  label: string;
};

export const WorkloadsDefinition = () => {
  const searchEl = useRef(null);
  const { workloads, setWorkloads } = useContext(DefinitionConfigContext);
  const [addTagDialog, showAddTagDialog] = useState(false);
  const [formWorkloads] = Form.useForm<TagForm>();
  const [hasError] = useState<Response>();
  const [WorkloadsTableData, setWorkloadsTableData] = useState([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [teamId, setTeamId] = useState<number>();
  const [globalOption, setGlobalOption] = useState<Product[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRecordTag, setSelectedRecordTag] = useState<string>('');

  const [teamDataSubmit, setTeamDataSubmit] = useState<OrganizationFormPayload>();

  useEffect(() => {
    const getTeamDetail = async () => {
      let resp = await fetchTeamDetail(teamId);
      setTeamDataSubmit(resp);
    };
    if (teamId) {
      getTeamDetail();
    }
  }, [teamId, workloads]);

  const getTeamsForOptions = async () => {
    const teams = await fetchAllTeams();
    let options: Option[] = teams?.map((team) => ({
      value: team.id,
      label: team.name,
    }));
    setOptions(options);
  };

  const getProducts = async () => {
    const products = await fetchProduct();
    let productData: Product[] = products.map((prods) => ({
      name: prods.name,
      type: prods.type,
    }));
    setGlobalOption(productData);
  };

  useEffect(() => {
    getTeamsForOptions();
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ${dataIndex}`}
            onSearch={() => confirm()}
            onPressEnter={() => confirm()}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      if (dataIndex === 'name') return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  useEffect(() => {
    async function fetchWorkloadsTableData() {
      if (!workloads) {
        return;
      }
      const fetchedWorkloadsTableData = await Promise.all(
        workloads.map(async (workload) => {
          const dependencies = await fetchTagsDependencies(workload.name, 0);
          return {
            ...workload,
            id: workload.id,
            name: workload.name,
            countTeam: dependencies?.length ?? 0,
          };
        })
      );
      const uniqueNames = new Set();
      const uniqueFetchedWorkloadsTableData: TagsTable[] = fetchedWorkloadsTableData.filter((item) => {
        if (!uniqueNames.has(item.name)) {
          uniqueNames.add(item.name);
          return true;
        }
        return false;
      });
      setWorkloadsTableData(orderBy(uniqueFetchedWorkloadsTableData, ['countTeam'], 'desc'));
    }
    getProducts();
    if (workloads) {
      fetchWorkloadsTableData();
    }
  }, [workloads, teamDataSubmit]);

  const onOpenModal = (tagName: string) => {
    setOpenModal(true);
    setSelectedRecordTag(tagName);
  };

  const WorkloadsColumns: EditableColumn<TagsTable>[] = [
    {
      title: 'Workload Name',
      dataIndex: 'name',
      inputType: 'text',
      width: '65%',
      ...getColumnSearchProps('name'),
      editable: true,
    },
    {
      title: 'Number of Teams',
      dataIndex: 'countTeam',
      inputType: 'number',
      editable: false,
      width: '25%',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.countTeam - b.countTeam,
      render: (text, record) => (
        <Tag key={text} onClick={() => onOpenModal(record.name)}>
          {text}
        </Tag>
      ),
    },
  ];

  const onDeleteProduct = async (id: number) => {
    try {
      await deleteProductTeamDetailSync(id);
      const newData = deleteItem(id, workloads);
      setWorkloads(newData);
    } catch (error) {
      openNotification(400, 'Delete Workload failed!');
    }
  };

  const onSaveProduct = async (id: number, { name }: TagsTable) => {
    try {
      await updateProductTeamDetailSync(id, name);
      const newData = updateItem({ id, name }, workloads);
      setWorkloads(newData);
    } catch (error) {
      error?.data?.error?.message && openNotification(400, error?.data?.error?.message);
    }
  };

  const onSubmitTagForm = async () => {
    try {
      await formWorkloads.validateFields();
      const { name, teamId } = formWorkloads.getFieldsValue();
      const newProduct = {
        name: name,
        type: ProductType.Workload,
      };
      formWorkloads.resetFields();
      if (teamDataSubmit) {
        const checkProductExistInTeam = teamDataSubmit?.products?.find(
          (item) => item.name.toLowerCase() === newProduct.name.toLowerCase() && item.type === newProduct.type
        );
        if (checkProductExistInTeam) {
          notification.error({
            message: `Error: Workload already exist`,
            duration: 4,
          });
          showAddTagDialog(false);
          return;
        } else {
          const rest =
            teamDataSubmit?.products?.filter(
              (x) => x.type !== ProductType.Workload || x.name.toLowerCase() !== newProduct.name.toLowerCase()
            ) ?? [];
          let result: OrganizationFormPayload = {
            ...teamDataSubmit,
            products: (!checkProductExistInTeam ? [newProduct] : []).concat(rest),
          };
          if (!teamDataSubmit.products) {
            result = {
              ...teamDataSubmit,
              products: [newProduct],
            };
          }
          if (teamDataSubmit?.scrumMaster !== null) {
            if (teamDataSubmit?.scrumMaster.email === '') {
              teamDataSubmit.scrumMaster.email = 'example@fpt.com';
            }
          }
          await createTeamDetail(result);
          setTeamDataSubmit(result);
        }
      }
      const foundProduct = globalOption?.find(
        (item) => item.name.toLowerCase() === newProduct.name.toLowerCase() && item.type === newProduct.type
      );

      if (!foundProduct) {
        let result: ProductPayload = {
          ...newProduct,
          createBy: CreateBy.Admin,
        };
        const resp = await createProduct([result]);
        const newData = insertItem(resp[0], workloads);
        setWorkloads(newData);
      } else {
        showAddTagDialog(false);
        return;
      }
      showAddTagDialog(false);
    } catch (error) {
      error?.data?.error?.message && openNotification(400, error?.data?.error?.message);
    }
  };

  const openNotification = (statusCode: number, message: string) => {
    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 4,
    });
  };

  return (
    <div className="definition">
      <div>
        {selectedRecordTag && (
          <TagsModal
            tagName={selectedRecordTag}
            visible={openModal}
            onClose={() => setOpenModal(false)}
            currentType={ProductType.Workload}
          />
        )}
        <Button type="primary" className="btn-add-new-item" onClick={() => showAddTagDialog(true)}>
          Add New Tag
        </Button>
        <EditableTable
          pageSize={20}
          data={WorkloadsTableData}
          columns={WorkloadsColumns}
          onSave={onSaveProduct}
          onDelete={onDeleteProduct}
          isEdit={true}
          isDelete={true}
        />
      </div>
      <Modal
        title="Add New Tag"
        visible={addTagDialog}
        onOk={onSubmitTagForm}
        onCancel={() => showAddTagDialog(false)}
        destroyOnClose={true}
      >
        <ErrorBox error={hasError} message="The Workload name already exist." />
        <Form {...layout} className="add-modal" form={formWorkloads} name="basic" preserve={false}>
          <Form.Item label="Workload name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Team Name"
            name="Team Name"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              showSearch
              placeholder="Select a Team"
              filterOption={(value, option) => option.label.toString().toLowerCase().includes(value.toLowerCase())}
              onChange={(value: number) => setTeamId(value)}
              options={options}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

import { Row, Space } from 'antd';
import { CriticalStatusColor, GoodStatusColor, RiskStatusColor } from 'app/common/constants';
import { CommonSection } from 'app/components/CommonSection/CommonSection';
import {
  DevelopmentTeamAndIssueData,
  FilterStatus,
  TeamDetail,
  WeeklyIssueTypeColour,
  WeeklyStatusPortfoliSection,
} from 'app/types/WeeklyOverview';
import { hex2rgba } from 'app/utils/utils';
import React, { useState } from 'react';
import { DotComponent, Label, TailInfo } from '../ReportWeeklyStatus.styled';
import { StatusDetail } from '../StatusDetail/StatusDetail';
interface Props {
  teams: WeeklyStatusPortfoliSection[];
  setToggleModal: (visible: boolean) => void;
  setSelectedTeamCardData: (data: TeamDetail) => void;
}

interface LabelStatusProps {
  color?: string;
  count: number;
  label: string;
  setListOption: (filter: FilterStatus) => void;
  option: FilterStatus;
}

const LabelStatus = ({ color, count, label, setListOption, option }: LabelStatusProps) => {
  const [switching, setSwitching] = useState<boolean>(
    color === CriticalStatusColor ? option.critical : color === RiskStatusColor ? option.risk : option.good
  );
  const onChangeOption = () => {
    setSwitching(!switching);
    switch (color) {
      case CriticalStatusColor:
        setListOption({
          ...option,
          critical: !switching,
        });
        break;
      case RiskStatusColor:
        setListOption({
          ...option,
          risk: !switching,
        });
        break;
      case GoodStatusColor:
        setListOption({
          ...option,
          good: !switching,
        });
        break;
    }
  };
  return (
    <Label onClick={onChangeOption}>
      <DotComponent color={switching ? color : hex2rgba(color, 0.3)} />
      <span className={`label-text ${!switching && 'disable'}`}>{label} </span>
      <span className={`label-text num ${!switching && 'disable'}`}>({count})</span>
    </Label>
  );
};
const RenderTailHeader = (
  dataSource: WeeklyStatusPortfoliSection[],
  setFilter: (filter: FilterStatus) => void,
  filter: FilterStatus
) => {
  let countCrit = 0;
  let countRisk = 0;
  let countGood = 0;
  dataSource.forEach((portfo) => {
    portfo.developmentTeams.forEach((data) => {
      let checkCrit = data.tags.findIndex((tag) => tag.type === WeeklyIssueTypeColour.Critical);
      let checkRisk = data.tags.findIndex((tag) => tag.type === WeeklyIssueTypeColour.Risk);
      if (checkCrit !== -1) {
        countCrit++;
      } else if (checkRisk !== -1) {
        countRisk++;
      } else {
        countGood++;
      }
    });
  });

  return (
    <TailInfo>
      <Row justify="end" align="middle">
        <Space size={40}>
          <LabelStatus
            color={CriticalStatusColor}
            label="Critical"
            count={countCrit}
            option={filter}
            setListOption={setFilter}
          />
          <LabelStatus
            color={RiskStatusColor}
            label="Risk"
            count={countRisk}
            option={filter}
            setListOption={setFilter}
          />
          <LabelStatus
            color={GoodStatusColor}
            label="Good"
            count={countGood}
            option={filter}
            setListOption={setFilter}
          />
        </Space>
      </Row>
    </TailInfo>
  );
};
export const DetailSection = ({ teams, setToggleModal, setSelectedTeamCardData }: Props) => {
  const [filterStatus, setFilterStatus] = useState<FilterStatus>({
    critical: true,
    good: true,
    risk: true,
  });
  const filterTeamStatus = (list: DevelopmentTeamAndIssueData[], type: WeeklyIssueTypeColour) => {
    let resultCrit = [];
    let resultRisk = [];
    let resultGood = [];
    list.forEach((data) => {
      let checkCrit = data.tags.findIndex((tag) => tag.type === WeeklyIssueTypeColour.Critical);
      let checkRisk = data.tags.findIndex((tag) => tag.type === WeeklyIssueTypeColour.Risk);
      if (checkCrit !== -1) {
        resultCrit.push(data);
      } else if (checkRisk !== -1) {
        resultRisk.push(data);
      } else {
        resultGood.push(data);
      }
    });
    if (type === WeeklyIssueTypeColour.Critical) return resultCrit;
    if (type === WeeklyIssueTypeColour.Risk) return resultRisk;
    if (type === WeeklyIssueTypeColour.Good) return resultGood;
  };

  return (
    <CommonSection title="Status Detail" tailContent={RenderTailHeader(teams, setFilterStatus, filterStatus)}>
      {teams
        .filter((p) => p.developmentTeams.length > 0)
        .map((p, i) => {
          const criticalData = filterStatus.critical
            ? filterTeamStatus(p.developmentTeams, WeeklyIssueTypeColour.Critical)
            : [];
          const riskData = filterStatus.risk ? filterTeamStatus(p.developmentTeams, WeeklyIssueTypeColour.Risk) : [];
          const goodData = filterStatus.good ? filterTeamStatus(p.developmentTeams, WeeklyIssueTypeColour.Good) : [];
          if (criticalData.length + riskData.length + goodData.length > 0)
            return (
              <Row key={i} className="portfolio-section">
                <StatusDetail
                  filter={filterStatus}
                  criticalTeams={criticalData}
                  riskTeams={riskData}
                  goodTeams={goodData}
                  title={p.portfoliosTitle}
                  setSelectedTeamCardData={setSelectedTeamCardData}
                  setToggleModal={setToggleModal}
                />
              </Row>
            );
          return null;
        })}
    </CommonSection>
  );
};

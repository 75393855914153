import { Bar } from '@ant-design/plots';
import { OwnershipLevelItem } from 'app/types/OwnershipProgramData';

interface IOwnershipChartStatistics {
  dataSource: OwnershipLevelItem[];
}
export const OwnershipLevelStatisticsChart = ({ dataSource }: IOwnershipChartStatistics) => {
  const data = dataSource.map((item) => ({
    teams: item.teams,
    level: item.level < 0 ? 'None' : `Level ${item.level}`,
    monthChange: item.teams - item.previousMonth,
  }));
  const config = {
    data,
    xField: 'teams',
    yField: 'level',
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    tooltip: {
      customContent: (title, data) => {
        return `<div>
                    <h4>Ownership Level Statistics</h4>
                    <br />
                    <p>Level: ${data[0]?.data?.level}</p>
                    <p>Teams: ${data[0]?.data?.teams}</p>
                    <p>MonthChange: ${data[0]?.data?.monthChange}</p>
                </div>`;
      },
    },
  };
  return <Bar {...config} />;
};

import { Col, Row, Image } from 'antd';
import Title from 'antd/lib/typography/Title';
import { fetchTaskByIteration } from 'app/apis/teamDetailClient';
import { IterationGoal } from 'app/components/IterationGoals/IterationGoal';
import RetrospectiveNoteSection from 'app/components/RetrospectiveNote/RetrospectiveNoteSection';
import { TeamCapacity } from 'app/components/TeamCapacity/TeamCapacityPlan/TeamCapacity';
import { Velocity } from 'app/components/Velocity/Velocity';
import { WorkItem } from 'app/pages/WorkItem/WorkItem';
import { RetrospectiveType } from 'app/types/entity';
import { Stage } from 'app/types/TeamDetailTypes';

import { useContext } from 'react';
import { StyledPlanWrapper, StyledPrePlanWrapper } from './Plan.styled';
import { PlanSection } from './PlanSection/PlanSection';
import { useFetch } from 'app/hooks/useFetch';
import { SubPage } from 'app/types/TeamDetail';
import { IterationContext } from 'app/contexts/IterationContext';
import { getNoteData, IIterationNote, NoteCategory } from 'app/types/IterationNote';
import { IterationNoteContext } from 'app/contexts/IterationNoteContext';
import { IterationNote } from '../IterationNote/IterationNote';
import { DeviderWrapper, PageTitle } from 'app/layouts/AdminLayout.styled';
import { CircleFillWithLabel } from 'app/components/CircleFillWithLabel/CircleFillWithLabel';
import ToolTip from 'app/components/Tooltip/ToolTip';
import { IterationBackLogContext } from 'app/contexts/IterationBackLogContext';
import { DemoPlan } from 'app/components/DemoPlan/DemoPlan';
import { EditableTable } from 'app/components/EditableTable';
import { Attendance } from 'app/components/Attendance/Attendance';
import { TeamHighLight } from 'app/components/TeamHighlight/TeamHighLight';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { QuarterReleasePlan } from 'app/components/QuarterReleasePlan/QuarterReleasePlan';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
interface IProps {
  stage: Stage;
  iterationId: number;
  saveNoteHandler: (iterationNote: IIterationNote) => Promise<void>;
}

export const Plan = ({ stage, ...restProps }: IProps) => {
  const { selectedIteration } = useContext(IterationContext);
  const { team: selectedTeam } = useContext(TeamDetailContext);
  const { previousIterationId } = useContext(IterationBackLogContext);
  const { iterationNotes } = useContext(IterationNoteContext);
  const { handleComponentClass, handleSectionClass } = useContext(DisplayComponentsContext);

  const { data: dataSource } = useFetch(() => {
    // Do not fetch data when user did not create plan before
    if (stage === undefined || stage === Stage.PrePlan) return;

    return fetchTaskByIteration(selectedIteration?.id);
  }, [selectedIteration?.id, stage]);

  if (stage === Stage.PrePlan)
    return (
      <StyledPrePlanWrapper>
        <Image src="/assets/img/dashboard.jpg" preview={false} width="100%" />
        <Title level={3} className="head-text">
          ITERATION PLAN
        </Title>
        <Title level={5} className="head-text">
          {`Click "Create Plan" button to start planning`}
        </Title>
      </StyledPrePlanWrapper>
    );

  return (
    <StyledPlanWrapper className="team-report-wrapper">
      {/* 
        Temporary ignore wrapping this block with PlanSection, waiting for future implementation from PlanSection
        (Adding custom action on the right of the title row)
      */}
      <Row className={`${handleComponentClass('plan-iteration-highlight')} section`}>
        <Col span={24}>
          <TeamHighLight page={SubPage.Plan} />
        </Col>
      </Row>
      <Row className={`${handleComponentClass('plan-iteration-goal')} section`}>
        <Col span={24}>
          <IterationGoal page={SubPage.Plan} />
        </Col>
      </Row>
      <Row className={`${handleComponentClass('plan-day-off-schedule')} work-item`}>
        <Col span={24}>
          <Attendance page={SubPage.Plan} classNames={handleComponentClass('plan-day-off-schedule')} />
        </Col>
      </Row>
      <div id="iteration-backlog-section" className={handleComponentClass('plan-iteration-backlog')}>
        <Row className="work-item-title">
          <Col span={14}>
            <PageTitle>
              <Title level={5}>
                {'ITERATION BACKLOG'}
                <ToolTip
                  title={
                    <span>
                      The Iteration Backlogs section provides a view of work items within this iteration. You can set
                      the commitment for each work item (Yes/No)
                    </span>
                  }
                />
              </Title>
            </PageTitle>
          </Col>
          {/* <Col span={10}>
            <Row justify="end">
              <CircleFillWithLabel color="#FF5A5A" size="25px" label="Carried Over" id="carried-over-icon" />
            </Row>
          </Col> */}
          <DeviderWrapper />
        </Row>

        <Row className="work-item">
          <Col span={24}>
            <WorkItem page={SubPage.Plan} />
            <IterationNote
              data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Plan, NoteCategory.IterationBacklogs)}
              onSave={async (note) => await restProps.saveNoteHandler(note)}
            />
          </Col>
        </Row>
      </div>
      {/* 
          TO DO: Try to enhance this but there is no way
          Some how if we put 2 continue Editable table, the col-group of the second table display wrong, maybe some conflict beetween column of 2 data is the reason,
          So for now, I'm put the null table here to make sure the column data has been cleared.
      */}
      <EditableTable data={[]} columns={[]} style={{ display: 'none' }} />

      <Row className={`${handleComponentClass('plan-demo-plan')} section`}>
        <Col span={24}>
          <DemoPlan page={SubPage.Plan} />
        </Col>
      </Row>

      <PlanSection
        title="VELOCITY AND CAPACITY"
        titleTootip={
          <span>
            VELOCITY AND CAPACITY allows you to easily view the velocity and capacity of your team in current iteration
            and whether the team is over or under expectation. This section pull work from the backlog and detailed task
            plan in current iteration.
          </span>
        }
        withBottomSpace
        classProp={handleSectionClass('plan-velocity-and-capacity')}
      >
        <Row gutter={16} className={handleComponentClass('plan-planned-velocity')}>
          <Col className="gutter-row" sm={24} md={12}>
            <Velocity />
          </Col>
          <Col className="gutter-row" sm={24} md={12}>
            <IterationNote
              data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Plan, NoteCategory.Velocity)}
              onSave={async (note) => await restProps.saveNoteHandler(note)}
              noMargin
            />
          </Col>
        </Row>
        <Row className={handleComponentClass('plan-team-detail')}>
          <Col>
            <TeamCapacity dataPlan={dataSource} />
            <IterationNote
              data={getNoteData(selectedIteration?.id, iterationNotes, SubPage.Plan, NoteCategory.CapacityDetail)}
              onSave={async (note) => await restProps.saveNoteHandler(note)}
            />
          </Col>
        </Row>
      </PlanSection>

      <PlanSection
        classProp={handleComponentClass('plan-follow-up-actions')}
        title="FOLLOW-UP ACTIONS"
        titleTootip={
          <span>
            Actions defined by your team in the Retrospective of previous iteration. Your team should strictly follow
            those actions to achieve improvement goals this iteration.
          </span>
        }
      >
        <Row className="note">
          {previousIterationId && (
            <Col span={24}>
              <RetrospectiveNoteSection
                backgroundColor="#FFEEFD"
                iterationId={previousIterationId}
                header="Actions of previous iteration"
                type={RetrospectiveType.Todo}
                iterationStage={stage}
                isSelectedIteration={false}
              />
            </Col>
          )}
        </Row>
      </PlanSection>
      {/* <QuarterReleasePlan
        key={selectedIteration.name}
        teamId={selectedTeam.id}
        iterationName={selectedIteration.name}
        className={handleComponentClass('plan-quarter-release-plan')}
      /> */}
    </StyledPlanWrapper>
  );
};

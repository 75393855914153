import { OverviewChartData } from 'app/types/OverviewChartData';
import { SaveChartSettingRequest } from 'app/types/SaveChartSettingRequest';
import { fetchAsync } from '../utils/fetch';

export const fetchOverviewVelocity = (teamId, iterationQ): Promise<OverviewChartData> =>
  fetchAsync(`/api/teamOverview/velocity/${teamId}/${iterationQ}`);

export const fetchOverviewThroughput = (teamId, iterationQ): Promise<OverviewChartData> =>
  fetchAsync(`/api/teamOverview/throughput/${teamId}/${iterationQ}`);

export const fetchOverviewUtilizedCapacity = (teamId, viewType): Promise<OverviewChartData> =>
  fetchAsync(`/api/teamOverview/utilized-capacity/${teamId}/${viewType}`);

export const fetchOverviewIterationDefects = (teamId, viewType): Promise<OverviewChartData> =>
  fetchAsync(`/api/teamOverview/iteration-defects/${teamId}/${viewType}`);

export const fetchOverviewIterationCompleteness = (teamId, viewType): Promise<OverviewChartData> =>
  fetchAsync(`/api/teamOverview/iteration-completeness/${teamId}/${viewType}`);

export const fetchOverviewIterationProductivity = (teamId, viewType): Promise<OverviewChartData> =>
  fetchAsync(`/api/teamOverview/iteration-productivity/${teamId}/${viewType}`);

export const saveChartSettingRequest = (data: SaveChartSettingRequest): Promise<any> => {
  return fetchAsync(`/api/teamOverview/saveChartSetting`, {
    method: 'POST',
    body: data,
  });
};

import { Column, ColumnConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import { UserStoryItem } from '../../types/ProgramTrends';
import { Col, Empty } from 'antd';
import { Card } from 'app/components/Card';
import {
  AcceptedChartColor,
  CompletedChartColor,
  DefinedChartColor,
  IdeaChartColor,
  InProgressChartColor,
  LabelTrendsChart,
  ReleasedChartColor,
  TitleChartStyle,
} from 'app/common/constants';
interface IUserStoryStatusChart {
  dataSource: UserStoryItem[];
  loading?: boolean;
}

export const UserStoryStatusChart = ({ dataSource, loading }: IUserStoryStatusChart) => {
  // The order of this list config make change to the order of type column chart
  const orderTypeConfig = [
    { name: 'Released', color: ReleasedChartColor },
    { name: 'Accepted', color: AcceptedChartColor },
    { name: 'Completed', color: CompletedChartColor },
    { name: 'In-Progress', color: InProgressChartColor },
    { name: 'Defined', color: DefinedChartColor },
    { name: 'Idea', color: IdeaChartColor },
  ];
  const groupArrayOfObjects = (list: any[], key: string) => {
    return list?.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const generateColumnConfig = (dataSource: UserStoryItem[]): ColumnConfig => {
    const customTargetLegend: LegendItem[] = [
      {
        name: 'Idea',
        value: 'idea',
        marker: {
          symbol: 'circle',
          style: {
            fill: IdeaChartColor,
          },
        },
      },
      {
        name: 'Defined',
        value: 'defined',
        marker: {
          symbol: 'circle',
          style: {
            fill: DefinedChartColor,
          },
        },
      },
      {
        name: 'In-Progress',
        value: 'inProgress',
        marker: {
          symbol: 'circle',

          style: {
            fill: InProgressChartColor,
          },
        },
      },
      {
        name: 'Completed',
        value: 'completed',
        marker: {
          symbol: 'circle',

          style: {
            fill: CompletedChartColor,
          },
        },
      },
      {
        name: 'Accepted',
        value: 'accepted',
        marker: {
          symbol: 'circle',

          style: {
            fill: AcceptedChartColor,
          },
        },
      },
      {
        name: 'Released',
        value: 'released',
        marker: {
          symbol: 'circle',

          style: {
            fill: ReleasedChartColor,
          },
        },
      },
    ];
    // group array by type
    const groupedData = groupArrayOfObjects(dataSource, 'type');
    // group array ordered by orderTypeConfig
    const dataByType = orderTypeConfig.map((cfg) => groupedData[cfg.name]);
    // destructure 2D array to 1D array
    const data = dataByType.reduce((prev, next) => {
      return prev?.concat(next);
    });
    return {
      data,
      legend: {
        position: 'top',
        items: customTargetLegend,
        flipPage: false,
      },
      autoFit: true,
      columnWidthRatio: 0.5,
      isStack: true,
      xField: 'iteration',
      yField: 'userStory',
      seriesField: 'type',
      color: orderTypeConfig.map((cfg) => cfg.color),
      xAxis: {
        label: LabelTrendsChart,
      },
      yAxis: {
        type: 'linear',

        title: {
          text: 'User stories',
          position: 'center',
          spacing: 20,
          style: TitleChartStyle,
        },
        position: 'left',
      },
    };
  };

  return (
    <Card title={'User Story Status'} loading={loading}>
      {!loading &&
        (!dataSource?.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Col span={24}>
            <Column {...generateColumnConfig(dataSource)} />
          </Col>
        ))}
    </Card>
  );
};

import React, { useMemo } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import { Col, Empty } from 'antd';
import { LabelTrendsChart, OwnershipLevelcolor, OwnershipLevelColors, OwnershipLevelTitle } from 'app/common/constants';
import { OwnershipLevelItem } from 'app/types/OwnershipProgramData';
import { getOwnershipLevelTitle, OwnershipLevel } from 'app/types/Ownership';
import { isEmpty, uniq } from 'lodash';
interface IUserStoryStatusChart {
  dataSource: OwnershipLevelItem[];
}

const generateColumnConfig = (dataSource: OwnershipLevelItem[]): ColumnConfig => {
  const customTargetLegendV2: LegendItem[] = uniq(dataSource.map((x) => x.level))
    .sort()
    .map((lv) => ({
      name: lv < 0 ? 'None' : `Level ${lv}`,
      value: lv,
      marker: {
        symbol: 'circle',

        style: {
          fill: lv < 0 ? OwnershipLevelcolor.none : OwnershipLevelColors[lv],
        },
      },
    }));

  const data = dataSource?.map((item) => ({
    name: item.name,
    percent: item.teams,
    type: getOwnershipLevelTitle(item.level),
  }));
  return {
    data,
    legend: {
      position: 'top',
      items: customTargetLegendV2,
      flipPage: false,
    },
    tooltip: {
      formatter: (datum) => ({
        name: `${datum.type}`,
        value: `${(datum.percent * 100).toFixed(1)} %`,
      }),
    },
    isPercent: true,
    autoFit: true,
    yAxis: {
      label: {
        formatter: (text: string) => Number(text) * 100,
      },
    },
    columnWidthRatio: 0.5,
    maxColumnWidth: 60,
    minColumnWidth: 30,
    isStack: true,
    xField: 'name',
    yField: 'percent',
    seriesField: 'type',
    color: [OwnershipLevelcolor.none, ...OwnershipLevelColors],
    xAxis: {
      label: {
        rotate: -Math.PI / 6,
        offset: 20,
        style: {
          fontSize: 12,
        },
        formatter: (value) => (value.length > 14 ? `${value.slice(0, 14)}...` : value),
      },
    },
  };
};

export const OwnershipLevelChart = ({ dataSource }: IUserStoryStatusChart) => {
  if (!dataSource) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Col span={24}>
      <Column {...generateColumnConfig(dataSource)} />
    </Col>
  );
};

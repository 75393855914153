import { Button } from 'antd';
import styled from 'styled-components';

export const StyledSyncNowButton = styled(Button)`
  background-color: #389e0d;
  color: #fff;

  &:hover,
  &:focus {
    border-color: #389e0d;
    background-color: #389e0d;
    color: #fff;
  }

  &.edit {
    background-color: #1890ff;
    &:hover,
    &:focus {
      border-color: #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }

  &.warning {
    background-color: #ffc600;

    &:hover,
    &:focus {
      border-color: #ffc600;
      background-color: #ffc600;
      color: #fff;
    }
  }
  &.warning.ant-btn {
    border-radius: 3px !important;
  }
`;

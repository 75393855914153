import { getAllNoteByPage, getNoteByPage } from 'app/apis/teamDetailClient';
import { useFetch } from 'app/hooks/useFetch';
import { IIterationNote } from 'app/types/IterationNote';
import { createContext, Dispatch, SetStateAction, useContext, useState, useEffect } from 'react';
import { IterationContext } from './IterationContext';
import { TeamContext } from './TeamContext ';

export interface IIterationNoteContext {
  iterationNotes: IIterationNote[];
  setIterationNotes: Dispatch<SetStateAction<IIterationNote[] | undefined>>;
  iterationNotesAll: IIterationNote[];
  setIterationNotesAll: Dispatch<SetStateAction<IIterationNote[] | undefined>>;
}
export const IterationNoteContext = createContext<IIterationNoteContext>(null);
interface IProps {
  children: React.ReactNode;
}

export const IterationNoteContextProvider = ({ children }: IProps) => {
  const [iterationNotes, setIterationNotes] = useState<IIterationNote[]>([]);
  const [iterationNotesAll, setIterationNotesAll] = useState<IIterationNote[]>([]);
  const { selectedIteration } = useContext(IterationContext);
  const { page } = useContext(TeamContext);

  useFetch(async () => {
    if (selectedIteration?.id) {
      if (page != undefined) {
        const notes = await getNoteByPage(selectedIteration?.id, page);
        setIterationNotes(notes);
      }
    }
  }, [selectedIteration?.id, page]);
  useFetch(async () => {
    if (page != undefined) {
      const notes = await getAllNoteByPage(page);
      setIterationNotesAll(notes);
    }
  }, [page]);
  return (
    <IterationNoteContext.Provider
      value={{ iterationNotes, setIterationNotes, iterationNotesAll, setIterationNotesAll }}
    >
      {children}
    </IterationNoteContext.Provider>
  );
};

import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { Card } from 'app/components/Card';
import { PageTitle } from 'app/pages/TeamDetail/TeamDetail.styled';
import { SelectOptions } from 'app/types/entity';
import { useState } from 'react';
import Detail from './Detail/Detail';
import Overview from './Overview/Overview';
import { PlanGroupedTeamCapacity } from './PlanGroupedTeamCapacity/PlanGroupedTeamCapacity';
import { PlanGroupedTeamVelocity } from './PlanGroupedTeamVelocity/PlanGroupedTeamVelocity';

export enum Tab {
  Overview,
  Detail,
}

export const tabOptions: SelectOptions<Tab>[] = [
  {
    label: 'Overview',
    value: 0,
  },
  {
    label: 'Detail',
    value: 1,
  },
];

interface IProps {
  titleColumn: string;
  teamType: number;
}

export const GroupedTeamPlan = ({ titleColumn, teamType }: IProps) => {
  const [tab, setTab] = useState(Tab.Overview);

  const tabOption = tabOptions;

  const onChangeTabType = ({ target: { value } }: RadioChangeEvent) => {
    setTab(value);
  };

  return (
    <div>
      <Row gutter={[24, 16]}>
        <Col span={12}>
          <Card title="Planned Velocity">
            <PlanGroupedTeamVelocity />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Planned Capacity">
            <PlanGroupedTeamCapacity />
          </Card>
        </Col>
      </Row>

      <Row className="iteration-detail-title" gutter={16}>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <PageTitle level={5}>ITERATION DETAIL</PageTitle>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <Radio.Group
            options={tabOption}
            onChange={onChangeTabType}
            value={tab}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {tab === Tab.Overview && <Overview titleColumn={titleColumn} teamType={teamType} />}
          {tab === Tab.Detail && <Detail titleColumn={titleColumn} teamType={teamType} />}
        </Col>
      </Row>
    </div>
  );
};

import { HolidayItem, HolidayRequest } from 'app/types/Holiday';
import { fetchAsync } from '../utils/fetch';
import moment from 'moment';

export const fetchAllHoliday = async (): Promise<HolidayItem[]> => {
  var listData = await fetchAsync('/api/holiday/all', {
    method: 'GET',
  });

  var result = listData.map((x: HolidayItem) => {
    let fromDateOfHoliday = splitDate(x.fromDateOfHoliday);
    let toDateOfHoliday = splitDate(x.toDateOfHoliday);
    const holiday: HolidayItem = {
      ...x,
      fromDateOfHoliday: fromDateOfHoliday,
      toDateOfHoliday: toDateOfHoliday,
    };
    return holiday;
  });
  return result;
};

export const createHoliday = async (request: HolidayRequest): Promise<HolidayItem> => {
  return fetchAsync('/api/holiday', {
    method: 'POST',
    body: request,
  });
};

export const updateHoliday = async (id: number, request: HolidayItem): Promise<HolidayItem> => {
  return fetchAsync(`/api/holiday/${id}`, {
    method: 'PUT',
    body: request,
  });
};

export const deleteHoliday = async (id: number): Promise<HolidayItem> => {
  return fetchAsync(`/api/holiday/${id}`, {
    method: 'DELETE',
  });
};

const splitDate = (date) => {
  if (date) {
    return moment(date).format('YYYY/MM/DD');
  }
  return null;
};

import { useState, useEffect } from 'react';
import { Area } from '@ant-design/charts';
import { AreaConfig } from '@ant-design/plots';
import { LegendItem } from '@antv/g2/esm/interface';
import { Done as DoneCFD, InProgressChartColor as InprogressCFD, Todo as TodoCFD } from 'app/common/constants';
import { IDataChartProps, TaskState } from 'app/types/CacheReportModel';
import { Empty } from 'antd';

export interface ICFDDataChartProps {
  teamId?: number;
  dataSource: any;
}

export const CumulativeDiagram = ({ dataSource }: ICFDDataChartProps) => {
  const [data, setData] = useState<TaskState[]>([]);
  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);
  const config: AreaConfig = {
    data,
    xField: 'dateId',
    yField: 'tasks',
    seriesField: 'state',
    width: 500,
    height: 300,
    color: [TodoCFD, InprogressCFD, DoneCFD],
    legend: {
      position: 'top',
      flipPage: false,
      items: customTargetLegend,
    },
    yAxis: {
      title: {
        text: 'Tasks',
        position: 'center',
        spacing: 20,
        style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
      },
      line: {
        style: {
          stroke: TodoCFD,
          shadowColor: TodoCFD,
          shadowBlur: 10,
          shadowOffsetX: 5,
          shadowOffsetY: 5,
        },
      },
    },
    xAxis: {
      range: [0, 1],
    },
    areaStyle: () => {
      return {
        fillOpacity: 1,
      };
    },
  };
  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return <Area {...config} />;
};

export const customTargetLegend: LegendItem[] = [
  {
    name: 'Todo',
    value: 'Todo',
    marker: {
      symbol: 'circle',
      style: {
        fill: TodoCFD,
      },
    },
  },
  {
    name: 'In-Progress',
    value: 'In-Progress',
    marker: {
      symbol: 'circle',
      style: {
        fill: InprogressCFD,
      },
    },
  },
  {
    name: 'Done',
    value: 'Done',
    marker: {
      symbol: 'circle',
      style: {
        fill: DoneCFD,
      },
    },
  },
];

import { useContext, useEffect, useRef, useState } from 'react';
import { IssueReportIdeaWrapper } from './IssueReportIdea.styled';
import { Row, Col, Button, TablePaginationConfig, Modal, Space, Avatar, Spin } from 'antd';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { SuccesType, TABLE_DEFAULT_PAGE_INDEX, roleNames } from 'app/common/constants';
import { IIssueReportIdeaResponse } from 'app/types/IssueReportIdeaModel';
import { useFetch } from 'app/hooks/useFetch';
import { getIssueReportIdeas, deleteIssueReportIdea } from 'app/apis/issueReportIdea';
import { useForm } from 'antd/es/form/Form';
import { EditorState, ContentState } from 'draft-js';
import { getEditorState } from 'app/components/IssueReportIdea/IssueReportIdeaHandle';
import Icon, { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { ErrorType } from 'app/common/constants';
import Search from 'antd/lib/input/Search';
import {
  IssueReportStatus,
  IssueReportStatusToText,
  IssueReportType,
  IssueReportTypeToText,
  IssueStatusItem,
} from 'app/components/IssueReportIdea/IssueReportIdeaHelper';
import { bindingData } from 'app/components/IssueReportIdea/IssueReportIdeaHandle';
import StoryIcon from '../storyicon';
import IssueIcon from '../issueicon';
import EnhanceIcon from '../enhanceicon';
import { nameAbbreviation } from 'app/utils/stringUtils';
import { UserContext } from 'app/contexts/UserContext';
import { UploadFile } from 'antd/lib/upload/interface';
import { IssueReportDetailInfo } from 'app/components/IssueReportIdea/IssueReportDetailInfo';
import { IssueReportIdeaFormModal } from 'app/components/IssueReportIdea/IssueReportIdeaFormModal';
import { Helmet } from 'react-helmet';
import { TeamContext } from 'app/contexts/TeamContext ';
import { isNull, orderBy } from 'lodash';

export enum formAction {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}

export function IssueReportIdea() {
  const searchEl = useRef(null);
  const [filterValue, setFilterValue] = useState<number>();
  const pageSize = 20;
  const [page, setPage] = useState<number>(TABLE_DEFAULT_PAGE_INDEX);
  const [tableData, setTableData] = useState<IIssueReportIdeaResponse[]>([]);
  const [typeModal, setTypeModal] = useState(formAction.Create);
  const [workItemsUserInfo, setWorkItemsUserInfo] = useState<string[]>();
  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const isAllowed = userRole === roleNames.pqa || userRole === roleNames.admin || userRole === roleNames.scrumMaster;
  const isAllowedForAdmin = userRole === roleNames.admin;

  // get list data
  const { data: issueReportIdeas } = useFetch<IIssueReportIdeaResponse[]>(() => {
    return getIssueReportIdeas();
  });

  const { setBreadcrumbs } = useContext(TeamContext);

  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'About ADM',
        parentCrumb: null,
        renderAsLink: false,
      },
      {
        path: '#',
        text: 'Feedbacks',
        parentCrumb: null,
        renderAsLink: true,
      },
    ]);
  }, [setBreadcrumbs]);

  // table
  useEffect(() => {
    if (issueReportIdeas != undefined) {
      issueReportIdeas.map((item) => {
        bindingData(item);
      });
    }
    setTableData(issueReportIdeas);
  }, [issueReportIdeas]);

  useEffect(() => {
    setWorkItemsUserInfo(Array.from(new Set(tableData?.map((i) => i.userInfo.fullName))));
  }, [tableData]);

  const handleFilterChange = (option) => {
    setFilterValue(Number(option.key));
    setPage(TABLE_DEFAULT_PAGE_INDEX);
  };

  const handleFilterClear = () => {
    setFilterValue(null);
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
  };

  // Modal popup - Form
  const [addModalState, setAddModalState] = useState(false);
  const [issueReportFormData, setIssueReportFormData] = useState<IIssueReportIdeaResponse>({
    id: 0,
    name: '',
    type: 0,
    status: 0,
    detail: '',
    creatorUserId: 0,
    creationTime: null,
    lastModificationTime: null,
    lastModifierUserId: 0,
    issueReportIdeaImages: [],
    userInfo: null,
    statusText: '',
    typeText: '',
    feedback: '',
  });

  useEffect(() => {
    setIssueReportFormData(issueReportFormData);
  }, [issueReportFormData]);

  const [form] = useForm();

  const [typeValue, setTypeValue] = useState(null);
  const [typePrefixIcon, setTypePrefixIcon] = useState(null);

  // Handle Upload Image
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // Modal
  const showModal = (formAct: formAction, formData) => {
    setAddModalState(true);
    setIssueReportFormData(formData);
    if (formAct === formAction.Edit) {
      setTypeModal(formAction.Edit);
      updateTypeSelect(formData.type);

      // Binding data for form
      form.setFieldsValue({
        id: formData.id,
        type: formData.type,
        name: formData.name,
        status: formData.status,
        // detail: EditorState.createWithContent(convertFromRaw(JSON.parse(formData.detail))),
        detail: formData.detail,
      });

      // Convert formData.detail to EditorState which editor can read and display it.
      const newEditorState = getEditorState(formData.detail);
      setEditorState(newEditorState);

      // set FileList for Upload Field to display on Edit modal
      var listImgs = [];
      formData.issueReportIdeaImages?.map((item) => {
        var newFile = {
          uid: `${item.id}`,
          name: item.fileName,
          status: 'done',
          url: item.path,
        } as UploadFile;
        listImgs.push(newFile);
      });
      setFileList(listImgs);
    } else {
      setTypeModal(formAction.Create);
      updateTypeSelect(null);
      form.resetFields();
      setFileList([]);
    }
  };

  const closeModal = () => {
    setAddModalState(false);
    // Reset Editor
    const emptyEditorState = EditorState.push(editorState, ContentState.createFromText(''), 'remove-range');
    setEditorState(emptyEditorState);

    // Reset Prefix Icon
    setTypePrefixIcon(null);
  };

  const updateTypeSelect = (value: any) => {
    setTypeValue(value);
    switch (value) {
      case IssueReportType.Idea:
        setTypePrefixIcon(<Icon component={StoryIcon} />);
        break;
      case IssueReportType.Enhance:
        setTypePrefixIcon(<Icon component={EnhanceIcon} />);
        break;
      case IssueReportType.Issue:
        setTypePrefixIcon(<Icon component={IssueIcon} />);
        break;
      default:
        setTypePrefixIcon(null);
    }
  };

  const { confirm } = Modal;
  const showDeleteConfirm = (id: number) => {
    confirm({
      title: 'Are you sure delete this record?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        handleDelete(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDelete = async (id: number) => {
    await deleteIssueReportIdea(id)
      .then((res) => {
        setTableData([...tableData.filter((item) => item.id !== id)]);
        openNotificationByType(SuccesType, 'Delete Successfully !');
      })
      .catch((err) => {
        openNotificationByType(ErrorType, 'Something Went Wrong !!!');
      });
  };

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ${dataIndex}`}
            onSearch={() => confirm()}
            onPressEnter={() => confirm()}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      if (dataIndex === 'name') return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const renderTypeColumn = (record: IIssueReportIdeaResponse) => {
    return (
      <Space>
        <Icon
          component={
            record.type === IssueReportType.Idea
              ? StoryIcon
              : record.type === IssueReportType.Issue
              ? IssueIcon
              : EnhanceIcon
          }
        />
        {IssueReportTypeToText(record.type)}
      </Space>
    );
  };

  const renderStatus = (item: IIssueReportIdeaResponse) => {
    return <IssueStatusItem item={item} />;
  };

  // Edittable
  const columns: EditableColumn<any>[] = [
    {
      title: 'Type',
      dataIndex: 'typeText',
      key: 'typeText',
      editable: false,
      width: '10%',
      align: 'left',
      filters: [
        { text: IssueReportTypeToText(IssueReportType.Idea), value: IssueReportType.Idea },
        { text: IssueReportTypeToText(IssueReportType.Issue), value: IssueReportType.Issue },
        { text: IssueReportTypeToText(IssueReportType.Enhance), value: IssueReportType.Enhance },
      ],
      render: (text, record) => renderTypeColumn(record),
      onFilter: (value: number, record) => record.type === value,
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '60%',
      onFilter: (value: string, record) => record.name.startsWith(value),
      ellipsis: true,
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Status',
      dataIndex: 'statusText',
      key: 'statusText',
      editable: false,
      width: '8%',
      align: 'left',
      render: (v, record, i) => renderStatus(record),
      filters: [
        { text: IssueReportStatusToText(IssueReportStatus.Todo), value: IssueReportStatus.Todo },
        { text: IssueReportStatusToText(IssueReportStatus.Confirm), value: IssueReportStatus.Confirm },
        { text: IssueReportStatusToText(IssueReportStatus.Inprogress), value: IssueReportStatus.Inprogress },
        { text: IssueReportStatusToText(IssueReportStatus.Done), value: IssueReportStatus.Done },
        { text: IssueReportStatusToText(IssueReportStatus.Reject), value: IssueReportStatus.Reject },
        { text: IssueReportStatusToText(IssueReportStatus.Duplicate), value: IssueReportStatus.Duplicate },
        { text: IssueReportStatusToText(IssueReportStatus.Reopen), value: IssueReportStatus.Reopen },
      ],
      // render: (value, record, index) => RenderText(value, index),
      onFilter: (value, record) => record.status === value,
      ellipsis: true,
    },
    {
      title: 'Created by',
      dataIndex: 'userInfo',
      key: 'userInfo',
      editable: false,
      width: '16%',
      align: 'left',
      ellipsis: true,
      // defaultSortOrder: 'descend',
      filters: orderBy(
        workItemsUserInfo?.map((userInfo) => ({
          text: userInfo,
          value: userInfo,
        }))
      ),
      onFilter: (value: any, record) => record.userInfo.fullName === value,
      render: (value, record) => {
        return (
          <Space>
            <Avatar
              style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle' }}
              size="large"
              src={record.userInfo?.avatarUrl}
            >
              {nameAbbreviation(record.userInfo.fullName)}
            </Avatar>
            {record.userInfo.fullName}
          </Space>
        );
      },
    },
    {
      title: 'Created date',
      dataIndex: 'creationTime',
      key: 'creationTime',
      editable: false,
      width: '9%',
      align: 'left',
      ellipsis: true,
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => 1,
    },
  ];

  return (
    <>
      <IssueReportIdeaWrapper>
        <Helmet>
          <title>ADM Tool | Feedbacks</title>
        </Helmet>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row>
              <Col span={14}>
                <span className="title" style={{ color: '#0E50A4' }}>
                  FEEDBACKS
                </span>
              </Col>
              <Col span={10} style={{ textAlign: 'right' }}>
                {isAllowed ? (
                  <Button
                    type="primary"
                    className="btn-add"
                    onClick={() => {
                      showModal(formAction.Create, {
                        name: '',
                        type: 0,
                        status: 0,
                        detail: '',
                        uploadImages: [],
                      });
                    }}
                  >
                    Create
                  </Button>
                ) : (
                  <></>
                )}
                <IssueReportIdeaFormModal
                  addModalState={addModalState}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  issueReportFormData={issueReportFormData}
                  setIssueReportFormData={setIssueReportFormData}
                  typeModal={typeModal}
                  setAddModalState={setAddModalState}
                  tableData={tableData}
                  setTableData={setTableData}
                  typeValue={typeValue}
                  setTypeValue={setTypeValue}
                  typePrefixIcon={typePrefixIcon}
                  fileList={fileList}
                  setFileList={setFileList}
                  isAllowedForAdmin={isAllowedForAdmin}
                  onCloseModal={() => closeModal()}
                  onBindingData={(data: IIssueReportIdeaResponse) => bindingData(data)}
                  onUpdateTypeSelect={(value: any) => updateTypeSelect(value)}
                  form={form}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div>
              {tableData && tableData.length > 0 ? (
                <EditableTable
                  columns={columns}
                  data={tableData}
                  current={page}
                  onChange={(pagination) => onChange(pagination)}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  showAction={false}
                  expandable={{
                    expandedRowRender: (record) => (
                      <IssueReportDetailInfo
                        record={record}
                        isAllowed={isAllowed}
                        onShowModal={(formAct: formAction, formData: IIssueReportIdeaResponse) =>
                          showModal(formAct, formData)
                        }
                        onShowDeleteConfirm={(id: number) => showDeleteConfirm(id)}
                        onGetEditorState={(detail: string) => getEditorState(detail)}
                      />
                    ),
                  }}
                />
              ) : (
                <Spin />
              )}
            </div>
          </Col>
        </Row>
      </IssueReportIdeaWrapper>
    </>
  );
}
export default IssueReportIdea;

import { Progress } from 'antd';
import { useEffect, useState } from 'react';
import { StyledTestCoverage } from './TestCoverage.styled';
import { TestCaseResponse, TestVerdict } from 'app/types/TestCaseResponse';

interface IProps {
  dataSource: TestCaseResponse[];
}
export const TestCoverage = ({ dataSource }: IProps) => {
  const [testCoverage, setTestCoverage] = useState<number>();

  const calculateTestCoverage = (testCaseResponse: TestCaseResponse[]) => {
    const totalTestCases = testCaseResponse
      .flatMap((t) => t?.testCaseResults)
      .reduce((total, cur) => total + cur.value, 0);

    const totalExecutedTestCases = testCaseResponse
      .flatMap((t) => t.testCaseResults)
      .reduce((total, cur) => (cur.type !== 'Not Run' ? total + cur.value : total), 0);

    return totalTestCases !== 0 ? +((totalExecutedTestCases / totalTestCases) * 100).toFixed(1) : 0;
  };
  useEffect(() => {
    dataSource && setTestCoverage(calculateTestCoverage(dataSource));
  }, [dataSource]);
  return (
    <StyledTestCoverage>
      <div className="tc-container">
        <Progress
          strokeLinecap={'square'}
          status={'normal'}
          percent={testCoverage}
          strokeColor="#5186E2"
          gapDegree={140}
          width={287}
          type="dashboard"
        />
      </div>
    </StyledTestCoverage>
  );
};

import type { ColumnsType, TableProps } from 'antd/lib/table';
import { Col, Progress, Row, Table, Tooltip, TablePaginationConfig, Empty } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import './Overview.scss';
import { OverviewTableContainer } from './OverviewTable.styled';
import {
  DefectColor,
  GroupedTeamLinks,
  PROGRESS_BAR_MAX_COLOR,
  TABLE_DEFAULT_PAGE_SIZE,
  UserStoriesColor,
} from 'app/common/constants';
import { Cards, OverviewTableData } from 'app/types/GroupedTeamModel';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { TeamParentContext } from 'app/contexts/TeamParentContext';
import { Link } from 'react-router-dom';

interface IProps {
  titleColumn: string;
  teamType: number;
}

function Overview({ titleColumn, teamType }: IProps) {
  const [dataOverview, setDataOverview] = useState<OverviewTableData[]>();
  const [pageSize, setPageSize] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const { loading, iterationData } = useContext(TeamParentContext);

  useEffect(() => {
    setDataOverview(iterationData?.planData?.overview);
  }, [iterationData]);

  const PercentageBar = ({ value }: any) => {
    return (
      <div className="progress-container">
        <Progress
          className="bar"
          strokeColor="#0066FF"
          percent={value === Infinity ? 0 : value && value > 100 ? 100 : value}
          showInfo={false}
          strokeLinecap="square"
        />
        <div className="percentage-value">
          {value === Infinity ? 'N/A' : value}
          {value !== Infinity && '%'}
        </div>
      </div>
    );
  };

  const SortNumberVelocity = (record: OverviewTableData) => {
    const value = Number((100 * record.velocity) / record.targetVelocity);
    return value;
  };

  const MyPercentageBarVelocity = (record: OverviewTableData) => {
    const value = Number((100 * record.velocity) / record.targetVelocity);
    const result = Math.round(value * 10) / 10;
    if (isNaN(result)) {
      return <PercentageBar value={0} />;
    } else {
      return <PercentageBar value={result} />;
    }
  };

  const sumCards = (record: Cards) => {
    const defects = record.defects;
    const userStories = record.userStories;
    const sum = defects + userStories;
    return sum;
  };

  const calculateWidth = (cellValue: number, cards: number) => {
    return `${(cards / cellValue) * 100} %`;
  };

  const SortNumberCapacity = (record: OverviewTableData) => {
    const value = Number((100 * record.capacity) / record.targetCapacity);
    return value;
  };

  const MyPercentageBarCapacity = (record: OverviewTableData) => {
    const value = Number((100 * record.capacity) / record.targetCapacity);
    const result = Math.round(value * 10) / 10;
    if (isNaN(result)) {
      return <PercentageBar value={0} />;
    } else {
      return <PercentageBar value={result} />;
    }
  };

  const CardsSegment = (record: OverviewTableData) => {
    const data = record.cards;
    const tooltipData = data?.map((item, i) => {
      return (
        <div key={i} className="tooltip-card-overview-table">
          <div className="tooltip-card-item ">
            <div>
              <svg className="bullet-point">
                <circle cx="5" cy="5" r="5" strokeWidth={3} fill={DefectColor} />
              </svg>
              <span>Defects </span>
            </div>
            <div>
              <span>{item.defects}</span>
            </div>
          </div>

          <div className="tooltip-card-item ">
            <div>
              <svg className="bullet-point">
                <circle cx="5" cy="5" r="5" strokeWidth={3} fill={UserStoriesColor} />
              </svg>
            </div>
            <span>User Stories </span>
            <div>
              <span style={{ marginLeft: '10px' }}>{item.userStories}</span>
            </div>
          </div>
        </div>
      );
    });
    return (
      <table className="tooltip-card-overview-table">
        <Tooltip
          title={tooltipData}
          overlayInnerStyle={{ background: 'white', color: 'black', fontSize: '14px', padding: '10px' }}
        >
          <tr className="row-cards-item">
            {data?.map((item, i) => {
              return item.defects === 0 ? (
                ''
              ) : (
                <td
                  key={i}
                  width={calculateWidth(sumCards(item), item.defects)}
                  style={{ background: DefectColor, color: 'white' }}
                >
                  <span>{item.defects}</span>
                </td>
              );
            })}
            {data?.map((item, i) => {
              return item.userStories === 0 ? (
                ''
              ) : (
                <td
                  key={i}
                  width={calculateWidth(sumCards(item), item.userStories)}
                  style={{ background: UserStoriesColor, color: 'white' }}
                >
                  <span>{item.userStories}</span>
                </td>
              );
            })}
          </tr>
        </Tooltip>
      </table>
    );
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const renderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`/${GroupedTeamLinks[teamType + 1]}/${record.teamId}`}>
          {text}
        </Link>
      </>
    );
  };

  const columns = useMemo<EditableColumn<OverviewTableData>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: titleColumn,
        dataIndex: 'name',
        width: '37%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (value, record) => renderLink(value, record),
      },
      {
        title: <span style={{ marginLeft: '3px' }}>Planned Capacity</span>,
        dataIndex: 'capacity',
        sorter: {
          compare: (a, b) => SortNumberCapacity(a) - SortNumberCapacity(b),
        },
        width: '20%',
        render: (value, record, index) => MyPercentageBarCapacity(record),
      },
      {
        title: <span style={{ marginLeft: '3px' }}>Planned Velocity</span>,
        dataIndex: 'velocity',
        sorter: {
          compare: (a, b) => SortNumberVelocity(a) - SortNumberVelocity(b),
        },
        width: '20%',
        render: (value, record, index) => MyPercentageBarVelocity(record),
      },
      {
        title: <span style={{ marginLeft: '10px' }}>Cards</span>,
        dataIndex: 'cards',
        width: '20%',
        render: (value, record, index) => CardsSegment(record),
      },
    ],
    [page, pageSize]
  );
  return (
    <OverviewTableContainer>
      <EditableTable
        columns={columns}
        data={dataOverview}
        onChange={onChange}
        showAction={false}
        className="iteration-detail-table"
        id="release-train-overview-table"
        loading={loading}
      />
    </OverviewTableContainer>
  );
}

export default Overview;

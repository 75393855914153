import { MonitorStatusResponse } from 'app/types/SystemMonitoring';
import { SyncStatus } from 'app/common/constants';
import { useContext, useEffect, useState } from 'react';

import * as signalR from '@microsoft/signalr';
import { AuthContext } from 'app/contexts/AuthContext';
import { notification } from 'antd';
import { fetchAsync } from 'app/utils/fetch';
import { useFetch } from './useFetch';

const useSyncStatus = (
  statusReceivedCallback?: (syncStatusResponse: MonitorStatusResponse) => void,
  chartGeneratedCallback?: () => void
) => {
  const { user } = useContext(AuthContext);
  const { data, loading, error } = useFetch(() => fetchAsync('/api/auth/signalr', { method: 'POST' }));
  const [syncStatus, setSyncStatus] = useState<string>(null);
  useEffect(() => {
    if (loading) return;

    if (error) {
      console.error('[SignalR] Authn error!');
      return;
    }

    const connection = new signalR.HubConnectionBuilder()
      .withUrl('/signalr/hubs/systemmonitoring', {
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    if (connection) {
      connection.on('OnSyncStatusChange', (syncStatusResponse: MonitorStatusResponse) => {
        setSyncStatus(syncStatusResponse.status);
        if (syncStatusResponse.status === SyncStatus.INPROGRESS) {
          notification.info({
            message: 'Syncing',
            description: `Syncing ...`,
            duration: 2,
          });
        } else if (syncStatusResponse.status === SyncStatus.SUCCEEDED) {
          notification.success({
            message: 'Success',
            description: `Sync successfully`,
            duration: 2,
          });
        } else {
          notification.error({
            message: 'Sync failed',
            description: `Sync failed`,
            duration: 2,
          });
        }

        statusReceivedCallback && statusReceivedCallback(syncStatusResponse);
      });

      connection.on('OnGenerateChartCompleted', () => {
        // console.log('chart generate completed');
        chartGeneratedCallback && chartGeneratedCallback();
      });

      connection
        .start()
        .then(() => console.log('signalr connected'))
        .catch((err) => console.error(`[SignalR] Error: ${err}`));
    }
    return () => {
      connection.stop().then(() => console.log('signalr connection terminated'));
    };
  }, [chartGeneratedCallback, statusReceivedCallback, user.token, loading, error]);

  return { syncStatus };
};

export default useSyncStatus;

/* eslint-disable no-extend-native */
export {};

declare global {
  export interface Number {
    toStringWithSign(showZero?: boolean): string;
  }
}

Number.prototype.toStringWithSign = function (this: number, showZero: boolean = false) {
  if (this === 0) {
    return showZero ? '+' + this : '';
  }

  return (this > 0 ? '+' + this : this).toString();
};

import { Button, Col, notification, Row } from 'antd';
import { AuthContext } from 'app/contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';

import { OwnershipHighlightWrapper } from './OwnershipHighlight.styled';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { roleNames } from 'app/common/constants';
import { OwnershipMonthlyHighlight } from 'app/types/Ownership';
import { updateMonthlyHighlight } from 'app/apis/ownershipClient';
import './OwnershipHighlight.scss';

type IProps = {
  highlight: OwnershipMonthlyHighlight;
  selectedDate: moment.Moment;
  isAllowedEdit: boolean;
};

export const OwnershipHighlight = ({ highlight, selectedDate, isAllowedEdit }: IProps) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [isSubmit, setIsSubmitting] = useState<boolean>(false);
  const [cacheData, setCacheData] = useState(EditorState.createEmpty());
  const [highlightData, setHighlightData] = useState(EditorState.createEmpty());
  const { user } = useContext(AuthContext);

  const convertHtmlToState = (htmlStr: string) => {
    const contentBlock = htmlToDraft(htmlStr);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  useEffect(() => {
    let html = '';
    if (highlight) html = highlight.highlightNote;
    const editorState = convertHtmlToState(html);
    setHighlightData(editorState);
    setCacheData(editorState);
  }, [highlight]);

  const htmlStr = draftToHtml(convertToRaw(highlightData?.getCurrentContent()));

  const onSave = async () => {
    setIsSubmitting(true);

    try {
      let href = window.location.href;
      let data: OwnershipMonthlyHighlight = {
        highlightNote: htmlStr,
        highlightDate: selectedDate.utc(true).toDate(),
        monthlyHighlightId: highlight.monthlyHighlightId,
        teamId: parseInt(href.substring(href.lastIndexOf('/') + 1)),
      };
      await updateMonthlyHighlight(data);
      const editorState = convertHtmlToState(htmlStr);
      setHighlightData(editorState);
      setIsSubmitting(false);
      setEditable(false);
      setCacheData(editorState);
    } catch (error) {
      console.error(error);
      notification.error({ message: error });
      setIsSubmitting(false);
    }
  };
  const onCancelEdit = () => {
    setEditable(false);
    setHighlightData(cacheData);
  };
  return (
    <OwnershipHighlightWrapper>
      <Row>
        <Col span={24}>
          <div className="monthly-highlight-title">
            <span>Monthly Highlight</span>
            {isAllowedEdit && (
              <span>
                {editable ? (
                  <>
                    <Button type="link" htmlType="submit" onClick={onSave} loading={isSubmit}>
                      Save
                    </Button>
                    <Button type="ghost" onClick={onCancelEdit}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  (user.role === roleNames.admin ||
                    user.role === roleNames.pqa ||
                    user.role === roleNames.scrumMaster) && (
                    <Button type="link" onClick={() => setEditable(true)}>
                      Edit
                    </Button>
                  )
                )}
              </span>
            )}
          </div>
        </Col>
      </Row>
      {editable ? (
        <Editor
          wrapperClassName="editor-wrapper"
          editorClassName="editor-box"
          editorState={highlightData}
          onEditorStateChange={setHighlightData}
          toolbar={{
            image: false,
          }}
        />
      ) : (
        <Editor
          readOnly={true}
          toolbarHidden={true}
          editorClassName="editor-box-read-only"
          placeholder="Edit highlight here"
          editorState={cacheData}
        />
      )}
    </OwnershipHighlightWrapper>
  );
};

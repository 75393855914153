import { Tag } from 'antd';
import { TicketResponse } from 'app/types/PlannedWorkItem';
import 'app/pages/WorkItem/WorkItem.scss';
import { isNull } from 'lodash';

interface IProps {
  record: TicketResponse;
}

export const ScheduleState = ({ record }: IProps) => {
  let hasActived = false;
  if (isNull(record.scheduleStatePrefix)) {
    return <></>;
  }
  return (
    <span className="tag-wrapper">
      {['I', 'D', 'P', 'C', 'A', 'R'].map((x) => {
        if (record.scheduleStatePrefix === x) hasActived = true;
        const color = record.scheduleStatePrefix === x ? '#108ee9' : hasActived ? '#E5F0F6' : '#B5E1F9';

        return (
          <Tag key={`tag_${x}`} color={color} className={record.scheduleStatePrefix === x ? 'is-active' : ''}>
            <span>{x}</span>
          </Tag>
        );
      })}
    </span>
  );
};

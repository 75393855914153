import { Col, Row } from 'antd';
import { Card } from 'app/components/Card';
import { UtilizedCapacity } from 'app/charts/Report/UtilizedCapacity/UltilizedCapacity';
import { IterationScheduleState } from 'app/components/IterationReport/IterationScheduleState';
import { IterationTeamDelivery } from 'app/components/IterationReport/IterationTeamDelivery';
import './GroupedTeamMonitor.scss';
import { ReportType } from 'app/types/CacheReportModel';
import { GroupedTeamModel } from 'app/types/GroupedTeamModel';
import { TeamType } from 'app/types/team';
import { IterationBurnDown } from 'app/charts/IterationBurndown';
import { useCallback, useContext } from 'react';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import IterationDetailSection from '../IterationDetail/IterationDetailMonitor/IterationDetailSection';

interface IGroupedTeamReportProps {
  iteration: GroupedTeamModel;
  type: TeamType;
}

export const GroupedTeamMonitor = ({ iteration, type }: IGroupedTeamReportProps) => {
  const dataSource = iteration?.monitorData;
  const { team } = useContext(TeamDetailContext);
  const filterReport = useCallback(
    (type: ReportType) => {
      const chart = dataSource?.cacheReportModels?.filter((x) => x.type === type);
      if (chart === undefined || chart === null || chart.length < 1) {
        return undefined;
      }
      return chart.at(0).cacheDataResult;
    },

    [dataSource?.cacheReportModels]
  );
  const chartType = useCallback((type: TeamType) => {
    let chart = [];
    switch (type) {
      case TeamType.ReleaseTrain:
        chart['delivery'] = ReportType.ReleaseTrainDeliveryMonitor;
        chart['scheduleState'] = ReportType.ReleaseTrainScheduleStateMonitor;
        chart['capacity'] = ReportType.ReleaseTrainActualCapacityMonitor;
        chart['burndown'] = ReportType.ReleaseTrainIterationBurndown;
        chart['IterationDetail'] = ReportType.ReleaseTrainIterationDetail;
        break;
      case TeamType.DeliveryStream:
        chart['delivery'] = ReportType.DeliveryStreamDeliveryMonitor;
        chart['scheduleState'] = ReportType.DeliveryStreamScheduleStateMonitor;
        chart['capacity'] = ReportType.DeliveryStreamActualCapacityMonitor;
        chart['burndown'] = ReportType.DeliveryStreamIterationBurndown;
        chart['IterationDetail'] = ReportType.DeliveryStreamIterationDetail;
        break;
      case TeamType.Portfolio:
        chart['delivery'] = ReportType.PortfolioDeliveryMonitor;
        chart['scheduleState'] = ReportType.PortfolioScheduleStateMonitor;
        chart['capacity'] = ReportType.PortfolioActualCapacityMonitor;
        chart['burndown'] = ReportType.PortfolioIterationBurndown;
        chart['IterationDetail'] = ReportType.PortfolioIterationDetail;
        break;
      default:
        break;
    }
    return chart;
  }, []);
  return (
    <div>
      <Row gutter={[24, 16]} className="chart-container">
        <Col xs={24} xxl={8} xl={12} className="delivery-div">
          <Card
            title="Delivery"
            titleTooltip={
              <span>
                Delivery = Total points of &quot;Accepted&quot; and &quot;Released&quot; cards (User stories and
                Defects) / Total points of all cards.
              </span>
            }
          >
            <IterationTeamDelivery dataSource={filterReport(chartType(team?.type)['delivery'])} isMonitor />
          </Card>
        </Col>
        <Col xs={24} xxl={8} xl={12} className="schedule-state-div">
          <Card title="Schedule State" titleTooltip={<span>Shows number of User Stories in each state.</span>}>
            <IterationScheduleState dataSource={filterReport(chartType(team?.type)['scheduleState'])} />
          </Card>
        </Col>

        <Col xs={24} xxl={8} xl={12} className="actual-capacity-div">
          <Card
            title="Actual Capacity"
            titleTooltip={<span>Utilized capacity = (Team actual effort / Team total capacity) * 100%</span>}
          >
            <UtilizedCapacity dataSource={filterReport(chartType(team?.type)['capacity'])} isMonitor />
          </Card>
        </Col>
        <Col xs={24} xxl={8} xl={12}>
          <Card
            title="Iteration Burndown"
            titleTooltip={
              <span>
                The Sprint Burndown Chart makes the work of the Team visible. It is a graphic representation of the rate
                at which work is completed (hours) and how much work remains to be done. Work remaining in the iteration
                to proactively anticipate whether the committed work will be delivered by the end of the iteration.
              </span>
            }
          >
            <IterationBurnDown dataSource={filterReport(chartType(team?.type)['burndown'])} isReport={true} />
          </Card>
        </Col>
      </Row>
      <IterationDetailSection dataSource={filterReport(chartType(team?.type)['IterationDetail'])} type={type} />
    </div>
  );
};

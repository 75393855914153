import { Button, Col, Form, Input, Modal, Row, Select, Upload } from 'antd';
import { StyledModal } from './IssueReportStyledModal.styled';
import FormItem from 'antd/lib/form/FormItem';
import CustomSelect from './CustomSelect';
import { Editor } from 'react-draft-wysiwyg';
import { FormInstance } from 'antd/es/form';
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { EditorState, Modifier, convertToRaw } from 'draft-js';
import { IIssueReportIdeaRequest, IIssueReportIdeaResponse } from 'app/types/IssueReportIdeaModel';
import { useContext, useState } from 'react';
import { formAction } from 'app/pages/AboutADM/IssueReportIdea/IssueReportIdea';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { createIssueReportIdea, editIssueReportIdea, uploadImageEditor } from 'app/apis/issueReportIdea';
import { ErrorType, SuccesType, roleNames } from 'app/common/constants';
import { SelectOptions } from 'app/types/entity';
import {
  IssueReportStatus,
  IssueReportStatusToText,
  IssueReportType,
  IssueReportTypeToText,
} from './IssueReportIdeaHelper';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { AuthContext } from 'app/contexts/AuthContext';

interface IssueReportIdeaFormModalProps {
  addModalState: boolean;
  editorState: any;
  setEditorState: React.Dispatch<any>;
  issueReportFormData: IIssueReportIdeaResponse;
  setIssueReportFormData: React.Dispatch<React.SetStateAction<IIssueReportIdeaResponse>>;
  typeModal: formAction;
  setAddModalState: React.Dispatch<React.SetStateAction<boolean>>;
  tableData?: IIssueReportIdeaResponse[];
  setTableData?: React.Dispatch<React.SetStateAction<IIssueReportIdeaResponse[]>>;
  typeValue: any;
  setTypeValue: React.Dispatch<any>;
  typePrefixIcon: any;
  fileList: UploadFile<any>[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  isAllowedForAdmin: boolean;
  onCloseModal: () => void;
  onResetModal?: () => void;
  onBindingData: (data: IIssueReportIdeaResponse) => IIssueReportIdeaResponse;
  onUpdateTypeSelect: (value: any) => void;
  form: FormInstance<any>;
  addContinue?: boolean;
}

export const IssueReportIdeaFormModal = (props: IssueReportIdeaFormModalProps) => {
  const maxImages = 5;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const issueReportFormData = props.issueReportFormData;
  const { user } = useContext(AuthContext);

  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const typeModal = props.typeModal;
  const requiredValidationMessage = 'This field is required';
  const editorState = props.editorState;
  const form = props.form;
  const fileList = props.fileList;
  const { TextArea } = Input;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    props.setFileList(newFileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Images</div>
    </div>
  );
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  // Submit form
  const handleSubmitForm = async () => {
    try {
      const formValues = props.form.getFieldsValue();
      // Convert Editor content to Json
      const contentState = editorState.getCurrentContent();
      const contentStateJson = JSON.stringify(convertToRaw(contentState));
      const payload: IIssueReportIdeaRequest = {
        id: formValues.id,
        name: formValues.name,
        type: formValues.type,
        status: formValues.status,
        // detail: formValues.detail.blocks[0].text,
        detail: contentStateJson,
        issueReportIdeaImages: issueReportFormData.issueReportIdeaImages
          ? issueReportFormData.issueReportIdeaImages
          : [],
        uploadedImages: uploadFiles,
        feedback: formValues.feedback == null ? '' : formValues.feedback,
      };

      // reset uploadFiles
      setUploadFiles([]);
      if (typeModal === formAction.Create) {
        // props.onSubmit(payload);
        const data = await createIssueReportIdea(payload);
        props.onBindingData(data);
        props.setTableData([...props.tableData, data]);
        openNotificationByType(SuccesType, 'Create successfully !');
      } else if (typeModal === formAction.Edit) {
        const data = await editIssueReportIdea(payload);
        props.onBindingData(data);
        props.setTableData([
          ...props.tableData.map((item) =>
            item.id === data.id
              ? {
                  ...data,
                  statusText: data.statusText,
                  typeText: data.typeText,
                  userInfo: data.userInfo,
                  issueReportIdeaImages: data.issueReportIdeaImages,
                }
              : item
          ),
        ]);
        openNotificationByType(SuccesType, 'Edit Successfully !');
      }
    } catch (error) {
      openNotificationByType(ErrorType, 'Something Went Wrong !!!');
    }
  };

  const handleImageUploadEditor = (file, callback) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {
        const res = await uploadImageEditor(file);
        resolve({ data: { link: res.path } });
      };
      reader.readAsDataURL(file);
    });
  };

  const { confirm } = Modal;
  const showUpdateConfirm = (isKeepOpenModal?: boolean) => {
    confirm({
      title: 'Are you sure save this record?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        handleSubmitForm();
        if (isKeepOpenModal) {
          props.onResetModal();
        } else {
          props.onCloseModal();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const TypeSelectOptions: SelectOptions<number>[] = [
    {
      label: '--------Select Type---------',
      value: null,
    },
    {
      label: IssueReportTypeToText(IssueReportType.Idea),
      value: IssueReportType.Idea,
    },
    {
      label: IssueReportTypeToText(IssueReportType.Issue),
      value: IssueReportType.Issue,
    },
    {
      label: IssueReportTypeToText(IssueReportType.Enhance),
      value: IssueReportType.Enhance,
    },
  ];
  const StatusSelectOptions: SelectOptions<number>[] = [
    {
      label: IssueReportStatusToText(IssueReportStatus.Todo),
      value: IssueReportStatus.Todo,
    },
    {
      label: IssueReportStatusToText(IssueReportStatus.Confirm),
      value: IssueReportStatus.Confirm,
    },
    {
      label: IssueReportStatusToText(IssueReportStatus.Inprogress),
      value: IssueReportStatus.Inprogress,
    },
    {
      label: IssueReportStatusToText(IssueReportStatus.Done),
      value: IssueReportStatus.Done,
    },
    {
      label: IssueReportStatusToText(IssueReportStatus.Reject),
      value: IssueReportStatus.Reject,
    },
    {
      label: IssueReportStatusToText(IssueReportStatus.Duplicate),
      value: IssueReportStatus.Duplicate,
    },
    {
      label: IssueReportStatusToText(IssueReportStatus.Reopen),
      value: IssueReportStatus.Reopen,
    },
  ];

  function FooterComponent() {
    const component: any = [
      <Button key="back" size="large" onClick={() => props.onCloseModal()}>
        Cancel
      </Button>,
      <Button key="submit" size="large" htmlType="submit" type="primary" form="IssueReportIdeaForm">
        Submit
      </Button>,
    ];
    if (props.addContinue) {
      component.push(
        <Button
          key="button"
          onClick={() => showUpdateConfirm(true)}
          size="large"
          type="primary"
          form="IssueReportIdeaForm"
        >
          Submit & Continue
        </Button>
      );
    }
    return component;
  }

  return (
    <StyledModal
      title="FEEDBACK"
      visible={props.addModalState}
      onCancel={() => props.onCloseModal()}
      width={990}
      centered
      getContainer={false}
      footer={<FooterComponent />}
    >
      <Form
        {...layout}
        form={form}
        size={'middle'}
        name="IssueReportIdeaForm"
        autoComplete="off"
        colon={false}
        labelAlign="left"
        validateTrigger="onSubmit"
        onFinish={() => showUpdateConfirm()}
      >
        <Row>
          <Col span={24} style={{ display: 'block' }}>
            <FormItem name="id" style={{ display: 'hidden', height: '0', margin: '0' }}>
              <></>
            </FormItem>
            <FormItem
              label="Type"
              name="type"
              labelCol={{ span: 24 }}
              style={{ width: '45%', float: 'left' }}
              rules={[{ required: true, message: requiredValidationMessage }]}
            >
              <CustomSelect
                options={TypeSelectOptions}
                value={props.typeValue}
                prefixIcon={props.typePrefixIcon}
                onChange={(value) => props.onUpdateTypeSelect(value)}
                placeholder="--------Select Type---------"
              />
            </FormItem>
            <FormItem
              label="Status"
              name="status"
              initialValue={IssueReportStatus.Todo}
              labelCol={{ span: 24 }}
              style={{ width: '45%', float: 'right' }}
              rules={[{ required: typeModal == formAction.Edit, message: requiredValidationMessage }]}
            >
              <Select
                options={StatusSelectOptions}
                disabled={typeModal == formAction.Create ? true : props.isAllowedForAdmin ? false : true}
                // value={props.typeValue}
                // onChange={(value) => props.setTypeValue(value)}
              />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          label="Summary"
          name="name"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: requiredValidationMessage }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Detail"
          name="detail"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: requiredValidationMessage }]}
        >
          <Editor
            readOnly={false}
            toolbarHidden={false}
            editorState={editorState}
            editorStyle={{ height: '250px' }}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-box"
            toolbarClassName="editor-toolbar"
            toolbar={{
              image: {
                uploadCallback: handleImageUploadEditor,
                previewImage: true,
                alt: { present: true, mandatory: false },
              },
            }}
            onEditorStateChange={(values) => {
              form.setFieldsValue({
                detail: values,
              });
              props.setEditorState(values);
            }}
            onTab={(e) => {
              const tabCharacter = '              ';
              e.preventDefault();
              let newContentState = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                tabCharacter
              );
              form.setFieldsValue({
                detail: EditorState.push(editorState, newContentState, 'insert-characters'),
              });
            }}
          />
        </FormItem>

        <FormItem label="Upload" name="images" labelCol={{ span: 24 }}>
          <Upload
            listType="picture-card"
            // defaultFileList={}
            fileList={fileList}
            showUploadList={{ showRemoveIcon: true }}
            onPreview={handlePreview}
            onChange={handleChange}
            multiple
            accept=".jpeg, .png, .jpg"
            beforeUpload={(file, fileList) => {
              if (issueReportFormData.issueReportIdeaImages) {
                if (
                  issueReportFormData.issueReportIdeaImages?.length + uploadFiles?.length + fileList?.length >
                  maxImages
                ) {
                  if (file.uid === fileList[fileList.length - 1].uid) {
                    openNotificationByType(ErrorType, `Maximum is ${maxImages} images!!!`);
                  }
                  return Upload.LIST_IGNORE;
                }
              } else {
                if (uploadFiles?.length + fileList?.length > maxImages) {
                  if (file.uid === fileList[fileList.length - 1].uid) {
                    openNotificationByType(ErrorType, `Maximum is ${maxImages} images!!!`);
                  }
                  return Upload.LIST_IGNORE;
                }
              }
              if (file.size / 1024 / 1024 >= 3) {
                openNotificationByType(ErrorType, file.name + ' size is too big. File must be under 3MB ');
                return Upload.LIST_IGNORE;
              }
              //sometimes undf or empty array
              const arr = issueReportFormData?.issueReportIdeaImages
                ? [...issueReportFormData.issueReportIdeaImages]
                : [];
              const dbExisted = arr.filter((x) => x.fileName === file.name);
              const uploadExisted = uploadFiles?.filter((x) => x.name === file.name);
              if (dbExisted.length > 0 || uploadExisted.length > 0) {
                openNotificationByType(ErrorType, file.name + ' already existed');
                return Upload.LIST_IGNORE;
              }
              setUploadFiles([...uploadFiles, ...fileList.filter((x) => x.uid !== undefined)]);
              return false;
            }}
            onRemove={(file) => {
              const x = issueReportFormData.issueReportIdeaImages?.filter(
                (issueReportIdeaImage) => issueReportIdeaImage.id.toString() === file.uid
              );
              if (x?.length > 0) {
                props.setIssueReportFormData({
                  ...issueReportFormData,
                  issueReportIdeaImages: [
                    ...issueReportFormData.issueReportIdeaImages.filter(
                      (issueReportIdeaImage) => issueReportIdeaImage.id.toString() !== file.uid
                    ),
                  ],
                });
              } else {
                setUploadFiles([...uploadFiles?.filter((upFile) => upFile.name !== file.name)]);
              }
            }}
          >
            {fileList.length >= maxImages ? null : uploadButton}
          </Upload>
          <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </FormItem>

        {typeModal === formAction.Edit && user.role === roleNames.admin ? (
          <FormItem label="Admin comment" name="feedback" labelCol={{ span: 24 }}>
            <TextArea rows={4} />
          </FormItem>
        ) : null}
      </Form>
    </StyledModal>
  );
};

import { SearchOutlined } from '@ant-design/icons';
import { Button, TablePaginationConfig } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { fetchHeatmapList } from 'app/apis/ownershipHeatmap';
import { fetchProjectGroupsOption } from 'app/apis/projectGroup';
import { OwnershipColor, OwnershipEvaluationTitle, roleNames, USER_PAGE_NUMBER } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { UserContext } from 'app/contexts/UserContext';
import { useFetch } from 'app/hooks/useFetch';
import { OwnershipLevel } from 'app/types/Ownership';
import { ClientSatisfaction, OwnershipHeatmapTableColumn } from 'app/types/OwnershipHeatmap';
import { TeamResponse, TeamType } from 'app/types/team';
import { isNull } from 'lodash';
import React, { useMemo, useRef, useState, useEffect, useContext } from 'react';
import { OwnershipDetailDrawer } from './OwnershipDetailDrawer';
import { definedColorSatisfaction } from './OwnershipHelper';
import { CustomLevelCell, EmptyRow, SelectedRow } from './OwnershipStatusDetail.styled';

type Props = {
  selectedMonth: moment.Moment;
  tableTeamData: TeamResponse[];
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
};
interface TeamSelected {
  id: number;
  name: string;
  scrumMasterEmail: string;
}

export const OwnershipDetailTable = ({ selectedMonth, tableTeamData, openDrawer, setOpenDrawer }: Props) => {
  const searchEl = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const [tableData, setTableData] = useState<OwnershipHeatmapTableColumn[]>();
  const [selectedTeam, setSelectedTeam] = useState<TeamSelected>(null);
  const { data: heatmapData } = useFetch(() => fetchHeatmapList(selectedMonth.toDate()), [selectedMonth, openDrawer]);
  const { data: projectgroupOptions } = useFetch(fetchProjectGroupsOption);
  const {
    user: { userRole, email },
  } = useContext(UserContext);
  const isAllowedAction =
    userRole === roleNames.admin || userRole === roleNames.pqa || userRole === roleNames.scrumMaster;
  const isAllowedEdit = userRole === roleNames.admin || userRole === roleNames.pqa;
  useEffect(() => {
    if (tableTeamData && heatmapData) {
      let result: OwnershipHeatmapTableColumn[] = tableTeamData
        .filter((x) => x.isFPTInvolvded === true)
        ?.map((teamData) => {
          let newrecordColumn: OwnershipHeatmapTableColumn = {
            id: teamData.id,
            name: teamData.name,
            teamId: teamData.id,
            teamDataResponse: teamData.teamDataResponse,
            date: null,
            clientSatisfaction: ClientSatisfaction.NA,
            ownershipLevel: OwnershipLevel.None,
            satisfactionRate: -1,
            productionIssue: -1,
            highlight: '',
            projectGroupName: teamData.projectGroupName,
            scrumMasterEmail: teamData.scrumMaster?.email,
          };
          let teamId = teamData.id;
          let matchedTeam = heatmapData.find((x) => x.teamId === teamId);
          if (matchedTeam) {
            newrecordColumn = {
              id: teamData.id,
              teamId: teamData.id,
              name: teamData.name,
              date: matchedTeam.date,
              highlight: matchedTeam.highlight,
              clientSatisfaction: matchedTeam.clientSatisfaction,
              ownershipLevel: matchedTeam.ownershipLevel,
              satisfactionRate: matchedTeam.satisfactionRate,
              productionIssue: matchedTeam.productionIssue,
              teamDataResponse: teamData.teamDataResponse,
              projectGroupName: teamData.projectGroupName,
              scrumMasterEmail: teamData.scrumMaster?.email,
            };
          }
          return newrecordColumn;
        });
      setTableData(result);
    }
  }, [tableTeamData, heatmapData]);
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const onOpenDrawer = (record: OwnershipHeatmapTableColumn) => {
    setOpenDrawer(true);
    setSelectedTeam({ id: record.teamId, name: record.name, scrumMasterEmail: record.scrumMasterEmail });
  };
  const renderClientSatisfactionColumn = (record: OwnershipHeatmapTableColumn) => {
    return {
      props: {
        style: {
          background: definedColorSatisfaction(record.clientSatisfaction, 'client').background,
          fontWeight: 600,
        },
      },
      children: (
        <SelectedRow onClick={() => onOpenDrawer(record)}>
          {definedColorSatisfaction(record.clientSatisfaction, 'client').title}
        </SelectedRow>
      ),
    };
  };
  const renderLevelColumn = (record: OwnershipHeatmapTableColumn) => {
    return {
      props: {
        style: { background: definedColorSatisfaction(record.ownershipLevel, 'level').background, fontWeight: 600 },
      },
      children: (
        <CustomLevelCell onClick={() => onOpenDrawer(record)}>
          <span className={definedColorSatisfaction(record.ownershipLevel, 'level').title !== 'N/A' ? 'level' : ''}>
            <span className="numb">{definedColorSatisfaction(record.ownershipLevel, 'level').title}</span>
          </span>
        </CustomLevelCell>
      ),
    };
  };
  const renderSLARateColumn = (record: OwnershipHeatmapTableColumn) => {
    return {
      props: {
        style: {
          background:
            record.satisfactionRate >= 95
              ? OwnershipColor.satisfied
              : record.satisfactionRate >= 85 && record.satisfactionRate < 95
              ? OwnershipColor.caution
              : record.satisfactionRate >= 0 && record.satisfactionRate < 85
              ? OwnershipColor.atRisk
              : OwnershipColor.notApplicable,
          fontWeight: 600,
        },
      },
      children: (
        <SelectedRow onClick={() => onOpenDrawer(record)}>
          {record.satisfactionRate > -1
            ? `${record.satisfactionRate}%`
            : record.satisfactionRate === -2
            ? 'No Ticket/SLA tracking'
            : 'N/A'}
        </SelectedRow>
      ),
    };
  };
  const renderProdIssueColumn = (record: OwnershipHeatmapTableColumn) => {
    return {
      props: {
        style: {
          background:
            record.productionIssue > 0
              ? OwnershipColor.atRisk
              : record.productionIssue === 0
              ? OwnershipColor.satisfied
              : OwnershipColor.notApplicable,
          fontWeight: 600,
        },
      },
      children: (
        <SelectedRow onClick={() => onOpenDrawer(record)}>
          {record.productionIssue > -1 ? (record.productionIssue === 0 ? 'No issue' : record.productionIssue) : 'N/A'}
        </SelectedRow>
      ),
    };
  };
  const renderTeamName = (record: OwnershipHeatmapTableColumn) => (
    <SelectedRow onClick={() => onOpenDrawer(record)}>{record.name}</SelectedRow>
  );

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ...`}
            onSearch={() => confirm()}
            onPressEnter={() => confirm()}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      if (dataIndex === 'name') return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });
  const filterDataOption = (type: TeamType) => {
    var data: ColumnFilterItem[];
    switch (type) {
      case TeamType.ReleaseTrain:
        data = tableTeamData?.map((item) => ({
          text: item?.teamDataResponse?.releaseTrainName,
          value: item?.teamDataResponse?.releaseTrainId,
        }));
        break;
      case TeamType.DeliveryStream:
        data = tableTeamData?.map((item) => ({
          text: item?.teamDataResponse?.deliveryStreamName,
          value: item?.teamDataResponse?.deliveryStreamId,
        }));
        break;
      case TeamType.Portfolio:
        data = tableTeamData?.map((item) => ({
          text: item?.teamDataResponse?.portfolioName,
          value: item?.teamDataResponse?.portfolioId,
        }));
        break;
    }
    return data?.filter(
      (value, index, self) => index === self.findIndex((t) => t.value === value.value && t.text === value.text)
    );
  };
  const filterProjectOption = () => {
    var data: ColumnFilterItem[] = projectgroupOptions?.map((item) => ({
      text: item?.label,
      value: item?.label,
    }));

    return data?.filter(
      (value, index, self) => index === self.findIndex((t) => t.value === value.value && t.text === value.text)
    );
  };
  const columnTeams = useMemo<EditableColumn<OwnershipHeatmapTableColumn>[]>(
    () => {
      let defineColumns: EditableColumn<OwnershipHeatmapTableColumn>[] = [
        {
          title: '#',
          width: '3%',
          render: (value, record, index) => (page - 1) * pageSize + index + 1,
          align: 'center',
        },
        {
          title: 'Portfolio',
          dataIndex: ['teamDataResponse', 'portfolioName'],
          width: 160,
          filters: filterDataOption(TeamType.Portfolio),
          onFilter: (value: number, record) => record?.teamDataResponse?.portfolioId === value,
          render: (value, record) => <SelectedRow onClick={() => onOpenDrawer(record)}>{value}</SelectedRow>,
        },
        {
          title: 'Delivery Stream',
          dataIndex: ['teamDataResponse', 'deliveryStreamName'],
          width: 160,
          editable: false,
          filters: filterDataOption(TeamType.DeliveryStream),
          onFilter: (value: number, record) => record?.teamDataResponse?.deliveryStreamId === value,
          render: (value, record) => <SelectedRow onClick={() => onOpenDrawer(record)}>{value}</SelectedRow>,
        },
        {
          title: 'Release Train',
          dataIndex: ['teamDataResponse', 'releaseTrainName'],
          width: 160,
          editable: false,
          filters: filterDataOption(TeamType.ReleaseTrain),
          onFilter: (value: number, record) => record?.teamDataResponse?.releaseTrainId === value,
          render: (value, record) => <SelectedRow onClick={() => onOpenDrawer(record)}>{value}</SelectedRow>,
        },
        {
          title: 'Project',
          dataIndex: 'projectGroupName',
          width: 100,
          filters: filterProjectOption(),
          onFilter: (value, record) => record?.projectGroupName === value,
          render: (value, record) =>
            value ? (
              <SelectedRow onClick={() => onOpenDrawer(record)}>{value}</SelectedRow>
            ) : (
              <EmptyRow onClick={() => onOpenDrawer(record)} />
            ),
        },
        {
          title: 'Team Name',
          dataIndex: 'name',
          width: 160,
          editable: false,
          ...getColumnSearchProps('name'),
          render: (value, record) => renderTeamName(record),
        },
        {
          title: OwnershipEvaluationTitle.clientSatisfaction,
          width: 160,
          dataIndex: 'clientSatisfaction',
          render: (value, record) => renderClientSatisfactionColumn(record),
          align: 'center',
          sorter: (a, b) => a.clientSatisfaction.toString().localeCompare(b.clientSatisfaction.toString()),
        },
        {
          title: OwnershipEvaluationTitle.ownershipLevel,
          width: 160,
          dataIndex: 'ownershipLevel',
          render: (value, record) => renderLevelColumn(record),
          align: 'center',
          sorter: (a, b) => a.ownershipLevel.toString().localeCompare(b.ownershipLevel.toString()),
        },
        {
          title: OwnershipEvaluationTitle.SLARate,
          width: 190,
          dataIndex: 'satisfactionRate',
          render: (value, record) => renderSLARateColumn(record),
          align: 'center',
          sorter: (a, b) => a.satisfactionRate.toString().localeCompare(b.satisfactionRate.toString()),
        },
        {
          title: OwnershipEvaluationTitle.ProdIssue,
          width: 180,
          dataIndex: 'productionIssue',
          render: (value, record) => renderProdIssueColumn(record),
          align: 'center',
          sorter: (a, b) => a.productionIssue.toString().localeCompare(b.productionIssue.toString()),
        },
      ];
      if (isAllowedAction) {
        defineColumns.push({
          title: 'Action',
          width: 80,
          render: (value, record, index) => {
            if (record.scrumMasterEmail === email || isAllowedEdit)
              return (
                <Button type="link" onClick={() => onOpenDrawer(record)}>
                  Edit
                </Button>
              );
          },
        });
      }
      return defineColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, tableTeamData]
  );
  return (
    <div>
      <EditableTable
        data={tableData}
        isEdit={false}
        columns={columnTeams}
        showAction={false}
        pageSize={pageSize}
        current={page}
        onChange={onChange}
        pagination={{
          hideOnSinglePage: true,
          onChange: () => onChange,
        }}
        scroll={{ x: 'max-content', y: 500 }}
      />
      {selectedTeam && (
        <OwnershipDetailDrawer
          smEmail={selectedTeam?.scrumMasterEmail}
          visible={openDrawer}
          setVisible={setOpenDrawer}
          teamId={selectedTeam?.id}
          selectedMonth={selectedMonth}
          teamName={selectedTeam?.name}
        />
      )}
    </div>
  );
};

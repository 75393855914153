import { Col, Row } from 'antd';
import { IterationBurnDown } from 'app/charts/IterationBurndown';
import { CumulativeDiagram } from 'app/charts/CumulativeDiagram';
import { Card } from 'app/components/Card';
import { TeamDetailContext } from 'app/contexts/TeamDetailContext';
import { useContext, useEffect, useState } from 'react';
import { IterationDefectsByState } from 'app/charts/IterationDefects/IterationDefectsByState';
import { ComplimentSection } from 'app/components/Compliments/ComplimentsSection';
import { IterationGoal } from 'app/components/IterationGoals/IterationGoal';
import { WorkItem } from 'app/pages/WorkItem/WorkItem';
import { PlanSection } from '../Plan/PlanSection/PlanSection';
import './Monitoring.scss';
import { Throughput } from 'app/charts/Throughput';
import { SubPage } from 'app/types/TeamDetail';
import { CacheReportModel, ChartCollectionModel, ReportType } from 'app/types/CacheReportModel';
import { useFetch } from 'app/hooks/useFetch';
import { fetchThroughtputChart, fetchTaskByIterationMonitor } from 'app/apis/teamDetailClient';
import { fetchDataChartByType } from 'app/apis/teamDetailClient';
import { Stage, TeamCapacityResponse } from 'app/types/TeamDetailTypes';
import { RetrospectiveType } from 'app/types/entity';
import RetrospectiveNoteSection from 'app/components/RetrospectiveNote/RetrospectiveNoteSection';
import { Iteration } from 'app/types/Iteration';
import { orderBy } from 'lodash';
import { IterationDefectsByEnvironment } from 'app/charts/IterationDefectsByEnvironment';
import { TeamCapacityMonitor } from 'app/components/TeamCapacity/TeamCapacityMonitor/TeamCapacityMonitor';
import { IterationContext } from 'app/contexts/IterationContext';
import { IterationTeamDelivery } from 'app/components/IterationReport/IterationTeamDelivery';
import { IterationScheduleState } from 'app/components/IterationReport/IterationScheduleState';
import { UtilizedCapacity } from 'app/charts/Report/UtilizedCapacity/UltilizedCapacity';
import { DemoPlan } from 'app/components/DemoPlan/DemoPlan';
import { Attendance } from 'app/components/Attendance/Attendance';
import { DisplayComponentsContext } from 'app/contexts/DisplayComponentsContext';
import { IterationBurnUp } from 'app/charts/IterationBurnup';

interface IProps {
  stage: Stage;
  page: SubPage;
}
export const Monitoring = (props: IProps) => {
  const { selectedIteration, teamIterations } = useContext(IterationContext);
  const { team } = useContext(TeamDetailContext);
  const { handleComponentClass, handleSectionClass } = useContext(DisplayComponentsContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataChart, setDataChart] = useState<ChartCollectionModel>();
  const { data: itbdData } = useFetch(() => fetchDataChartByType(team?.id, ReportType.IterationBurndown));
  const { data: itbuData } = useFetch(() => fetchDataChartByType(team?.id, ReportType.IterationBurnup));
  const { data: utilizedData } = useFetch(() => fetchDataChartByType(team?.id, ReportType.UtilizedCapacity));
  const { data: cumulativeDiagramData } = useFetch(() =>
    fetchDataChartByType(team?.id, ReportType.CumulativeFlowDiagram)
  );
  const { data: scheduleStateData } = useFetch(() => fetchDataChartByType(team?.id, ReportType.ScheduleState));
  const { data: teamDeliveryData } = useFetch(() => fetchDataChartByType(team?.id, ReportType.TeamDelivery));
  const { data: iterationDefectByEnvironmentData } = useFetch(() =>
    fetchDataChartByType(team?.id, ReportType.IterationDefectsByEnvironment)
  );
  const { data: iterationDefectByStateData } = useFetch(() =>
    fetchDataChartByType(team?.id, ReportType.IterationDefectsByState)
  );
  const { data: throughtputData } = useFetch(() => fetchThroughtputChart(team?.id));
  const [iterationDefectByState, setIterationDefectByState] = useState<CacheReportModel>();
  const [iterationBurnDown, setIterationBurnDown] = useState<CacheReportModel>();
  const [iterationBurnUp, setIterationBurnUp] = useState<CacheReportModel>();
  const [cumulativeDiagram, setCumulativeDiagram] = useState<CacheReportModel>();
  const [throughputChart, setThroughputChart] = useState<CacheReportModel[]>();
  const [iterationDefectByEnvironment, setiterationDefectByEnvironment] = useState<CacheReportModel>();
  const [teamDelivery, setTeamDelivery] = useState<CacheReportModel>();
  const [scheduleState, setScheduleState] = useState<CacheReportModel>();
  const [utilizedCapacity, setUtilizedCapacity] = useState<CacheReportModel>();
  const [throughtput, setThroughtputData] = useState<CacheReportModel[]>();
  const { data: dataSource } = useFetch<TeamCapacityResponse>(
    () => fetchTaskByIterationMonitor(selectedIteration?.id),
    [selectedIteration?.id]
  );
  useEffect(() => {
    setIterationBurnDown(itbdData);
    setIterationBurnUp(itbuData);
    setUtilizedCapacity(utilizedData);
    setCumulativeDiagram(cumulativeDiagramData);
    setScheduleState(scheduleStateData);
    setTeamDelivery(teamDeliveryData);
    setiterationDefectByEnvironment(iterationDefectByEnvironmentData);
    setIterationDefectByState(iterationDefectByStateData);
    setDataChart(throughtputData);
    let throughtputDataForUSDefect = throughtputData?.cacheReportModels?.filter(
      (x) => x.type === ReportType.Throughput
    );
    let throughtputDataForUS = throughtputData?.cacheReportModels?.filter((x) => x.type === ReportType.CfdForUserStory);
    let formatThroughtput = throughtputDataForUSDefect
      ? [
          {
            cacheDataResult: {
              userStory: throughtputDataForUS[0]?.cacheDataResult,
              userStoryDefect: throughtputDataForUSDefect[0]?.cacheDataResult,
            },
            teamId: throughtputDataForUSDefect[0]?.teamId,
            type: throughtputDataForUSDefect[0]?.type,
          },
        ]
      : [
          {
            cacheDataResult: [],
            teamId: null,
            type: null,
          },
        ];
    setThroughputChart(formatThroughtput);
  }, [
    itbdData,
    itbuData,
    utilizedData,
    cumulativeDiagramData,
    scheduleStateData,
    teamDeliveryData,
    iterationDefectByEnvironmentData,
    iterationDefectByStateData,
    throughtputData,
  ]);

  const getPreviousIteration = (currentIterationId: number, source: Iteration[]): Iteration => {
    const currentIteration = source?.find((x) => x.id === currentIterationId);
    if (currentIteration === undefined) return null;
    const previousIteration = orderBy(
      source.filter((x) => x.dateTo <= currentIteration.dateFrom),
      (x) => x.dateTo,
      'desc'
    )?.[0];
    return previousIteration;
  };
  return (
    <>
      <Row gutter={[24, 16]} className={`${handleSectionClass('monitor-team-trend')} chart-container`}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={8}
          className={`${handleComponentClass('monitor-utilized-capacity')} team-delivery-div`}
        >
          <Card
            title="Utilized Capacity"
            titleTooltip={<span>Utilized capacity = (Team actual effort / Team total capacity) * 100%</span>}
          >
            <UtilizedCapacity dataSource={utilizedCapacity?.cacheDataResult} isMonitor />
          </Card>
        </Col>
        {iterationBurnDown != null && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={8}
            className={handleComponentClass('monitor-iteration-burndown')}
          >
            <Card
              title="Iteration Burndown"
              titleTooltip={
                <span>
                  The Sprint Burndown Chart makes the work of the Team visible. It is a graphic representation of the
                  rate at which work is completed (hours) and how much work remains to be done. Work remaining in the
                  iteration to proactively anticipate whether the committed work will be delivered by the end of the
                  iteration.
                </span>
              }
            >
              <IterationBurnDown isReport={false} dataSource={iterationBurnDown?.cacheDataResult} />
            </Card>
          </Col>
        )}
        {iterationBurnUp != null && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={8}
            className={handleComponentClass('monitor-iteration-burnup')}
          >
            <Card
              title="Iteration Burnup"
              titleTooltip={
                <span>
                  Work delivered so far in the iteration to proactively anticipate whether the iteration scope will be
                  delivered
                </span>
              }
            >
              <IterationBurnUp isReport={false} dataSource={iterationBurnUp?.cacheDataResult} />
            </Card>
          </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={8}
          className={`${handleComponentClass('monitor-schedule-state')} schedule-state-div`}
        >
          <Card title="Schedule State" titleTooltip={<span>Shows number of User Stories in each state.</span>}>
            <IterationScheduleState dataSource={scheduleState?.cacheDataResult} />
          </Card>
        </Col>
        {throughputChart?.length > 0 && (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8} className={handleComponentClass('monitor-throughput')}>
            <Card
              title="Throughput"
              clasTitleName="throughput"
              titleTooltip={
                <span>
                  Throughput is defined as “the number of work items finished per unit of time”. Shows number of items
                  (User stories and Defects) and its state on daily basis.
                </span>
              }
            >
              <Throughput
                teamId={team?.id}
                iteration={selectedIteration}
                dataSource={throughputChart[0].cacheDataResult}
              />
            </Card>
          </Col>
        )}

        {cumulativeDiagram != null && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={8}
            className={handleComponentClass('monitor-cumulative-flow')}
          >
            <Card
              title="Cumulative Flow Diagram (CFD)"
              titleTooltip={
                <>
                  <span>
                    The chart tracks the total number of tasks in the columns of the &quot;In Progress&quot; section on
                    your board each day.
                  </span>
                  <br />
                  <span>
                    The horizontal axis of the CFD represents the days in iteration for which the chart is visualizing
                    data. The vertical axis shows the cumulative number of tasks in the iteration backlogs at various
                    points in time.
                  </span>
                </>
              }
            >
              <CumulativeDiagram teamId={team?.id} dataSource={cumulativeDiagram?.cacheDataResult} />
            </Card>
          </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={8}
          className={`${handleComponentClass('monitor-team-delivery')} team-delivery-div`}
        >
          <Card
            title="Team Delivery"
            titleTooltip={
              <span>
                Team delivery = Total points of &quot;Accepted&quot; and &quot;Released&quot; cards (User stories and
                Defects) / Total points of all cards.
              </span>
            }
          >
            <IterationTeamDelivery dataSource={teamDelivery?.cacheDataResult} isMonitor />
          </Card>
        </Col>

        {iterationDefectByState != null && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={8}
            className={handleComponentClass('monitor-iteration-defects-by-state')}
          >
            <Card
              title="Iteration Defects by State"
              titleTooltip={
                <span>
                  All defects in the iteration, categorized by state to assess end of iteration technical debt.
                </span>
              }
            >
              <IterationDefectsByState
                teamId={team?.id}
                iteration={selectedIteration}
                dataSource={iterationDefectByState?.cacheDataResult}
              />
            </Card>
          </Col>
        )}

        {iterationDefectByEnvironment != null && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={8}
            className={handleComponentClass('monitor-iteration-defects-by-environment')}
          >
            <Card
              title="Iteration Defects by Environment"
              titleTooltip={
                <span>
                  All defects in the iteration, categorized by environment to assess end of iteration technical debt.
                </span>
              }
            >
              <IterationDefectsByEnvironment
                teamId={team?.id}
                iteration={selectedIteration}
                dataSource={iterationDefectByEnvironment?.cacheDataResult}
              />
            </Card>
          </Col>
        )}
      </Row>

      {dataSource != null && (
        <>
          <PlanSection
            id=""
            title="CAPACITY DETAIL"
            titleTootip={
              <span>
                Capacity Detail displays all project team members with their individual capacity, plan and actual spent
                on the daily basis. In order to change a users capacity, you must update it in Rally or manual update in
                Plan tab in planning phase.
              </span>
            }
            withTopSpace
            classProp={handleComponentClass('monitor-capacity-detail')}
          >
            <Row>
              {dataSource !== undefined && (
                <Col span={24}>
                  <TeamCapacityMonitor dataMonitor={dataSource} page={SubPage.Monitoring} />
                </Col>
              )}
            </Row>
          </PlanSection>
          <Row className={`${handleComponentClass('monitor-day-off-schedule')} work-item`}>
            <Col span={24}>
              <Attendance page={SubPage.Monitoring} />
            </Col>
          </Row>
          <Row className={`${handleComponentClass('monitor-iteration-goal')} iteration-goal`}>
            <Col span={24}>
              <IterationGoal page={SubPage.Monitoring} />
            </Col>
          </Row>
          <PlanSection
            id=""
            title="ITERATION BACKLOG"
            titleTootip={
              <span>
                Effort column: Red circle indicator shows that this card has NO CHANGE in effort for 2 consecutive
                working days. SM should work with owner to detect reason.
              </span>
            }
            withTopSpace
            classProp={handleComponentClass('monitor-iteration-backlog')}
          >
            <Row className="work-item">
              <Col span={24}>
                <WorkItem page={SubPage.Monitoring} />
              </Col>
            </Row>
          </PlanSection>
          <Row className={handleComponentClass('monitor-demo-plan')}>
            <Col span={24}>
              <DemoPlan page={SubPage.Monitoring} />
            </Col>
          </Row>
          <ComplimentSection
            iterationId={selectedIteration?.id}
            header="5 star compliments"
            className={handleComponentClass('monitor-5-star-compliments')}
          />
          <PlanSection
            title="RETROSPECTIVE"
            titleTootip={
              <span>
                By the end of the Offline Sprint Retrospective, the Scrum Master should have identified key conclusions
                about what went well? What could be improved in this iteration and improvements that it will implement
                in the next iteration. Anytime you finish an action, SM can mark it done on tool.
              </span>
            }
            classProp={handleComponentClass('monitor-retrospective')}
          >
            <Row className="note">
              <Col span={24}>
                <RetrospectiveNoteSection
                  backgroundColor="#FFEEFD"
                  iterationId={getPreviousIteration(selectedIteration.id, teamIterations)?.id}
                  header="Actions of previous iteration"
                  type={RetrospectiveType.Todo}
                  iterationStage={props.stage}
                  isSelectedIteration={false}
                  isAction={true}
                />
              </Col>
            </Row>
            <Row className="note">
              {selectedIteration && (
                <Col span={24}>
                  <RetrospectiveNoteSection
                    backgroundColor="#D5FFE9"
                    iterationId={selectedIteration.id}
                    header="What did we do well?"
                    type={RetrospectiveType.Well}
                    iterationStage={props.stage}
                    isSelectedIteration={true}
                  />
                </Col>
              )}
            </Row>
            <Row className="note">
              {selectedIteration && (
                <Col span={24}>
                  <RetrospectiveNoteSection
                    backgroundColor="#FFEBEB"
                    iterationId={selectedIteration.id}
                    header="What should we have done better?"
                    type={RetrospectiveType.Bad}
                    iterationStage={props.stage}
                    isSelectedIteration={true}
                  />
                </Col>
              )}
            </Row>
            <Row className="note">
              {selectedIteration && (
                <Col span={24}>
                  <RetrospectiveNoteSection
                    backgroundColor="#D7E7FD"
                    iterationId={selectedIteration.id}
                    header="Follow-Up Actions"
                    type={RetrospectiveType.Todo}
                    iterationStage={props.stage}
                    isSelectedIteration={true}
                  />
                </Col>
              )}
            </Row>
          </PlanSection>
        </>
      )}
    </>
  );
};

import { IComplimentResponse } from 'app/types/entity';
import { Popconfirm, Image, Col, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './ComplimentCard.scss';
import { Trash } from 'react-bootstrap-icons';

export interface IComplimentCardProps {
  data: IComplimentResponse;
  editable: boolean;
  onEdit: () => void;
  onReadmore: () => void;
  onDelete: () => void;
}
export function ComplimentCard({ ...props }: IComplimentCardProps) {
  const textRef = useRef<any>();
  const [displayReadmore, setDisplayReadmore] = useState(true);
  const [previewImg, setPreviewImg] = useState<{ visible: boolean; src: string }>({ visible: false, src: '' });
  useEffect(() => {
    if (textRef?.current) {
      if (textRef.current.offsetHeight < textRef.current.scrollHeight) {
        setDisplayReadmore(true);
      } else {
        setDisplayReadmore(false);
      }
    }
  }, [textRef, props.data.message]);

  return (
    <>
      <div className="card">
        <Row>
          <Col className="card-title" span={20}>
            {props.data.title}
          </Col>
          {props.editable && (
            <Col span={4} className="card-actions">
              <Popconfirm
                title="Are you sure to delete compliment?"
                onConfirm={() => props.onDelete()}
                placement="topRight"
                okText="Yes"
                cancelText="No"
              >
                <Trash className="edit delete" />
              </Popconfirm>
              <span className="edit" onClick={() => props.onEdit()}>
                Edit
              </span>
            </Col>
          )}
        </Row>
        <div className="card-message-container">
          <div className="card-message" ref={textRef}>
            {props.data.message}
          </div>
          {displayReadmore && (
            <Row justify="end">
              <span className="readmore-btn" onClick={() => props.onReadmore()}>
                read more
              </span>
            </Row>
          )}
        </div>
        <div className="card-from">From: {props.data.from}</div>
        <Row style={{ minHeight: '26px' }}>
          {props.data.complimentImages?.map((img, index) => (
            <Col
              span={8}
              key={index}
              onClick={() => {
                setPreviewImg({ visible: true, src: img.href });
              }}
            >
              <span className="card-images" style={{ width: '70%' }}>
                {img?.fileName}
              </span>
            </Col>
          ))}
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{
                destroyOnClose: true,
                visible: previewImg.visible,
                onVisibleChange: (vis) => setPreviewImg({ visible: vis, src: previewImg.src }),
              }}
            >
              <Image src={previewImg.src} />
            </Image.PreviewGroup>
          </div>
        </Row>
        {/* <Row align="bottom" style={{ minHeight: '76px' }}>
          <Col span={12}></Col>
          <Col span={12}>
            <Row justify="end">
            </Row>
          </Col>
        </Row> */}
      </div>
    </>
  );
}

import { Line, LineConfig } from '@ant-design/plots';
import { OwnershipLevelColors } from 'app/common/constants';
import { OwnershipScoreTrendItem } from 'app/types/Ownership';
import moment from 'moment';
import { LegendItem } from '@antv/g2/esm/interface';

interface IProps {
  dataSource: OwnershipScoreTrendItem[];
}

export const OwnershipScoreTrendChart = ({ dataSource }: IProps) => {
  const chartConfig: LineConfig = {
    data: dataSource.map((x) => {
      return {
        Date: moment(x.itemDate).format('MMM YY'),
        Score: Math.round(x.scorePercent) / 100,
        Level: `Level ${x.ownershipLevel}`,
      };
    }),
    xField: 'Date',
    yField: 'Score',
    seriesField: 'Level',
    label: {},
    yAxis: {
      type: 'linear',
      max: 1.0,
      min: 0.0,
      position: 'left',
      tickInterval: 0.1,
    },
    padding: [60, 50, 50, 50],
    legend: {
      position: 'top',
      items: dataSource
        ?.map((x) => x.ownershipLevel)
        ?.filter((value, index, array) => array.indexOf(value) === index)
        ?.sort((a, b) => a - b)
        ?.map(
          (k, i) =>
            ({
              name: `Level ${k}`,
              value: `Level ${k}`,
              marker: {
                symbol: 'circle',
                style: {
                  stroke: OwnershipLevelColors[i % OwnershipLevelColors.length],
                  fill: OwnershipLevelColors[i % OwnershipLevelColors.length],
                },
              },
            } as LegendItem)
        ),
    },
    color: OwnershipLevelColors,
    point: {
      shape: 'diamond',
      size: 5,
    },
    lineStyle: {
      opacity: 1,
    },
  };
  return <Line {...chartConfig} />;
};

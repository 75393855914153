/* eslint-disable react/display-name */
import { Col, DatePicker, Input, Select, Tag } from 'antd';
import { Row } from 'antd';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TeamContext } from 'app/contexts/TeamContext ';
import { UncompletionReportWrapper } from './UncompletionReport.styled';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { OwnershipReportData } from 'app/types/OwnershipReportData';
import { fetchUncompletionReport } from 'app/apis/reportClient';
import { ColumnFilterItem, TablePaginationConfig } from 'antd/lib/table/interface';
import { USER_PAGE_NUMBER } from 'app/common/constants';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { SearchOutlined } from '@ant-design/icons';
import { useFetch } from 'app/hooks/useFetch';
import { getIterationsListNotFuture } from 'app/apis/iterationOverview';
import { UncompletionReportData, UncompletionReportFilterType } from 'app/types/UncompletionReport';
import { isNull } from 'lodash';
const { Search } = Input;

export const UncompletionReport = () => {
  const [data, setData] = useState<UncompletionReportData[]>([]);
  const { setBreadcrumbs } = useContext(TeamContext);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment().startOf('month'));
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const searchEl = useRef(null);
  const { data: iterationsList } = useFetch<string[]>(() => getIterationsListNotFuture(), []);
  const [selectedIterationName, setSelectedIterationName] = useState<string>('');
  const [iterationOptions, setIterationOptions] = useState<{ label: string; value: string }[]>([]);
  const [loading, setLoading] = useState(true);

  const handleIterationOptionChange = async (selectedValue: string) => {
    setSelectedIterationName(selectedValue);
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        path: '#',
        text: 'Report',
        parentCrumb: null,
        renderAsLink: false,
      },
      {
        path: '#',
        text: 'Uncompletion Report',
        parentCrumb: null,
        renderAsLink: true,
      },
    ]);
    if (iterationsList?.length > 0) {
      if (!selectedIterationName) {
        iterationsList.shift();
        setSelectedIterationName(iterationsList[0].toString());
      }
      const options = iterationsList.map((iteration) => ({
        label: iteration.toString(),
        value: iteration.toString(),
      }));
      setIterationOptions(options);

      if (selectedIterationName) handleIterationOptionChange(selectedIterationName);
    }
  }, [iterationsList, setBreadcrumbs, selectedIterationName]);

  useEffect(() => {
    loadData(selectedIterationName);
  }, [selectedIterationName]);

  const loadData = async (iterationName: string) => {
    const data = await fetchUncompletionReport(iterationName);
    setData(data);
    setLoading(false);
  };

  const renderCommitColumn = (record: UncompletionReportData) => {
    if (record.isCommitted === true || isNull(record.isCommitted)) {
      return <Tag color="#108ee9">Y</Tag>;
    }
    return <Tag color="#f3c300">N</Tag>;
  };

  const filterDataOption = (type: UncompletionReportFilterType) => {
    var newData: ColumnFilterItem[];
    switch (type) {
      case UncompletionReportFilterType.ByPortfolio:
        newData = data?.map((item) => ({
          text: item?.portfolio,
          value: item?.portfolioId,
        }));
        break;
      case UncompletionReportFilterType.ByReleaseTrain:
        newData = data?.map((item) => ({
          text: item?.releaseTrain,
          value: item?.releaseTrainId,
        }));
        break;
      case UncompletionReportFilterType.ByDeliveryStream:
        newData = data?.map((item) => ({
          text: item?.deliveryStream,
          value: item?.deliveryStreamId,
        }));
        break;
      case UncompletionReportFilterType.ByScheduleState:
        newData = data?.map((item) => ({
          text: item?.scheduleState,
          value: item?.scheduleState,
        }));
        break;
      case UncompletionReportFilterType.ByCommit:
        newData = data?.map((item) => ({
          text: item?.isCommitted,
          value: item?.isCommitted,
        }));
        break;
    }
    return newData.filter(
      (value, index, self) =>
        value.value !== null && index === self.findIndex((t) => t.value === value.value && t.text === value.text)
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ...`}
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record.teamName.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const columns = useMemo<EditableColumn<UncompletionReportData>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Portfolio',
        width: '200px',
        dataIndex: 'portfolio',
        key: 'portfolio',
        editable: false,
        filters: filterDataOption(UncompletionReportFilterType.ByPortfolio),
        onFilter: (value: number, record) => record?.portfolioId === value,
        render: (value, record, index) => {
          return <div className="overflow-cell">{record.portfolio}</div>;
        },
      },
      {
        title: 'Delivery Steam',
        width: '200px',
        dataIndex: 'deliveryStream',
        key: 'deliveryStream',
        editable: false,
        filters: filterDataOption(UncompletionReportFilterType.ByDeliveryStream),
        onFilter: (value: number, record) => record?.deliveryStreamId === value,
        render: (value, record, index) => {
          return <div className="overflow-cell">{record.deliveryStream}</div>;
        },
      },
      {
        title: 'Release Train',
        width: '200px',
        dataIndex: 'releaseTrain',
        key: 'releaseTrain',
        editable: false,
        filters: filterDataOption(UncompletionReportFilterType.ByReleaseTrain),
        onFilter: (value: number, record) => record?.releaseTrainId === value,
        render: (value, record, index) => {
          return <div className="overflow-cell">{record.releaseTrain}</div>;
        },
      },
      {
        title: 'Team',
        width: '150px',
        dataIndex: 'teamName',
        key: 'teamName',
        editable: false,
        ...getColumnSearchProps('name'),
        render: (value, record, index) => {
          return <div className="overflow-cell">{record.teamName}</div>;
        },
      },
      {
        title: 'User Story',
        width: '400px',
        dataIndex: 'userStory',
        key: 'userStory',
        editable: false,
        render: (value, record, index) => {
          return <div className="overflow-cell">{record.userStory}</div>;
        },
      },
      {
        title: 'Schedule State',
        width: '150px',
        dataIndex: 'scheduleState',
        key: 'scheduleState',
        editable: false,
        align: 'center',
        filters: filterDataOption(UncompletionReportFilterType.ByScheduleState),
        onFilter: (value: string, record) => record?.scheduleState === value,
      },
      {
        title: 'Commit?',
        width: '120px',
        dataIndex: 'isCommitted',
        key: 'isCommitted',
        editable: false,
        align: 'center',
        onFilter: (value: boolean, record) =>
          record?.isCommitted == null ? true === value : record?.isCommitted === value,
        render: (text, record) => renderCommitColumn(record),
        filters: [
          { text: 'Y', value: true },
          { text: 'N', value: false },
        ],
      },
      {
        title: 'Point',
        width: '100px',
        dataIndex: 'point',
        editable: false,
        align: 'center',
        sorter: (a, b) => a.point - b.point,
      },
      {
        title: 'Note',
        dataIndex: 'note',
        editable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, data]
  );

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <UncompletionReportWrapper>
      <span className="title">
        UNCOMPLETION REPORT
        <Select
          onChange={handleIterationOptionChange}
          value={selectedIterationName}
          options={iterationOptions}
          style={{ width: 120 }}
        />
      </span>
      <EditableTable
        pageSize={pageSize}
        current={page}
        loading={loading}
        data={data}
        showAction={false}
        columns={columns}
        onChange={onChange}
        scroll={{ x: 'max-content' }}
      />
    </UncompletionReportWrapper>
  );
};

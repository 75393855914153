import styled from 'styled-components';

export const CarriedOverWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .carried-card {
    margin: 0;
    border: none;

    &.healthy {
      background-color: #d5ffe9;
    }

    &.unhealthy {
      background-color: #ffd4d4;
    }
  }
`;

import { SubPage } from './TeamDetail';

export enum NoteCategory {
  IterationBacklogs,
  IterationBurnDown,
  Velocity,
  CFD,
  CapacityDetail,
  IterationBurnDownByTeam,
  DefectsAndDelivered,
  QualityReport,
  UserStoriesCommittedDelivered,
  DefectsCommittedDelivered,
  IterationDefectsByState,
  IterationDefectsByEnvironment,
  TestExecutionTable,
  RootCauseCategoryByDefect,
  ResolutionByDefect,
  IterationBurnUp,
}
export interface IIterationNote {
  id?: number;
  pageType: SubPage.Plan | SubPage.Report;
  noteCategory: NoteCategory;
  content: string;
  iterationId: number;
}
const initNote = (
  iterationId: number,
  noteCategory: NoteCategory,
  pageType: SubPage.Plan | SubPage.Report
): IIterationNote => {
  return iterationId && { iterationId, noteCategory, pageType, content: null };
};

const getNoteByPageAndCategory = (
  notes: IIterationNote[],
  pageType: SubPage.Plan | SubPage.Report,
  noteCategory: NoteCategory
) => {
  const result = notes?.find((n) => n.pageType === pageType && n.noteCategory === noteCategory);
  return result;
};
export const getNoteByCategory = (notes: IIterationNote[], noteCategory: NoteCategory) => {
  const result = notes?.find((n) => n.noteCategory === noteCategory);
  return result;
};
export const getNoteData = (
  selectedIterationId: number,
  iterationNotes: IIterationNote[],
  page: SubPage.Plan | SubPage.Report,
  noteCategory: NoteCategory
) => getNoteByPageAndCategory(iterationNotes, page, noteCategory) ?? initNote(selectedIterationId, noteCategory, page);

import { Button, Col, Modal, Progress, Row } from 'antd';
import { updateQualitySummary } from 'app/apis/reportClient';
import { IterationContext } from 'app/contexts/IterationContext';
import { UserContext } from 'app/contexts/UserContext';
import { canEditReport } from 'app/pages/TeamDetail/Report/Report';
import { IQualitySummaryRequest, IQualitySummaryResponse } from 'app/types/entity';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Card } from '../Card';

import './QualitySummary.scss';
import { QualitySummaryWrapper } from './QualitySummary.styled';
import { QualitySummaryForm } from './QualitySummaryForm';

interface IProps {
  iterationReportID: number;
  qualitySummary: IQualitySummaryResponse;
}

export const QualitySummary = ({ iterationReportID, qualitySummary }: IProps) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [qualityFormData, setQualityFormData] = useState<IQualitySummaryResponse>(null);
  const { reportStatus } = useContext(IterationContext);
  const MIN_HEIGHT_ITEM = '300px';
  const {
    user: { permissions },
  } = useContext(UserContext);

  const handleSubmit = async (payload: IQualitySummaryRequest) => {
    setQualityFormData({
      ...qualityFormData,
      ...payload,
    });

    await updateQualitySummary(iterationReportID, payload);
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
  };

  useEffect(() => {
    setQualityFormData(qualitySummary);
  }, [qualitySummary]);

  return (
    <QualitySummaryWrapper>
      <Row gutter={[24, 24]} justify="space-around" style={{ padding: '32px 0', minHeight: MIN_HEIGHT_ITEM }}>
        <Button
          type="link"
          className="summary-edit-button"
          onClick={() => {
            showModal();
          }}
          hidden={!canEditReport(reportStatus, permissions)}
        >
          Edit
        </Button>
        <Col sm={8} xs={24}>
          <Card title="Defect Rate" className="quality-summary-item">
            <div className="summary-defect-rate">
              <span>{_.round(qualityFormData?.defectRate, 1)}%</span>
              <div>
                <p>
                  {qualityFormData?.defects} defects / {qualityFormData?.userStories} USs
                </p>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={8} xs={24}>
          <Card title="UT Coverage" className="quality-summary-item">
            {qualityFormData ? (
              <Progress type="circle" percent={qualityFormData.unitTestCoverage} format={(e) => `${e}%`} />
            ) : (
              <Progress type="circle" percent={0} />
            )}
          </Card>
        </Col>
        <Col sm={8} xs={24}>
          <Card title="Line Of Code" className="quality-summary-item">
            <div className="summary-loc">
              {qualityFormData ? (
                <span>
                  {qualityFormData.numberLineOfCode > 0 ? '+' : ''}
                  {qualityFormData.numberLineOfCode}
                </span>
              ) : (
                <span>0</span>
              )}
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        className="quality-summary-modal"
        title="Quality Summary"
        centered
        footer={null}
        visible={visibleModal}
        onCancel={() => {
          closeModal();
        }}
        destroyOnClose={true}
      >
        <QualitySummaryForm onSubmit={handleSubmit} onCloseModal={closeModal} qualitySummary={qualityFormData} />
      </Modal>
    </QualitySummaryWrapper>
  );
};

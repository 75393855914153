import { Row } from 'antd';
import styled from 'styled-components';

export const Title = styled.span`
  color: #09488a;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
`;
export const StyledOwnershipWrapper = styled.div`
  .card-wrapper.card-container {
    margin: 0;
  }

  .main-title {
    margin-bottom: 3rem;
  }
`;
export const TailContent = styled(Row)`
  .legend-title {
    margin-left: 5px;
  }
  .ant-row {
    margin-bottom: 16px;
  }
`;

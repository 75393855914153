/* eslint-disable react/display-name */
import { Button, Col, Input, notification, Radio, RadioChangeEvent } from 'antd';
import { TablePaginationConfig, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ExportTeam, updateTeam } from 'app/apis/teamClient';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { PageTitle } from 'app/layouts/AdminLayout.styled';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { teamOptions, TeamResponse, TeamType } from 'app/types/team';
import './ManageTeams.scss';
import { updateItem } from 'app/utils/tableUtils';
import { TeamContext } from 'app/contexts/TeamContext ';
import { FieldRequiredText, GroupedTeamLinks, USER_PAGE_NUMBER } from 'app/common/constants';
import { useFetch } from 'app/hooks/useFetch';
import { fetchUserRoleUsers, fetchScrumMasterUsers } from 'app/apis/userClient';
import { UserResponse } from 'app/types/user';
import { isNull } from 'lodash';
import { StyledLinkCell } from 'app/components/StyledLinkCell';
import { Link } from 'react-router-dom';
import { openNotification } from 'app/utils/notificationUtils';
import { SelectOptions } from 'app/types/entity';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { ManageTeamsWrapper } from './ManageTeams.styled';
import { downloadFileFromBlob } from 'app/utils/importUtils';
import { ErrorBox } from 'app/components/ErrorBox';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { Helmet } from 'react-helmet';

const { Search } = Input;

export const ManageTeams = () => {
  const getParentType = (type: TeamType): TeamType => {
    switch (type) {
      case TeamType.Team:
        return TeamType.ReleaseTrain;
      case TeamType.ReleaseTrain:
        return TeamType.DeliveryStream;
      case TeamType.DeliveryStream:
        return TeamType.Portfolio;
      default:
        return null;
    }
  };
  const { teams: teamData, setTeams: setData, setBreadcrumbs } = useContext(TeamContext);

  const searchEl = useRef(null);
  const [activeType, setActiveType] = useState<number>(TeamType.Team);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const { data: originalData } = useFetch(fetchScrumMasterUsers);
  const { data: originalUserData } = useFetch(fetchUserRoleUsers);
  const [index, setIndex] = useState<number>(0);
  const [dataUsers, setUsers] = useState<UserResponse[]>();
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();
  const [scrumMasterOptions, setScrumMasterOptions] = useState<SelectOptions<any>[]>();
  const [authorizedUserOptions, setAuthorizedUserOptions] = useState<SelectOptions<any>[]>();

  useEffect(() => {
    setUsers(originalData);
    let scrumMasterOptionData = originalData?.map((item) => ({ value: item.id, label: item.email }));
    if (scrumMasterOptionData !== undefined) {
      scrumMasterOptionData.unshift({ value: null, label: '' });
    }
    setScrumMasterOptions(scrumMasterOptionData);
    let authorizedUserOptions = originalUserData?.map((item) => ({ value: item.id, label: item.email }));
    if (authorizedUserOptions !== undefined) {
      authorizedUserOptions.unshift({ value: null, label: '' });
    }
    if (authorizedUserOptions !== null) setAuthorizedUserOptions(authorizedUserOptions);
    setBreadcrumbs([createDefaultBreadCrumb('Team Management')]);
  }, [originalData, setBreadcrumbs, originalUserData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const onChangeTeamType = ({ target: { value } }: RadioChangeEvent) => {
    setActiveType(value);
    setPage(1);
    setIndex(index + 1);
  };

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ...`}
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      if (dataIndex === 'name') return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());

      var scrumMaster = dataUsers.find((x) => x.fullName.includes(value));
      if (record[dataIndex] === scrumMaster?.id) {
        return record[dataIndex];
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const filterDataOption = (type: TeamType) => {
    var data: ColumnFilterItem[];
    switch (type) {
      case TeamType.ReleaseTrain:
        data = teamData?.map((item) => ({
          text: item?.teamDataResponse?.releaseTrainName,
          value: item?.teamDataResponse?.releaseTrainId,
        }));
        break;
      case TeamType.DeliveryStream:
        data = teamData?.map((item) => ({
          text: item?.teamDataResponse?.deliveryStreamName,
          value: item?.teamDataResponse?.deliveryStreamId,
        }));
        break;
      case TeamType.Portfolio:
        data = teamData?.map((item) => ({
          text: item?.teamDataResponse?.portfolioName,
          value: item?.teamDataResponse?.portfolioId,
        }));
        break;
    }
    return data?.filter(
      (value, index, self) => index === self.findIndex((t) => t.value === value.value && t.text === value.text)
    );
  };

  const renderNameColumn = (record: TeamResponse) => {
    var navigateTo;
    switch (record.type) {
      case 1:
        navigateTo = `/portfolio/${record.id}`;
        break;
      case 2:
        navigateTo = `/delivery-stream/${record.id}`;
        break;
      case 3:
        navigateTo = `/release-train/${record.id}`;
        break;
      case 4:
        navigateTo = `/teams/${record.id}`;
        break;
    }
    return (
      <>
        <StyledLinkCell>
          <Link to={navigateTo}>{record.name}</Link>
        </StyledLinkCell>
      </>
    );
  };

  const renderLink = (text, record) => {
    return (
      <>
        <Link style={{ color: 'black' }} to={`/teams/${record.id}`}>
          {text}
        </Link>
      </>
    );
  };
  const columns = useMemo<EditableColumn<TeamResponse>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: false,
        rules: [{ required: true, message: FieldRequiredText }],
        ...getColumnSearchProps('name'),
        render: (text, record) => renderNameColumn(record),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        options: [
          { label: 'Portfolio', value: 1 },
          { label: 'Delivery Stream', value: 2 },
          { label: 'Release Train', value: 3 },
          { label: 'Development Team', value: 4 },
        ],
        editable: true,
        inputType: 'select',
      },
      {
        title: 'Parent',
        dataIndex: 'parentId',
        key: 'parentId',
        editable: false,
        inputType: 'select',
        onFilter: (value: number, record) => record.parentId === value,
      },
      {
        title: 'Model',
        dataIndex: 'model',
        inputType: 'select',
        options: [
          { label: 'Scrum', value: 'Scrum' },
          { label: 'Kanban', value: 'Kanban' },
          { label: 'Scrumban', value: 'Scrumban' },
        ],
        filters: [
          { text: 'Scrum', value: 'Scrum' },
          { text: 'Kanban', value: 'Kanban' },
          { text: 'Scrumban', value: 'Scrumban' },
        ],
        onFilter: (value: any, record) => record.model?.indexOf(value) === 0,
        render: (text, record) => record.model,
        editable: true,
      },
      {
        title: 'Scrum Master',
        dataIndex: 'scrumMasterID',
        editable: true,
        options: scrumMasterOptions,
        inputType: 'select',
        inputProps: {
          searchOnSelect: true,
        },
        ...getColumnSearchProps('scrumMasterID'),
      },
      {
        title: 'FPT Involved',
        dataIndex: 'isFPTInvolvded',
        inputType: 'select',
        editable: true,
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
        filters: [
          { text: 'True', value: true },
          { text: 'False', value: false },
        ],
        onFilter: (value, record) => record.isFPTInvolvded === value,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, scrumMasterOptions]
  );

  const columnTeams = useMemo<EditableColumn<TeamResponse>[]>(
    () => [
      {
        title: '#',
        width: '3%',
        render: (value, record, index) => (page - 1) * pageSize + index + 1,
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: false,
        ...getColumnSearchProps('name'),
        render: (value, record) => renderLink(value, record),
      },
      {
        title: 'Release Train',
        dataIndex: ['teamDataResponse', 'releaseTrainName'],
        key: 'RT',
        editable: false,
        filters: filterDataOption(TeamType.ReleaseTrain),
        onFilter: (value: number, record) => record?.teamDataResponse?.releaseTrainId === value,
      },
      {
        title: 'Delivery Stream',
        dataIndex: ['teamDataResponse', 'deliveryStreamName'],
        key: 'DS',
        editable: false,
        filters: filterDataOption(TeamType.DeliveryStream),
        onFilter: (value: number, record) => record?.teamDataResponse?.deliveryStreamId === value,
      },
      {
        title: 'Portfolio',
        dataIndex: ['teamDataResponse', 'portfolioName'],
        key: 'PORT',
        editable: false,
        rules: [{ required: true, message: FieldRequiredText }],
        filters: filterDataOption(TeamType.Portfolio),
        onFilter: (value: number, record) => record?.teamDataResponse?.portfolioId === value,
      },
      {
        title: 'Working Type',
        dataIndex: 'workingType',
        options: [
          { label: '', value: null },
          { label: 'Development', value: 1 },
          { label: 'KTLO', value: 2 },
          { label: 'SRE', value: 3 },
          { label: 'Triage', value: 4 },
        ],
        editable: true,
        inputType: 'select',
        filters: [
          { text: 'Development', value: 1 },
          { text: 'KTLO', value: 2 },
          { text: 'SRE', value: 3 },
          { text: 'Triage', value: 4 },
        ],
        onFilter: (value: number, record) => record?.workingType === value,
      },
      {
        title: 'Model',
        dataIndex: 'model',
        inputType: 'select',
        options: [
          { label: '', value: null },
          { label: 'Scrum', value: 'Scrum' },
          { label: 'Kanban', value: 'Kanban' },
          { label: 'Scrumban', value: 'Scrumban' },
        ],
        filters: [
          { text: 'Scrum', value: 'Scrum' },
          { text: 'Kanban', value: 'Kanban' },
          { text: 'Scrumban', value: 'Scrumban' },
        ],
        onFilter: (value: any, record) => record.model?.indexOf(value) === 0,
        render: (text, record) => record.model,
        editable: true,
      },
      {
        title: 'Scrum Master',
        dataIndex: 'scrumMasterID',
        editable: true,
        options: scrumMasterOptions,
        inputType: 'select',
        inputProps: {
          searchOnSelect: true,
        },
        ...getColumnSearchProps('scrumMasterID'),
      },
      {
        title: 'Authorized User',
        dataIndex: 'authorizedUserIds',
        editable: true,
        width: 210,
        options: authorizedUserOptions,
        inputType: 'select',
        inputProps: {
          searchOnSelect: true,
          mode: 'multiple',
        },
        render: (text, record) =>
          record.authorizedUserIds?.map((x) => {
            return authorizedUserOptions?.map((y) => {
              if (y.value === x) return y.label + ' ';
            });
          }),
      },
      {
        title: 'FPT Involved',
        dataIndex: 'isFPTInvolvded',
        inputType: 'select',
        editable: true,
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
        filters: [
          { text: 'True', value: true },
          { text: 'False', value: false },
        ],
        onFilter: (value, record) => record.isFPTInvolvded === value,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, scrumMasterOptions, teamData]
  );

  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (!teamData) {
      return;
    }

    const newColumns = [...columns];
    const indexParent = newColumns.findIndex((item) => item.dataIndex === 'parentId');
    const parentCol = newColumns[indexParent];

    parentCol.options = teamData.map((item) => ({ value: item.id, label: item.name }));

    parentCol.filters = teamData
      ?.filter((item) => item.type === getParentType(activeType))
      .map((item) => ({ text: item.name, value: item.id }));

    setTableColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeType, teamData, page, dataUsers]);

  const onSave = async (id: number, { scrumMasterID, isFPTInvolvded, model, workingType, authorizedUserIds }: any) => {
    try {
      await updateTeam(id, { scrumMasterID, isFPTInvolvded, model, workingType, authorizedUserIds });
      const newData = updateItem({ id, scrumMasterID, isFPTInvolvded, model, workingType }, teamData);
      notification.success({
        message: `Update successfully`,
        duration: 2,
      });
      setData(null);
      setData(newData);
    } catch (error) {
      openNotification(400, 'Update failed!');
    }
  };

  const downloadFile = async () => {
    try {
      setDownloadLoading(true);
      const result = await ExportTeam();
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
      setHasError(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  const tableData = teamData?.filter((item) => item.type === activeType);

  return (
    <ManageTeamsWrapper>
      <Helmet>
        <title>ADM Tool | Team management</title>
      </Helmet>
      <Row>
        <Col span={15}>
          <PageTitle>
            <Title level={3}>TEAM MANAGEMENT</Title>
          </PageTitle>
        </Col>
        <Col span={9}>
          <Button
            className="btn-DownloadXML"
            size="large"
            onClick={() => downloadFile()}
            loading={downloadLoading}
            style={{ float: 'right' }}
          >
            <DownloadOutlined />
          </Button>
        </Col>
      </Row>
      <ErrorBox error={hasError} message={hasError} />
      <Row className="team-header">
        <Radio.Group
          options={teamOptions}
          onChange={onChangeTeamType}
          value={activeType}
          optionType="button"
          buttonStyle="solid"
        />
      </Row>
      {activeType !== TeamType.Team ? (
        <EditableTable
          key={index}
          pageSize={pageSize}
          current={page}
          data={tableData}
          isEdit={true}
          columns={tableColumns}
          onSave={onSave}
          onChange={onChange}
        />
      ) : (
        <EditableTable
          key={index}
          pageSize={pageSize}
          current={page}
          data={teamData}
          isEdit={true}
          columns={columnTeams}
          onSave={onSave}
          onChange={onChange}
        />
      )}
    </ManageTeamsWrapper>
  );
};

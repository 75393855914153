import { OwnershipScoreLevel } from 'app/types/Ownership';
import { GaugeConfig, Gauge } from '@ant-design/plots';
import { GROWING_COLOR, BUILDING_COLOR, SOLID_COLOR, STRONG_COLOR, LEADING_COLOR } from 'app/common/constants';

interface IProps {
  data: OwnershipScoreLevel[];
}

export const OwnershipTotalScore = ({ data }: IProps) => {
  const maxScore = Math.max(...data.map((x) => x.maxScore));
  const actualScore = data.map((x) => x.actualScore).reduce((partialSum, a) => partialSum + a, 0);
  const ticks = [...data.map((x) => (x.scoreTarget?.from ?? 0) / maxScore), 1];

  const gaugeConfig: GaugeConfig = {
    axis: {
      label: {
        formatter: function formatter(v) {
          return Math.round((Number(v) / 2) * 100);
        },
      },
      subTickLine: { count: 4 },
    },
    percent: actualScore / maxScore,
    statistic: {
      content: {
        style: {
          fontSize: '36px',
          lineHeight: '36px',
          paddingBottom: '5px',
        },
        formatter: () => {
          return `${actualScore} pts`;
        },
      },
    },
    range: {
      ticks: ticks,
      color: [GROWING_COLOR, BUILDING_COLOR, SOLID_COLOR, STRONG_COLOR, LEADING_COLOR],
    },
  };

  return <Gauge {...gaugeConfig} />;
};

import {
  OwnershipClientSatisfactionTitle,
  OwnershipProdIssueTitle,
  OwnershipSLARateTitle,
} from './../../../common/constants';
import { OwnershipColor } from 'app/common/constants';
import {
  ClientSatisfaction,
  ProductionIssue,
  SatisfactionRate,
  EvaluationProgress,
} from './../../../types/OwnershipHeatmap';
import { OwnershipLevel } from 'app/types/Ownership';
type OwnershipColumnType = 'client' | 'level' | 'rate' | 'issue';
export const definedColorSatisfaction = (
  rate: ClientSatisfaction | OwnershipLevel | ProductionIssue | SatisfactionRate,
  type: OwnershipColumnType
) => {
  if (type === 'client') {
    switch (rate) {
      case ClientSatisfaction.Satisfied:
        return { background: OwnershipColor.satisfied, title: OwnershipClientSatisfactionTitle.satisfied };

      case ClientSatisfaction.SomewhatSatisfied:
        return { background: OwnershipColor.caution, title: OwnershipClientSatisfactionTitle.someWhatSatisfied };

      case ClientSatisfaction.NotSatisfied:
        return { background: OwnershipColor.atRisk, title: OwnershipClientSatisfactionTitle.notSatisfied };

      case ClientSatisfaction.NotApplicable:
        return { background: OwnershipColor.notApplicable, title: OwnershipClientSatisfactionTitle.notApplicable };
      case ClientSatisfaction.NA:
        return { background: OwnershipColor.notApplicable, title: OwnershipClientSatisfactionTitle.notAvailable };

      default:
        return { background: OwnershipColor.notApplicable, title: OwnershipClientSatisfactionTitle.notAvailable };
    }
  } else if (type === 'level') {
    switch (rate) {
      case OwnershipLevel.Level_4:
        return { background: OwnershipColor.satisfied, title: '4' };
      case OwnershipLevel.Level_3:
        return { background: OwnershipColor.satisfied, title: '3' };

      case OwnershipLevel.Level_2:
        return { background: OwnershipColor.caution, title: '2' };

      case OwnershipLevel.Level_1:
        return { background: OwnershipColor.atRisk, title: '1' };

      case OwnershipLevel.Level_0:
        return { background: OwnershipColor.notApplicable, title: '0' };

      case OwnershipLevel.None:
        return { background: OwnershipColor.notApplicable, title: 'N/A' };

      default:
        return { background: OwnershipColor.notApplicable, title: 'N/A' };
    }
  } else if (type === 'rate') {
    switch (rate) {
      case SatisfactionRate.High:
        return { background: OwnershipColor.satisfied, title: OwnershipSLARateTitle.high };

      case SatisfactionRate.Medium:
        return { background: OwnershipColor.caution, title: OwnershipSLARateTitle.medium };

      case SatisfactionRate.Low:
        return { background: OwnershipColor.atRisk, title: OwnershipSLARateTitle.low };

      case SatisfactionRate.NoTracking:
        return { background: OwnershipColor.notApplicable, title: OwnershipSLARateTitle.notTracking };

      case SatisfactionRate.NA:
        return { background: OwnershipColor.notApplicable, title: OwnershipSLARateTitle.notAvailable };

      default:
        return { background: OwnershipColor.notApplicable, title: OwnershipSLARateTitle.notAvailable };
    }
  } else {
    switch (rate) {
      case ProductionIssue.NoIssue:
        return { background: OwnershipColor.satisfied, title: OwnershipProdIssueTitle.noIssue };

      case ProductionIssue.NA:
        return { background: OwnershipColor.notApplicable, title: OwnershipProdIssueTitle.notAvailable };

      case ProductionIssue.RollbackIssue:
        return { background: OwnershipColor.atRisk, title: OwnershipProdIssueTitle.rollbackIssue };

      case ProductionIssue.NoProductionSupport:
        return { background: OwnershipColor.notApplicable, title: OwnershipProdIssueTitle.noProductionsupport };

      default:
        return { background: OwnershipColor.notApplicable, title: OwnershipProdIssueTitle.noProductionsupport };
    }
  }
};

export const evaluationTitle = (type: EvaluationProgress) => {
  switch (type) {
    case EvaluationProgress.AtRisk:
      return { background: OwnershipColor.caution, title: 'At risk' };

    case EvaluationProgress.Completed:
      return { background: OwnershipColor.completed, title: 'Completed' };

    case EvaluationProgress.CriticalRisk:
      return { background: OwnershipColor.atRisk, title: 'Critical risk' };

    case EvaluationProgress.NotStarted:
      return { background: OwnershipColor.notApplicable, title: 'Not started' };
    case EvaluationProgress.OnTrack:
      return { background: OwnershipColor.satisfied, title: 'On track' };

    default:
      return { background: OwnershipColor.notApplicable, title: 'Not started' };
  }
};

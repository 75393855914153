import { Card, Empty } from 'antd';
import { Meta } from 'antd/lib/list/Item';
import { AwardModel, AwardTo } from 'app/types/AllAwardType';
import { StyleRecognitionCard } from './RecognitionCard.styled';
interface IProps {
  award: AwardModel;
  onclick: (value) => void;
}
export const RecognitionCard = (props: IProps) => {
  const handleTitle = (awardTo: AwardTo[]) => {
    const title = awardTo?.map((obj) => obj.name)?.join(', ');
    return <div className="title">{title}</div>;
  };
  const handleDescription = (description) => {
    return <span>{description}</span>;
  };

  return (
    <StyleRecognitionCard>
      <Card
        className="recognition-card"
        hoverable
        cover={props?.award.imageUrl ? <img src={props?.award.imageUrl} /> : <Empty description={'No Image'} />}
        onClick={() => {
          props.onclick(props.award);
        }}
      >
        <div className="recognition-feedback">
          <div className="recognition-feedback-content">{props?.award?.feedback}</div>
        </div>
        <Meta
          title={handleTitle(props?.award?.awardTo)}
          description={handleDescription(props?.award?.presentativeFor.name)}
        />
      </Card>
    </StyleRecognitionCard>
  );
};

import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier, convertToRaw, ContentState } from 'draft-js';
import { formAction } from 'app/pages/AboutADM/ReleaseNote/ReleaseNote';
import { IReleaseNoteRequest, IReleaseNoteResponse } from 'app/types/ReleaseNoteModel';
import { createReleaseNote, editReleaseNote } from 'app/apis/releaseNote';
import { bindingReleaseNoteData } from './ReleaseNoteHelper';
import { openNotificationByType } from 'app/utils/notificationUtils';
import { ErrorType, SuccesType } from 'app/common/constants';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ModalFunc } from 'antd/lib/modal/confirm';
import { FormInstance } from 'antd/es/form';
import { StyledModal } from './ReleaseNoteStyledModal.styled';

interface ReleaseNoteFormModalProps {
  typeModal: formAction;
  setTypeModal: React.Dispatch<React.SetStateAction<formAction>>;
  setTypePrefixIcon: React.Dispatch<any>;
  listData: IReleaseNoteResponse[];
  setListData: React.Dispatch<React.SetStateAction<IReleaseNoteResponse[]>>;
  confirm: ModalFunc;
  formReleaseNote: FormInstance<any>;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  editorState: any;
  setEditorState: React.Dispatch<any>;
}

export const ReleaseNoteFormModal = (props: ReleaseNoteFormModalProps) => {
  const formReleaseNote = props.formReleaseNote;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const requiredValidationMessage = 'This field is required';
  const confirm = props.confirm;
  const editorState = props.editorState;

  const closeModal = () => {
    props.setModalState(false);
    formReleaseNote.resetFields();
    // Reset Editor
    const emptyEditorState = EditorState.push(editorState, ContentState.createFromText(''), 'remove-range');
    props.setEditorState(emptyEditorState);

    // Reset Prefix Icon
    props.setTypePrefixIcon(null);
  };

  const handleSubmitForm = async () => {
    try {
      const formData = formReleaseNote.getFieldsValue();
      // Convert Editor content to Json
      const contentState = editorState.getCurrentContent();
      const contentStateJson =
        contentState.hasText() && contentState.getPlainText().length > 0
          ? JSON.stringify(convertToRaw(contentState))
          : null;
      const payload: IReleaseNoteRequest = {
        id: formData.id,
        name: formData.name,
        description: contentStateJson,
        releaseDate: formData.releaseDate,
      };
      if (props.typeModal === formAction.Create) {
        const data = await createReleaseNote(payload);
        bindingReleaseNoteData(data);
        props.setListData([...props.listData, data]);
        openNotificationByType(SuccesType, 'Create Successfully !');
      } else if (props.typeModal === formAction.Edit) {
        const data = await editReleaseNote(payload);
        bindingReleaseNoteData(data);
        props.setListData([
          ...props.listData.map((item) =>
            item.id == data.id
              ? { ...data, name: data.name, description: data.description, releaseDate: data.releaseDate }
              : item
          ),
        ]);
        openNotificationByType(SuccesType, 'Edit Successfully !');
      }
    } catch (error) {
      openNotificationByType(ErrorType, 'Something Went Wrong !!!');
    }
    closeModal();
  };

  const showUpdateConfirm = () => {
    confirm({
      title: 'Are you sure save this record?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        handleSubmitForm();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <StyledModal
      title="RELEASE NOTE"
      visible={props.modalState}
      onCancel={() => closeModal()}
      width={800}
      centered
      getContainer={false}
      footer={[
        <Button key="back" size="large" onClick={() => closeModal()}>
          Cancel
        </Button>,
        <Button key="submit" size="large" htmlType="submit" type="primary" form="ReleaseNoteForm">
          Submit
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={formReleaseNote}
        size={'middle'}
        name="ReleaseNoteForm"
        autoComplete="off"
        colon={false}
        labelAlign="left"
        validateTrigger="onSubmit"
        onFinish={() => showUpdateConfirm()}
      >
        <Row>
          <Col span={12}>
            <FormItem name="id" style={{ display: 'hidden', height: '0', margin: '0' }}>
              <></>
            </FormItem>
            <FormItem
              label="Name"
              name="name"
              labelCol={{ span: 24 }}
              style={{ width: '90%', float: 'left' }}
              rules={[{ required: true, message: requiredValidationMessage }]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Release Date"
              name="releaseDate"
              labelCol={{ span: 24 }}
              style={{ width: '90%', float: 'right' }}
              rules={[{ required: true, message: requiredValidationMessage }]}
            >
              <DatePicker />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Description" name="description" labelCol={{ span: 24 }}>
              <Editor
                readOnly={false}
                toolbarHidden={false}
                editorState={editorState}
                editorStyle={{ height: '220px' }}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-box"
                toolbarClassName="editor-toolbar"
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                    'remove',
                    'history',
                  ],
                }}
                onEditorStateChange={(values) => {
                  formReleaseNote.setFieldsValue({
                    description: values,
                  });
                  props.setEditorState(values);
                }}
                onTab={(e) => {
                  const tabCharacter = '              ';
                  e.preventDefault();
                  let currentState = formReleaseNote.getFieldValue('description');
                  let newContentState = Modifier.replaceText(
                    currentState.getCurrentContent(),
                    currentState.getSelection(),
                    tabCharacter
                  );
                  formReleaseNote.setFieldsValue({
                    detail: EditorState.push(currentState, newContentState, 'insert-characters'),
                  });
                }}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

import styled from 'styled-components';

export const StyledBestPerformer = styled.div`
  .ant-table-cell {
    padding: 8px;
  }

  .filter-dropdown {
    position: absolute;
    width: 100%;
    right: 28px;
    top: 15px;
    & > div {
      min-width: 150px;
    }
  }
  @media screen and (max-width: 992px) {
    .best-performer-card {
      .card-title {
        text-align: left;
      }
    }
  }
`;

export const StyledAvatarInfo = styled.div`
  > span {
    margin-left: 5px;
  }
`;

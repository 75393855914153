import { MonitorStatusResponse } from './SystemMonitoring';
import { SelectOptions } from './entity';
import { TeamResponse } from './team';
import { UserStoryStates, WorkItemType } from './PlannedWorkItem';

export enum SubPage {
  Plan,
  Monitoring,
  Report,
  Overview,
}

export const subPageOptions: SelectOptions<SubPage>[] = [
  {
    label: 'Overview',
    value: SubPage.Overview,
  },
  {
    label: 'Plan',
    value: SubPage.Plan,
  },
  {
    label: 'Monitor',
    value: SubPage.Monitoring,
  },
  {
    label: 'Report',
    value: SubPage.Report,
  },
];

export enum CustomReportType {
  ImgReport = 1,
  ValueReport = 2,
}

export const UnitOptions = [
  {
    label: '%',
    value: 1,
  },
  {
    label: 'Text',
    value: 2,
  },
];

export const CustomReportTypeOptions: SelectOptions<CustomReportType>[] = [
  {
    label: 'Upload picture',
    value: CustomReportType.ImgReport,
  },
  {
    label: 'Input value',
    value: CustomReportType.ValueReport,
  },
];

export interface ITeamDetailContext {
  lastSyncOn: Date;
  team: TeamResponse;
  setTeam: (team: TeamResponse) => void;
}

export enum ExportType {
  PDF,
  Image,
  PPT,
}

export class QuarterReleasePlanDrillDownPlot {
  name: string = '';
  itemType: WorkItemType.Feature | WorkItemType.UserStory;
  itemCode: string;
  itemTitle: string;
  value: number;
  taskState: string;
  children: QuarterReleasePlanDrillDownPlot[] = [];
  preliminaryEstimate: string;
  plannedEndDate: Date;
  quarterName: string; // e.g. 2023Q1
  quarterReleaseNote: string;
  quarterReleaseNoteID: number;
  data: QuarterReleasePlanDrillDownPlot; // This is generated by antd; only useful for chart rendering data
}

export enum QuarterReleasePlanTaskStatePrepending {
  Feature = ' (F)',
  UserStory = ' (US)',
}

export class DrillDownPlotItemFormatterData {
  color: string = '';
  data: QuarterReleasePlanDrillDownPlot;
}

export class DrillDownPlotItemFormatter {
  path: DrillDownPlotItemFormatterData[] = [];
}

export class DrillDownColorFieldData {
  taskState: string = '';
  value: number = 0;
}

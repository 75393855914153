import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { USER_PAGE_NUMBER } from 'app/common/constants';
import { HolidayItem, HolidayRequest, CountryOption } from 'app/types/Holiday';
import { TeamContext } from 'app/contexts/TeamContext ';
import { createDefaultBreadCrumb } from 'app/common/breadcrumb';
import { Helmet } from 'react-helmet';
import { PageTitle } from 'app/layouts/AdminLayout.styled';
import Title from 'antd/lib/typography/Title';
import { AddProjBtn } from './Holiday.styled';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TablePaginationConfig,
  Switch,
  Tooltip,
  Typography,
  Space,
} from 'antd';
import { useFetch } from 'app/hooks/useFetch';
import { createHoliday, deleteHoliday, fetchAllHoliday, updateHoliday } from 'app/apis/holiday';
import { EditableColumn, EditableTable } from 'app/components/EditableTable';
import { openNotification } from 'app/utils/notificationUtils';
import { deleteItem, updateItem } from 'app/utils/tableUtils';
import { StyledModal } from '../ManageUsers/ManagedUsers.styled';
import './Holiday.scss';
import holidays from 'date-holidays';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { isNull } from 'lodash';
import Search from 'antd/lib/input/Search';

type Props = {};
export const Holiday = (props: Props) => {
  const searchEl = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(USER_PAGE_NUMBER);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [holidaysData, setHoliday] = useState<HolidayItem[]>();
  const [reset, setTriggerReset] = useState<boolean>(false);
  const [isRangeDate, setRangeDate] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [codeCountry, setCodeCountry] = useState<string>(null);
  const [isDisableSubmitModal, setDisableSubmitModal] = useState<boolean>(false);
  const [countriesOption, setCountriesOption] = useState<CountryOption[]>();
  const { setBreadcrumbs } = useContext(TeamContext);
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const dateFormat = 'YYYY/MM/DD';
  const textDisplay = isEdit ? 'UPDATE' : 'ADD';
  let dateCurrent = new Date();
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const optionCountryDefault: CountryOption = {
    label: 'All',
    value: 'All',
  };
  let inputProps = {
    searchOnSelect: true,
  };
  const initialValue = {
    fromDateOfHoliday: isRangeDate ? [null, null] : moment(dateCurrent.toLocaleString()),
  };

  useFetch(async () => {
    const hd = new holidays();
    const countriesData: CountryOption[] = flatCountry(hd.getCountries());
    countriesData.push(optionCountryDefault);
    setCountriesOption(countriesData);
  });

  useFetch(async () => {
    const data = await fetchAllHoliday();
    setHoliday(data);
  }, [reset]);

  useFetch(async () => {
    setBreadcrumbs([createDefaultBreadCrumb('Holiday')]);
  }, [setBreadcrumbs]);

  const requiredValidationMessage = (message) => {
    return [{ required: true, message: message }];
  };

  const renderCountry = (record: HolidayItem) => {
    let projectLeadInfo = countriesOption?.find((x) => x.value === record.countryCode) ?? optionCountryDefault;
    return <span>{projectLeadInfo?.label}</span>;
  };

  const renderName = (record: HolidayItem) => {
    let { countryCode, name } = record;
    let nameWithCode = countryCode !== 'All' ? `[${countryCode}] ${name}` : name;
    return <span>{nameWithCode}</span>;
  };

  const switchDateToRange = (event) => {
    setDisableSubmitModal(event);
    if (!event) {
      form.setFieldsValue({
        fromDateOfHoliday: null,
      });
    }
    setRangeDate(event);
  };

  const convertDate = (date) => {
    if (date) {
      return moment(date, dateFormat);
    }
    return null;
  };

  const renderValueDate = (record: HolidayItem) => {
    let { fromDateOfHoliday, toDateOfHoliday } = record;
    if (fromDateOfHoliday && !toDateOfHoliday) {
      return convertDate(fromDateOfHoliday);
    }
    return [convertDate(fromDateOfHoliday), convertDate(toDateOfHoliday)];
  };

  const renderDataOfDate = (record: HolidayItem) => {
    let { fromDateOfHoliday, toDateOfHoliday } = record;
    let messageData = fromDateOfHoliday;
    if (toDateOfHoliday) {
      messageData = `${fromDateOfHoliday}->${toDateOfHoliday}`;
    }
    return <span>{messageData}</span>;
  };

  const processDateWhenSubmit = (values) => {
    let date = values.fromDateOfHoliday;
    let isValidDate = Array.isArray(date) && date.length === 2;
    let fromDateOfHoliday: string = null;
    let toDateOfHoliday: string = null;

    if (isValidDate) {
      fromDateOfHoliday = date[0].format(dateFormat);
      toDateOfHoliday = date[1].format(dateFormat);
    } else {
      fromDateOfHoliday = date.format(dateFormat);
    }

    return {
      fromDateOfHoliday,
      toDateOfHoliday,
    };
  };

  // End Process datepicker
  const handleChangeRangeDate = (range) => {
    if (range && range.length === 2) {
      setDisableSubmitModal(false);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder={`Search ${dataIndex}`}
            onSearch={() => confirm()}
            onPressEnter={() => confirm()}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isNull(record[dataIndex])) {
        return '';
      }
      if (dataIndex === 'name') return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const onChangePagination = (pagination: TablePaginationConfig) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const flatCountry = (countries) => {
    var keys = Object.keys(countries);

    var listCountry = keys.map((x) => {
      let country: CountryOption = {
        label: countries[x],
        value: x,
      };
      return country;
    });

    return listCountry;
  };

  const onAddHoliday = async (values) => {
    try {
      const { fromDateOfHoliday, toDateOfHoliday } = processDateWhenSubmit(values);

      let newRecord: HolidayRequest = {
        name: values.name,
        description: values.description,
        fromDateOfHoliday: fromDateOfHoliday,
        toDateOfHoliday: toDateOfHoliday,
        countryCode: values.countryCode,
      };

      const respone = await createHoliday(newRecord);
      if (respone) {
        setTriggerReset(!reset);
        setToggleModal(false);
        setCodeCountry(null);
      }
    } catch (error) {
      console.error(error);
      openNotification(error.status, error?.data?.error?.message);
    }
  };

  const onUpdateHoliday = async (values) => {
    try {
      const { fromDateOfHoliday, toDateOfHoliday } = processDateWhenSubmit(values);

      var record = { ...values, fromDateOfHoliday, toDateOfHoliday };
      const respone = await updateHoliday(values.id, record);

      if (respone) {
        values = {
          ...values,
          dayofHoliday: toDateOfHoliday ? `${fromDateOfHoliday}->${toDateOfHoliday}` : fromDateOfHoliday,
          name: codeCountry ? `[${codeCountry}] ${values.name}` : values.name,
        };
        let newData = updateItem(record, holidaysData);
        setHoliday(newData);
        setToggleModal(false);
        setCodeCountry(null);
      }
    } catch (error) {
      console.error(error);
      var validateError = error?.data?.error?.validationErrors;
      if (validateError !== undefined && validateError?.length > 0) {
        for (let validate of validateError) {
          if (validate.members.includes('fromDateOfHoliday')) {
            validate.message = 'Please input Date!';
          }
          openNotification(error.status, validate.message);
        }
      } else {
        openNotification(error.status, error?.data?.error?.message);
      }
    }
  };

  const onDeleteHoliday = async (id: number, record: HolidayItem) => {
    try {
      await deleteHoliday(record.id);
      let newData = deleteItem(id, holidaysData);
      setHoliday(newData);
    } catch (error) {
      console.error(error);
      openNotification(error.status, error?.data?.error?.message);
    }
  };

  const onEdit = async (id: number) => {
    let index = holidaysData.map((x) => x.id).indexOf(id);
    let record = holidaysData[index];

    setCodeCountry(record.countryCode);
    setEdit(true);
    setToggleModal(true);
    setRangeDate(record.toDateOfHoliday ? true : false);

    form.setFieldsValue({
      ...record,
      fromDateOfHoliday: renderValueDate(record),
    });
  };

  const toggleAddProject = () => {
    form.resetFields();
    form.setFieldsValue({
      fromDateOfHoliday: null,
    });
    setToggleModal(true);
    setRangeDate(false);
    setDisableSubmitModal(false);
    setEdit(false);
    setCodeCountry(null);
  };

  const onchangeCountry = (value) => {
    let code = 'All';
    if (value !== 'All') code = value;
    setCodeCountry(code);
  };

  const columns = useMemo<EditableColumn<HolidayItem>[]>(() => {
    let defineColumns: EditableColumn<HolidayItem>[] = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '20%',
        ...getColumnSearchProps('name'),
        render: (value, record, index) => renderName(record),
      },
      {
        title: 'Date',
        dataIndex: 'fromDateOfHoliday',
        className: 'manage_holiday_datapicker',
        width: '15%',
        inputType: 'custom',
        render: (value, record, index) => renderDataOfDate(record),
      },
      {
        title: 'Country',
        dataIndex: 'countryCode',
        width: '15%',
        render: (value, record, index) => renderCountry(record),
        inputType: 'select',
        options: countriesOption,
        ...inputProps,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '38%',
      },
    ];

    return defineColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidaysData]);

  return (
    <div>
      <Helmet>
        <title>ADM Tool | Holiday</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>Holiday</Title>
      </PageTitle>

      <Row style={{ marginBottom: '1.5em' }}>
        <Col style={{ marginLeft: 'auto' }}>
          <AddProjBtn type="primary" onClick={toggleAddProject}>
            Add Holiday
          </AddProjBtn>
        </Col>
      </Row>

      <EditableTable
        data={holidaysData}
        onSave={onUpdateHoliday}
        onDelete={onDeleteHoliday}
        onEdit={onEdit}
        columns={columns}
        isEdit={true}
        isDelete={true}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          onChange: () => onChangePagination,
          current: pageNumber,
        }}
      />

      <StyledModal
        destroyOnClose
        title={`${textDisplay} HOLIDAY`}
        visible={toggleModal}
        onCancel={() => setToggleModal(false)}
        width={700}
        centered
        footer={[
          <Button key="back" size="large" onClick={() => setToggleModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            size="large"
            form="addHoliday"
            disabled={isDisableSubmitModal}
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>,
        ]}
      >
        <Form
          {...layout}
          size={'middle'}
          onFinish={isEdit ? onUpdateHoliday : onAddHoliday}
          name="addHoliday"
          initialValues={initialValue}
          autoComplete="off"
          colon={false}
          labelAlign="left"
          form={form}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item label="Name">
            <Space>
              {codeCountry && codeCountry !== 'All' && (
                <Tooltip title="Country code" className="display_code_country">
                  <Typography.Text>[{codeCountry}]</Typography.Text>
                </Tooltip>
              )}
              <Form.Item
                className="holiday_name"
                name="name"
                rules={requiredValidationMessage('Please input this field!')}
              >
                <Input
                  style={{ paddingLeft: codeCountry && codeCountry !== 'All' ? '40px' : '11px' }}
                  autoFocus={true}
                />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item label="Date of Holiday">
            <Form.Item
              label="Date"
              name="fromDateOfHoliday"
              rules={requiredValidationMessage('Please choose this field!')}
              className="fromDateOfHoliday"
            >
              {!isRangeDate ? (
                <DatePicker format={dateFormat} />
              ) : (
                <RangePicker onCalendarChange={handleChangeRangeDate} format={dateFormat} />
              )}
            </Form.Item>

            <Form.Item className="switch-date" label="In range" valuePropName="checked">
              <Switch checked={isRangeDate} onClick={switchDateToRange} />
            </Form.Item>
          </Form.Item>

          <Form.Item initialValue={'All'} label="Country" name="countryCode">
            <Select
              options={countriesOption}
              showSearch={true}
              optionFilterProp="label"
              onChange={onchangeCountry}
              filterOption={(value, option) => option.label.toString().toLowerCase().includes(value.toLowerCase())}
            />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>
        </Form>
      </StyledModal>
    </div>
  );
};
